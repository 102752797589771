/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * downloadCsv.
 * Downloads a CSV file from the specified path and prepends a BOM to ensure proper encoding.
 * This make sure excel does not use ANSI as its default encoding method.
 *
 * @param {any} responseData
 * @param {string} filename
 * @returns {Promise<void>}
 */
export async function downloadCsv(responseData: any, filename: string): Promise<void> {
  const bom = '\ufeff';
  const blob = new Blob([bom + responseData], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Clean up the URL object
  URL.revokeObjectURL(url);
}
