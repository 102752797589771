import { Button, Checkbox, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { useGetCompanyQuery, useSetCompanyContactMutation } from '@blockpulse3/graphql/hooks';
import { ErrorMessage, Input, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

import { naturalAccessFormDefaults, naturalAccessFormSchema } from './schema';
import { INaturalCompanyAccessForm } from './types';

type Props = {
  /* ** Disabled fields ** */
  isDisabled?: boolean;
  /* ** Is editing mode ** */
  isEditing?: boolean;
  /* ** Default form values ** */
  defaultValues: INaturalCompanyAccessForm;
  /* ** Should activate 'Delete' button ** */
  shouldCancel?: boolean;
  /* ** Callback on delete button ** */
  onCancel?: () => void;
  /* ** Callback on submit button ** */
  onSubmit?: (data: INaturalCompanyAccessForm) => void;
};

export function NaturalCompanyAccessForm({
  isDisabled = false,
  isEditing = false,
  defaultValues = naturalAccessFormDefaults,
  shouldCancel = true,
  onCancel = noop,
  onSubmit = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { companyId = '' } = useParams();
  const { refetch } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const [setCompanyContact] = useSetCompanyContactMutation();

  const { register, formState, setValue, handleSubmit } = useForm<INaturalCompanyAccessForm>({
    defaultValues,
    resolver: yupResolver(naturalAccessFormSchema),
  });

  const handleFormSubmit: SubmitHandler<INaturalCompanyAccessForm> = (data) => {
    onSubmit(data);
  };

  const handleFormCancel = (): void => {
    onCancel();
  };

  const handleSetCompanyContact = (e: React.ChangeEvent<HTMLInputElement>, id: string): void => {
    if (id) {
      setCompanyContact({
        variables: {
          setCompanyContactInput: {
            representativeId: id,
            companyId,
            isContact: e.target.checked,
          },
        },
        onCompleted: () => {
          refetch();
          successToast({ title: t('ContactUpdated') });
        },
        onError: () => {
          errorToast({ title: t('ContactModificationError') });
        },
      });
    }
  };

  useEffect(() => {
    /* ** Fill fields on default values change: switching the it's me switch ** */
    setValue('firstName', defaultValues.firstName);
    setValue('lastName', defaultValues.lastName);
  }, [defaultValues, setValue]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing="8">
        <fieldset disabled={isDisabled}>
          <Stack spacing="4">
            <FormControl isInvalid={!!formState.errors?.email}>
              <FormLabel htmlFor="email">{t('Email', { nb: 1 })}</FormLabel>
              <Input id="email" type="text" {...register('email')} />
              <ErrorMessage error={formState.errors?.email} />
            </FormControl>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.firstName}>
                <FormLabel htmlFor="firstName">{t('FirstName')}</FormLabel>
                <Input id="firstName" type="text" {...register('firstName')} />
                <ErrorMessage error={formState.errors?.firstName} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.lastName}>
                <FormLabel htmlFor="lastName">{t('LastName')}</FormLabel>
                <Input id="name" type="text" {...register('lastName')} />
                <ErrorMessage error={formState.errors?.lastName} />
              </FormControl>
            </Stack>
          </Stack>
        </fieldset>
        {isEditing && (
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <Checkbox
              isChecked={defaultValues.isContact}
              onChange={(e): void => handleSetCompanyContact(e, defaultValues.id || '')}
            >
              {t('SetContactAsRepresentative')}
            </Checkbox>
          </Stack>
        )}
        <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
          <Button
            isDisabled={!shouldCancel}
            type="button"
            variant="secondary"
            width="full"
            onClick={handleFormCancel}
          >
            {t('Delete')}
          </Button>
          <Button isDisabled={isDisabled} type="submit" width="full">
            {t('Validate')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export type NaturalCompanyAccessFormProps = Props;
