import {
  BoxProps,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Stack,
} from '@chakra-ui/react';

import { ErrorQueryCard } from './ErrorQueryCard';

type Props = BoxProps;

export function ErrorQueryModal({ ...props }: Props): JSX.Element {
  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalBody>
        <Stack py="4" spacing="2" {...props}>
          <ErrorQueryCard h="full" w="full" />
        </Stack>
      </ModalBody>
    </ModalContent>
  );
}

export type ErrorQueryModalProps = Props;
