import { FormControl, FormLabel, HStack, InputGroup, Text } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { MergedQuestionText } from '@blockpulse3/graphql/hooks';
import { ExternalLink, Input } from '@blockpulse3/ui/commons';

import { QuestionTooltip } from '../QuestionTooltip';
import { useMifidModal } from '../provider';

type Props = {
  /* ** Current question ** */
  question: MergedQuestionText;
};

export function TextQuestion({ question }: Props): JSX.Element {
  const t = useTranslations();

  const { questionId, label, tooltip, externalLink } = question;

  const { isSectionDisabled } = useMifidModal();
  const { setValue, control } = useFormContext();

  const handleChange = (value: string): void => {
    setValue(questionId, [value]);
  };

  return (
    <Controller
      key={questionId}
      control={control}
      name={questionId}
      render={({ field }): JSX.Element => (
        <FormControl my="2">
          <FormLabel htmlFor={questionId}>
            <HStack spacing="1">
              <Text>
                {label}
                {externalLink && <ExternalLink link={externalLink} mx="1" title={t('LearnMore')} />}
                {tooltip && <QuestionTooltip label={tooltip} />}
              </Text>
            </HStack>
          </FormLabel>
          <InputGroup w={{ base: 'full', md: '60%' }}>
            <Input
              defaultValue={field.value}
              id={questionId}
              isDisabled={isSectionDisabled}
              type="text"
              onChange={(event): void => handleChange(event.target.value)}
            />
          </InputGroup>
        </FormControl>
      )}
    />
  );
}
