export enum SubscriptionsTabsStatus {
  ALL_STATUS = 'ALL_STATUS',
  NOT_SIGNED_UP = 'NOT_SIGNED_UP',
  SIGNED_UP = 'SIGNED_UP',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  APPROVED = 'APPROVED',
  FINALIZED = 'FINALIZED',
  REFUSED = 'REFUSED',
  VESTING = 'VESTING',
  EXERCISED = 'EXERCISED',
}
