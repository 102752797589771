import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { TableContainer } from '@blockpulse3/ui/commons';

import { OperationRow } from '../types';
import { OperationTableRow } from './OperationTableRow';

dayjs.extend(LocalizedFormat);

type Props = {
  operations: OperationRow[];
};

/**
 * OperationTable.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationTable({ operations }: Props): JSX.Element {
  const t = useTranslations();

  const isSpace = useMatch(routes.space.href + '/*');

  return (
    <TableContainer maxHeight={isSpace ? 'none' : '400px'}>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>{t('Operation', { nb: 1 }).toLowerCase()}</Th>
            {isSpace && (
              <>
                <Th>{t('Action', { nb: 2 })}</Th>
                <Th>{t('Status', { nb: 1 })}</Th>
                <Th>{t('CompanyName')}</Th>
              </>
            )}
            <Th isNumeric>{t('Amount', { nb: 1 })}</Th>
            <Th>{t('Subscriber', { nb: 3 })}</Th>
            <Th>{t('EndDate')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {operations.map((operation) => (
            <OperationTableRow key={operation.id} operation={operation} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export type OperationTableProps = Props;
