import { Card, CardBody, CardHeader, Heading, Skeleton, Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetSubscriptionQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionOperationInformations } from './SubscriptionOperationInformations';
import { SubscriptionProgression } from './SubscriptionProgression';

type Props = unknown;

export function SubscriptionInformations(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });

  if (loading || !data) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="200px" />;
  }

  return (
    <Card>
      <CardHeader>
        <Heading size="md">{t('GlobalInformation')}</Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing="3">
          <SubscriptionProgression />
          <SubscriptionOperationInformations />
        </Stack>
      </CardBody>
    </Card>
  );
}

export type SubscriptionProgressionProps = Props;
