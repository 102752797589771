import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputRightAddon,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { useState } from 'react';
import { Controller, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { IntlUboStatusValues } from '@blockpulse3/data/locales/types';
import { nationalityOptions, noop } from '@blockpulse3/data/shared';
import { UboRole } from '@blockpulse3/graphql/hooks';
import { alpha3CountryOptions, formatCountry, formatNationality } from '@blockpulse3/helpers';
import { ErrorMessage, Input } from '@blockpulse3/ui/commons';
import { useAuthUser, useManagedIndividual } from '@blockpulse3/web-client/auth';

import { schemaUbo, uboFormDefaultValues } from './schema';
import { ICompanyUboForm } from './types';

type Props = {
  /* ** Is the switch to prefill user data disabled ** */
  isUserSwitchDisabled: boolean;
  /* ** Default form values ** */
  defaultValues?: ICompanyUboForm;
  /* ** Max sharePercentage value. `100 - (sum of previous ubos)` ** */
  maxSharePercentage: number;
  /* ** Callback on form cancel ** */
  onCancel?: () => void;
  /* ** Callback on form submit ** */
  onSubmit?: (data: ICompanyUboForm) => void;
};

export function CompanyUboForm({
  isUserSwitchDisabled,
  defaultValues = uboFormDefaultValues,
  maxSharePercentage,
  onCancel = noop,
  onSubmit = noop,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nUboStatus = useTranslations('UboStatusValues');

  const { user: authUser } = useAuthUser();
  const { individual } = useManagedIndividual();

  const [isUserChecked, setIsUserChecked] = useState<boolean>(
    individual?.id === defaultValues.individualIdentityId,
  );

  const { register, control, formState, watch, setValue, reset, handleSubmit } =
    useForm<ICompanyUboForm>({
      defaultValues,
      resolver: yupResolver(schemaUbo) as Resolver<ICompanyUboForm>,
    });

  const isRepresentativeStatus = watch('role') === UboRole.LegalRepresentative;

  const handleUserSwitch = (): void => {
    if (!individual) {
      return;
    }

    if (!isUserChecked) {
      setValue('individualIdentityId', individual.id);
      setValue('firstName', individual.firstName || '');
      setValue('lastName', individual.lastName || '');
      setValue('birthdate', individual.birthdate || '');
      setValue('birthplace', individual.birthplace || '');
      setValue('birthCityPostalCode', individual.birthCityPostalCode || '');
      setValue('birthCountry', {
        label: formatCountry(individual.birthCountry || 'FRA'),
        value: individual.birthCountry || 'FRA',
      });
      setValue('nationality', {
        label: formatNationality(individual.nationality || 'FRA'),
        value: individual.nationality || 'FRA',
      });
      setValue('address.line', individual.address?.line || '');
      setValue('address.city', individual.address?.city || '');
      setValue('address.postalCode', individual.address?.postalCode || '');
      setValue('address.country', {
        label: formatCountry(individual.address?.country || 'FRA'),
        value: individual.address?.country || 'FRA',
      });
      setIsUserChecked(true);
    } else {
      reset({ ...uboFormDefaultValues });
      setIsUserChecked(false);
    }
  };

  const handleFormCancel = (): void => {
    onCancel();
  };

  const handleFormSubmit: SubmitHandler<ICompanyUboForm> = (data): void => {
    onSubmit(data);
  };

  const isUserIndividual = authUser?.individualIdentity?.id === individual?.id;

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing="8">
        <Stack spacing="4">
          <Stack direction={{ base: 'column', md: 'row' }}>
            <FormControl alignItems="center" display="flex" isDisabled={isUserSwitchDisabled}>
              <Switch id="isUser" isChecked={isUserChecked} onChange={handleUserSwitch} />
              <FormLabel htmlFor="isUser" mb="0" ml="2">
                <Text>
                  {t.rich(isUserIndividual ? 'ItsMe' : 'ItsName', {
                    name: `${individual?.firstName} ${individual?.lastName}`,
                    important: (chunk) => (
                      <Text as="span" fontWeight="bold">
                        {chunk}
                      </Text>
                    ),
                  })}
                </Text>
              </FormLabel>
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.firstName}>
              <FormLabel htmlFor="uboFirstName">{t('FirstName')}</FormLabel>
              <Input id="uboFirstName" type="text" {...register('firstName')} />
              <ErrorMessage error={formState.errors?.firstName} />
            </FormControl>
            <FormControl isInvalid={!!formState.errors?.lastName}>
              <FormLabel htmlFor="uboLastName">{t('LastName')}</FormLabel>
              <Input id="uboLastName" type="text" {...register('lastName')} />
              <ErrorMessage error={formState.errors?.lastName} />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.birthdate}>
              <FormLabel htmlFor="uboBirthdate">{t('BirthDate')}</FormLabel>
              <Input id="uboBirthdate" type="date" {...register('birthdate')} />
              <ErrorMessage error={formState.errors?.birthdate} />
            </FormControl>
            <FormControl isInvalid={!!formState.errors?.birthplace}>
              <FormLabel htmlFor="uboBirthplace">{t('BirthCity')}</FormLabel>
              <Input id="uboBirthplace" type="text" {...register('birthplace')} />
              <ErrorMessage error={formState.errors?.birthplace} />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.birthCityPostalCode}>
              <FormLabel htmlFor="uboBirthCityPostalCode">{t('BirthPostalCode')}</FormLabel>
              <Input
                id="uboBirthCityPostalCode"
                type="string"
                {...register('birthCityPostalCode')}
              />
              <ErrorMessage error={formState.errors?.birthCityPostalCode} />
            </FormControl>
            <Controller
              control={control}
              name={'birthCountry'}
              render={({ field }): JSX.Element => (
                <FormControl isInvalid={!!formState.errors.birthCountry}>
                  <FormLabel htmlFor="uboBirthCountry">{t('BirthCountry')}</FormLabel>
                  <Select
                    id="uboBirthCountry"
                    menuPlacement="auto"
                    options={alpha3CountryOptions}
                    {...field}
                  />
                  <ErrorMessage error={formState.errors?.birthCountry?.value} />
                </FormControl>
              )}
            />
          </Stack>
          <Stack>
            <Controller
              control={control}
              name="nationality"
              render={({ field }): JSX.Element => (
                <FormControl
                  isInvalid={!!formState.errors?.nationality}
                  w={{ base: 'full', md: '49%' }}
                >
                  <FormLabel htmlFor="uboNationality">{t('Nationality')}</FormLabel>
                  <Select
                    id="uboNationality"
                    menuPlacement="auto"
                    options={nationalityOptions}
                    placeholder={t('ExampleNationality')}
                    {...field}
                  />
                  <ErrorMessage error={formState.errors?.nationality?.value} />
                </FormControl>
              )}
            />
          </Stack>
          <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
            <Text fontSize="lg" fontWeight="bold">
              {t('Residence')}
            </Text>
            <Stack spacing="4">
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.address?.line}>
                  <FormLabel htmlFor="uboLine">{t('Address', { nb: 1 })}</FormLabel>
                  <Input id="uboLine" type="string" {...register('address.line')} />
                  <ErrorMessage error={formState.errors?.address?.line} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.address?.city}>
                  <FormLabel htmlFor="uboCity">{t('City')}</FormLabel>
                  <Input id="uboCity" type="string" {...register('address.city')} />
                  <ErrorMessage error={formState.errors?.address?.city} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.address?.postalCode}>
                  <FormLabel htmlFor="uboPostalCode">{t('PostalCode', { nb: 1 })}</FormLabel>
                  <Input id="uboPostalCode" type="string" {...register('address.postalCode')} />
                  <ErrorMessage error={formState.errors?.address?.postalCode} />
                </FormControl>
                <Controller
                  control={control}
                  name="address.country"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors?.address?.country}>
                      <FormLabel htmlFor="uboCountry">{t('Country')}</FormLabel>
                      <Select
                        id="uboCountry"
                        menuPlacement="auto"
                        options={alpha3CountryOptions}
                        {...field}
                      />
                      <ErrorMessage error={formState.errors?.address?.country?.value} />
                    </FormControl>
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <Controller
              control={control}
              name={'role'}
              render={({ field }): JSX.Element => (
                <FormControl isInvalid={!!formState.errors?.role}>
                  <FormLabel htmlFor="uboRole">{t('Status', { nb: 1 })} </FormLabel>
                  <RadioGroup id="uboRole" {...field}>
                    <Stack>
                      {Object.keys(UboRole).map((role) => (
                        <Radio key={role} value={role}>
                          {i18nUboStatus(role as IntlUboStatusValues)}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                  <ErrorMessage error={formState.errors?.role} />
                </FormControl>
              )}
            />
          </Stack>
          {!isRepresentativeStatus && (
            <Stack spacing="4">
              <FormControl isInvalid={!!formState.errors?.sharePercentage}>
                <FormLabel htmlFor="uboSharePercentage">
                  {t('PercentageOfSocialCapitalHeld')}
                </FormLabel>
                <InputGroup>
                  <Input
                    id="uboSharePercentage"
                    max={maxSharePercentage}
                    step="0.01"
                    type="number"
                    {...register('sharePercentage')}
                  />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
                <ErrorMessage error={formState.errors?.sharePercentage} />
              </FormControl>
              <FormControl>
                <Checkbox {...register('isDirect')}>
                  <Text>{t('CapitalDirectlyHeld')}</Text>
                </Checkbox>
              </FormControl>
            </Stack>
          )}
        </Stack>
        <HStack justifyContent="space-between">
          <Button
            type="button"
            variant="secondary"
            w="full"
            onClick={(): void => handleFormCancel()}
          >
            {t('Delete')}
          </Button>
          <Button type="submit" w="full">
            {t('Validate')}
          </Button>
        </HStack>
      </Stack>
    </form>
  );
}

export type CompanyUboFormProps = Props;
