import {
  AspectRatio,
  HStack,
  Icon,
  Link,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PlayIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { ResponsiveModal } from './ResponsiveModal';

type Props = {
  videoLink: string;
};

export function VideoLinkModal({ videoLink }: Props): JSX.Element {
  const t = useTranslations();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <HStack>
      <Icon
        as={PlayIcon}
        backgroundColor="gray.50"
        borderRadius="100px"
        boxSize="30px"
        color="gray.500"
        p="4px"
      />
      <Link onClick={onOpen}>
        <Text fontSize="sm" textDecoration="underline">
          {t('PresentationVideo')}
        </Text>
      </Link>
      <ResponsiveModal isOpen={isOpen} scrollBehavior="outside" size="3xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('PresentationVideo')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AspectRatio ratio={16 / 9}>
              <iframe allowFullScreen src={videoLink || ''} title="video" />
            </AspectRatio>
          </ModalBody>
        </ModalContent>
      </ResponsiveModal>
    </HStack>
  );
}
