import { Grid, GridItem, Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { SpaceType } from '@blockpulse3/graphql/hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';
import { OperationList } from '@blockpulse3/web-client/dashboard';

import { SpaceHeader } from '../SpaceHeader';
import { ManagedCompaniesList } from './ManagedCompaniesList';
import { ManagedSpacesList } from './ManagedSpacesList';

type Props = unknown;

export function SpaceDashboard(): JSX.Element {
  const t = useTranslations();

  const { spaceType } = useAuthUser();

  const displayManagedCompanies = spaceType !== SpaceType.GLOBAL;
  const displayManagedSpaces = spaceType === SpaceType.PROVIDER;
  const displaySide = displayManagedCompanies || displayManagedSpaces;

  return (
    <Grid
      gap="10"
      gridTemplateColumns={{ base: '1fr', xl: '2fr 1fr' }}
      minHeight="0"
      minWidth="0"
      templateAreas={{
        base: `"header"
          "main"
          "side"`,
        xl: displaySide
          ? `"header header"
          "main side"`
          : `"header header"
          "main main"`,
      }}
    >
      <GridItem area="header" minWidth="0">
        <SpaceHeader />
      </GridItem>
      <GridItem area="main" minWidth="0">
        <Stack spacing="4">
          <Heading size="md">{t('CurrentManagedOperations')}</Heading>
          <OperationList displayType="cards" limit={3} />
        </Stack>
      </GridItem>
      {displaySide && (
        <GridItem area="side" minWidth="0">
          <Stack spacing="4">
            {displayManagedCompanies && <ManagedCompaniesList />}
            {displayManagedSpaces && <ManagedSpacesList />}
          </Stack>
        </GridItem>
      )}
    </Grid>
  );
}

export type SpaceDashboardProps = Props;
