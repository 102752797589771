import { useParams } from 'react-router-dom';

import { useGetCompanyQuery } from '@blockpulse3/graphql/hooks';

import { RepatriateOperationAlert } from './RepatriateOperationAlert';
import { ResumeOperationRepatriationAlert } from './ResumeOperationRepatriationAlert';

export function AssetAndOptionAlert(): JSX.Element | null {
  const { companyId = '' } = useParams();

  const { data, loading, error } = useGetCompanyQuery({
    variables: {
      companyId,
    },
  });

  if (loading || error || !data) return null;

  const { company } = data;

  // Resume current repatriation if any
  if (company.currentRepatriation) {
    return <ResumeOperationRepatriationAlert />;
  }

  // Repatriate a new operation
  return <RepatriateOperationAlert />;
}
