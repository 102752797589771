import { HStack, Icon, Text } from '@chakra-ui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

export function UserVerificationPending(): JSX.Element {
  const t = useTranslations();

  return (
    <HStack color="orange.400" justify="center" pt="5">
      <Icon as={DotsHorizontalIcon} boxSize="24px" />
      <Text fontSize="lg" fontWeight="500">
        {t('YourIdentityVerificationInProgress')}
      </Text>
    </HStack>
  );
}
