import { useDisclosure } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { OperationStatus } from '@blockpulse3/graphql/hooks';
import { CompleteOperationModal } from '@blockpulse3/web-client/shareholding';

import { useGetOperations } from '../../hook';
import { OperationRow } from '../../types';
import { OperationCardTemplate } from './OperationCardTemplate';

type Props = {
  operation: OperationRow;
  operationSubtitle?: string;
};

export function OperationCardUnknown({ operation, operationSubtitle }: Props): JSX.Element {
  const t = useTranslations();

  const { operationsRefetch } = useGetOperations(OperationStatus.FINALIZED);

  const completeOperationModal = useDisclosure();

  const handleOperationCompleted = (): void => {
    operationsRefetch();
    completeOperationModal.onClose();
  };

  const handleRowClick = (): void => {
    completeOperationModal.onOpen();
  };

  return (
    <>
      <OperationCardTemplate
        isIncomplete={true}
        operation={operation}
        operationSubtitle={operationSubtitle || t('IncompleteF')}
        onClick={handleRowClick}
      />
      {completeOperationModal.isOpen && (
        <CompleteOperationModal
          isOpen={completeOperationModal.isOpen}
          operationId={operation.id}
          onClose={completeOperationModal.onClose}
          onCompleted={handleOperationCompleted}
        />
      )}
    </>
  );
}
