import * as yup from 'yup';

import { FormErrors, requiredNumberField } from '@blockpulse3/data/shared';

import { FinancialInformationsForm } from './types';

export const financialInformationsSchema = yup.object({
  shareCapital: requiredNumberField,
  compensation: yup
    .number()
    .typeError(FormErrors.RequiredField)
    .min(0, FormErrors.PositiveFormat)
    .required(FormErrors.RequiredField),
  carriedInterest: yup
    .number()
    .typeError(FormErrors.RequiredField)
    .min(0, FormErrors.CarriedPercentageFormat)
    .max(30, FormErrors.CarriedPercentageFormat)
    .required(FormErrors.RequiredField),
  preferredSharesPercentage: yup.number().when('carriedInterest', {
    is: (carriedInterest: number) => carriedInterest > 0,
    then: (schema) =>
      schema
        .typeError(FormErrors.RequiredField)
        .positive(FormErrors.PositiveFormat)
        .min(0.01, FormErrors.PercentageFormat)
        .max(100, FormErrors.PercentageFormat)
        .required(FormErrors.RequiredField),
  }),
}) as yup.ObjectSchema<FinancialInformationsForm>;
