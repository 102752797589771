import { useDisclosure } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { ContractType, routes } from '@blockpulse3/data/shared';
import {
  AssetInfosFragment,
  AssetStatus,
  AssetType,
  OperationType,
  useDeleteAssetMutation,
  useGetBondAssetsLazyQuery,
  useGetCompanyQuery,
  useGetOptionAssetsLazyQuery,
  useGetStockAssetsLazyQuery,
} from '@blockpulse3/graphql/hooks';
import { DeleteConfirmModal, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

import { CompleteOperationModal } from '../';
import { AssetAndOptionCards } from './AssetAndOptionCards';
import { AssetAndOptionTable } from './AssetAndOptionTable';

type Props = {
  displayType?: 'table' | 'cards';
  stocks?: AssetInfosFragment[];
  bonds?: AssetInfosFragment[];
  options?: AssetInfosFragment[];
};

export function AssetAndOptionList({
  displayType = 'table',
  stocks,
  bonds,
  options,
}: Props): JSX.Element | null {
  const t = useTranslations();

  const confirmRef = useRef(null);
  const completeOperationModal = useDisclosure();
  const deleteAssetModal = useDisclosure();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const navigate = useNavigate();
  const location = useLocation();
  const { companyId = '' } = useParams();

  const [asset, setAsset] = useState<AssetInfosFragment | undefined>(undefined);

  const [deleteAsset, { loading: isDeleteLoading }] = useDeleteAssetMutation();

  const [, { refetch: refetchStocks }] = useGetStockAssetsLazyQuery({
    variables: { companyId: companyId || '' },
  });

  const [, { refetch: refetchOptions }] = useGetOptionAssetsLazyQuery({
    variables: { companyId: companyId || '' },
  });

  const [, { refetch: refetchBonds }] = useGetBondAssetsLazyQuery({
    variables: { companyId: companyId || '' },
  });

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const currentRepatriation = companyReq.data?.company?.currentRepatriation;

  const isInCaptable = matchPath(
    routes.company.href + '/' + routes.company.captable.href + '/*',
    location.pathname,
  );

  const handleCompleteOperationModalClose = (): void => {
    completeOperationModal.onClose();
    setAsset(undefined);
  };

  const handleOperationCompleted = (operationId: string): void => {
    if (!asset) return;

    switch (asset?.contractType) {
      case ContractType.OPTION_TOKEN:
        handleOptionNavigation(operationId);
        break;
      case ContractType.BOND_TOKEN:
        if (asset.operation?.type) {
          handleBondNavigation(operationId, asset.operation.type);
        }
    }

    handleCompleteOperationModalClose();
  };

  const handleRepatriateOperation = (operationId: string): void => {
    navigate(
      generatePath(routes.company.newOperation.repatriation.edit.full, {
        companyId,
        operationId,
      }),
    );
  };

  const handleStockClick = (): void => {
    if (isInCaptable) {
      navigate('..', { relative: 'path' });
    } else {
      navigate(routes.company.captable.href);
    }
  };

  const handleOptionNavigation = (operationId: string): void => {
    navigate(
      generatePath(routes.company.optionPool.full, {
        companyId,
        operationId,
      }),
    );
  };

  const handleOptionClick = (asset: AssetInfosFragment): void => {
    const { operation } = asset;
    const isUnknownOperation = asset.operation?.type === OperationType.UNKNOWN;

    if (
      asset.status !== AssetStatus.DRAFT &&
      (isUnknownOperation || !operation?.id || !operation?.sharePrice)
    ) {
      setAsset(asset);
      completeOperationModal.onOpen();
    } else if (operation?.id) {
      if (asset.status === AssetStatus.DRAFT && operation.id === currentRepatriation) {
        handleRepatriateOperation(operation.id);
      } else {
        handleOptionNavigation(operation.id);
      }
    }
  };

  const handleBondNavigation = (operationId: string, type: OperationType): void => {
    const pathPrefix = isInCaptable ? '../' : '';
    const routeSlug = type === OperationType.OPPORTUNITY ? 'opportunity' : 'fundraising';
    navigate(
      pathPrefix +
        generatePath(routes.company[routeSlug].href, {
          companyId,
          operationId,
        }),
    );
  };

  const handleBondClick = (asset: AssetInfosFragment): void => {
    const { operation } = asset;

    if (asset.status !== AssetStatus.DRAFT && (!operation?.id || !operation?.sharePrice)) {
      setAsset(asset);
      completeOperationModal.onOpen();
    } else if (operation?.id) {
      if (asset.status === AssetStatus.DRAFT && operation.id === currentRepatriation) {
        handleRepatriateOperation(operation.id);
      } else {
        handleBondNavigation(operation.id, operation.type);
      }
    }
  };

  const handleDeleteModalOpen = (asset: AssetInfosFragment): void => {
    setAsset(asset);
    deleteAssetModal.onOpen();
  };

  const handleModalClose = (): void => {
    completeOperationModal.onClose();
    deleteAssetModal.onClose();
    setAsset(undefined);
  };

  const handleDeleteAsset = (): void => {
    if (!asset || !companyId) {
      return;
    }

    deleteAsset({
      variables: { deleteAssetInput: { companyId, assetId: asset.id } },
      onCompleted: () => {
        successToast({ title: t('AssetDeleted') });
        if (asset.assetType === AssetType.BOND) {
          refetchBonds();
        } else if (
          [AssetType.ORDINARY_SHARE, AssetType.PREFERRED_SHARE].includes(asset.assetType)
        ) {
          refetchStocks();
        } else {
          refetchOptions();
        }
        handleModalClose();
      },
      onError: () => {
        errorToast({ title: t('AssetDeletionError') });
      },
    });
  };

  return (
    <>
      {displayType === 'cards' ? (
        <AssetAndOptionCards
          bonds={bonds}
          handleBondClick={handleBondClick}
          handleDeleteModalOpen={handleDeleteModalOpen}
          handleOptionClick={handleOptionClick}
          handleStockClick={handleStockClick}
          options={options}
          stocks={stocks}
        />
      ) : (
        <AssetAndOptionTable
          bonds={bonds}
          handleBondClick={handleBondClick}
          handleDeleteModalOpen={handleDeleteModalOpen}
          handleOptionClick={handleOptionClick}
          handleStockClick={handleStockClick}
          options={options}
          stocks={stocks}
        />
      )}
      {completeOperationModal.isOpen && !!asset && (
        <CompleteOperationModal
          assetId={asset.id}
          isOpen={completeOperationModal.isOpen}
          operationId={asset.operation?.id}
          operationType={asset.operation?.type}
          onClose={handleCompleteOperationModalClose}
          onCompleted={handleOperationCompleted}
        />
      )}
      {deleteAssetModal.isOpen && !!asset && (
        <DeleteConfirmModal
          isLoading={isDeleteLoading}
          isOpen={deleteAssetModal.isOpen}
          leastDestructiveRef={confirmRef}
          subtitle={t('DefinitiveAction')}
          title={t('DeleteAsset')}
          onClose={handleModalClose}
          onDelete={handleDeleteAsset}
        />
      )}
    </>
  );
}

export type AssetAndOptionListProps = Props;
