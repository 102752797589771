import { InfoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Icon,
  ListItem,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
} from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { DocumentDownloadIcon, LightningBoltIcon } from '@heroicons/react/solid';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  IdentityType,
  SubscriptionSide,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { IconBoxWrapper } from '@blockpulse3/ui/commons';
import { useIdentity, useManagedIndividual } from '@blockpulse3/web-client/auth';

import { IVerificationType } from './types';

type Props = {
  setVerificationType: React.Dispatch<React.SetStateAction<IVerificationType>>;
};

export function SelectVerificationType({ setVerificationType = noop }: Props): JSX.Element {
  const t = useTranslations();

  const { individual } = useManagedIndividual();

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const { data } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = data?.subscription;
  const subscriptionIdentity =
    subscription?.side === SubscriptionSide.SELLER
      ? subscription?.sellerIdentity
      : subscription?.buyerIdentity;
  const presidentIndividualIdentity = subscriptionIdentity?.companyIdentity?.kybIndividualIdentity;

  const handleUbbleVerification = (): void => {
    setVerificationType('ubble');
  };

  const handleDocumentVerification = (): void => {
    setVerificationType('document');
  };

  const shouldDisplayBanner = (): boolean => {
    if (!data?.subscription) {
      return false;
    }

    if (subscriptionIdentity?.type === IdentityType.INDIVIDUAL) {
      return false;
    }

    return presidentIndividualIdentity?.id !== individual?.id;
  };

  /* ** Clear way to return default values in auth case section (no subscriptionId) ** */
  const shouldBlockVerification = shouldDisplayBanner();
  const presidentName = presidentIndividualIdentity?.name || '';

  return (
    <Stack spacing="5" w="full">
      {shouldBlockVerification && (
        <Alert status="warning">
          <AlertIcon as={ExclamationIcon} />
          <AlertTitle>
            {t.rich('RepresentativeIdentityToVerify', {
              identity: presidentName,
              important: (chunk) => (
                <Text as="span" fontWeight="bold">
                  {chunk}
                </Text>
              ),
            })}
          </AlertTitle>
        </Alert>
      )}
      <Button
        isDisabled={shouldBlockVerification}
        pl="24px"
        variant="container"
        onClick={handleUbbleVerification}
      >
        <IconBoxWrapper mr="24px">
          <Icon as={LightningBoltIcon} boxSize="20px" color="indigo.600" minW="50px" />
        </IconBoxWrapper>
        <Stack>
          <Text>{t('InstantMobileVerification')}</Text>
          <UnorderedList color="gray.700" fontSize="sm" fontWeight="light" pl="1.5">
            <ListItem>{t('OriginalIDRequiredCameraPhone')}</ListItem>
            <ListItem>{t('StepValidationMinutes')}</ListItem>
          </UnorderedList>
        </Stack>
      </Button>
      <Button
        isDisabled={shouldBlockVerification}
        pl="24px"
        variant="container"
        onClick={handleDocumentVerification}
      >
        <IconBoxWrapper mr="24px">
          <Icon as={DocumentDownloadIcon} boxSize="20px" color="gray.700" minW="50px" />
        </IconBoxWrapper>
        <Stack>
          <Text>{t('ManualVerification')}</Text>
          <UnorderedList color="gray.700" fontSize="sm" fontWeight="light" pl="1.5">
            <ListItem>{t('DocumentsTransmittedInPDF')}</ListItem>
            <HStack>
              <ListItem>{t('HaveTwoFormsOfID')}</ListItem>
              <Tooltip
                hasArrow
                placement="top"
                label={
                  <UnorderedList>
                    <ListItem>{t('FirstPiecePassportOrID')}</ListItem>
                    <ListItem>{t('SecondPiece')}</ListItem>
                  </UnorderedList>
                }
              >
                <Icon as={InfoIcon} color="gray.600" />
              </Tooltip>
            </HStack>
            <ListItem>{t('StepValidation24to48h')}</ListItem>
          </UnorderedList>
        </Stack>
      </Button>
    </Stack>
  );
}
