import * as yup from 'yup';

import { requiredNumberField, requiredPastDateField } from '@blockpulse3/data/shared';

import { IAssetPrice } from './type';

export const assetPriceFormSchema = yup.object({
  price: requiredNumberField,
  date: requiredPastDateField,
  label: yup.string().optional(),
}) as yup.ObjectSchema<IAssetPrice>;
