import { DocumentInfosFragment } from '@blockpulse3/graphql/hooks';

/**
 * getDocumentIds.
 * Return subscription document ids.
 *
 * @param {Array} documents
 * @returns {Record<string, string>}
 */
export function getDocumentIds(documents: DocumentInfosFragment[]): Record<string, string> {
  return [...documents]
    .sort((doc1, doc2) => (doc1.type < doc2.type ? -1 : 1))
    .reduce((acc, cur) => ({ ...acc, [cur.type]: cur.id || '' }), {});
}
