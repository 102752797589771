import { PathMatch, useMatch, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  CompanyCapitalType,
  FundraisingType,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';

type IFundraisingRoutes = typeof routes.company.newFundraising;
type UseFundraisingSteps = {
  /* ** Current step number ** */
  progress: number;
  /* ** Available steps ** */
  activeSteps: {
    label: string;
    value: PathMatch<string> | null;
  }[];
};

/**
 * useFundraisingSteps.
 * Build available steps depending on the company capital type and the type of the fundraising.
 * `activeSteps` looks for the first PathMatch, that is the current step from the URL.
 *
 * @param {IRoutes['private'] | IRoutes['crowdfunding']} fundraisingRoutes
 * @param {FundraisingType} type
 * @returns {UseFundraisingSteps}
 */
export function useFundraisingSteps(
  fundraisingRoutes: IFundraisingRoutes['private'] | IFundraisingRoutes['crowdfunding'],
  type: FundraisingType,
): UseFundraisingSteps {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const isPrivate = type === FundraisingType.PRIVATE;
  const isFixedCapital = company?.capitalType === CompanyCapitalType.FIXED;

  const isCreateActive = useMatch(fundraisingRoutes.full);
  const isCreateFromActive = useMatch(routes.company.newFundraising.private.fromOpportunity.full);
  const isParametersActive = useMatch(fundraisingRoutes.edit.full);
  const isDocumentationActive = useMatch(
    fundraisingRoutes.edit.full + '/' + fundraisingRoutes.edit.documentation.href,
  );
  const isIBANActive = useMatch(
    fundraisingRoutes.edit.full + '/' + fundraisingRoutes.edit.iban.href,
  );
  const isSubscribersActive = useMatch(
    fundraisingRoutes.edit.full + '/' + routes.company.newFundraising.private.edit.subscribers.href,
  );
  const isSummaryActive = useMatch(
    fundraisingRoutes.edit.full + '/' + fundraisingRoutes.edit.summary.href,
  );

  const activeSteps = [
    {
      label: t('FundraisingSettings'),
      value: isParametersActive || isCreateActive || isCreateFromActive,
    },
    { label: t('Documentation'), value: isDocumentationActive },
    ...(isFixedCapital ? [{ label: t('BankInfos'), value: isIBANActive }] : []),
    ...(isPrivate ? [{ label: t('Subscriber', { nb: 1 }), value: isSubscribersActive }] : []),
    { label: t('Summary'), value: isSummaryActive },
  ];

  const progress = activeSteps.findIndex((v) => v.value);

  return { progress, activeSteps };
}
