import * as yup from 'yup';

import {
  requiredBirthdateField,
  requiredSelectField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { formatCountry } from '@blockpulse3/helpers';

import { IUpdateProfileForm } from './types';

export const schema = yup
  .object({
    firstName: requiredStringField,
    lastName: requiredStringField,
    birthdate: requiredBirthdateField,
    birthplace: requiredStringField,
    birthCityPostalCode: requiredStringField,
    birthCountry: requiredSelectField,
    nationality: requiredSelectField,
    gender: requiredStringField,
  })
  .required() as yup.ObjectSchema<IUpdateProfileForm>;

export const profileDefaultValues: IUpdateProfileForm = {
  profilePicture: '',
  firstName: '',
  lastName: '',
  birthdate: '',
  birthplace: '',
  birthCityPostalCode: '',
  birthCountry: { label: formatCountry('FRA'), value: 'FRA' },
  nationality: { label: '', value: '' },
  gender: '',
};
