import { Badge, BadgeProps, Text, ThemeTypings } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { OperationStatus } from '@blockpulse3/graphql/hooks';

type Props = {
  /* ** Status of the operation ** */
  operationStatus: OperationStatus;
  /* ** All in workflow status ** */
  isAllInSubscriptionWorkflow?: boolean;
} & BadgeProps;

/**
 * OperationBadge.
 * Rendering a badge of desired operation status.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationBadge({
  operationStatus,
  isAllInSubscriptionWorkflow = false,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const getBadgeSettings = (
    operationStatus: OperationStatus,
    isAllInSubscriptionWorkflow: boolean,
  ): { label: string; colorScheme: ThemeTypings['colorSchemes'] } => {
    const BADGE_MAP: Partial<
      Record<
        OperationStatus,
        {
          label: string;
          colorScheme: ThemeTypings['colorSchemes'];
        }
      >
    > = {
      [OperationStatus.CLOSED]: {
        label: t(isAllInSubscriptionWorkflow ? 'ClosedAllInF' : 'ClosedF'),
        colorScheme: 'orange',
      },
      [OperationStatus.STARTED]: {
        label: t('InProgress'),
        colorScheme: 'yellow',
      },
      [OperationStatus.REVIEWED]: {
        label: t('RevisedF'),
        colorScheme: 'blue',
      },
      [OperationStatus.FINALIZED]: {
        label: t('FinalizedF'),
        colorScheme: 'green',
      },
    };

    return (
      BADGE_MAP[operationStatus] || {
        label: t('Draft', { nb: 1 }),
        colorScheme: 'gray',
      }
    );
  };

  const { label, colorScheme } = getBadgeSettings(operationStatus, isAllInSubscriptionWorkflow);

  return (
    <Badge colorScheme={colorScheme} {...props}>
      <Text>{label}</Text>
    </Badge>
  );
}

export type OperationBadgeProps = Props;
