import { Card, CardBody, Stack, useDisclosure } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { SpaceFilterType } from '@blockpulse3/data/shared';
import {
  GetSpacesDocument,
  GetSpacesQuery,
  GetSpacesQueryVariables,
  Space,
  SpaceEntityInfosFragment,
  SpacesFilterInput,
  useDeleteSpaceMutation,
} from '@blockpulse3/graphql/hooks';
import { DeleteConfirmModal, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';
import { usePagination } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { NewSpaceFormModal, UpdateSpaceFormModal } from '../../commons';
import { PAGE_SIZE } from '../../utils';
import { SpaceListControls } from './SpaceListControls';
import { SpaceListTable } from './SpaceListTable';

type Props = {
  showManaged?: boolean;
};

/**
 * SpaceList.
 *
 * @returns {JSX.Element}
 */
export function SpaceList({ showManaged = false }: Props): JSX.Element {
  const t = useTranslations();

  const confirmRef = useRef(null);

  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId: providerId = authSpaceId } = useParams();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const createSpaceModal = useDisclosure();
  const updateSpaceModal = useDisclosure();
  const deleteSpaceModal = useDisclosure();

  const [updateSpaceId, setUpdateSpaceId] = useState<Space['id'] | null>(null);
  const [deleteSpaceId, setDeleteSpaceId] = useState<Space['id'] | null>(null);
  const [filters, setFilters] = useState<SpacesFilterInput[]>([]);

  const [deleteSpace, { loading: isDeleteLoading }] = useDeleteSpaceMutation();

  const paginationProps = usePagination<
    GetSpacesQuery,
    GetSpacesQueryVariables,
    SpaceEntityInfosFragment
  >({
    queryDocument: GetSpacesDocument,
    queryOptions: {
      variables: {
        first: PAGE_SIZE,
        providerId: showManaged ? providerId : undefined,
        filterBy: filters,
      },
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'spaces',
    pageSize: PAGE_SIZE,
  });
  const { refetch, reset } = paginationProps;

  const handleSpaceAddModal = (): void => {
    createSpaceModal.onOpen();
  };

  const handleSpaceAddSuccess = (): void => {
    refetch();
  };

  const handleSpaceUpdateModal = (spaceId: Space['id']): void => {
    setUpdateSpaceId(spaceId);
    updateSpaceModal.onOpen();
  };

  const handleSpaceUpdateSuccess = (): void => {
    setUpdateSpaceId(null);
    updateSpaceModal.onClose();
    refetch();
  };

  const handleSpaceUpdateError = (): void => {
    setUpdateSpaceId(null);
    updateSpaceModal.onClose();
  };

  const handleSpaceDeleteModal = (spaceId: Space['id']): void => {
    setDeleteSpaceId(spaceId);
    deleteSpaceModal.onOpen();
  };

  const handleSpaceDelete = (): void => {
    if (!deleteSpaceId) return;

    deleteSpace({
      variables: { spaceId: deleteSpaceId },
      onCompleted: () => {
        successToast({ title: t('DeleteSpaceSuccess') });
        setDeleteSpaceId(null);
        deleteSpaceModal.onClose();
        refetch();
      },
      onError: () => {
        errorToast({ title: t('DeleteSpaceError') });
        setDeleteSpaceId(null);
        deleteSpaceModal.onClose();
      },
    });
  };

  const handleSearchInputSubmit = (value: string): void => {
    const isInputAndFilterSearchEmpty =
      value === '' && !filters.some((filter) => filter.name === 'searchValue');
    if (isInputAndFilterSearchEmpty) return;

    resetSpecificFilter('searchValue', SpaceFilterType.CUSTOM, value);
  };

  const resetSpecificFilter = (
    filterName: string,
    filterType: SpaceFilterType,
    value: string,
  ): void => {
    const currentFilterWithoutFilterName = filters.filter((filter) => filter.name !== filterName);
    const newFilter: SpacesFilterInput = {
      name: filterName,
      type: filterType,
      value,
    };
    reset();
    setFilters([...currentFilterWithoutFilterName, newFilter]);
  };

  return (
    <Card>
      <CardBody as={Stack} spacing="4">
        <SpaceListControls
          onSearchSubmit={handleSearchInputSubmit}
          onSpaceAdd={handleSpaceAddModal}
        />
        <SpaceListTable
          onRowDeleteClick={handleSpaceDeleteModal}
          onRowUpdateClick={handleSpaceUpdateModal}
          {...paginationProps}
        />
        <NewSpaceFormModal
          isOpen={createSpaceModal.isOpen}
          providerId={showManaged ? providerId : undefined}
          onClose={createSpaceModal.onClose}
          onSubmitSuccess={handleSpaceAddSuccess}
        />
        {updateSpaceId && (
          <UpdateSpaceFormModal
            isOpen={updateSpaceModal.isOpen}
            spaceId={updateSpaceId}
            onClose={updateSpaceModal.onClose}
            onSubmitError={handleSpaceUpdateError}
            onSubmitSuccess={handleSpaceUpdateSuccess}
          />
        )}
        <DeleteConfirmModal
          isLoading={isDeleteLoading}
          isOpen={deleteSpaceModal.isOpen}
          leastDestructiveRef={confirmRef}
          subtitle={t('DefinitiveAction')}
          title={t('ConfirmSpaceDeleteTitle')}
          onClose={deleteSpaceModal.onClose}
          onDelete={handleSpaceDelete}
        />
      </CardBody>
    </Card>
  );
}

export type SpaceListProps = Props;
