import * as yup from 'yup';

import { FormErrors, requiredEmailField } from '@blockpulse3/data/shared';
import { getUserTimezone } from '@blockpulse3/helpers';

import { ILoginForm } from './types';

export const schema = yup
  .object({
    email: requiredEmailField,
    password: yup.string().min(8, FormErrors.PasswordFormat).required(FormErrors.RequiredField),
  })
  .required() as yup.ObjectSchema<ILoginForm>;

export const LoginFormDefaults: ILoginForm = {
  email: '',
  password: '',
  timezone: getUserTimezone(),
};
