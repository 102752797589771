import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  Divider,
  HStack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';

type Props = {
  onSubmit: () => void;
  onClose: () => void;
  leastDestructiveRef: AlertDialogProps['leastDestructiveRef'];
  isLoading?: boolean;
} & Omit<AlertDialogProps, 'onClose' | 'children' | 'leastDestructiveRef'>;

export function OpportunityOperationCloseAlertModal({
  onSubmit,
  onClose,
  isLoading,
  ...props
}: Props): JSX.Element | null {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const { data, error } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });

  if (!data || error) {
    return null;
  }

  const { operation } = data;
  if (operation.data?.__typename !== 'OpportunityData') {
    return null;
  }

  const { totalApprovedAmount } = operation.data;

  return (
    <AlertDialog onClose={onClose} {...props}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="xl" fontWeight="500">
            {t('CloseOperationQuestion')}
          </AlertDialogHeader>
          <AlertDialogBody>
            {t.rich('FinalizeIntentGathering', {
              gatheredAmount: formatNumberCurrency(totalApprovedAmount),
              important: (chunk) => <b>{chunk}</b>,
            })}
          </AlertDialogBody>
          <Divider />
          <AlertDialogFooter as={HStack} spacing="4">
            <Button variant="secondary" onClick={onClose} isDisabled={isLoading}>
              {t('Cancel')}
            </Button>
            <Button onClick={onSubmit} isLoading={isLoading}>
              {t('CloseOperationPrematurely')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export type DeleteConfirmModal = Props;
