import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Heading,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { CompanyStatus, useUpdateCompanyMutation } from '@blockpulse3/graphql/hooks';
import {
  formatDate,
  formatNationality,
  formatNumberCurrency,
  isCompanyComplete,
} from '@blockpulse3/helpers';
import {
  ErrorQueryCard,
  useErrorToast,
  useSuccessToast,
  useWarningToast,
} from '@blockpulse3/ui/commons';

import { SummaryRoleInfos } from '../SummaryRoleInfos';
import { useGetOnboardingExistingCompany } from '../hooks';

/**
 * OnboardingExistingCompanySummaryStep.
 * Summary of the existing company creation.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OnboardingExistingCompanySummary(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const warningToast = useWarningToast();
  const successToast = useSuccessToast();

  const navigate = useNavigate();
  const { companyId = '' } = useParams();

  const { data, loading, error } = useGetOnboardingExistingCompany();

  const [updateCompany] = useUpdateCompanyMutation();

  const isSpaceRegistration = useMatch(routes.manage.href + '/*');

  const handleStepCancel = (): void => {
    navigate('../' + routes.onboardings.company.edit.access.href);
  };

  const handleStepSubmit = (): void => {
    if (isCompanyComplete(data?.company)) {
      updateCompany({
        variables: {
          updateCompanyInput: {
            companyId,
            status: CompanyStatus.RUNNING,
          },
        },
        onCompleted: async () => {
          successToast({ title: t('CompanyAdded') });
          navigate(
            generatePath(isSpaceRegistration ? routes.manage.company.full : routes.company.href, {
              companyId,
            }),
          );
        },
        onError: () => {
          errorToast({ title: t('ErrorAddingCompany') });
        },
      });
    } else {
      warningToast({ title: t('IncompleteCompany') });
      navigate(generatePath(routes.onboardings.company.edit.href, { companyId }));
    }
  };

  if (loading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (error) {
    return <ErrorQueryCard width="full" />;
  }

  if (!data) {
    return <ErrorQueryCard width="full" />;
  }

  return (
    <Card variant="divider-top" width="full">
      <CardHeader>
        <Heading size="lg">{t('Verification')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        <Stack spacing="4">
          <Text fontSize="xl" fontWeight="bold">
            {t('CompanyInformation')} :
          </Text>
          <Stack bg="gray.50" borderRadius="md" p="3" spacing="1">
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('CompanyName')} :
              </Text>
              {data.company.name}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('Nationality')} :
              </Text>
              {formatNationality(data.company.address?.country || 'FRA')}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('RegistrationNumber')} :
              </Text>
              {data.company?.registrationNumber}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('CorporateForm')} :
              </Text>
              {data.company?.corporateForm}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('CreationDate')} :
              </Text>
              {formatDate(data.company?.creationDate, 'D MMMM YYYY')}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('ShareCapital')} :
              </Text>
              {formatNumberCurrency(data.company?.shareCapital || '')}
            </Text>
          </Stack>
        </Stack>
        <SummaryRoleInfos />
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button variant="secondary" w="full" onClick={handleStepCancel}>
          {t('Back')}
        </Button>
        <Button data-cy="next" w="full" onClick={handleStepSubmit}>
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}
