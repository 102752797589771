import { OperationStatus } from '@blockpulse3/graphql/hooks';

/**
 * getOpeationStep.
 * Return operation status as step number.
 *
 * @param {OperationStatus} status
 * @returns {number}
 */
export function getOperationStep(status: OperationStatus): number {
  switch (status) {
    case OperationStatus.CLOSED:
    case OperationStatus.FINALIZED:
      return 3;
    default:
      return 1;
  }
}
