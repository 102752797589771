import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  HStack,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useTranslations } from 'use-intl';

import {
  OperationDocumentType,
  SubscriptionInfosFragment,
  useGetDocumentPdfUrlLazyQuery,
  useGetSubscriptionQuery,
  useRefuseFundsSourceCertificateMutation,
} from '@blockpulse3/graphql/hooks';
import { CONTENT_LOADING, DeleteConfirmModal, PreviewDocumentModal } from '@blockpulse3/ui/commons';

type Props = {
  subscriptionId: SubscriptionInfosFragment['id'];
};

export function ValidateFundsSourceCertificate({ subscriptionId }: Props): JSX.Element | null {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const deleteRef = useRef(null);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const deleteModal = useDisclosure();

  const [documentUrl, setDocumentUrl] = useState<string | symbol | null>(CONTENT_LOADING);

  /* ** Cached subscription query ** */
  const { data, loading, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  /* ** Query to get pdf url of a document ** */
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();

  const [refuseCertificate, { loading: isRefuseCertificateLoading }] =
    useRefuseFundsSourceCertificateMutation();

  const subscription = data?.subscription;
  const subscriptionDocuments = subscription?.subscriptionDocuments || [];

  const fundsSourceCertificates = subscriptionDocuments.filter(
    (doc) => doc.type === OperationDocumentType.FUNDS_SOURCE_CERTIFICATE,
  );

  if (!fundsSourceCertificates) return null;

  const handleOpenClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        setDocumentUrl(pdfUrl);
        onOpen();
      },
    });
  };

  const handleCertificateRefused = (): void => {
    const customMessage = inputRef.current?.value || undefined;

    refuseCertificate({
      variables: {
        refuseFundsSourceCertificateInput: {
          subscriptionId,
          customMessage,
        },
      },
      onCompleted: () => {
        refetch();
        onClose();
      },
    });
  };

  return (
    <>
      {fundsSourceCertificates.map((doc, index) => (
        <Button
          key={doc.id}
          isDisabled={loading}
          isLoading={loading}
          size="sm"
          variant="secondary"
          onClick={(): void => handleOpenClick(doc.id)}
        >
          {fundsSourceCertificates.length > 1
            ? t('ShowReceipt') + ' n° ' + (index + 1)
            : t('ShowReceipt')}
        </Button>
      ))}
      <PreviewDocumentModal
        isOpen={isOpen}
        src={documentUrl}
        title={i18nDocumentTitle('FUNDS_SOURCE_CERTIFICATE')}
        onClose={onClose}
      >
        <Stack gap="4" w="full">
          <Accordion allowToggle variant="unstyled">
            <AccordionItem>
              <AccordionButton data-cy="expand-parameters">
                <Text color="gray" fontSize="sm" textAlign="left">
                  {t('CustomRefusalOfProofMessageOptional')}
                </Text>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p="0">
                <Textarea minH="200" ref={inputRef} size="sm" />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <HStack alignSelf="end">
            <Button variant="secondary" onClick={deleteModal.onOpen}>
              {t('RefuseProof')}
            </Button>
            <Button onClick={onClose}>{t('ValidateReceipt')}</Button>
          </HStack>
        </Stack>
      </PreviewDocumentModal>
      <DeleteConfirmModal
        isLoading={isRefuseCertificateLoading}
        isOpen={deleteModal.isOpen}
        leastDestructiveRef={deleteRef}
        subtitle={t('DefinitiveAction')}
        title={t('RefuseAndDeleteProofQuestion')}
        onClose={deleteModal.onClose}
        onDelete={handleCertificateRefused}
      />
    </>
  );
}
