import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  TableContainer as ChakraTableContainer,
  TableContainerProps as ChakraTableContainerProps,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { UIEventHandler, useEffect, useRef, useState } from 'react';

type Props = ChakraTableContainerProps;

export function TableContainer({ children, ...props }: Props): JSX.Element {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

  // Main horizontal offset to avoid displaying arrows when not needed
  const scrollOffset = 30;

  useEffect(() => {
    const boxContainer = tableContainerRef.current;
    if (boxContainer) {
      const { offsetWidth, scrollWidth } = boxContainer;
      setShowRightArrow(offsetWidth + scrollOffset < scrollWidth);
      setShowLeftArrow(boxContainer.scrollLeft - scrollOffset > 0);

      const resizeObserver = new ResizeObserver(() => {
        const { offsetWidth, scrollWidth } = boxContainer;
        setShowRightArrow(offsetWidth + scrollOffset < scrollWidth);
        setShowLeftArrow(boxContainer.scrollLeft - scrollOffset > 0);
      });
      resizeObserver.observe(boxContainer);

      return () => {
        resizeObserver.disconnect();
      };
    }
    return () => {};
  }, []);

  const handleRightArrowClick = (): void => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.scroll({ left: tableContainer.scrollLeft + 100, behavior: 'smooth' });
    }
  };

  const handleLeftArrowClick = (): void => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.scroll({ left: tableContainer.scrollLeft - 100, behavior: 'smooth' });
    }
  };

  const handleTableScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const { scrollLeft, offsetWidth, scrollWidth } = e.currentTarget;
    setShowRightArrow(scrollLeft + offsetWidth + scrollOffset < scrollWidth);
    setShowLeftArrow(scrollLeft - scrollOffset > 0);
  };

  return (
    <Box position="relative">
      <ChakraTableContainer
        borderColor="gray.200"
        borderStyle="solid"
        borderWidth="1px 0 0"
        className="table-container"
        maxHeight="400px"
        overflowY="auto"
        ref={tableContainerRef}
        onScroll={handleTableScroll}
        {...props}
      >
        {children}
      </ChakraTableContainer>
      {showLeftArrow && (
        <IconButton
          _hover={{ opacity: 1 }}
          aria-label="scroll-left"
          background="gray.50"
          boxShadow="md"
          icon={<Icon as={ChevronLeftIcon} boxSize="6" color="gray.900" />}
          left="0"
          minW="8"
          opacity="1"
          position="absolute"
          rounded="sm"
          top="50%"
          transform="translate(-50%, -50%)"
          zIndex="8"
          onClick={handleLeftArrowClick}
        />
      )}
      {showRightArrow && (
        <IconButton
          _hover={{ opacity: 1 }}
          aria-label="scroll-right"
          background="gray.50"
          boxShadow="md"
          icon={<Icon as={ChevronRightIcon} boxSize="6" color="gray.900" />}
          minW="8"
          position="absolute"
          right="0"
          rounded="sm"
          top="50%"
          transform="translate(50%, -50%)"
          zIndex="8"
          onClick={handleRightArrowClick}
        />
      )}
    </Box>
  );
}

export type TableContainerProps = Props;
