import { OperationInfosFragment, OperationType } from '@blockpulse3/graphql/hooks';

import { IPartialOperationParameters } from './types';

export function getOperationParameters(
  operation: OperationInfosFragment | undefined,
): IPartialOperationParameters {
  const { type, fundraising } = operation || {};

  let hardCap = null;
  if (type === OperationType.FUNDRAISING && fundraising) {
    hardCap = fundraising.hardCap ?? null;
  }

  return {
    hardCap,
    sharePrice: operation?.sharePrice ?? 1,
    minimalAmount: operation?.minimalAmount ?? null,
    maximalAmount: operation?.maximalAmount ?? null,
  };
}
