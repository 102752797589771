import { Text } from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';
import { TranslationValues, useTranslations } from 'use-intl';

import { IntlFormErrors } from '@blockpulse3/data/locales/types';

type Props = {
  error: FieldError | undefined;
  values?: TranslationValues | undefined;
};

/**
 * FormError.
 * Display an error message from a form error object.
 * Handles translations, with an enum associated to a locale
 * object file.
 *
 * @param {Props}
 * @returns {JSX.Element | null}
 */
export function ErrorMessage({ error, values }: Props): JSX.Element | null {
  const i18nFormError = useTranslations('FormErrors');

  /* ** Default form error message ** */
  const msg = (error?.message as IntlFormErrors) ?? 'REQUIRED_FIELD';

  return error?.message ? (
    <Text color="red" fontSize="xs">
      {msg.includes(' ') ? msg : i18nFormError(msg, values)}
    </Text>
  ) : null;
}

export type FormErrorProps = Props;
