import { Image } from '@chakra-ui/react';

import { ThemeIcon, ThemeIconProps } from '../../components';
import LightLogo from './assets/axereal_dark.svg';
import DarkLogo from './assets/axereal_white.svg';

function LightIcon(): JSX.Element {
  return <Image alt="Axereal" height="full" loading="lazy" src={LightLogo} />;
}

function DarkIcon(): JSX.Element {
  return <Image alt="Axereal" height="full" loading="lazy" src={DarkLogo} />;
}

export function SmallIcon({ ...props }: ThemeIconProps): JSX.Element {
  return <ThemeIcon darkIcon={<DarkIcon />} lightIcon={<LightIcon />} {...props} />;
}
