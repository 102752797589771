import { Props, Select, SingleValue } from 'chakra-react-select';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { AssetType, OperationType } from '@blockpulse3/graphql/hooks';

import { SelectMenuList, SelectSingleValue } from './components';
import { OperationAssetOption, OperationAssetOptionGroup } from './types';

type OperationAssetSelectProps = Props<OperationAssetOption, false, OperationAssetOptionGroup> & {
  operationType: OperationType;
  /* ** Is add disabled ** */
  isAddButtonDisabled?: boolean;
  /* ** Callback on asset selection, change ** */
  onChange?: (option: SingleValue<OperationAssetOption>) => void;
  /* ** Callback on new asset selection ** */
  onAddAsset?: (assetType: AssetType) => void;
  /* ** Callback on modify asset button click ** */
  onEditAsset?: (option: OperationAssetOption) => void;
};

export function OperationAssetSelect({
  isAddButtonDisabled = false,
  operationType,
  onChange = noop,
  onAddAsset = noop,
  onEditAsset = noop,
  ...props
}: OperationAssetSelectProps): JSX.Element {
  const i18nAssetType = useTranslations('AssetTypeValues');

  const handleChange = (option: SingleValue<OperationAssetOption>): void => {
    onChange(option);
  };

  const handleAddAsset = (assetType: AssetType): void => {
    onAddAsset(assetType);
  };

  const handleEditAsset = (option: OperationAssetOption): void => {
    onEditAsset(option);
  };

  const getOptionLabel = (option: OperationAssetOption): string => {
    return option.label && option.label !== 'default'
      ? option.label
      : i18nAssetType(option.type, { nb: 2 });
  };

  const getOptionValue = (option: OperationAssetOption): string => {
    return option.value;
  };

  return (
    <Select<OperationAssetOption, false>
      colorScheme="gray"
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isSearchable={false}
      menuPosition="fixed"
      placeholder=""
      chakraStyles={{
        singleValue: (provided) => ({
          ...provided,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }),
        valueContainer: (provided) => ({
          ...provided,
          paddingInlineEnd: '2',
        }),
      }}
      components={{
        MenuList: (props) => (
          <SelectMenuList
            isAddButtonDisabled={isAddButtonDisabled}
            operationType={operationType}
            onClick={handleAddAsset}
            {...props}
          />
        ),
        SingleValue: (props) => <SelectSingleValue onModifyClick={handleEditAsset} {...props} />,
      }}
      onChange={handleChange}
      {...props}
    />
  );
}
