import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { SubscriptionsFilterInput } from '@blockpulse3/graphql/hooks';

import {
  IOperationTableFilterContext,
  OperationTableFilterContext,
  initOperationTableFilter,
} from './context';

/**
 * OperationTableFilterProvider.
 * Store checked operation filters in both Fundraising and Secondary table controls to bypass
 * the reset on sidepanel display (new page).
 *
 * @returns {JSX.Element}
 */
export function OperationTableFilterProvider(): JSX.Element {
  const [filters, setFilters] = useState<SubscriptionsFilterInput[]>(
    initOperationTableFilter.filters,
  );

  return (
    <OperationTableFilterContext.Provider value={{ filters, setFilters }}>
      <Outlet />
    </OperationTableFilterContext.Provider>
  );
}

export function useOperationFilterContext(): IOperationTableFilterContext {
  return useContext(OperationTableFilterContext);
}
