import { Stack, Text } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { FundsSourceCertificateRefusedData } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';

type Props = {
  date: Date;
  data: FundsSourceCertificateRefusedData;
};

export function ActivityFundsSourceCertificateRefused({ date, data }: Props): JSX.Element {
  const t = useTranslations();

  const { validatorName } = data;

  return (
    <Stack spacing={1}>
      <Text fontSize="sm">{t('FundsSourceCertificateRefused')}</Text>
      <Stack color="gray.500" spacing={1}>
        <Text>
          {formatDate(date, 'DD/MM/YYYY')}
          {validatorName && <> &middot; {validatorName}</>}
        </Text>
      </Stack>
    </Stack>
  );
}

export type ActivityDocumentResetProps = Props;
