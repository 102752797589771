import * as yup from 'yup';

import { StockAssetType, requiredNumberField, requiredStringField } from '@blockpulse3/data/shared';

import { IStockAsset } from './types';

export const stockAssetTypeOptions = Object.values(StockAssetType).map((assetType) => ({
  label: assetType,
  value: assetType,
}));

export const schema = yup.object({
  name: requiredStringField,
  nominalValue: requiredNumberField,
}) as yup.ObjectSchema<IStockAsset>;

export const defaultValues: IStockAsset = {
  name: '',
  nominalValue: 0,
};
