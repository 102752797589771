import { Icon, Stack, Text } from '@chakra-ui/react';
import { TemplateIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

export function FundraisingSubscribersTableEmpty(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack layerStyle="emptyState">
      <Icon as={TemplateIcon} boxSize="40px" color="gray.500" />
      <Text>{t('NoFilterResult')}</Text>
    </Stack>
  );
}
