import * as yup from 'yup';

import { requiredEmailField, requiredStringField } from '@blockpulse3/data/shared';

import { INaturalCompanyAccessForm } from './types';

export const naturalAccessFormDefaults: INaturalCompanyAccessForm = {
  id: null,
  email: '',
  firstName: '',
  lastName: '',
};

export const naturalAccessFormSchema = yup.object({
  email: requiredEmailField,
  firstName: requiredStringField,
  lastName: requiredStringField,
}) as yup.ObjectSchema<INaturalCompanyAccessForm>;
