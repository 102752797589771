import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import { generatePath, resolvePath, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { SpvStatus, routes } from '@blockpulse3/data/shared';
import { useGetCompaniesQuery, useGetPartialCompaniesQuery } from '@blockpulse3/graphql/hooks';
import { useBadge } from '@blockpulse3/ui/ui-hooks';
import { useManagedIndividual } from '@blockpulse3/web-client/auth';
import {
  CompanyCreateButton,
  CompanyImportButton,
  SwitcherItem,
  useWorkspaceSwitcher,
} from '@blockpulse3/web-client/sidebar';

export function MyCompanies(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const { individual } = useManagedIndividual();

  const { onSwitcherOpen } = useWorkspaceSwitcher();

  /* ** Get items ** */
  const companiesReq = useGetCompaniesQuery({
    variables: {
      individualIdentityId: individual?.id,
    },
    skip: !individual?.id,
  });
  const companies = companiesReq.data?.companies || [];

  const partialsReq = useGetPartialCompaniesQuery({
    variables: {
      individualIdentityId: individual?.id,
    },
    skip: !individual?.id,
  });
  const partials = partialsReq.data?.partials || [];

  /* ** Dynamic badge getter ** */
  const { getBadge } = useBadge(
    null,
    [
      {
        value: SpvStatus.VALIDATED,
        color: 'yellow',
        label: t('InCreation'),
      },
      {
        value: SpvStatus.SIGNED,
        color: 'yellow',
        label: t('InCreation'),
      },
      {
        value: SpvStatus.VERIFIED,
        color: 'yellow',
        label: t('InCreation'),
      },
      {
        value: SpvStatus.CREATING,
        color: 'blue',
        label: t('RegistrationInProgress'),
      },
    ],
    { color: 'gray', label: t('Draft', { nb: 1 }) },
  );

  /* ** Redirect to clicked workspace dashboard ** */
  const handleWorkspaceClick = (id: string): void => {
    const selectedCompany = companies.find((w) => w.id === id);
    if (selectedCompany) {
      navigate(
        resolvePath(generatePath(routes.manage.company.href, { companyId: selectedCompany.id })),
      );
    }
  };

  const handlePartialClick = (id: string): void => {
    const partial = partials.find((d) => d.id === id);
    if (!partial) return;

    const companyId = partial?.id || '';
    navigate(resolvePath(generatePath(routes.manage.company.href, { companyId })));
  };

  const loading = companiesReq.loading || partialsReq.loading;
  const MAX_LENGTH = 5;
  const totalCount = companies.length + partials.length;
  const companiesLength = companies.length > MAX_LENGTH ? MAX_LENGTH : companies.length;
  const partialsLength = MAX_LENGTH - companiesLength;

  return (
    <>
      <Card>
        <CardHeader>
          <Heading size="md">
            {t('MyCompanies')}
            {!loading && totalCount > 0 && <Badge ml="2">{totalCount}</Badge>}
          </Heading>
        </CardHeader>
        <CardBody p="0" pt="2">
          {loading ? (
            <Stack m="5" spacing="8">
              <Skeleton minH="50px" />
              <Skeleton minH="50px" />
              <Skeleton minH="50px" />
            </Stack>
          ) : (
            <Stack spacing="0" w="full">
              {totalCount === 0 && (
                <Stack bgColor="transparent" layerStyle="emptyState" p="5">
                  <Icon as={OfficeBuildingIcon} boxSize="40px" color="gray.500" />
                  <Text>{t('NoCompany')}</Text>
                </Stack>
              )}
              {companies.slice(0, companiesLength).map((company) => (
                <SwitcherItem
                  key={company.id}
                  isUser={false}
                  name={company.name}
                  profilePicture={company.identity?.profilePicture}
                  py={3}
                  workspaceId={company.id}
                  onItemClick={handleWorkspaceClick}
                >
                  <Text fontSize="sm" fontWeight="light" variant="ellipsis">
                    {company.registrationNumber}
                  </Text>
                </SwitcherItem>
              ))}
              {partials.slice(0, partialsLength).map((partial) => {
                /* ** Draft based badge if a company_draft is associated to the partial company ** */
                const status = partial?.spvStatus || SpvStatus.EDITING;
                const { label, color } = getBadge(status);
                return (
                  <SwitcherItem
                    key={partial.id}
                    isUser={false}
                    profilePicture={partial.identity?.profilePicture}
                    py={3}
                    workspaceId={partial.id}
                    name={
                      partial.name ||
                      t('NewCompanyID', {
                        id: partial.id.toString(),
                      })
                    }
                    onItemClick={handlePartialClick}
                  >
                    <Badge alignSelf="flex-start" colorScheme={color}>
                      {label}
                    </Badge>
                  </SwitcherItem>
                );
              })}
              {totalCount > MAX_LENGTH && (
                <Button p="4" textAlign="left" variant="link" onClick={onSwitcherOpen}>
                  {t('SeeAll')}
                </Button>
              )}
            </Stack>
          )}
        </CardBody>
      </Card>
      <CompanyCreateButton />
      <CompanyImportButton />
    </>
  );
}
