import MessageKeys from 'use-intl/dist/core/utils/MessageKeys';

export type IOperationSubscribersCSVModal = {
  csvFile: string;
};

export enum OperationCSVType {
  FUNDRAISING_PRIVATE = 'FUNDRAISING_PRIVATE',
  FUNDRAISING_CROWDFUNDING = 'FUNDRAISING_CROWDFUNDING',
  SECONDARY_BUYER = 'SECONDARY_BUYER',
  SECONDARY_SELLER = 'SECONDARY_SELLER',
  OPPORTUNITY = 'OPPORTUNITY'
}

export type OperationCSVLabel = {
  csvTemplateLink: string;
  successToastTitle: MessageKeys<IntlMessages, keyof Messages>;
  submitTitle: MessageKeys<IntlMessages, keyof Messages>;
  alertTitle?: MessageKeys<IntlMessages, keyof Messages>;
};
