import dayjs from 'dayjs';

import { OptionGrantStatus } from '@blockpulse3/data/shared';
import {
  OptionTokenGrantEntityFragment,
  OptionTokenGrantFragment,
  OptionTokenInfosFragment,
} from '@blockpulse3/graphql/hooks';

export function getOptionTokenGrantStatus(
  option: OptionTokenInfosFragment,
  grant?: OptionTokenGrantFragment | OptionTokenGrantEntityFragment,
): string {
  if (!grant) {
    return OptionGrantStatus.GRANTED;
  }

  const endSubscriptionDate = dayjs
    .unix(grant.date)
    .add(option.subscriptionPeriod, option.durationUnit);
  const endVestingDate = dayjs
    .unix(grant.date)
    .add(option.vestingCurve.vestingDuration, option.durationUnit);
  const cancellationDate = !!grant.cancellationDate && dayjs.unix(grant.cancellationDate);

  const optionExpirationDate = dayjs.unix(option.expirationDate);

  const today = dayjs();

  if (
    (cancellationDate && today.isAfter(cancellationDate)) ||
    today.isAfter(optionExpirationDate)
  ) {
    return OptionGrantStatus.OBSOLETE;
  }

  if (grant.exercisedAmount === grant.amount) {
    return OptionGrantStatus.EXERCISED;
  }

  if (grant.subscribedAmount) {
    if (grant.subscribedAmount === grant.amount && today.isBefore(endSubscriptionDate)) {
      return OptionGrantStatus.SUBSCRIBED;
    }

    if (today.isBefore(endVestingDate)) {
      return OptionGrantStatus.VESTING;
    }

    return OptionGrantStatus.VESTED;
  }

  return OptionGrantStatus.GRANTED;
}
