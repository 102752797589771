import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon, UploadIcon, XCircleIcon } from '@heroicons/react/outline';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { SortingState, Updater } from '@tanstack/react-table';
import axios from 'axios';
import { useMemo, useState } from 'react';
import { generatePath, resolvePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  CompanyType,
  GetPaginatedShareTransferRegisterDocument,
  GetPaginatedShareTransferRegisterQuery,
  GetPaginatedShareTransferRegisterQueryVariables,
  MovementFilterInput,
  MovementFilterType,
  MovementInfosFragment,
  OperationEntityInfosFragment,
  OrderBy,
  OrderByDirection,
  useGenerateRmtExtractUrlMutation,
  useGenerateRmtUrlMutation,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { downloadCsv } from '@blockpulse3/helpers';
import { Input } from '@blockpulse3/ui/commons';
import { usePagination } from '@blockpulse3/ui/ui-hooks';

import { ShareTransferRegisterPaginatedTable } from './ShareTransferRegisterPaginatedTable';

const PAGE_SIZE = 10;

type Props = {
  operation?: OperationEntityInfosFragment | undefined;
};

/**
 * ShareTransferRegister.
 * RMT view.
 *
 * @returns {JSX.Element}
 */

export function ShareTransferRegister({ operation }: Props): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState<string>('');
  const [filters, setFilters] = useState<MovementFilterInput[]>([]);
  const [sorting, setSorting] = useState<SortingState>([{ id: 'number', desc: false }]);
  const [isCsvExtractLoading, setIsCsvExtractLoading] = useState<boolean>(false);

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq.data?.company;
  const isPartial = company?.type === CompanyType.PARTIAL;
  const currentRepatriation = company?.currentRepatriation;

  const [generateRmtUrl, { loading: isRmtLoading }] = useGenerateRmtUrlMutation();
  const [generateRmtExtractUrl, { loading: isRmtExtractLoading }] =
    useGenerateRmtExtractUrlMutation();

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputReset = (): void => {
    setSearchInput('');
    resetSpecificFilter('searchValue', MovementFilterType.CUSTOM, '');
  };

  // For now only handle unique column sorting
  const orderBy: OrderBy | undefined = useMemo(() => {
    if (!sorting.length) return undefined;
    return {
      direction: sorting[0].desc ? OrderByDirection.DESC : OrderByDirection.ASC,
      field: sorting[0].id,
    };
  }, [sorting]);

  const paginationProps = usePagination<
    GetPaginatedShareTransferRegisterQuery,
    GetPaginatedShareTransferRegisterQueryVariables,
    MovementInfosFragment
  >({
    queryDocument: GetPaginatedShareTransferRegisterDocument,
    queryOptions: {
      variables: {
        companyId,
        operationId: operation?.id,
        filterBy: filters,
        first: PAGE_SIZE,
        orderBy,
      },
      skip: !companyId || !company || (isPartial && !operation?.id),
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'movements',
    pageSize: PAGE_SIZE,
  });

  const { totalCount, refetch, reset } = paginationProps;

  const handleSearchInputSubmit = (value: string): void => {
    const isInputAndFilterSearchEmpty =
      value === '' && !filters.some((filter) => filter.name === 'searchValue');

    if (isInputAndFilterSearchEmpty) return;

    resetSpecificFilter('searchValue', MovementFilterType.CUSTOM, value);
  };

  const resetSpecificFilter = (
    filterName: string,
    filterType: MovementFilterType,
    value: string,
  ): void => {
    const currentFilterWithoutFilterName = filters.filter((filter) => filter.name !== filterName);
    const newFilter: MovementFilterInput = {
      name: filterName,
      type: filterType,
      value,
    };
    reset();
    setFilters([...currentFilterWithoutFilterName, newFilter]);
  };

  const handleDownloadRmtCsv = async (): Promise<void> => {
    const formData = new FormData();
    let route = '';
    if (operation?.id) {
      route = '/operations/exportRmtExtractCsv';
      formData.append('operationId', operation?.id);
    } else if (companyId) {
      route = '/companies/exportRmtExtractCsv';
      formData.append('companyId', companyId);
    }

    setIsCsvExtractLoading(true);
    await axios
      .post(process.env['NX_API_CONTROLLER_ENDPOINT'] + route, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        const filename = operation?.id
          ? `rmt-extract-operation-${operation?.id}.csv`
          : `rmt-extract-company-${companyId}.csv`;
        downloadCsv(response.data, filename);
        setIsCsvExtractLoading(false);
      })
      .catch(() => setIsCsvExtractLoading(false));
  };

  const handleDownloadRmtPdf = (): void => {
    if (operation?.id) {
      generateRmtExtractUrl({
        variables: { operationId: operation?.id },
        onCompleted: (data) => {
          window.open(data?.generateRmtExtractUrl, '_blank');
        },
      });
    } else if (companyId) {
      generateRmtUrl({
        variables: { companyId },
        onCompleted: (data) => {
          window.open(data?.generateRmtUrl, '_blank');
        },
      });
    }
  };

  const handleSortingChange = (sortFn: Updater<SortingState>): void => {
    setSorting(sortFn);
    refetch();
  };

  const handleResumeOperationRepatriation = (): void => {
    if (!currentRepatriation) return;

    navigate(
      generatePath(routes.company.newOperation.repatriation.edit.full, {
        companyId,
        operationId: currentRepatriation,
      }),
    );
  };

  const handleRepatriateOperation = (): void => {
    navigate(
      '../../' +
        resolvePath(routes.company.newOperation.repatriation.href, routes.company.newOperation.href)
          .pathname,
      { relative: 'path' },
    );
  };

  if (companyReq.loading) {
    return (
      <Skeleton>
        <Card h="270px" />
      </Skeleton>
    );
  }

  const isMenuLoading = isRmtLoading || isRmtExtractLoading || isCsvExtractLoading;

  return (
    <Card>
      <CardHeader
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">
          {operation ? operation.name + ' - ' : undefined}
          {t(
            operation || company?.type === CompanyType.PARTIAL
              ? 'ShareTransferRegisterExtract'
              : 'ShareTransferRegister',
          )}
        </Heading>
      </CardHeader>
      <CardBody as={Stack} spacing="4">
        <HStack justifyContent="space-between">
          <HStack>
            <InputGroup width={{ base: 'full', md: '300px' }}>
              <Input
                paddingRight="80px"
                placeholder={t('Search')}
                value={searchInput}
                onChange={handleSearchInputChange}
                onKeyDown={(e): void => {
                  if (e.key === 'Enter') {
                    handleSearchInputSubmit(searchInput);
                  }
                }}
              />
              <InputRightElement
                flexDirection="row-reverse"
                justifyContent="space-between"
                paddingX="1"
                width="80px"
              >
                <IconButton
                  aria-label="search-database"
                  icon={<Icon as={SearchIcon} />}
                  size="sm"
                  variant="secondary"
                  onClick={(): void => handleSearchInputSubmit(searchInput)}
                />
                {!!searchInput && (
                  <IconButton
                    aria-label="reset"
                    icon={<Icon as={XCircleIcon} />}
                    size="sm"
                    variant="ghost"
                    onClick={handleSearchInputReset}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            <Text
              color="gray.800"
              fontSize="sm"
              px={{ base: '0', md: '3' }}
              rounded="md"
              textAlign="center"
            >
              {t('ResultsCount', { nb: totalCount })}
            </Text>
          </HStack>
          {(companyId || operation?.id) && (
            <Menu>
              <MenuButton
                as={Button}
                isLoading={isMenuLoading}
                rightIcon={<Icon as={DotsVerticalIcon} boxSize="4" />}
                variant="secondary"
              >
                {t('Action', { nb: 2 })}
              </MenuButton>
              <MenuList>
                <MenuItem
                  aria-label="export pdf"
                  icon={<Icon as={DownloadIcon} boxSize="5" />}
                  isDisabled={isMenuLoading}
                  onClick={handleDownloadRmtPdf}
                >
                  {t('ExportPDFAction')}
                </MenuItem>
                <MenuItem
                  aria-label="export csv"
                  icon={<Icon as={DownloadIcon} boxSize="5" />}
                  isDisabled={isMenuLoading}
                  onClick={handleDownloadRmtCsv}
                >
                  {t('ExportCSVAction')}
                </MenuItem>
                {companyId && company && !isPartial && (
                  <>
                    {currentRepatriation ? (
                      <MenuItem
                        aria-label="resume operation repatriation"
                        icon={<Icon as={UploadIcon} boxSize="5" />}
                        onClick={handleResumeOperationRepatriation}
                      >
                        {t('ResumeOperationRepatriation')}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        aria-label="repatriate external operation"
                        icon={<Icon as={UploadIcon} boxSize="5" />}
                        onClick={handleRepatriateOperation}
                      >
                        {t('RepatriateExternalOperation')}
                      </MenuItem>
                    )}
                  </>
                )}
              </MenuList>
            </Menu>
          )}
        </HStack>
        <ShareTransferRegisterPaginatedTable
          sorting={sorting}
          onSortingChange={handleSortingChange}
          {...paginationProps}
        />
      </CardBody>
    </Card>
  );
}
