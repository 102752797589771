import { Box, Flex } from '@chakra-ui/react';
import { Navigate, Outlet, useMatch } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { useAuthUser } from '@blockpulse3/web-client/auth';
import { Sidebar } from '@blockpulse3/web-client/sidebar';

type Props = unknown;

export function AppLayout(): JSX.Element {
  const { user } = useAuthUser();
  const isSpace = useMatch(routes.space.href + '/*');

  const member = user?.members?.[0];

  if (isSpace && !member) {
    return <Navigate replace to={routes.me.href} />;
  }

  return (
    <Flex as="section" direction={{ base: 'column', lg: 'row' }} height="auto" minH="100vh">
      <Sidebar bg={isSpace ? 'black' : 'primary'} />
      <Box w="64" />
      <Box bg="gray.50" flex="1" overflowY="auto" px={{ base: '4', md: '10' }} py="6">
        <Outlet />
      </Box>
    </Flex>
  );
}

export type AppLayoutProps = Props;
