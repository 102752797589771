import { Box, Flex, Icon, IconButton, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { TrashIcon } from '@heroicons/react/outline';
import { useRef } from 'react';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { formatDate } from '@blockpulse3/helpers';

import { DeleteConfirmModal } from '../DeleteConfirmModal';

type Props = {
  isDeletable: boolean;
  title: string;
  date?: Date;
  children: React.ReactNode;
  onDelete?: () => void;
};
export function Comment({
  isDeletable,
  title,
  date,
  onDelete = noop,
  children,
}: Props): JSX.Element {
  const t = useTranslations();

  const confirmRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDelete = (): void => {
    onDelete();
    onClose();
  };

  return (
    <>
      <Box
        color="gray.700"
        flex="1"
        fontSize="xs"
        mb="2"
        w="100%"
        _hover={{
          '*>.ibtn-delete': { opacity: 1 },
        }}
      >
        <Stack spacing="1">
          <Flex justifyContent="space-between">
            <Text fontSize="sm" fontWeight="600">
              {title}
            </Text>
            {isDeletable && (
              <IconButton
                aria-label="add-comment"
                className="ibtn-delete"
                icon={<Icon as={TrashIcon} boxSize="14px" />}
                ml={2}
                opacity={0}
                size="xs"
                variant="icon-delete"
                onClick={onOpen}
              />
            )}
          </Flex>
          <Box bg="gray.100" borderRadius="0 8px 8px 8px" fontSize="sm" p="3">
            {children}
          </Box>
          <Text color="gray.500" ml="auto">
            {formatDate(date, 'DD/MM/YYYY HH:mm')}
          </Text>
        </Stack>
      </Box>
      <DeleteConfirmModal
        isOpen={isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteCommentQuestion')}
        onClose={onClose}
        onDelete={handleDelete}
      />
    </>
  );
}
