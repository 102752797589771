import { Navigate, Route, Routes } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import {
  AutoLogin,
  ForgotPasswordView,
  InvitationSignUpView,
  LoginView,
  ResetPasswordView,
  SignUpView,
  VerifyEmailView,
} from '@blockpulse3/web-client/user';

import { CenterLayout, HeroLayout } from '../layouts';

type Props = unknown;

/**
 * AuthScreen.
 * Screen rendering auth related routes.
 *
 * @returns {JSX.Element}
 */
export function AuthScreen(): JSX.Element {
  return (
    <Routes>
      <Route element={<AutoLogin />} path={routes.autoLogin.href} />
      <Route element={<HeroLayout />}>
        <Route element={<LoginView />} path={routes.login.href} />
        <Route element={<InvitationSignUpView />} path={routes.invitationSignUp.href} />
        <Route element={<SignUpView />} path={routes.signup.href} />
      </Route>
      <Route element={<CenterLayout />}>
        <Route element={<VerifyEmailView />} path={routes.verifyEmail.href} />
        <Route element={<ForgotPasswordView />} path={routes.forgotPassword.href} />
        <Route element={<ResetPasswordView />} path={routes.resetPassword.href} />
      </Route>
      <Route element={<Navigate replace to={routes.login.href} />} path="*" />
    </Routes>
  );
}

export type AuthScreenProps = Props;
