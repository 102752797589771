import { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

export function ScrollToTop(props: Props): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment>{props.children}</Fragment>;
}
