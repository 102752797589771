import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  CashIcon,
  CollectionIcon,
  CurrencyEuroIcon,
  SwitchHorizontalIcon,
} from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberInt } from '@blockpulse3/helpers';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

type Props = unknown;

/**
 * SecondaryOperationInformations.
 * Computed information section of the secondary operation.
 *
 * @returns {JSX.Element}
 */
export function SecondaryOperationInformations(): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const { data, loading, error } = useGetOperationQuery({
    variables: { operationId },
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="260px" />
      </Skeleton>
    );
  }

  if (!data || data.operation?.data?.__typename !== 'SecondaryData' || error) {
    return <ErrorQueryCard h="260px" />;
  }

  const subscriptionCount = data.operation.subscriptionCount;
  const totalTradedAmount = data.operation.data.tradedAmount;
  const totalTradedShareCount = data.operation.data.tradedShareCount;
  const totalShareToSellCount = data.operation.data.totalShareToSell;
  const approvedTransactionCount = data.operation.data.approvedTransactionCount;

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '8' }}>
      <Card w="full">
        <CardHeader>
          <HStack justifyContent="space-between" spacing="4">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('TradingVolume')}
            </Heading>
            <Icon as={SwitchHorizontalIcon} boxSize="5" color="gray.500" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberCurrency(totalTradedAmount)}
          </Text>
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader>
          <HStack justifyContent="space-between" spacing="4">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('AssetsSoldCount')}
            </Heading>
            <Icon as={CollectionIcon} boxSize="5" color="gray.500" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberInt(totalTradedShareCount)}{' '}
            <Text as="span" fontSize="sm">
              / {formatNumberInt(totalShareToSellCount)}
            </Text>
          </Text>
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader>
          <HStack justifyContent="space-between" spacing="4">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('SecondaryTransactionsApproved')}
            </Heading>
            <Icon as={CashIcon} boxSize="5" color="gray.500" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberInt(approvedTransactionCount)}{' '}
            <Text as="span" fontSize="sm">
              / {formatNumberInt(subscriptionCount)}
            </Text>
          </Text>
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader>
          <HStack justifyContent="space-between" spacing="4">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('AssetPrice')}
            </Heading>
            <Icon as={CurrencyEuroIcon} boxSize="5" color="gray.500" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberCurrency(data.operation.sharePrice || 1)}
          </Text>
        </CardBody>
      </Card>
    </Stack>
  );
}

export type SecondaryOperationInformationsProps = Props;
