import {
  HStack,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { SearchIcon, XCircleIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { IndividualIdentitiesFilterInput } from '@blockpulse3/graphql/hooks';
import { Input } from '@blockpulse3/ui/commons';

type Props = {
  /* ** Total count of companies ** */
  totalCount: number;
  /* ** Active filters ** */
  filters: IndividualIdentitiesFilterInput[];
  // /* ** Callback on filter update ** */
  // onFiltersChange: (value: IndividualIdentitiesFilterInput[]) => void;
  /* ** Callback on search submit ** */
  onSearchInputSubmit: (value: string) => void;
};

/**
 * AdminIndividualsControls.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function AdminIndividualsControls({
  totalCount,
  filters,
  // onFiltersChange,
  onSearchInputSubmit,
}: Props): JSX.Element {
  const t = useTranslations();

  const [searchInput, setSearchInput] = useState<string>('');

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputReset = (): void => {
    setSearchInput('');
    onSearchInputSubmit('');
  };

  const handleSearchInputSubmit = (): void => {
    onSearchInputSubmit(searchInput);
  };

  // const handleFiltersChange = (value: CompaniesFilterInput[]): void => {
  //   onFiltersChange(value);
  // };

  return (
    <Stack direction={{ base: 'column-reverse', md: 'row' }} justifyContent="space-between">
      <Stack direction={{ base: 'column', md: 'row' }}>
        <InputGroup width={{ base: 'full', md: '300px' }}>
          <Input
            paddingRight="80px"
            placeholder={t('Search')}
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={(e): void => {
              if (e.key === 'Enter') {
                handleSearchInputSubmit();
              }
            }}
          />
          <InputRightElement
            flexDirection="row-reverse"
            justifyContent="space-between"
            paddingX="1"
            width="80px"
          >
            <IconButton
              aria-label="search-database"
              icon={<Icon as={SearchIcon} />}
              size="sm"
              variant="secondary"
              onClick={handleSearchInputSubmit}
            />
            {!!searchInput && (
              <IconButton
                aria-label="reset"
                icon={<Icon as={XCircleIcon} />}
                size="sm"
                variant="ghost"
                onClick={handleSearchInputReset}
              />
            )}
          </InputRightElement>
        </InputGroup>
        {/* <AdminIndividualsTableFilters defaultValue={filters} onSubmit={handleFiltersChange} /> */}
        <HStack>
          <Text
            color="gray.800"
            fontSize="sm"
            px={{ base: '0', md: '3' }}
            rounded="md"
            textAlign="center"
          >
            {t('ResultsCount', { nb: totalCount })}
          </Text>
        </HStack>
      </Stack>
    </Stack>
  );
}

export type AdminIndividualsControlsProps = Props;
