import { Skeleton } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { usePreviewShareTransferRegisterQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { ShareTransferRegisterTable } from '../../ShareTransferRegister/ShareTransferRegisterTable';

type Props = unknown;

/**
 * ShareTransferRegisterPreview.
 * Same table on shareholding view. Use data from document instead of company.
 *
 * @returns {JSX.Element}
 */
export function ShareTransferRegisterPreview(): JSX.Element {
  const { companyId = '' } = useParams();

  const { data, loading, error } = usePreviewShareTransferRegisterQuery({
    variables: { companyId },
  });

  if (loading) {
    return <Skeleton height="400px" width="full" />;
  }

  if (error) {
    return <ErrorQueryCard height="400px" width="full" />;
  }

  const movements = data?.previewShareTransferRegister || [];

  return <ShareTransferRegisterTable isPreview={true} movements={movements} />;
}

export type ShareTransferRegisterPreviewProps = Props;
