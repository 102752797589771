import { useDisclosure } from '@chakra-ui/react';
import { ClockIcon } from '@heroicons/react/outline';
import { generatePath, resolvePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { OperationStatus } from '@blockpulse3/graphql/hooks';

import { ExtendOperationModal } from '../../../ExtendOperationModal';
import { OperationRow } from '../../types';
import { OperationTableRowTemplate } from './OperationTableRowTemplate';
import { OperationTableUnknownRow } from './OperationTableUnknownRow';

type Props = {
  operation: OperationRow;
};

export function OperationTableSecondaryRow({ operation }: Props): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const navigate = useNavigate();

  const isSpace = useMatch(routes.space.href + '/*');

  const extendOperationModal = useDisclosure();

  const handleOperationDraftClick = (): void => {
    if (isSpace) {
      return handleOperationCurrentClick();
    }

    if (!companyId) return;

    navigate(
      generatePath(routes.company.newSecondary.href + '/' + routes.company.newSecondary.edit.href, {
        operationId: operation.id,
      }),
    );
  };

  const handleOperationCurrentClick = (): void => {
    const rowCompanyId = isSpace && operation.company?.id ? operation.company.id : companyId;
    if (rowCompanyId) {
      const relPath = generatePath(routes.company.secondary.href, {
        companyId: rowCompanyId,
        operationId: operation.id,
      });
      navigate(
        isSpace
          ? resolvePath(
              relPath,
              generatePath(routes.space.company.full, { companyId: rowCompanyId }),
            ).pathname
          : relPath,
      );
    }

    return;
  };

  const handleRowClick = (): void => {
    switch (operation.status) {
      case OperationStatus.DRAFT: {
        handleOperationDraftClick();
        break;
      }

      default: {
        handleOperationCurrentClick();
        break;
      }
    }
  };

  const endDate = operation.closingDate || operation.endSubscriptionDate;
  const canExtendOperation = isSpace && operation.status === OperationStatus.STARTED && endDate;

  if (operation.data?.__typename !== 'SecondaryData') return null;

  if (!operation.sharePrice) {
    return (
      <OperationTableUnknownRow operation={operation} operationSubtitle={t('SecondaryMarket')} />
    );
  }

  return (
    <>
      <OperationTableRowTemplate
        operation={operation}
        operationSubtitle={t('SecondaryMarket')}
        totalAmount={operation.data.tradedAmount}
        menuActions={[
          {
            label: t('ExtendOperation'),
            ariaLabel: 'extend operation',
            icon: ClockIcon,
            disabled: !canExtendOperation,
            onClick: extendOperationModal.onOpen,
          },
        ]}
        onRowClick={handleRowClick}
      />
      {canExtendOperation && extendOperationModal.isOpen && (
        <ExtendOperationModal
          isOpen={extendOperationModal.isOpen}
          operationId={operation.id}
          operationType={operation.type}
          title={t('ExtendOperationWName', { operationName: operation.name })}
          onClose={extendOperationModal.onClose}
        />
      )}
    </>
  );
}

export type OperationTableFundraisingRowProps = Props;
