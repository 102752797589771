import {
  HStack,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { TemplateIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetCompanyQuery, usePreviewCaptableQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberInt } from '@blockpulse3/helpers';
import { CompanyIdentityAvatar, ErrorQueryCard } from '@blockpulse3/ui/commons';

import { ShareholderTable } from '../../ShareholderTable';

type Props = unknown;

export function CapTablePreview(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const { data, loading, error } = useGetCompanyQuery({ variables: { companyId } });
  const {
    data: captableData,
    loading: captableLoading,
    error: captableError,
  } = usePreviewCaptableQuery({ variables: { companyId } });

  if (loading || captableLoading) {
    return <Skeleton height="400px" width="full" />;
  }

  if (error || captableError) {
    return <ErrorQueryCard height="400px" width="full" />;
  }
  const { name, shareCapital, nominalValue, identity } = data?.company || {};
  const capTable = captableData?.previewCaptable || {};
  const {
    stockTotalSupply,
    stockShareCapital,
    fullyDilutedTotalSupply,
    fullyDilutedShareCapital,
    isComplete,
  } = capTable || {};
  const displayedShareCapital = Math.max(shareCapital || 0, stockShareCapital || 0);
  const shareholders = capTable?.shareholders || [];

  return (
    <Stack px="0" spacing="6">
      <Stack spacing="4">
        <HStack>
          <CompanyIdentityAvatar boxSize="20" src={identity?.profilePicture} />
          <Heading color="gray.800" fontSize="xl" fontWeight="semibold" paddingLeft="4">
            {name}
          </Heading>
        </HStack>
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
          <Stat bg="gray.50" justifyContent="start" p="4">
            <StatLabel color="gray.500" fontSize="md" fontWeight="500">
              {t('ShareCapital')}
            </StatLabel>
            <StatNumber>{formatNumberCurrency(displayedShareCapital || 0)}</StatNumber>
            <Tooltip
              hasArrow
              label={t('FullyDiluted', { gender: 'male', nb: 1 })}
              placement="right-end"
            >
              <StatHelpText width="fit-content">
                {formatNumberCurrency(fullyDilutedShareCapital || 0)}
              </StatHelpText>
            </Tooltip>
          </Stat>
          <Stat bg="gray.50" p="4">
            <StatLabel color="gray.500" fontSize="md" fontWeight="500">
              {t('SharesCount')}
            </StatLabel>
            <StatNumber>{formatNumberInt(stockTotalSupply || 0)}</StatNumber>
            <Tooltip
              hasArrow
              placement="right-end"
              label={t('FullyDiluted', {
                gender: 'male',
                nb: stockTotalSupply || 0,
              })}
            >
              <StatHelpText width="fit-content">
                {formatNumberInt(fullyDilutedTotalSupply || 0)}
              </StatHelpText>
            </Tooltip>
          </Stat>
          <Stat bg="gray.50" p="4">
            <StatLabel color="gray.500" fontSize="md" fontWeight="500">
              {t('NominalValue')}
            </StatLabel>
            <StatNumber>{formatNumberCurrency(nominalValue || 0)}</StatNumber>
          </Stat>
        </Stack>
      </Stack>
      <Stack spacing="2">
        <Heading fontSize="lg">{t('Shareholder', { nb: shareholders.length })}</Heading>
        {isComplete ? (
          <ShareholderTable capTable={capTable} />
        ) : (
          <Stack layerStyle="emptyState">
            <Icon as={TemplateIcon} boxSize="40px" color="gray.500" />
            <Stack alignItems="center" spacing="0">
              <Text>{t('PartiallyImportedCompany')}</Text>
              <Text>{t('InsufficientInfoForCapitalTable')}</Text>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export type CapTablePreviewProps = Props;
