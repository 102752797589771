import { Icon, InfoIcon } from '@chakra-ui/icons';
import { HStack, Stack, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { CsvCellError } from '@blockpulse3/data/shared';
import { formatIBAN, formatNumberCurrency } from '@blockpulse3/helpers';

import { TableContainer } from './TableContainer';

type Props = {
  csvData: Record<string, string>[];
  cellErrors?: CsvCellError[];
};

export function CsvDataTable({ csvData, cellErrors }: Props): JSX.Element | null {
  const t = useTranslations();
  const i18nCSVParseError = useTranslations('CSVParseError');

  if (!csvData?.length) return null;

  const cols = Object.keys(csvData[0]);

  return (
    <Stack spacing="2">
      {!!cellErrors?.length && (
        <Text color="red" fontWeight={500}>
          {t('ErrorCount', { nb: cellErrors.length })}
        </Text>
      )}
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              {cols.map((col) => (
                <Th key={col}>{col}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {csvData.map((row: { [x: string]: string }, rowIndex: number) => (
              <Tr key={rowIndex}>
                {cols.map((col, colIndex) => {
                  const cellError = cellErrors?.find(
                    (err) => err.row === rowIndex && err.column === colIndex,
                  );

                  let rowValue = row[col];

                  const isIban = col.toLowerCase().includes('iban');
                  if (isIban) {
                    rowValue = formatIBAN(row[col]);
                  } else {
                    const isCurrency = [
                      'Montant',
                      'Capital social',
                      'Valeur nominale',
                      "Frais d'entrée",
                    ].includes(col);
                    if (isCurrency) {
                      rowValue = /[^0-9.]/.test(row[col])
                        ? row[col]
                        : formatNumberCurrency(row[col]);
                    }
                  }

                  const hasError = !!cellError;
                  return (
                    <Td key={col} backgroundColor={hasError ? 'red.50 !important' : ''}>
                      <HStack justifyContent="space-between">
                        <Text color={hasError ? 'red' : undefined}>{rowValue}</Text>
                        {hasError && cellError.code && (
                          <Tooltip
                            hasArrow
                            label={i18nCSVParseError(cellError.code)}
                            placement="top"
                          >
                            <Icon as={InfoIcon} color="red" />
                          </Tooltip>
                        )}
                      </HStack>
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export type CsvDataTableProps = Props;
