import {
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/solid';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlRoleValues } from '@blockpulse3/data/locales/types';
import { IdentityType, RepresentativeRole, useGetCompanyQuery } from '@blockpulse3/graphql/hooks';
import {
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';
import { useAuthUser, useManagedIndividual } from '@blockpulse3/web-client/auth';

import { canUserUpdateCompany } from './utils';

type Props = {
  onOpen: () => void;
};

export function SettingsCompanyRepresentatives({ onOpen }: Props): JSX.Element {
  const t = useTranslations();
  const i18nRoleValues = useTranslations('RoleValues');

  const { user: authUser } = useAuthUser();
  const { individual } = useManagedIndividual();
  const { companyId = '' } = useParams();
  const { data, loading, error } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  if (!loading && (error || !data)) {
    return (
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 5, lg: 8 }}>
        <Text flexBasis={{ lg: 250 }} fontSize="lg" fontWeight="medium">
          {t('Representative', { nb: 1 })}
        </Text>
        <ErrorQueryCard h="100px" maxW={{ lg: '3xl' }} w="full" />
      </Stack>
    );
  }

  const representatives = (data?.company?.companyRepresentative || []).filter(
    (rep) => ![RepresentativeRole.CREATOR, RepresentativeRole.VIEWER].includes(rep.role),
  );
  const isEditDisabled = !canUserUpdateCompany(authUser, individual, data?.company);

  return (
    <Stack
      alignItems="flex-start"
      direction={{ base: 'column', lg: 'row' }}
      spacing={{ base: 5, lg: 8 }}
    >
      <HStack alignItems="center" flexBasis={{ lg: 250 }}>
        <Text fontSize="lg" fontWeight="medium">
          {t('Representative', { nb: 1 })}
        </Text>
      </HStack>
      <Skeleton isLoaded={!loading} maxWidth={{ lg: '3xl' }} width="full">
        <Card maxW={{ lg: '3xl' }} variant="divider-bottom">
          <CardBody>
            <Stack spacing="4">
              {representatives.map((representative) => {
                const identity = representative.representativeIdentity;
                const address =
                  identity.type === IdentityType.COMPANY
                    ? identity.companyIdentity?.address?.formatted
                    : identity.individualIdentity?.address?.formatted;

                return (
                  <Stack
                    key={representative.id}
                    alignItems="flex-start"
                    direction={['column', 'row']}
                    spacing="2"
                  >
                    <Heading flexBasis={['auto', '150px']} size="sm">
                      {i18nRoleValues(representative.role as IntlRoleValues)}
                    </Heading>
                    <IdentityCard>
                      <IdentityAvatar
                        boxSize="9"
                        src={identity.profilePicture}
                        type={identity.type}
                      />
                      <IdentityCardTitle fontSize="md" isChecked={identity.isVerified}>
                        <Text>{identity.name}</Text>
                      </IdentityCardTitle>
                      <IdentityCardDescription>
                        <Text>{address}</Text>
                        <Text>{identity.email}</Text>
                        <Text>{identity.phone}</Text>
                      </IdentityCardDescription>
                    </IdentityCard>
                  </Stack>
                );
              })}
              <Button isDisabled={isEditDisabled} variant="secondary" onClick={onOpen}>
                <Icon as={PencilIcon} mr="1" /> {t('Edit')}
              </Button>
            </Stack>
          </CardBody>
        </Card>
      </Skeleton>
    </Stack>
  );
}

export type SettingsCompanyRepresentativesProps = Props;
