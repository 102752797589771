import { SingleValueProps, chakraComponents } from 'chakra-react-select';

import { IdentitySelectOption, IdentitySelectOptionGroup } from '../types';
import { IdentitySelectBadge } from './IdentitySelectBadge';

export type IdentitySelectControlProps = SingleValueProps<
  IdentitySelectOption,
  false,
  IdentitySelectOptionGroup
>;

export const SingleValue = (props: IdentitySelectControlProps): JSX.Element => {
  const { identity } = props.data;

  return (
    <chakraComponents.SingleValue {...props}>
      <IdentitySelectBadge identity={identity} />
    </chakraComponents.SingleValue>
  );
};
