import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys,
);

const baseStyle = definePartsStyle({
  control: {
    '&[data-checked]': {
      'bg': 'secondary',
      'borderColor': 'secondary',
      '&:hover': {
        bg: 'secondary',
        borderColor: 'secondary',
      },
    },
  },
});

const answer = definePartsStyle({
  label: {
    lineHeight: 1,
    width: 'full',
  },
  container: {
    'borderColor': 'gray.200',
    'borderWidth': '1px',
    'borderStyle': 'solid',
    'borderRadius': '5px',
    'padding': '3',
    'alignItems': 'flex-start',
    '&[data-checked]': {
      bg: 'gray.50',
      borderColor: 'gray.400',
    },
  },
});

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    answer,
  },
});
