import { createContext } from 'react';

import { noop } from '@blockpulse3/data/shared';
import {
  IdentityEntityInfosFragment,
  IndividualIdentityInfosFragment,
} from '@blockpulse3/graphql/hooks';

/**
 * IIdentityContext.
 *
 * Identity context type, providing the identity data
 * and a setter
 */
export type IIdentityContext = {
  identity: IdentityEntityInfosFragment | null;
  identityId: string;
  individual: IndividualIdentityInfosFragment | null;
  refetch: () => void;
};

export const IdentityContext = createContext<IIdentityContext>({
  identity: null,
  identityId: '',
  individual: null,
  refetch: noop,
});
