import { Props, Select } from 'chakra-react-select';
import { useTranslations } from 'use-intl';

import { IntlFiscalAdvantageTypeValues } from '@blockpulse3/data/locales/types';

import { AllowedFiscalAdvantageOption, AllowedFiscalAdvantageOptionGroup } from './types';

type AllowedFiscalAdvantageSelect = Props<
  AllowedFiscalAdvantageOption,
  true,
  AllowedFiscalAdvantageOptionGroup
>;

export function AllowedFiscalAdvantageSelect({
  defaultValue,
  ...props
}: AllowedFiscalAdvantageSelect): JSX.Element {
  const t = useTranslations();
  const i18nFiscalAdvantageType = useTranslations('FiscalAdvantageTypeValues');

  return (
    <Select<AllowedFiscalAdvantageOption, true>
      isMulti
      closeMenuOnSelect={false}
      colorScheme="indigo"
      defaultValue={defaultValue}
      isClearable={false}
      noOptionsMessage={(): null => null}
      placeholder={t('None')}
      chakraStyles={{
        valueContainer: (provided) => ({
          ...provided,
          paddingInlineStart: '3',
          paddingInlineEnd: '3',
        }),
      }}
      formatOptionLabel={(fiscalAdvantage): string =>
        i18nFiscalAdvantageType(fiscalAdvantage.label as IntlFiscalAdvantageTypeValues)
      }
      {...props}
    />
  );
}
