import * as yup from 'yup';

import {
  BrandName,
  brandConfigs,
  requiredEmailField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { SpaceType } from '@blockpulse3/graphql/hooks';

import { SpaceForm } from './types';

export const schema: yup.ObjectSchema<SpaceForm> = yup.object({
  name: requiredStringField,
  type: yup.object({
    label: requiredStringField,
    value: yup.mixed<SpaceType>().oneOf(Object.values(SpaceType)).required(),
  }),
  brand: yup.object({
    label: requiredStringField,
    value: yup
      .mixed<BrandName>()
      .oneOf(Object.keys(brandConfigs) as BrandName[])
      .required(),
  }),
  owner: yup.object({
    firstName: yup.string().when('$isUpdate', {
      is: false,
      then: () => requiredStringField,
    }),
    lastName: yup.string().when('$isUpdate', {
      is: false,
      then: () => requiredStringField,
    }),
    email: yup.string().when('$isUpdate', {
      is: false,
      then: () => requiredEmailField,
    }),
  }),
});

export const spaceFormDefaultValues: SpaceForm = {
  name: '',
  type: { label: 'Group', value: SpaceType.GROUP },
  brand: { label: brandConfigs.blockpulse.name, value: 'blockpulse' },
  owner: {
    firstName: '',
    lastName: '',
    email: '',
  },
};
