import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import {
  AddSubscriptionDocuments,
  ResponsiveModal,
  ResponsiveModalProps,
  SubscriptionDocumentsSelectProps,
} from '@blockpulse3/ui/commons';

type Props = {
  subscriptionId: string;
  uploadableSubscriptionDocuments?: SubscriptionDocumentsSelectProps[];
} & Omit<ResponsiveModalProps, 'children'>;

export function AddSubscriptionDocumentModal({
  subscriptionId,
  uploadableSubscriptionDocuments,
  onClose,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const onCloseModal = (): void => {
    onClose();
  };

  return (
    <ResponsiveModal onClose={onCloseModal} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('AddDocument')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} spacing="4">
          <AddSubscriptionDocuments
            subscriptionId={subscriptionId}
            uploadableSubscriptionDocuments={uploadableSubscriptionDocuments}
          />
        </ModalBody>
      </ModalContent>
    </ResponsiveModal>
  );
}
