import * as yup from 'yup';

import { FormErrors, requiredEmailField, requiredPasswordField } from '@blockpulse3/data/shared';
import { getUserTimezone } from '@blockpulse3/helpers';

import { ISignUpForm } from './types';

export const schema = yup
  .object({
    email: requiredEmailField,
    password: requiredPasswordField,
    confirmPassword: yup
      .string()
      .trim()
      .required(FormErrors.RequiredField)
      .test(
        'password-confirmation',
        FormErrors.PasswordMatch,
        (value, context) => value === context.parent.password,
      ),
    conditions: yup.boolean().isTrue('CONDITIONS_FIELD').required(FormErrors.RequiredField),
  })
  .required() as yup.ObjectSchema<ISignUpForm>;

export const signUpFormDefaults: ISignUpForm = {
  email: '',
  password: '',
  confirmPassword: '',
  conditions: false,
  timezone: getUserTimezone(),
};
