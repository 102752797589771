import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowRightIcon, OfficeBuildingIcon } from '@heroicons/react/outline';
import { generatePath, resolvePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  GetSpacesDocument,
  GetSpacesQuery,
  GetSpacesQueryVariables,
  SpaceEntityInfosFragment,
} from '@blockpulse3/graphql/hooks';
import { usePagination } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { NewSpaceFormModal } from '../commons';
import { spaceTypeIcons } from '../utils';
import { SpaceSwitcherItem } from './SpaceSwitcherItem';

const MAX_LENGTH = 5;

export function ManagedSpacesList(): JSX.Element {
  const t = useTranslations();
  const i18nSpaceType = useTranslations('SpaceTypes');

  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const createSpaceModal = useDisclosure();

  const navigate = useNavigate();

  const paginationProps = usePagination<
    GetSpacesQuery,
    GetSpacesQueryVariables,
    SpaceEntityInfosFragment
  >({
    queryDocument: GetSpacesDocument,
    queryOptions: {
      variables: {
        first: MAX_LENGTH,
        providerId: spaceId,
      },
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'spaces',
    pageSize: MAX_LENGTH,
  });
  const { loading, results, totalCount, refetch } = paginationProps;
  const spaces = results || [];

  const handleSpaceAddSuccess = (): void => {
    refetch();
  };

  const handleSpaceClick = (spaceId: string): void => {
    const selectedSpace = spaces.find((s) => s?.id === spaceId);
    if (selectedSpace) {
      navigate(
        resolvePath(
          generatePath(routes.space.space.href, { spaceId: selectedSpace.id }),
          routes.space.href,
        ).pathname,
      );
    }
  };

  const handleSeeAllClick = (): void => {
    navigate(routes.space.managedSpaces.full);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Heading size="md">
            <HStack justifyContent="space-between">
              <Text>
                {t('ManagedSpaces')}
                {!loading && totalCount > 0 && <Badge ml="2">{totalCount}</Badge>}
              </Text>
              <Button
                rightIcon={<Icon as={ArrowRightIcon} boxSize="4" />}
                size="sm"
                variant="secondary"
                onClick={handleSeeAllClick}
              >
                {t('Manage')}
              </Button>
            </HStack>
          </Heading>
        </CardHeader>
        <CardBody p="0" pt="2">
          {loading ? (
            <Stack m="5" spacing="8">
              <Skeleton minH="50px" />
              <Skeleton minH="50px" />
              <Skeleton minH="50px" />
            </Stack>
          ) : (
            <Stack spacing="0" w="full">
              {totalCount === 0 && (
                <Stack bgColor="transparent" layerStyle="emptyState" p="5">
                  <Icon as={OfficeBuildingIcon} boxSize="40px" color="gray.500" />
                  <Text>{t('NoManagedSpace')}</Text>
                </Stack>
              )}
              {spaces.map((space) => (
                <SpaceSwitcherItem
                  key={space?.id}
                  name={space?.name || ''}
                  py={3}
                  spaceId={space?.id || ''}
                  onItemClick={handleSpaceClick}
                >
                  <Badge colorScheme="blackAlpha">
                    <HStack spacing="1">
                      <Icon as={spaceTypeIcons[space.type]} boxSize="14px" color="gray.500" />
                      <Text>{i18nSpaceType(space.type)}</Text>
                    </HStack>
                  </Badge>
                </SpaceSwitcherItem>
              ))}
              {totalCount > MAX_LENGTH && (
                <Button p="4" textAlign="left" variant="link" onClick={handleSeeAllClick}>
                  {t('SeeAll')}
                </Button>
              )}
            </Stack>
          )}
        </CardBody>
      </Card>
      <NewSpaceFormModal
        isOpen={createSpaceModal.isOpen}
        providerId={spaceId}
        onClose={createSpaceModal.onClose}
        onSubmitSuccess={handleSpaceAddSuccess}
      />
    </>
  );
}
