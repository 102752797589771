import { InfoIcon } from '@chakra-ui/icons';
import {
  Card,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  InputGroup,
  InputRightElement,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import {
  AssetEntityInfosFragment,
  AssetInfosFragment,
  AssetStatus,
  AssetType,
  Operation,
  OperationType,
  useCompleteOperationMutation,
  useCreateImportedOperationMutation,
  useGetAssetDraftsByOperationQuery,
  useGetAssetLazyQuery,
  useGetAssetsByCompanyQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorMessage, ErrorQueryCard, Input, useErrorToast } from '@blockpulse3/ui/commons';
import {
  OperationAssetOption,
  OperationAssetSelect,
  getAssetDraftsOptions,
} from '@blockpulse3/web-client/operation/commons';

import {
  AGACreateModal,
  BSAAIRCreateModal,
  BSACreateModal,
  BSPCECreateModal,
  BondCreateModal,
} from '../';
import { completeOperationFormSchema } from './schema';
import { ICompleteOperationForm } from './type';
import { getCompleteOperationParameters } from './utils';

type Props = {
  /** Operation ID to complete **/
  operationId?: Operation['id'];
  /** Asset ID to preselect in the form if no `operationId` arg **/
  assetId?: string;
  /** Operation type to preselect in the form if no `operationId` arg **/
  operationType?: OperationType;
  /** Is repatriation flag to lock other repatriation **/
  isRepatriation?: boolean;
  /** Callback to execute when the operation is completed **/
  onCompleted: (operationId: string) => void;
  /** Callback to set loading state **/
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export function CompleteOperationForm({
  operationId,
  assetId,
  operationType,
  isRepatriation = false,
  onCompleted,
  setIsLoading,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nOperationTypes = useTranslations('OperationTypes');

  const createBondModal = useDisclosure();
  const createAGAModal = useDisclosure();
  const createBSAModal = useDisclosure();
  const createBSAAIRModal = useDisclosure();
  const createBSPCEModal = useDisclosure();

  const errorToast = useErrorToast();

  const { companyId = '' } = useParams();

  const [assetType, setAssetType] = useState<AssetType | null>(null);
  const [currentAsset, setCurrentAsset] = useState<AssetEntityInfosFragment | null>(null);
  const [tmpDraftOptions, setTmpDraftOptions] = useState<OperationAssetOption[]>([]);

  const [completeOperation] = useCompleteOperationMutation();
  const [createImportedOperation] = useCreateImportedOperationMutation();

  const operationReq = useGetOperationQuery({
    variables: { operationId: operationId || '' },
    skip: !operationId,
  });
  const operation = operationReq.data?.operation;

  const effectiveCompanyId = companyId || operation?.company?.id || '';
  const assetsReq = useGetAssetsByCompanyQuery({
    variables: { companyId: effectiveCompanyId },
    skip: !effectiveCompanyId,
  });
  const assets = useMemo(() => assetsReq.data?.getAssetsByCompany || [], [assetsReq]);

  const assetDraftsReq = useGetAssetDraftsByOperationQuery({
    variables: { operationId: operationId || '' },
    skip: !operationId,
  });
  const assetDrafts = assetDraftsReq.data?.getAssetDraftsByOperation || [];
  /* ** Previously created asset draft ** */
  const draftOptions = getAssetDraftsOptions(assetDrafts);

  const [getAsset] = useGetAssetLazyQuery();

  const typeOptions = useMemo(
    () =>
      Object.keys(OperationType).map((type) => ({
        label: type,
        value: type,
      })),
    [i18nOperationTypes],
  );

  const assetOptions = useMemo(
    () =>
      assets.map(({ name, id, assetType, status }) => ({
        label: name,
        value: id,
        type: assetType,
        isDraft: status === AssetStatus.DRAFT,
      })),
    [assets],
  );

  const asset = useMemo(() => {
    if (assetId) {
      return assets.find((asset) => asset.id === assetId);
    }
    return undefined;
  }, [assetId, assetOptions]);

  const { register, control, formState, handleSubmit, reset, setValue, watch, setError } =
    useForm<ICompleteOperationForm>({
      defaultValues: getCompleteOperationParameters(operation, asset, operationType),
      resolver: yupResolver(completeOperationFormSchema),
    });
  const selectedOperationType = watch('type');

  useEffect(() => {
    reset(getCompleteOperationParameters(operation, asset, operationType));
  }, [operation, assetId, operationType, reset]);

  const filteredAssetOptions = useMemo(() => {
    if (!selectedOperationType.value || selectedOperationType.value === 'UNKNOWN') {
      return [];
    }

    const operationPoolAssets = assetOptions.filter((asset) =>
      [AssetType.AGA, AssetType.BSA, AssetType.BSA_AIR, AssetType.BSPCE].includes(asset.type),
    );

    const classicAssets = assetOptions.filter(
      (asset) =>
        ![AssetType.AGA, AssetType.BSA, AssetType.BSA_AIR, AssetType.BSPCE].includes(asset.type),
    );

    return [
      ...(selectedOperationType.value !== OperationType.OPTION_POOL
        ? classicAssets
        : operationPoolAssets || []),
      ...draftOptions,
      ...tmpDraftOptions,
    ];
  }, [selectedOperationType, assetOptions, draftOptions, tmpDraftOptions]);

  useEffect(() => {
    const newParams = getCompleteOperationParameters(
      operation,
      asset,
      operationType,
      filteredAssetOptions,
    );
    setValue('asset', newParams.asset);
  }, [filteredAssetOptions]);

  if (operationReq.loading || assetsReq.loading || assetDraftsReq.loading) {
    return (
      <Skeleton>
        <Card h="350px" />
      </Skeleton>
    );
  }

  if (operationReq.error || assetsReq.error || assetDraftsReq.error) {
    return <ErrorQueryCard h="350px" />;
  }

  const handleFormSubmit: SubmitHandler<ICompleteOperationForm> = (formData) => {
    if (!formData?.asset?.value) {
      setError('asset', {
        type: 'required',
        message: FormErrors.RequiredField,
      });
      return;
    }

    const input = {
      name: formData.name,
      type: formData.type.value as OperationType,
      closingDate: formData.closingDate,
      assetId: formData.asset.value,
      sharePrice: formData.sharePrice,
    };

    setIsLoading(true);
    if (operationId) {
      completeOperation({
        variables: {
          completeOperationInput: {
            ...input,
            operationId,
          },
        },
        onCompleted: ({ completeOperation }) => {
          onCompleted(completeOperation.id);
          setIsLoading(false);
        },
        onError: () => {
          errorToast({ title: t('CompleteOperationError') });
          setIsLoading(false);
        },
      });
    } else if (companyId) {
      createImportedOperation({
        variables: {
          createImportedOperationInput: {
            ...input,
            companyId,
            isRepatriation,
          },
        },
        onCompleted: ({ createImportedOperation }) => {
          onCompleted(createImportedOperation.id);
          setIsLoading(false);
        },
        onError: () => {
          errorToast({ title: t('CompleteOperationError') });
          setIsLoading(false);
        },
      });
    }
  };

  const handleCreateAsset = (assetType: AssetType): void => {
    setAssetType(assetType);
    switch (assetType) {
      case AssetType.AGA:
        createAGAModal.onOpen();
        break;
      case AssetType.BOND:
        createBondModal.onOpen();
        break;
      case AssetType.BSA:
        createBSAModal.onOpen();
        break;
      case AssetType.BSA_AIR:
        createBSAAIRModal.onOpen();
        break;
      case AssetType.BSPCE:
        createBSPCEModal.onOpen();
        break;
      default:
        return;
    }
  };

  const handleEditAsset = async (option: OperationAssetOption): Promise<void> => {
    let asset = [...assets, ...assetDrafts].find((asset) => asset.id === option.value);
    if (!asset && option.value === tmpDraftOptions[0].value) {
      const assetReq = await getAsset({ variables: { companyId, assetId: option.value } });
      asset = assetReq.data?.asset;
    }
    if (asset) {
      setCurrentAsset(asset);
      handleCreateAsset(asset.assetType);
    }
  };

  const resetAssetState = (): void => {
    setCurrentAsset(null);
    setAssetType(null);
  };

  const handleCloseAGAModal = (): void => {
    resetAssetState();
    createAGAModal.onClose();
  };

  const handleCloseBSAModal = (): void => {
    resetAssetState();
    createBSAModal.onClose();
  };

  const handleCloseBSAAIRModal = (): void => {
    resetAssetState();
    createBSAAIRModal.onClose();
  };

  const handleCloseBSPCEModal = (): void => {
    resetAssetState();
    createBSPCEModal.onClose();
  };

  const handleCloseBondModal = (): void => {
    resetAssetState();
    createBondModal.onClose();
  };

  const handleCreateComplete = (asset: AssetInfosFragment): void => {
    if (!asset.operation?.id) {
      setTmpDraftOptions([
        {
          label: asset.name,
          value: asset.id,
          type: asset.assetType,
          isDraft: true,
        },
      ]);
    }
    setValue('asset', {
      label: asset.name,
      value: asset.id,
      type: asset.assetType,
      isDraft: asset.status === AssetStatus.DRAFT,
    });
    if (operationId) {
      assetDraftsReq.refetch();
    }
  };

  return (
    <>
      <form id="complete-operation" onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="4">
          <FormControl isInvalid={!!formState.errors?.name}>
            <FormLabel htmlFor="name">{t('OperationName')}</FormLabel>
            <Input id="name" type="text" {...register('name')} />
            <ErrorMessage error={formState.errors?.name} />
          </FormControl>
          <Stack alignItems="flex-start" direction={{ base: 'column', md: 'row' }} spacing="4">
            <Controller
              control={control}
              name="type"
              render={({ field }): JSX.Element => (
                <FormControl isInvalid={!!formState.errors?.type}>
                  <FormLabel htmlFor="type">{t('OperationType')}</FormLabel>
                  <Select
                    id="type"
                    isDisabled={!!operationType && operationType !== OperationType.UNKNOWN}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    options={typeOptions}
                    getOptionLabel={(option): string =>
                      option.label && i18nOperationTypes(option.label as OperationType)
                    }
                    {...field}
                  />
                  <ErrorMessage error={formState.errors?.type?.value} />
                </FormControl>
              )}
            />
            <FormControl isInvalid={!!formState.errors.closingDate}>
              <FormLabel htmlFor="closingDate">{t('OperationEndDate')}</FormLabel>
              <Input id="closingDate" type="date" {...register('closingDate')} />
              <ErrorMessage error={formState.errors.closingDate} />
            </FormControl>
          </Stack>
          <Stack alignItems="flex-start" direction={{ base: 'column', md: 'row' }} spacing="4">
            <Controller
              control={control}
              name="asset"
              render={({ field }): JSX.Element => (
                <FormControl isInvalid={!!formState.errors?.asset}>
                  <FormLabel htmlFor="asset">{t('AssetType')}</FormLabel>
                  <OperationAssetSelect
                    id="asset"
                    isAddButtonDisabled={draftOptions.length > 0 || tmpDraftOptions.length > 0}
                    menuPlacement="auto"
                    operationType={selectedOperationType.value as OperationType}
                    options={filteredAssetOptions}
                    value={field.value}
                    onAddAsset={handleCreateAsset}
                    onChange={field.onChange}
                    onEditAsset={handleEditAsset}
                  />
                  <ErrorMessage error={formState.errors.asset?.value} />
                </FormControl>
              )}
            />
            <FormControl isInvalid={!!formState.errors?.sharePrice}>
              <FormLabel htmlFor="sharePrice">
                <HStack>
                  <Text>{t('PricePerShare')}</Text>
                  <Tooltip hasArrow label={t('PricePerShareTooltip')} placement="top">
                    <Icon as={InfoIcon} color="gray.600" />
                  </Tooltip>
                </HStack>
              </FormLabel>
              <InputGroup>
                <Input id="sharePrice" step="0.01" type="number" {...register('sharePrice')} />
                <InputRightElement color="gray.500">€</InputRightElement>
              </InputGroup>
              <ErrorMessage error={formState.errors.sharePrice} />
            </FormControl>
          </Stack>
        </Stack>
      </form>
      {createAGAModal.isOpen && (
        <AGACreateModal
          editAsset={currentAsset}
          isOpen={createAGAModal.isOpen}
          onClose={handleCloseAGAModal}
          onComplete={handleCreateComplete}
        />
      )}
      {createBSAModal.isOpen && (
        <BSACreateModal
          editAsset={currentAsset}
          isOpen={createBSAModal.isOpen}
          onClose={handleCloseBSAModal}
          onComplete={handleCreateComplete}
        />
      )}
      {createBSAAIRModal.isOpen && (
        <BSAAIRCreateModal
          editAsset={currentAsset}
          isOpen={createBSAAIRModal.isOpen}
          onClose={handleCloseBSAAIRModal}
          onComplete={handleCreateComplete}
        />
      )}
      {createBSPCEModal.isOpen && (
        <BSPCECreateModal
          editAsset={currentAsset}
          isOpen={createBSPCEModal.isOpen}
          onClose={handleCloseBSPCEModal}
          onComplete={handleCreateComplete}
        />
      )}
      {createBondModal.isOpen && assetType && (
        <BondCreateModal
          assetType={assetType}
          editAsset={currentAsset}
          isOpen={createBondModal.isOpen}
          onClose={handleCloseBondModal}
          onComplete={handleCreateComplete}
        />
      )}
    </>
  );
}
