import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetCompanyQuery } from '@blockpulse3/graphql/hooks';
import {
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';
import { CompleteCompanyForm } from '@blockpulse3/web-client/workspace';

type Props = {
  /* ** Callback on form submit ** */
  onSubmit: () => void;
} & Omit<ResponsiveModalProps, 'children'>;

/**
 * OperationHeadlessCompanyModal.
 * Complete company informations before redirect to operation workflows.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationHeadlessCompanyModal({ onSubmit, ...props }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  /* ** From cache ** */
  const { data } = useGetCompanyQuery({ variables: { companyId }, skip: !companyId });

  const handleFormSubmit = (): void => {
    onSubmit();
  };

  const companyName = data?.company.name;

  return (
    <ResponsiveModal scrollBehavior="inside" {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">{t('CompleteCompanyInfoOf', { companyName })}</ModalHeader>
        <ModalBody scrollBehavior="auto">
          <Alert mb="16px" status="info">
            <AlertIcon />
            <Stack spacing="3">
              <AlertTitle>{t('InitiateOperationWithoutRegistryWarning')}</AlertTitle>
            </Stack>
          </Alert>
          <CompleteCompanyForm onSubmit={handleFormSubmit} />
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button variant="secondary" onClick={props.onClose}>
            {t('Cancel')}
          </Button>
          <Button form="complete-company" type="submit">
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
