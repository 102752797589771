import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerProps,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import {
  OperationPanelActivityFeed,
  OperationPanelComments,
  OperationPanelHoldingMethod,
  OperationPanelReminder,
  OperationPanelTags,
} from '../..';
import { TransactionPanelDocuments } from './TransactionPanelDocuments';
import { TransactionPanelIdentityCard } from './TransactionPanelIdentityCard';
import { TransactionPanelOperation } from './TransactionPanelOperation';
import { TransactionPanelProgressSteps } from './TransactionPanelProgressSteps';

type Props = Omit<DrawerProps, 'children'>;

/**
 * TransactionPanel.
 * Side panel of the secondary operation table view. Visibility is triggered by
 * `useDisclosureLink()` in `<SecondaryOperationTransactions />`.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function TransactionPanel({ ...props }: Props): JSX.Element {
  const t = useTranslations();

  return (
    <Drawer size="sm" {...props}>
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody pb="16" pt="4" px="0">
          <Stack spacing="4">
            <TransactionPanelIdentityCard />
            <Divider />
            <TransactionPanelOperation />
            <Divider />
            <TransactionPanelProgressSteps />
            <Divider />
            <TransactionPanelDocuments />
            <Divider />
            <OperationPanelHoldingMethod />
            <Divider />
            <OperationPanelReminder />
            <Divider />
            <OperationPanelTags />
            <Divider />
            <Stack px="4" spacing="4">
              <Tabs isLazy defaultIndex={0}>
                <TabList>
                  <Tab>{t('Activity', { nb: 2 })}</Tab>
                  <Tab>{t('Comment', { nb: 2 })}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <OperationPanelActivityFeed />
                  </TabPanel>
                  <TabPanel>
                    <OperationPanelComments />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export type TransactionPanelProps = Props;
