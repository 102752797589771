import { Card, CardBody, Skeleton, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { useAuthUser, useIdentity, useManagedIndividual } from '@blockpulse3/web-client/auth';

import { NotAllowedCard } from '../NotAllowedCard';
import { InlineIBANDisplay } from './InlineIBANDisplay';
import { UpdateIBANFormModal } from './UpdateIBANFormModal';

type Props = {
  onComplete?: () => void;
};

/**
 * UpdateDistributionIBAN.
 *
 * @returns {JSX.Element}
 */
export function UpdateDistributionIBAN({ onComplete = noop }: Props): JSX.Element {
  const t = useTranslations();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user: authUser } = useAuthUser();
  const { individual } = useManagedIndividual();
  const { identity } = useIdentity();

  if (!authUser) {
    return <NotAllowedCard h="400px" />;
  }

  if (!individual) {
    return <Skeleton h="400px" />;
  }

  return (
    <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }}>
      <Text flexBasis={{ lg: 250 }} fontSize="lg" fontWeight="medium">
        {t('MyExternalIBANs')}
      </Text>
      <Card maxW={{ lg: '3xl' }} w="full">
        <CardBody>
          <InlineIBANDisplay iban={identity?.distributionIban} onAdd={onOpen} onEdit={onOpen} />
        </CardBody>
      </Card>
      {isOpen && (
        <UpdateIBANFormModal
          defaultIban={identity?.distributionIban}
          isOpen={isOpen}
          onClose={onClose}
          onComplete={onComplete}
        />
      )}
    </Stack>
  );
}
