import { Card, CardBody, HStack, Heading, Skeleton, Stack, Text } from '@chakra-ui/react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { CompanyStatus, useGetCompanyQuery } from '@blockpulse3/graphql/hooks';
import { CompanyIdentityAvatar, ErrorQueryCard } from '@blockpulse3/ui/commons';

/**
 * SPVHeader.
 *
 * @returns {JSX.Element}
 */
export function SPVHeader(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useGetCompanyQuery({ variables: { companyId } });

  if (data?.company.status === CompanyStatus.RUNNING) {
    navigate(generatePath(routes.company.href, { companyId }));
  }

  if (loading) {
    return (
      <Skeleton>
        <Card h="150px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="150px" />;
  }

  if (!data) {
    return <ErrorQueryCard h="150px" />;
  }

  const companyName = data.company.name;

  return (
    <Card>
      <HStack as={CardBody} spacing="8">
        <CompanyIdentityAvatar boxSize="32" />
        <Stack spacing="3">
          <Text color="gray.500" fontSize="xs" fontWeight="semibold" textTransform="uppercase">
            {t('Registration', { nb: 1 })}
          </Text>
          <Heading fontWeight="bold">{companyName}</Heading>
        </Stack>
      </HStack>
    </Card>
  );
}
