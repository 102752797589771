import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { UserInfosFragment, useUpdateIndividualIdentityMutation } from '@blockpulse3/graphql/hooks';
import { ErrorMessage, ResponsiveModalFooter } from '@blockpulse3/ui/commons';

import { genderPickerFormDefaultValues, schema } from './schema';
import { GenderPickerForm } from './types';

type Props = {
  user: UserInfosFragment | null;
  defaultValues?: GenderPickerForm;
  onSubmit: () => void;
};

export function GenderPicker({
  onSubmit,
  user,
  defaultValues = genderPickerFormDefaultValues,
}: Props): JSX.Element {
  const t = useTranslations();
  const [updateIndividualIdentity] = useUpdateIndividualIdentityMutation();

  const { control, formState, handleSubmit } = useForm<GenderPickerForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isOpen: isModalOpen, onClose: onModalClose } = useDisclosure({ defaultIsOpen: true });

  const handleFormSubmit = (data: GenderPickerForm): void => {
    updateIndividualIdentity({
      variables: {
        updateIndividualIdentityInput: {
          individualIdentityId: user?.individualIdentity?.id || '',
          gender: data.gender,
        },
      },
    });
    onModalClose();
    onSubmit();
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isModalOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('CompletePersonalInfo')}</ModalHeader>
        <ModalBody>
          <Text>{t('UpdateUserProfileInfo')}</Text>
          <Text pt="2">{t('CivilStatus')}</Text>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <form id="gender-form" onSubmit={handleSubmit(handleFormSubmit)}>
              <Controller
                control={control}
                name="gender"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.gender}>
                    <FormLabel htmlFor="gender"></FormLabel>
                    <RadioGroup defaultValue={defaultValues.gender} id="gender" {...field}>
                      <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 2, md: 4 }}>
                        <Radio value="M">{t('Mr')}</Radio>
                        <Radio value="F">{t('Mrs')}</Radio>
                      </Stack>
                    </RadioGroup>
                    <ErrorMessage error={formState.errors?.gender} />
                  </FormControl>
                )}
              />
            </form>
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button form="gender-form" type="submit">
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </Modal>
  );
}

export type GenderPickerProps = Props;
