import { Button, HStack, Icon } from '@chakra-ui/react';
import { SwitchHorizontalIcon } from '@heroicons/react/solid';
import { useParams } from 'react-router-dom';

import { useWorkspaceSwitcher } from '../provider';
import { SwitcherButtonCompany } from './SwitcherButtonCompany';
import { SwitcherButtonUser } from './SwitcherButtonUser';

type Props = unknown;

/**
 * SwitcherButton.
 * Component rendering button to switch workspace.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SwitcherButton(): JSX.Element {
  const { companyId = '' } = useParams();

  const { onSwitcherOpen } = useWorkspaceSwitcher();

  return (
    <HStack justifyContent="space-between">
      <Button
        data-cy="workspace-switcher"
        rightIcon={<Icon as={SwitchHorizontalIcon} />}
        variant="workspace"
        onClick={onSwitcherOpen}
      >
        {companyId ? <SwitcherButtonCompany /> : <SwitcherButtonUser />}
      </Button>
    </HStack>
  );
}

export type WorkspaceSwitcherButtonProps = Props;
