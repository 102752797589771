export enum FormErrors {
  AmountGreaterThanHardCap = 'AMOUNT_GREATER_THAN_HARD_CAP',
  AmountGreaterThanShareCap = 'AMOUNT_GREATER_THAN_SHARE_CAP',
  AmountLowerThanShareCap = 'AMOUNT_LOWER_THAN_SHARE_CAP',
  CarriedPercentageFormat = 'CARRIED_PERCENTAGE_FORMAT',
  DropzoneDefault = 'DROPZONE_DEFAULT',
  DropzoneInvalidTemplate = 'DROPZONE_INVALID_TEMPLATE',
  EmailDomain = 'EMAIL_DOMAIN',
  EmailFormat = 'EMAIL_FORMAT',
  FileRequired = 'FILE_REQUIRED',
  FileTooLarge = 'FILE_TOO_LARGE',
  InvalidUrl = 'INVALID_URL',
  FundsSourceCertificateMissing = 'FUNDS_SOURCE_CERTIFICATE_MISSING',
  HardCapGreaterThanNominalValue = 'HARDCAP_GREATER_THAN_NOMINAL_VALUE',
  IntegerFormat = 'INTEGER_FORMAT',
  InvalidFileFormat = 'INVALID_FILE_FORMAT',
  MaximalAmount = 'MAXIMAL_AMOUNT',
  MaximalAmountLessThanMinimal = 'MAXIMAL_AMOUNT_GREATER_LESS_THAN',
  MaximalCapitalLessThanMinimal = 'MAXIMAL_CAPITAL_GREATER_LESS_THAN',
  MinimalAmount = 'MINIMAL_AMOUNT',
  MinimalAmountGreaterThanMaximal = 'MINIMAL_AMOUNT_GREATER_THAN_MAXIMAL',
  MinimalAmountGreaterThanNominalValue = 'MINIMAL_AMOUNT_GREATER_THAN_NOMINAL_VALUE',
  MinimalCapitalGreaterThanMaximal = 'MINIMAL_CAPITAL_GREATER_THAN_MAXIMAL',
  NonNegativeNumber = 'NON_NEGATIVE_NUMBER',
  PasswordFormat = 'PASSWORD_FORMAT',
  PasswordMatch = 'PASSWORD_MATCH',
  PasswordStrength = 'PASSWORD_STRENGTH',
  PercentageFormat = 'PERCENTAGE_FORMAT',
  PhoneAlreadyInUse = 'PHONE_ALREADY_IN_USE',
  PhoneFormat = 'PHONE_FORMAT',
  PositiveFormat = 'POSITIVE_FORMAT',
  PreferredShareCount = 'PREFERRED_SHARE_COUNT',
  RequiredField = 'REQUIRED_FIELD',
  SoftGreaterThanHardCap = 'SOFTCAP_GREATER_THAN_HARDCAP',
  UboPercentageFormat = 'UBO_PERCENTAGE_FORMAT',
  UnauthorizedChar = 'UNAUTHORIZED_CHARACTER',
  ValidDateFormat = 'VALID_DATE_FORMAT',
  ValuationField = 'VALUATION_FIELD',
  PastDate = 'PAST_DATE',
  FutureDate = 'FUTURE_DATE',
  TodayOrFutureDate = 'TODAY_OR_FUTURE_DATE',
  MaximalAssetAmount = 'MAXIMAL_ASSET_AMOUNT',
  BadJsonFormat = 'BAD_JSON_FORMAT',
  DateAfterEndOfOperation = 'DATE_AFTER_END_OF_OPERATION',
  DateBeforeCreationDate = 'DATE_BEFORE_CREATION_DATE',
  DurationLongerThanVesting = 'DURATION_LONGER_THAN_VESTING',
}
