import { useDisclosure } from '@chakra-ui/react';
import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { MergedIdentityChoiceStep } from '@blockpulse3/graphql/hooks';

import { SubscriptionStep } from '../../SubscriptionStep';
import { IdentityChoiceStepModal } from './IdentityChoiceStepModal';

type Props = {
  isDisabled: boolean;
  subscriptionStep: MergedIdentityChoiceStep;
};

export function IdentityChoiceStep({ subscriptionStep, isDisabled }: Props): JSX.Element {
  const t = useTranslations();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SubscriptionStep
        icon={SwitchHorizontalIcon}
        isDisabled={isDisabled}
        status={subscriptionStep.status}
        title={t('ChooseIdentity')}
        type={subscriptionStep.type}
        onClick={onOpen}
      />
      {isOpen && <IdentityChoiceStepModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
}
