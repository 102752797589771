import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { CompanyCapitalType, useGetOperationQuery } from '@blockpulse3/graphql/hooks';

import { NewFundraisingUploadBankDetails } from '../commons';

type Props = unknown;

export function NewCrowdfundingFundraisingCapitalFixedBankDetails(): JSX.Element {
  const navigate = useNavigate();
  const { operationId = '', companyId = '' } = useParams();

  const fundraisingRoutes = routes.company.newFundraising.crowdfunding;

  useGetOperationQuery({
    variables: {
      operationId,
    },
    onCompleted: (data) => {
      if (data?.operation.company.capitalType !== CompanyCapitalType.FIXED) {
        navigate(generatePath(routes.company.href, { companyId }));
      }
    },
    skip: !operationId,
  });

  const handleFormSubmit = (): void => {
    if (!companyId) return;
    navigate('../' + fundraisingRoutes.edit.summary.href);
  };

  const handleFormCancel = (): void => {
    navigate('../' + fundraisingRoutes.edit.documentation.href);
  };

  return (
    <NewFundraisingUploadBankDetails onCancel={handleFormCancel} onSubmit={handleFormSubmit} />
  );
}

export type NewCrowdfundingFundraisingCapitalFixedBankDetailsProps = Props;
