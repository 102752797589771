import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerProps,
  HStack,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useTranslations } from 'use-intl';

import { ContractType } from '@blockpulse3/data/shared';
import {
  AssetType,
  BalanceInfosFragment,
  Identity,
  IdentityType,
  OperationDocumentType,
  TokenDurationUnit,
  useGetAssetQuery,
  useGetDocumentPdfUrlLazyQuery,
  useGetMovementsByAssetAndIdentityQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberPercentage } from '@blockpulse3/helpers';
import {
  ASSET_COLOR_MAP,
  AssetBadge,
  DocumentLink,
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';

import { CompanyValuationsCard } from '../CompanyValuationsCard';
import { TransactionsList } from '../TransactionsList';

type Props = {
  balance: BalanceInfosFragment;
  identityId: Identity['id'] | null;
} & Omit<DrawerProps, 'children'>;

/**
 * BondAssetSidePanel.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function BondAssetSidePanel({ balance, identityId, ...props }: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValue = useTranslations('DocumentValues');
  const i18nDurationsUnit = useTranslations('DurationUnits');

  const { signer, contact } = balance.asset.company;

  const assetReq = useGetAssetQuery({
    variables: {
      companyId: balance.asset.company.id,
      assetId: balance.asset.id,
    },
    skip: !balance.asset.id,
  });

  const movementsReq = useGetMovementsByAssetAndIdentityQuery({
    variables: { assetId: balance.asset.id, identityId: identityId || '' },
    skip: !identityId || !balance.asset.id,
  });

  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();

  if (assetReq.loading || movementsReq.loading) {
    return (
      <Drawer size="md" {...props}>
        <DrawerContent maxW={{ base: 'lg', xl: 'calc((100vw - var(--chakra-sizes-64))* 0.33)' }}>
          <DrawerCloseButton />
          <DrawerBody p="0">
            <Stack alignItems="center" h="full" justifyContent="center" py="4" spacing="4">
              <Spinner />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  if (
    !assetReq.data ||
    assetReq.error ||
    assetReq.data.asset?.token?.__typename !== 'BondTokenModel'
  ) {
    return (
      <Drawer size="md" {...props}>
        <DrawerContent maxW={{ base: 'lg', xl: 'calc((100vw - var(--chakra-sizes-64))* 0.33)' }}>
          <DrawerCloseButton />
          <DrawerBody p="0">
            <ErrorQueryCard height="full" width="full" />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  const bondToken = assetReq.data.asset?.token;
  const assetDocuments = assetReq.data.asset?.documents || [];
  const movements = movementsReq.data?.getMovementsByAssetAndIdentity || [];

  const { durationUnit, interestRate, interestPeriod, maturityDate } = bondToken;

  const handleDocumentClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        window.open(pdfUrl, '_blank');
      },
    });
  };

  const getPeriodicity = (): string => {
    let isMonth, isYear;

    const defaultLabel = `${interestPeriod} ${i18nDurationsUnit(
      durationUnit as keyof IntlMessages['DurationUnits'],
    )}`;

    switch (durationUnit) {
      case TokenDurationUnit.day: {
        isMonth = interestPeriod === 30 || interestPeriod === 31;
        isYear = interestPeriod === 365;
        break;
      }
      case TokenDurationUnit.month: {
        isMonth = interestPeriod === 1;
        isYear = interestPeriod === 12;
        break;
      }
      default: {
        return defaultLabel;
      }
    }
    if (isYear) return t('YearShort', { nb: 1 });
    if (isMonth) return t('DurationUnits.month');
    return defaultLabel;
  };

  const repIndividualIdentity = contact?.individualIdentity || signer.individualIdentity;

  return (
    <Drawer size="md" {...props}>
      <DrawerContent
        borderColor={ASSET_COLOR_MAP[balance.asset.assetType]}
        borderStyle="solid"
        borderTopWidth="2px"
        maxW={{ base: 'lg', xl: 'calc((100vw - var(--chakra-sizes-64))* 0.33)' }}
      >
        <DrawerCloseButton />
        <DrawerBody p="0">
          <Stack pb="4" spacing="6">
            <Stack bg="gray.50" p="4" spacing="1">
              <CompanyValuationsCard
                balance={balance}
                m="-4"
                mb="0"
                underlyingAssetType={AssetType.BOND}
              />
              <HStack alignItems="start" fontSize="sm" justifyContent="space-between">
                <Text color="gray.500" fontWeight="500">
                  {t('Asset', { nb: balance.total })}
                </Text>
                <HStack>
                  {balance.subscribedAmount > 0 && (
                    <HStack>
                      <AssetBadge type={balance.asset.assetType} value={balance.subscribedAmount} />
                      <Text fontSize="sm">- {balance.asset.name}</Text>
                    </HStack>
                  )}
                </HStack>
              </HStack>
              <HStack fontSize="sm" justifyContent="space-between">
                <Text color="gray.500" fontWeight="500">
                  {t('InterestRate')}
                </Text>
                <Text fontWeight="500">
                  {formatNumberPercentage(interestRate || 0)}
                  {` / ${getPeriodicity().toLowerCase()}`}
                </Text>
              </HStack>
              <HStack fontSize="sm" justifyContent="space-between">
                <Text color="gray.500" fontWeight="500">
                  {t('MaturityDate')}
                </Text>
                <Text fontWeight="500">{dayjs(maturityDate).format('D MMMM YYYY')}</Text>
              </HStack>
            </Stack>
            <TransactionsList
              contractType={ContractType.BOND_TOKEN}
              identityId={identityId}
              movements={movements}
            />
            <Divider />
            {assetDocuments.length && (
              <>
                <Stack px="4" spacing="2">
                  <Text color="gray.600" fontWeight="600">
                    {t('CompanyDocuments')}
                  </Text>
                  <Stack spacing="2">
                    {assetDocuments.map((document) => {
                      if (!document) return null;
                      return (
                        <DocumentLink
                          key={document.id}
                          fileName={
                            document.type === OperationDocumentType.CUSTOM_FILE
                              ? document.title
                              : i18nDocumentValue(
                                  document.type as keyof IntlMessages['DocumentValues'],
                                )
                          }
                          onClick={(): void => handleDocumentClick(document.id)}
                        />
                      );
                    })}
                  </Stack>
                </Stack>
                <Divider />
              </>
            )}
            <Stack px="4" spacing="2">
              <Text color="gray.600" fontWeight="600">
                {t('Contact', { nb: 1 })}
              </Text>
              <IdentityCard bg="gray.50" p="3">
                <IdentityAvatar type={IdentityType.INDIVIDUAL} />
                <IdentityCardTitle isChecked>
                  <Text>{repIndividualIdentity?.name}</Text>
                </IdentityCardTitle>
                <IdentityCardDescription>{repIndividualIdentity?.email}</IdentityCardDescription>
              </IdentityCard>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export type BondAssetSidePanelProps = Props;
