import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  IconButton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DocumentTextIcon, TrashIcon } from '@heroicons/react/outline';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import {
  DocumentInfosFragment,
  OperationDocumentType,
  useDeleteOperationDocumentMutation,
  useGetDocumentPdfUrlLazyQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';

import { AddDocumentCustomFileOperationModal } from '../AddOperationDocuments';
import { DeleteConfirmModal } from '../DeleteConfirmModal';
import { DocumentLink } from '../DocumentLink';
import { CONTENT_LOADING, PreviewDocumentModal } from '../DocumentModal';
import { useSuccessToast } from '../Toast';

export function TabPanelOperationDocuments(): JSX.Element {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const { operationId = '' } = useParams();

  const confirmRef = useRef(null);

  const successToast = useSuccessToast();

  const previewModal = useDisclosure();
  const addDocumentModal = useDisclosure();
  const confirmDeleteModal = useDisclosure();

  const getOperationReq = useGetOperationQuery({ variables: { operationId }, skip: !operationId });
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();
  const [deleteOperationDocument, { loading: isDeleteOperationLoading }] =
    useDeleteOperationDocumentMutation();

  const operation = getOperationReq.data?.operation;

  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>();
  const [previewTitle, setPreviewTitle] = useState<string | null>();
  const [documentPdfUrl, setDocumentPdfUrl] = useState<string | symbol | null>(CONTENT_LOADING);

  const documents = operation?.documents;

  const handleDocumentClick = (document: DocumentInfosFragment): void => {
    setDocumentPdfUrl(CONTENT_LOADING);
    previewModal.onOpen();

    getDocumentPdfUrl({
      variables: {
        documentId: document.id,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        setDocumentPdfUrl(pdfUrl);
        setPreviewTitle(document.title);
      },
    });
  };

  const handleFileDelete = (): void => {
    if (!operationId || !selectedDocumentId) return;

    deleteOperationDocument({
      variables: {
        deleteOperationDocumentInput: {
          operationId,
          documentType: OperationDocumentType.CUSTOM_FILE,
          documentId: selectedDocumentId,
        },
      },
      onCompleted: () => {
        successToast({ title: t('DocumentDeleted') });
        getOperationReq.refetch();
        confirmDeleteModal.onClose();
      },
    });
  };

  const handleDeleteClick = (documentId: string): void => {
    setSelectedDocumentId(documentId);
    confirmDeleteModal.onOpen();
  };

  let customFileIndex = 0;

  return (
    <Card>
      <CardHeader>
        <Heading size="md">{t('DocumentRelatedOperation', { nb: documents?.length })}</Heading>
      </CardHeader>
      <CardBody>
        {!documents ? (
          <Stack h="150px" layerStyle="emptyState">
            <Icon as={DocumentTextIcon} boxSize="40px" color="gray.500" />
            <Text>{t('DocumentNotFoundInOperation')}</Text>
            <Stack>
              <Button
                leftIcon={<AddIcon />}
                size="sm"
                variant="secondary"
                w="fit-content"
                onClick={addDocumentModal.onOpen}
              >
                {t('AddAnnexDocument')}
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing="4">
            {documents.map((doc, index) => {
              let fileName = i18nDocumentTitle(doc.type as IntlDocumentValues);
              if (doc.type === OperationDocumentType.CUSTOM_FILE) {
                fileName =
                  doc.document?.title === 'CUSTOM_FILE'
                    ? `${t('Annex', { nb: 1 })} ${++customFileIndex}`
                    : doc.document?.title;
              }
              return (
                <HStack key={doc.id}>
                  <DocumentLink
                    fileName={fileName}
                    onClick={(): void => handleDocumentClick(doc.document)}
                  />
                  {doc.type === OperationDocumentType.CUSTOM_FILE && (
                    <IconButton
                      aria-label="delete-document"
                      icon={<Icon as={TrashIcon} boxSize="4" />}
                      size="xs"
                      variant="icon-delete"
                      onClick={(): void => handleDeleteClick(doc.document.id)}
                    />
                  )}
                </HStack>
              );
            })}
            <Button
              leftIcon={<AddIcon />}
              size="sm"
              variant="secondary"
              w="fit-content"
              onClick={addDocumentModal.onOpen}
            >
              {t('AddAnnexDocument')}
            </Button>
          </Stack>
        )}
      </CardBody>
      {previewModal.isOpen && (
        <PreviewDocumentModal
          isOpen={previewModal.isOpen}
          src={documentPdfUrl}
          title={previewTitle || ''}
          onClose={previewModal.onClose}
        />
      )}
      {addDocumentModal.isOpen && (
        <AddDocumentCustomFileOperationModal
          isOpen={addDocumentModal.isOpen}
          onClose={addDocumentModal.onClose}
        />
      )}
      {confirmDeleteModal.isOpen && (
        <DeleteConfirmModal
          isLoading={isDeleteOperationLoading}
          isOpen={confirmDeleteModal.isOpen}
          leastDestructiveRef={confirmRef}
          subtitle={t('DefinitiveAction')}
          title={t('DeleteDocumentQuestion')}
          onClose={confirmDeleteModal.onClose}
          onDelete={handleFileDelete}
        />
      )}
    </Card>
  );
}
