import { Icon, Stack, Text } from '@chakra-ui/react';
import { TemplateIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  CompanyType,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';

import { CapTableInformations } from './CapTableInformations';
import { CapTableShareholders } from './CapTableShareholders';

type Props = unknown;

/**
 * CapTableView.
 * Main captable view, rendering specific captable informations and the list of shareholders.
 *
 * @returns {JSX.Element}
 */
export function CapTableView(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    fetchPolicy: 'network-only',
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  if (company?.type === CompanyType.PARTIAL) {
    return (
      <Stack layerStyle="emptyState">
        <Icon as={TemplateIcon} boxSize="40px" color="gray.500" />
        <Stack alignItems="center" spacing="0">
          <Text>{t('PartiallyImportedCompany')}</Text>
          <Text>{t('InsufficientInfoForCapitalTable')}</Text>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack spacing="4">
      <CapTableInformations />
      <CapTableShareholders />
    </Stack>
  );
}

export type CapTableViewProps = Props;
