import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  CreateSubscriptionInput,
  CreateSubscriptionMutation,
  IdentityInfosFragment,
  IdentityType,
  SubscriptionSide,
  UpdateSubscriptionMutation,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
} from '@blockpulse3/graphql/hooks';
import { ResponsiveModal, ResponsiveModalProps, useErrorToast } from '@blockpulse3/ui/commons';

import { SubscriberInformationsForm } from './Forms/SubscriberInformationsForm';
import { IOperationSubscriber } from './types';
import { isLegalSubscriber, operationSubscriberDefaultValues } from './utils';

type Props = {
  operationId: string;
  subscriptionId?: string;
  defaultValues?: IOperationSubscriber;
  amountRangeRequired: boolean;
  amountRequired: boolean;
  feesRequired: boolean;
  modalTitle: string;
  modalSubmitText: string;
  onClose: () => void;
  onComplete: (
    data: CreateSubscriptionMutation | UpdateSubscriptionMutation,
    subscription: CreateSubscriptionInput,
    isLegal: boolean,
  ) => void;
  setInvalidLegalSubscriber?: (
    company: IdentityInfosFragment['companyIdentity'] | undefined,
  ) => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

/**
 * OperationSubscribersModal.
 * Form for the Parameters informations of an Operation.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationSubscribersModal<T extends IOperationSubscriber>({
  operationId,
  subscriptionId,
  amountRangeRequired,
  amountRequired,
  feesRequired,
  modalTitle,
  modalSubmitText,
  defaultValues = operationSubscriberDefaultValues,
  onClose,
  onComplete,
  setInvalidLegalSubscriber = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const [createSubscription] = useCreateSubscriptionMutation();
  const [updateSubscription] = useUpdateSubscriptionMutation();

  const handleModalClose = (): void => {
    onClose();
  };

  const handleFormSubmit = (data: T): void => {
    setInvalidLegalSubscriber(undefined);
    const { subscriber } = data;
    const isLegal = isLegalSubscriber(subscriber);

    const subscription = {
      side: SubscriptionSide.BUYER,
      type: isLegal ? IdentityType.COMPANY : IdentityType.INDIVIDUAL,
      name: isLegal ? subscriber.name : null,
      registrationNumber: isLegal ? subscriber.identificationNumber : null,
      firstName: !isLegal ? subscriber.firstName : null,
      lastName: !isLegal ? subscriber.lastName : null,
      email: subscriber.email.toLowerCase(),
      investAmount: 'amount' in subscriber ? subscriber.amount : 0,
      admissionFees: subscriber.admissionFees,
      minimalAmount: subscriber.minimalAmount,
      maximalAmount: subscriber.maximalAmount,
    };

    if (subscriptionId && subscriptionId !== '') {
      updateSubscription({
        variables: {
          updateSubscriptionInput: {
            subscriptionId,
            ...subscription,
          },
        },
        onCompleted: (data) => {
          onComplete(data, subscription, isLegal);
        },
        onError: () => {
          errorToast({ title: t('SubscriberCreationError') });
        },
      });
    } else if (operationId) {
      createSubscription({
        variables: {
          operationId,
          createSubscriptionInput: { ...subscription },
        },
        onCompleted: (data) => {
          onComplete(data, subscription, isLegal);
        },
        onError: () => {
          errorToast({ title: t('SubscriberCreationError') });
        },
      });
    }
    handleModalClose();
  };

  return (
    <ResponsiveModal onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SubscriberInformationsForm
            amountRangeRequired={amountRangeRequired}
            amountRequired={amountRequired}
            defaultValues={defaultValues}
            feesRequired={feesRequired}
            modalSubmitText={modalSubmitText}
            onClose={handleModalClose}
            onSubmit={handleFormSubmit}
          />
        </ModalBody>
      </ModalContent>
    </ResponsiveModal>
  );
}
