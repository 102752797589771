import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Circle,
  HStack,
  Heading,
  Icon,
  Progress,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ArchiveIcon, ChartPieIcon, TicketIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetOptionPoolOperationQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberInt, formatNumberPercentage } from '@blockpulse3/helpers';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

/**
 * OptionPoolInformations.
 * Information section, such as percentage, amount... of the Option pool.
 *
 * @returns {JSX.Element}
 */
export function OptionPoolInformations(): JSX.Element | null {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const { data, loading, error } = useGetOptionPoolOperationQuery({
    variables: { operationId },
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="200px" />;
  }

  if (!data) {
    return <ErrorQueryCard h="200px" />;
  }

  const option = data.operation?.asset;
  if (
    !option ||
    option.token?.__typename !== 'OptionTokenModel' ||
    option.tokenData?.__typename !== 'OptionTokenModelData'
  ) {
    return null;
  }

  const optionData = option.tokenData;

  return (
    <>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '8' }}>
        <Card w="full">
          <CardHeader>
            <HStack spacing="4" justifyContent="space-between">
              <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
                {t('OptionTotal')}
              </Heading>
              <Icon as={TicketIcon} color="gray.500" boxSize="5" />
            </HStack>
          </CardHeader>
          <CardBody pt="2">
            <Text color="gray.800" fontSize="lg" fontWeight="700">
              {formatNumberInt(option.token.initialSupply)}
            </Text>
          </CardBody>
        </Card>
        <Card w="full">
          <CardHeader>
            <HStack spacing="4" justifyContent="space-between">
              <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
                {t('UnallocatedOptions')}
              </Heading>
              <Icon as={ArchiveIcon} color="gray.500" boxSize="5" />
            </HStack>
          </CardHeader>
          <CardBody pt="2">
            <Text color="gray.800" fontSize="lg" fontWeight="700">
              {formatNumberInt(option.token.remainingSupply)}
            </Text>
          </CardBody>
        </Card>
        <Card w="full">
          <CardHeader>
            <HStack spacing="4" justifyContent="space-between">
              <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
                {t('CapitalPercentage')}
              </Heading>
              <Icon as={ChartPieIcon} color="gray.500" boxSize="5" />
            </HStack>
          </CardHeader>
          <CardBody pt="2">
            <Text color="gray.800" fontSize="lg" fontWeight="700">
              {formatNumberPercentage(optionData.shareCapitalPercentage)}
            </Text>
          </CardBody>
        </Card>
      </Stack>
      <Card>
        <CardBody as={Stack} spacing="4">
          <Stack>
            <HStack spacing="3">
              <HStack spacing="2">
                <Circle bgColor="primary" size="3" />
                <Text>
                  {t('ExercisedCount', {
                    nb: formatNumberPercentage(optionData.exercisedPercentage),
                  })}
                </Text>
              </HStack>
              <HStack spacing="2">
                <Circle bgColor="primary" opacity="0.6" size="3" />
                <Text>
                  {t('ExercisableCount', {
                    nb: formatNumberPercentage(optionData.totalVestedBalancePercentage),
                  })}
                </Text>
              </HStack>
              <HStack spacing="2">
                <Circle background="gray.300" size="3" />
                <Text>
                  {t('AssignedCount', {
                    nb: formatNumberPercentage(optionData.grantedPercentage),
                  })}
                </Text>
              </HStack>
            </HStack>
            <Box height="12px" position="relative">
              <Progress
                bg="transparent"
                height="full"
                position="absolute"
                rounded="md"
                top="0"
                value={optionData.exercisedPercentage}
                width="full"
                zIndex="3"
              />
              <Progress
                bg="transparent"
                height="full"
                position="absolute"
                rounded="md"
                top="0"
                value={optionData.totalVestedBalancePercentage}
                variant="pending"
                width="full"
                zIndex="2"
              />
              <Progress
                height="full"
                position="absolute"
                rounded="md"
                sx={{ '& > div': { backgroundColor: 'gray.300' } }}
                top="0"
                value={optionData.grantedPercentage}
                width="full"
                zIndex="1"
              />
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </>
  );
}
