import { HStack, Icon, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { ReceiptRefundIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import {
  OperationType,
  TransferReceivedData,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { IconButtonWithTooltip } from '@blockpulse3/ui/commons';

import { RefundModal } from '../../OperationPanelPayment';

type Props = {
  data: TransferReceivedData;
  date: Date;
  subscriptionId: string;
};

export function ActivityTransferReceived({ date, data, subscriptionId }: Props): JSX.Element {
  const t = useTranslations();

  const refundModal = useDisclosure();

  const subscriptionReq = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });
  const isFundraising =
    subscriptionReq.data?.subscription?.operation?.type === OperationType.FUNDRAISING;

  const { amount, bankTransferId, debtorName, label, reference } = data;

  return (
    <Stack spacing={1}>
      <HStack justifyContent="space-between" spacing="2">
        <Text fontSize="sm">
          {t.rich('AmountReceivedValidation', {
            amount: formatNumberCurrency(amount),
            important: (chunks) => (
              <Text as="span" fontWeight="600">
                {chunks}
              </Text>
            ),
          })}
        </Text>
        {isFundraising && (
          <>
            <IconButtonWithTooltip
              aria-label="refund"
              icon={<Icon as={ReceiptRefundIcon} boxSize="18px" color="gray.700" />}
              label={t('Refund')}
              size="sm"
              variant="secondary"
              onClick={refundModal.onOpen}
            />
            {refundModal.isOpen && (
              <RefundModal
                bankTransferId={bankTransferId}
                defaultAmount={amount}
                isOpen={refundModal.isOpen}
                subscriptionId={subscriptionId}
                onClose={refundModal.onClose}
              />
            )}
          </>
        )}
      </HStack>
      <Stack color="gray.500" spacing="2">
        <Stack bg="gray.50" paddingX="3" paddingY="2" rounded="md" spacing="1">
          {debtorName && (
            <HStack spacing="2">
              <Text>{t('Debtor', { nb: 1 })}</Text>
              <Text fontWeight="600">{debtorName}</Text>
            </HStack>
          )}
          <HStack spacing="2">
            <Text>{t('Label', { nb: 1 })}</Text>
            <Text fontWeight="600">{label || ' - '}</Text>
          </HStack>
          <HStack spacing="2">
            <Text>{t('Reference', { nb: 1 })}</Text>
            <Text fontWeight="600">{reference || ' - '}</Text>
          </HStack>
        </Stack>
        <Text>{formatDate(date, 'DD/MM/YYYY')}</Text>
      </Stack>
    </Stack>
  );
}

export type ActivityTransferReceivedProps = Props;
