import { AvatarProps } from '@chakra-ui/react';

import { IdentityType } from '@blockpulse3/graphql/hooks';

import { CompanyIdentityAvatar } from './CompanyIdentityAvatar';
import { UserIdentityAvatar } from './UserIdentityAvatar';

type Props = Omit<AvatarProps, 'src'> & {
  identity?: {
    type?: IdentityType;
    profilePicture?: string | null;
  };
  type?: IdentityType;
  src?: string | null;
};

/**
 * IdentityAvatar.
 * Avatar switch with the two types of Identity.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function IdentityAvatar({
  identity,
  type = IdentityType.COMPANY,
  ...props
}: Props): JSX.Element {
  const src = identity?.profilePicture || props.src || undefined;
  const identityType = identity?.type || type;

  return identityType === IdentityType.INDIVIDUAL ? (
    <UserIdentityAvatar src={src} {...props} />
  ) : (
    <CompanyIdentityAvatar src={src} {...props} />
  );
}

export type IdentityAvatarProps = Props;
