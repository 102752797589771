import { ThemeOverride } from '@chakra-ui/react';
import { createContext } from 'react';

import { noop } from '@blockpulse3/data/shared';

import { theme } from './theme';

export type ThemeContextType = {
  theme: ThemeOverride;
  setTheme: React.Dispatch<React.SetStateAction<ThemeContextType>>;
};

export const initTheme: ThemeContextType = {
  theme,
  setTheme: noop,
};

export const ThemeContext = createContext<ThemeContextType>(initTheme);
