import * as yup from 'yup';

import { requiredSelectField, requiredStringField } from '@blockpulse3/data/shared';
import { formatCountry } from '@blockpulse3/helpers';

import { SPVInformationsForm } from './types';

export const companyInformationSchema = yup.object({
  name: requiredStringField,
  address: yup.object({
    line: requiredStringField,
    city: requiredStringField,
    postalCode: requiredStringField,
    country: requiredSelectField,
  }),
  target: yup.object({
    name: requiredStringField,
    registrationNumber: requiredStringField,
    registrationEntity: requiredStringField,
  }),
  revocationMajority: requiredSelectField,
}) as yup.ObjectSchema<SPVInformationsForm>;

export const companyInformationsFormDefaults: SPVInformationsForm = {
  name: '',
  address: {
    line: '',
    city: '',
    postalCode: '',
    country: { label: formatCountry('FRA'), value: 'FRA' },
  },
  target: {
    name: '',
    registrationEntity: '',
    registrationNumber: '',
  },
  revocationMajority: { label: '', value: null },
};
