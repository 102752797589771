import { HStack, Skeleton } from '@chakra-ui/react';

import { HoldingMethod, noop } from '@blockpulse3/data/shared';
import {
  BalanceInfosFragment,
  WalletInfosFragment,
  useGetBalancesByIdentityQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, ScrollableHStack } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { WalletCard } from './WalletCard';

type Props = {
  wallets: WalletInfosFragment[];
  holdingMethods: HoldingMethod[];
  onCardClick?: (balance: BalanceInfosFragment) => void;
};

export function WalletCards({ wallets, holdingMethods, onCardClick = noop }: Props): JSX.Element {
  const { identityId } = useIdentity();

  const { data, loading, error } = useGetBalancesByIdentityQuery({
    variables: {
      identityId: identityId || '',
      holdingMethods,
    },
    skip: !identityId,
  });
  const balances = data?.getBalancesByIdentity || [];

  // Group balances by asset
  const balancesByAssetObj = balances.reduce(
    (acc: Record<string, BalanceInfosFragment>, balance: BalanceInfosFragment) => {
      const key = balance.asset.id;
      if (acc[key]) {
        acc[key].total += balance.total;
        acc[key].subscribedAmount += balance.subscribedAmount;
        if (acc[key]?.investedAmount) {
          acc[key].investedAmount = (acc[key].investedAmount ?? 0) + (balance.investedAmount ?? 0);
        }
        if (acc[key]?.valueOfShares) {
          acc[key].valueOfShares = (acc[key].valueOfShares ?? 0) + (balance.valueOfShares ?? 0);
        }
      } else {
        acc[key] = { ...balance };
      }
      return acc;
    },
    {},
  );
  const balancesByAsset: BalanceInfosFragment[] = Object.values(balancesByAssetObj).sort((a, b) =>
    (a?.valueOfShares ?? 0) < (b?.valueOfShares ?? 0) ? 1 : -1,
  );

  if (loading) {
    return (
      <HStack overflowX="hidden" spacing="4">
        <Skeleton minH="200px" minW="150px"></Skeleton>
        <Skeleton minH="200px" minW="150px"></Skeleton>
        <Skeleton minH="200px" minW="150px"></Skeleton>
        <Skeleton minH="200px" minW="150px"></Skeleton>
        <Skeleton minH="200px" minW="150px"></Skeleton>
        <Skeleton minH="200px" minW="150px"></Skeleton>
        <Skeleton minH="200px" minW="150px"></Skeleton>
        <Skeleton minH="200px" minW="150px"></Skeleton>
      </HStack>
    );
  }

  if (error) {
    return <ErrorQueryCard h="200px" />;
  }

  return (
    <ScrollableHStack spacing="4">
      {balancesByAsset.map((balance) => {
        const displayedHoldingMethods = wallets
          .filter((wallet) => wallet.balances?.some((b) => b.asset.id === balance.asset.id))
          .map((wallet) => wallet.holdingMethod)
          .filter((holdingMethod) => holdingMethods.includes(holdingMethod));

        return (
          <WalletCard
            key={balance.asset.id}
            balance={balance}
            holdingMethods={displayedHoldingMethods}
            onClick={onCardClick}
          />
        );
      })}
    </ScrollableHStack>
  );
}
