import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  CheckIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import { routes } from '@blockpulse3/data/shared';
import {
  CompanyCapitalType,
  FundraisingWorkflowType,
  OperationStatus,
  useGetCompanyQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberPercentage } from '@blockpulse3/helpers';
import { BoxIcon, ErrorQueryCard } from '@blockpulse3/ui/commons';

import { crowdfundingSubscriptionPeriodOptions } from './schema';

type Props = unknown;

export function NewCrowdfundingFundraisingSummary(): JSX.Element {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const navigate = useNavigate();

  const { operationId = '', companyId = '' } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const { data, loading, error } = useGetOperationQuery({
    variables: {
      operationId,
    },
    skip: !operationId,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.operation.status !== OperationStatus.DRAFT) {
        navigate(generatePath(routes.company.href, { companyId }));
      }
    },
    onError: () => {
      navigate(generatePath(routes.company.href, { companyId }));
    },
  });

  const operation = data?.operation;
  const fundraising = operation?.fundraising;
  const fundraisingRoutes = routes.company.newFundraising.crowdfunding;

  if (loading) {
    return <Skeleton h="500px" w="full" />;
  }

  if (error || operation?.data?.__typename !== 'FundraisingData') {
    return (
      <HStack px="6" spacing="4">
        <ErrorQueryCard h="500px" w="full" />
      </HStack>
    );
  }

  const handleStepCancel = (): void => {
    if (!company) return;
    if (company.capitalType === CompanyCapitalType.FIXED) {
      navigate('../' + fundraisingRoutes.edit.iban.href);
    } else {
      navigate('../' + fundraisingRoutes.edit.documentation.href);
    }
  };

  const handleFundraisingSubmit = (): void => {
    if (!company) return;
    navigate(generatePath(routes.company.fundraising.full, { companyId, operationId }));
  };

  return (
    <Card variant="divider-top">
      <CardHeader as={Stack} spacing="2">
        <Heading size="lg">{t('Summary')}</Heading>
        <Text color="gray.600" fontSize="lg">
          {t('GeneratedContractsAndServices')}
        </Text>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="5">
        <Stack direction={{ base: 'column', md: 'row' }} spacing="2">
          <HStack
            alignItems="flex-start"
            bg="gray.50"
            p="8"
            rounded="md"
            spacing="4"
            w={{ base: 'full', md: '50%' }}
          >
            <BoxIcon icon={UserGroupIcon} iconBoxSize="24px" />
            <Stack spacing="4">
              <Stack spacing="0">
                <Text fontSize="lg" fontWeight="600">
                  {operation?.name}
                </Text>
                <Text lineHeight="1">{t('CapitalRaiseName').toLowerCase()}</Text>
              </Stack>
              <Stack spacing="0">
                <Text fontSize="lg" fontWeight="600">
                  {operation?.subscriptionPeriod
                    ? (
                        crowdfundingSubscriptionPeriodOptions.find(
                          (opt) => opt.value === operation.subscriptionPeriod,
                        ) || {}
                      ).label
                    : ''}
                </Text>
                <Text lineHeight="1">{t('OfSubscriptionPeriod')}</Text>
              </Stack>
              <Stack spacing="0">
                <Text fontSize="lg" fontWeight="600">
                  {t(
                    `FundraisingWorkflow${
                      fundraising?.workflowType || FundraisingWorkflowType.FIRST_IN
                    }`,
                  )}
                </Text>
                <Text lineHeight="1">
                  {t(
                    `FundraisingWorkflow${
                      fundraising?.workflowType || FundraisingWorkflowType.FIRST_IN
                    }Description`,
                  )}
                </Text>
              </Stack>
            </Stack>
          </HStack>
          <HStack
            alignItems="flex-start"
            bg="gray.50"
            p="8"
            rounded="md"
            spacing="4"
            w={{ base: 'full', md: '50%' }}
          >
            <BoxIcon icon={CurrencyDollarIcon} iconBoxSize="24px" />
            <Stack spacing="4">
              <Stack spacing="0">
                <Text fontSize="lg" fontWeight="600">
                  {formatNumberCurrency(fundraising?.hardCap || '')}
                </Text>
                <Text lineHeight="1">{t('ToRaise')}</Text>
              </Stack>
              <Stack spacing="0">
                <Text fontSize="lg" fontWeight="600">
                  {formatNumberCurrency(operation?.data?.valuation || '')}
                </Text>
                <Text lineHeight="1">{t('PreMoneyValuation')}</Text>
              </Stack>
              <Stack spacing="0">
                <Text fontSize="lg" fontWeight="600">
                  {formatNumberPercentage(operation?.data?.capitalHeldByNewSubscriptions || '')}
                </Text>
                <Text lineHeight="1">{t('NewSharesHeldByNewSubscribersPostMoney')}</Text>
              </Stack>
            </Stack>
          </HStack>
        </Stack>
        <Stack spacing="6">
          <Stack spacing="4">
            <Heading size="md">{t('Document', { nb: 1 })}</Heading>
            <SimpleGrid gap="4" minChildWidth="300px">
              <HStack>
                <Flex
                  alignItems="center"
                  bg="gray.50"
                  borderRadius="full"
                  boxSize="32px"
                  justifyContent="center"
                >
                  <Icon as={DocumentTextIcon} boxSize="24px" color="gray.500" />
                </Flex>
                <Text color="gray.600" fontWeight="medium">
                  {i18nDocumentTitle('PV_EXERCICE')}
                </Text>
              </HStack>
              <HStack>
                <Flex
                  alignItems="center"
                  bg="gray.50"
                  borderRadius="full"
                  boxSize="32px"
                  justifyContent="center"
                >
                  <Icon as={DocumentTextIcon} boxSize="24px" color="gray.500" />
                </Flex>
                <Text color="gray.600" fontWeight="medium">
                  {i18nDocumentTitle(operation.subscriptionDocumentType as IntlDocumentValues)}
                </Text>
              </HStack>
              <HStack>
                <Flex
                  alignItems="center"
                  bg="gray.50"
                  borderRadius="full"
                  boxSize="32px"
                  justifyContent="center"
                >
                  <Icon as={DocumentTextIcon} boxSize="24px" color="gray.500" />
                </Flex>
                <Text color="gray.600" fontWeight="medium">
                  {i18nDocumentTitle('PV_CONSTATATION')}
                </Text>
              </HStack>
            </SimpleGrid>
          </Stack>
          <Stack spacing="4">
            <Heading size="md">{t('IncludedServices')}</Heading>
            <SimpleGrid gap="4" minChildWidth="300px">
              <HStack>
                <Icon as={CheckIcon} boxSize="24px" color="green.500" />
                <Text color="gray.600" fontWeight="medium">
                  {t('ElecSignatureManagement')}
                </Text>
              </HStack>
              <HStack>
                <Icon as={CheckIcon} boxSize="24px" color="green.500" />
                <Text color="gray.600" fontWeight="medium">
                  {t('FinancialFlowsManagementAssociated')}
                </Text>
              </HStack>
              <HStack>
                <Icon as={CheckIcon} boxSize="24px" color="green.500" />
                <Text color="gray.600" fontWeight="medium">
                  {t('AutomaticUpdateOfYourCapitalizationTablePeriod')}
                </Text>
              </HStack>
              <HStack>
                <Icon as={CheckIcon} boxSize="24px" color="green.500" />
                <Text color="gray.600" fontWeight="medium">
                  {t('ArchiveAndCentralizeOperationDocuments')}
                </Text>
              </HStack>
              <HStack>
                <Icon as={CheckIcon} boxSize="24px" color="green.500" />
                <Text color="gray.600" fontWeight="medium">
                  {t('AutomatedDistributionOfAssets')}
                </Text>
              </HStack>
              <HStack>
                <Icon as={CheckIcon} boxSize="24px" color="green.500" />
                <Text color="gray.600" fontWeight="medium">
                  {t('UpdateOfShareTransferRegisterOnBlockchain')}
                </Text>
              </HStack>
              <HStack>
                <Icon as={CheckIcon} boxSize="24px" color="green.500" />
                <Text color="gray.600" fontWeight="medium">
                  {t('UpdateOfIndividualShareholderAccountsOnBlockchain')}
                </Text>
              </HStack>
            </SimpleGrid>
          </Stack>
        </Stack>
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button variant="secondary" w="full" onClick={handleStepCancel}>
          {t('Back')}
        </Button>
        <Button data-cy="validate" w="full" onClick={handleFundraisingSubmit}>
          {t('Validate')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export type NewCrowdfundingFundraisingSummaryProps = Props;
