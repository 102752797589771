import { BoxProps, Flex, FlexProps, Icon, IconProps } from '@chakra-ui/react';

type Props = {
  icon: BoxProps['as'];
  iconBoxSize?: IconProps['boxSize'];
  color?: IconProps['color'];
} & FlexProps;

export function BoxIcon({
  icon,
  iconBoxSize = '18px',
  color = 'gray.900',
  ...props
}: Props): JSX.Element {
  return (
    <Flex alignItems="center" bg="gray.100" justifyContent="center" p="2" rounded="sm" {...props}>
      <Icon as={icon} boxSize={iconBoxSize} color={color} />
    </Flex>
  );
}

export type BoxIconProps = Props;
