import { FormControl, FormLabel } from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import {
  IndividualDocumentType,
  useDeleteKycDocumentMutation,
  useGetIndividualDocumentsQuery,
} from '@blockpulse3/graphql/hooks';
import { DropzoneInput, ErrorMessage } from '@blockpulse3/ui/commons';
import { useManagedIndividual } from '@blockpulse3/web-client/auth';

import { IVerificationUserForm } from '../types';

type Props = {
  documentType: IndividualDocumentType;
  documentInputName: keyof IVerificationUserForm;
  documentInputLabel: string;
};

export function UploadIdentityDocument({
  documentType,
  documentInputName,
  documentInputLabel,
}: Props): JSX.Element {
  const t = useTranslations();

  const { individual } = useManagedIndividual();
  const individualIdentityId = individual?.id || '';

  const [identityFileUploading, setIdentityFileUploading] = useState<boolean>(false);

  // Delete document mutation
  const [deleteDocument] = useDeleteKycDocumentMutation();

  // Fetch individual documents
  const individualDocumentsReq = useGetIndividualDocumentsQuery({
    variables: { individualIdentityId },
    skip: !individualIdentityId,
  });
  const individualDocuments = individualDocumentsReq.data?.getIndividualDocuments || [];
  const identityDocument = individualDocuments.find((doc) => doc.document.type === documentType);
  const identityDocumentFileName = identityDocument
    ? {
        [identityDocument.document.id]: new File(
          [identityDocument.document.title],
          identityDocument.document.title,
        ),
      }
    : {};

  // Load form context
  const { formState, control, clearErrors, setError } = useFormContext<IVerificationUserForm>();

  // Delete file api call
  const handleIdentityFileDelete = (): void => {
    deleteDocument({
      variables: {
        deleteKycDocumentInput: {
          individualIdentityId,
          documentType,
        },
      },
      onCompleted: () => {
        individualDocumentsReq.refetch();
      },
    });
  };

  // Upload a file using axios
  const handleFileUpload = async (
    acceptedFiles: File[],
    documentType: IndividualDocumentType,
  ): Promise<void> => {
    setIdentityFileUploading(true);

    const formData = new FormData();
    formData.append('document', acceptedFiles[0]);
    formData.append('individualIdentityId', individualIdentityId);
    formData.append('documentType', documentType);
    await axios.post(
      process.env['NX_API_CONTROLLER_ENDPOINT'] + '/identities/uploadKycDocument',
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    await individualDocumentsReq.refetch();

    setIdentityFileUploading(false);
  };

  // Upload identity file on drop
  const handleIdentityFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    clearErrors(documentInputName);
    if (fileRejections.length === 0) {
      handleFileUpload(acceptedFiles, documentType);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError(documentInputName, {
            type: 'custom',
            message: FormErrors.FileTooLarge,
          });
          break;
        }
        default: {
          setError(documentInputName, {
            type: 'custom',
            message: FormErrors.DropzoneDefault,
          });
          break;
        }
      }
    }
  };

  return (
    <Controller
      control={control}
      name={documentInputName}
      render={(): JSX.Element => (
        <FormControl>
          <FormLabel>{documentInputLabel}</FormLabel>
          <DropzoneInput
            accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
            files={identityDocumentFileName}
            isDisabled={individualDocumentsReq.loading}
            isLoading={identityFileUploading}
            maxFiles={1}
            subTitle={t('ImageUpTo5MB')}
            onDelete={handleIdentityFileDelete}
            onDrop={(acceptedFiles: File[], fileRejections: FileRejection[]): void =>
              handleIdentityFileDrop(acceptedFiles, fileRejections)
            }
          />
          <ErrorMessage error={formState.errors?.identityDocumentFile} />
        </FormControl>
      )}
    />
  );
}
