import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetCompanyDocumentsQuery, useGetCompanyQuery } from '@blockpulse3/graphql/hooks';
import {
  AddIdentityDocuments,
  ErrorQueryCard,
  LinkButton,
  useGetCompanyDocuments,
} from '@blockpulse3/ui/commons';
import { useManagerRole } from '@blockpulse3/web-client/auth';

import { DocumentTable } from '../DocumentTable';

type Props = {
  backHref?: string;
};

/**
 * CompanyDocumentsView.
 *
 * @returns {JSX.Element}
 */
export function CompanyDocumentsView({ backHref }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const isUploadAuthorized = useManagerRole({ companyId });

  const [isAddingDocuments, setIsAddingDocuments] = useState<boolean>(false);

  const companyReq = useGetCompanyQuery({ variables: { companyId }, skip: !companyId });
  const companyDocumentsReq = useGetCompanyDocumentsQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const [getCompanyDocuments] = useGetCompanyDocuments();

  if (companyReq.loading || companyDocumentsReq.loading) {
    return (
      <Stack spacing="6">
        {backHref && <LinkButton label={t('Back')} route={backHref} />}
        <Heading>{t('Document', { nb: 1 })}</Heading>
        <Skeleton>
          <Card h="300px" />
        </Skeleton>
      </Stack>
    );
  }

  const identityId = companyReq.data?.company.identity?.id;
  const companyDocuments = companyDocumentsReq.data?.getCompanyDocuments || [];

  if (companyDocumentsReq.error || !identityId) {
    return (
      <Stack spacing="6">
        {backHref && <LinkButton label={t('Back')} route={backHref} />}
        <Heading>{t('Document', { nb: 1 })}</Heading>
        <ErrorQueryCard h="300px" />
      </Stack>
    );
  }

  const documents = companyDocuments.map(({ document }) => document) || [];
  const hasAllDocuments = getCompanyDocuments().every((addableDocument) =>
    documents.some((document) => document.type === addableDocument.type),
  );

  return (
    <Stack spacing="6">
      {backHref && <LinkButton label={t('Back')} route={backHref} />}
      <Heading>{t('Document', { nb: 2 })}</Heading>
      <Card>
        <CardHeader alignItems="center" as={HStack} justifyContent="space-between">
          <Heading size="md">{t('CompanyDocuments')}</Heading>
          {!hasAllDocuments && isUploadAuthorized && (
            <Button
              leftIcon={<AddIcon />}
              size="sm"
              variant="secondary"
              w="fit-content"
              onClick={(): void => setIsAddingDocuments((val) => !val)}
            >
              {t('AddDocument')}
            </Button>
          )}
        </CardHeader>
        <CardBody>
          {isAddingDocuments && (
            <Stack pb="4">
              <AddIdentityDocuments
                identityId={identityId}
                onlyIdentityDocuments={true}
                onUploadCompleted={companyDocumentsReq.refetch}
              />
            </Stack>
          )}
          <DocumentTable
            identityId={identityId}
            values={documents}
            onDeleteCompleted={companyDocumentsReq.refetch}
          />
        </CardBody>
      </Card>
    </Stack>
  );
}

export type DocumentsViewProps = Props;
