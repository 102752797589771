import { Button } from '@chakra-ui/react';
import { generatePath, resolvePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  CompanyCapitalType,
  CompanyStatus,
  CompanyType,
  SpvStatus,
  useCreateCompanyMutation,
} from '@blockpulse3/graphql/hooks';

import { StepFormContextProvider } from '../../provider';
import { OnboardingStep } from '../OnboardingStep';
import { SPVInformations, SPVInformationsForm } from '../forms/SPVInformations';

const DEFAULT_NOMINAL_VALUE = 1;

/**
 * OnboardingSPVCreate.
 * SPV informations, create step.
 *
 * @returns {JSX.Element}
 */
export function OnboardingSPVCreate(): JSX.Element {
  const t = useTranslations();
  const { spaceId = '' } = useParams();

  const navigate = useNavigate();

  const [createCompany] = useCreateCompanyMutation();

  const handleCreateSPV = (data: SPVInformationsForm): void => {
    const { address, revocationMajority, ...rest } = data;

    createCompany({
      variables: {
        createCompanyInput: {
          spaceId,
          status: CompanyStatus.DRAFT,
          type: CompanyType.SPV,
          spvStatus: SpvStatus.EDITING,
          capitalType: CompanyCapitalType.VARIABLE,
          address: {
            line: address.line,
            city: address.city,
            postalCode: address.postalCode,
            country: address.country.value,
          },
          country: address.country.value,
          revocationMajority: revocationMajority.value,
          nominalValue: DEFAULT_NOMINAL_VALUE,
          ...rest,
        },
      },
      onCompleted: async (data) => {
        const companyId = data.createCompany.id;
        const historyPrefix = spaceId ? routes.space.href : '';
        window.history.replaceState(
          null,
          '',
          historyPrefix + generatePath(routes.onboardings.spv.edit.full, { companyId }),
        );
        const redirectPrefix = spaceId ? '../../' : '';
        navigate(
          redirectPrefix +
            resolvePath(
              routes.onboardings.spv.edit.compensation.href,
              generatePath(routes.onboardings.spv.edit.href, { companyId }),
            ).pathname,
          { relative: 'path' },
        );
      },
    });
  };

  return (
    <StepFormContextProvider>
      <OnboardingStep subTitle={t('DefineInvestmentVehicleInfo')} title={t('LegalInformation')}>
        <SPVInformations onSubmit={handleCreateSPV} />
        <Button key="submit" data-cy="next" form="spv-informations" type="submit" w="full">
          {t('Next')}
        </Button>
      </OnboardingStep>
    </StepFormContextProvider>
  );
}
