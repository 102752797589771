import * as yup from 'yup';

import { requiredEmailField } from '@blockpulse3/data/shared';

import { IUpdateEmailForm } from './types';

export const schema = yup.object({
  email: requiredEmailField,
});

export const emailDefaultValues: IUpdateEmailForm = {
  email: '',
};
