import { HStack, Stack, Text } from '@chakra-ui/react';

import { UserIdentityAvatar } from '@blockpulse3/ui/commons';
import { useManagedIndividual } from '@blockpulse3/web-client/auth';

type Props = unknown;

/**
 * SwitcherButtonUser.
 * User version of SwitcherButton.
 *
 * @returns {JSX.Element}
 */
export function SwitcherButtonUser(): JSX.Element {
  const { individual } = useManagedIndividual();

  return (
    <HStack pl="0.5" w="full">
      <UserIdentityAvatar boxSize="8" ml="1.5" src={individual?.identity?.profilePicture} />
      <Stack alignItems="flex-start" flexGrow="1" pl="0.5" spacing="0">
        <Text
          fontSize="md"
          fontWeight="semibold"
          maxWidth="150px"
          overflow="hidden"
          textAlign="left"
          variant="ellipsis"
        >
          {`${individual?.firstName || '...'} ${individual?.lastName || ''}`}
        </Text>
      </Stack>
    </HStack>
  );
}

export type SwitcherButtonUserProps = Props;
