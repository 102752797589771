import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Icon,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { LockClosedIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import MessageKeys from 'use-intl/dist/core/utils/MessageKeys';

import {
  SpvStatus,
  useGetBankCapitalAccountByCompanyQuery,
  useGetSpvQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { getDepositBadge } from '../../../utils';
import { SPVMatriculationBankDetailsModal } from './SPVMatriculationBankDetailsModal';

/**
 * SPVMatriculationDeposit.
 * Capital deposit accordion.
 *
 * @returns {JSX.Element}
 */
export function SPVMatriculationDeposit(): JSX.Element {
  const t = useTranslations();

  const bankDetailsModal = useDisclosure();

  const { companyId = '' } = useParams();

  const getSPVReq = useGetSpvQuery({ variables: { companyId } });
  const getBankCapitalAccountReq = useGetBankCapitalAccountByCompanyQuery({
    variables: { companyId },
  });

  if (getSPVReq.loading || getBankCapitalAccountReq.loading) {
    return <Skeleton h="65px" />;
  }

  if (getSPVReq.error || getBankCapitalAccountReq.error) {
    return <ErrorQueryCard h="65px" />;
  }

  if (!getSPVReq.data || !getBankCapitalAccountReq.data) {
    return <ErrorQueryCard h="65px" />;
  }

  const company = getSPVReq.data.company;
  const bankCapitalAccount = getBankCapitalAccountReq.data.getBankCapitalAccountByCompany;

  const activeStep = company.spvStatus === SpvStatus.VERIFIED ? [0] : [];

  const handleIBANMModalOpen = (): void => {
    bankDetailsModal.onOpen();
  };

  const handleIBANMModalClose = (): void => {
    bankDetailsModal.onClose();
  };

  const badgeValues = getDepositBadge(company, bankCapitalAccount);

  return (
    <>
      <Accordion key={`deposit${company?.id}`} allowMultiple defaultIndex={activeStep}>
        <AccordionItem isDisabled={!bankCapitalAccount?.iban}>
          <AccordionButton>
            <AccordionIcon boxSize="8" />
            <Box flex="1" p="2" textAlign="left">
              <Text fontSize="xl" fontWeight="semibold">
                {t('CapitalDeposit')}
              </Text>
            </Box>
            <Badge colorScheme={badgeValues.color}>
              {t(badgeValues.label as MessageKeys<IntlMessages, keyof Messages>)}
            </Badge>
          </AccordionButton>
          <AccordionPanel>
            <Stack spacing="4">
              <Alert status="info">
                <AlertIcon />
                <AlertTitle>{t('VerifyIdentitiesSignDocumentation')}</AlertTitle>
              </Alert>
              <Button
                alignSelf="flex-end"
                leftIcon={<Icon as={LockClosedIcon} />}
                variant="secondary"
                onClick={handleIBANMModalOpen}
              >
                {t('DisplayIBAN')}
              </Button>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <SPVMatriculationBankDetailsModal
        isOpen={bankDetailsModal.isOpen}
        onClose={handleIBANMModalClose}
      />
    </>
  );
}
