import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Icon,
  Skeleton,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslations } from 'use-intl';

import { IntlRoleValues } from '@blockpulse3/data/locales/types';
import { noop } from '@blockpulse3/data/shared';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { useStepFormContext } from '../../../provider';
import { useGetOnboardingExistingCompany } from '../../hooks';
import { RepresentantInformationsForm, representantTitle } from './RepresentantInformationsForm';
import { representativeFormDefaults } from './schema';
import { getRepresentatives } from './utils';

type Props = {
  /* ** Is editing mode ** */
  isEditing?: boolean;
  /* ** Can only fill president representative ** */
  isPresident?: boolean;
  /* ** Should fill creation date for moral representatives ** */
  hasCreationDate?: boolean;
  /* ** Should fill filiation for natural representatives ** */
  hasFiliation?: boolean;
  /* ** Step cancelled callback ** */
  onCancel?: () => void;
  /* ** Step submitted callback ** */
  onSubmit?: () => void;
};

/**
 * ExistingCompanyRepresentatStep.
 * Step that renders the form for the representant of the company.
 * It is splitted on purpose, to keep the form agnostic.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function CompanyRepresentatives({
  isEditing = false,
  isPresident = false,
  hasCreationDate = false,
  hasFiliation = false,
  onCancel = noop,
  onSubmit = noop,
}: Props): JSX.Element {
  /* ** Ref to externaly submit nested accordion forms (Legal,Natural RepresentantForm) ** */
  const formRef = useRef<HTMLFormElement>(null);

  const t = useTranslations();
  const i18nRoleValues = useTranslations('RoleValues');

  const { data, loading, error, refetch } = useGetOnboardingExistingCompany(isEditing);

  const representatives = getRepresentatives(data?.company.companyRepresentative);
  const newForm = representatives.length === 0;

  const { setCancelHandler, setSubmitHandler } = useStepFormContext();
  const handleStepSubmit = useCallback((): void => {
    /*
     * If the user submit with the second button with modified data from a already fetched
     * representative, with the current design, we can do nothing for him
     *
     */
    if (newForm) {
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    } else {
      onSubmit();
    }
  }, [newForm, formRef, onSubmit]);

  const handleStepCancel = useCallback((): void => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    setSubmitHandler(handleStepSubmit);
    setCancelHandler(handleStepCancel);
  }, [handleStepSubmit, handleStepCancel, setSubmitHandler, setCancelHandler]);

  const handleFormSubmit = (): void => {
    refetch();
  };

  if (loading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (error || !data) {
    return <ErrorQueryCard width="full" />;
  }

  return (
    <>
      {representatives.map((representative) => {
        return (
          <Accordion key={representative.id} allowToggle>
            <AccordionItem mt="0">
              <AccordionButton>
                <Tooltip hasArrow label={t('ValidatedRepresentative')} placement="top">
                  <Icon as={CheckCircleIcon} boxSize="30" color="green.500" />
                </Tooltip>
                <Box flex="1" p="2" textAlign="left">
                  <Heading fontSize="lg">
                    {i18nRoleValues(representative.role as IntlRoleValues)}
                  </Heading>
                  <Text>{representantTitle(representative)}</Text>
                </Box>
                <AccordionIcon boxSize="8" />
              </AccordionButton>
              <AccordionPanel>
                <RepresentantInformationsForm
                  defaultValues={representative}
                  hasCreationDate={hasCreationDate}
                  hasFiliation={hasFiliation}
                  id={representative.id}
                  ref={formRef}
                  shouldCancel={false}
                  onSubmitComplete={handleFormSubmit}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      })}
      {newForm && (
        <Accordion allowToggle>
          <AccordionItem mt="0">
            <AccordionButton>
              <Tooltip hasArrow label={t('IncompleteRepresentativeInformation')} placement="top">
                <Icon as={ExclamationCircleIcon} boxSize="30" color="yellow.400" />
              </Tooltip>
              <Box flex="1" p="2" textAlign="left">
                <Heading fontSize="lg">
                  {isPresident ? i18nRoleValues('PRESIDENT') : i18nRoleValues('General')}
                </Heading>
              </Box>
              <AccordionIcon boxSize="8" />
            </AccordionButton>
            <AccordionPanel>
              <RepresentantInformationsForm
                defaultValues={representativeFormDefaults}
                hasCreationDate={hasCreationDate}
                hasFiliation={hasFiliation}
                id={null}
                isPresident={isPresident}
                ref={formRef}
                shouldCancel={false}
                onSubmitComplete={handleFormSubmit}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
}

export type CompanyRepresentativesProps = Props;
