import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

import { StepFormContextProvider } from '../../provider';
import { OnboardingStep } from '../OnboardingStep';
import { SPVFinancialInformations } from '../forms';

/**
 * FinancialInformationsStep.
 * Step that renders the form for the financial informatinos of the SPV.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OnboardingSPVFinancialInformations(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const handleStepSubmit = (): void => {
    navigate('../' + routes.onboardings.spv.edit.representatives.href);
  };

  const handleStepCancel = (): void => {
    navigate('..');
  };

  return (
    <StepFormContextProvider>
      <OnboardingStep subTitle={t('DefineFinancementStructure')} title={t('FinancementStructure')}>
        <SPVFinancialInformations onCancel={handleStepCancel} onSubmit={handleStepSubmit} />
        <Button key="cancel" variant="secondary" w="full">
          {t('Back')}
        </Button>
        <Button key="submit" data-cy="next" form="financial-informations" type="submit" w="full">
          {t('Next')}
        </Button>
      </OnboardingStep>
    </StepFormContextProvider>
  );
}
