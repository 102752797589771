import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

type Props = {
  children: React.ReactNode;
} & BoxProps;

export function IdentityCardDescription({ children, ...props }: Props): JSX.Element {
  return (
    <Box color="gray.500" fontSize="sm" fontWeight="400" {...props}>
      {children}
    </Box>
  );
}

export type IdentityCardDescriptionProps = Props;
