import {
  BoxProps,
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Skeleton,
  SkeletonText,
  Stack,
} from '@chakra-ui/react';

import { ResponsiveModalFooter } from './ResponsiveModalFooter';

type Props = BoxProps;

export function SkeletonModal({ ...props }: Props): JSX.Element {
  return (
    <ModalContent>
      <ModalHeader>
        <SkeletonText noOfLines={1} skeletonHeight="8" w="80%" />
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack py="4" {...props}>
          <Skeleton h="full" w="full" />
        </Stack>
      </ModalBody>
      <Divider />
      <ResponsiveModalFooter>
        <Skeleton>
          <Button w="200px" />
        </Skeleton>
        <Skeleton>
          <Button w="200px" />
        </Skeleton>
      </ResponsiveModalFooter>
    </ModalContent>
  );
}

export type SkeletonModalProps = Props;
