import { BoxProps, Button, Flex, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { ShieldExclamationIcon } from '@heroicons/react/solid';
import { Link, useMatch, useNavigate } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';
import { useBreakpoints } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { SettingsMenu } from '../../SettingsMenu';
import { SettingsNav } from '../../SettingsNav';
import { SettingsNotifications } from '../../SettingsNotifications';
import { SwitcherButton } from '../../SwitcherModal';

type Props = BoxProps;

export function SidebarDesktop({ ...props }: Props): JSX.Element {
  const { spaceId, spaceName } = useAuthUser();

  const navigate = useNavigate();

  const { isDesktop, isLargeScreen } = useBreakpoints();

  const isSpace = useMatch(routes.space.href + '/*');
  const isManaged = useMatch(routes.manage.href + '/*');

  const handleGoToSpace = (): void => {
    if (!spaceId) return;
    navigate(routes.space.href);
  };

  return (
    <Flex
      bg={props.bg || 'primary'}
      color="white"
      direction="column"
      flexShrink="0"
      height="calc(var(--vh, 1vh) * 100)"
      overflowY="auto"
      position="fixed"
      top="0"
      w="64"
      zIndex="9"
      {...props}
    >
      <Stack p="3" pt="6" spacing="8">
        {isDesktop || isLargeScreen ? (
          <Link to={isSpace ? routes.space.href : routes.me.href}>
            <Stack h="55px" justifyContent="center" w="full">
              <BrandIcon bgTheme="dark" h="full" margin="auto" maxW="210px" w="full" />
            </Stack>
          </Link>
        ) : null}
        {!isSpace && <SwitcherButton />}
      </Stack>
      <Stack flex="1" flexShrink="0" justifyContent="flex-start" p="3" spacing="2">
        {isManaged && spaceId && (
          <Button
            bg="black"
            fontSize="sm"
            leftIcon={<Icon as={ShieldExclamationIcon} boxSize="18px" ml="1" mr="2" />}
            variant="menu-button"
            onClick={handleGoToSpace}
          >
            <HStack justifyContent="space-between" w="full">
              <Text>{spaceName}</Text>
              <Icon as={ArrowNarrowRightIcon} boxSize="18px" />
            </HStack>
          </Button>
        )}
        <SettingsNav />
      </Stack>
      <Stack p="3" spacing="2">
        <SettingsNotifications />
        <SettingsMenu />
      </Stack>
    </Flex>
  );
}

export type SidebarDesktopProps = Props;
