import { Card, Skeleton, Text } from '@chakra-ui/react';
import { Link, useMatch, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useGetSpvQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, Progression, ProgressionStep } from '@blockpulse3/ui/commons';
import { useAuthUser, useManagedIndividual } from '@blockpulse3/web-client/auth';

import { getDraftCurrentStep, isDraftEditable } from '../utils';

type Props = unknown;

export function SPVProgression(): JSX.Element {
  const t = useTranslations();

  const { individual } = useManagedIndividual();
  const { user } = useAuthUser();

  const isSpace = useMatch(routes.space.href + '/*');

  const { companyId = '' } = useParams();

  const { data, loading, error } = useGetSpvQuery({ variables: { companyId } });

  if (loading) {
    return (
      <Skeleton>
        <Card h="150px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="150px" />;
  }

  if (!data) {
    return <ErrorQueryCard h="150px" />;
  }

  const company = data.company;
  const isSuperAdmin = user?.isSuperAdmin;
  const isManagingSpace = !!isSpace && isSuperAdmin;
  const editable = isDraftEditable(company, individual, isManagingSpace);
  const currentStep = getDraftCurrentStep(company?.spvStatus);

  return (
    <Card>
      <Progression currentStep={currentStep} totalSteps={5}>
        <ProgressionStep isCompleted title={t('Setup')}>
          {editable && (
            <Link relative="path" to={'..'}>
              <Text fontSize="xs" fontWeight="semibold" textDecoration="underline">
                {t('Modify')}
              </Text>
            </Link>
          )}
        </ProgressionStep>
        <ProgressionStep isCompleted={currentStep >= 2} title={t('SignatureOfDocuments')} />
        <ProgressionStep isCompleted={currentStep >= 3} title={t('IdentitiesVerification')}>
          <Text color="gray.600" fontSize="xs">
            {t('InitialShareholderIdentityVerification')}
          </Text>
        </ProgressionStep>
        <ProgressionStep isCompleted={currentStep >= 4} title={t('CapitalDeposit')}>
          <Text color="gray.600" fontSize="xs">
            {t('InitialShareholderFundsDeposit')}
          </Text>
        </ProgressionStep>
        <ProgressionStep isCompleted={currentStep >= 5} title={t('Formalities')}>
          <Text color="gray.600" fontSize="xs">
            {t('RegisterFormalitiesInProgress')}
          </Text>
        </ProgressionStep>
      </Progression>
    </Card>
  );
}

export type SPVProgressionProps = Props;
