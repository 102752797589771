import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { ClipboardListIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  AssetType,
  CompanyCapitalType,
  DocumentStatus,
  FundraisingWorkflowType,
  OperationDocumentType,
  OperationStatus,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import { Input, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

type Props = unknown;

/**
 * FundraisingUploadCertificate.
 * Section to upload the deposit certificate and trigger the PV signature.
 *
 * @returns {JSX.Element | null}
 */
export function FundraisingUploadCertificate(): JSX.Element | null {
  const t = useTranslations();

  const inputRef = useRef<HTMLInputElement>(null);

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { operationId = '' } = useParams();

  const { data, loading, refetch } = useGetOperationQuery({ variables: { operationId } });

  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  if (loading || !data) {
    return null;
  }

  const { fundraising, ...operation } = data.operation;

  const isFixed = operation.company.capitalType === CompanyCapitalType.FIXED;
  const isBond = operation.assetType === AssetType.BOND;

  const isAllIn = fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;
  const isClosed = operation.status === OperationStatus.CLOSED;
  const isFinalized = operation.status === OperationStatus.FINALIZED;

  const deposit = operation.documents?.find(
    (document) => document.type === OperationDocumentType.DEPOSIT_CERTIFICATE,
  );
  const isDepositSign = deposit ? deposit.document.status === DocumentStatus.SIGNED : false;

  const handleUploadButtonClick = (): void => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ): Promise<void> => {
    const files = e.target.files;

    if (files && files.length === 1) {
      const formData = new FormData();
      formData.append('operationId', operationId);
      formData.append('document', files[0]);
      setUploadLoading(true);

      await axios
        .post(
          process.env['NX_API_CONTROLLER_ENDPOINT'] + '/fundraisings/uploadDepositCertificate',
          formData,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(() => {
          refetch();
          successToast({ title: t('DocumentImportedSuccessfully') });
        })
        .catch(() => {
          errorToast({ title: t('FileUploadError') });
        })
        .finally(() => {
          setUploadLoading(false);
        });
    }
  };

  if (
    !isFixed ||
    isBond ||
    !((isAllIn && isFinalized) || (!isAllIn && isClosed)) ||
    isDepositSign
  ) {
    return null;
  }

  return (
    <Alert boxShadow="md" status="warning">
      <Stack
        alignItems="stretch"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing="4"
        width="full"
      >
        <HStack alignItems="flex-start" spacing="0">
          <AlertIcon as={ClipboardListIcon} />
          <Stack spacing="0">
            <AlertTitle fontWeight="bold">{t('CapitalDepositCertificate')}</AlertTitle>
            <AlertDescription>{t('DepositCapitalCertificateBeforeConfirmation')}</AlertDescription>
          </Stack>
        </HStack>
        <HStack alignItems="center" ml={{ base: '0', md: '4' }}>
          <Input
            accept=".pdf"
            display="none"
            ref={inputRef}
            type="file"
            onChange={handleInputChange}
          />
          <Button
            className="confirm"
            fontSize="sm"
            isLoading={uploadLoading}
            loadingText={t('ImportInProgress')}
            onClick={handleUploadButtonClick}
          >
            {t('DepositCertificateImportAction')}
          </Button>
        </HStack>
      </Stack>
    </Alert>
  );
}

export type FundraisingUploadCertificateProps = Props;
