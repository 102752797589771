/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * downloadFile.
 * Since we can't return directly a stream to a download box with the Content-Disposition file
 * attachement, we use this dirty JS hack.
 *
 * @param {any} responseData
 * @param {string} filename
 * @returns {void}
 */
export function downloadFile(responseData: any, filename: string): void {
  const url = window.URL.createObjectURL(new Blob([responseData]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);

  link.click();
}
