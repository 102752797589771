import { Icon, Link, LinkProps, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@heroicons/react/outline';

type Props = {
  link: string;
  title?: string;
} & LinkProps;

export function ExternalLink({ link, title, ...props }: Props): JSX.Element {
  return (
    <Link isExternal color="indigo.600" href={link || '#'} {...props}>
      {title && <Text as="span">{title}</Text>}
      <Icon as={ExternalLinkIcon} boxSize="19px" verticalAlign="text-top" />
    </Link>
  );
}
