import { Card, CardBody } from '@chakra-ui/react';

import { AdminBankTransferTable } from './AdminBankTransferTable';

export function AdminBankTransferList(): JSX.Element {
  return (
    <Card>
      <CardBody>
        <AdminBankTransferTable />
      </CardBody>
    </Card>
  );
}
