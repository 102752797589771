import { Icon, Skeleton, Stack, Text } from '@chakra-ui/react';
import { CubeTransparentIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { OperationStatus } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { OperationCards } from './OperationCards';
import { OperationTable } from './OperationTable';
import { useGetOperations } from './hook';
import { OperationRowDisplayType } from './types';

type Props = {
  displayType: OperationRowDisplayType;
  limit?: number;
  isRestrictedToOpportunities?: boolean;
};

/**
 * OperationRowsCurrent.
 * Query and display STARTED operations.
 *
 * @returns {JSX.Element}
 */
export function OperationRowsCurrent({
  displayType,
  limit,
  isRestrictedToOpportunities = false,
}: Props): JSX.Element {
  const t = useTranslations();

  const { operations, operationsLoading, operationsError } = useGetOperations(
    isRestrictedToOpportunities ? undefined : OperationStatus.STARTED,
  );

  if (operationsLoading && !operations.length) {
    return (
      <Stack gap="3">
        <Skeleton minH="111px"></Skeleton>
        <Skeleton minH="111px"></Skeleton>
        <Skeleton minH="111px"></Skeleton>
      </Stack>
    );
  }

  if (operationsError) {
    return <ErrorQueryCard h="200px" />;
  }

  const isEmpty = operations.length === 0;
  const operationsLimited = limit ? operations.slice(0, limit) : operations;

  const RenderOperationRows = (): JSX.Element => {
    if (displayType === 'cards') {
      return <OperationCards operations={operationsLimited} />;
    }

    return <OperationTable operations={operationsLimited} />;
  };

  return isEmpty ? (
    <Stack layerStyle="emptyState">
      <Icon as={CubeTransparentIcon} boxSize="40px" color="gray.500" />
      <Text>{t('NoCurrentOperation')}</Text>
    </Stack>
  ) : (
    <RenderOperationRows />
  );
}
