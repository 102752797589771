import { ISubscription } from '@blockpulse3/data/shared';
import { GetAllSubscriptionsByIndividualIdentityQuery } from '@blockpulse3/graphql/hooks';

/**
 * concatUserSubscriptions.
 * Concatenate individual user subscriptions with his represented companies subscriptions
 *
 * @param {GetAllSubscriptionsByIndividualIdentityQuery['individualIdentity']} individualIdentity
 * @returns {ISubscription[]}
 */
export const concatUserSubscriptions = (
  individualIdentity: GetAllSubscriptionsByIndividualIdentityQuery['individualIdentity'],
): ISubscription[] => {
  const subscriptions: ISubscription[] = [];

  // Push individual subscriptions
  const individualSubscriptions = individualIdentity.identity?.subscriptions || [];
  subscriptions.push(...individualSubscriptions);

  // Push companies related subscriptions
  const representatives = individualIdentity?.identity?.identityRepresentative || [];
  for (const representative of representatives) {
    const companySubscriptions = representative?.company?.identity?.subscriptions || [];
    for (const companySubscription of companySubscriptions) {
      if (!subscriptions.find((subscription) => companySubscription.id === subscription.id)) {
        subscriptions.push(...companySubscriptions);
      }
    }
  }

  return subscriptions;
};
