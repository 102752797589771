import * as yup from 'yup';

import { requiredUrlField } from '@blockpulse3/data/shared';

import { IFundraisingArchiveImportModal } from './types';

export const importArchiveSchema = yup.object({
  archiveUrl: requiredUrlField,
}) as yup.ObjectSchema<IFundraisingArchiveImportModal>;

export const importArchiveDefaultValue = {
  archiveUrl: '',
};
