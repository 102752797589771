import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { ErrorMessage } from '@blockpulse3/ui/commons';

import { phoneSchema } from './schema';
import { IUpdatePhoneForm } from './types';

type Props = {
  defaultValues: IUpdatePhoneForm;
  onSubmit: (data: IUpdatePhoneForm) => void;
};

export function UpdatePhoneForm({ defaultValues, onSubmit = noop }: Props): JSX.Element {
  const t = useTranslations();

  /* ** Control if the form is "touched (!= changed) ** */
  const [isTouched, setIsTouched] = useState<boolean>(false);
  /* ** Update phone form ** */
  const { control, formState, handleSubmit } = useForm<IUpdatePhoneForm>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(phoneSchema),
  });

  /* ** Submit button disabled state ** */
  const isDisabled = useMemo(() => {
    return !formState.isValid || !isTouched;
  }, [isTouched, formState.isValid]);

  const handleFormTouched = (): void => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleFormSubmit: SubmitHandler<IUpdatePhoneForm> = (data) => {
    onSubmit(data);
  };

  return (
    <form onChange={handleFormTouched} onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 5, lg: 8 }}>
        <Text flexBasis={{ lg: 250 }} fontSize="lg" fontWeight="medium">
          {t('Phone')}
        </Text>
        <Card maxW={{ lg: '3xl' }} variant="divider-bottom" w="full">
          <CardBody>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.phone} w={{ base: 'full', md: '49%' }}>
                <FormLabel htmlFor="phone">{t('Phone')}</FormLabel>
                <PhoneInputWithCountry
                  control={control}
                  defaultCountry="FR"
                  inputComponent={Input}
                  name="phone"
                />
                <ErrorMessage error={formState.errors?.phone} />
              </FormControl>
            </Stack>
          </CardBody>
          <Divider />
          <CardFooter justifyContent="flex-end">
            <Button disabled={isDisabled} type="submit">
              {t('Save')}
            </Button>
          </CardFooter>
        </Card>
      </Stack>
    </form>
  );
}

export type UpdatePhoneFormProps = Props;
