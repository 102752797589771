import { useContext, useMemo } from 'react';

import { SubscriptionInfosFragment } from '@blockpulse3/graphql/hooks';

import { IMifidModalContext, MifidModalContext } from './context';

type Props = {
  children: React.ReactNode;
  isSectionDisabled: boolean;
  subscriptionId: SubscriptionInfosFragment['id'];
};

export function MifidModalContextProvider({
  children,
  isSectionDisabled,
  subscriptionId,
}: Props): JSX.Element {
  const value = useMemo(
    () => ({
      isSectionDisabled,
      subscriptionId,
    }),
    [isSectionDisabled, subscriptionId],
  );

  return <MifidModalContext.Provider value={value}>{children}</MifidModalContext.Provider>;
}

export function useMifidModal(): IMifidModalContext {
  return useContext(MifidModalContext);
}
