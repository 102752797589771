import { AssetInfosFragment } from '@blockpulse3/graphql/hooks';

import { OperationAssetOption } from './types';

/**
 * getAssetDraftsOptions.
 * Returns draft asset options to fill configuration drop down.
 *
 * @param {AssetInfosFragment[]} [assetDrafts]
 * @returns {OperationAssetOption[]}
 */
export function getAssetDraftsOptions(assetDrafts?: AssetInfosFragment[]): OperationAssetOption[] {
  if (!assetDrafts) {
    return [];
  }

  return assetDrafts.map((draft) => ({
    label: draft.name,
    type: draft.assetType,
    value: draft.id,
    isDraft: true,
  }));
}
