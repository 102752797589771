export const alpha3Countries: Record<string, string> = {
  FRA: 'France',
  AFG: 'Afghanistan',
  ZAF: 'Afrique du Sud',
  ALB: 'Albanie',
  DZA: 'Algérie',
  DEU: 'Allemagne',
  AND: 'Andorre',
  AGO: 'Angola',
  ATG: 'Antigua-et-Barbuda',
  SAU: 'Arabie saoudite',
  ARG: 'Argentine',
  ARM: 'Arménie',
  AUS: 'Australie',
  AUT: 'Autriche',
  AZE: 'Azerbaïdjan',
  BHS: 'Bahamas',
  BHR: 'Bahreïn',
  BGD: 'Bangladesh',
  BRB: 'Barbade',
  BLR: 'Biélorussie',
  BEL: 'Belgique',
  BLZ: 'Belize',
  BEN: 'Bénin',
  BTN: 'Bhoutan',
  BOL: 'Bolivie',
  BIH: 'Bosnie-Herzégovine',
  BWA: 'Botswana',
  BRA: 'Brésil',
  BRN: 'Brunei',
  BGR: 'Bulgarie',
  BFA: 'Burkina Faso',
  BDI: 'Burundi',
  KHM: 'Cambodge',
  CMR: 'Cameroun',
  CAN: 'Canada',
  CPV: 'Cap-Vert',
  CAF: 'République centrafricaine',
  CHL: 'Chili',
  CHN: 'Chine',
  CYP: 'Chypre',
  COL: 'Colombie',
  COM: 'Comores',
  COG: 'République du Congo',
  COD: 'République démocratique du Congo',
  KOR: 'Corée du Sud',
  PRK: 'Corée du Nord',
  CRI: 'Costa Rica',
  CIV: "Côte d'Ivoire",
  HRV: 'Croatie',
  CUB: 'Cuba',
  DNK: 'Danemark',
  DJI: 'Djibouti',
  DOM: 'République dominicaine',
  DMA: 'Dominique',
  EGY: 'Égypte',
  SLV: 'Salvador',
  ARE: 'Émirats arabes unis',
  ECU: 'Équateur',
  ERI: 'Érythrée',
  ESP: 'Espagne',
  EST: 'Estonie',
  USA: 'États-Unis',
  ETH: 'Éthiopie',
  FJI: 'Fidji',
  FIN: 'Finlande',
  GAB: 'Gabon',
  GMB: 'Gambie',
  GEO: 'Géorgie',
  GHA: 'Ghana',
  GRC: 'Grèce',
  GRD: 'Grenade',
  GTM: 'Guatemala',
  GIN: 'Guinée',
  GNB: 'Guinée-Bissau',
  GNQ: 'Guinée équatoriale',
  GUY: 'Guyana',
  HTI: 'Haïti',
  HND: 'Honduras',
  HUN: 'Hongrie',
  IND: 'Inde',
  IDN: 'Indonésie',
  IRN: 'Iran',
  IRQ: 'Irak',
  IRL: 'Irlande',
  ISL: 'Islande',
  ISR: 'Israël',
  ITA: 'Italie',
  JAM: 'Jamaïque',
  JPN: 'Japon',
  JOR: 'Jordanie',
  KAZ: 'Kazakhstan',
  KEN: 'Kenya',
  KGZ: 'Kirghizistan',
  KIR: 'Kiribati',
  KWT: 'Koweït',
  LAO: 'Laos',
  LSO: 'Lesotho',
  LVA: 'Lettonie',
  LBN: 'Liban',
  LBR: 'Liberia',
  LBY: 'Libye',
  LIE: 'Liechtenstein',
  LTU: 'Lituanie',
  LUX: 'Luxembourg',
  MKD: 'Macédoine du Nord',
  MDG: 'Madagascar',
  MYS: 'Malaisie',
  MWI: 'Malawi',
  MDV: 'Maldives',
  MLI: 'Mali',
  MLT: 'Malte',
  MAR: 'Maroc',
  MHL: 'Îles Marshall',
  MUS: 'Maurice',
  MRT: 'Mauritanie',
  MEX: 'Mexique',
  FSM: 'États fédérés de Micronésie',
  MDA: 'Moldavie',
  MCO: 'Monaco',
  MNG: 'Mongolie',
  MNE: 'Monténégro',
  MOZ: 'Mozambique',
  MMR: 'Birmanie',
  NAM: 'Namibie',
  NRU: 'Nauru',
  NPL: 'Népal',
  NIC: 'Nicaragua',
  NER: 'Niger',
  NGA: 'Nigeria',
  NOR: 'Norvège',
  NZL: 'Nouvelle-Zélande',
  OMN: 'Oman',
  UGA: 'Ouganda',
  UZB: 'Ouzbékistan',
  PAK: 'Pakistan',
  PLW: 'Palaos',
  PAN: 'Panama',
  PNG: 'Papouasie-Nouvelle-Guinée',
  PRY: 'Paraguay',
  NLD: 'Pays-Bas',
  PER: 'Pérou',
  PHL: 'Philippines',
  POL: 'Pologne',
  PRT: 'Portugal',
  QAT: 'Qatar',
  ROU: 'Roumanie',
  GBR: 'Royaume-Uni',
  RUS: 'Russie',
  RWA: 'Rwanda',
  KNA: 'Saint-Christophe-et-Niévès',
  SMR: 'Saint-Marin',
  VCT: 'Saint-Vincent-et-les-Grenadines',
  LCA: 'Sainte-Lucie',
  SLB: 'Îles Salomon',
  WSM: 'Samoa',
  STP: 'Sao Tomé-et-Principe',
  SEN: 'Sénégal',
  SRB: 'Serbie',
  SYC: 'Seychelles',
  SLE: 'Sierra Leone',
  SGP: 'Singapour',
  SVK: 'Slovaquie',
  SVN: 'Slovénie',
  SOM: 'Somalie',
  SDN: 'Soudan',
  SSD: 'Soudan du Sud',
  LKA: 'Sri Lanka',
  SWE: 'Suède',
  CHE: 'Suisse',
  SUR: 'Suriname',
  SWZ: 'Eswatini',
  SYR: 'Syrie',
  TJK: 'Tadjikistan',
  TZA: 'Tanzanie',
  TCD: 'Tchad',
  CZE: 'Tchéquie',
  THA: 'Thaïlande',
  TLS: 'Timor oriental',
  TGO: 'Togo',
  TON: 'Tonga',
  TTO: 'Trinité-et-Tobago',
  TUN: 'Tunisie',
  TKM: 'Turkménistan',
  TUR: 'Turquie',
  TUV: 'Tuvalu',
  UKR: 'Ukraine',
  URY: 'Uruguay',
  VUT: 'Vanuatu',
  VEN: 'Venezuela',
  VNM: 'Viêt Nam',
  YEM: 'Yémen',
  ZMB: 'Zambie',
  ZWE: 'Zimbabwe',
};

export const alpha2Countries: Record<string, string> = {
  FR: 'France',
  AF: 'Afghanistan',
  ZA: 'Afrique du sud',
  AX: 'Åland, îles',
  AL: 'Albanie',
  DZ: 'Algérie',
  DE: 'Allemagne',
  AD: 'Andorre',
  AO: 'Angola',
  AI: 'Anguilla',
  AG: 'Antigua-et-barbuda',
  SA: 'Arabie saoudite',
  AR: 'Argentine',
  AM: 'Arménie',
  AW: 'Aruba',
  AU: 'Australie',
  AT: 'Autriche',
  AZ: 'Azerbaïdjan',
  BJ: 'Bénin',
  BS: 'Bahamas',
  BH: 'Bahreïn',
  BD: 'Bangladesh',
  BB: 'Barbade',
  BE: 'Belgique',
  BZ: 'Belize',
  BM: 'Bermudes',
  BT: 'Bhoutan',
  BY: 'Biélorussie',
  BO: 'Bolivie',
  BQ: 'Bonaire, saint-eustache et saba',
  BA: 'Bosnie-herzégovine',
  BW: 'Botswana',
  BR: 'Brésil',
  BN: 'Brunei darussalam',
  BG: 'Bulgarie',
  BF: 'Burkina faso',
  BI: 'Burundi',
  KY: 'Caïmans, îles',
  KH: 'Cambodge',
  CM: 'Cameroun',
  CA: 'Canada',
  CV: 'Cap-vert',
  CF: 'République centrafricaine',
  CL: 'Chili',
  CN: 'Chine',
  CX: 'Christmas, île',
  CY: 'Chypre',
  CC: 'Cocos (keeling), îles',
  CO: 'Colombie',
  KM: 'Comores',
  CG: 'République du Congo',
  CD: 'République démocratique du Congo',
  CK: 'Cook, ïles',
  KR: 'Corée du Sud',
  KP: 'Corée du Nord',
  CR: 'Costa rica',
  HR: 'Croatie',
  CU: 'Cuba',
  CW: 'Curaçao',
  CI: "Côte d'ivoire",
  DK: 'Danemark',
  DJ: 'Djibouti',
  DO: 'République dominicaine',
  DM: 'Dominique',
  EG: 'Egypte',
  SV: 'El salvador',
  AE: 'Emirats arabes unis',
  EC: 'Equateur',
  ER: 'Erythrée',
  US: 'Etats-unis',
  ET: 'Ethiopie',
  ES: 'Espagne',
  EE: 'Estonie',
  FO: 'Féroé, îles',
  FK: 'Falkland, îles (malvinas)',
  FJ: 'Fidji',
  FI: 'Finlande',
  GE: 'Géorgie',
  GA: 'Gabon',
  GM: 'Gambie',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Grèce',
  GD: 'Grenade',
  GL: 'Groenland',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernesey',
  GN: 'Guinée',
  GQ: 'Guinée équatoriale',
  GW: 'Guinée-bissau',
  GY: 'Guyana',
  GF: 'Guyane française',
  HT: 'Haïti',
  HN: 'Honduras',
  HK: 'Hong kong',
  HU: 'Hongrie',
  IM: 'Ile de man',
  VG: 'Iles vierges britanniques',
  VI: 'Iles vierges des états-unis',
  IN: 'Inde',
  ID: 'Indonésie',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Irlande',
  IS: 'Islande',
  IL: 'Israël',
  IT: 'Italie',
  JM: 'Jamaïque',
  JP: 'Japon',
  JE: 'Jersey',
  JO: 'Jordanie',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KG: 'Kirghizistan',
  KI: 'Kiribati',
  KW: 'Koweït',
  LA: 'Laos',
  LS: 'Lesotho',
  LV: 'Lettonie',
  LR: 'Libéria',
  LB: 'Liban',
  LY: 'Libye',
  LI: 'Liechtenstein',
  LT: 'Lituanie',
  LU: 'Luxembourg',
  MK: 'Macédoine du Nord',
  MO: 'Macao',
  MG: 'Madagascar',
  MY: 'Malaisie',
  MW: 'Malawi',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malte',
  MP: 'Mariannes du nord, îles',
  MA: 'Maroc',
  MH: 'Marshall, îles',
  MQ: 'Martinique',
  MU: 'Maurice',
  MR: 'Mauritanie',
  YT: 'Mayotte',
  MX: 'Mexique',
  FM: 'États fédérés de Micronésie',
  MD: 'Moldavie',
  MC: 'Monaco',
  MN: 'Mongolie',
  ME: 'Monténégro',
  MS: 'Montserrat',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NP: 'Népal',
  NA: 'Namibie',
  NR: 'Nauru',
  NI: 'Nicaragua',
  NG: 'Nigéria',
  NE: 'Niger',
  NU: 'Niué',
  NF: 'Norfolk, île',
  NO: 'Norvège',
  NC: 'Nouvelle-calédonie',
  NZ: 'Nouvelle-zélande',
  IO: "Océan indien, territoire britannique de l'",
  OM: 'Oman',
  UG: 'Ouganda',
  UZ: 'Ouzbékistan',
  PK: 'Pakistan',
  PW: 'Palaos',
  PS: 'Palestinien occupé, territoire',
  PA: 'Panama',
  PG: 'Papouasie-nouvelle-guinée',
  PY: 'Paraguay',
  NL: 'Pays-bas',
  PE: 'Pérou',
  PH: 'Philippines',
  PL: 'Pologne',
  PF: 'Polynésie française',
  PR: 'Porto rico',
  PT: 'Portugal',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Roumanie',
  GB: 'Royaume-uni',
  RU: 'Russie',
  RW: 'Rwanda',
  SN: 'Sénégal',
  EH: 'Sahara occidental',
  BL: 'Saint-barthélemy',
  KN: 'Saint-kitts-et-nevis',
  SM: 'Saint-marin',
  SX: 'Saint-martin (partie néerlandaise)',
  MF: 'Saint-martin (partie française)',
  PM: 'Saint-pierre-et-miquelon',
  VA: 'Saint-siège (Etat de la cité du vatican)',
  VC: 'Saint-vincent-et-les grenadines',
  SH: 'Sainte-héléne, ascension et tristan da cunha',
  LC: 'Sainte-lucie',
  SB: 'Salomon, îles',
  WS: 'Samoa',
  AS: 'Samoa américaines',
  ST: 'Sao tomé-et-principe',
  RS: 'Serbie',
  SC: 'Seychelles',
  SL: 'Sierra leone',
  SG: 'Singapour',
  SI: 'Slovénie',
  SK: 'Slovaquie',
  SO: 'Somalie',
  SD: 'Soudan',
  SS: 'Soudan du sud',
  LK: 'Sri lanka',
  SE: 'Suède',
  CH: 'Suisse',
  SR: 'Suriname',
  SJ: 'Svalbard et île jan mayen',
  SZ: 'Swaziland',
  SY: 'Syrie',
  TW: 'Taïwan',
  TJ: 'Tadjikistan',
  TZ: 'Tanzanie',
  CZ: 'Tchèquie',
  TD: 'Tchad',
  TH: 'Thaïlande',
  TL: 'Timor-leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinité-et-tobago',
  TN: 'Tunisie',
  TM: 'Turkménistan',
  TC: 'Turques-et-Caïques, îles',
  TR: 'Turquie',
  TV: 'Tuvalu',
  UA: 'Ukraine',
  UY: 'Uruguay',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viêt Nam',
  WF: 'Wallis et futuna',
  YE: 'Yémen',
  ZM: 'Zambie',
  ZW: 'Zimbabwe',
};

export type IsoAlphaCountry = 'alpha2' | 'alpha3';

export const alpha2CountryKeys = Object.keys(alpha2Countries);
export const alpha3CountryKeys = Object.keys(alpha3Countries);

export const alpha2CountryOptions = alpha2CountryKeys.map((alpha2) => ({
  label: alpha2Countries[alpha2],
  value: alpha2,
}));
export const alpha3CountryOptions = alpha3CountryKeys.map((alpha3) => ({
  label: alpha3Countries[alpha3],
  value: alpha3,
}));

export function getAlphaFromCountry(label: string, code: IsoAlphaCountry = 'alpha3'): string {
  if (!label) return '';

  const countries = code === 'alpha2' ? alpha2Countries : alpha3Countries;

  const alpha = Object.entries(countries).find(
    ([, country]) => country.toLowerCase() === label.toLowerCase(),
  );
  if (alpha) {
    return alpha[0];
  }

  return label;
}

export function formatCountry(alpha: string, code: IsoAlphaCountry = 'alpha3'): string {
  const countries = code === 'alpha2' ? alpha2Countries : alpha3Countries;

  const fmtAlpha = alpha.toLocaleUpperCase();
  const country = countries[fmtAlpha];

  if (!country) {
    return alpha;
  }

  return country;
}
