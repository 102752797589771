import {
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/solid';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlUboStatusValues } from '@blockpulse3/data/locales/types';
import {
  IdentityType,
  IdentityVerificationStatus,
  UboRole,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';
import { useAuthUser, useManagedIndividual } from '@blockpulse3/web-client/auth';

import { canUserUpdateCompany } from './utils';

type Props = {
  onOpen: () => void;
};

export function SettingsCompanyUbos({ onOpen }: Props): JSX.Element {
  const t = useTranslations();
  const i18nUboStatus = useTranslations('UboStatusValues');

  const { user: authUser } = useAuthUser();
  const { individual } = useManagedIndividual();
  const { companyId = '' } = useParams();
  const { data, loading, error } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  if (error || !data) {
    return (
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 5, lg: 8 }}>
        <Text flexBasis={{ lg: 250 }} fontSize="lg" fontWeight="medium">
          {t('Owner', { nb: 1 })}
        </Text>
        <ErrorQueryCard h="100px" maxW={{ lg: '3xl' }} w="full" />
      </Stack>
    );
  }

  const company = data?.company;
  const ubos = company?.ubos || [];
  const isEditDisabled = !canUserUpdateCompany(authUser, individual, data.company);

  return (
    <Stack
      alignItems="flex-start"
      direction={{ base: 'column', lg: 'row' }}
      spacing={{ base: 5, lg: 8 }}
    >
      <HStack alignItems="center" flexBasis={{ lg: 250 }}>
        <Text fontSize="lg" fontWeight="medium">
          {t('Owner', { nb: 1 })}
        </Text>
      </HStack>
      <Skeleton isLoaded={!loading} maxWidth={{ lg: '3xl' }} width="full">
        <Card maxW={{ lg: '3xl' }} variant="divider-bottom">
          <CardBody>
            <Stack spacing="4">
              {ubos.length === 0 && <Text color="gray.500">{t('NoDefinedOwner')}</Text>}
              {ubos.map((ubo) => {
                const individual = ubo?.individualIdentity;
                const name = individual
                  ? `${individual.firstName} ${individual.lastName}`
                  : `${ubo.firstName} ${ubo.lastName}`;

                return (
                  <Stack
                    key={ubo.id}
                    alignItems="flex-start"
                    direction={['column', 'row']}
                    spacing="2"
                  >
                    <Heading flexBasis={['auto', '150px']} size="sm">
                      {ubo.role === UboRole.HasCapital ? (
                        <>
                          {ubo.sharePercentage}%
                          <Text fontSize="xs">
                            {t(ubo.isDirect ? 'Directly' : 'Indirectly').toLowerCase()}
                          </Text>
                        </>
                      ) : (
                        i18nUboStatus(ubo.role as IntlUboStatusValues)
                      )}
                    </Heading>
                    <IdentityCard>
                      <IdentityAvatar
                        boxSize="9"
                        src={individual?.identity?.profilePicture}
                        type={IdentityType.INDIVIDUAL}
                      />
                      <IdentityCardTitle
                        fontSize="md"
                        isChecked={
                          individual?.kycVerificationStatus === IdentityVerificationStatus.APPROVED
                        }
                      >
                        <Text>{name}</Text>
                      </IdentityCardTitle>
                      <IdentityCardDescription>
                        {individual ? (
                          <>
                            <Text>{individual?.address?.formatted}</Text>
                            <Text>{individual.email}</Text>
                            <Text>{individual.phone}</Text>
                          </>
                        ) : (
                          <Text>{ubo?.address?.formatted}</Text>
                        )}
                      </IdentityCardDescription>
                    </IdentityCard>
                  </Stack>
                );
              })}
              <Button isDisabled={isEditDisabled} variant="secondary" onClick={onOpen}>
                <Icon as={PencilIcon} mr="1" /> {t('Edit')}
              </Button>
            </Stack>
          </CardBody>
        </Card>
      </Skeleton>
    </Stack>
  );
}

export type SettingsCompanyUbosProps = Props;
