import * as Apollo from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ConnectionType } from '@blockpulse3/data/shared';
import { PageInfo } from '@blockpulse3/graphql/hooks';
import { extractNodesFromEdges } from '@blockpulse3/helpers';

/**
 * Options for the usePagination hook
 */
type UsePaginationOptions<TQuery, TVariables extends Apollo.OperationVariables> = {
  /* ** Query document. Ex `GetSubscriptionsQueryDocument` ** */
  queryDocument: Apollo.DocumentNode;
  /* ** useQuery variables, typed ** */
  queryOptions: Apollo.QueryHookOptions<TQuery, TVariables>;
  /* ** Key holding Relay type data ** */
  dataName: keyof TQuery;
  /* ** Page size returned ** */
  pageSize: number;
};

/**
 * type for handlers returned by the usePagination hook.
 */
export type PaginationHandlers<T> = {
  results: T[];
  pageInfo: PageInfo;
  totalCount: number;
  startIndex: number;
  endIndex: number;
  pageCount: number;
  currentPage: number;
  loading: boolean;
  isLoadingMore: boolean;
  /** * This function is used to move to the next page in the pagination sequence. */
  handleNextPage: () => void;
  /** used to move to the previous page in the pagination sequence. */
  handlePreviousPage: () => void;
  refetch: () => void;
  reset: () => void;
};

/**
 * usePagination.
 * usePagination is a custom hook to abstract the logic of pagination navigation.
 * It keeps track of the cursor history and provides functions to go to the next and previous pages.
 *
 * @param {UsePaginationOptions}
 * @returns {PaginationHandlers<TNode>}
 */
export function usePagination<TQuery, TVariables extends Apollo.OperationVariables, TNode>({
  queryDocument,
  queryOptions,
  dataName,
  pageSize,
}: UsePaginationOptions<TQuery, TVariables>): PaginationHandlers<TNode> {
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [currentCursor, setCurrentCursor] = useState<string | null>(null);
  const [cursorHistory, setCursorHistory] = useState<string[]>([]);
  const [cursorMap, setCursorMap] = useState<{ [cursor: string]: number }>({});

  const defaultPageInfo: PageInfo = { hasNextPage: false, hasPreviousPage: false };

  const { data, loading, fetchMore, refetch } = Apollo.useQuery<TQuery, TVariables>(
    queryDocument,
    queryOptions,
  );

  const response = data?.[dataName] as ConnectionType<TNode>;
  const totalCount = response?.totalCount || 0;

  const results = useMemo(
    () => (response ? extractNodesFromEdges<TNode>(response) : []),
    [response],
  );

  const pageInfo = useMemo(() => {
    const info = { ...response?.pageInfo };
    info.hasPreviousPage = !!currentCursor;
    const hasNotNextPage =
      !info.hasNextPage &&
      ((!currentCursor && !cursorHistory.length) ||
        (currentCursor && cursorHistory.indexOf(currentCursor) === cursorHistory.length - 1));
    info.hasNextPage = !hasNotNextPage;
    return info;
  }, [response, currentCursor, cursorHistory]);

  const endCursor = useMemo(() => pageInfo?.endCursor, [pageInfo?.endCursor]);

  useEffect(() => {
    if (pageInfo?.endCursor && !cursorMap[pageInfo.endCursor]) {
      const newCursorMap = { ...cursorMap };
      newCursorMap[pageInfo.endCursor] = results.length;
      setCursorMap(newCursorMap);
    }
  }, [results, pageInfo, cursorMap]);

  const [resultSlice, startIndex, endIndex] = useMemo(() => {
    const startIndex = currentCursor && cursorMap[currentCursor] ? cursorMap[currentCursor] : 0;
    const endIndex = Math.min(startIndex + pageSize, totalCount);
    return [results.slice(startIndex, endIndex), startIndex, endIndex];
  }, [results, currentCursor, cursorMap, pageSize, totalCount]);

  const loadMore = async (cursor: string): Promise<void> => {
    setCurrentCursor(cursor);
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        after: cursor,
      },
    });
    setIsLoadingMore(false);
  };

  const handleNextPage = async (): Promise<void> => {
    if (cursorHistory.length) {
      const existingNextCursor =
        cursorHistory[(currentCursor ? cursorHistory.indexOf(currentCursor) : -1) + 1];
      if (existingNextCursor) {
        setCurrentCursor(existingNextCursor);
        return;
      }
    }
    if (endCursor) {
      setCursorHistory([...cursorHistory, endCursor]);
      await loadMore(endCursor);
    }
  };

  const handlePreviousPage = async (): Promise<void> => {
    if (currentCursor && cursorHistory.length) {
      const existingPreviousCursor = cursorHistory[cursorHistory.indexOf(currentCursor) - 1];
      if (existingPreviousCursor) {
        setCurrentCursor(existingPreviousCursor);
        return;
      }
    }
    setCurrentCursor(null);
    // TODO: add support for backward pagination when necessary
    // if (startCursor) {
    //   setCursorHistory([startCursor, ...cursorHistory]);
    //   await loadMore(startCursor);
    // }
  };

  const reset = useCallback(() => {
    setCurrentCursor(null);
    setCursorHistory([]);
    setCursorMap({});
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resetAndRefetch = useCallback(async (): Promise<Apollo.ApolloQueryResult<any>> => {
    reset();
    return refetch();
  }, [refetch]);

  return {
    results: resultSlice,
    pageInfo: pageInfo || defaultPageInfo,
    totalCount,
    startIndex,
    endIndex,
    pageCount: Math.ceil(totalCount / pageSize),
    currentPage: Math.ceil(endIndex / pageSize),
    loading,
    isLoadingMore,
    handleNextPage,
    handlePreviousPage,
    refetch: resetAndRefetch,
    reset,
  };
}
