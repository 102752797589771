import { Link as ChakraLink, Text, useDisclosure } from '@chakra-ui/react';
import { CreditCardIcon } from '@heroicons/react/outline';
import { Link as ReactRouterLink, generatePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  FundraisingWorkflowType,
  MergedPaymentStep,
  OperationStatus,
  SubscriptionPaymentMethod,
  SubscriptionSide,
  SubscriptionStepStatus,
  useGetBankDistributionBySubscriptionQuery,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionStep } from '../../SubscriptionStep';
import { SubscriptionBankDetailsModal } from './SubscriptionBankDetailsModal';

type Props = {
  subscriptionStep: MergedPaymentStep;
  isDisabled: boolean;
};

export function PaymentStep({ subscriptionStep, isDisabled }: Props): JSX.Element {
  const t = useTranslations();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { subscriptionId = '', companyId = '' } = useParams();

  const { identityId } = useIdentity();

  const { data } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = data?.subscription;

  const bankDistributionReq = useGetBankDistributionBySubscriptionQuery({
    variables: { subscriptionId },
    skip:
      !subscription ||
      subscription.paymentMethod !== SubscriptionPaymentMethod.QUEUED_CREDIT_TRANSFER,
  });
  const bankDistribution = bankDistributionReq.data?.getBankDistributionBySubscription;

  const investAmount = subscription?.investAmount;
  const operation = subscription?.operation;
  const isAfterClosing = !!subscriptionStep.isAfterClosing;
  const fundraisingIsReviewed = operation?.status === OperationStatus.REVIEWED;

  /* ** Get subscription's identity side context * **/
  const subscriptionSide =
    subscription?.sellerIdentity?.id === identityId
      ? SubscriptionSide.SELLER
      : SubscriptionSide.BUYER;

  /* ** Fund values ** */
  const fundsTransferValue = formatNumberCurrency(subscription?.bankVirtualIban?.balance || 0);
  const fundsTransferTotal = formatNumberCurrency(subscription?.amountDue || 0);

  const isInvestAmount = !!investAmount && investAmount !== 0;

  const handleFundsTransferModalOpen = (status: SubscriptionStepStatus): void => {
    if (
      ![SubscriptionStepStatus.VALID, SubscriptionStepStatus.PENDING].includes(status) &&
      subscriptionSide !== SubscriptionSide.SELLER
    ) {
      onOpen();
    }
  };

  const getStatusMessage = (
    stepStatus: SubscriptionStepStatus,
    paymentMethod: SubscriptionPaymentMethod,
  ): React.ReactNode => {
    if (subscriptionSide === SubscriptionSide.SELLER) {
      return (
        stepStatus !== SubscriptionStepStatus.VALID && t('FundsReceivedAfterApprovalByCompany')
      );
    } else {
      switch (stepStatus) {
        case SubscriptionStepStatus.VALID:
          return t.rich('FundsReceivedSuccessfully', {
            fundsTransferTotal,
            important: (chunks) => <Text as="b">{chunks}</Text>,
          });
        case SubscriptionStepStatus.ERROR:
          if (paymentMethod === SubscriptionPaymentMethod.SCHEDULED_CREDIT_TRANSFER) {
            return t.rich('ErrorScheduledPaymentDescription', {
              subscriptionAmount: formatNumberCurrency(investAmount || 0),
              important: (chunks) => <Text as="b">{chunks}</Text>,
            });
          }
          return t.rich('FundsTransferReceived', {
            fundsTransferValue,
            fundsTransferTotal,
            important: (chunks) => <Text as="b">{chunks}</Text>,
          });
        case SubscriptionStepStatus.PENDING:
        case SubscriptionStepStatus.IN_PROGRESS:
          if (paymentMethod === SubscriptionPaymentMethod.QUEUED_CREDIT_TRANSFER) {
            const distributionId = bankDistribution?.id;
            if (!distributionId) return '';

            const route = companyId ? routes.company.distribution.href : routes.me.payment.href;
            const distributionLink = '../../' + generatePath(route, { distributionId });
            return t.rich('PendingQueuedPaymentDescription', {
              link: (chunks) => (
                <ChakraLink
                  as={ReactRouterLink}
                  relative="path"
                  textDecoration="underline"
                  to={distributionLink}
                >
                  {chunks}
                </ChakraLink>
              ),
            });
          } else if (paymentMethod === SubscriptionPaymentMethod.SCHEDULED_CREDIT_TRANSFER) {
            const transactionDebitDate = subscription?.operation?.transactionDebitDate;
            return t.rich('PendingScheduledPaymentDescription', {
              date: formatDate(transactionDebitDate),
              important: (chunks) => (
                <Text as="span" fontWeight="bold">
                  {chunks}
                </Text>
              ),
            });
          }
          return '';
        default:
          return t.rich('TransferFundsSoon', {
            fundsTransferTotal,
            important: (chunks) => <Text as="b">{chunks}</Text>,
          });
      }
    }
  };

  const { type, status, side } = subscriptionStep;
  const isStepDisabled =
    (side && subscriptionSide !== side) || isDisabled || (isAfterClosing && !fundraisingIsReviewed);
  const isAllInFundraising =
    operation?.fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;
  const statusMessage =
    isInvestAmount &&
    (!isAllInFundraising || !isStepDisabled) &&
    getStatusMessage(status, subscription?.paymentMethod);

  return (
    <>
      {isAfterClosing && (
        <Text fontSize="sm" fontWeight="600" mt="2">
          {t('PostValidationStep')}
        </Text>
      )}
      <SubscriptionStep
        icon={CreditCardIcon}
        isDisabled={isStepDisabled}
        status={status}
        title={t('FundsTransfer')}
        type={type}
        onClick={handleFundsTransferModalOpen}
      >
        {statusMessage && <Text fontSize="sm">{statusMessage}</Text>}
      </SubscriptionStep>
      {isOpen && <SubscriptionBankDetailsModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
}
