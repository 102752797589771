import {
  Card,
  CardBody,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { ManagementType, SpaceType, useGetSpaceQuery } from '@blockpulse3/graphql/hooks';
import { OperationList } from '@blockpulse3/web-client/dashboard';

import { SpaceList } from './AdminSpaces/SpaceList';
import { SpaceHeader } from './SpaceHeader';
import { SpaceManagements } from './SpaceManagements';
import { SpaceMembers } from './SpaceMembers';

type Props = unknown;

/**
 * SpaceAdminView.
 * Space admin view with member table.
 *
 * @returns {JSX.Element}
 */
export function SpaceAdminView(): JSX.Element {
  const t = useTranslations();

  const { spaceId = '' } = useParams();

  const [tabIndex, setTabIndex] = useState(0);

  const { data } = useGetSpaceQuery({ variables: { spaceId }, skip: !spaceId });
  const space = data?.space;

  // Reset tab index on space change
  useEffect(() => {
    setTabIndex(0);
  }, [spaceId]);

  const handleTabsChange = (index: number): void => {
    setTabIndex(index);
  };

  const isProvider = space?.type === SpaceType.PROVIDER;
  const isGlobal = space?.type === SpaceType.GLOBAL;

  return (
    <Stack spacing="6">
      <Stack spacing="4">
        <SpaceHeader />
        <Heading size="lg">
          {t('Space', { nb: 1 })} {space?.name}
        </Heading>
      </Stack>
      <Tabs isLazy index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab>{t('Administrator', { nb: 2 })}</Tab>
          <Tab>{t('ControlledCompanies')}</Tab>
          <Tab>{t('ManagedIdentities')}</Tab>
          {!isGlobal && <Tab>{t('ManagedOperations')}</Tab>}
          {isProvider && <Tab>{t('ManagedSpaces')}</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel as={Stack} gap="6">
            <SpaceMembers />
          </TabPanel>
          <TabPanel as={Stack} gap="6">
            <SpaceManagements type={ManagementType.OWNER} />
          </TabPanel>
          <TabPanel as={Stack} gap="6">
            <SpaceManagements type={ManagementType.MANAGER} />
          </TabPanel>
          {!isGlobal && (
            <TabPanel as={Stack} gap="6">
              <Card>
                <CardBody>
                  <OperationList displayType="table" />
                </CardBody>
              </Card>
            </TabPanel>
          )}
          {isProvider && (
            <TabPanel as={Stack} gap="6">
              <SpaceList showManaged={true} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export type SpaceAdminViewProps = Props;
