import { Props, Select, SingleValue } from 'chakra-react-select';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useMemo, useState } from 'react';

import { noop } from '@blockpulse3/data/shared';
import { useSearchCompanyQuery, useSearchSirenLazyQuery } from '@blockpulse3/graphql/hooks';

import { components } from './components';
import { CompanyOption, CompanyOptionGroup } from './types';

dayjs.extend(customParseFormat);

export type SearchCompanySelectProps = Props<CompanyOption, false, CompanyOptionGroup> & {
  onChange?: (option: SingleValue<CompanyOption>) => void;
};

/**
 * SearchCompanySelect.
 * Generic Select component handling Pappers options.
 *
 * @param {}
 */
export function SearchCompanySelect({
  onChange = noop,
  ...props
}: SearchCompanySelectProps): JSX.Element {
  /* ** Search input for the Pappers' request ** */
  const [searchString, setSearchString] = useState<string>('');

  /* ** Pappers /recherche query ** */
  const { data } = useSearchCompanyQuery({
    variables: {
      searchString,
    },
    skip: !searchString,
  });

  /* ** Pappers /entreprise query ** */
  const [searchSiren, { loading }] = useSearchSirenLazyQuery();

  /* ** Update search company string on input change ** */
  const handleInputChange = (newInput: string): void => {
    setSearchString(newInput);
  };

  const handleChange = (option: SingleValue<CompanyOption>): void => {
    if (option) {
      searchSiren({
        variables: { siren: option.registrationNumber || '' },
        onCompleted: (res) => {
          onChange({
            ...option,
            registrationEntity: res?.searchSIREN?.registrationEntity || '',
            businessActivityDescription: res?.searchSIREN?.businessActivityDescription || '',
          });
        },
        onError: () => {
          onChange(option);
        },
      });
    }
  };

  const options = useMemo(() => {
    return data?.searchCompany.map((company) => {
      const date = dayjs(company.creationDate, 'DD/MM/YYYY');
      return {
        category: company?.category || '',
        name: company?.name || '',
        creationDate: date.format('YYYY-MM-DD'),
        businessActivity: company?.businessActivity || '',
        shareCapital: parseFloat(company?.shareCapital || '0'),
        corporateForm: company?.corporateForm || '',
        address: {
          city: company?.address?.city || '',
          country: company?.address?.country || '',
          line: company?.address?.line || '',
          postalCode: company?.address?.postalCode || '',
        },
        registrationNumber: company?.registrationNumber || '',
        /* ** Initialize fields, will be filled on the onChange callback ** */
        registrationEntity: '',
        businessActivityDescription: '',
      };
    });
  }, [data?.searchCompany]);

  return (
    <Select<CompanyOption, false>
      components={components}
      filterOption={(): boolean => true}
      getOptionLabel={(option): string => option?.name || ''}
      getOptionValue={(option): string => option?.name || ''}
      isLoading={loading}
      options={options}
      placeholder=""
      onChange={handleChange}
      onInputChange={handleInputChange}
      {...props}
    />
  );
}
