import * as yup from 'yup';

import {
  FormErrors,
  requiredEmailField,
  requiredNumberField,
  requiredPercentageNumberField,
  requiredStringField,
} from '@blockpulse3/data/shared';

import { IOperationLegalSubscriberInformations } from './types';

export const schema = yup.object({
  email: requiredEmailField,
  name: requiredStringField,
  identificationNumber: requiredStringField,
  amount: yup
    .number()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
    .when('$amountRequired', {
      is: true,
      then: () => requiredNumberField,
    }),
  admissionFees: yup
    .number()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
    .when('$feesRequired', {
      is: true,
      then: () => requiredPercentageNumberField(0),
    }),
  minimalAmount: yup
    .number()
    .nullable()
    .when('$amountRangeRequired', {
      is: true,
      then: () =>
        yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value))
          .test('min-max', FormErrors.MinimalAmountGreaterThanMaximal, (value, context) => {
            if (!value || value === 0 || !context.parent.maximalAmount) {
              return true;
            }
            return context.parent.maximalAmount >= value;
          })
          .test('min-negative', FormErrors.PositiveFormat, (value) => {
            if (!value || value === 0) {
              return true;
            }
            return value >= 0;
          })
          .when('$hardCap', ([hardCap], schema) => {
            return hardCap ? schema.max(hardCap, FormErrors.AmountGreaterThanHardCap) : schema;
          }),
    }),
  maximalAmount: yup
    .number()
    .nullable()
    .when('$amountRangeRequired', {
      is: true,
      then: () =>
        yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value))
          .test('max-min', FormErrors.MaximalAmountLessThanMinimal, (value, context) => {
            if (!value || value === 0 || !context.parent.minimalAmount) {
              return true;
            }
            return value >= context.parent.minimalAmount;
          })
          .test('max-negative', FormErrors.PositiveFormat, (value) => {
            if (!value || value === 0) {
              return true;
            }
            return value >= 0;
          })
          .when('$hardCap', ([hardCap], schema) => {
            return hardCap > 0 ? schema.max(hardCap, FormErrors.AmountGreaterThanHardCap) : schema;
          }),
    }),
}) as yup.ObjectSchema<IOperationLegalSubscriberInformations>;
