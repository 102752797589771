import { Badge, Skeleton, Stack, Text } from '@chakra-ui/react';
import {
  ActionMeta,
  AsyncCreatableSelect,
  ChakraStylesConfig,
  MultiValue,
} from 'chakra-react-select';
import { useCallback, useId, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useAttachSubscriptionTagMutation,
  useCreateAndAttachTagMutation,
  useDetachSubscriptionTagMutation,
  useGetSubscriptionQuery,
  useGetTagsLazyQuery,
} from '@blockpulse3/graphql/hooks';
import {
  TagInput,
  TagMultiValue,
  TagOption,
  TagPlaceholder,
  useErrorToast,
  useGetNextColorTag,
} from '@blockpulse3/ui/commons';

type Props = unknown;

const chakraStyles: ChakraStylesConfig<TagOption, true> = {
  placeholder: (provided) => ({
    ...provided,
    color: 'gray.400',
  }),
};

/*
 * OperationPanelTags.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationPanelTags(): JSX.Element {
  const t = useTranslations();

  /* ** Default generated id for async select re-render on tag deletion ** */
  const tagKeyId = useId();

  const { companyId = '', operationId = '', subscriptionId = '' } = useParams();

  const errorToast = useErrorToast();

  const { data, loading, error, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });
  const [loadTags, tagsReq] = useGetTagsLazyQuery();

  const [attachTag] = useAttachSubscriptionTagMutation();
  const [detachTag] = useDetachSubscriptionTagMutation();
  const [createAndAttachTag] = useCreateAndAttachTagMutation();

  /* ** AsyncCreatableSelect key, used for the reloading ** */
  const [selectKey, setSelectKey] = useState<string>('select-id');

  /* ** Hold next tag color ** */
  const createTagColor = useGetNextColorTag({ companyId, operationId });

  /* ** Promise callback to load tags on input change ** */
  const loadOptions = useCallback(
    async (inputValue: string) => {
      const { data } = await loadTags({ variables: { getTagsInput: { companyId, operationId } } });

      if (!data) {
        return [];
      }

      return data.getTags
        .filter((tag) => tag.name.toLowerCase().includes(inputValue.toLowerCase()))
        .map((tag) => ({ label: tag.name, value: tag.id, color: tag.color }));
    },
    [companyId, operationId, loadTags],
  );

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Tags')}
        </Text>
        <Skeleton h="60px" />
      </Stack>
    );
  }

  const handleTagCreate = (name: string): void => {
    createAndAttachTag({
      variables: {
        createAndAttachTagInput: {
          companyId,
          name,
          color: createTagColor,
          subscriptionId,
        },
      },
      onCompleted: (data) => {
        refetch();
        /* ** Update tag query with the new one ** */
        tagsReq.refetch();
        /* ** Reload `<AsyncCreatableSelect />` loadOptions by updating the key ** */
        setSelectKey(data.createAndAttachTag.id);
      },
      onError: () => {
        errorToast({ title: t('TagCreationError') });
      },
    });
  };

  const handleTagChange = (_: MultiValue<TagOption>, action: ActionMeta<TagOption>): void => {
    switch (action.action) {
      case 'select-option': {
        if (action.option) {
          attachTag({
            variables: {
              attachSubscriptionTagInput: {
                companyTagId: action.option.value,
                subscriptionId,
              },
            },
            onCompleted: () => {
              refetch();
            },
            onError: () => {
              errorToast({ title: t('AssociateTagError') });
            },
          });
        }
        break;
      }
      case 'pop-value':
      case 'remove-value': {
        if (action.removedValue) {
          detachTag({
            variables: {
              detachSubscriptionTagInput: {
                companyTagId: action.removedValue.value,
                subscriptionId,
              },
            },
            onCompleted: () => {
              refetch();
              /* ** Reload `<AsyncCreatableSelect />` loadOptions by updating the key ** */
              setSelectKey(tagKeyId);
            },
            onError: () => {
              errorToast({ title: t('TagDissociationError') });
            },
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const getNoOptionsMessage = (): string => {
    return t('NoExistingTag');
  };

  const subscription = data?.subscription;
  /* ** Subscription tags mapped to an option array ** */
  const tags = subscription.tags || [];
  const tagOptions = tags.map((tag) => ({ label: tag.name, value: tag.id, color: tag.color }));

  return (
    <Stack px="4" spacing="2">
      <Text color="gray.600" fontWeight="600">
        {t('Tags')}
      </Text>
      <AsyncCreatableSelect
        key={selectKey}
        defaultOptions
        isMulti
        isSearchable
        chakraStyles={chakraStyles}
        isClearable={false}
        isLoading={tagsReq.loading}
        loadOptions={loadOptions}
        menuPlacement="auto"
        noOptionsMessage={getNoOptionsMessage}
        placeholder={t('SelectTags')}
        value={tagOptions}
        components={{
          MultiValue: TagMultiValue,
          Input: TagInput,
        }}
        formatCreateLabel={(inputValue: string): JSX.Element => (
          <TagPlaceholder color={createTagColor} inputValue={inputValue} />
        )}
        formatOptionLabel={(option: TagOption): JSX.Element => (
          <Badge
            bg={!option.color ? 'transparent' : undefined}
            colorScheme={option.color}
            p={!option.color ? '0' : undefined}
          >
            {option.label}
          </Badge>
        )}
        onChange={handleTagChange}
        onCreateOption={handleTagCreate}
      />
    </Stack>
  );
}

export type OperationPanelTagsProps = Props;
