import dayjs from 'dayjs';
import * as yup from 'yup';

import {
  FormErrors,
  requiredBooleanField,
  requiredNumberField,
  requiredSelectField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { AssetType, FundraisingWorkflowType } from '@blockpulse3/graphql/hooks';
import { defaultFiscalAdvantageOptions } from '@blockpulse3/web-client/operation/commons';

import { ICrowdfundingFundraisingParametersForm } from './types';

export const crowdfundingSubscriptionPeriodOptions = [
  { label: '6 semaines', value: 42 },
  { label: '8 semaines', value: 56 },
  { label: '12 semaines', value: 84 },
];

export const schemaFundraisingCrowdfunding = yup.object({
  name: requiredStringField,
  subscriptionPeriod: yup.object({
    label: requiredStringField,
    value: requiredNumberField,
  }),
  asset: requiredSelectField,
  valuation: yup.number().when('asset', {
    is: (asset: { type: AssetType }) => asset.type === AssetType.ORDINARY_SHARE,
    then: () => requiredNumberField,
  }),
  sharePrice: yup.number().when('asset', {
    is: (asset: { type: AssetType }) => asset.type === AssetType.ORDINARY_SHARE,
    then: () => requiredNumberField,
  }),
  hardCap: requiredNumberField.test(
    'hardCap-min',
    FormErrors.HardCapGreaterThanNominalValue,
    (value, context) => {
      if (!value || value === 0 || !context.parent.sharePrice) {
        return true;
      }

      return context.parent.sharePrice <= value;
    },
  ),
  hasMifid: requiredBooleanField,
  workflowType: requiredStringField,
  closingDate: requiredStringField
    .nullable()
    .test('all-in-fundraising', FormErrors.RequiredField, (value, context) => {
      return !(context.parent.workflowType === FundraisingWorkflowType.ALL_IN && !value);
    })
    .test('date-passed', FormErrors.ValidDateFormat, (value, context) => {
      if (context.parent.workflowType === FundraisingWorkflowType.FIRST_IN) {
        return true;
      }

      return dayjs(value).isAfter(dayjs());
    }),
  softCap: requiredNumberField.test(
    'max-hardcap',
    FormErrors.SoftGreaterThanHardCap,
    (value, context) => {
      if (!value || value === 0 || !context.parent.sharePrice) {
        return true;
      }

      return context.parent.hardCap > value;
    },
  ),
  minimalAmount: yup
    .number()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
    .nullable()
    .test('min-max', FormErrors.MinimalAmountGreaterThanMaximal, (value, context) => {
      if (!value || value === 0 || !context.parent.maximalAmount) {
        return true;
      }
      return context.parent.maximalAmount >= value;
    })
    .test('min-val', FormErrors.MinimalAmountGreaterThanNominalValue, (value, context) => {
      if (!value || value === 0 || !context.parent.sharePrice) {
        return true;
      }
      return context.parent.sharePrice <= value;
    })
    .test('min-hardCap', FormErrors.AmountGreaterThanHardCap, (value, context) => {
      if (!value || value === 0) {
        return true;
      }
      return value < context.parent.hardCap;
    })
    .test('min-negative', FormErrors.PositiveFormat, (value) => {
      if (!value || value === 0) {
        return true;
      }
      return value >= 0;
    }),
  maximalAmount: yup
    .number()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
    .nullable()
    .test('max-min', FormErrors.MaximalAmountLessThanMinimal, (value, context) => {
      if (!value || value === 0 || !context.parent.minimalAmount) {
        return true;
      }
      return value >= context.parent.minimalAmount;
    })
    .test('max-hardCap', FormErrors.AmountGreaterThanHardCap, (value, context) => {
      if (!value || value === 0) {
        return true;
      }
      return value < context.parent.hardCap;
    })
    .test('min-negative', FormErrors.PositiveFormat, (value) => {
      if (!value || value === 0) {
        return true;
      }
      return value >= 0;
    }),
  proofOfFundsThreshold: yup
    .number()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
    .nullable()
    .test('min-negative', FormErrors.PositiveFormat, (value) => {
      if (!value || value === 0) {
        return true;
      }
      return value >= 0;
    }),
}) as yup.ObjectSchema<ICrowdfundingFundraisingParametersForm>;

export const crowdfundingParametersDefaultValues: ICrowdfundingFundraisingParametersForm = {
  name: '',
  subscriptionPeriod: crowdfundingSubscriptionPeriodOptions[0],
  valuation: 0,
  sharePrice: 0,
  asset: {
    label: 'default',
    value: AssetType.ORDINARY_SHARE,
    type: AssetType.ORDINARY_SHARE,
  },
  allowedFiscalAdvantages: defaultFiscalAdvantageOptions,
  hardCap: null,
  workflowType: FundraisingWorkflowType.FIRST_IN,
  minimalAmount: null,
  maximalAmount: null,
  hasMifid: false,
  softCap: null,
  closingDate: null,
  proofOfFundsThreshold: 40_000,
};
