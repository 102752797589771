import { Card, CardProps, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/solid';

type Props = {
  title: string;
  subtitle?: string;
} & CardProps;

export function WarningCard({ title, subtitle, ...props }: Props): JSX.Element {
  return (
    <Card backgroundColor="yellow.50" borderRadius="md" boxShadow="none" p="3" {...props}>
      <HStack>
        <Icon alignSelf="start" as={ExclamationIcon} boxSize="18px" color="yellow.400" mt="1" />
        <Stack>
          <Text color="yellow.800" fontSize="sm" fontWeight="500">
            {title}
          </Text>
          {subtitle && (
            <Text color="yellow.700" fontSize="sm" fontWeight="400">
              {subtitle}
            </Text>
          )}
        </Stack>
      </HStack>
    </Card>
  );
}
