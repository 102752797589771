import { Badge, BadgeProps, ColorProps, HStack, Text } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { IntlMemberRoles } from '@blockpulse3/data/locales/types';
import { MemberRole } from '@blockpulse3/graphql/hooks';

type Props = {
  /* ** Role of the member ** */
  role: MemberRole;
} & BadgeProps;

const BADGE_COLOR_MAP: Record<
  MemberRole,
  { bgColor: ColorProps['color']; color: ColorProps['color'] }
> = {
  [MemberRole.ADMIN]: {
    bgColor: 'pink.100',
    color: 'pink.800',
  },
  [MemberRole.COLLABORATOR]: {
    bgColor: 'blue.100',
    color: 'blue.800',
  },
  [MemberRole.COMPLIANCE_OFFICER]: {
    bgColor: 'green.100',
    color: 'green.800',
  },
  [MemberRole.OWNER]: {
    bgColor: 'red.100',
    color: 'red.800',
  },
};

/**
 * SpaceMemberBadge.
 * Simple component, rendering a badge of desired space member role.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SpaceMemberBadge({ role, ...props }: Props): JSX.Element {
  const i18nMemberRole = useTranslations('MemberRoles');

  return (
    <Badge
      backgroundColor={BADGE_COLOR_MAP[role].bgColor}
      color={BADGE_COLOR_MAP[role].color}
      px="2"
      py="0.5"
      {...props}
    >
      <HStack spacing="1">
        <Text fontSize="sm">{i18nMemberRole(role as IntlMemberRoles)}</Text>
      </HStack>
    </Badge>
  );
}

export type SpaceMemberBadgeProps = Props;
