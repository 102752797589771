import * as yup from 'yup';

import {
  requiredEmailField,
  requiredNumberField,
  requiredPastDateField,
  requiredSelectField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { IdentityType, RepresentativeRole } from '@blockpulse3/graphql/hooks';
import { formatCountry, formatNationality } from '@blockpulse3/helpers';

import { IRepresentativeForm } from './types';

export const representativeFormDefaults: IRepresentativeForm = {
  /* ** Common ** */
  id: null,
  type: IdentityType.COMPANY,
  role: RepresentativeRole.PRESIDENT,
  address: {
    line: '',
    city: '',
    postalCode: '',
    country: { label: formatCountry('FRA'), value: 'FRA' },
  },
  /* ** Company ** */
  name: '',
  registrationEntity: '',
  registrationNumber: '',
  nominalValue: 0,
  birthdate: '',
  birthplace: '',
  birthCityPostalCode: '',
  birthCountry: { label: formatCountry('FRA'), value: 'FRA' },
  corporateForm: '',
  creationDate: '',
  shareCapital: 0,
  country: { label: formatCountry('FRA'), value: 'FRA' },
  nationality: { label: formatNationality('FRA'), value: 'FRA' },
  businessActivityDescription: '',
  /* ** Individual ** */
  firstName: '',
  lastName: '',
  email: '',
  position: '',
  motherFirstName: '',
  motherLastName: '',
  fatherFirstName: '',
  fatherLastName: '',
};

export const legalRepresentativeFormSchema = yup.object({
  name: requiredStringField,
  corporateForm: requiredStringField,
  shareCapital: requiredNumberField,
  registrationEntity: requiredStringField,
  registrationNumber: requiredStringField,
  address: yup.object({
    line: requiredStringField,
    city: requiredStringField,
    postalCode: requiredStringField,
    country: requiredSelectField,
  }),
  firstName: requiredStringField,
  lastName: requiredStringField,
  position: requiredStringField,
  email: requiredEmailField,
  creationDate: yup.string().when('$hasCreationDate', {
    is: (hasCreationDate: boolean) => hasCreationDate,
    then: () => requiredPastDateField,
  }),
}) as yup.ObjectSchema<IRepresentativeForm>;

export const naturalRepresentativeFormSchema = yup.object({
  firstName: requiredStringField,
  lastName: requiredStringField,
  email: requiredEmailField,
  nationality: requiredSelectField,
  birthdate: requiredPastDateField,
  birthplace: requiredStringField,
  birthCityPostalCode: requiredStringField,
  birthCountry: requiredSelectField,
  address: yup.object({
    line: requiredStringField,
    city: requiredStringField,
    postalCode: requiredStringField,
    country: requiredSelectField,
  }),
  motherFirstName: yup.string().when('$hasFiliation', {
    is: (hasFiliation: boolean) => hasFiliation,
    then: () => requiredStringField,
  }),
  motherLastName: yup.string().when('$hasFiliation', {
    is: (hasFiliation: boolean) => hasFiliation,
    then: () => requiredStringField,
  }),
  fatherFirstName: yup.string().when('$hasFiliation', {
    is: (hasFiliation: boolean) => hasFiliation,
    then: () => requiredStringField,
  }),
  fatherLastName: yup.string().when('$hasFiliation', {
    is: (hasFiliation: boolean) => hasFiliation,
    then: () => requiredStringField,
  }),
}) as yup.ObjectSchema<IRepresentativeForm>;
