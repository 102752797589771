import { Badge, Button, Card, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { AssetInfosFragment, AssetStatus } from '@blockpulse3/graphql/hooks';
import { ASSET_COLOR_MAP, AssetBadge } from '@blockpulse3/ui/commons';

type Props = {
  asset: AssetInfosFragment;
  onClick?: (asset: AssetInfosFragment) => void;
  handleDeleteModalOpen: (asset: AssetInfosFragment) => void;
};

export function AssetCard({
  asset,
  onClick = noop,
  handleDeleteModalOpen = noop,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nAssetType = useTranslations('AssetTypeValues');

  const handleAssetClick = (): void => {
    onClick(asset);
  };

  const isDraft = asset.status === AssetStatus.DRAFT;
  const isDraftWithoutOperation = isDraft && !asset?.operation;

  return (
    <Card
      key={asset.id}
      borderBottomWidth="2px"
      borderColor={ASSET_COLOR_MAP[asset.assetType]}
      borderStyle="solid"
      justifyContent="space-between"
      maxH="150px"
      maxW="180px"
      minH="150px"
      minW="180px"
      overflow="hidden"
      p="3"
    >
      <Stack m="-3" p="3" pl="4" spacing="1">
        <AssetBadge
          assetName={asset.name}
          boxed={false}
          size="lg"
          type={asset.assetType}
          value={asset.supply}
          assetCount={
            ('token' in asset &&
              asset.token &&
              'grants' in asset.token &&
              asset.token?.grants?.length) ||
            undefined
          }
        />
        <HStack justifyContent="space-between">
          <Text color="gray.500" fontSize="xs" fontWeight="400">
            {i18nAssetType(asset.assetType, { nb: 1 })}
          </Text>
          {isDraft && (
            <Badge colorScheme="gray" fontSize="xs">
              {t('Draft', { nb: 1 })}
            </Badge>
          )}
        </HStack>
      </Stack>
      {isDraftWithoutOperation ? (
        <Button
          mb="-1"
          size="xs"
          variant="outline"
          onClick={(): void => handleDeleteModalOpen(asset)}
        >
          {t('DeleteDraft')}
        </Button>
      ) : (
        <Button
          rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="3" />}
          size="xs"
          variant="link"
          onClick={handleAssetClick}
        >
          {t('ShowDetails')}
        </Button>
      )}
    </Card>
  );
}
