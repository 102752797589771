import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import { OperationDocumentType, useGetOperationQuery } from '@blockpulse3/graphql/hooks';

import { DropzoneInput } from '../DropzoneInput';
import { ErrorMessage } from '../ErrorMessage';
import { Input } from '../Input';
import { ResponsiveModal, ResponsiveModalProps } from '../ResponsiveModal';
import { ResponsiveModalFooter } from '../ResponsiveModalFooter';
import { useSuccessToast } from '../Toast';
import { customFileSchema } from './schema';
import { CustomFileForm } from './types';

type Props = {
  onClose: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

export function AddDocumentCustomFileOperationModal({ ...props }: Props): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();
  const { onClose } = props;

  const successToast = useSuccessToast();

  const getOperationReq = useGetOperationQuery({ variables: { operationId }, skip: !operationId });

  const [documentFileName, setDocumentFileName] = useState<{ [key: string]: File }>({});

  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [operationCustomDocumentFile, setOperationCustomDocumentFile] = useState<File>();

  const { register, control, handleSubmit, formState, setError, setValue } = useForm({
    defaultValues: { customFile: '' },
    resolver: yupResolver(customFileSchema),
  });

  const handleFileUpload = async (data: CustomFileForm): Promise<void> => {
    if (!operationId || !operationCustomDocumentFile) return;

    const formData = new FormData();
    formData.append('operationId', operationId);
    formData.append('document', operationCustomDocumentFile);
    formData.append('documentType', OperationDocumentType.CUSTOM_FILE);
    formData.append('filename', data.filename);
    setIsFileLoading(true);

    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/uploadOperationDocument',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .catch(() => {
        setError('customFile', {
          type: 'custom',
          message: FormErrors.DropzoneDefault,
        });
      });
    await getOperationReq.refetch();
    successToast({ title: t('SuccessfulFileUpload') });
    onClose();
  };

  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    if (fileRejections.length === 0) {
      setValue('customFile', acceptedFiles[0].name);
      setDocumentFileName({
        1: new File([acceptedFiles[0].name], acceptedFiles[0].name),
      });
      setOperationCustomDocumentFile(acceptedFiles[0]);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('customFile', { type: 'custom', message: FormErrors.FileTooLarge });
          break;
        }
        default: {
          setError('customFile', { type: 'custom', message: FormErrors.DropzoneDefault });
          break;
        }
      }
    }
  };

  const handleFormSubmit: SubmitHandler<CustomFileForm> = (data): void => {
    handleFileUpload(data);
  };

  const handleFileDelete = async (): Promise<void> => {
    setValue('customFile', '');
    setDocumentFileName({});
  };

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="xl" fontWeight="500">
          {t('AddAnnexDocument')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="add-operation-custom-file" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <FormControl isInvalid={!!formState.errors.filename}>
                <FormLabel htmlFor="filename">{t('DocumentName')}</FormLabel>
                <Input id="title" type="string" {...register('filename')} />
                <ErrorMessage error={formState.errors.filename} />
              </FormControl>
              <Controller
                control={control}
                name="customFile"
                render={(): JSX.Element => (
                  <FormControl>
                    <FormLabel>{t('SelectDocument')}</FormLabel>
                    <DropzoneInput
                      accept={{ 'application/pdf': [] }}
                      files={documentFileName}
                      isLoading={isFileLoading}
                      isPreviewEnabled={false}
                      maxFiles={1}
                      subTitle={t('ImageUpTo5MB')}
                      icon={
                        <Icon
                          as={DocumentTextIcon}
                          boxSize="42px"
                          color="gray.400"
                          strokeWidth="1px"
                        />
                      }
                      onDelete={handleFileDelete}
                      onDrop={handleFileDrop}
                    />
                    <ErrorMessage error={formState.errors?.customFile} />
                  </FormControl>
                )}
              />
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button
            form="add-operation-custom-file"
            isDisabled={getOperationReq.loading || isFileLoading}
            type="submit"
          >
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
