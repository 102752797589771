import { Alert, AlertTitle, AvatarGroup, HStack } from '@chakra-ui/react';
import { useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IdentityType, MemberFilterType, routes } from '@blockpulse3/data/shared';
import {
  GetMembersDocument,
  GetMembersQuery,
  GetMembersQueryVariables,
  MemberEntityInfosFragment,
  MemberStatus,
  useGetMembersCountQuery,
  useGetSpaceQuery,
} from '@blockpulse3/graphql/hooks';
import { usePagination } from '@blockpulse3/ui/ui-hooks';

import { IdentityAvatar } from './IdentityAvatar';

const PAGE_SIZE = 4;

type Props = {
  spaceId: string;
  displayProvider?: boolean;
};

/**
 * MembersOwnershipAlert.
 * Display an alert with either space members or its provider members.
 *
 * @param {Props}
 * @returns {JSX.Element | null}
 */
export function MembersOwnershipAlert({ spaceId, displayProvider }: Props): JSX.Element | null {
  const t = useTranslations();

  const isSpace = useMatch(routes.space.href + '/*');

  const paginationProps = usePagination<
    GetMembersQuery,
    GetMembersQueryVariables,
    MemberEntityInfosFragment
  >({
    queryDocument: GetMembersDocument,
    queryOptions: {
      variables: {
        spaceId,
        first: PAGE_SIZE,
        filterBy: [
          {
            name: displayProvider ? 'onlyProviderMember' : 'noProviderMember',
            type: MemberFilterType.CUSTOM,
          },
          {
            name: 'status',
            value: MemberStatus.VALIDATED,
            type: MemberFilterType.STATUS,
          },
        ],
      },
      skip: !spaceId,
      fetchPolicy: 'no-cache',
    },
    dataName: 'members',
    pageSize: PAGE_SIZE,
  });
  const { results } = paginationProps;

  const spaceReq = useGetSpaceQuery({
    variables: { spaceId },
    skip: !spaceId,
  });

  const membersCountReq = useGetMembersCountQuery({
    variables: { spaceId },
    skip: !spaceId,
    fetchPolicy: 'cache-and-network',
  });

  if (spaceReq.error || membersCountReq.error) {
    return null;
  }

  if (!results.length || !spaceReq.data || !membersCountReq.data) {
    return null;
  }

  const members = results;
  const firstMemberName = members[0].user?.individualIdentity?.name || '';
  const memberIdentities = members.map((member) => member.user?.individualIdentity?.identity);

  const { space } = spaceReq.data;
  const spaceName = displayProvider ? space.provider?.name : space.name;

  const { validatedBase, validatedShadow } = membersCountReq.data.getMembersCount;
  const membersCount = displayProvider ? validatedShadow : validatedBase;
  const remainingMembers = membersCount - 1;

  return (
    <Alert status="info">
      <HStack>
        <AvatarGroup max={PAGE_SIZE} size="md">
          {memberIdentities.map((identity) => (
            <IdentityAvatar
              key={`${displayProvider ? 'shadow' : 'member'}-${identity?.id}`}
              src={identity?.profilePicture}
              type={IdentityType.INDIVIDUAL}
            />
          ))}
        </AvatarGroup>
        <AlertTitle>
          {t.rich(isSpace ? 'MembersOwnershipSpaceAlert' : 'MembersOwnershipCompanyAlert', {
            important: (chunk) => <b>{chunk}</b>,
            firstMemberName,
            remainingMembers,
            spaceName,
          })}
        </AlertTitle>
      </HStack>
    </Alert>
  );
}
