import { CompanyInfosFragment, SearchCompanyInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatCountry, formatNationality } from '@blockpulse3/helpers';

import { ICompanyUboForm } from './types';

export function getCompanyUbos(data: CompanyInfosFragment | undefined): ICompanyUboForm[] {
  if (!data || !data?.ubos) {
    return [];
  }

  return data.ubos?.map((ubo) => ({
    id: ubo.id,
    individualIdentityId: ubo.individualIdentityId,
    firstName: ubo.firstName,
    lastName: ubo.lastName,
    birthCityPostalCode: ubo.birthCityPostalCode,
    birthCountry: {
      label: formatCountry(ubo.birthCountry || 'FRA'),
      value: ubo.birthCountry || 'FRA',
    },
    birthdate: ubo.birthdate,
    birthplace: ubo.birthplace,
    address: {
      line: ubo.address?.line || '',
      city: ubo.address?.city || '',
      postalCode: ubo.address?.postalCode || '',
      country: {
        label: formatCountry(ubo.address?.country || 'FRA'),
        value: ubo.address?.country || 'FRA',
      },
    },
    nationality: {
      label: formatNationality(ubo.nationality || 'FRA'),
      value: ubo.nationality || 'FRA',
    },
    isDirect: ubo.isDirect,
    role: ubo.role,
    sharePercentage: ubo.sharePercentage,
  }));
}

export function getPappersUbos(
  data: SearchCompanyInfosFragment | undefined | null,
): ICompanyUboForm[] {
  if (!data || !data?.ubos) {
    return [];
  }

  return data.ubos.map((ubo) => ({
    id: ubo.id,
    individualIdentityId: ubo.individualIdentityId,
    firstName: ubo.firstName,
    lastName: ubo.lastName,
    birthCityPostalCode: ubo.birthCityPostalCode,
    birthCountry: {
      label: formatCountry(ubo.birthCountry || 'FRA'),
      value: ubo.birthCountry || 'FRA',
    },
    birthdate: ubo.birthdate,
    birthplace: ubo.birthplace,
    address: {
      line: ubo.address?.line || '',
      city: ubo.address?.city || '',
      postalCode: ubo.address?.postalCode || '',
      country: {
        label: formatCountry(ubo.address?.country || 'FRA'),
        value: ubo.address?.country || 'FRA',
      },
    },
    nationality: {
      label: formatNationality(ubo.nationality || 'FRA'),
      value: ubo.nationality || 'FRA',
    },
    isDirect: ubo.isDirect,
    role: ubo.role,
    sharePercentage: ubo.sharePercentage,
  }));
}
