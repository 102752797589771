import {
  Badge,
  Button,
  Heading,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { ISubscription, getBrand, getHostName, routes } from '@blockpulse3/data/shared';
import {
  OperationInvestStatus,
  OperationStatus,
  useGetAllSubscriptionsByIndividualIdentityQuery,
  useGetSubscriptionsByCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useManagedIndividual } from '@blockpulse3/web-client/auth';

import { InvitationCards } from './InvitationCards';
import { concatUserSubscriptions } from './utils';

type Props = {
  limit?: number;
  size?: 'sm' | 'md' | 'lg';
};

/**
 * InvitationList.
 * Subscription invitation cards (STARTED, PASSED).
 *
 * @returns {JSX.Element}
 */
export function InvitationList({ limit, size }: Props): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const { individual } = useManagedIndividual();
  const { companyId = '' } = useParams();

  const {
    data: userReq,
    loading: subscriptionsUserLoading,
    error: subscriptionsUserError,
  } = useGetAllSubscriptionsByIndividualIdentityQuery({
    variables: { individualIdentityId: individual?.id || '' },
    fetchPolicy: 'cache-and-network',
    skip: !individual?.id || !!companyId,
  });
  const {
    data: companyReq,
    loading: subscriptionsCompanyLoading,
    error: subscriptionsCompanyError,
  } = useGetSubscriptionsByCompanyQuery({
    variables: { companyId: companyId || '' },
    fetchPolicy: 'cache-and-network',
    skip: !companyId,
  });

  if ((!companyId && !individual) || subscriptionsCompanyLoading || subscriptionsUserLoading) {
    return (
      <Stack spacing="4">
        <Heading size="md">{t(limit ? 'MyCurrentTransactions' : 'MyTransactions')}</Heading>
        <Stack gap="3">
          <Skeleton minH="107px"></Skeleton>
          <Skeleton minH="107px"></Skeleton>
          <Skeleton minH="107px"></Skeleton>
        </Stack>
      </Stack>
    );
  }

  if (subscriptionsUserError || subscriptionsCompanyError) {
    return (
      <Stack spacing="4">
        <Heading size="md">{t(limit ? 'MyCurrentTransactions' : 'MyTransactions')}</Heading>
        <ErrorQueryCard h="200px" />
      </Stack>
    );
  }

  const subscriptions = userReq?.individualIdentity
    ? concatUserSubscriptions(userReq?.individualIdentity) || []
    : companyReq?.getSubscriptionsByCompany || [];

  /* ** Sort subscriptions by end of subscription date ** */
  const sortSubscriptions = (a: ISubscription, b: ISubscription): number =>
    dayjs(a.operation.endSubscriptionDate).diff(dayjs(b.operation.endSubscriptionDate));

  /* ** Split elements of the getSubscriptions request according to subscriptions status ** */
  const subscriptionsCurrent = (subscriptions || [])
    .filter(
      (subscription) =>
        [OperationStatus.STARTED, OperationStatus.CLOSED, OperationStatus.REVIEWED].includes(
          subscription.operation.status,
        ) && subscription.investStatus !== OperationInvestStatus.DECLINED,
    )
    .sort(sortSubscriptions);
  const subscriptionsPassed = (subscriptions || [])
    .filter(
      (subscription) =>
        subscription.operation.status === OperationStatus.FINALIZED &&
        subscription.investStatus !== OperationInvestStatus.DECLINED,
    )
    .sort(sortSubscriptions);

  const hostname = getHostName();
  const brand = getBrand(hostname);

  const isAxerealSection =
    brand === 'axereal' && dayjs().isBefore('2023-09-12') && subscriptions.length === 0;

  const handleButtonClick = (): void => {
    if (companyId) {
      navigate(routes.company.invitations.href);
    } else {
      navigate(routes.me.invitations.href);
    }
  };

  const RenderOperationListItems = (): JSX.Element => {
    if (limit) {
      return (
        <Stack spacing="4">
          <InvitationCards limit={3} size={size} subscriptions={subscriptionsCurrent} />
          {subscriptions.length > 0 && (
            <Button variant="link" onClick={handleButtonClick}>
              {t('SeeAllTransactions')}
            </Button>
          )}
        </Stack>
      );
    }

    return (
      <Tabs defaultIndex={0}>
        <TabList>
          <Tab>
            {t('InProgress')}
            {subscriptionsCurrent.length > 0 && <Badge ml="2">{subscriptionsCurrent.length}</Badge>}
          </Tab>
          <Tab>
            {t('Past')}
            {subscriptionsPassed.length > 0 && <Badge ml="2">{subscriptionsPassed.length}</Badge>}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <InvitationCards size={size} subscriptions={subscriptionsCurrent} />
          </TabPanel>
          <TabPanel>
            <InvitationCards size={size} subscriptions={subscriptionsPassed} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  };

  return (
    <Stack spacing="4">
      <Heading size="md">{t(limit ? 'MyCurrentTransactions' : 'MyTransactions')}</Heading>
      {isAxerealSection ? (
        <Stack layerStyle="emptyState" spacing="0">
          <Stack h="65px">
            <BrandIcon bgTheme="light" h="full" w="156px" />
          </Stack>
          <Text>
            {t.rich('AxerealInvestOperationStartsOnSeptember12', {
              important: (chunks) => <Text as="b">{chunks}</Text>,
            })}
          </Text>
          <Text>{t('RetrieveInvitationLater')}</Text>
        </Stack>
      ) : (
        <RenderOperationListItems />
      )}
    </Stack>
  );
}
