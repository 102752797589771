import { Badge, Box, HStack, Icon, Text, ThemeTypings } from '@chakra-ui/react';
import { XIcon } from '@heroicons/react/outline';
import { InputProps, MultiValueProps, components } from 'chakra-react-select';
import { useTranslations } from 'use-intl';

import { TagOption } from './types';

export type AsyncCreatableSelectCreateLabelProps = {
  /* ** Received inputValue from AsyncCreatableSelect ** */
  inputValue: string;
  /* ** Color of the badge that will be created ** */
  color: ThemeTypings['colorSchemes'];
};

/**
 * TagPlaceholder.
 * Custom component for the create Tag when no option exists.
 *
 * @param {AsyncCreatableSelectCreateLabelProps}
 * @returns {JSX.Element}
 */
export function TagPlaceholder({
  inputValue,
  color,
}: AsyncCreatableSelectCreateLabelProps): JSX.Element {
  const t = useTranslations();

  return (
    <HStack>
      <Text>
        {t.rich('CreateTagRich', {
          name: inputValue,
          tag: (chunk) => <Badge colorScheme={color}>{chunk}</Badge>,
        })}
      </Text>
    </HStack>
  );
}

/**
 * TagMultiValue.
 * Custom MultiValue component for the `<AsyncCreatableSelect />`.
 * Renders `<Badge />` instead.
 *
 * @param {MultiValueProps}
 * @returns {JSX.Element}
 */
export function TagMultiValue({ ...props }: MultiValueProps<TagOption, true>): JSX.Element {
  return (
    <Badge colorScheme={props.data.color} mr="2" my="1">
      <HStack spacing="1">
        <Text>{props.data.label}</Text>
        <Box cursor="pointer" lineHeight="0" {...props.removeProps}>
          <Icon as={XIcon} />
        </Box>
      </HStack>
    </Badge>
  );
}

/**
 * TagInput.
 * Custom Input component for the `<AsyncCreatableSelect />`.
 * 255 characters limit.
 *
 * @param {InputProps}
 * @returns {JSX.Element}
 */
export function TagInput({ children, ...props }: InputProps<TagOption, true>): JSX.Element {
  return (
    <components.Input {...props} maxLength={255}>
      {children}
    </components.Input>
  );
}
