export enum AssetType {
  ORDINARY_SHARE = 'ORDINARY_SHARE',
  PREFERRED_SHARE = 'PREFERRED_SHARE',
  BSA = 'BSA',
  BSA_AIR = 'BSA_AIR',
  BSPCE = 'BSPCE',
  AGA = 'AGA',
  BOND = 'BOND',
}

export enum StockAssetType {
  ORDINARY_SHARE = 'ORDINARY_SHARE',
  PREFERRED_SHARE = 'PREFERRED_SHARE',
}

export enum OptionAssetType {
  AGA = 'AGA',
  BSA = 'BSA',
  BSA_AIR = 'BSA_AIR',
  BSPCE = 'BSPCE',
}

export enum BondAssetType {
  BOND = 'BOND',
}
