import { Badge, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { OperationRowsCurrent, OperationRowsDrafts, OperationRowsPassed } from './OperationRows';
import { useGetOperationsCount } from './OperationRows/hook';
import { OperationRowDisplayType } from './OperationRows/types';

type Props = {
  displayType: OperationRowDisplayType;
};

export function OperationRowsFull({ displayType }: Props): JSX.Element {
  const t = useTranslations();

  const { operationsCount } = useGetOperationsCount();
  const { started = 0, passed = 0, drafts = 0 } = operationsCount;

  return (
    <Tabs isLazy defaultIndex={0}>
      <TabList>
        <Tab>
          <Text>{t('InProgress')}</Text>
          <Badge ml="2">{started}</Badge>
        </Tab>
        <Tab>
          <Text>{t('Past')}</Text>
          <Badge ml="2">{passed}</Badge>
        </Tab>
        <Tab>
          <Text>{t('Draft', { nb: 1 })}</Text>
          <Badge ml="2">{drafts}</Badge>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <OperationRowsCurrent displayType={displayType} />
        </TabPanel>
        <TabPanel>
          <OperationRowsPassed displayType={displayType} />
        </TabPanel>
        <TabPanel>
          <OperationRowsDrafts displayType={displayType} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
