import dayjs from 'dayjs';

import { AssetEntityInfosFragment } from '@blockpulse3/graphql/hooks';

import { defaultValues, durationUnitOptions } from './schema';
import { IOptionAsset } from './types';

export function getOptionAssetDefaultValues(asset: AssetEntityInfosFragment): IOptionAsset {
  if (!asset.data || asset.data?.__typename !== 'OptionAssetData') return defaultValues;
  return {
    name: asset.name,
    initialSupply: asset.data.initialSupply
      ? asset.data.initialSupply
      : defaultValues.initialSupply,
    remainingSupply: defaultValues.remainingSupply,
    date: asset.data.date ? dayjs(asset.data.date).format('YYYY-MM-DD') : defaultValues.date,
    expirationDate: asset.data.expirationDate
      ? dayjs(asset.data.expirationDate).format('YYYY-MM-DD')
      : defaultValues.expirationDate,
    durationUnit:
      durationUnitOptions.find((option) => option.value === asset.data?.durationUnit) ||
      defaultValues.durationUnit,
    attributionPeriod: asset.data.attributionPeriod
      ? asset.data.attributionPeriod
      : defaultValues.attributionPeriod,
    subscriptionPrice: asset.data.subscriptionPrice
      ? asset.data.subscriptionPrice
      : defaultValues.subscriptionPrice,
    subscriptionPeriod: asset.data.subscriptionPeriod
      ? asset.data.subscriptionPeriod
      : defaultValues.subscriptionPeriod,
    exercisePrice: asset.data.exercisePrice
      ? asset.data.exercisePrice
      : defaultValues.exercisePrice,
    conversionParity: asset.data.conversionParity
      ? asset.data.conversionParity
      : defaultValues.conversionParity,
    delegated: asset.data.delegated ? asset.data.delegated : defaultValues.delegated,
    transferable: asset.data.transferable ? asset.data.transferable : defaultValues.transferable,
    accelerable: asset.data.accelerable ? asset.data.accelerable : defaultValues.accelerable,
    approvable: asset.data.approvable ? asset.data.approvable : defaultValues.approvable,
    hasCurve: asset.data.vestingCurve?.noCurve ? false : true,
    cliffDuration: asset.data.vestingCurve?.cliffDuration
      ? asset.data.vestingCurve?.cliffDuration
      : defaultValues.cliffDuration,
    vestingDuration: asset.data.vestingCurve?.vestingDuration
      ? asset.data.vestingCurve?.vestingDuration
      : defaultValues.vestingDuration,
    intervalDuration: asset.data.vestingCurve?.intervalDuration
      ? asset.data.vestingCurve?.intervalDuration
      : defaultValues.intervalDuration,
  };
}
