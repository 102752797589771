import { generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { OperationStatus } from '@blockpulse3/graphql/hooks';

import { OperationRow } from '../../types';
import { OperationCardTemplate } from './OperationCardTemplate';
import { OperationCardUnknown } from './OperationCardUnknown';

type Props = {
  operation: OperationRow;
};

export function OperationCardOptionPool({ operation }: Props): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const navigate = useNavigate();

  const isOperationPage = useMatch(
    routes.company.href + '/' + routes.company.operations.href + '/*',
  );
  const routePrefix = isOperationPage ? '../' : '';

  const handleOperationDraftClick = (): void => {
    if (!companyId) return;
    // FILL DRAFT OPTION POOL ROUTE HERE
  };

  const handleOperationCurrentClick = (): void => {
    if (companyId) {
      const relPath = generatePath(routes.company.optionPool.href, {
        companyId,
        operationId: operation.id,
      });
      navigate(routePrefix + relPath, { relative: 'path' });
    }

    return;
  };

  const handleRowClick = (): void => {
    switch (operation.status) {
      case OperationStatus.DRAFT: {
        handleOperationDraftClick();
        break;
      }

      default: {
        handleOperationCurrentClick();
        break;
      }
    }
  };

  if (!operation.sharePrice) {
    return <OperationCardUnknown operation={operation} operationSubtitle={t('OptionPool')} />;
  }

  return (
    <OperationCardTemplate
      operation={operation}
      operationSubtitle={t('OptionPool')}
      onClick={handleRowClick}
    />
  );
}
