import { Stack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';


import { OperationRow } from '../types';
import { OperationCard } from './OperationCard';

dayjs.extend(LocalizedFormat);

type Props = {
  operations: OperationRow[];
};

/**
 * OperationCards.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationCards({ operations }: Props): JSX.Element {
  return (
    <Stack gap="3">
      {operations.map((operation) => (
        <OperationCard key={operation.id} operation={operation} />
      ))}
    </Stack>
  );
}

export type OperationTableProps = Props;
