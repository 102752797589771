import { Button, Icon, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useTranslations } from 'use-intl';

import { FormErrors, noop } from '@blockpulse3/data/shared';
import {
  CompanyDocumentType,
  Identity,
  IdentityType,
  useGetIdentityDocumentsQuery,
  useGetIdentityQuery,
} from '@blockpulse3/graphql/hooks';

import { DropzoneInput } from '../DropzoneInput';
import { IdentityDocumentsSelectProps } from './types';
import { useGetCompanyDocuments, useGetKybDocuments, useGetKycDocuments } from './utils';

type Props = {
  identityId: Identity['id'];
  onlyIdentityDocuments?: boolean;
  onUploadCompleted?: () => void;
};

/**
 * AddIdentityDocuments
 * Allow superadmins to add company and individual documents to a subscription
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function AddIdentityDocuments({
  identityId,
  onlyIdentityDocuments,
  onUploadCompleted = noop,
}: Props): JSX.Element | null {
  const t = useTranslations();
  const i18nError = useTranslations('FormErrors');

  const [error, setError] = useState<string | undefined>();
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<
    IdentityDocumentsSelectProps | undefined
  >(undefined);

  const { data: identityData, refetch: refetchIdentity } = useGetIdentityQuery({
    variables: {
      identityId,
    },
  });

  const identity = identityData?.identity;
  const identityType = identityData?.identity.type;

  const companyId = identity?.companyIdentity?.id;
  const signerIndividualIdentity = identity?.companyIdentity?.signer?.individualIdentity;
  const individualIdentity = identity?.individualIdentity;
  const individualIdentityId = individualIdentity?.id;

  const { data: identityDocumentsData, refetch: refetchDocuments } = useGetIdentityDocumentsQuery({
    variables: {
      identityId,
    },
    fetchPolicy: 'no-cache',
  });
  const documents = identityDocumentsData?.getIdentityDocuments || [];

  // Default list of uploadable documents depending of IdentityType
  const [getKybDocuments] = useGetKybDocuments();
  const [getKycDocuments] = useGetKycDocuments();
  const [getCompanyDocuments] = useGetCompanyDocuments();
  const defaultOptions = (): IdentityDocumentsSelectProps[] => {
    if (!identityType) return [];

    if (identityType === IdentityType.COMPANY) {
      if (onlyIdentityDocuments) {
        return getCompanyDocuments();
      }
      return getKybDocuments(signerIndividualIdentity?.name);
    } else {
      return getKycDocuments(individualIdentity?.name);
    }
  };

  // Remove existing documents from the select options
  const filteredOptions = defaultOptions().filter((addableDocument) =>
    documents.every((document) => document.type !== addableDocument.type),
  );

  if (!filteredOptions.length) return null;

  /* ** Method to upload a file using axios ** */
  const handleFileUpload = async (acceptedFiles: File[]): Promise<void> => {
    // For now only handle company documents
    if (!selectedDocument) return;

    const formData = new FormData();
    let endpoint = '';
    if (selectedDocument.type in CompanyDocumentType && companyId) {
      formData.append('companyDocument', acceptedFiles[0]);
      formData.append('companyId', companyId);
      formData.append('documentType', selectedDocument.type);
      endpoint = '/companies/uploadKybDocument';
    } else if (individualIdentityId || signerIndividualIdentity?.id) {
      const docIndividualIdentityId = individualIdentityId || signerIndividualIdentity?.id || '';
      formData.append('document', acceptedFiles[0]);
      formData.append('individualIdentityId', docIndividualIdentityId);
      formData.append('documentType', selectedDocument.type);
      endpoint = '/identities/uploadKycDocument';
    }

    if (companyId || individualIdentityId) {
      setIsFileLoading(true);

      await axios
        .post(process.env['NX_API_CONTROLLER_ENDPOINT'] + endpoint, formData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch(() => {
          setError(i18nError(FormErrors.DropzoneDefault));
          setIsFileLoading(false);
        });
      setSelectedDocument(undefined);
      await refetchDocuments();
      await refetchIdentity();
      setIsFileLoading(false);
      onUploadCompleted();
    }
  };

  /* ** Upload file handler ** */
  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    if (!selectedDocument) return;

    if (fileRejections.length === 0) {
      handleFileUpload(acceptedFiles);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError(i18nError(FormErrors.FileTooLarge));
          break;
        }
        default: {
          setError(i18nError(FormErrors.DropzoneDefault));
          break;
        }
      }
    }
  };

  return (
    <Stack>
      <Menu matchWidth>
        <MenuButton
          as={Button}
          fontWeight={400}
          rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
          textAlign="start"
          variant="secondary"
        >
          {selectedDocument ? selectedDocument.label : t('SelectDocumentType')}
        </MenuButton>
        <MenuList>
          {filteredOptions.map((option, i) => (
            <MenuItem key={i} onClick={(): void => setSelectedDocument(option)}>
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <DropzoneInput
        accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
        files={{}}
        isDisabled={!selectedDocument}
        isLoading={isFileLoading}
        maxFiles={1}
        subTitle={t('ImageUpTo5MB')}
        onDelete={noop}
        onDrop={handleFileDrop}
      />
      {error && (
        <Text color="red" fontSize="xs">
          {error}
        </Text>
      )}
    </Stack>
  );
}
