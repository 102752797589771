import { Request } from 'express';

export const getHostName = (): string => {
  return window.location.host;
};

export const getReqHostName = (req: Request): string => {
  const origin = req.headers.origin;
  return origin?.replace('http://', '').replace('https://', '') || '';
};
