import { tagPalette } from '@blockpulse3/data/shared';

/**
 * getNextColorTag.
 * Get the name of the color for the next tag
 *
 * @param {number} tagCount
 * @returns {string}
 */
export function getNextColorTag(tagCount: number): string {
  return tagPalette[tagCount % tagPalette.length];
}
