import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { ClockIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetOperationQuery, useStartOpportunityMutation } from '@blockpulse3/graphql/hooks';
import { useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

export function OpportunityOperationStartAlert(): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { refetch } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });

  const [startOpportunity, { loading: starting }] = useStartOpportunityMutation();

  const handleStartOpportunityOperation = (): void => {
    if (!operationId) return;

    startOpportunity({
      variables: { operationId },
      onCompleted: () => {
        successToast({ title: t('StartOperationSuccessToast') });
        refetch();
      },
      onError: () => {
        errorToast({ title: t('StartOperationErrorToast') });
      },
    });
  };

  return (
    <Alert boxShadow="md" status="warning">
      <Stack
        alignItems="stretch"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing="4"
        width="full"
      >
        <HStack alignItems="flex-start" spacing="0">
          <AlertIcon as={ClockIcon} />
          <Stack spacing="0">
            <AlertTitle fontWeight="bold">{t('OperationToStart')}</AlertTitle>
            <AlertDescription>{t('OperationToStartDescription')}</AlertDescription>
          </Stack>
        </HStack>
        <HStack alignItems="center" ml={{ base: '0', md: '4' }}>
          <Button
            className="confirm"
            fontSize="sm"
            isDisabled={starting}
            isLoading={starting}
            onClick={handleStartOpportunityOperation}
          >
            {t('StartOperation')}
          </Button>
        </HStack>
      </Stack>
    </Alert>
  );
}
