import * as yup from 'yup';

import {
  FormErrors,
  requiredBooleanField,
  requiredNumberField,
  requiredSelectField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { UboRole } from '@blockpulse3/graphql/hooks';

import { ICompanyUboForm } from './types';

export const schemaUbo = yup.object({
  firstName: requiredStringField,
  lastName: requiredStringField,
  role: requiredStringField,
  birthdate: requiredStringField,
  birthplace: requiredStringField,
  birthCityPostalCode: requiredStringField,
  birthCountry: requiredSelectField,
  sharePercentage: requiredNumberField.when('role', {
    is: UboRole.HasCapital,
    then: (schema) =>
      schema.moreThan(25, FormErrors.UboPercentageFormat).max(100, FormErrors.UboPercentageFormat),
    otherwise: (schema) => schema.min(0),
  }),
  isDirect: requiredBooleanField,
  address: yup.object({
    line: requiredStringField,
    city: requiredStringField,
    postalCode: requiredStringField,
    country: requiredSelectField,
  }),
  nationality: requiredSelectField,
}) as yup.ObjectSchema<ICompanyUboForm>;

export const uboFormDefaultValues: ICompanyUboForm = {
  id: '',
  individualIdentityId: null,
  firstName: '',
  lastName: '',
  birthCityPostalCode: '',
  birthCountry: { label: '', value: '' },
  birthdate: '',
  birthplace: '',
  address: {
    line: '',
    city: '',
    postalCode: '',
    country: { label: '', value: '' },
  },
  isDirect: false,
  role: UboRole.HasCapital,
  sharePercentage: 25.01,
  nationality: { label: '', value: '' },
};
