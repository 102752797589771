import { Route, Routes } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { IdentityContextProvider } from '@blockpulse3/web-client/auth';
import { GraphiQLView } from '@blockpulse3/web-client/graphiql';
import {
  AdminBankTransfer,
  AdminCompanies,
  AdminIndividuals,
  AdminOperationList,
  AdminRegistrations,
  AdminSpaces,
  ManagedSpacesView,
  SpaceAdminView,
  SpaceControlsView,
  SpaceDashboard,
  SpaceManagementsView,
  SpaceMembersView,
} from '@blockpulse3/web-client/spaces';

import { AppLayout } from '../layouts';
import { CompanyWorkspaceRoutes } from './CompanyWorkspaceRoutes';
import { UserWorkspaceRoutes } from './UserWorkspaceRoutes';

/**
 * SpaceRoutes.
 * Group sub route components when it's possible.
 *
 * @returns {JSX.Element}
 */
export function SpaceRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<IdentityContextProvider />}>
        <Route element={<UserWorkspaceRoutes />} path={routes.space.individual.href + '/*'} />
        <Route element={<CompanyWorkspaceRoutes />} path={routes.space.company.href + '/*'} />
        <Route element={<AppLayout />}>
          <Route index element={<SpaceDashboard />} />
          <Route element={<GraphiQLView />} path={routes.space.graphql.href} />
          <Route element={<AdminOperationList />} path={routes.space.operations.href} />
          <Route element={<AdminSpaces />} path={routes.space.spaces.href} />
          <Route element={<ManagedSpacesView />} path={routes.space.managedSpaces.href} />
          <Route element={<SpaceAdminView />} path={routes.space.space.href} />
          <Route element={<AdminCompanies />} path={routes.space.companies.href} />
          <Route element={<AdminRegistrations />} path={routes.space.registrations.href} />
          <Route element={<AdminIndividuals />} path={routes.space.individuals.href} />
          <Route element={<AdminBankTransfer />} path={routes.space.bankTransfers.href} />
          <Route element={<SpaceControlsView />} path={routes.space.controls.href} />
          <Route element={<SpaceManagementsView />} path={routes.space.managements.href} />
          <Route element={<SpaceMembersView />} path={routes.space.members.href} />
        </Route>
      </Route>
    </Routes>
  );
}
