import { IReminderLabels, OperationSubscribersType } from './types';

export const getReminderLabels = (emailType: OperationSubscribersType): IReminderLabels => {
  switch (emailType) {
    case OperationSubscribersType.SUBSCRIBERS:
      return {
        modalTitle: 'RemindSubscribers',
        modalBackupTitle: 'NoSubscriberToRemind',
        toastSuccessLabel: 'MailSentSuccessfullyToSubscriber',
        alertTitle: 'SubscribersAlreadyRemindedRecently',
        alertDescriptions: 'RecentlyRemindedSubscriber',
      };
    case OperationSubscribersType.SELLERS:
      return {
        modalTitle: 'RemindSellers',
        modalBackupTitle: 'NoSellerToRemind',
        toastSuccessLabel: 'MailSentSuccessfullyToSeller',
        alertTitle: 'SellersAlreadyRemindedRecently',
        alertDescriptions: 'RecentlyRemindedSeller',
      };
    case OperationSubscribersType.INTENTS:
      return {
        modalTitle: 'RemindPotentialBuyer',
        modalBackupTitle: 'NoPotentialBuyerToRemind',
        toastSuccessLabel: 'MailSentSuccessfullyToPotentialBuyer',
        alertTitle: 'PotentialBuyersAlreadyRemindedRecently',
        alertDescriptions: 'RecentlyRemindedPotentialBuyer',
      };
    case OperationSubscribersType.BUYERS:
      return {
        modalTitle: 'RemindBuyers',
        modalBackupTitle: 'NoBuyerToRemind',
        toastSuccessLabel: 'MailSentSuccessfullyToBuyer',
        alertTitle: 'BuyersAlreadyRemindedRecently',
        alertDescriptions: 'RecentlyRemindedBuyer',
      };
  }
};
