import { InfoIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  InputGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { CreditCardIcon, LockClosedIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { SingleValue } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useGetBalancesByAssetAndIdentityQuery,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberInt } from '@blockpulse3/helpers';
import {
  CompanyIdentityAvatar,
  ErrorMessage,
  IdentitySelect,
  IdentitySelectOption,
  Input,
  ResponsiveModal,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionHoldingMethod } from '../../../SubscriptionHoldingMethod';
import { saleStepDefaultValues, schema } from './schema';
import { SaleStepForm } from './types';
import {
  getAllowedFiscalAdvantages,
  getAllowedHoldingMethods,
  getAvailableSharesByHoldingMethod,
} from './utils';

type Props = {
  /* ** Default form values ** */
  defaultValues?: SaleStepForm;
  /* ** State of parent queries loading ** */
  isLoading: boolean;
  /* ** Submit handler ** */
  onSubmit: SubmitHandler<SaleStepForm>;
} & Omit<ResponsiveModalProps, 'children'>;

export function SaleModal({
  defaultValues = saleStepDefaultValues,
  onSubmit,
  isLoading,
  onClose,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();

  const { identityId } = useIdentity();
  const [isHoldingMethodLoading, setIsHoldingMethodLoading] = useState(false);

  const { data: subscriptionData } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = subscriptionData?.subscription;
  const operation = subscription?.operation;
  const isPromiseToSell = operation?.isPromiseToSell;
  const assetId = operation?.asset?.id;

  const { data: balancesData, refetch: balancesRefetch } = useGetBalancesByAssetAndIdentityQuery({
    variables: {
      identityId: identityId || '',
      assetId: assetId || '',
    },
    skip: !assetId || !identityId || isPromiseToSell,
  });
  const balances = balancesData?.getBalancesByAssetAndIdentity || [];

  const availableSharesByHoldingMethod = getAvailableSharesByHoldingMethod(
    subscriptionData,
    balances,
  );

  const availableShares = isPromiseToSell
    ? defaultValues.shareCount
    : availableSharesByHoldingMethod;

  // Get allowed holding methods based on balance data
  const allowedHoldingMethods = getAllowedHoldingMethods(balances);
  const allowedFiscalAdvantages = getAllowedFiscalAdvantages(allowedHoldingMethods);

  const sharePrice = operation?.sharePrice || 1;
  const admissionFees = subscription?.admissionFees;
  const asset = operation?.asset;

  const { register, watch, handleSubmit, setValue, formState, reset, clearErrors } =
    useForm<SaleStepForm>({
      defaultValues,
      resolver: yupResolver(schema),
      context: { availableShares },
    });

  const shareCount = watch('shareCount');
  const investAmount = watch('investAmount');

  useEffect(() => {
    const investAmount = shareCount * sharePrice;
    const rounded = Math.round(investAmount * 100) / 100;
    setValue('investAmount', rounded);
  }, [shareCount, sharePrice, setValue]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  const authorizedBuyers = operation?.authorizedBuyers || [];
  const options: IdentitySelectOption[] = authorizedBuyers
    .filter((buyerIdentity) => buyerIdentity.id !== identityId)
    .map((buyerIdentity) => {
      return {
        value: buyerIdentity.id,
        label: buyerIdentity.name,
        identity: buyerIdentity,
      };
    });
  const isSelectDisabled = options.length === 1 && !!defaultValues.buyerIdentity?.value;

  // Format default buyer identity if exist
  const buyerIdentity = watch('buyerIdentity');
  const defaultSelectValue: IdentitySelectOption | undefined = buyerIdentity.identity
    ? { ...buyerIdentity, identity: buyerIdentity.identity }
    : undefined;

  const onBuyerChange = (data: SingleValue<IdentitySelectOption>): void => {
    if (data) {
      setValue('buyerIdentity', { label: data.label, value: data.value });
      clearErrors('buyerIdentity');
    }
  };

  const handleModalClose = (): void => {
    onClose();
    reset({ ...defaultValues });
  };

  const handleInvestAllIn = (): void => {
    setValue('shareCount', availableShares);
  };

  return (
    <ResponsiveModal onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('TermsOfSale')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="sale-step" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="4">
              <Stack>
                <Text fontSize="lg" fontWeight="600">
                  {t('WantToSell')}
                </Text>
                <SubscriptionHoldingMethod
                  allowedFiscalAdvantages={allowedFiscalAdvantages}
                  allowedHoldingMethods={allowedHoldingMethods}
                  isSeller={true}
                  setIsLoading={setIsHoldingMethodLoading}
                  onChange={balancesRefetch}
                />
              </Stack>
              <Stack>
                <Text fontSize="lg" fontWeight="600">
                  {t('SharesCountOf')}
                </Text>
                <HStack
                  backgroundColor="gray.50"
                  border="2px"
                  borderColor="gray.100"
                  borderRadius="lg"
                  spacing="0"
                >
                  <Stack
                    borderRightColor="gray.100"
                    borderRightWidth="2px"
                    p="4"
                    spacing="0"
                    w="50%"
                  >
                    <FormControl isInvalid={!!formState.errors.shareCount}>
                      <FormLabel color={isPromiseToSell ? 'gray.500' : undefined} my="0">
                        {t('SharesCount')}
                      </FormLabel>
                      <HStack justify="space-between" mb="1">
                        <HStack>
                          <Icon as={CreditCardIcon} color="gray.500" />
                          {isHoldingMethodLoading ? (
                            <Spinner color="gray.500" size="xs" speed="0.7s" />
                          ) : (
                            <Text color="gray.500" fontSize="xs">
                              {t('AvailableAssets', {
                                availableShares: formatNumberInt(availableShares),
                              })}
                            </Text>
                          )}
                        </HStack>
                        <Button
                          border="1px"
                          borderColor="gray.300"
                          borderRadius="2xl"
                          color="gray.500"
                          fontSize="xs"
                          height="auto"
                          size="xs"
                          variant="ghost"
                          onClick={handleInvestAllIn}
                        >
                          {t('Max')}
                        </Button>
                      </HStack>
                      <InputGroup>
                        <Input
                          disabled={isPromiseToSell}
                          id="shareCount"
                          step="1"
                          type="number"
                          {...register('shareCount')}
                        />
                      </InputGroup>
                    </FormControl>
                  </Stack>
                  <HStack m="auto">
                    <CompanyIdentityAvatar src={asset?.company?.identity?.profilePicture} />
                    <Stack spacing="0">
                      <Text fontWeight="600">{asset?.company.name}</Text>
                      <Text fontWeight="400">{asset?.name}</Text>
                    </Stack>
                  </HStack>
                </HStack>
                <ErrorMessage error={formState.errors.shareCount} />
              </Stack>
              <Stack>
                <HStack>
                  <Text fontSize="lg" fontWeight="600">
                    {t('AtUnitPriceOf')}
                  </Text>
                  <Tooltip hasArrow label={t('RestrictedSalePriceChange')} placement="top">
                    <Icon as={InfoIcon} color="gray.500" />
                  </Tooltip>
                </HStack>
                <HStack
                  backgroundColor="gray.50"
                  border="2px"
                  borderColor="gray.100"
                  borderRadius="lg"
                  spacing="0"
                >
                  <Stack
                    borderRightColor="gray.100"
                    borderRightWidth="2px"
                    p="4"
                    spacing="0"
                    w="50%"
                  >
                    <Text color="gray.500" fontWeight="500">
                      {t('PricePerShare')}
                    </Text>
                    <Text fontSize="large" fontWeight="700">
                      {formatNumberCurrency(sharePrice)}
                    </Text>
                  </Stack>
                  <Stack p="4" spacing="0" w="50%">
                    <Text color="gray.500" fontWeight="500">
                      {t('TotalAmount')}
                    </Text>
                    <Text fontSize="large" fontWeight="700">
                      {formatNumberCurrency(investAmount)}
                    </Text>
                  </Stack>
                </HStack>
              </Stack>
              <Stack>
                <HStack>
                  <Text fontSize="lg" fontWeight="600">
                    {t('To')}
                  </Text>
                  <Tooltip hasArrow label={t('RestrictedBuyerChange')} placement="top">
                    <Icon as={InfoIcon} color="gray.500" />
                  </Tooltip>
                </HStack>
                <Stack
                  backgroundColor="gray.50"
                  border="2px"
                  borderColor="gray.100"
                  borderRadius="lg"
                  p="4"
                >
                  <FormControl isInvalid={!!formState.errors.buyerIdentity}>
                    <FormLabel color={isSelectDisabled ? 'gray.500' : undefined}>
                      <HStack>
                        {isSelectDisabled && <Icon as={LockClosedIcon} boxSize="15px" />}
                        <Text>{t('Acquirer', { nb: 1 })}</Text>
                      </HStack>
                    </FormLabel>
                    <IdentitySelect
                      defaultValue={defaultSelectValue}
                      isDisabled={isSelectDisabled}
                      options={options}
                      placeholder={t('ChoosePurchaser')}
                      onChange={onBuyerChange}
                    />
                  </FormControl>
                </Stack>
                <ErrorMessage error={formState.errors.buyerIdentity?.value} />
              </Stack>
              {admissionFees && (
                <Text fontSize="lg" fontWeight="600">
                  {t('RegistrationFees', {
                    n: formatNumberCurrency(admissionFees),
                  })}
                </Text>
              )}
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            form="sale-step"
            isDisabled={isHoldingMethodLoading}
            isLoading={isLoading}
            type="submit"
            width="full"
          >
            {t('Validate')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
