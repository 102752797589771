import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Progress,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CashIcon, ChartPieIcon, TrendingUpIcon, UserGroupIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FundraisingWorkflowType, useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberPercentage } from '@blockpulse3/helpers';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

type Props = unknown;

/**
 * FundraisingInformations.
 * Information section, such as percentage, amount... of the Fundraising.
 *
 * @returns {JSX.Element}
 */
export function FundraisingInformations(): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const { data, loading, error } = useGetOperationQuery({
    variables: {
      operationId,
    },
  });

  if (loading || !operationId) {
    return (
      <Skeleton>
        <Card h="260px" />
      </Skeleton>
    );
  }

  if (error || !data?.operation.fundraising) {
    return <ErrorQueryCard h="260px" />;
  }

  const { fundraising, ...operation } = data.operation;

  if (operation.data?.__typename !== 'FundraisingData') {
    return <ErrorQueryCard h="260px" />;
  }

  const isAllInSubscriptionWorkflow = fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;

  const {
    objective,
    totalDeclaredAmount,
    committedAmount,
    totalApprovedAmount,
    totalCollectedFees,
  } = operation.data;

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '8' }}>
      <Card w="full">
        <CardHeader>
          <HStack justifyContent="space-between" spacing="4">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('OngoingSubscriptionAmounts')}
            </Heading>
            <Icon as={TrendingUpIcon} boxSize="5" color="gray.500" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberCurrency(committedAmount || 0)}
          </Text>
          {isAllInSubscriptionWorkflow && totalDeclaredAmount && (
            <Text color="gray.600" fontSize="sm">
              <Text as="span" fontWeight="500">
                {t('Provided')}
              </Text>{' '}
              {formatNumberCurrency(totalDeclaredAmount)}
            </Text>
          )}
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader>
          <HStack justifyContent="space-between" spacing="4">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('GoalAchievedAt')}
            </Heading>
            <Icon as={ChartPieIcon} boxSize="5" color="gray.500" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Stack>
            <Text color="gray.800" fontSize="lg" fontWeight="700">
              {formatNumberPercentage(objective || 0)}
            </Text>
            <Progress h="2" rounded="md" value={objective || 0} />
          </Stack>
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader>
          <HStack justifyContent="space-between" spacing="4">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {isAllInSubscriptionWorkflow ? t('AmountValidated') : t('CollectedAmount')}
            </Heading>
            <Icon as={CashIcon} boxSize="5" color="gray.500" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberCurrency(totalApprovedAmount + totalCollectedFees)}
          </Text>
          {!!totalCollectedFees && (
            <Text color="gray.600" fontSize="sm">
              {t('IncludingFees', { fees: formatNumberCurrency(totalCollectedFees) })}
            </Text>
          )}
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader>
          <HStack justifyContent="space-between" spacing="4">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('InvitedSubscriber', { nb: operation.subscriptionCount })}
            </Heading>
            <Icon as={UserGroupIcon} boxSize="5" color="gray.500" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {operation.subscriptionCount}
          </Text>
        </CardBody>
      </Card>
    </Stack>
  );
}

export type FundraisingInformationsProps = Props;
