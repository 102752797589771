import {
  GetSubscriptionInfosFragment,
  SubscriptionInfosFragment,
  SubscriptionShortInfosFragment,
  SubscriptionStepType,
} from '@blockpulse3/graphql/hooks';

import { SubscriptionStepStatus } from './enums';

/**
 * isSubscriptionStarted.
 * Lookout for first subscription step status to determine if subscription is started
 * Omit KYC step as it could be completed in other subscriptions
 *
 * @param {SubscriptionInfosFragment | SubscriptionShortInfosFragment | GetSubscriptionInfosFragment | null | undefined} subscription
 * @returns {boolean}
 */
export function isSubscriptionStarted(
  subscription:
    | SubscriptionInfosFragment
    | SubscriptionShortInfosFragment
    | GetSubscriptionInfosFragment
    | null
    | undefined,
): boolean {
  if (!subscription?.data?.mergedSteps) return false;

  const mergedSteps = subscription.data.mergedSteps;
  const firstStep = mergedSteps.find(
    (step) => step.type !== SubscriptionStepType.IDENTITY_VERIFICATION,
  );

  return firstStep?.status !== SubscriptionStepStatus.NONE;
}
