import { Button, HStack, Icon, Text } from '@chakra-ui/react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import React from 'react';

import { noop } from '@blockpulse3/data/shared';

type Props = {
  /* ** Is link active ** */
  isActive?: boolean;
  /* ** Document title ** */
  fileName: string;
  /* ** ie. Badge ** */
  children?: React.ReactNode;
  /* ** Button onClick ** */
  onClick: () => void;
  isLoading?: boolean;
  loadingText?: string;
};

export function DocumentLink({
  isActive = true,
  fileName,
  children = null,
  onClick = noop,
  isLoading = false,
  loadingText,
}: Props): JSX.Element {
  return (
    <HStack>
      <HStack>
        <Icon
          as={DocumentTextIcon}
          backgroundColor="gray.50"
          borderRadius="100px"
          boxSize="30px"
          color="gray.500"
          p="4px"
        />
        {isActive ? (
          <Button
            color="gray.900"
            fontWeight="400"
            isLoading={isLoading}
            loadingText={loadingText}
            size="sm"
            spinnerPlacement="end"
            textAlign="left"
            variant="link"
            whiteSpace="normal"
            onClick={onClick}
          >
            <Text fontSize="sm" textDecoration="underline">
              {fileName}
            </Text>
          </Button>
        ) : (
          <Text fontSize="sm" textDecoration="underline">
            {fileName}
          </Text>
        )}
      </HStack>
      {children}
    </HStack>
  );
}
