import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import { Operation, OperationDocumentType } from '@blockpulse3/graphql/hooks';
import {
  DropzoneInput,
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { uploadSubscriptionTemplateDocumentSchema } from './schema';
import { IUploadSubscriptionTemplateForm } from './type';

type Props = {
  onClose: () => void;
  operationId: Operation['id'];
} & Omit<ResponsiveModalProps, 'children'>;

export function UploadSubscriptionTemplateModal({
  onClose,
  operationId,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [subscriptionTemplateFile, setSubscriptionTemplateFile] = useState<File>();
  const [documentFileName, setDocumentFileName] = useState<{ [key: string]: File }>({});

  const successToast = useSuccessToast();

  const { control, handleSubmit, formState, setError, setValue } =
    useForm<IUploadSubscriptionTemplateForm>({
      defaultValues: { subscriptionTemplateFile: '' },
      resolver: yupResolver<IUploadSubscriptionTemplateForm>(
        uploadSubscriptionTemplateDocumentSchema,
      ),
    });

  const handleFileUpload = async (): Promise<void> => {
    if (!subscriptionTemplateFile) return;

    const formData = new FormData();
    formData.append('operationId', operationId);
    formData.append('subscriptionTemplate', subscriptionTemplateFile);
    formData.append('documentType', OperationDocumentType.SUBSCRIPTION);

    setIsLoading(true);
    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/importSubscriptionTemplate',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(() => {
        successToast({ title: t('SuccessfulFileUpload') });
      })
      .catch(() => {
        setError('subscriptionTemplateFile', {
          type: 'custom',
          message: FormErrors.DropzoneDefault,
        });
        setIsLoading(false);
      });
    setIsLoading(false);
    onClose();
  };

  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    if (fileRejections.length === 0) {
      setValue('subscriptionTemplateFile', acceptedFiles[0].name);
      setDocumentFileName({
        1: new File([acceptedFiles[0].name], acceptedFiles[0].name),
      });
      setSubscriptionTemplateFile(acceptedFiles[0]);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('subscriptionTemplateFile', {
            type: 'custom',
            message: FormErrors.FileTooLarge,
          });
          break;
        }
        default: {
          setError('subscriptionTemplateFile', {
            type: 'custom',
            message: FormErrors.DropzoneDefault,
          });
          break;
        }
      }
    }
  };

  const handleFileDelete = (): void => {
    setValue('subscriptionTemplateFile', '');
    setDocumentFileName({});
    setSubscriptionTemplateFile(undefined);
  };

  const handleFormSubmit: SubmitHandler<IUploadSubscriptionTemplateForm> = (): void => {
    handleFileUpload();
  };

  return (
    <ResponsiveModal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('ImportSubscriptionTemplate')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="uploadSPVDocument" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <Controller
                control={control}
                name="subscriptionTemplateFile"
                render={(): JSX.Element => (
                  <FormControl>
                    <FormLabel>{t('SelectDocument')}</FormLabel>
                    <DropzoneInput
                      files={documentFileName}
                      isDisabled={isLoading}
                      isLoading={isLoading}
                      maxFiles={1}
                      subTitle=".DOCX"
                      accept={{
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                          [],
                      }}
                      onDelete={handleFileDelete}
                      onDrop={handleFileDrop}
                    />
                    <ErrorMessage error={formState.errors?.subscriptionTemplateFile} />
                  </FormControl>
                )}
              />
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button
            form="uploadSPVDocument"
            isDisabled={isLoading}
            isLoading={isLoading}
            type="submit"
          >
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
