import { FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SingleValue } from 'chakra-react-select';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { IdentityShortInfosFragment } from '@blockpulse3/graphql/hooks';
import { ErrorMessage, IdentitySelect, IdentitySelectOption } from '@blockpulse3/ui/commons';

import { operationSellerDefaultValues, schema } from './schema';
import { INewSecondaryOperationSeller } from './types';

type Props = {
  /* ** Array of shareholders that are not already defined as authorized sellers ** */
  addableSellers: IdentityShortInfosFragment[];
  /* ** Submit handler ** */
  onSubmit: SubmitHandler<INewSecondaryOperationSeller>;
  /* ** Default form values ** */
  defaultValues?: INewSecondaryOperationSeller;
};

export function NewSecondaryOperationSellerForm({
  addableSellers,
  defaultValues = operationSellerDefaultValues,
  onSubmit = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const { formState, setValue, clearErrors, handleSubmit } = useForm<INewSecondaryOperationSeller>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const options: IdentitySelectOption[] = addableSellers.map((sellerIdentity) => {
    return {
      value: sellerIdentity.id,
      label: sellerIdentity.name,
      identity: sellerIdentity,
    };
  });

  const onSellerChange = (data: SingleValue<IdentitySelectOption>): void => {
    if (data) {
      setValue('sellerIdentity', { label: data.label, value: data.value });
      clearErrors('sellerIdentity');
    }
  };

  return (
    <form id="add-seller" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="4">
        <FormControl isInvalid={!!formState.errors.sellerIdentity}>
          <FormLabel>{t('Seller', { nb: 1 })}</FormLabel>
          <IdentitySelect
            defaultValue={undefined}
            options={options}
            placeholder={t('ChooseShareholder')}
            onChange={onSellerChange}
          />
        </FormControl>
      </Stack>
      <ErrorMessage error={formState.errors.sellerIdentity?.value} />
    </form>
  );
}

export type NewSecondaryOperationSellerModalProps = Props;
