import { Flex, Show, Stack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

type Props = unknown;

/**
 * HeroLayout.
 * Layout rendering an outlet with an hero component next to it.
 *
 * @returns {JSX.Element}
 */
export function HeroLayout(): JSX.Element {
  return (
    <Stack direction={{ base: 'column', md: 'row' }} minH="100vh">
      <Flex align="center" flex="1" justify="center" p="8">
        <Stack maxW="lg" spacing="6" w="full">
          <Outlet />
        </Stack>
      </Flex>
      <Show above="md">
        <Flex background="primary" flex="1" />
      </Show>
    </Stack>
  );
}

export type HeroLayoutProps = Props;
