import dayjs from 'dayjs';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

import { DisposableDomains, FormErrors } from '../';

/* ** String schema ** */
export const requiredStringField = yup
  .string()
  .trim()
  .required(FormErrors.RequiredField)
  .matches(/^[!-.?-Za-zÀ-ÖØ-öø-ÿ0-9+_\s]+$/, FormErrors.UnauthorizedChar);

/* ** Password schema ** */
export const requiredPasswordField = yup
  .string()
  .trim()
  .required(FormErrors.RequiredField)
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%^&*])(?=.{8,})/,
    FormErrors.PasswordStrength,
  );

/* ** Url schema ** */
export const requiredUrlField = yup
  .string()
  .trim()
  .required(FormErrors.RequiredField)
  .url(FormErrors.InvalidUrl);

/* ** UUID schema, TODO: uuid() is breaking tests, use simple string() for now ** */
export const requiredUuidField = yup.string().required();

/* ** Number format general (floats) ** */
export const numberField = yup.number();
export const requiredNumberField = yup
  .number()
  .typeError(FormErrors.RequiredField)
  .moreThan(0, FormErrors.PositiveFormat)
  .required(FormErrors.RequiredField);

export const optionalNumberField = yup
  .number()
  .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
  .min(0, FormErrors.NonNegativeNumber);

/* ** Number format integer special ** */
export const requiredIntegerNumberField = yup
  .number()
  .integer(FormErrors.IntegerFormat)
  .typeError(FormErrors.RequiredField)
  .moreThan(0, FormErrors.PositiveFormat)
  .required(FormErrors.RequiredField);

/* ** Boolean schema, checkbox,radio ** */
export const requiredBooleanField = yup.boolean().required(FormErrors.RequiredField);

/* ** Email format ** */
export const requiredEmailField = yup
  .string()
  .email(FormErrors.EmailFormat)
  .trim()
  .required(FormErrors.RequiredField)
  .test('email', FormErrors.EmailDomain, (value) => {
    const domain = (value || '').split('@')[1];
    if (domain) {
      return !DisposableDomains.has(domain.toLowerCase().trim());
    }
    return false;
  });

/* ** Date format, ends today ** */
export const dateField = yup.string().test('date', FormErrors.PastDate, (date) => {
  const fmtDate = dayjs(date);
  return fmtDate.isValid();
});
export const requireDateField = yup
  .string()
  .test('date', FormErrors.PastDate, (date) => {
    const fmtDate = dayjs(date);
    return fmtDate.isValid();
  })
  .required(FormErrors.RequiredField);
export const requiredPastDateField = yup
  .string()
  .test('date', FormErrors.PastDate, (date) => {
    const fmtDate = dayjs(date);
    return fmtDate.isBefore(dayjs());
  })
  .required(FormErrors.RequiredField);
export const requiredFutureDateField = yup
  .string()
  .test('date', FormErrors.FutureDate, (date) => {
    const fmtDate = dayjs(date);
    return fmtDate.isAfter(dayjs());
  })
  .required(FormErrors.RequiredField);
export const requiredTodayOrFutureDateField = yup
  .string()
  .test('date', FormErrors.TodayOrFutureDate, (date) => {
    const fmtDate = dayjs(date);
    return fmtDate.isAfter(dayjs().startOf('day').subtract(1, 'second'));
  })
  .required(FormErrors.RequiredField);

/* ** Birthdate format ** */
export const requiredBirthdateField = yup
  .string()
  .test('birthdate', FormErrors.ValidDateFormat, (date) => {
    const fmtDate = dayjs(date);
    return fmtDate.isBefore(dayjs()) && fmtDate.isAfter(dayjs('1900-01-01'));
  })
  .required(FormErrors.RequiredField);

/* ** Select object field ** */
export const requiredSelectField = yup
  .object({
    label: requiredStringField,
    value: requiredStringField,
  })
  .required(FormErrors.RequiredField);

/* ** Telephone field ** */
export const phoneField = yup
  .string()
  .nullable()
  .test({
    name: 'phone',
    test: (value) => (value ? isValidPhoneNumber(value) : true),
    message: FormErrors.PhoneFormat,
  });

/* ** File input field ** */
export const requiredFileField = yup.mixed().required(FormErrors.RequiredField);

/* ** Percentage format between (min) and 100% ** */
export const requiredPercentageNumberField = (min = 1): yup.NumberSchema<number | undefined> =>
  yup
    .number()
    .typeError(FormErrors.RequiredField)
    .positive(FormErrors.PositiveFormat)
    .min(min, FormErrors.PercentageFormat)
    .max(100, FormErrors.PercentageFormat)
    .required(FormErrors.RequiredField);
