import { AssetEntityInfosFragment } from '@blockpulse3/graphql/hooks';

import { IAssetRow } from './types';

/**
 * getStockAssetRows.
 *
 * @param {AssetEntityInfosFragment[]} data
 * @returns {IAssetRow[]}
 */
export function getStockAssetRows(data?: AssetEntityInfosFragment[]): IAssetRow[] {
  if (!data) {
    return [];
  }

  return data.map((value) => ({
    id: value.id,
    name: value.name,
    nominalValue: value.nominalValue || 0,
    data: value,
  }));
}

/**
 * getOptionAssetRows.
 *
 * @param {AssetEntityInfosFragment[]} data
 * @returns {IAssetRow[]}
 */
export function getOptionAssetRows(data?: AssetEntityInfosFragment[]): IAssetRow[] {
  if (!data) {
    return [];
  }

  return data.map((value) => ({
    id: value.id,
    name: value.name,
    nominalValue: value.data?.__typename === 'OptionAssetData' ? value.data.exercisePrice : 0,
    data: value,
  }));
}

/**
 * getBondAssetRows.
 *
 * @param {AssetEntityInfosFragment[]} data
 * @returns {IAssetRow[]}
 */
export function getBondAssetRows(data?: AssetEntityInfosFragment[]): IAssetRow[] {
  if (!data) {
    return [];
  }

  return data.map((value) => ({
    id: value.id,
    name: value.name,
    nominalValue: value.nominalValue || 0,
    data: value,
  }));
}
