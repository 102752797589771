import { Badge, BadgeProps, ColorProps, Text } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { OperationType, SubscriptionSide } from '@blockpulse3/graphql/hooks';

type Props = {
  /* ** Type of the operation ** */
  operationType: OperationType;
  /* ** Side of a subscription ** */
  subscriptionSide: SubscriptionSide;
} & BadgeProps;

const BADGE_COLOR_MAP: Record<
  OperationType,
  Record<SubscriptionSide, { bgColor: ColorProps['color']; color: ColorProps['color'] }>
> = {
  [OperationType.UNKNOWN]: {
    [SubscriptionSide.BUYER]: {
      bgColor: 'gray.100',
      color: 'gray.800',
    },
    [SubscriptionSide.SELLER]: {
      bgColor: 'gray.100',
      color: 'gray.800',
    },
  },
  [OperationType.FUNDRAISING]: {
    [SubscriptionSide.BUYER]: {
      bgColor: 'purple.100',
      color: 'purple.800',
    },
    [SubscriptionSide.SELLER]: {
      bgColor: 'red.100',
      color: 'red.800',
    },
  },
  [OperationType.OPTION_POOL]: {
    [SubscriptionSide.BUYER]: {
      bgColor: 'gray.100',
      color: 'gray.800',
    },
    [SubscriptionSide.SELLER]: {
      bgColor: 'gray.100',
      color: 'gray.800',
    },
  },
  [OperationType.SECONDARY]: {
    [SubscriptionSide.SELLER]: {
      bgColor: 'orange.100',
      color: 'orange.800',
    },
    [SubscriptionSide.BUYER]: {
      bgColor: 'cyan.100',
      color: 'cyan.800',
    },
  },
  [OperationType.OPPORTUNITY]: {
    [SubscriptionSide.SELLER]: {
      bgColor: 'green.100',
      color: 'green.800',
    },
    [SubscriptionSide.BUYER]: {
      bgColor: 'green.100',
      color: 'green.800',
    },
  },
};

/**
 * SubscriptionTypeBadge.
 * Simple component, rendering a badge of desired subscription type.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SubscriptionTypeBadge({
  operationType,
  subscriptionSide,
  ...props
}: Props): JSX.Element {
  const i18nSubscriptionType = useTranslations('SubscriptionTypeValues');

  return (
    <Badge
      backgroundColor={BADGE_COLOR_MAP[operationType][subscriptionSide].bgColor}
      color={BADGE_COLOR_MAP[operationType][subscriptionSide].color}
      {...props}
    >
      <Text>{i18nSubscriptionType(`${operationType}_${subscriptionSide}`)}</Text>
    </Badge>
  );
}

export type SubscriptionTypeBadgeProps = Props;
