import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Icon,
  Link,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DocumentTextIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import {
  CompanyDocumentType,
  CompanyShortInfosFragment,
  SubscriptionSide,
  useDeleteKybDocumentMutation,
  useGetCompanyDocumentsQuery,
  useGetCompanyQuery,
  useGetDocumentPdfUrlLazyQuery,
  useGetSubscriptionQuery,
  useValidateKybDocumentsMutation,
} from '@blockpulse3/graphql/hooks';
import {
  CONTENT_LOADING,
  DropzoneInput,
  ErrorMessage,
  ErrorQueryModal,
  PreviewDocumentModal,
  ResponsiveModalFooter,
} from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { useVerificationStepContext } from '../../providers';
import { kybSchema } from './schema';
import { IVerificationCompanyForm } from './types';

type Props = {
  onSubmit: () => void;
};

export function CompanyVerificationStep({ onSubmit }: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const previewModal = useDisclosure();
  const [documentPdfUrl, setDocumentPdfUrl] = useState<string | symbol | null>(CONTENT_LOADING);
  const [previewTitle, setPreviewTitle] = useState<string | null>();
  const [isKbisLoading, setIsKbisLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);

  let company: CompanyShortInfosFragment | null = null;
  const { subscriptionId = '', companyId = '' } = useParams();
  const { identityId } = useIdentity();

  const subscriptionReq = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
    notifyOnNetworkStatusChange: true,
  });

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const [validateKybDocuments] = useValidateKybDocumentsMutation();
  const { activeSteps, stepIndex } = useVerificationStepContext();

  if (subscriptionReq.data) {
    const subscription = subscriptionReq.data.subscription;
    const subscriptionIdentity =
      subscription.side === SubscriptionSide.SELLER
        ? subscription.sellerIdentity
        : subscription.buyerIdentity;
    company = subscriptionIdentity?.companyIdentity as CompanyShortInfosFragment;
  } else if (companyReq.data) {
    company = companyReq.data.company as CompanyShortInfosFragment;
  }

  const registrationNumber = company?.registrationNumber;

  /* ** Fetch company documents from the subscription company ** */
  const companyDocumentsReq = useGetCompanyDocumentsQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();
  /* ** Delete document mutation ** */
  const [deleteDocument] = useDeleteKybDocumentMutation();

  /* ** Pappers redirection link ** */
  const pappersLink = `https://www.pappers.fr/entreprise/${registrationNumber}`;
  /* ** Linked subscription company documents to filter ** */
  const companyDocuments = companyDocumentsReq.data?.getCompanyDocuments || [];
  const kbisDocument = companyDocuments.find(
    (doc) => doc.document.type === CompanyDocumentType.REGISTER_EXTRACT,
  );
  const statusDocument = companyDocuments.find(
    (doc) => doc.document.type === CompanyDocumentType.STATUTS,
  );

  /* ** Documents file names for the `<DropzoneInput />` ** */
  const kbisFileName = kbisDocument
    ? {
        [kbisDocument.document.id]: new File(
          [kbisDocument.document.title],
          kbisDocument.document.title,
        ),
      }
    : {};
  const statusFileName = statusDocument
    ? {
        [statusDocument.document.id]: new File(
          [statusDocument.document.title],
          statusDocument.document.title,
        ),
      }
    : {};

  /* ** Form ** */
  const { control, handleSubmit, formState, setError, setValue, clearErrors } =
    useForm<IVerificationCompanyForm>({
      defaultValues: { kbisFile: '', statusFile: '' },
      resolver: yupResolver(kybSchema),
    });

  useEffect(() => {
    /* ** Set document in form on file change ** */
    if (kbisDocument) {
      setValue('kbisFile', kbisDocument?.document.id || '');
    }
    if (statusDocument) {
      setValue('statusFile', statusDocument?.document.id || '');
    }
  }, [kbisDocument, statusDocument, setValue]);

  if (companyDocumentsReq.error) {
    return <ErrorQueryModal h="380px" />;
  }

  /* ** On status file upload ** */
  const handleStatusFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    clearErrors('statusFile');
    if (fileRejections.length === 0) {
      setIsStatusLoading(true);
      handleFileUpload(acceptedFiles, CompanyDocumentType.STATUTS);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('statusFile', { type: 'custom', message: FormErrors.FileTooLarge });
          break;
        }
        default: {
          setError('statusFile', { type: 'custom', message: FormErrors.DropzoneDefault });
          break;
        }
      }
    }
  };

  /* ** On KBIS file upload ** */
  const handleKbisFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    clearErrors('kbisFile');
    if (fileRejections.length === 0) {
      setIsKbisLoading(true);
      handleFileUpload(acceptedFiles, CompanyDocumentType.REGISTER_EXTRACT);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('kbisFile', { type: 'custom', message: FormErrors.FileTooLarge });
          break;
        }
        default: {
          setError('kbisFile', { type: 'custom', message: FormErrors.DropzoneDefault });
          break;
        }
      }
    }
  };

  /* ** Method to upload a file using axios ** */
  const handleFileUpload = async (
    acceptedFiles: File[],
    documentType: CompanyDocumentType,
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('document', acceptedFiles[0]);
    formData.append('companyId', companyId);
    formData.append('documentType', documentType);
    await axios.post(
      process.env['NX_API_CONTROLLER_ENDPOINT'] + '/companies/uploadKybDocument',
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    await companyDocumentsReq.refetch();
    setIsKbisLoading(false);
    setIsStatusLoading(false);
  };

  /* ** Delete file handlers ** */
  const handleStatusFileDelete = (): void => {
    setValue('statusFile', '');
    handleFileDelete(CompanyDocumentType.STATUTS);
  };
  const handleKbisFileDelete = (): void => {
    setValue('kbisFile', '');
    handleFileDelete(CompanyDocumentType.REGISTER_EXTRACT);
  };
  const handleFileDelete = (documentType: CompanyDocumentType): void => {
    deleteDocument({
      variables: {
        deleteKybDocumentInput: {
          companyId,
          documentType,
        },
      },
      onCompleted: () => {
        companyDocumentsReq.refetch();
      },
    });
  };

  const handleFilePreview = (
    documentType: CompanyDocumentType.REGISTER_EXTRACT | CompanyDocumentType.STATUTS,
  ): void => {
    if (!companyId) return;
    setDocumentPdfUrl(CONTENT_LOADING);
    previewModal.onOpen();

    let documentData;
    if (documentType === CompanyDocumentType.REGISTER_EXTRACT) {
      setPreviewTitle(i18nDocumentTitle('REGISTER_EXTRACTOrPappers'));
      documentData = kbisDocument?.document;
    } else {
      setPreviewTitle(i18nDocumentTitle('STATUTS'));
      documentData = statusDocument?.document;
    }
    if (documentData) {
      getDocumentPdfUrl({
        variables: {
          documentId: documentData.id,
        },
        fetchPolicy: 'no-cache',
        onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
          setDocumentPdfUrl(pdfUrl);
        },
      });
    }
  };

  const handleFormSubmit = async (): Promise<void> => {
    await validateKybDocuments({ variables: { companyId } });
    if (subscriptionId) {
      await subscriptionReq.refetch();
    }
    onSubmit();
  };

  return (
    <ModalContent>
      <ModalHeader>
        <Text>{t('VerifyMyIdentity')}</Text>
        <Text color="secondary" fontSize="sm">
          {t('StepInfo', {
            stepCount: stepIndex + 1,
            totalStepCount: activeSteps,
          })}
          {t('CompanyDocuments')}
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing="2">
          <Text fontWeight="500">
            {t.rich('VerifyCompanyIdentityDownloadDocs', {
              link: (chunks) => (
                <Link isExternal color="indigo.600" href={pappersLink}>
                  {chunks}
                  <Icon as={ExternalLinkIcon} mx="3px" verticalAlign="text-top" />
                </Link>
              ),
            })}
          </Text>
          <form id="document-upload" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <Controller
                control={control}
                name="statusFile"
                render={(): JSX.Element => (
                  <FormControl>
                    <FormLabel>{i18nDocumentTitle('STATUTS')}</FormLabel>
                    <DropzoneInput
                      isPreviewEnabled
                      accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
                      files={statusFileName}
                      isDisabled={companyDocumentsReq.loading}
                      isLoading={isStatusLoading}
                      maxFiles={1}
                      subTitle={t('ImageUpTo5MB')}
                      icon={
                        <Icon
                          as={DocumentTextIcon}
                          boxSize="42px"
                          color="gray.400"
                          strokeWidth="1px"
                        />
                      }
                      onDelete={handleStatusFileDelete}
                      onDrop={handleStatusFileDrop}
                      onPreview={(): void => handleFilePreview(CompanyDocumentType.STATUTS)}
                    />
                    <ErrorMessage error={formState.errors?.statusFile} />
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="kbisFile"
                render={(): JSX.Element => (
                  <FormControl>
                    <FormLabel>{i18nDocumentTitle('REGISTER_EXTRACTOrPappers3Months')}</FormLabel>
                    <DropzoneInput
                      isPreviewEnabled
                      accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
                      files={kbisFileName}
                      isDisabled={companyDocumentsReq.loading}
                      isLoading={isKbisLoading}
                      maxFiles={1}
                      subTitle={t('ImageUpTo5MB')}
                      icon={
                        <Icon
                          as={DocumentTextIcon}
                          boxSize="42px"
                          color="gray.400"
                          strokeWidth="1px"
                        />
                      }
                      onDelete={handleKbisFileDelete}
                      onDrop={handleKbisFileDrop}
                      onPreview={(): void =>
                        handleFilePreview(CompanyDocumentType.REGISTER_EXTRACT)
                      }
                    />
                    <ErrorMessage error={formState.errors?.kbisFile} />
                  </FormControl>
                )}
              />
            </Stack>
          </form>
        </Stack>
      </ModalBody>
      <Divider />
      <ResponsiveModalFooter>
        <Button form="document-upload" isLoading={subscriptionReq.loading} type="submit">
          {t('Next')}
        </Button>
      </ResponsiveModalFooter>
      <PreviewDocumentModal
        isOpen={previewModal.isOpen}
        src={documentPdfUrl}
        title={previewTitle || ''}
        onClose={previewModal.onClose}
      />
    </ModalContent>
  );
}

export type CompanyVerificationStepProps = Props;
