import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Icon,
  Skeleton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { CheckCircleIcon, ExclamationCircleIcon, PlusIcon } from '@heroicons/react/outline';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  RepresentativeRole,
  useCreateIndividualRepresentativeMutation,
  useDeleteRepresentativeMutation,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, useSuccessToast } from '@blockpulse3/ui/commons';

import { useStepFormContext } from '../../../provider';
import { useGetOnboardingExistingCompany } from '../../hooks';
import { INaturalCompanyAccessForm } from './CompanyAccessForm';
import { CompanyAccessForm } from './CompanyAccessForm/CompanyAccessForm';
import { naturalAccessFormDefaults } from './CompanyAccessForm/schema';

type Props = {
  /* ** Is editing mode ** */
  isEditing?: boolean;
  /* ** Step cancelled callback ** */
  onCancel?: () => void;
  /* ** Step submitted callback ** */
  onSubmit?: () => void;
};

/**
 * CompanyAccess.
 * Step that renders the form for the other roles of the company.
 * It is splitted on purpose, to keep the form agnostic of useLegalIdentityForm().
 *
 * @returns {JSX.Element}
 */
export function CompanyAccess({
  isEditing = false,
  onCancel = noop,
  onSubmit = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const successToast = useSuccessToast();

  const { companyId = '' } = useParams();

  const { data, loading, error, refetch } = useGetOnboardingExistingCompany(isEditing);

  const [createAccess] = useCreateIndividualRepresentativeMutation();
  const [deleteAccess] = useDeleteRepresentativeMutation();

  const { isOpen, onToggle } = useDisclosure();

  /* ** Go to next step, do not validate new form ** */
  const { setCancelHandler, setSubmitHandler } = useStepFormContext();
  const handleStepSubmit = useCallback((): void => {
    onSubmit();
  }, [onSubmit]);

  const handleStepCancel = useCallback((): void => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    setSubmitHandler(handleStepSubmit);
    setCancelHandler(handleStepCancel);
  }, [handleStepSubmit, handleStepCancel, setSubmitHandler, setCancelHandler]);

  /* ** Add a new other role form, with default values ** */
  const handleNewAccessForm = (): void => {
    onToggle();
  };

  /* ** Create new viewer representative ** */
  const handleCreateAccess = (data: INaturalCompanyAccessForm): void => {
    createAccess({
      variables: {
        createIndividualRepresentativeInput: {
          companyId,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          role: RepresentativeRole.VIEWER,
        },
      },
      onCompleted: () => {
        onToggle();
        refetch();
      },
    });
  };

  /* ** Submit delete viewer representative ** */
  const handleDeleteAccess = (id: string | null): void => {
    if (id && companyId) {
      deleteAccess({
        variables: {
          companyId,
          representativeId: id,
        },
        onCompleted: () => {
          successToast({ title: t('DeletedCollaborator') });
          refetch();
        },
      });
    }
  };

  if (loading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (error) {
    return <ErrorQueryCard width="full" />;
  }

  if (!data) {
    return <ErrorQueryCard width="full" />;
  }

  // Filter representatives to keep only VIEWER and CREATOR not already displayed
  const representatives = data?.company?.companyRepresentative || [];
  const representativeIds = representatives
    .filter((rep) => ![RepresentativeRole.CREATOR, RepresentativeRole.VIEWER].includes(rep.role))
    .map((rep) => rep.representativeIdentity.id);
  const accessReps = representatives
    .filter(
      (rep) =>
        rep.role === RepresentativeRole.VIEWER ||
        !representativeIds.includes(rep.representativeIdentity.id),
    )
    .sort((a, b) => (a.representativeIdentity.name > b.representativeIdentity.name ? 1 : -1));

  return (
    <>
      {accessReps.map((representative) => (
        <Accordion key={representative.id} allowToggle>
          <AccordionItem mt="0">
            <AccordionButton>
              <Tooltip hasArrow label={t('RoleValidated')} placement="top">
                <Icon as={CheckCircleIcon} boxSize="30" color="green.500" />
              </Tooltip>
              <Box flex="1" p="2" textAlign="left">
                <Heading fontSize="lg">{`${representative.representativeIdentity?.individualIdentity?.firstName} ${representative.representativeIdentity?.individualIdentity?.lastName}`}</Heading>
                <Text>{representative.representativeIdentity?.individualIdentity?.email}</Text>
              </Box>
              <AccordionIcon boxSize="8" />
            </AccordionButton>
            <AccordionPanel>
              <CompanyAccessForm
                isDisabled
                isEditing={isEditing}
                shouldCancel={representative.role !== RepresentativeRole.CREATOR}
                defaultValues={{
                  id: representative.id,
                  email: representative.representativeIdentity?.individualIdentity?.email || '',
                  firstName:
                    representative.representativeIdentity?.individualIdentity?.firstName || '',
                  lastName:
                    representative.representativeIdentity?.individualIdentity?.lastName || '',
                  isContact: representative.isContact,
                }}
                onCancel={handleDeleteAccess}
                onSubmit={handleCreateAccess}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}
      {isOpen && (
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem mt="0">
            <AccordionButton>
              <Tooltip hasArrow label={t('IncompleteRoleInformation')} placement="top">
                <Icon as={ExclamationCircleIcon} boxSize="30" color="yellow.400" />
              </Tooltip>
              <Box flex="1" p="2" textAlign="left">
                <Heading fontSize="lg">Autre role</Heading>
              </Box>
              <AccordionIcon boxSize="8" />
            </AccordionButton>
            <AccordionPanel>
              <CompanyAccessForm
                defaultValues={naturalAccessFormDefaults}
                onCancel={onToggle}
                onSubmit={handleCreateAccess}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
      <Button isDisabled={isOpen} variant="light" onClick={handleNewAccessForm}>
        <Icon as={PlusIcon} mr="1" /> {t('AddColaborator')}
      </Button>
    </>
  );
}

export type CompanyAccessProps = Props;
