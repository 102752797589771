import * as yup from 'yup';

import { FormErrors, requiredNumberField, requiredSelectField } from '@blockpulse3/data/shared';

import { SaleStepForm } from './types';

export const schema = yup.object({
  shareCount: requiredNumberField.when('$availableShares', ([availableShares], schema) => {
    return schema.max(availableShares, FormErrors.MaximalAssetAmount);
  }),
  investAmount: requiredNumberField,
  buyerIdentity: requiredSelectField,
}) as yup.ObjectSchema<SaleStepForm>;

export const saleStepDefaultValues: SaleStepForm = {
  shareCount: 0,
  investAmount: 0,
  buyerIdentity: { label: '', value: '' },
};
