import {
  Button,
  Card,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';

import {
  BankTransferInfosFragment,
  useGetPendingBankTransfersQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { TableContainer } from '@blockpulse3/ui/commons';

import { ReconcileBankTransferModal } from './ReconcileBankTransferModal';

export function AdminBankTransferTable(): JSX.Element {
  const t = useTranslations();

  const { data, loading, refetch } = useGetPendingBankTransfersQuery();

  const [selectedBankTransferId, setSelectedBankTransferId] = useState<string>('');
  const [sorting, setSorting] = useState<SortingState>([{ id: 'identity', desc: false }]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleReconciling = (bankTransferId: string): void => {
    setSelectedBankTransferId(bankTransferId);
    onOpen();
  };

  const columnHelper = useMemo(() => createColumnHelper<BankTransferInfosFragment>(), []);
  const columns = useMemo(
    () => [
      columnHelper.accessor('label', {
        id: 'label',
        header: t('Label', { nb: 1 }),
        enableColumnFilter: false,
        cell: (row) => <Td>{row.getValue()}</Td>,
      }),
      columnHelper.accessor('reference', {
        id: 'reference',
        header: t('Reference', { nb: 1 }),
        enableColumnFilter: false,
        cell: (row) => <Td>{row.getValue()}</Td>,
      }),
      columnHelper.accessor('amount', {
        id: 'amount',
        header: t('Amount', { nb: 1 }),
        enableColumnFilter: false,
        cell: (row) => <Td>{row.getValue()}</Td>,
      }),
      columnHelper.accessor('createdAt', {
        header: t('CreationDate'),
        enableSorting: true,
        enableColumnFilter: false,
        cell: (row) => (
          <Td align="right">{row.getValue() ? formatDate(row.getValue(), 'DD/MM/YYYY') : '-'}</Td>
        ),
      }),
      columnHelper.accessor(() => 'reconciling', {
        header: t('Share', { nb: 1 }),
        enableSorting: true,
        enableColumnFilter: false,
        cell: (info) => (
          <Td align="right">
            <Button
              variant="secondary"
              onClick={(): void => handleReconciling(info.row.original.id)}
            >
              {t('Reconcile')}
            </Button>
          </Td>
        ),
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data: data?.getPendingBankTransfers || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="400px" />
      </Skeleton>
    );
  }

  return (
    <Stack spacing="4">
      <TableContainer maxH="none">
        <Table style={{ backgroundColor: 'red' }} variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    <HStack justifyContent="space-between" spacing="2">
                      <HStack
                        cursor={header.column.getCanSort() ? 'pointer' : 'initial'}
                        spacing="0"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Text>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </Text>
                        {header.column.getIsSorted() && (
                          <Icon
                            boxSize="16px"
                            as={
                              header.column.getIsSorted() === 'asc'
                                ? ChevronUpIcon
                                : ChevronDownIcon
                            }
                          />
                        )}
                      </HStack>
                    </HStack>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) =>
                  flexRender(cell.column.columnDef.cell, {
                    ...cell.getContext(),
                    key: cell.id,
                  }),
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {selectedBankTransferId && (
        <ReconcileBankTransferModal
          bankTransferId={selectedBankTransferId}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={refetch}
        />
      )}
    </Stack>
  );
}
