import { Icon, Stack, Text } from '@chakra-ui/react';
import { UserGroupIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

type Props = unknown;

/**
 * SubscriberTableEmpty.
 * Empty component to render when `<SubscriberTable />` is empty.
 *
 * @returns {JSX.Element}
 */
export function SubscribersTableEmpty(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack layerStyle="emptyState">
      <Icon as={UserGroupIcon} boxSize="40px" color="gray.500" />
      <Text>{t('NoSubscribersAddedYet')}</Text>
    </Stack>
  );
}

export type SubscribersTableEmptyProps = Props;
