export const styles = {
  md: {
    fontSize: 'lg',
    fontWeight: 'medium',
    badgeFontSize: '14px',
  },
  sm: {
    fontSize: 'xs',
    fontWeight: 'medium',
    badgeFontSize: '12px',
  },
};
