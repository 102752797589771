import { BigNumber } from 'bignumber.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
import { NumberToLetter } from 'convertir-nombre-lettre';

/* ** Number of decimals on format. !== BigNumber.config() ** */
const DP = 2;
/* ** Default format, currency specific ** */
const MONEY_FMT = {
  prefix: '',
  decimalSeparator: ',',
  groupSeparator: ' ',
  groupSize: 3,
  suffix: '',
};

/**
 * formatNumber.
 * Format a value, using a format object.
 * BigNumber.Value := string | number | BigNumber instance.
 *
 * @param {BigNumber.Value} n
 * @param {BigNumber.Format} options
 * @returns {string}
 */
export function formatNumber(n: BigNumber.Value, options: BigNumber.Format = MONEY_FMT): string {
  const fmt = { ...MONEY_FMT, ...options };
  const number = new BigNumber(n);

  return number.toFormat(DP, fmt);
}

/**
 * formatNumberCurrency.
 * Shorthand for currency formated values.
 *
 * @param {BigNumber.Value} n
 * @returns {string}
 */
export function formatNumberCurrency(n: BigNumber.Value): string {
  return formatNumber(n, { suffix: ' €' });
}

/**
 * formatCurrencyToNumber.
 * Convert a formatted currency into a number
 *
 * @param {string} n
 * @returns {number}
 */
export function formatCurrencyToNumber(n: string): number {
  const strNumber = n.replace(/\s|€/g, '').replace(',', '.');
  return parseFloat(strNumber);
}

/**
 * formatNumberPercentage.
 * Shorthand for percentage formated values.
 *
 * @param {BigNumber.Value} n
 * @returns {string}
 */
export function formatNumberPercentage(n: BigNumber.Value): string {
  return formatNumber(n, { suffix: ' %' });
}

/**
 * formatNumberInt.
 * Format a value without decimals, using a format object.
 * BigNumber.Value := string | number | BigNumber instance.
 *
 * @param {BigNumber.Value} n
 * @param {BigNumber.Format} options
 * @returns {string}
 */
export function formatNumberInt(n: BigNumber.Value, options: BigNumber.Format = MONEY_FMT): string {
  const fmt = { ...MONEY_FMT, ...options };
  const number = new BigNumber(n);

  return number.toFormat(0, fmt);
}

/**
 * getNearestMultiple.
 * Return the biggest multiple of div lower than n.
 *
 * @param {BigNumber.Value} n
 * @param {BigNumber.Value} div
 * @returns {string}
 */
export function getNearestMultiple(n: BigNumber.Value, div: BigNumber.Value): BigNumber {
  const number = new BigNumber(n);
  return number.idiv(div).times(div);
}

/**
 * formatNumberInLetters.
 * Convert a number into a string with letters.
 *
 * @param {BigNumber.Value} n
 * @returns {string}
 */
export function formatNumberInLetters(n: BigNumber.Value): string {
  if (!n) return '';
  const number = new BigNumber(n);
  const decimalPart = Math.round((number.toNumber() % 1) * 100) / 100;
  if (decimalPart === 0) return NumberToLetter(n);
  const integer = number.minus(decimalPart).toNumber();
  const decimals = Math.trunc(decimalPart * 100);
  return `${NumberToLetter(integer)} virgule ${NumberToLetter(decimals)}`;
}
