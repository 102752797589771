import { Navigate, Route, Routes } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { DistributionView, DistributionsView } from '@blockpulse3/web-client/bank';
import {
  CompanyDashboardView,
  CompanyDocumentsView,
  InvitationView,
  OperationListView,
} from '@blockpulse3/web-client/dashboard';
import { OperationTableFilterProvider } from '@blockpulse3/web-client/operation/commons';
import {
  FundraisingView,
  NewCrowdfundingFundraisingCapitalFixedBankDetails,
  NewCrowdfundingFundraisingCreate,
  NewCrowdfundingFundraisingDocumentation,
  NewCrowdfundingFundraisingLayout,
  NewCrowdfundingFundraisingParameters,
  NewCrowdfundingFundraisingSummary,
  NewPrivateFundraisingCapitalFixedBankDetails,
  NewPrivateFundraisingCreate,
  NewPrivateFundraisingDocumentation,
  NewPrivateFundraisingLayout,
  NewPrivateFundraisingParameters,
  NewPrivateFundraisingSubscribers,
  NewPrivateFundraisingSummary,
} from '@blockpulse3/web-client/operation/fundraising';
import {
  NewOpportunityOperationCreate,
  NewOpportunityOperationDocument,
  NewOpportunityOperationLayout,
  NewOpportunityOperationParameters,
  NewOpportunityOperationSummary,
  OpportunityOperationView,
} from '@blockpulse3/web-client/operation/opportunity';
import { OptionPoolView } from '@blockpulse3/web-client/operation/option-pool';
import {
  NewSecondaryOperationBuyers,
  NewSecondaryOperationCreate,
  NewSecondaryOperationLayout,
  NewSecondaryOperationParameters,
  NewSecondaryOperationSellers,
  NewSecondaryOperationSummary,
  SecondaryOperationView,
} from '@blockpulse3/web-client/operation/secondary';
import {
  OptionView,
  RepatriationOperationInformations,
  RepatriationOperationLayout,
  RepatriationShareTransferRegisterAssets,
  RepatriationShareTransferRegisterCompany,
  RepatriationShareTransferRegisterGrants,
  RepatriationShareTransferRegisterIdentities,
  RepatriationShareTransferRegisterImport,
  RepatriationShareTransferRegisterLayout,
  RepatriationShareTransferRegisterSummary,
  ShareholdingView,
} from '@blockpulse3/web-client/shareholding';
import { SubscriptionView } from '@blockpulse3/web-client/subscription';
import { WorkspaceSettingsView } from '@blockpulse3/web-client/workspace';

import { AppLayout } from '../layouts';
import { BankRoutes } from './BankRoutes';

type Props = unknown;

/**
 * CompanyWorkspaceRoutes.
 * Renders all company related routes, set active workspace.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function CompanyWorkspaceRoutes(): JSX.Element {
  const newFundraisingPrivateRoutes = routes.company.newFundraising.private;
  const newFundraisingCrowdfundingRoutes = routes.company.newFundraising.crowdfunding;
  const newOperationRoutes = routes.company.newOperation;
  const repatriationRoutes = routes.company.repatriation;
  const newSecondaryRoutes = routes.company.newSecondary;
  const newOpportunityRoutes = routes.company.newOpportunity;

  return (
    <Routes>
      <Route
        element={<NewPrivateFundraisingLayout />}
        path={newFundraisingPrivateRoutes.href + '/*'}
      >
        <Route
          element={<NewPrivateFundraisingCreate />}
          path={newFundraisingPrivateRoutes.fromOpportunity.href}
        />
        <Route path={newFundraisingPrivateRoutes.edit.href + '/*'}>
          <Route
            element={<NewPrivateFundraisingDocumentation />}
            path={newFundraisingPrivateRoutes.edit.documentation.href}
          />
          <Route
            element={<NewPrivateFundraisingCapitalFixedBankDetails />}
            path={newFundraisingPrivateRoutes.edit.iban.href}
          />
          <Route
            element={<NewPrivateFundraisingSubscribers />}
            path={newFundraisingPrivateRoutes.edit.subscribers.href}
          />
          <Route
            element={<NewPrivateFundraisingSummary />}
            path={newFundraisingPrivateRoutes.edit.summary.href}
          />
          <Route element={<NewPrivateFundraisingParameters />} path="*" />
        </Route>
        <Route element={<NewPrivateFundraisingCreate />} path="*" />
      </Route>
      <Route
        element={<NewCrowdfundingFundraisingLayout />}
        path={newFundraisingCrowdfundingRoutes.href + '/*'}
      >
        <Route path={newFundraisingCrowdfundingRoutes.edit.href + '/*'}>
          <Route
            element={<NewCrowdfundingFundraisingDocumentation />}
            path={newFundraisingCrowdfundingRoutes.edit.documentation.href}
          />
          <Route
            element={<NewCrowdfundingFundraisingCapitalFixedBankDetails />}
            path={newFundraisingCrowdfundingRoutes.edit.iban.href}
          />
          <Route
            element={<NewCrowdfundingFundraisingSummary />}
            path={newFundraisingCrowdfundingRoutes.edit.summary.href}
          />
          <Route element={<NewCrowdfundingFundraisingParameters />} path="*" />
        </Route>
        <Route element={<NewCrowdfundingFundraisingCreate />} path="*" />
      </Route>
      <Route element={<NewSecondaryOperationLayout />} path={newSecondaryRoutes.href + '/*'}>
        <Route
          element={<NewSecondaryOperationCreate />}
          path={newSecondaryRoutes.fromOpportunity.href}
        />
        <Route path={newSecondaryRoutes.edit.href + '/*'}>
          <Route
            element={<NewSecondaryOperationSellers />}
            path={newSecondaryRoutes.edit.sellers.href}
          />
          <Route
            element={<NewSecondaryOperationBuyers />}
            path={newSecondaryRoutes.edit.buyers.href}
          />
          <Route
            element={<NewSecondaryOperationSummary />}
            path={newSecondaryRoutes.edit.summary.href}
          />
          <Route element={<NewSecondaryOperationParameters />} path="*" />
        </Route>
        <Route element={<NewSecondaryOperationCreate />} path="*" />
      </Route>
      <Route element={<NewOpportunityOperationLayout />} path={newOpportunityRoutes.href + '/*'}>
        <Route path={newOpportunityRoutes.edit.href}>
          <Route
            element={<NewOpportunityOperationDocument />}
            path={newOpportunityRoutes.edit.documentation.href}
          />
          <Route
            element={<NewOpportunityOperationSummary />}
            path={newOpportunityRoutes.edit.summary.href}
          />
          <Route element={<NewOpportunityOperationParameters />} path="*" />
        </Route>
        <Route element={<NewOpportunityOperationCreate />} path="*" />
      </Route>
      <Route
        element={<RepatriationOperationLayout />}
        path={newOperationRoutes.href + '/' + newOperationRoutes.repatriation.href + '/*'}
      >
        <Route path={newOperationRoutes.repatriation.edit.href}>
          <Route
            element={<RepatriationShareTransferRegisterAssets />}
            path={repatriationRoutes.assets.href}
          />
          <Route
            element={<RepatriationShareTransferRegisterIdentities />}
            path={repatriationRoutes.identities.href}
          />
          <Route
            element={<RepatriationShareTransferRegisterImport />}
            path={repatriationRoutes.import.href}
          />
          <Route
            element={<RepatriationShareTransferRegisterGrants />}
            path={repatriationRoutes.grants.href}
          />
          <Route
            element={<RepatriationShareTransferRegisterSummary />}
            path={repatriationRoutes.summary.href}
          />
          <Route element={<RepatriationOperationInformations />} path="*" />
        </Route>
        <Route element={<RepatriationOperationInformations />} path="*" />
      </Route>
      <Route
        element={<RepatriationShareTransferRegisterLayout />}
        path={repatriationRoutes.href + '/*'}
      >
        <Route
          element={<RepatriationShareTransferRegisterAssets />}
          path={repatriationRoutes.assets.href}
        />
        <Route
          element={<RepatriationShareTransferRegisterIdentities />}
          path={repatriationRoutes.identities.href}
        />
        <Route
          element={<RepatriationShareTransferRegisterImport />}
          path={repatriationRoutes.import.href}
        />
        <Route
          element={<RepatriationShareTransferRegisterGrants />}
          path={repatriationRoutes.grants.href}
        />
        <Route
          element={<RepatriationShareTransferRegisterSummary />}
          path={repatriationRoutes.summary.href}
        />
        <Route element={<RepatriationShareTransferRegisterCompany />} path="*" />
      </Route>
      <Route element={<BankRoutes />} path="*" />
      <Route element={<AppLayout />}>
        <Route index element={<CompanyDashboardView />} />
        <Route element={<WorkspaceSettingsView />} path={routes.company.settings.href} />
        <Route element={<CompanyDocumentsView />} path={routes.company.documents.href} />
        <Route element={<InvitationView />} path={routes.company.invitations.href} />
        <Route element={<OperationListView />} path={routes.company.operations.href} />
        <Route element={<ShareholdingView />} path={routes.company.captable.href}>
          <Route element={<ShareholdingView />} path={routes.company.captable.rmt.href} />
          <Route element={<ShareholdingView />} path={routes.company.captable.rmt.movement.href} />
          <Route element={<ShareholdingView />} path={routes.company.captable.assets.href} />
        </Route>
        <Route element={<DistributionsView />} path={routes.company.distributions.href} />
        <Route element={<DistributionView />} path={routes.company.distribution.href} />
        <Route element={<SubscriptionView />} path={routes.subscription.href} />
        <Route element={<OperationTableFilterProvider />}>
          <Route element={<FundraisingView />} path={routes.company.fundraising.href}>
            <Route
              element={<FundraisingView />}
              path={routes.company.fundraising.subscription.href}
            />
          </Route>
        </Route>
        <Route element={<OperationTableFilterProvider />}>
          <Route element={<SecondaryOperationView />} path={routes.company.secondary.href}>
            <Route
              element={<SecondaryOperationView />}
              path={routes.company.secondary.subscription.href}
            />
          </Route>
        </Route>
        <Route element={<OperationTableFilterProvider />}>
          <Route element={<OpportunityOperationView />} path={routes.company.opportunity.href}>
            <Route
              element={<OpportunityOperationView />}
              path={routes.company.opportunity.subscription.href}
            />
          </Route>
        </Route>
        <Route element={<OperationTableFilterProvider />}>
          <Route element={<OptionPoolView />} path={routes.company.optionPool.href}>
            <Route
              element={<OptionPoolView />}
              path={routes.company.optionPool.subscription.href}
            />
          </Route>
        </Route>
        <Route element={<OptionView />} path={routes.company.option.href} />
      </Route>
      <Route element={<Navigate replace to={routes.me.href} />} path="*" />
    </Routes>
  );
}

export type CompanyWorkspaceRoutesProps = Props;
