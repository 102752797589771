import { Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { SpaceHeader } from '../SpaceHeader';
import { AdminIndividualsList } from './AdminIndividualsList';

type Props = unknown;

export function AdminIndividuals(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack spacing="6">
      <Stack spacing="4">
        <SpaceHeader />
        <Heading size="lg">{t('Individual', { nb: 3 })}</Heading>
      </Stack>
      <AdminIndividualsList />
    </Stack>
  );
}

export type AdminIndividualsProps = Props;
