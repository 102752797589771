import { Button, Divider, Skeleton, Stack, useDisclosure } from '@chakra-ui/react';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  OperationStatus,
  SubscriptionStepStatus,
  useDeleteSubscriptionMutation,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { DeleteConfirmModal, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';
import { useStepStatusMapLazy } from '@blockpulse3/ui/ui-hooks';

type Props = unknown;

export function AttributionPanelDelete(): JSX.Element | null {
  const t = useTranslations();

  const confirmRef = useRef(null);

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { subscriptionId = '' } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, loading, error, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const [deleteSubscription, { loading: deleteSubscriptionLoading }] =
    useDeleteSubscriptionMutation();

  const subscription = data?.subscription;

  const generateStepStatusMap = useStepStatusMapLazy();
  const [stepStatusMap] = generateStepStatusMap(subscription?.data?.mergedSteps);

  if (loading) {
    return (
      <>
        <Divider />
        <Stack px="4" spacing="4">
          <Skeleton h="40px" />
        </Stack>
      </>
    );
  }

  if (!subscription?.operation || error) return null;

  const isClosed =
    subscription?.operation.status &&
    [OperationStatus.CLOSED, OperationStatus.REVIEWED, OperationStatus.FINALIZED].includes(
      subscription?.operation.status,
    );

  const subscriptionSigned =
    stepStatusMap['DOCUMENT_SIGNATURE'] &&
    stepStatusMap['DOCUMENT_SIGNATURE'] === SubscriptionStepStatus.VALID;

  const shouldRender = !isClosed && !subscriptionSigned;

  if (!shouldRender) return null;

  const handleSubscriberDelete = (): void => {
    deleteSubscription({
      variables: {
        subscriptionId,
      },
      onCompleted: () => {
        onClose();
        successToast({ title: t('IntentDeletedSuccessfully') });
        refetch();
      },
      onError: () => {
        errorToast({ title: t('IntentDeletionError') });
      },
    });
  };

  return (
    <>
      <Divider />
      <Stack px="4" spacing="2">
        <Button
          alignSelf="end"
          isDisabled={deleteSubscriptionLoading}
          isLoading={deleteSubscriptionLoading}
          mt="4"
          variant="delete"
          w="fit-content"
          onClick={onOpen}
        >
          {t('DeleteIntent')}
        </Button>
        <DeleteConfirmModal
          isLoading={deleteSubscriptionLoading}
          isOpen={isOpen}
          leastDestructiveRef={confirmRef}
          subtitle={t('CarefullDefinitiveAction')}
          title={t('DeleteIntentQuestion')}
          onClose={onClose}
          onDelete={handleSubscriberDelete}
        />
      </Stack>
    </>
  );
}

export type IntentPanelDeleteProps = Props;
