import {
  Box,
  Button,
  Divider,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react';
import { BellIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useAuthUser } from '@blockpulse3/web-client/auth';

dayjs.extend(relativeTime);

type Props = unknown;

export function SettingsNotifications(): JSX.Element {
  const t = useTranslations();

  const { user: authUser, spaceId } = useAuthUser();

  const isManaging = useMatch(routes.manage.href + '/*');

  return (
    <Popover isLazy placement="auto-end">
      <PopoverTrigger>
        <Button
          bg={isManaging && spaceId ? 'black' : undefined}
          fontSize="sm"
          fontWeight="normal"
          leftIcon={<Icon as={BellIcon} boxSize="18px" ml="1" mr="2" />}
          variant="menu-button"
        >
          {t('Notifications')}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody maxHeight="500px" overflowY="scroll" p="0">
            {authUser?.uiNotifications?.length ? (
              authUser?.uiNotifications?.map((notification, index) => {
                return (
                  <Box key={notification.id} p="0">
                    {index !== 0 && <Divider borderColor="gray.300" />}
                    <Stack p="3" spacing="2" textAlign="left">
                      <Text fontSize="sm" fontWeight="500">
                        {notification.textFr}
                      </Text>
                      <Text
                        color="gray.500"
                        fontSize="xs"
                        fontWeight="600"
                        sx={{
                          ':first-letter': {
                            textTransform: 'uppercase',
                          },
                        }}
                      >
                        {dayjs(notification.createdAt).fromNow()}
                      </Text>
                    </Stack>
                  </Box>
                );
              })
            ) : (
              <Stack p="3" spacing="2">
                <Text fontSize="md" fontWeight="500">
                  {t('NoNotification')}
                </Text>
              </Stack>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export type SettingsNotificationsProps = Props;
