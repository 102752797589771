import {
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { TranslationValues, useTranslations } from 'use-intl';

import { FormErrors, OperationDocumentType } from '@blockpulse3/data/shared';
import {
  MergedHoldingMethodStep,
  useGetSubscriptionDocumentsQuery,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionHoldingMethod } from '../../../SubscriptionHoldingMethod';
import { FundsSourceUpload } from '../../commons';
import { schema } from './schema';
import { HoldingMethodStepForm } from './types';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  subscriptionStep: MergedHoldingMethodStep;
} & Omit<ResponsiveModalProps, 'children'>;

export function HoldingMethodModal({
  onClose,
  onSubmit,
  subscriptionStep,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const [errorValues, setErrorValues] = useState<TranslationValues | undefined>(undefined);
  const [isHoldingMethodLoading, setIsHoldingMethodLoading] = useState(false);

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const { data: documentData } = useGetSubscriptionDocumentsQuery({
    variables: { subscriptionId, documentType: OperationDocumentType.FUNDS_SOURCE_CERTIFICATE },
    skip: !subscriptionId,
  });
  const fundsSourceCertificates = documentData?.getSubscriptionDocuments || [];

  const { data: subscriptionData } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = subscriptionData?.subscription;
  const operation = subscription?.operation;
  const investAmount = subscription?.investAmount || 0;
  const proofOfFundsThreshold = operation?.proofOfFundsThreshold;
  const needsFundsSourceCertificate =
    proofOfFundsThreshold && investAmount >= proofOfFundsThreshold;

  const methods = useForm<HoldingMethodStepForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, setError } = methods;

  const handleFormSubmit: SubmitHandler<HoldingMethodStepForm> = (): void => {
    const proofOfFundsThresholdFormatted = formatNumberCurrency(proofOfFundsThreshold || 0);

    // Show error and continue form validation
    if (needsFundsSourceCertificate && !fundsSourceCertificates.length) {
      setErrorValues({
        proofOfFundsThreshold: proofOfFundsThresholdFormatted,
      });
      setError('fundsSourceCertificate', {
        type: 'custom',
        message: FormErrors.FundsSourceCertificateMissing,
      });
    }

    onSubmit();
  };

  return (
    <ResponsiveModal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="xl" p="4">
          {t('ChooseHoldingMethod')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack}>
          <FormProvider {...methods}>
            <form id="holding-method-step" onSubmit={handleSubmit(handleFormSubmit)}>
              <Stack>
                <SubscriptionHoldingMethod setIsLoading={setIsHoldingMethodLoading} />
                {needsFundsSourceCertificate && <FundsSourceUpload errorValues={errorValues} />}
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button form="holding-method-step" isDisabled={isHoldingMethodLoading} type="submit">
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
