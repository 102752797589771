import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  FormControl,
  FormLabel,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  ArchiveDocumentError,
  ArchiveDocumentErrorCode,
  ArchiveParsingErrorCode,
  noop,
} from '@blockpulse3/data/shared';
import {
  ErrorMessage,
  Input,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useSuccessToast,
  useWarningToast,
} from '@blockpulse3/ui/commons';

import { importArchiveDefaultValue, importArchiveSchema } from './schema';
import { IFundraisingArchiveImportModal } from './types';

type Props = {
  /* ** Callback on modal close ** */
  onClose: () => void;
} & Omit<ResponsiveModalProps, 'children'>;

export function FundraisingArchiveImportModal({ onClose = noop, ...props }: Props): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [error, setError] = useState<ArchiveDocumentError | null>(null);

  const successToast = useSuccessToast();
  const warningToast = useWarningToast();

  const { control, handleSubmit, register, formState, resetField, watch } =
    useForm<IFundraisingArchiveImportModal>({
      defaultValues: importArchiveDefaultValue,
      resolver: yupResolver(importArchiveSchema),
      mode: 'onChange',
    });

  const handleUrlSubmit = async (): Promise<void> => {
    const archiveUrl = watch('archiveUrl');
    setError(null);

    if (archiveUrl && operationId) {
      const formData = new FormData();
      formData.append('archiveUrl', archiveUrl);
      formData.append('operationId', operationId);
      try {
        setIsUploading(true);
        const { data } = await axios.post(
          process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/importOperationDocumentsArchive',
          formData,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        if (data) {
          successToast({ title: t('DocumentsImportedSuccessfully') });
        } else {
          warningToast({ title: t('NoNewDocumentFound') });
        }

        handleModalClose();
      } catch (err: unknown) {
        if (axios.isAxiosError(err)) {
          const { data } = err.response || {};
          setError(data);
        }
      }
      setIsUploading(false);
    }
  };

  const handleModalClose = (): void => {
    setError(null);
    resetField('archiveUrl');
    onClose();
  };

  const ErrorText = ({ error }: { error: ArchiveDocumentError }): JSX.Element => {
    switch (error.code) {
      case ArchiveDocumentErrorCode.ARCHIVE_DOWNLOAD_FAILED:
        return <Text>{t('ArchiveDownloadFailed')}</Text>;
      case ArchiveDocumentErrorCode.FOLDER_NOT_FOUND:
        return <Text>{t('FolderNotFoundInArchive')}</Text>;
      case ArchiveDocumentErrorCode.ARCHIVE_PARSING_FAILED: {
        const ListTag =
          error.parsingErrors && error.parsingErrors.length > 1 ? UnorderedList : Fragment;
        const ItemTag = error.parsingErrors && error.parsingErrors.length > 1 ? ListItem : Text;
        return (
          <ListTag>
            {error.parsingErrors.map(({ code, path }, index) => {
              switch (code) {
                case ArchiveParsingErrorCode.IDENTITY_NOT_FOUND_IN_OPERATION:
                  return (
                    <ItemTag key={`${path}-${code}`}>
                      {t.rich('IdentityNotFoundInOperation', {
                        path,
                        important: (chunk) => <b>{chunk}</b>,
                      })}
                    </ItemTag>
                  );
                case ArchiveParsingErrorCode.FILE_TYPE_UNSUPPORTED:
                  return (
                    <ItemTag key={`${path}-${code}`}>
                      {t.rich('FileTypeUnsupported', {
                        path,
                        important: (chunk) => <b>{chunk}</b>,
                      })}
                    </ItemTag>
                  );
                default:
                  return <ItemTag key={index}>{t('OperationDocumentsImportDefaultError')}</ItemTag>;
              }
            })}
          </ListTag>
        );
      }
      default:
        return <Text>{t('OperationDocumentsImportDefaultError')}</Text>;
    }
  };

  return (
    <ResponsiveModal onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('ImportDocumentsInBatch')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="4">
            <Alert status="info">
              <AlertIcon />
              <Stack spacing="2">
                <AlertTitle>{t('ImportedDocumentsReplaceWarning')}</AlertTitle>
              </Stack>
            </Alert>
            <form onSubmit={handleSubmit(handleUrlSubmit)}>
              <Controller
                control={control}
                name="archiveUrl"
                render={(): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.archiveUrl}>
                    <FormLabel>{t('ArchiveLink')}</FormLabel>
                    <Input id="archiveUrl" type="text" {...register('archiveUrl')} />
                    <ErrorMessage error={formState.errors?.archiveUrl} />
                  </FormControl>
                )}
              />
            </form>
            {error && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>
                  <Stack>
                    <ErrorText error={error} />
                  </Stack>
                </AlertTitle>
              </Alert>
            )}
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button isDisabled={isUploading} variant="secondary" onClick={handleModalClose}>
            {t('Cancel')}
          </Button>
          <Button
            isDisabled={!formState.isValid || isUploading}
            isLoading={isUploading}
            variant="primary"
            onClick={handleUrlSubmit}
          >
            {t('ImportOperationDocumentsAction')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
