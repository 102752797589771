import { ColorProps, HStack, Text, Tooltip } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { AssetType } from '@blockpulse3/graphql/hooks';
import { formatNumberInt } from '@blockpulse3/helpers';

type Props = {
  /* ** Type of the Asset (AO, BSA...) ** */
  type: AssetType;
  /* ** Number of assets, if null, just info type rendered ** */
  value: number | null;
  /* ** Size of the badge ** */
  size?: string;
  /* ** Is badge boxed ** */
  boxed?: boolean;
  /* ** Color of the text ** */
  color?: ColorProps['color'];
  /* ** Count of asset (for BSA AIR use case) */
  assetCount?: number;
  /* ** Name of the asset ** */
  assetName?: string;
  tooltip?: string;
};

export const ASSET_COLOR_MAP: Record<AssetType, ColorProps['color']> = {
  BOND: 'cyan.600',
  ORDINARY_SHARE: 'indigo.700',
  PREFERRED_SHARE: 'purple.600',
  BSA: 'orange.600',
  BSA_AIR: 'pink.600',
  BSPCE: 'norange.600',
  AGA: 'npurple.600',
};

export const ASSET_BGCOLOR_MAP: Record<AssetType, ColorProps['color']> = {
  BOND: 'cyan.200',
  ORDINARY_SHARE: 'indigo.200',
  PREFERRED_SHARE: 'purple.200',
  BSA: 'orange.200',
  BSA_AIR: 'pink.200',
  BSPCE: 'norange.200',
  AGA: 'npurple.200',
};

/**
 * AssetBadge.
 * Simple component, rendering a badge of desired asset type.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function AssetBadge({
  type,
  value,
  size = 'sm',
  boxed = true,
  color,
  assetCount = 1,
  assetName,
  tooltip,
}: Props): JSX.Element {
  const i18nAssetCount = useTranslations('AssetTypeCounts');
  const i18nAssetValue = useTranslations('AssetTypeValues');

  let smallSize = 'xs';
  let isLarge = false;
  if (['lg', 'xl'].includes(size)) {
    isLarge = true;
    smallSize = 'sm';
  }

  const boxStyles = {
    bg: 'gray.100',
    px: '2',
    py: '0.5',
    rounded: 'md',
    width: 'fit-content',
  };

  let badgeLabel: JSX.Element | string = assetName || '';
  if (value || value === 0) {
    const displayedValue = formatNumberInt(
      type === AssetType.BSA_AIR && value !== 0 ? assetCount : value,
    );
    if (assetName && type !== AssetType.ORDINARY_SHARE) {
      badgeLabel = (
        <>
          <Text
            as={!boxed && isLarge ? 'div' : 'span'}
            color={boxed ? 'inherit' : 'gray.800'}
            fontSize={size}
            fontWeight="700"
          >
            {displayedValue}
          </Text>{' '}
          {assetName}
        </>
      );
    } else {
      badgeLabel = (
        <>
          {i18nAssetCount.rich(type, {
            assetCount: displayedValue,
            nb: value,
            formattedNb: value || value === 0 ? formatNumberInt(value) : '',
            important: (chunk) => (
              <Text
                as={!boxed && isLarge ? 'div' : 'span'}
                color={boxed ? 'inherit' : 'gray.800'}
                fontSize={size}
                fontWeight="700"
              >
                {chunk}
              </Text>
            ),
          })}
        </>
      );
    }
  } else {
    badgeLabel = i18nAssetValue(type, { nb: 1 });
  }

  return (
    <Tooltip hasArrow isDisabled={!tooltip} label={tooltip} placement="top" textAlign="center">
      <HStack {...(boxed ? boxStyles : {})}>
        <Text color={color || ASSET_COLOR_MAP[type]} fontSize={size}>
          <Text
            as="span"
            display="block"
            fontSize={smallSize}
            fontWeight={boxed ? '600' : '400'}
            textAlign={!isLarge ? 'center' : undefined}
          >
            {badgeLabel}
          </Text>
        </Text>
      </HStack>
    </Tooltip>
  );
}

export type AssetBadgeProps = Props;
