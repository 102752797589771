import { Skeleton, Stack, Stepper, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetActivitiesQuery } from '@blockpulse3/graphql/hooks';

import { Activity } from './Activity/Activity';

type Props = unknown;

/**
 * OperationPanelActivityFeed.
 * Activity feed tab section of the transaction side panel.
 *
 * @returns {JSX.Element}
 */
export function OperationPanelActivityFeed(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();

  const { data, loading, error } = useGetActivitiesQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });
  const activities = useMemo(() => data?.activities, [data?.activities]);

  if (loading || !data || error) {
    return (
      <Stack spacing="4">
        <Skeleton h="30px" w="75%" />
        <Skeleton h="30px" w="65%" />
        <Skeleton h="30px" w="80%" />
        <Skeleton h="30px" w="70%" />
      </Stack>
    );
  }

  return (
    <Stack spacing="4">
      {!activities?.length && (
        <Text color="gray.500" fontSize="sm">
          {t('NoActivity')}
        </Text>
      )}
      <Stepper gap="0" index={-1} orientation="vertical" w="100%">
        {activities?.map((activity) => <Activity key={activity.id} activity={activity} />)}
      </Stepper>
    </Stack>
  );
}

export type OperationPanelActivityFeedProps = Props;
