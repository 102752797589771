import { IconButton, IconButtonProps, Text, Tooltip } from '@chakra-ui/react';

type Props = {
  label: string;
} & IconButtonProps;

/**
 * IconButtonWithTooltip.
 * Wrappe a button in tooltip
 * @returns {JSX.Element}
 */
export function IconButtonWithTooltip({ label, ...props }: Props): JSX.Element {
  const { isDisabled, icon, onClick } = props;
  return (
    <Tooltip hasArrow label={<Text textAlign="center">{label}</Text>} placement="top">
      <IconButton
        icon={icon}
        isDisabled={isDisabled}
        variant="secondary"
        onClick={onClick}
        {...props}
      />
    </Tooltip>
  );
}

export type IconButtonWithTooltipProps = Props;
