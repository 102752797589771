import { createContext } from 'react';

import { noop } from '@blockpulse3/data/shared';
import { SubscriptionsFilterInput } from '@blockpulse3/graphql/hooks';

export type IOperationTableFilterContext = {
  filters: SubscriptionsFilterInput[];
  setFilters: React.Dispatch<React.SetStateAction<SubscriptionsFilterInput[]>>;
};

export const initOperationTableFilter: IOperationTableFilterContext = {
  filters: [],
  setFilters: noop,
};

export const OperationTableFilterContext =
  createContext<IOperationTableFilterContext>(initOperationTableFilter);
