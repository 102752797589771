import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { SortingState, Updater } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { generatePath, resolvePath, useLocation, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { SubscriptionsTabsStatus, routes } from '@blockpulse3/data/shared';
import {
  GetSubscriptionsDocument,
  GetSubscriptionsQuery,
  GetSubscriptionsQueryVariables,
  OperationStatus,
  OrderBy,
  OrderByDirection,
  SubscriptionFilterType,
  SubscriptionShortInfosFragment,
  SubscriptionsFilterInput,
  useGetOperationQuery,
  useGetSubscriptionsCountQuery,
} from '@blockpulse3/graphql/hooks';
import { useDisclosureLink, usePagination } from '@blockpulse3/ui/ui-hooks';
import {
  OperationRemindersModal,
  OperationSubscribersType,
  useOperationFilterContext,
} from '@blockpulse3/web-client/operation/commons';

import { SecondaryOperationTransactionControls } from './SecondaryOperationTransactionControls';
import { SecondaryOperationTransactionTable } from './SecondaryOperationTransactionTable';
import { SecondaryOperationTransactionTabs } from './SecondaryOperationTransactionTabs';
import { getTabsWithCount } from './utils';

export const PAGE_SIZE = 10;

type Props = {
  transactionPanel: ReturnType<typeof useDisclosureLink>;
};

/**
 * SecondaryOperationTransactions.
 *
 * @returns {JSX.Element}
 */
export function SecondaryOperationTransactions({ transactionPanel }: Props): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const location = useLocation();

  const reminderModal = useDisclosure();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { filters, setFilters } = useOperationFilterContext();

  const { data: operationData } = useGetOperationQuery({
    variables: {
      operationId,
    },
  });

  const subscriptionsCountsReq = useGetSubscriptionsCountQuery({
    variables: { operationId },
    fetchPolicy: 'cache-and-network',
  });

  // For now only handle unique column sorting
  const orderBy: OrderBy | undefined = useMemo(() => {
    if (!sorting.length) return undefined;
    return {
      direction: sorting[0].desc ? OrderByDirection.DESC : OrderByDirection.ASC,
      field: sorting[0].id,
    };
  }, [sorting]);

  const paginationProps = usePagination<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables,
    SubscriptionShortInfosFragment
  >({
    queryDocument: GetSubscriptionsDocument,
    queryOptions: {
      variables: {
        operationId,
        first: PAGE_SIZE,
        filterBy: filters,
        orderBy,
      },
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'subscriptions',
    pageSize: PAGE_SIZE,
  });
  const { totalCount, refetch, reset } = paginationProps;

  const operation = operationData?.operation;
  const isStarted = operation?.status === OperationStatus.STARTED;
  const isRelatedToOpportunity = !!operation?.opportunityId;
  const subscriptionsCounts = subscriptionsCountsReq.data?.getSubscriptionsCount;
  const tabs = getTabsWithCount(subscriptionsCounts);

  /* ** Refetch subscriptions() on filter modal submit ** */
  const handleFiltersSubmit = (values: SubscriptionsFilterInput[]): void => {
    const searchValueFilter = filters.filter((filter) =>
      ['searchValue', 'investStatus'].includes(filter.name),
    );
    reset();
    setFilters([...values, ...searchValueFilter]);
  };

  const handleSearchInputSubmit = (value: string): void => {
    const isInputAndFilterSearchEmpty =
      value === '' && !filters.some((filter) => filter.name === 'searchValue');

    if (isInputAndFilterSearchEmpty) return;

    resetSpecificFilter('searchValue', SubscriptionFilterType.CUSTOM, value);
  };

  const resetSpecificFilter = (
    filterName: string,
    filterType: SubscriptionFilterType,
    value: string,
  ): void => {
    const currentFilterWithoutFilterName = filters.filter((filter) => filter.name !== filterName);
    const newFilter: SubscriptionsFilterInput = {
      name: filterName,
      type: filterType,
      value,
    };
    reset();
    setFilters([...currentFilterWithoutFilterName, newFilter]);
  };

  const handleTabChange = (index: number): void => {
    setTabIndex(index);

    const newFilters: SubscriptionsFilterInput[] = [];
    if (tabs[index].name !== SubscriptionsTabsStatus.ALL_STATUS) {
      newFilters.push({
        name: 'investStatus',
        type: SubscriptionFilterType.STATUS,
        value: tabs[index].name,
      });
    }

    const resetFilterStatus = filters.filter((filter) => filter.name !== 'investStatus');
    setFilters([...newFilters, ...resetFilterStatus]);
    refetch();
  };

  const handleSortingChange = (sortFn: Updater<SortingState>): void => {
    setSorting(sortFn);
    refetch();
  };

  const handleRowClick = (subscriptionId: string): void => {
    transactionPanel.onOpen(
      resolvePath(
        generatePath(routes.company.secondary.subscription.href, { subscriptionId }),
        location.pathname,
      ).pathname,
    );
  };

  return (
    <Card>
      <CardHeader
        alignItems={{ base: 'flex-start', md: 'center' }}
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">{t('TransactionsList')}</Heading>
        {isStarted && (
          <Button variant="secondary" onClick={reminderModal.onOpen}>
            {t(isRelatedToOpportunity ? 'RemindBuyers' : 'RemindSellers', {
              nb: totalCount,
            })}
          </Button>
        )}
      </CardHeader>
      <CardBody as={Stack} spacing="4">
        <SecondaryOperationTransactionTabs handleTabChange={handleTabChange} tabs={tabs} />
        <SecondaryOperationTransactionControls
          activeTabIndex={tabIndex}
          filters={filters}
          tabs={tabs}
          onFiltersChange={handleFiltersSubmit}
          onSearchInputSubmit={handleSearchInputSubmit}
        />
        <SecondaryOperationTransactionTable
          operationStatus={operation?.status}
          sorting={sorting}
          tabName={tabs[tabIndex].name}
          onRowClick={handleRowClick}
          onSortingChange={handleSortingChange}
          {...paginationProps}
        />
      </CardBody>
      <OperationRemindersModal
        filters={filters}
        isOpen={reminderModal.isOpen}
        operationId={operationId}
        totalCount={totalCount}
        subscribersType={
          isRelatedToOpportunity
            ? OperationSubscribersType.BUYERS
            : OperationSubscribersType.SELLERS
        }
        onClose={reminderModal.onClose}
      />
    </Card>
  );
}

export type SecondaryOperationTransactionsProps = Props;
