import * as yup from 'yup';

import { FormErrors } from '@blockpulse3/data/shared';

import { IFundraisingSubscriberCheck } from './types';

export const checkSubscriberSchema = yup.object({
  maxSubscribers: yup.boolean().isTrue('CONDITIONS_FIELD').required(FormErrors.RequiredField),
}) as yup.ObjectSchema<IFundraisingSubscriberCheck>;

export const checkSubscriberDefaultValues: IFundraisingSubscriberCheck = {
  maxSubscribers: true,
};
