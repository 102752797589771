import { Card, CardBody, CardHeader, Skeleton, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { EmailVerificationStatus, useVerifyEmailMutation } from '@blockpulse3/graphql/hooks';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';
import { useErrorToast } from '@blockpulse3/ui/commons';

import { VerifyEmailExpired } from './VerifyEmailExpired';
import { VerifyEmailFailure } from './VerifyEmailFailure';
import { VerifyEmailSuccess } from './VerifyEmailSuccess';

type Props = unknown;

let isMailSent = false;

export function VerifyEmailView(): JSX.Element {
  const errorToast = useErrorToast();

  const navigate = useNavigate();
  const location = useLocation();

  const [verifyEmail, { data, error, loading }] = useVerifyEmailMutation();

  useEffect(() => {
    if (isMailSent) return;

    const qs = new URLSearchParams(location.search);
    const qsEmail = qs.get('email');
    const qsToken = qs.get('token');

    if (qsEmail && qsToken) {
      verifyEmail({
        variables: {
          verifyEmailInput: {
            email: qsEmail,
            token: qsToken,
          },
        },
        onCompleted: (data) => {
          if (data?.verifyEmail?.access_token) {
            localStorage.setItem('token', data.verifyEmail.access_token);
          }
        },
        onError: (err) => {
          errorToast({ title: err.message });
        },
      });
      isMailSent = true;
    } else {
      // Not enough info to continue, navigate back to root
      navigate(routes.me.href);
    }
  }, [location.search, verifyEmail, navigate, errorToast]);

  const isSuccess = !!data?.verifyEmail?.access_token;
  const isFailure = !!error;
  const isExpired = data?.verifyEmail?.process_step === EmailVerificationStatus.EXPIRED_TOKEN;

  const RenderVerifyEmailComponent = (): JSX.Element | null => {
    if (isExpired) {
      return <VerifyEmailExpired />;
    }
    if (isSuccess) {
      return <VerifyEmailSuccess />;
    }
    if (isFailure) {
      return <VerifyEmailFailure />;
    }
    return null;
  };

  return (
    <Skeleton isLoaded={!loading}>
      <Card w={{ base: 'full', sm: 420 }}>
        <CardHeader alignItems="center" as={Stack} w="full">
          <BrandIcon display="flex" justifyContent="center" objectFit="cover" width="200px" />
        </CardHeader>
        <CardBody>
          <RenderVerifyEmailComponent />
        </CardBody>
      </Card>
    </Skeleton>
  );
}

export type VerifyEmailViewProps = Props;
