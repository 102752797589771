import { HStack, Icon, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

export function UserVerificationApproved(): JSX.Element {
  const t = useTranslations();

  return (
    <HStack color="green.500" justify="center" pt="5">
      <Icon as={CheckCircleIcon} boxSize="24px" />
      <Text fontSize="lg" fontWeight="500">
        {t('YourIdentityAlreadyVerified')}
      </Text>
    </HStack>
  );
}
