import { useCallback } from 'react';
import { useTranslations } from 'use-intl';

import { isSubscriptionStarted } from '@blockpulse3/data/shared';
import {
  OperationInvestStatus,
  OperationStatus,
  OperationType,
  SubscriptionInfosFragment,
  SubscriptionShortInfosFragment,
} from '@blockpulse3/graphql/hooks';

import { IBadge } from './useBadge';

type UseInvestStatusBadge = (
  subscription: SubscriptionInfosFragment | SubscriptionShortInfosFragment | undefined,
  operationStatus: OperationStatus | undefined,
) => IBadge;

export function useInvestStatusBadge(): UseInvestStatusBadge {
  const t = useTranslations();

  return useCallback<UseInvestStatusBadge>(
    (subscription, operationStatus) => {
      if (
        operationStatus &&
        [OperationStatus.DRAFT, OperationStatus.VALIDATED].includes(operationStatus)
      ) {
        return { color: 'gray', label: t('NotStarted') };
      }
      if (!subscription) {
        return { color: 'gray', label: t('InvitationSent') };
      }
      if (subscription.investStatus === OperationInvestStatus.REFUSED) {
        return { color: 'red', label: t('Rejected') };
      }
      if (subscription.investStatus === OperationInvestStatus.DECLINED) {
        return { color: 'red', label: t('Rejected') };
      }
      if (subscription.investStatus === OperationInvestStatus.EXERCISED) {
        return { color: 'green', label: t('Exercised') };
      }
      if (operationStatus === OperationStatus.FINALIZED) {
        return { color: 'green', label: t('Finalized') };
      }
      if (subscription.investStatus === OperationInvestStatus.COMPLETED) {
        return { color: 'blue', label: t('Completed', { nb: 1 }) };
      }
      if (subscription.investStatus === OperationInvestStatus.APPROVED) {
        return { color: 'green', label: t('Validated') };
      }
      if (subscription.investStatus === OperationInvestStatus.FINALIZED) {
        return { color: 'green', label: t('Finalized') };
      }
      if (isSubscriptionStarted(subscription)) {
        return { color: 'yellow', label: t('InProgress') };
      }
      if (
        (subscription.operation?.type !== OperationType.SECONDARY &&
          subscription.buyerIdentity?.isSignedUp) ||
        (subscription.operation?.type === OperationType.SECONDARY &&
          subscription.sellerIdentity?.isSignedUp)
      ) {
        return { color: 'yellow', label: t('Registered') };
      }
      return { color: 'gray', label: t('InvitationSent') };
    },
    [t],
  );
}
