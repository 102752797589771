import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { UploadIcon } from '@heroicons/react/outline';
import { resolvePath, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

export function RepatriateOperationAlert(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const handleResumeOperationRepatriation = (): void => {
    navigate(
      '../../' +
        resolvePath(routes.company.newOperation.repatriation.href, routes.company.newOperation.href)
          .pathname,
      { relative: 'path' },
    );
  };

  return (
    <Alert boxShadow="md" status="info">
      <Stack
        alignItems="stretch"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing="4"
        width="full"
      >
        <HStack alignItems="flex-start" spacing="0">
          <AlertIcon as={UploadIcon} />
          <Stack spacing="0">
            <AlertTitle fontWeight="bold">{t('ExternalOperationQuestion')}</AlertTitle>
            <AlertDescription>{t('ExternalOperationQuestionDescription')}</AlertDescription>
          </Stack>
        </HStack>
        <HStack alignItems="center" ml={{ base: '0', md: '4' }}>
          <Button className="confirm" fontSize="sm" onClick={handleResumeOperationRepatriation}>
            {t('RepatriateExternalOperation')}
          </Button>
        </HStack>
      </Stack>
    </Alert>
  );
}
