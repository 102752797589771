import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  Divider,
  HStack,
} from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { useResetKycStatusMutation } from '@blockpulse3/graphql/hooks';
import { useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

type Props = {
  individualIdentityId: string;
  onClose: () => void;
  refetch: () => void;
} & Omit<AlertDialogProps, 'onClose' | 'children'>;

export function AdminIndividualsResetKycModal({
  individualIdentityId,
  onClose,
  refetch,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const [resetKycStatus, { loading }] = useResetKycStatusMutation();

  const handleResetKycStatus = (): void => {
    resetKycStatus({
      variables: {
        resetKycStatusInput: {
          individualIdentityId,
        },
      },
      onCompleted: () => {
        successToast({ title: t('KYCResetSuccessfully') });
        refetch();
        onClose();
      },
      onError: () => {
        errorToast({ title: t('KYCResetError') });
      },
    });
  };

  return (
    <AlertDialog onClose={onClose} {...props}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="xl" fontWeight="500">
            {t('ResetKycQuestion')}
          </AlertDialogHeader>
          <AlertDialogBody>{t('DefinitiveAction')}</AlertDialogBody>
          <Divider />
          <AlertDialogFooter as={HStack} spacing="4">
            <Button isDisabled={loading} variant="secondary" onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button isLoading={loading} variant="delete" onClick={handleResetKycStatus}>
              {t('ResetKYC')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export type DeleteConfirmModal = Props;
