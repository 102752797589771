import { Button, Skeleton, Stack, useDisclosure } from '@chakra-ui/react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  CreateSubscriptionInput,
  CreateSubscriptionMutation,
  FundraisingType,
  OperationStatus,
  UpdateSubscriptionMutation,
  useGetSubscriptionQuery,
  useGetSubscriptionsQuery,
} from '@blockpulse3/graphql/hooks';

import { OperationSubscribersModal } from '../../OperationSubscribersModal';
import { formatSubscription } from './utils';

export function SubscriptionPanelAdmin(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '', operationId = '' } = useParams();
  const navigate = useNavigate();

  const subscriptionModal = useDisclosure();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const { refetch: refetchSubscriptions } = useGetSubscriptionsQuery({
    variables: { operationId, first: 150 },
    skip: !operationId,
  });

  if (loading || !data || error) {
    return (
      <Stack px="4">
        <Skeleton h="80px" />
      </Stack>
    );
  }

  const handleSubscriberModalComplete = (
    data: CreateSubscriptionMutation | UpdateSubscriptionMutation,
    subscription: CreateSubscriptionInput,
    isLegal: boolean,
  ): void => {
    if ('updateSubscription' in data) {
      const newSubscriptionId = data.updateSubscription.id;

      // Refetch table data
      refetchSubscriptions();

      navigate(
        generatePath(routes.company.fundraising.subscription.href, {
          subscriptionId: newSubscriptionId,
        }),
      );
    }
  };

  const { subscription } = data;
  const formattedSubscription = formatSubscription(subscription);
  const isDisabled = [
    OperationStatus.CLOSED,
    OperationStatus.REVIEWED,
    OperationStatus.FINALIZED,
  ].includes(subscription.operation.status);

  return (
    <>
      <Stack px="4" spacing="2">
        <Button
          isDisabled={isDisabled}
          size="sm"
          variant="secondary"
          onClick={subscriptionModal.onOpen}
        >
          {t('ModifyTheSubscriber')}
        </Button>
      </Stack>
      {subscriptionModal.isOpen && (
        <OperationSubscribersModal
          amountRequired={subscription.operation.fundraising?.type === FundraisingType.PRIVATE}
          defaultValues={formattedSubscription}
          feesRequired={true}
          isOpen={subscriptionModal.isOpen}
          modalSubmitText={t('ModifyTheSubscriber')}
          modalTitle={t('ModifySubscriber')}
          operationId={operationId}
          subscriptionId={subscriptionId}
          amountRangeRequired={
            subscription.operation.fundraising?.type === FundraisingType.CROWDFUNDING
          }
          onClose={subscriptionModal.onClose}
          onComplete={handleSubscriberModalComplete}
        />
      )}
    </>
  );
}
