import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {
  CheckCircleIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  PlusIcon,
} from '@heroicons/react/outline';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  CompanyDocumentType,
  CreateUboInput,
  DocumentStatus,
  IdentityVerificationStatus,
  UboRole,
  UpdateUboInput,
  useCreateUboMutation,
  useDeleteCompanyDocumentMutation,
  useDeleteUboMutation,
  useGetCompanyQuery,
  useGetDocumentPdfUrlLazyQuery,
  useGetPappersExtractInpiDocumentQuery,
  useSearchSirenLazyQuery,
  useUpdateUboMutation,
} from '@blockpulse3/graphql/hooks';
import { isCompanyComplete } from '@blockpulse3/helpers';
import {
  CONTENT_LOADING,
  DropzoneInput,
  ErrorQueryCard,
  PreviewDocumentModal,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useManagedIndividual } from '@blockpulse3/web-client/auth';

import { useStepFormContext } from '../../../provider';
import { CompanyUboForm } from './CompanyUboForm';
import { ICompanyUboForm } from './types';
import { getCompanyUbos, getPappersUbos } from './utils';

type Props = {
  /* ** Is editing mode ** */
  isEditing?: boolean;
  /* ** Id of the targeted company ** */
  targetCompanyId?: string;
  /* ** Step cancelled callback ** */
  onCancel?: () => void;
  /* ** Step submitted callback ** */
  onSubmit?: () => void;
};

export function CompanyUbos({
  isEditing = false,
  targetCompanyId,
  onCancel = noop,
  onSubmit = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const [dropzoneFileName, setDropzoneFileName] = useState<{ [key: string]: File }>({});

  const [isPollingStarted, setIsPollingStarted] = useState<boolean>(false);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [documentPdfUrl, setDocumentPdfUrl] = useState<string | symbol | null>(CONTENT_LOADING);

  const { individual } = useManagedIndividual();
  const { companyId: urlCompanyId = '' } = useParams();

  const companyId = targetCompanyId || urlCompanyId;

  const previewModal = useDisclosure();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const [searchSiren, { data: pappersData, loading: pappersLoading }] = useSearchSirenLazyQuery();
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();
  const [deleteCompanyDocument] = useDeleteCompanyDocumentMutation();

  const [createUbo] = useCreateUboMutation();
  const [updateUbo] = useUpdateUboMutation();
  const [deleteUbo] = useDeleteUboMutation();

  const [hiddenPappersUbos, setHiddenPappersUbos] = useState<string[]>([]);

  const { data, loading, error, refetch } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
    onCompleted: (data) => {
      if (
        !isEditing &&
        data.company.kybVerificationStatus === IdentityVerificationStatus.APPROVED &&
        isCompanyComplete(data.company)
      ) {
        return;
      }

      // Auto-fill company data if not already filled
      if (!data.company?.ubos || data.company.ubos.length === 0) {
        const siren = data.company.registrationNumber || '';
        if (siren) {
          searchSiren({ variables: { siren } });
        }
      }
    },
  });

  const getPappersDocReq = useGetPappersExtractInpiDocumentQuery({
    variables: { companyId },
    fetchPolicy: 'network-only',
    skip: !companyId,
  });
  const {
    data: extractInpiData,
    loading: extractInpiLoading,
    refetch: refetchExtractInpi,
  } = getPappersDocReq;

  useEffect(() => {
    if (
      extractInpiData?.getPappersExtractINPIDocument?.document &&
      extractInpiData.getPappersExtractINPIDocument.document.status === DocumentStatus.SIGNED
    ) {
      if (isPollingStarted) {
        getPappersDocReq.stopPolling();
        setIsPollingStarted(false);
      }
      setDropzoneFileName({
        [extractInpiData.getPappersExtractINPIDocument.document.id]: new File(
          [extractInpiData.getPappersExtractINPIDocument.document.title],
          extractInpiData.getPappersExtractINPIDocument.document.title,
        ),
      });
    }
  }, [extractInpiData, getPappersDocReq, isPollingStarted]);

  useEffect(() => {
    if (!getPappersDocReq) return;

    if (
      !isPollingStarted &&
      !isFileLoading &&
      extractInpiData?.getPappersExtractINPIDocument?.document?.status === DocumentStatus.ONGOING
    ) {
      // Start polling on INPI extract if not completely downloaded from Pappers
      setIsPollingStarted(true);
      getPappersDocReq.startPolling(1000);

      setTimeout(() => {
        getPappersDocReq.stopPolling();
        setIsPollingStarted(false);
      }, 20_000);
    }
  }, [isPollingStarted, getPappersDocReq, extractInpiData, isFileLoading]);

  /* ** Existing company ubos ** */
  const companyUbos = useMemo(() => getCompanyUbos(data?.company), [data?.company]);
  /* ** Filtered Pappers ubos ** */
  const pappersUbos = useMemo(
    () =>
      getPappersUbos(pappersData?.searchSIREN).filter((ubo) => !hiddenPappersUbos.includes(ubo.id)),
    [pappersData?.searchSIREN, hiddenPappersUbos],
  );
  /* ** Merge ubos ** */
  const ubos = [...companyUbos, ...pappersUbos];
  /* ** Look for user ubo in list ** */
  const isUserSwitchDisabled = ubos.some((ubo) => ubo.individualIdentityId === individual?.id);
  /* ** Compute remaining sharePercentage, to block user on submit ** */
  const maxSharePercentage = 100 - companyUbos.reduce((acc, cur) => cur.sharePercentage + acc, 0);

  const handleNewUboForm = (): void => {
    onToggle();
  };

  const handleCancelUboForm = (): void => {
    onClose();
  };

  const handleHideUboForm = (id: string): void => {
    setHiddenPappersUbos((prev) => [...prev, id]);
  };

  const handleCreateUbo = (data: ICompanyUboForm): void => {
    const hasCapital = data.role !== UboRole.LegalRepresentative;

    const createUboInput: CreateUboInput = {
      firstName: data.firstName,
      lastName: data.lastName,
      birthplace: data.birthplace,
      birthCityPostalCode: data.birthCityPostalCode,
      birthCountry: data.birthCountry.value,
      birthdate: data.birthdate,
      role: data.role,
      companyId,
      sharePercentage: hasCapital ? data.sharePercentage : 0,
      isDirect: hasCapital ? data.isDirect : false,
      individualIdentityId: data.individualIdentityId,
      nationality: data.nationality?.value,
      address: {
        ...data.address,
        country: data.address.country.value,
      },
    };
    createUbo({
      variables: { createUboInput },
      onCompleted: () => {
        onClose();
        refetch();
        /* ** Hide form if data.id is specified ie. Pappers form ** */
        handleHideUboForm(data.id);
        successToast({ title: t('OwnerAdded') });
      },
      onError: () => {
        errorToast({ title: t('ErrorCreatingOwner') });
      },
    });
  };

  const handleUpdateUbo = (data: ICompanyUboForm): void => {
    const hasCapital = data.role !== UboRole.LegalRepresentative;

    const updateUboInput: UpdateUboInput = {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      birthplace: data.birthplace,
      birthCityPostalCode: data.birthCityPostalCode,
      birthCountry: data.birthCountry.value,
      birthdate: data.birthdate,
      role: data.role,
      companyId,
      sharePercentage: hasCapital ? data.sharePercentage : 0,
      isDirect: hasCapital ? data.isDirect : false,
      individualIdentityId: data.individualIdentityId,
      nationality: data.nationality?.value,
      address: {
        ...data.address,
        country: data.address.country.value,
      },
    };

    updateUbo({
      variables: { updateUboInput },
      onCompleted: () => {
        onClose();
        refetch();
        successToast({ title: t('OwnerModified') });
      },
      onError: () => {
        errorToast({ title: t('ErrorModifyingOwner') });
      },
    });
  };

  const handleDeleteUbo = (id: ICompanyUboForm['id']): void => {
    if (!id) {
      return;
    }
    deleteUbo({
      variables: { deleteUboInput: { companyId, uboId: id } },
      onCompleted: () => {
        refetch();
        successToast({ title: t('OwnerDeleted') });
      },
      onError: () => {
        errorToast({ title: t('ErrorDeletingOwner') });
      },
    });
  };

  const { setCancelHandler, setSubmitHandler } = useStepFormContext();

  const handleStepSubmit = useCallback((): void => {
    if (companyUbos.length === 0) {
      errorToast({ title: t('AddAtLeastOneOwner') });
    } else {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUbos.length, onSubmit]);

  const handleStepCancel = useCallback((): void => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    setSubmitHandler(handleStepSubmit);
    setCancelHandler(handleStepCancel);
  }, [handleStepSubmit, handleStepCancel, setSubmitHandler, setCancelHandler]);

  const handleFileDelete = (): void => {
    if (!companyId) return;

    deleteCompanyDocument({
      variables: {
        deleteCompanyDocumentInput: {
          companyId,
          documentType: CompanyDocumentType.DBE,
        },
      },
      onCompleted: () => {
        setDocumentPdfUrl(null);
        setDropzoneFileName({});
        successToast({ title: t('DocumentDeleted') });
      },
      onError: () => {
        errorToast({ title: t('DocumentDeletionError') });
      },
    });
  };

  const handleFileUpload = async (acceptedFile: File): Promise<void> => {
    if (!companyId) return;

    setIsFileLoading(true);

    const formData = new FormData();
    formData.append('companyId', companyId);
    formData.append('documentType', CompanyDocumentType.DBE);
    formData.append('document', acceptedFile);
    await axios.post(
      process.env['NX_API_CONTROLLER_ENDPOINT'] + '/companies/uploadCompanyDocument',
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    await refetchExtractInpi();
    setIsFileLoading(false);
  };

  const handleFileDrop = (acceptedFiles: File[]): void => {
    if (acceptedFiles[0]) {
      handleFileUpload(acceptedFiles[0]);
    }
  };

  const handleFilePreview = async (): Promise<void> => {
    if (!companyId) return;
    setDocumentPdfUrl(CONTENT_LOADING);
    previewModal.onOpen();

    if (extractInpiData?.getPappersExtractINPIDocument?.document) {
      getDocumentPdfUrl({
        variables: {
          documentId: extractInpiData.getPappersExtractINPIDocument.document.id,
        },
        fetchPolicy: 'no-cache',
        onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
          setDocumentPdfUrl(pdfUrl);
        },
      });
    }
  };

  if (loading || pappersLoading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (error) {
    return <ErrorQueryCard />;
  }

  if (!data) {
    return <ErrorQueryCard />;
  }

  const companyName = data.company.name;

  const disableAccordion =
    data.company.kybVerificationStatus === IdentityVerificationStatus.APPROVED;

  const sx = disableAccordion
    ? {
        opacity: 0.6,
        cursor: 'not-allowed',
      }
    : {};

  return (
    <>
      <Stack>
        <Text fontWeight={500}>{t('OwnersDistributionProof')}</Text>
        <DropzoneInput
          isPreviewEnabled
          accept={{ 'application/pdf': [] }}
          files={dropzoneFileName}
          icon={<Icon as={DocumentTextIcon} boxSize="42px" color="gray.400" strokeWidth="1px" />}
          isLoading={extractInpiLoading || isPollingStarted || isFileLoading}
          maxFiles={1}
          subTitle=".pdf"
          onDelete={handleFileDelete}
          onDrop={handleFileDrop}
          onPreview={handleFilePreview}
        />
      </Stack>
      {pappersUbos.length > 0 && (
        <Alert flexShrink="0" status="info" sx={sx}>
          <AlertIcon />
          <Stack spacing="1">
            <AlertTitle>{t('OwnersInfoRecovery', { companyName })}</AlertTitle>
          </Stack>
        </Alert>
      )}
      {companyUbos.map((ubo) => (
        <Accordion key={ubo.id} allowToggle index={disableAccordion ? -1 : undefined} sx={sx}>
          <AccordionItem mt="0">
            <AccordionButton>
              <Tooltip hasArrow label={t('ValidatedRecipient')} placement="top">
                <Icon as={CheckCircleIcon} boxSize="30" color="green.500" />
              </Tooltip>
              <Box flex="1" p="2" textAlign="left">
                <Heading fontSize="lg">{t('Owner', { nb: 1 })}</Heading>
                <Text>
                  {ubo.firstName} {ubo.lastName}
                </Text>
              </Box>
              <AccordionIcon boxSize="8" />
            </AccordionButton>
            <AccordionPanel>
              <CompanyUboForm
                defaultValues={ubo}
                isUserSwitchDisabled={true}
                maxSharePercentage={maxSharePercentage + ubo.sharePercentage}
                onCancel={(): void => handleDeleteUbo(ubo.id)}
                onSubmit={handleUpdateUbo}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}
      {pappersUbos.map((ubo) => (
        <Accordion key={ubo.id} allowToggle index={disableAccordion ? -1 : undefined} sx={sx}>
          <AccordionItem mt="0">
            <AccordionButton>
              <Tooltip hasArrow label={t('IncompleteBeneficiaryInformation')} placement="top">
                <Icon as={InformationCircleIcon} boxSize="30" color="blue.400" />
              </Tooltip>
              <Box flex="1" p="2" textAlign="left">
                <Heading fontSize="lg">{t('OwnerRecovered')}</Heading>
                <Text>
                  {ubo.firstName} {ubo.lastName}
                </Text>
              </Box>
              <AccordionIcon boxSize="8" />
            </AccordionButton>
            <AccordionPanel>
              <CompanyUboForm
                defaultValues={ubo}
                isUserSwitchDisabled={true}
                maxSharePercentage={maxSharePercentage}
                onCancel={(): void => handleHideUboForm(ubo.id)}
                onSubmit={handleCreateUbo}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}
      {(isOpen || ubos.length === 0) && (
        <Accordion allowToggle defaultIndex={[0]} index={disableAccordion ? -1 : undefined} sx={sx}>
          <AccordionItem mt="0">
            <AccordionButton>
              <Tooltip hasArrow label={t('IncompleteBeneficiaryInformation')} placement="top">
                <Icon as={ExclamationCircleIcon} boxSize="30" color="yellow.400" />
              </Tooltip>
              <Box flex="1" p="2" textAlign="left">
                <Heading fontSize="lg">{t('NewOwner')}</Heading>
              </Box>
              <AccordionIcon boxSize="8" />
            </AccordionButton>
            <AccordionPanel>
              <CompanyUboForm
                isUserSwitchDisabled={isUserSwitchDisabled}
                maxSharePercentage={maxSharePercentage}
                onCancel={handleCancelUboForm}
                onSubmit={handleCreateUbo}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
      <Button isDisabled={isOpen || ubos.length === 0} variant="light" onClick={handleNewUboForm}>
        <Icon as={PlusIcon} mr="1" /> {t('AddOwner')}
      </Button>
      <PreviewDocumentModal
        isOpen={previewModal.isOpen}
        src={documentPdfUrl}
        title={t('OwnersDistributionProof')}
        onClose={previewModal.onClose}
      />
    </>
  );
}

export type CompanyUbosProps = Props;
