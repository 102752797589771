import { Icon, Stack, Text } from '@chakra-ui/react';
import { DocumentIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

type Props = unknown;

/**
 * DocumentTableEmpty.
 *
 * @returns {JSX.Element}
 */
export function DocumentTableEmpty(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack layerStyle="emptyState">
      <Icon as={DocumentIcon} boxSize="40px" color="gray.500" />
      <Text>{t('NoDocumentsYet')}</Text>
    </Stack>
  );
}

export type DocumentTableEmptyProps = Props;
