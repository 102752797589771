import {
  Box,
  Card,
  CardBody,
  Circle,
  HStack,
  Progress,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetAssetQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberInt, formatNumberPercentage } from '@blockpulse3/helpers';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

type Props = unknown;

export function OptionSummaryProgression(): JSX.Element | null {
  const t = useTranslations();

  const { assetId = '', companyId = '' } = useParams();

  const { data, loading, error } = useGetAssetQuery({
    variables: {
      assetId,
      companyId,
    },
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="200px" />;
  }

  if (!data) {
    return <ErrorQueryCard h="200px" />;
  }

  const option = data.asset;
  if (
    !option ||
    option.token?.__typename !== 'OptionTokenModel' ||
    option.tokenData?.__typename !== 'OptionTokenModelData'
  ) {
    return null;
  }

  const optionData = option.tokenData;

  return (
    <Card>
      <CardBody as={Stack} spacing="4">
        <Stack spacing="4">
          <Stack>
            <HStack spacing="3">
              <HStack spacing="2">
                <Circle background="indigo.500" size="6px" />
                <Text>
                  {t('ExercisedCount', {
                    nb: formatNumberPercentage(optionData.exercisedPercentage),
                  })}
                </Text>
              </HStack>
              <HStack spacing="2">
                <Circle background="indigo.300" size="6px" />
                <Text>
                  {t('ExercisableCount', {
                    nb: formatNumberPercentage(optionData.totalVestedBalancePercentage),
                  })}
                </Text>
              </HStack>
              <HStack spacing="2">
                <Circle background="gray.300" size="6px" />
                <Text>
                  {t('AssignedCount', { nb: formatNumberPercentage(optionData.grantedPercentage) })}
                </Text>
              </HStack>
            </HStack>
            <Box height="12px" position="relative">
              <Progress
                bg="transparent"
                height="full"
                position="absolute"
                rounded="md"
                sx={{ '& > div': { backgroundColor: 'indigo.500' } }}
                top="0"
                value={optionData.exercisedPercentage}
                width="full"
                zIndex="3"
              />
              <Progress
                bg="transparent"
                height="full"
                position="absolute"
                rounded="md"
                sx={{ '& > div': { backgroundColor: 'indigo.300' } }}
                top="0"
                value={optionData.totalVestedBalancePercentage}
                width="full"
                zIndex="2"
              />
              <Progress
                height="full"
                position="absolute"
                rounded="md"
                sx={{ '& > div': { backgroundColor: 'gray.300' } }}
                top="0"
                value={optionData.grantedPercentage}
                width="full"
                zIndex="1"
              />
            </Box>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '8' }}>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('OptionTotal')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberInt(option.token.initialSupply)}
              </Text>
            </Box>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('UnallocatedOptions')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberInt(option.token.remainingSupply)}
              </Text>
            </Box>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('CapitalPercentage')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberPercentage(optionData.shareCapitalPercentage)}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
}

export type OptionSummaryProgressionProps = Props;
