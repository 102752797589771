import {
  OperationInvestStatus,
  SubscriptionTab,
  SubscriptionsTabsStatus,
} from '@blockpulse3/data/shared';
import {
  OperationStatus,
  OptionTokenModel,
  SubscriptionShortInfosFragment,
  SubscriptionsCountInfosFragment,
} from '@blockpulse3/graphql/hooks';
import { getOptionTokenGrantStatus } from '@blockpulse3/helpers';
import { IBadge, useGrantBadge, useInvestStatusBadge } from '@blockpulse3/ui/ui-hooks';

export function getTabsWithCount(count?: SubscriptionsCountInfosFragment): SubscriptionTab[] {
  return [
    {
      name: SubscriptionsTabsStatus.ALL_STATUS,
      count: count?.total || 0,
    },
    {
      name: SubscriptionsTabsStatus.NOT_SIGNED_UP,
      count: count?.invitationSent || 0,
    },
    {
      name: SubscriptionsTabsStatus.SIGNED_UP,
      count: count?.signedUp || 0,
    },
    {
      name: SubscriptionsTabsStatus.PENDING,
      count: count?.pending || 0,
    },
    {
      name: SubscriptionsTabsStatus.COMPLETED,
      count: count?.completed || 0,
    },
    {
      name: SubscriptionsTabsStatus.VESTING,
      count: count?.approved || 0,
    },
    {
      name: SubscriptionsTabsStatus.EXERCISED,
      count: count?.exercised || 0,
    },
  ].filter((tab) => tab) as SubscriptionTab[];
}

export const useBadgeForSubscriber = (
  subscriber: SubscriptionShortInfosFragment,
  optionToken: OptionTokenModel | undefined,
  operationStatus: OperationStatus,
): IBadge => {
  const investStatusBadge = useInvestStatusBadge();
  const { getBadge: getGrantBadge } = useGrantBadge();

  if (
    subscriber.investStatus === OperationInvestStatus.APPROVED &&
    optionToken?.__typename === 'OptionTokenModel' &&
    subscriber?.grant
  ) {
    return getGrantBadge(getOptionTokenGrantStatus(optionToken, subscriber.grant));
  }
  return investStatusBadge(subscriber, operationStatus);
};
