import { IdentityType } from '@blockpulse3/graphql/hooks';

import { IRepresentativeForm } from '../types';

/**
 * representantTitle.
 * Function to return the "title" of the form.
 * For a legal one, it's the company name (.name) and firstName and lastName for a
 * natural one.
 *
 * @param {IRepresentativeForm} rep
 * @returns {string}
 */
export function representantTitle(rep: IRepresentativeForm): string {
  if (rep.type === IdentityType.COMPANY) {
    return rep.name || '';
  }
  return `${rep.firstName} ${rep.lastName}`;
}
