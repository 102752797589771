import * as yup from 'yup';

import {
  requiredEmailField,
  requiredNumberField,
  requiredStringField,
} from '@blockpulse3/data/shared';

import { INewSecondaryOperationLegalBuyer } from '../NewSecondaryOperationLegalBuyerForm';

export const schema = yup.object({
  email: requiredEmailField,
  name: requiredStringField,
  registrationNumber: requiredStringField,
  investAmount: yup
    .number()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
    .when('$amountRequired', {
      is: true,
      then: () => requiredNumberField,
    }),
}) as yup.ObjectSchema<INewSecondaryOperationLegalBuyer>;
