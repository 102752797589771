import { Route, Routes } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { IdentityContextProvider } from '@blockpulse3/web-client/auth';

import { CompanyWorkspaceRoutes } from './CompanyWorkspaceRoutes';
import { OnboardingCompanyRoutes } from './OnboardingCompanyRoutes';
import { OnboardingSPVRoutes } from './OnboardingSPVRoutes';
import { UserWorkspaceRoutes } from './UserWorkspaceRoutes';

type Props = unknown;

/**
 * SpaceRoutes.
 * Group sub route components when it's possible.
 *
 * @returns {JSX.Element}
 */
export function ManageRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<IdentityContextProvider />}>
        <Route
          element={<OnboardingCompanyRoutes />}
          path={routes.onboardings.company.href + '/*'}
        />
        <Route element={<OnboardingSPVRoutes />} path={routes.onboardings.spv.href + '/*'} />
        <Route path={routes.manage.individual.href + '/*'}>
          <Route element={<CompanyWorkspaceRoutes />} path={routes.manage.company.href + '/*'} />
          <Route element={<UserWorkspaceRoutes />} path="*" />
        </Route>
        <Route element={<CompanyWorkspaceRoutes />} path={routes.manage.company.href + '/*'} />
      </Route>
    </Routes>
  );
}

export type SProps = Props;
