import { Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { ManagementType } from '@blockpulse3/graphql/hooks';

import { SpaceHeader } from './SpaceHeader';
import { SpaceManagements } from './SpaceManagements';

type Props = unknown;

export function SpaceControlsView(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack spacing="6">
      <Stack spacing="4">
        <SpaceHeader />
        <Heading size="lg">{t('ControlledCompanies')}</Heading>
      </Stack>
      <SpaceManagements type={ManagementType.OWNER} />
    </Stack>
  );
}

export type SpaceControlsViewProps = Props;
