import { ChakraProvider, ThemeOverride } from '@chakra-ui/react';
import { useContext, useMemo, useState } from 'react';

import { ThemeContext, initTheme } from './context';

type Props = {
  children: React.ReactNode;
};

export function ThemeContextProvider({ children }: Props): JSX.Element {
  const [theme, setTheme] = useState(initTheme.theme);

  const value = useMemo(() => ({ theme, setTheme }), [theme]);

  return (
    <ThemeContext.Provider value={value}>
      <ChakraProvider theme={value.theme}>
        {children}
      </ChakraProvider>
    </ThemeContext.Provider>
  );
}

export function useTheme(): ThemeOverride {
  return useContext(ThemeContext);
}
