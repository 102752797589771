import { Button, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

import { OperationRowsCurrent } from './OperationRows/OperationRowsCurrent';
import { OperationRowDisplayType } from './OperationRows/types';

type Props = {
  displayType: OperationRowDisplayType;
  limit?: number;
  isRestrictedToOpportunities?: boolean;
};

export function OperationRowsPreview({
  displayType,
  limit,
  isRestrictedToOpportunities = false,
}: Props): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const handleSeeAllClick = (): void => {
    navigate(routes.company.operations.href);
  };

  return (
    <Stack spacing="4">
      <OperationRowsCurrent
        displayType={displayType}
        isRestrictedToOpportunities={isRestrictedToOpportunities}
        limit={limit}
      />
      {!isRestrictedToOpportunities && (
        <Button variant="link" onClick={handleSeeAllClick}>
          {t('SeeAllOperations')}
        </Button>
      )}
    </Stack>
  );
}
