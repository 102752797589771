import { useMatch, useParams } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { CompanyStatus, CompanyType, useGetCompanyQuery } from '@blockpulse3/graphql/hooks';

import { SettingsNavCompany } from './SettingsNavCompany';
import { SettingsNavSPV } from './SettingsNavSPV';
import { SettingsNavSpace } from './SettingsNavSpace';
import { SettingsNavUser } from './SettingsNavUser';

type Props = unknown;

/**
 * SettingsNav.
 * Renders navigation items on the Sidebar depending on sub route contexts.
 *
 * @returns {JSX.Element}
 */
export function SettingsNav(): JSX.Element {
  const { companyId = '' } = useParams();
  const { data } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = data?.company;

  /* ** User nav ** */
  const isUserNav = useMatch(routes.me.href + '/*');
  /* ** Space nav ** */
  const isSpaceNav = useMatch(routes.space.href + '/*');
  /* ** SPV nav ** */
  const isSpvNav = company?.type === CompanyType.SPV && company.status !== CompanyStatus.RUNNING;
  /* ** Individual management ** */
  const isIndividualManagement = useMatch(routes.manage.individual.full + '/*');
  /* ** Company management ** */
  const isIndividualCompanyManagement = useMatch(
    routes.manage.individual.full + routes.company.href + '/*',
  );

  if (isSpaceNav) return <SettingsNavSpace />;
  if (isIndividualCompanyManagement) return <SettingsNavCompany />;
  if (isUserNav || isIndividualManagement) return <SettingsNavUser />;
  if (isSpvNav) return <SettingsNavSPV />;

  return <SettingsNavCompany />;
}

export type SettingsNavProps = Props;
