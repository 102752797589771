import { BoxProps, Flex, HStack, IconButton, Stack, useBreakpointValue } from '@chakra-ui/react';
import { TrashIcon, XIcon } from '@heroicons/react/outline';

import { noop } from '@blockpulse3/data/shared';

import { STEP_HEADER_HEIGHT_BASE, STEP_HEADER_HEIGHT_MD } from './constants';

type Props = {
  /* ** Show delete button ** */
  showDelete?: boolean;
  /* ** Callback on delete icon ** */
  onDelete?: () => void;
  /* ** Callback on close funel ** */
  onClose: () => void;
  /* ** Titles and subtitles ** */
  children: React.ReactNode;
} & BoxProps;

/**
 * StepHeader.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function StepHeader({
  showDelete = true,
  onDelete = noop,
  onClose,
  children,
  ...props
}: Props): JSX.Element {
  const height = useBreakpointValue({ base: STEP_HEADER_HEIGHT_BASE, md: STEP_HEADER_HEIGHT_MD });

  const handleClose = (): void => {
    onClose();
  };

  const handleDelete = (): void => {
    onDelete();
  };

  return (
    <HStack background="white" height={height} position="sticky" top="0" zIndex="2" {...props}>
      <Flex flexBasis={{ base: 0, lg: 200 }} />
      <Stack spacing="2">{children}</Stack>
      <HStack flexGrow="1" justifyContent="flex-end">
        {showDelete && (
          <IconButton
            _hover={{ color: 'red' }}
            aria-label="delete"
            icon={<TrashIcon height="20" width="20" />}
            variant="secondary"
            onClick={handleDelete}
          />
        )}
        <IconButton
          aria-label="close modal"
          icon={<XIcon height="20" width="20" />}
          variant="secondary"
          onClick={handleClose}
        />
      </HStack>
    </HStack>
  );
}

export type StepHeaderProps = Props;
