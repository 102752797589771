import { BrandName, brandConfigs } from '@blockpulse3/data/shared';
import { SpaceEntityInfosFragment, SpaceType } from '@blockpulse3/graphql/hooks';
import { capitalizeFirstLetter } from '@blockpulse3/helpers';

import { spaceFormDefaultValues } from './schema';
import { SpaceForm } from './types';

/* ** first variable default ** */
export const PAGE_SIZE = 10;

/**
 * getSpace.
 * Format a fetched Space entity into its form version.
 *
 * @param {SpaceEntityInfosFragment | undefined} data
 * @returns {SpaceForm}
 */
export function getSpace(data: SpaceEntityInfosFragment | undefined): SpaceForm {
  if (!data) {
    return spaceFormDefaultValues;
  }

  return {
    name: data.name,
    type: { label: data.type, value: data.type },
    brand: { label: capitalizeFirstLetter(data.brand), value: data.brand as BrandName },
    /* ** no need for owner fetching now ** */
    owner: spaceFormDefaultValues.owner,
  };
}

/**
 * getBrandOptions.
 * Format brand config export to retrieve an option brand list.
 *
 * @returns {SpaceForm['brand'][]}
 */
export function getBrandOptions(): SpaceForm['brand'][] {
  const brands = Object.keys(brandConfigs) as BrandName[];

  return brands.map((brand) => ({ label: capitalizeFirstLetter(brand), value: brand }));
}

/**
 * getSpaceTypeOptions.
 * Format SpaceType enum into select options.
 *
 * @returns {SpaceForm['type'][]}
 */
export function getSpaceTypeOptions(hasProvider = false): SpaceForm['type'][] {
  const excludedTypes = [SpaceType.GLOBAL];
  if (hasProvider) {
    excludedTypes.push(SpaceType.PROVIDER);
  }

  return Object.values(SpaceType)
    .filter((type) => !excludedTypes.includes(type))
    .map((type) => ({
      label: capitalizeFirstLetter(type),
      value: type,
    }));
}
