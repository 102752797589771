import { AssetEntityInfosFragment } from '@blockpulse3/graphql/hooks';

import { defaultValues } from './schema';
import { IStockAsset } from './types';

export function getStockAssetDefaultValues(asset: AssetEntityInfosFragment): IStockAsset {
  return {
    name: asset.name,
    nominalValue: asset.nominalValue ? asset.nominalValue : defaultValues.nominalValue,
  };
}
