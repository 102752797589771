import { Button } from '@chakra-ui/react';
import axios from 'axios';
import { ParseError } from 'papaparse';
import { useTranslations } from 'use-intl';

import { downloadCsv } from '@blockpulse3/helpers';

type Props = {
  path: string;
};

/**
 * DownloadCsvTemplate.
 * Render a button to download a CSV template.
 *
 * @param {string} path
 * @returns {JSX.Element}
 */
export function DownloadCsvTemplate({ path }: Props): JSX.Element {
  const t = useTranslations();

  const handleDownload = async (): Promise<void> => {
    const filename = path.split('/').pop() || 'download.csv';
    try {
      const response = await axios.get(path, { responseType: 'text' });
      await downloadCsv(response.data, filename);
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        const error = err?.response?.data as ParseError;
        throw new Error(`Error downloading CSV: ${error.message}`);
      }
    }
  };

  return (
    <Button color="info" fontSize="sm" fontWeight="500" variant="link" onClick={handleDownload}>
      {t('DownloadExampleArrow')}
    </Button>
  );
}
