import { Skeleton } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { noop } from '@blockpulse3/data/shared';
import { useGetSpvQuery, useUpdateCompanyMutation } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { SPVFinancialInformationsForm } from './SPVFinancialInformationsForm';
import { FinancialInformationsForm } from './types';
import { getFinancialInformations } from './utils';

type Props = {
  /* ** Step submitted callback ** */
  onSubmit: () => void;
  /* ** Step cancelled callback ** */
  onCancel: () => void;
};

/**
 * FinancialInformations.
 * Step that renders the form for the financial informatinos of the SPV.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SPVFinancialInformations({ onSubmit = noop, onCancel = noop }: Props): JSX.Element {
  const { companyId = '' } = useParams();

  const [updateCompany] = useUpdateCompanyMutation();

  const { data, loading, error, refetch } = useGetSpvQuery({ variables: { companyId } });

  if (loading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (error || !data) {
    return <ErrorQueryCard width="full" />;
  }

  const defaultValues = getFinancialInformations(data.company);

  const handleStepSubmit = (data: FinancialInformationsForm): void => {
    const { preferredSharesPercentage, ...rest } = data;

    updateCompany({
      variables: {
        updateCompanyInput: {
          companyId,
          preferredSharesPercentage: data.carriedInterest > 0 ? preferredSharesPercentage : 0,
          ...rest,
        },
      },
      onCompleted: async () => {
        await refetch();
        onSubmit();
      },
    });
  };

  return (
    <SPVFinancialInformationsForm
      defaultValues={defaultValues}
      onCancel={onCancel}
      onSubmit={handleStepSubmit}
    />
  );
}
