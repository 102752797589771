import { Badge, Tab, TabList, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { SubscriptionTab } from '@blockpulse3/data/shared';

type Props = {
  tabs: SubscriptionTab[];
  handleTabChange: (index: number) => void;
};

/**
 * OpportunityOperationIntentsTabs.
 * Tab list of opportunity operation view.
 *
 * @returns {JSX.Element}
 */
export function OpportunityOperationIntentsTabs({ tabs, handleTabChange }: Props): JSX.Element {
  const i18nSubscriptionsTabsStatus = useTranslations('SubscriptionsTabsStatusValues');

  return (
    <Tabs defaultIndex={0} onChange={handleTabChange}>
      <TabList>
        {tabs.map((tab, i) => (
          <Tab key={i}>
            <Text>{i18nSubscriptionsTabsStatus(tab.name)}</Text>
            <Badge ml="2">{tab.count}</Badge>
          </Tab>
        ))}
      </TabList>
      <TabPanels />
    </Tabs>
  );
}
