import { Stack } from '@chakra-ui/react';

import { OptionSummaryProgression } from './OptionSummaryProgression';
import { OptionSummarySubscribers } from './OptionSummarySubscribers';

type Props = unknown;

export function OptionSummary(): JSX.Element {
  return (
    <Stack spacing="6">
      <OptionSummaryProgression />
      <OptionSummarySubscribers />
    </Stack>
  );
}

export type OptionSummaryProps = Props;
