import { HStack, Stack, Text } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { TransferSentData } from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';

type Props = {
  data: TransferSentData;
  date: Date;
};

export function ActivityTransferSent({ date, data }: Props): JSX.Element {
  const t = useTranslations();

  const { amount, creditorName, label, reference, validatorName } = data;

  return (
    <Stack spacing={1}>
      <HStack justifyContent="space-between" spacing="2">
        <Text fontSize="sm">
          {t.rich('RefundSentActivity', {
            amount: formatNumberCurrency(amount),
            important: (chunks) => (
              <Text as="span" fontWeight="600">
                {chunks}
              </Text>
            ),
          })}
        </Text>
      </HStack>
      <Stack color="gray.500" spacing="2">
        <Stack bg="gray.50" paddingX="3" paddingY="2" rounded="md" spacing="1">
          {creditorName && (
            <HStack spacing="2">
              <Text>{t('Recipient', { nb: 1 })}</Text>
              <Text fontWeight="600">{creditorName}</Text>
            </HStack>
          )}
          <HStack spacing="2">
            <Text>{t('Label', { nb: 1 })}</Text>
            <Text fontWeight="600">{label || ' - '}</Text>
          </HStack>
          <HStack spacing="2">
            <Text>{t('Reference', { nb: 1 })}</Text>
            <Text fontWeight="600">{reference || ' - '}</Text>
          </HStack>
        </Stack>
        <Text>
          {formatDate(date, 'DD/MM/YYYY')}
          {validatorName && <> &middot; {validatorName}</>}
        </Text>
      </Stack>
    </Stack>
  );
}

export type ActivityTransferSentProps = Props;
