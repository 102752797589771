import { HStack, Stack, Text } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { InvestAmountReviewedData } from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';

type Props = {
  data: InvestAmountReviewedData;
  date: Date;
};

export function ActivityInvestAmountReviewed({ date, data }: Props): JSX.Element {
  const t = useTranslations();

  const { previousAmount, newAmount, validatorName } = data;

  return (
    <Stack spacing={1}>
      <Text fontSize="sm">
        {t.rich('SubscriptionAmountRevisedTo', {
          amount: formatNumberCurrency(newAmount),
          important: (chunks) => (
            <Text as="span" fontWeight="600">
              {chunks}
            </Text>
          ),
        })}
      </Text>
      <Stack color="gray.500" spacing={1}>
        <HStack bg="gray.50" paddingX="3" paddingY="2" rounded="md" spacing="2">
          <Text>{t('PreviousAmount')}</Text>
          <Text fontWeight="600">{formatNumberCurrency(previousAmount)}</Text>
        </HStack>
        <Text>
          {formatDate(date, 'DD/MM/YYYY')}
          {validatorName && <> &middot; {validatorName}</>}
        </Text>
      </Stack>
    </Stack>
  );
}

export type ActivityInvestAmountReviewedProps = Props;
