import {
  OperationDocumentInfosFragment,
  OperationDocumentType,
  OperationStatus,
} from '@blockpulse3/graphql/hooks';

type OperationDocumentRecord = Record<
  OperationDocumentType | string,
  OperationDocumentInfosFragment
>;

/**
 * getFundraisingProgressionStep.
 * Return current fundraising progression step.
 *
 * @param {OperationStatus} status
 * @returns {number}
 */
export function getFundraisingProgressionStep(status: OperationStatus): number {
  switch (status) {
    case OperationStatus.DRAFT:
    case OperationStatus.VALIDATED:
      return 1;
    case OperationStatus.STARTED:
      return 2;
    case OperationStatus.CLOSED:
    case OperationStatus.REVIEWED:
      return 3;
    case OperationStatus.FINALIZED:
      return 4;
    default:
      return 1;
  }
}

/**
 * getFundraisingDocuments.
 *
 * @param {Array} documents
 * @returns {OperationDocumentRecord}
 */
export function getFundraisingDocuments(
  documents: OperationDocumentInfosFragment[],
): OperationDocumentRecord {
  const FundraisingDocumentTypesToSign: string[] = [
    OperationDocumentType.PV_EXERCICE,
    OperationDocumentType.PV_EXERCICE_BOND,
    OperationDocumentType.PV_CONSTATATION,
    OperationDocumentType.PV_CONSTATATION_BOND,
  ];
  const docs: Record<OperationDocumentType | string, OperationDocumentInfosFragment> = {};
  documents.forEach((doc) => {
    if (FundraisingDocumentTypesToSign.includes(doc?.type)) {
      docs[doc?.type] = doc;
    }
  });
  return docs;
}
