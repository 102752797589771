import { Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { RepresentativeRole, useGetSpvQuery } from '@blockpulse3/graphql/hooks';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';

/**
 * SummaryRoleInfos.
 * Summary of the role for the SPV funnel.
 *
 * @returns {JSX.Element}
 */
export function SummaryRoleInfos(): JSX.Element {
  const t = useTranslations();
  const i18nRoleValues = useTranslations('RoleValues');

  const { companyId = '' } = useParams();

  const { data } = useGetSpvQuery({ variables: { companyId } });

  const representatives = data?.company.companyRepresentative || [];

  const presidents = representatives.filter((rep) => {
    return [RepresentativeRole.PRESIDENT, RepresentativeRole.COMPANY_MANAGER].includes(rep.role);
  });

  const notCreatorOrViewerIdentityIds = representatives
    .filter((rep) => ![RepresentativeRole.CREATOR, RepresentativeRole.VIEWER].includes(rep.role))
    .map((rep) => rep.representativeIdentity.id);

  const otherRoles = representatives.filter((rep, i) => {
    return (
      ![RepresentativeRole.PRESIDENT, RepresentativeRole.COMPANY_MANAGER].includes(rep.role) &&
      !(
        [RepresentativeRole.CREATOR, RepresentativeRole.VIEWER].includes(rep.role) &&
        notCreatorOrViewerIdentityIds.some((id) => id === rep.representativeIdentity.id)
      )
    );
  });

  return (
    <Stack spacing="4">
      <Text fontSize="xl" fontWeight="bold">
        {t('RolesAndAccess')}
      </Text>
      {presidents.map((president) => {
        const presidentIdentity = president.representativeIdentity;
        const presidentSubTitle = presidentIdentity.name;

        return (
          <IdentityCard key={president.id} layerStyle="border" p="4" rounded="md">
            <IdentityAvatar identity={presidentIdentity} />
            <IdentityCardTitle fontSize="lg">
              <Text fontWeight="600">{i18nRoleValues(RepresentativeRole.PRESIDENT)}</Text>
            </IdentityCardTitle>
            <IdentityCardDescription color="gray.800" fontSize="md">
              {presidentSubTitle}
            </IdentityCardDescription>
          </IdentityCard>
        );
      })}
      {otherRoles.map((otherRole) => {
        const roleIdentity = otherRole.representativeIdentity;
        const roleSubTitle = roleIdentity.name;

        // if role is CREATOR display in front "Collaborateur"
        const roleTitle =
          otherRole.role === RepresentativeRole.CREATOR
            ? i18nRoleValues(RepresentativeRole.VIEWER)
            : i18nRoleValues(otherRole.role);
        return (
          <IdentityCard key={otherRole.id} layerStyle="border" p="4" rounded="md">
            <IdentityAvatar identity={otherRole.representativeIdentity} />
            <IdentityCardTitle fontSize="lg">
              <Text fontWeight="600">{roleTitle}</Text>
            </IdentityCardTitle>
            <IdentityCardDescription color="gray.800" fontSize="md">
              {roleSubTitle}
            </IdentityCardDescription>
          </IdentityCard>
        );
      })}
    </Stack>
  );
}
