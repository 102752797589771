import { Box, HStack, Stack, StackProps } from '@chakra-ui/react';
import React from 'react';

import { IdentityAvatar } from '../IdentityAvatar';

type Props = {
  /* ** Content ** */
  children: React.ReactNode;
} & StackProps;

/**
 * IdentityCard.
 * Simple Stack wrapper to render identity infos.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function IdentityCard({ children, ...props }: Props): JSX.Element {
  return (
    <HStack alignItems="center" spacing="3" {...props}>
      {React.Children.map(children, (child) =>
        child && typeof child === 'object' && 'type' in child && child.type === IdentityAvatar
          ? child
          : null,
      )}
      <Box overflow="hidden" w="full">
        <Stack alignItems="flex-start" spacing="0">
          {React.Children.map(children, (child) =>
            child &&
            (typeof child !== 'object' || !('type' in child) || child.type !== IdentityAvatar)
              ? child
              : null,
          )}
        </Stack>
      </Box>
    </HStack>
  );
}

export type IdentityCardProps = Props;
