import { Flex, GridItem, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';

type Props = {
  index?: number;
  title: string;
  subTitle?: string;
  isCompleted: boolean;
  children?: React.ReactNode;
};

export function ProgressionStep({
  index = 0,
  title,
  subTitle,
  isCompleted,
  children,
}: Props): JSX.Element {
  return (
    <GridItem minW="200px" p="3" w="full">
      <Stack spacing="2">
        <HStack alignItems="center" flexShrink="0" spacing="2">
          {isCompleted ? (
            <Icon as={CheckCircleIcon} boxSize="34px" />
          ) : (
            <Flex
              alignItems="center"
              border="2px solid black"
              borderRadius="full"
              boxSize="32px"
              flexShrink="0"
              justifyContent="center"
            >
              {String(index + 1).padStart(2, '0')}
            </Flex>
          )}
          <Stack spacing="0">
            <Text>{title}</Text>
            <Text color="gray.600" fontSize="xs" fontWeight="400">
              {subTitle}
            </Text>
          </Stack>
        </HStack>
        {children}
      </Stack>
    </GridItem>
  );
}

export type ProgressionStepProps = Props;
