import { Button, HStack, Heading, Icon, Skeleton, Stack, Text } from '@chakra-ui/react';
import { CashIcon, PlusIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { CompanyDocumentType, isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import {
  CompanyType,
  DocumentStatus,
  useGetBondAssetsQuery,
  useGetCompanyDocumentQuery,
  useGetCompanyQuery,
  useGetOptionAssetsQuery,
  useGetStockAssetsQuery,
  useValidateRmtDocumentMutation,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, useSuccessToast } from '@blockpulse3/ui/commons';
import { useAuthUser, useManagerRole } from '@blockpulse3/web-client/auth';

import { AssetAndOptionList } from './AssetAndOptionList';
import { BlockpulseLoading } from './BlockpulseLoading/BlockpulseLoading';

type Props = {
  displayType?: 'table' | 'cards';
  showOptions?: boolean;
};

let timeoutId: NodeJS.Timeout | null = null;

export function AssetsAndOptions({ showOptions = true }: Props): JSX.Element | null {
  const t = useTranslations();

  const successToast = useSuccessToast();

  const { user } = useAuthUser();

  const navigate = useNavigate();
  const location = useLocation();
  const { companyId = '' } = useParams();

  const [isPollingStarted, setIsPollingStarted] = useState<boolean>(false);

  const [validateDocument, { loading: isResumeLoading }] = useValidateRmtDocumentMutation();

  const { data: companyData } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
    fetchPolicy: 'network-only',
  });

  const company = companyData?.company;

  const hasCaptable =
    !!company?.capTable?.fullyDilutedTotalSupply &&
    (!!company?.capTable?.stockTotalSupply || company?.type === CompanyType.PARTIAL);

  const {
    data: rmtDocumentData,
    loading: rmtDocumentLoading,
    startPolling,
    stopPolling,
  } = useGetCompanyDocumentQuery({
    variables: { companyId, documentType: CompanyDocumentType.RMT },
    skip: !companyId,
    fetchPolicy: 'network-only',
  });

  const rmtDocumentStatus = rmtDocumentData?.getCompanyDocument?.document?.status;
  const registrationStarted = rmtDocumentStatus === DocumentStatus.SIGNED;
  const registrationPending = rmtDocumentStatus === DocumentStatus.SIGNATURES_PENDING;
  const shareTransferRegisterValidated = registrationStarted || registrationPending;

  const isUserSigner = company?.signer?.individualIdentity?.id === user?.individualIdentity?.id;
  const isSpaceManager = useManagerRole({ companyId });
  const isRepatriationAuthorized = isUserSigner || isSpaceManager;

  const isFeatureOptionPool = isFeatureEnabled('optionPool');

  useEffect(() => {
    if (isPollingStarted && (!rmtDocumentStatus || rmtDocumentStatus === DocumentStatus.SIGNED)) {
      stopPolling();
      setIsPollingStarted(false);
      return;
    }

    if (!isPollingStarted && rmtDocumentStatus === DocumentStatus.SIGNATURES_PENDING) {
      setIsPollingStarted(true);
      startPolling(2000);

      if (timeoutId) clearTimeout(timeoutId);

      /* ** Stop polling after 1sec ** */
      timeoutId = setTimeout(async () => {
        stopPolling();
        setIsPollingStarted(false);
      }, 60_000);
    }
  }, [rmtDocumentStatus, isPollingStarted, setIsPollingStarted, startPolling, stopPolling]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const stocksReq = useGetStockAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId || !hasCaptable,
  });

  const optionsReq = useGetOptionAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !showOptions || !companyId || !isFeatureOptionPool || !hasCaptable,
  });

  const bondsReq = useGetBondAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId || !hasCaptable,
  });

  if (
    !stocksReq.data?.getStockAssets?.length &&
    showOptions &&
    !optionsReq.data?.getOptionAssets?.length &&
    !isRepatriationAuthorized
  ) {
    return null;
  }

  if (stocksReq.error || (showOptions && optionsReq.error) || bondsReq.error) {
    return (
      <Stack spacing="4">
        <Heading size="md">{showOptions ? t('AssetsAndPools') : t('Asset', { nb: 2 })}</Heading>
        <ErrorQueryCard h="200px" />
      </Stack>
    );
  }

  if (
    rmtDocumentLoading ||
    stocksReq.loading ||
    (showOptions && optionsReq.loading) ||
    bondsReq.loading ||
    (hasCaptable && !stocksReq.data)
  ) {
    return (
      <Stack spacing="4">
        <Heading size="md">{showOptions ? t('AssetsAndPools') : t('Asset', { nb: 2 })}</Heading>
        <HStack overflowX="hidden" spacing="4">
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
        </HStack>
      </Stack>
    );
  }

  const currentStocks = stocksReq.data?.getStockAssets || [];
  const currentOptions = optionsReq.data?.getOptionAssets || [];
  const currentBonds = bondsReq.data?.getBondAssets || [];
  const isEmpty =
    !hasCaptable && currentStocks.length === 0 && (!showOptions || currentOptions.length === 0);
  const isInCaptable = matchPath(
    routes.company.href + '/' + routes.company.captable.href + '/*',
    location.pathname,
  );

  const handleRepatriationClick = (): void => {
    navigate(routes.company.repatriation.href);
  };

  const handleStockClick = (): void => {
    if (isInCaptable) {
      navigate('..', { relative: 'path' });
    } else {
      navigate(routes.company.captable.href);
    }
  };

  const handleResumeOrRemindSignature = (): void => {
    validateDocument({
      variables: {
        companyId,
      },
      onCompleted: (data) => {
        if (data.validateRmtDocument?.signingUrl) {
          window.open(data.validateRmtDocument.signingUrl, '_self');
        } else {
          successToast({ title: t('DocumentSentToSignatory') });
        }
      },
    });
  };

  const ResumeSignatureOrSendReminder = (): JSX.Element => {
    return (
      <>
        <Icon as={CashIcon} boxSize="40px" color="gray.500" />
        <Button isLoading={isResumeLoading} size="sm" onClick={handleResumeOrRemindSignature}>
          {isUserSigner ? t('ResumeSignature') : t('SendReminder')}
        </Button>
        <Text maxWidth="500px" textAlign="center">
          {t('CertifyRegisterBySigning')}
        </Text>
      </>
    );
  };

  const TokenizationLoader = (): JSX.Element => {
    return (
      <>
        <BlockpulseLoading />
        <Text maxWidth="500px" textAlign="center">
          {t('RegisterCreationStarted')}
        </Text>
      </>
    );
  };

  return (
    <Stack spacing="4">
      <Stack
        alignItems={{ base: 'flex-start', md: 'center' }}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">{showOptions ? t('AssetsAndPools') : t('Asset', { nb: 2 })}</Heading>
        {showOptions && !isEmpty && (
          <Button size="sm" variant="light" onClick={handleStockClick}>
            {t('ManageShareholding')}
          </Button>
        )}
      </Stack>
      {isEmpty ? (
        <Stack layerStyle="emptyState">
          {shareTransferRegisterValidated ? (
            <>{registrationStarted ? <TokenizationLoader /> : <ResumeSignatureOrSendReminder />}</>
          ) : (
            <>
              <Icon as={CashIcon} boxSize="40px" color="gray.500" />
              <Text>{t('NoAsset')}</Text>
              {showOptions && (
                <Stack>
                  <Button
                    leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                    size="sm"
                    variant="light"
                    w="fit-content"
                    onClick={handleRepatriationClick}
                  >
                    {t('RepatriateShareholding')}
                  </Button>
                </Stack>
              )}
            </>
          )}
        </Stack>
      ) : (
        <AssetAndOptionList
          bonds={currentBonds}
          displayType="cards"
          options={showOptions ? currentOptions : undefined}
          stocks={currentStocks}
        />
      )}
    </Stack>
  );
}

export type AssetsAndOptionsProps = Props;
