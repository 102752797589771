import { Stack, Text } from '@chakra-ui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

type Props = unknown;

export function ForgotPasswordSuccessView(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack alignItems="center" mb="6" p={{ base: 4, md: 6 }} spacing="4">
      <CheckIcon color="green" height="64" width="64" />
      <Text fontWeight="bold" textAlign="center">
        {t('ResetPasswordEmailSent')}
      </Text>
    </Stack>
  );
}

export type ForgotPasswordSuccessViewProps = Props;
