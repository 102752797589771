import { BoxProps, Button, HStack, Icon, Text } from '@chakra-ui/react';
import { SwitchHorizontalIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

import { useWorkspaceSwitcher } from '.';

type Props = {
  children: React.ReactNode;
} & BoxProps;

/**
 * Identity.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function IdentityHeader({ children, ...props }: Props): JSX.Element {
  const t = useTranslations();

  const { onSwitcherOpen } = useWorkspaceSwitcher();

  return (
    <HStack
      borderColor="gray.200"
      borderStyle="solid"
      borderWidth="0 0 1px"
      data-cy="identity-header"
      justifyContent="space-between"
      pb="3"
      rounded="sm"
      {...props}
    >
      {children}
      <Button fontSize={['md', 'sm']} variant="secondary" onClick={onSwitcherOpen}>
        <Icon as={SwitchHorizontalIcon} mr={['0', '2']} />
        <Text display={['none', 'inherit']}>{t('SwitchWorkspace')}</Text>
      </Button>
    </HStack>
  );
}

export type IdentityHeaderProps = Props;
