import { Alert, AlertIcon, AlertTitle, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { AssetType, OperationType, useGetMovementQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';

type Props = unknown;

/**
 * SubscriptionPanelInvestment.
 * Update invest amount section of the subscription side panel.
 *
 * @returns {JSX.Element}
 */
export function MovementPanelInvestment(): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '', movementId = '' } = useParams();

  const { data, loading, error } = useGetMovementQuery({
    variables: {
      companyId,
      movementId,
    },
    skip: !companyId || !movementId,
  });

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Investment', { nb: 1 })}
        </Text>
        <Skeleton h="40px" />
      </Stack>
    );
  }

  const { movement } = data;
  const { subscription } = movement;

  const investAmount = subscription?.investAmount;
  const operation = subscription?.operation;
  const amount = investAmount ? formatNumberCurrency(investAmount) : '-';
  const assetType = operation?.assetType;

  if (!subscription || !operation) return null;

  if (operation?.type === OperationType.UNKNOWN || !operation?.sharePrice) {
    return (
      <Stack px="4">
        <Alert status="info">
          <AlertIcon />
          <AlertTitle>{t('ImportedOperationToComplete')}</AlertTitle>
        </Alert>
      </Stack>
    );
  }

  /* ** Group wordings ** */
  const isValuationAsset =
    assetType &&
    ![AssetType.ORDINARY_SHARE, AssetType.PREFERRED_SHARE, AssetType.BOND].includes(assetType);

  const pricePerAsset = operation?.sharePrice || 1;
  const pricePerAssetLabel =
    assetType === AssetType.BOND
      ? t('NbPricePerBond', { price: pricePerAsset ? formatNumberCurrency(pricePerAsset) : '-' })
      : t('NbPricePerShare', { price: pricePerAsset ? formatNumberCurrency(pricePerAsset) : '-' });

  return (
    <Stack px="4" spacing="2">
      <HStack>
        <Text color="gray.600" fontWeight="600">
          {isValuationAsset ? t('ValuationAtGrant') : t('Investment', { nb: 1 })}
        </Text>
      </HStack>
      <Stack spacing="1">
        <HStack alignItems="center" spacing="2">
          <Text fontSize="xl" fontWeight="700">
            {amount}
          </Text>
        </HStack>
        {(subscription?.minimalAmount || subscription?.maximalAmount) && (
          <HStack
            alignSelf="flex-start"
            bgColor="gray.100"
            color="gray.500"
            fontSize="xs"
            px="2"
            py="1"
            rounded="md"
          >
            {subscription?.minimalAmount && (
              <>
                <Text fontWeight="600">{t('Minimum')}</Text>
                <Text>{formatNumberCurrency(subscription.minimalAmount)}</Text>
                {subscription.maximalAmount && <Text>-</Text>}
              </>
            )}
            {subscription?.maximalAmount && (
              <>
                <Text fontWeight="600">{t('Maximum')}</Text>
                <Text>{formatNumberCurrency(subscription.maximalAmount)}</Text>
              </>
            )}
          </HStack>
        )}
      </Stack>
      <HStack fontSize="sm" justifyContent="space-between">
        <Text color="gray.500" fontWeight="500">
          {isValuationAsset ? t('ValuationByShare') : t('AcquisitionPrice')}
        </Text>
        <Text fontWeight="500">{pricePerAssetLabel}</Text>
      </HStack>
    </Stack>
  );
}

export type SubscriptionPanelInvestmentProps = Props;
