import { Button } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import {
  SubscriptionInfosFragment,
  useGetSubscriptionQuery,
  useValidateFundReceptionMutation,
} from '@blockpulse3/graphql/hooks';
import { useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

type Props = {
  subscriptionId: SubscriptionInfosFragment['id'];
};

export function ValidatePayment({ subscriptionId }: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const [validateFunds, { loading: loadingFunds }] = useValidateFundReceptionMutation();

  const { refetch, loading } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const handleFundsValidation = (): void => {
    validateFunds({
      variables: {
        validateFundReceptionInput: {
          subscriptionId,
        },
      },
      onCompleted: () => {
        successToast({
          title: t('FundsReceivedValidated'),
        });
        refetch();
      },
      onError: (error) => {
        errorToast({
          title: t('FundsReceiptValidationError'),
          description: error.message,
        });
      },
    });
  };

  return (
    <Button
      isDisabled={loadingFunds || loading}
      isLoading={loadingFunds || loading}
      size="sm"
      variant="secondary"
      onClick={handleFundsValidation}
    >
      {t('ConfirmFundReception')}
    </Button>
  );
}
