import { SpinnerIcon } from '@chakra-ui/icons';
import { AlertProps, CreateToastFnReturn, Icon, UseToastOptions, useToast } from '@chakra-ui/react';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { ExclamationIcon, XCircleIcon } from '@heroicons/react/solid';

export const TOAST_ICON: Record<NonNullable<AlertProps['status']>, React.ReactNode> = {
  info: <Icon as={InformationCircleIcon} boxSize="20px" color="blue.400" />,
  warning: <Icon as={ExclamationIcon} boxSize="20px" color="yellow.400" />,
  error: <Icon as={XCircleIcon} boxSize="20px" color="red.400" />,
  success: <Icon as={CheckCircleIcon} boxSize="20px" color="green.400" />,
  loading: <Icon as={SpinnerIcon} boxSize="20px" color="blue.400" />,
};

export const TOAST_OPTIONS: UseToastOptions = {
  position: 'top-right',
  variant: 'toast',
  isClosable: true,
};

/* ** Error toast hook shorthand ** */
export function useErrorToast(options?: UseToastOptions): CreateToastFnReturn {
  return useToast({ ...TOAST_OPTIONS, icon: TOAST_ICON['error'], ...options });
}
/* ** Success toast hook shorthand ** */
export function useSuccessToast(options?: UseToastOptions): CreateToastFnReturn {
  return useToast({ ...TOAST_OPTIONS, icon: TOAST_ICON['success'], ...options });
}
/* ** Info toast hook shorthand ** */
export function useInfoToast(options?: UseToastOptions): CreateToastFnReturn {
  return useToast({ ...TOAST_OPTIONS, icon: TOAST_ICON['info'], ...options });
}
/* ** Warning toast hook shorthand ** */
export function useWarningToast(options?: UseToastOptions): CreateToastFnReturn {
  return useToast({ ...TOAST_OPTIONS, icon: TOAST_ICON['warning'], ...options });
}
/* ** Loading toast hook shorthand ** */
export function useLoadingToast(options?: UseToastOptions): CreateToastFnReturn {
  return useToast({ ...TOAST_OPTIONS, icon: TOAST_ICON['loading'], ...options });
}
