import * as yup from 'yup';

import {
  requiredEmailField,
  requiredNumberField,
  requiredPastDateField,
  requiredPercentageNumberField,
  requiredSelectField,
  requiredStringField,
} from '@blockpulse3/data/shared';

import { schemaUbo } from '../CompanyUbos/schema';
import { ILegalShareholderForm, INaturalShareholderForm, SPVShareholderForm } from './types';

export const schemaNaturalShareholder = yup.object({
  firstName: requiredStringField,
  lastName: requiredStringField,
  email: requiredEmailField,
  nationality: requiredSelectField,
  birthdate: requiredPastDateField,
  birthplace: requiredStringField,
  birthCityPostalCode: requiredStringField,
  birthCountry: requiredSelectField,
  address: yup.object({
    line: requiredStringField,
    city: requiredStringField,
    postalCode: requiredStringField,
    country: requiredSelectField,
  }),
  motherFirstName: requiredStringField,
  motherLastName: requiredStringField,
  fatherFirstName: requiredStringField,
  fatherLastName: requiredStringField,
  residenceCertificate: requiredStringField,
  preferredSharesPercentages: requiredPercentageNumberField(0),
}) as yup.ObjectSchema<INaturalShareholderForm>;

export const schemaLegalShareholder = yup.object({
  name: requiredStringField,
  registrationNumber: requiredStringField,
  corporateForm: requiredStringField,
  creationDate: requiredStringField,
  registrationEntity: requiredStringField,
  shareCapital: requiredNumberField,
  address: yup.object({
    line: requiredStringField,
    city: requiredStringField,
    postalCode: requiredStringField,
    country: requiredSelectField,
  }),
  preferredSharesPercentages: requiredPercentageNumberField(0),
  firstName: requiredStringField,
  lastName: requiredStringField,
  email: requiredEmailField,
  position: requiredStringField,
  ubos: yup.array(schemaUbo),
}) as yup.ObjectSchema<ILegalShareholderForm>;

export const spvShareholderFormDefaultValues: SPVShareholderForm = {
  firstName: '',
  lastName: '',
  email: '',
  nationality: { label: '', value: '' },
  birthdate: '',
  birthplace: '',
  birthCityPostalCode: '',
  birthCountry: { label: '', value: '' },
  address: {
    line: '',
    city: '',
    postalCode: '',
    country: { label: '', value: '' },
  },
  motherFirstName: '',
  motherLastName: '',
  fatherFirstName: '',
  fatherLastName: '',
  residenceCertificate: '',
  preferredSharesPercentages: 0,
  name: '',
  registrationNumber: '',
  corporateForm: '',
  creationDate: '',
  registrationEntity: '',
  shareCapital: 0,
  position: '',
};
