import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { generatePath, resolvePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

import { CompleteOperationForm } from '../../';

/**
 * RepatriationOperationInformations.
 * Create/complete operation informations before repatriate it.
 *
 * @returns {JSX.Element}
 */
export function RepatriationOperationInformations(): JSX.Element {
  const t = useTranslations();

  const { companyId = '', operationId = '' } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleFormSubmit = (submittedOperationId: string): void => {
    if (operationId) {
      navigate(routes.company.repatriation.identities.href);
      return;
    }

    window.history.replaceState(
      null,
      '',
      generatePath(routes.company.newOperation.repatriation.edit.full, {
        companyId,
        operationId: submittedOperationId,
      }),
    );
    navigate(
      resolvePath(
        routes.company.repatriation.identities.href,
        generatePath(routes.company.newOperation.repatriation.edit.href, {
          operationId: submittedOperationId,
        }),
      ).pathname,
      { relative: 'path' },
    );
  };

  const handleStepCancel = (): void => {
    navigate(generatePath(routes.company.href, { companyId }));
  };

  return (
    <Card variant="divider-top" width="full">
      <CardHeader as={Stack} spacing="2">
        <Heading size="lg">{t('CompleteOperationInfo')}</Heading>
        <Text color="gray.600" fontSize="lg">
          {t('CompleteOperationInfoDescription')}
        </Text>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        <CompleteOperationForm
          isRepatriation={true}
          operationId={operationId}
          setIsLoading={setIsLoading}
          onCompleted={handleFormSubmit}
        />
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button variant="secondary" w="full" onClick={handleStepCancel}>
          {t('BackToDashboard')}
        </Button>
        <Button
          form="complete-operation"
          isDisabled={isLoading}
          isLoading={isLoading}
          type="submit"
          w="full"
        >
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}
