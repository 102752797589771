import { Button, HStack, Icon, Text } from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

import { formatIBAN } from '@blockpulse3/helpers';

import { IconButtonWithTooltip } from '../IconButtonWithTooltip';

type Props = {
  iban?: string | null;
  onEdit?: () => void;
  onAdd?: () => void;
  isDisabled?: boolean;
};

export function InlineIBANDisplay({ iban, onEdit, onAdd, isDisabled = false }: Props): JSX.Element {
  const t = useTranslations();

  return (
    <HStack
      background="gray.50"
      boxShadow="base"
      h="50px"
      justifyContent="space-between"
      px="4"
      rounded="md"
    >
      <HStack spacing="3">
        <Text fontWeight="600">{t('ExternalIBAN')}</Text>
        {iban && <Text fontSize="sm">{formatIBAN(iban)}</Text>}
      </HStack>
      {iban && onEdit ? (
        <IconButtonWithTooltip
          aria-label="edit"
          boxSize="18px"
          icon={<Icon as={PencilIcon} boxSize="18px" color="gray.500" />}
          isDisabled={isDisabled}
          label={t('UpdateExternalIBAN')}
          minW="auto"
          variant="unstyled"
          onClick={onEdit}
        />
      ) : (
        onAdd && (
          <Button
            color="secondary"
            fontSize="sm"
            iconSpacing="0.5"
            isDisabled={isDisabled}
            leftIcon={<Icon as={PencilIcon} boxSize="14px" />}
            variant="link"
            w="fit-content"
            onClick={onAdd}
          >
            {t('AddDistributionIBAN')}
          </Button>
        )
      )}
    </HStack>
  );
}
