import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import {
  useGetSubscriptionQuery,
  useValidateMifidSectionMutation,
} from '@blockpulse3/graphql/hooks';
import { downloadFile } from '@blockpulse3/helpers';
import {
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { MifidForm } from './MifidForm';
import { MifidSteps } from './MifidSteps';
import { MifidModalContextProvider } from './provider';
import { MifidQuestionForm } from './types';

type Props = {
  onClose: () => void;
  isReading?: boolean;
  subscriptionId: string;
} & Omit<ResponsiveModalProps, 'children'>;

export function MifidModal({
  onClose,
  isReading = false,
  subscriptionId = '',
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const [sectionIndex, setSectionIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [isNextEnabled, setNextEnabled] = useState(false);
  const [tmpSectionData, setTmpSectionData] = useState<MifidQuestionForm>({});
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

  const [validateMifidSection, { loading: validateSectionLoading }] =
    useValidateMifidSectionMutation();

  const { identityId } = useIdentity();

  const {
    data,
    refetch,
    loading: subscriptionLoading,
  } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
    // Make loading value update accordingly when refetching
    notifyOnNetworkStatusChange: true,
  });
  const mifidSections = data?.subscription.data.mergedMifidSections || [];
  const currentSection = mifidSections[sectionIndex];

  const isLastSection = sectionIndex === mifidSections.length - 1;
  const isFirstSection = sectionIndex === 0;

  const handleFormSubmit = (sectionData: MifidQuestionForm): void => {
    if (isReading) {
      if (isLastSection) {
        setSectionIndex(0);
        onClose();
      } else {
        setSectionIndex(sectionIndex + 1);
      }
      return;
    }

    // Prevent form submission if no data has been changed
    if (JSON.stringify(tmpSectionData) === JSON.stringify(sectionData)) {
      return;
    }
    setTmpSectionData(sectionData);

    // Prevent sending form if all answers have not been filled
    const inputCount = Object.values(sectionData).filter((value) => value);
    const sectionKeys = Object.keys(currentSection.questions);
    if (inputCount.length < sectionKeys.length) {
      return;
    }

    validateMifidSection({
      variables: {
        validateMifidSectionInput: {
          subscriptionId,
          mifidSectionId: currentSection.sectionId,
          answersInput: sectionData,
        },
      },
      onCompleted: async ({ validateMifidSection }) => {
        await refetch();
        if (!validateMifidSection) {
          setShowAlert(true);
          return;
        } else {
          setShowAlert(false);
          setTmpSectionData({});
          setSectionIndex(sectionIndex + 1);
        }

        // Close modal once all sections have been completed successfully
        if (isLastSection) {
          onClose();
        }
      },
    });
  };

  const handleCancel = (): void => {
    if (isFirstSection) {
      onClose();
    } else {
      setSectionIndex(sectionIndex - 1);
    }
  };

  const handleNextEnabled = (enabled: boolean): void => {
    setNextEnabled(enabled);
  };

  const handleReportDownload = (): void => {
    setIsExportLoading(true);
    try {
      axios
        .post(
          process.env['NX_API_CONTROLLER_ENDPOINT'] + '/subscriptions/exportMifidReport',
          {
            subscriptionId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              Accept: 'application/pdf',
            },
            responseType: 'blob',
          },
        )
        .then((response) => {
          downloadFile(response.data, 'mifid-report.pdf');
          setIsExportLoading(false);
        });
    } catch (err) {
      setIsExportLoading(false);
    }
  };

  const isLoading = validateSectionLoading || subscriptionLoading || !currentSection;

  return (
    <MifidModalContextProvider
      isSectionDisabled={isLoading || isReading}
      subscriptionId={subscriptionId}
    >
      <ResponsiveModal isCentered scrollBehavior="inside" size="5xl" onClose={onClose} {...props}>
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <ModalHeader>
            {t('ProfileInvestor')}
            <Text fontSize="sm" fontWeight="500">
              {t('ComplianceQuestionnaireInfo')}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            as={Stack}
            direction={{ base: 'column', md: 'row' }}
            p="0"
            position="relative"
            spacing="0"
          >
            <Stack
              bg="gray.50"
              boxShadow="sm"
              direction="column"
              flexShrink="0"
              flexWrap="nowrap"
              justifyContent="space-between"
              overflowX={{ base: 'auto', md: 'hidden' }}
              p="2"
              position={{ base: 'relative', md: 'sticky' }}
              top="0"
              width={{ base: 'full', md: '33%' }}
            >
              <MifidSteps mifidSections={mifidSections} sectionIndex={sectionIndex} />
              {showAlert && (
                <Stack p="2">
                  <Alert status="error">
                    <AlertIcon as={XCircleIcon} />
                    <Stack spacing="1">
                      <AlertTitle>{t('InvestorProfileNotSuitable')}</AlertTitle>
                      <AlertDescription>{t('ContactUsForMoreInfo')}</AlertDescription>
                    </Stack>
                  </Alert>
                </Stack>
              )}
            </Stack>
            <Flex
              direction="column"
              height="full"
              justify="space-between"
              overflowY={{ base: 'auto', md: 'hidden' }}
              px="5"
              width="full"
            >
              {currentSection && (
                <MifidForm
                  section={currentSection}
                  onSubmit={handleFormSubmit}
                  onUpdateNextEnabled={handleNextEnabled}
                />
              )}
            </Flex>
          </ModalBody>
          <Divider />
          <ResponsiveModalFooter justifyContent="space-between">
            {isReading && (
              <Button
                isDisabled={isExportLoading}
                isLoading={isExportLoading}
                loadingText={t('FileGeneration')}
                rightIcon={<Icon as={DownloadIcon} />}
                variant="secondary"
                onClick={handleReportDownload}
              >
                {t('DownloadReport')}
              </Button>
            )}
            <HStack marginLeft="auto">
              <Button type="button" variant="secondary" onClick={handleCancel}>
                {isFirstSection ? t('Cancel') : t('Back')}
              </Button>
              {isReading ? (
                <Button form="mifid" type="submit">
                  {isLastSection ? t('Close') : t('Next')}
                </Button>
              ) : (
                <Button
                  form="mifid"
                  isDisabled={!isNextEnabled}
                  isLoading={isLoading}
                  type="submit"
                >
                  {isLastSection ? t('Validate') : t('Next')}
                </Button>
              )}
            </HStack>
          </ResponsiveModalFooter>
        </ModalContent>
      </ResponsiveModal>
    </MifidModalContextProvider>
  );
}
