import { BoxProps, Grid, GridItem, ResponsiveValue, Stack } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { ProgressionStep, ProgressionStepProps } from './ProgressionStep';

type Props = {
  currentStep: number;
  totalSteps: number;
  children?: React.ReactElement<ProgressionStepProps> | React.ReactElement<ProgressionStepProps>[];
  boxShadow?: ResponsiveValue<'none' | undefined>;
} & BoxProps;

export function Progression({ currentStep, totalSteps, children, boxShadow }: Props): JSX.Element {
  /* ** Enriched <ProgressionStep /> to compute the step number ** */
  const steps = useMemo(() => {
    if (!children) {
      return [];
    }
    return React.Children.map<JSX.Element, React.ReactElement<ProgressionStepProps>>(
      children,
      (child, index) => <ProgressionStep index={index} {...child.props} />,
    );
  }, [children]);

  /* ** Progression bar ** */
  const bar = useMemo(() => {
    if (!children) {
      return [];
    }
    return React.Children.map<JSX.Element, React.ReactElement<ProgressionStepProps>>(
      children,
      (child, index) => {
        const isBg = child.props.isCompleted || currentStep === index;
        return (
          <GridItem
            background={isBg ? 'primary' : 'gray.100'}
            flexShrink="0"
            height="10px"
            minW="200px"
            opacity={currentStep === index ? '0.6' : 1}
            w="full"
          />
        );
      },
    );
  }, [children, currentStep]);

  return (
    <Stack boxShadow={boxShadow} overflow="hidden" p={{ base: 0, md: 0 }} spacing="3">
      <Grid overflowX="auto" templateColumns={`repeat(${totalSteps}, 1fr)`}>
        {bar}
        {steps}
      </Grid>
    </Stack>
  );
}

export type ProgressionProps = Props;
