import {
  Badge,
  Button,
  HStack,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { AssetInfosFragment, AssetStatus } from '@blockpulse3/graphql/hooks';
import { AssetBadge, TableContainer } from '@blockpulse3/ui/commons';

type Props = {
  stocks?: AssetInfosFragment[];
  bonds?: AssetInfosFragment[];
  options?: AssetInfosFragment[];
  showOptions?: boolean;
  handleStockClick: (asset: AssetInfosFragment) => void;
  handleOptionClick: (asset: AssetInfosFragment) => void;
  handleBondClick: (asset: AssetInfosFragment) => void;
  handleDeleteModalOpen: (asset: AssetInfosFragment) => void;
};

export function AssetAndOptionTable({
  stocks,
  bonds,
  options,
  showOptions = true,
  handleStockClick = noop,
  handleOptionClick = noop,
  handleBondClick = noop,
  handleDeleteModalOpen = noop,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nAssetType = useTranslations('AssetTypeValues');

  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>{t('Name')}</Th>
            <Th>{t('Quantity')}</Th>
            <Th w="200px" />
          </Tr>
        </Thead>
        <Tbody>
          {stocks?.map((row) => (
            <Tr key={row.id} role="button" onClick={(): void => handleStockClick(row)}>
              <Td>
                <HStack>
                  <Text fontWeight="600">{row.name}</Text>
                  {row.status === AssetStatus.DRAFT && (
                    <Badge alignSelf="flex-start" colorScheme="gray">
                      {t('Draft', { nb: 1 })}
                    </Badge>
                  )}
                </HStack>
                <Text color="gray.500" fontSize="xs" fontWeight="400">
                  {i18nAssetType(row.assetType, { nb: 1 })}
                </Text>
              </Td>
              <Td fontWeight="600">
                <AssetBadge type={row.assetType} value={row.supply} />
              </Td>
              <Td textAlign="right">
                <Button
                  rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                  size="sm"
                  variant="secondary"
                >
                  {t('ShowDetails')}
                </Button>
              </Td>
            </Tr>
          ))}
          {showOptions &&
            options?.map((row) => (
              <Tr key={row.id} role="button" onClick={(): void => handleOptionClick(row)}>
                <Td>
                  <HStack>
                    <Text fontWeight="600">{row.name}</Text>
                    {row.status === AssetStatus.DRAFT && (
                      <Badge alignSelf="flex-start" colorScheme="gray">
                        {t('Draft', { nb: 1 })}
                      </Badge>
                    )}
                  </HStack>
                  <Text color="gray.500" fontSize="xs" fontWeight="400">
                    {i18nAssetType(row.assetType, { nb: 1 })}
                  </Text>
                </Td>
                <Td fontWeight="600">
                  <AssetBadge
                    type={row.assetType}
                    value={row.supply}
                    assetCount={
                      (row.token?.__typename === 'OptionTokenModel' && row.token?.grants?.length) ||
                      0
                    }
                  />
                </Td>
                <Td textAlign="right">
                  {row.status === AssetStatus.DRAFT && !row.operation ? (
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={(): void => handleDeleteModalOpen(row)}
                    >
                      {t('DeleteDraft')}
                    </Button>
                  ) : (
                    <Button
                      rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                      size="sm"
                      variant="secondary"
                    >
                      {t('ShowDetails')}
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
          {bonds &&
            [...bonds]
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((row) => (
                <Tr
                  key={row.id}
                  role={row.operation?.id && 'button'}
                  onClick={(): void => (row.operation?.id ? handleBondClick(row) : noop())}
                >
                  <Td>
                    <HStack>
                      <Text fontWeight="600">{row.name}</Text>
                      {row.status === AssetStatus.DRAFT && (
                        <Badge alignSelf="flex-start" colorScheme="gray">
                          {t('Draft', { nb: 1 })}
                        </Badge>
                      )}
                    </HStack>
                    <Text color="gray.500" fontSize="xs" fontWeight="400">
                      {i18nAssetType(row.assetType, { nb: 1 })}
                    </Text>
                  </Td>
                  <Td fontWeight="600">
                    <AssetBadge type={row.assetType} value={row.supply} />
                  </Td>
                  <Td textAlign="right">
                    {row.status === AssetStatus.DRAFT && !row.operation ? (
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={(): void => handleDeleteModalOpen(row)}
                      >
                        {t('DeleteDraft')}
                      </Button>
                    ) : (
                      <Button
                        rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                        size="sm"
                        variant="secondary"
                      >
                        {t('ShowDetails')}
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
