import { Card, CardBody, Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled } from '@blockpulse3/data/shared';

import { OptionTable } from './OptionTable';

type Props = unknown;

export function OptionList(): JSX.Element | null {
  const t = useTranslations();

  const isFeatureOptionPool = isFeatureEnabled('optionPool');

  if (!isFeatureOptionPool) return null;

  return (
    <Stack spacing="4">
      <Stack
        alignItems={{ base: 'flex-start', md: 'center' }}
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">{t('Pools')}</Heading>
      </Stack>
      <Card>
        <CardBody>
          <OptionTable />
        </CardBody>
      </Card>
    </Stack>
  );
}

export type OptionListProps = Props;
