import { Stack } from '@chakra-ui/react';

import { SPVHeader } from './SPVHeader';
import { SPVMatriculation } from './SPVMatriculation';
import { SPVProgression } from './SPVProgression';

/**
 * SPVView.
 * View of a current active draft.
 *
 * @returns {JSX.Element}
 */
export function SPVView(): JSX.Element {
  return (
    <Stack spacing="6">
      <SPVHeader />
      <SPVProgression />
      <SPVMatriculation />
    </Stack>
  );
}
