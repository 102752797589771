import { Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { SpaceList } from './AdminSpaces/SpaceList';
import { SpaceHeader } from './SpaceHeader';

type Props = unknown;

export function ManagedSpacesView(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack spacing="6">
      <Stack spacing="4">
        <SpaceHeader />
        <Heading size="lg">{t('ManagedSpaces')}</Heading>
      </Stack>
      <SpaceList showManaged={true} />
    </Stack>
  );
}

export type ManagedSpacesViewProps = Props;
