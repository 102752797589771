import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ArrowRightIcon, OfficeBuildingIcon } from '@heroicons/react/outline';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { ManagerFilterType, routes } from '@blockpulse3/data/shared';
import {
  GetManagementsDocument,
  GetManagementsQuery,
  GetManagementsQueryVariables,
  ManagementType,
  ManagerEntityInfosFragment,
} from '@blockpulse3/graphql/hooks';
import { usePagination } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';
import { SwitcherItem } from '@blockpulse3/web-client/sidebar';

const MAX_LENGTH = 5;

/**
 * ManagedCompaniesList.
 * Display the companies managed by the current space.
 *
 * @returns {JSX.Element}
 */
export function ManagedCompaniesList(): JSX.Element {
  const t = useTranslations();

  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const navigate = useNavigate();

  const paginationProps = usePagination<
    GetManagementsQuery,
    GetManagementsQueryVariables,
    ManagerEntityInfosFragment
  >({
    queryDocument: GetManagementsDocument,
    queryOptions: {
      variables: {
        spaceId,
        first: MAX_LENGTH,
        filterBy: { name: '', type: ManagerFilterType.MANAGER_TYPE, value: ManagementType.OWNER },
      },
      skip: !spaceId,
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'managers',
    pageSize: MAX_LENGTH,
  });
  const { loading, results, totalCount } = paginationProps;
  const companies = results.map((manager) => manager.identity.companyIdentity);

  const handleWorkspaceClick = (id: string): void => {
    const selectedCompany = companies.find((w) => w?.id === id);
    if (selectedCompany) {
      navigate(
        generatePath(routes.manage.company.full, {
          spaceId,
          companyId: selectedCompany?.id || '',
        }),
      );
    }
  };

  const handleSeeAllClick = (): void => {
    navigate(routes.space.controls.full);
  };

  return (
    <Card>
      <CardHeader>
        <Heading size="md">
          <HStack justifyContent="space-between">
            <Text>
              {t('ControlledCompanies')}
              {!loading && totalCount > 0 && <Badge ml="2">{totalCount}</Badge>}
            </Text>
            <Button
              rightIcon={<Icon as={ArrowRightIcon} boxSize="4" />}
              size="sm"
              variant="secondary"
              onClick={handleSeeAllClick}
            >
              {t('Manage')}
            </Button>
          </HStack>
        </Heading>
      </CardHeader>
      <CardBody p="0" pt="2">
        {loading ? (
          <Stack m="5" spacing="8">
            <Skeleton minH="50px" />
            <Skeleton minH="50px" />
            <Skeleton minH="50px" />
          </Stack>
        ) : (
          <Stack spacing="0" w="full">
            {totalCount === 0 && (
              <Stack bgColor="transparent" layerStyle="emptyState" p="5">
                <Icon as={OfficeBuildingIcon} boxSize="40px" color="gray.500" />
                <Text>{t('NoCompany')}</Text>
              </Stack>
            )}
            {companies.map((company) => (
              <SwitcherItem
                key={company?.id}
                isUser={false}
                name={company?.name || ''}
                profilePicture={company?.identity?.profilePicture}
                py={3}
                workspaceId={company?.id || ''}
                onItemClick={handleWorkspaceClick}
              >
                <Text fontSize="sm" fontWeight="light" variant="ellipsis">
                  {company?.registrationNumber}
                </Text>
              </SwitcherItem>
            ))}
            {totalCount > MAX_LENGTH && (
              <Button p="4" textAlign="left" variant="link" onClick={handleSeeAllClick}>
                {t('SeeAll')}
              </Button>
            )}
          </Stack>
        )}
      </CardBody>
    </Card>
  );
}
