import * as yup from 'yup';

import { FormErrors, requiredPasswordField } from '@blockpulse3/data/shared';

export const schema = yup.object({
  password: requiredPasswordField,
  confirmPassword: yup
    .string()
    .trim()
    .required(FormErrors.RequiredField)
    .test(
      'password-confirmation',
      FormErrors.PasswordMatch,
      (value, context) => value === context.parent.password,
    ),
});
