import { useBreakpointValue } from '@chakra-ui/react';

import { SidebarDesktop, SidebarMobile } from './responsive';

type Props = {
  bg?: 'black' | 'primary';
};

export function Sidebar({ bg = 'primary' }: Props): JSX.Element {
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });

  return isDesktop ? <SidebarDesktop bg={bg} /> : <SidebarMobile />;
}

export type SidebarProps = Props;
