import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Icon, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useTranslations } from 'use-intl';

import { FormErrors, noop } from '@blockpulse3/data/shared';
import { OperationDocumentType, useGetSubscriptionQuery } from '@blockpulse3/graphql/hooks';
import { isDocumentTypeSubscription } from '@blockpulse3/helpers';

import { DropzoneInput } from '../DropzoneInput';
import { useSuccessToast } from '../Toast';
import { SubscriptionDocumentsSelectProps } from './type';
import { useOnlyCustomFile } from './utils';

type Props = {
  subscriptionId: string;
  uploadableSubscriptionDocuments?: SubscriptionDocumentsSelectProps[];
};

export function AddSubscriptionDocuments({
  subscriptionId,
  uploadableSubscriptionDocuments,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nError = useTranslations('FormErrors');

  const [error, setError] = useState<string | undefined>();
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<
    SubscriptionDocumentsSelectProps | undefined
  >(undefined);

  const successToast = useSuccessToast();

  const { data, loading, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const subscription = data?.subscription;
  const documents = subscription?.subscriptionDocuments || [];

  const subscriptionDocuments = documents.filter(
    (doc) =>
      doc.parentDocumentType === 'SubscriptionDocument' ||
      doc.type === OperationDocumentType.FUNDS_SOURCE_CERTIFICATE,
  );

  const numbersOfFundsSourceCertificates = documents.filter(
    (doc) => doc.type === OperationDocumentType.FUNDS_SOURCE_CERTIFICATE,
  ).length;

  const documentsTypes = subscriptionDocuments.map((doc) => doc.type);
  const uploadableFiles: SubscriptionDocumentsSelectProps[] = [];
  if (uploadableSubscriptionDocuments) {
    uploadableSubscriptionDocuments.forEach((doc) => {
      if (
        !documentsTypes.includes(doc.type) &&
        ![
          OperationDocumentType.CUSTOM_FILE,
          OperationDocumentType.FUNDS_SOURCE_CERTIFICATE,
        ].includes(doc.type)
      ) {
        uploadableFiles.push(doc);
      }

      if (
        doc.type === OperationDocumentType.FUNDS_SOURCE_CERTIFICATE &&
        numbersOfFundsSourceCertificates < 3
      ) {
        uploadableFiles.push(doc);
      }
      /* ** Condition to add all time CUSTOM_FILE in optionsFiles ** */
      if (doc.type === OperationDocumentType.CUSTOM_FILE) {
        uploadableFiles.push(doc);
      }
    });
  }

  const onlyCustomFile = useOnlyCustomFile();

  const optionsFiles = uploadableSubscriptionDocuments ? uploadableFiles : onlyCustomFile;

  /* ** Method to upload a file using axios ** */
  const handleFileUpload = async (acceptedFiles: File[]): Promise<void> => {
    // For now only handle company documents
    if (!selectedDocument) return;

    const formData = new FormData();
    formData.append('subscriptionDocument', acceptedFiles[0]);
    formData.append('subscriptionId', subscriptionId);
    formData.append('documentType', selectedDocument.type);
    const endpoint = isDocumentTypeSubscription(selectedDocument.type)
      ? '/subscriptions/uploadSignedSubscriptionForm'
      : '/subscriptions/uploadSubscriptionDocument';

    setIsFileLoading(true);

    await axios
      .post(process.env['NX_API_CONTROLLER_ENDPOINT'] + endpoint, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        successToast({ title: t('SuccessfulFileUpload') });
      })
      .catch(() => {
        setError(i18nError(FormErrors.DropzoneDefault));
        setIsFileLoading(false);
      });
    setSelectedDocument(undefined);
    await refetch();
    setIsFileLoading(false);
  };

  /* ** Upload file handler ** */
  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    if (!selectedDocument) return;

    if (fileRejections.length === 0) {
      handleFileUpload(acceptedFiles);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError(i18nError(FormErrors.FileTooLarge));
          break;
        }
        default: {
          setError(i18nError(FormErrors.DropzoneDefault));
          break;
        }
      }
    }
  };

  return (
    <Stack>
      <Menu matchWidth>
        <MenuButton
          as={Button}
          fontWeight={400}
          rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
          textAlign="start"
          variant="secondary"
        >
          {selectedDocument ? selectedDocument.label : t('SelectDocumentType')}
        </MenuButton>
        <MenuList>
          {optionsFiles.map((option, i) => (
            <MenuItem key={i} onClick={(): void => setSelectedDocument(option)}>
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <DropzoneInput
        accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
        files={{}}
        isDisabled={!selectedDocument || loading || isFileLoading}
        isLoading={isFileLoading}
        maxFiles={1}
        subTitle={t('ImageUpTo5MB')}
        onDelete={noop}
        onDrop={handleFileDrop}
      />
      {error && (
        <Text color="red" fontSize="xs">
          {error}
        </Text>
      )}
    </Stack>
  );
}
