export enum OperationSubscribersType {
  SUBSCRIBERS = 'SUBSCRIBERS',
  SELLERS = 'SELLERS',
  INTENTS = 'INTENTS',
  BUYERS = 'BUYERS',
}

export type IReminderLabels = {
  modalTitle: 'RemindSubscribers' | 'RemindSellers' | 'RemindPotentialBuyer' | 'RemindBuyers';
  modalBackupTitle:
    | 'NoSubscriberToRemind'
    | 'NoPotentialBuyerToRemind'
    | 'NoSellerToRemind'
    | 'NoBuyerToRemind';
  toastSuccessLabel:
    | 'MailSentSuccessfullyToSubscriber'
    | 'MailSentSuccessfullyToSeller'
    | 'MailSentSuccessfullyToPotentialBuyer'
    | 'MailSentSuccessfullyToBuyer';
  alertTitle:
    | 'SubscribersAlreadyRemindedRecently'
    | 'PotentialBuyersAlreadyRemindedRecently'
    | 'SellersAlreadyRemindedRecently'
    | 'BuyersAlreadyRemindedRecently';
  alertDescriptions:
    | 'RecentlyRemindedSubscriber'
    | 'RecentlyRemindedSeller'
    | 'RecentlyRemindedPotentialBuyer'
    | 'RecentlyRemindedBuyer';
};
