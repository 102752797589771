import { Box, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useTranslations } from 'use-intl';

import { getBrand, getBrandConfig, getHostName } from '@blockpulse3/data/shared';
import { formatNumberCurrency } from '@blockpulse3/helpers';

import { convertHexToRGBA } from './utils';

export type ILineChartData = {
  x: number;
  y: number | string;
};

type Props = {
  data: ILineChartData[];
  // Display first and last price in the chart
  displayAxis?: boolean;
  displayLabel?: boolean;
  displayTooltip?: boolean;
  // To change color of the line chart
  lineColor?: string;
};

export function SimpleLineChart({
  data,
  displayAxis = true,
  displayLabel = true,
  displayTooltip = true,
  lineColor,
}: Props): JSX.Element | null {
  const t = useTranslations();

  const { theme } = getBrandConfig(getBrand(getHostName()));

  if (!data || !data.length) return null;

  const dateFormatter = (ts: number): string => {
    return dayjs(ts).format('DD MMM YYYY');
  };

  const CustomTooltip = ({
    active,
    price,
    date,
  }: {
    active?: boolean;
    price?: number;
    date?: number;
  }): JSX.Element | null => {
    if (active && price && date) {
      return (
        <Box
          backdropBlur="4px"
          backdropFilter="auto"
          bgColor={convertHexToRGBA(brandColor, 0.2)}
          borderRadius="md"
          p={4}
        >
          <Text fontSize="sm">
            {t('PricePerShare')}: <strong>{formatNumberCurrency(price)}</strong>
          </Text>
          <Text fontSize="sm">
            {t('Date', { nb: 1 })}: <strong>{dateFormatter(date)}</strong>
          </Text>
        </Box>
      );
    }

    return null;
  };

  const CustomizedLabel = ({
    x,
    y,
    value,
  }: {
    x: number;
    y: number;
    value: number;
  }): JSX.Element => {
    return (
      <text dy={-20} fontSize={12} fontWeight={600} textAnchor="middle" x={x} y={y}>
        {formatNumberCurrency(value)}
      </text>
    );
  };

  const brandColor = lineColor ? lineColor : (theme.color.primary as string);

  const xAxisPadding = displayLabel ? 20 : 0;
  return (
    <ResponsiveContainer height="100%" width="100%">
      <LineChart data={data} height={300} width={500}>
        <XAxis
          dataKey="x"
          domain={[dateFormatter(data[0].x), dateFormatter(data[data.length - 1].x)]}
          hide={!displayAxis}
          interval="preserveStartEnd"
          padding={{ left: xAxisPadding, right: xAxisPadding }}
          scale="time"
          stroke={brandColor}
          tick={{ fontSize: 12 }}
          tickFormatter={dateFormatter}
          type="number"
        />
        <YAxis hide dataKey="y" padding={{ top: displayLabel ? 25 : 0 }} />
        {displayTooltip && (
          <Tooltip
            content={(content): JSX.Element => {
              const { active, payload } = content;
              const price = payload?.length ? (payload[0].value as number) : undefined;
              const date = payload?.length ? payload[0].payload.x : undefined;

              return <CustomTooltip active={active} date={date} price={price} />;
            }}
          />
        )}
        <Line
          dataKey="y"
          dot={displayLabel}
          stroke={brandColor}
          type="monotone"
          label={(d): JSX.Element => {
            if (!displayLabel) return <></>;

            const isLastOrFirst = d.index === 0 || d.index === data.length - 1;
            return isLastOrFirst ? <CustomizedLabel value={d.value} x={d.x} y={d.y} /> : <></>;
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
