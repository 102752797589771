import {
  Alert,
  AlertIcon,
  AlertTitle,
  Link as ChakraLink,
  Grid,
  GridItem,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/solid';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import { IdentityType, useGetPendingBankDistributionsQuery } from '@blockpulse3/graphql/hooks';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  PhoneBanner,
} from '@blockpulse3/ui/commons';
import { useAuthUser, useIdentity, useManagedIndividual } from '@blockpulse3/web-client/auth';
import { Wallet } from '@blockpulse3/web-client/shareholding';
import { IdentityHeader } from '@blockpulse3/web-client/sidebar';

import { GenderPicker } from './GenderPicker';
import { InvitationList } from './InvitationList';
import { MyCompanies } from './MyCompanies';

type Props = unknown;

/**
 * UserDashboardView.
 * Dashboard of the authentified user.
 *
 * @returns {JSX.Element}
 */
export function UserDashboardView(): JSX.Element {
  const t = useTranslations();

  const { individual, refetch } = useManagedIndividual();
  const { user: authUser } = useAuthUser();
  const { identityId } = useIdentity();

  const { data } = useGetPendingBankDistributionsQuery({
    variables: { identityId },
    skip: !identityId,
  });
  const hasPendingBankDistributions = data?.getPendingBankDistributions?.length;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderPhoneBanner = isFeatureEnabled('phoneBanner');

  const handleFormSubmit = (): void => {
    refetch();
  };

  const profilePicture = individual?.identity?.profilePicture || '';
  const isVerified = individual?.identity?.isVerified || false;

  return (
    <Grid
      gap="10"
      gridTemplateColumns={{ base: '1fr', xl: '2fr 1fr' }}
      minHeight="0"
      minWidth="0"
      templateAreas={{
        base: `"header"
            "main"
            "side"`,
        xl: `"header header"
            "main side"`,
      }}
    >
      <GridItem area="header" minWidth="0">
        <Stack spacing="6">
          {!authUser?.individualIdentity?.gender && (
            <GenderPicker
              defaultValues={{ gender: '' }}
              user={authUser}
              onSubmit={handleFormSubmit}
            />
          )}
          <IdentityHeader>
            <IdentityCard onMouseEnter={onOpen} onMouseLeave={onClose}>
              <IdentityAvatar boxSize="14" src={profilePicture} type={IdentityType.INDIVIDUAL} />
              <HStack spacing="3">
                <IdentityCardTitle isChecked={isVerified}>
                  <Text>{individual?.name}</Text>
                </IdentityCardTitle>
                {isOpen && (
                  <HStack fontSize="sm" spacing="1">
                    <Icon as={PencilIcon} />
                    <Link fontWeight="400" href={routes.me.settings.href}>
                      {t('EditInformation')}
                    </Link>
                  </HStack>
                )}
                <Link
                  display={{ base: 'block', md: 'none' }}
                  fontWeight="400"
                  href={routes.me.settings.href}
                >
                  <Icon as={PencilIcon} />
                </Link>
              </HStack>
              <IdentityCardDescription>
                <Text>{individual?.address?.formatted}</Text>
                <Text>{individual?.email}</Text>
                <Text>{individual?.phone}</Text>
              </IdentityCardDescription>
            </IdentityCard>
          </IdentityHeader>
        </Stack>
      </GridItem>
      <GridItem area="main" minWidth="0">
        <Stack spacing="10">
          <InvitationList limit={3} size="md" />
          <Wallet />
        </Stack>
      </GridItem>
      <GridItem area="side" minWidth="0">
        <Stack spacing="4">
          {hasPendingBankDistributions && (
            <Alert status="info">
              <AlertIcon />
              <Stack spacing="0">
                <AlertTitle>
                  {t.rich('ConsultPendingPayments', {
                    link: (chunks) => (
                      <ChakraLink
                        as={ReactRouterLink}
                        display="block"
                        fontWeight="600"
                        textDecoration="underline"
                        to={routes.me.payments.href}
                      >
                        {chunks}
                      </ChakraLink>
                    ),
                  })}
                </AlertTitle>
              </Stack>
            </Alert>
          )}
          {renderPhoneBanner && <PhoneBanner />}
          <MyCompanies />
        </Stack>
      </GridItem>
    </Grid>
  );
}

export type UserDashboardViewProps = Props;
