import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  Text,
  useCheckboxGroup,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { MergedQuestionMultipleChoice } from '@blockpulse3/graphql/hooks';
import { ExternalLink } from '@blockpulse3/ui/commons';

import { QuestionTooltip } from '../QuestionTooltip';
import { useMifidModal } from '../provider';

type Props = {
  /* ** Current question ** */
  question: MergedQuestionMultipleChoice;
};

export function MultipleChoiceQuestion({ question }: Props): JSX.Element {
  const t = useTranslations();

  const { questionId, label, tooltip, externalLink } = question;

  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue: question.answerValues,
  });

  const { isSectionDisabled } = useMifidModal();

  const { setValue, control } = useFormContext();

  const handleChange = (value: (string | number)[]): void => {
    const formattedValues = value.map((val) => val.toString());
    setValue(questionId, formattedValues);
  };

  return (
    <Controller
      key={questionId}
      control={control}
      name={questionId}
      render={({ field }): JSX.Element => (
        <FormControl my="2">
          <FormLabel htmlFor={questionId}>
            <HStack spacing="1">
              <Text>
                {label}
                {externalLink && <ExternalLink link={externalLink} mx="1" title={t('LearnMore')} />}
                {tooltip && <QuestionTooltip label={tooltip} />}
              </Text>
            </HStack>
          </FormLabel>
          <CheckboxGroup
            isDisabled={isSectionDisabled}
            value={field.value || []}
            onChange={(value): void => handleChange(value)}
          >
            <Stack>
              {question.values.map((value) => {
                return (
                  <Checkbox
                    key={value.label}
                    spacing="4"
                    value={value.label}
                    variant="answer"
                    w="full"
                    {...getCheckboxProps({ value: value.label })}
                  >
                    {value.label}
                  </Checkbox>
                );
              })}
            </Stack>
          </CheckboxGroup>
        </FormControl>
      )}
    />
  );
}
