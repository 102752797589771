import * as yup from 'yup';

import { FormErrors, requiredNumberField } from '@blockpulse3/data/shared';

import { SubscriptionPanelInvestmentForm } from './types';

export const schema = yup.object({
  amount: requiredNumberField
    .test('min', FormErrors.MinimalAmount, (value, context) => {
      if (!context.parent.minimalAmount || value === 0) {
        return true;
      }
      return value >= context.parent.minimalAmount;
    })
    .test('max', FormErrors.MaximalAmount, (value, context) => {
      if (!context.parent.maximalAmount || value === 0) {
        return true;
      }
      return value <= context.parent.maximalAmount;
    })
    .when('$hardCap', ([hardCap], schema) => {
      return hardCap > 0 ? schema.max(hardCap, FormErrors.AmountGreaterThanHardCap) : schema;
    }),
  minimalAmount: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .test('min-max', FormErrors.MinimalAmountGreaterThanMaximal, (value, context) => {
      if (!value || value === 0 || !context.parent.maximalAmount) {
        return true;
      }
      return context.parent.maximalAmount >= value;
    })
    .test('min-negative', FormErrors.PositiveFormat, (value) => {
      if (!value || value === 0) {
        return true;
      }
      return value >= 0;
    })
    .when('$hardCap', ([hardCap], schema) => {
      return hardCap > 0 ? schema.max(hardCap, FormErrors.AmountGreaterThanHardCap) : schema;
    }),
  maximalAmount: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .test('max-min', FormErrors.MaximalAmountLessThanMinimal, (value, context) => {
      if (value === null || value === undefined || !context.parent.minimalAmount) {
        return true;
      }
      return value >= context.parent.minimalAmount;
    })
    .test('max-negative', FormErrors.PositiveFormat, (value) => {
      if (!value || value === 0) {
        return true;
      }
      return value >= 0;
    })
    .when('$hardCap', ([hardCap], schema) => {
      return hardCap > 0 ? schema.max(hardCap, FormErrors.AmountGreaterThanHardCap) : schema;
    }),
}) as yup.ObjectSchema<SubscriptionPanelInvestmentForm>;

export const subscriptionDefaultValues: SubscriptionPanelInvestmentForm = {
  amount: 0,
  minimalAmount: null,
  maximalAmount: null,
};
