import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { HStack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

type Props = {
  label: string;
  direction?: 'left' | 'right';
  route: string;
};

export function LinkButton({ label, direction = 'left', route }: Props): JSX.Element {
  return (
    <Link to={route}>
      <HStack spacing="1">
        {direction === 'left' && <ChevronLeftIcon color="secondary" h={6} w={6} />}
        <Text color="secondary" fontWeight="600">
          {label}
        </Text>
        {direction === 'right' && <ChevronRightIcon color="secondary" h={6} w={6} />}
      </HStack>
    </Link>
  );
}

export type LinkButtonProps = Props;
