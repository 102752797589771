import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  HStack,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { ArrowNarrowRightIcon, ExclamationIcon } from '@heroicons/react/outline';
import { useRef } from 'react';
import { useTranslations } from 'use-intl';

import { Exceptions } from '@blockpulse3/data/shared';
import {
  SubscriptionsFilterInput,
  useCountRemindableSubscriptionInvitationsQuery,
  useRemindSubscriptionInvitationsMutation,
  useRemindSubscriptionTestEmailMutation,
} from '@blockpulse3/graphql/hooks';
import {
  Input,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
  useInfoToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { OperationSubscribersType } from './types';
import { getReminderLabels } from './utils';

type Props = {
  operationId: string;
  filters: SubscriptionsFilterInput[];
  onClose: () => void;
  totalCount: number;
  subscribersType: OperationSubscribersType;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

export function OperationRemindersModal({
  operationId,
  onClose,
  filters,
  subscribersType,
  totalCount,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const { user } = useAuthUser();

  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();
  const infoToast = useInfoToast();

  const [remindSubscriptionInvitations, { loading: remindSubscriptionsLoading }] =
    useRemindSubscriptionInvitationsMutation();
  const [remindSubscriptionTestEmail, { loading: testRemindLoading }] =
    useRemindSubscriptionTestEmailMutation();

  const { data: countRemindableSubscriptionData, refetch } =
    useCountRemindableSubscriptionInvitationsQuery({
      variables: { operationId, filterBy: filters },
      fetchPolicy: 'no-cache',
    });

  const remindableCount =
    countRemindableSubscriptionData?.countRemindableSubscriptionInvitations || 0;
  const nonRemindableCount = totalCount - remindableCount;
  const canSendReminder = totalCount !== nonRemindableCount;

  const { modalTitle, modalBackupTitle, toastSuccessLabel, alertTitle, alertDescriptions } =
    getReminderLabels(subscribersType);

  const handleSubmit = async (): Promise<void> => {
    const subject = inputRef.current?.value;
    const message = textareaRef.current?.value;
    await remindSubscriptionInvitations({
      variables: { operationId, filterBy: filters, customSubject: subject, customMessage: message },
      onCompleted: (data) => {
        successToast({
          title: t(toastSuccessLabel, {
            nb: data.remindSubscriptionInvitations.successCount,
          }),
        });
        refetch();
      },
      onError: (err: unknown) => {
        const error = err as Error;
        if (error.message === Exceptions.FailedToFetch) {
          infoToast({ title: t('SendingEmailsInProgress') });
        } else {
          errorToast({ title: t('ErrorSendingMailToSubscribers') });
        }
      },
    });
    onClose();
  };

  const handleSubmitTestEmail = async (): Promise<void> => {
    const subject = inputRef.current?.value;
    const message = textareaRef.current?.value;
    await remindSubscriptionTestEmail({
      variables: { operationId, filterBy: filters, customSubject: subject, customMessage: message },
      onCompleted: () => {
        successToast({
          title: t('TestEmailSentSuccessfully'),
        });
        refetch();
      },
      onError: (err: unknown) => {
        const error = err as Error;
        if (error.message === Exceptions.FailedToFetch) {
          infoToast({ title: t('SendingEmailsInProgress') });
        } else {
          errorToast({ title: t('ErrorSendingMailToSubscribers') });
        }
      },
    });
  };

  return (
    <ResponsiveModal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {remindableCount > 0 ? t(modalTitle, { nb: remindableCount }) : t(modalBackupTitle)}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Alert status="warning">
            <AlertIcon as={ExclamationIcon} />
            <Stack spacing="0">
              <AlertTitle>
                {!canSendReminder
                  ? t(alertTitle)
                  : t('SendEmailToSubscribers', {
                      nb: remindableCount,
                    })}
              </AlertTitle>
              {remindableCount > 0 && remindableCount < totalCount && (
                <AlertDescription>
                  {t(alertDescriptions, {
                    nb: nonRemindableCount,
                  })}
                </AlertDescription>
              )}
              <Button
                color="yellow.700"
                fontSize="small"
                fontWeight={600}
                isDisabled={testRemindLoading}
                mt={2}
                rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="4" />}
                variant="link"
                width="fit-content"
                onClick={handleSubmitTestEmail}
              >
                <Text>{t('SendTestEmailTo', { email: user?.email })}</Text>
              </Button>
            </Stack>
          </Alert>
          {canSendReminder && (
            <Accordion allowToggle mt="2" variant="unstyled">
              <AccordionItem>
                <AccordionButton data-cy="expand-parameters">
                  <Text color="gray" fontSize="sm" textAlign="left">
                    {t('CustomReminderMessageOptional')}
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb="0" pt="2">
                  <Stack spacing="2">
                    <Input
                      id="subject"
                      placeholder={t('EmailSubjectOptional')}
                      ref={inputRef}
                      size="sm"
                      width="full"
                    />
                    <Textarea id="message" minH="200" ref={textareaRef} size="sm" width="full" />
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter as={HStack}>
          <Button variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            isDisabled={!canSendReminder || remindSubscriptionsLoading || testRemindLoading}
            isLoading={remindSubscriptionsLoading}
            onClick={handleSubmit}
          >
            {t('Send')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
