import {
  Alert,
  AlertIcon,
  AlertTitle,
  Card,
  Link as ChakraLink,
  Grid,
  GridItem,
  Link,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, generatePath, resolvePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import {
  CompanyStatus,
  CompanyType,
  useGetCompanyQuery,
  useGetPendingBankDistributionsQuery,
} from '@blockpulse3/graphql/hooks';
import { PhoneBanner } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';
import { AssetsAndOptions, Wallet } from '@blockpulse3/web-client/shareholding';
import { CompanyHeader } from '@blockpulse3/web-client/sidebar';

import { InvitationList } from './InvitationList';
import { OperationList } from './OperationList';
import { SPVDashboardView } from './SPVDashboard';

type Props = unknown;

/**
 * CompanyDashboardView.
 * Dashboard of the company from the active workspace.
 *
 * @returns {JSX.Element}
 */
export function CompanyDashboardView(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const { identityId } = useIdentity();

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const company = companyReq?.data?.company;

  const { data } = useGetPendingBankDistributionsQuery({
    variables: { identityId },
    skip: !identityId,
  });
  const hasPendingBankDistributions = data?.getPendingBankDistributions?.length;

  const renderPhoneBanner = isFeatureEnabled('phoneBanner');

  const isComplete = company?.status === CompanyStatus.RUNNING;

  if (!company) {
    return (
      <Skeleton>
        <Card h="80px" />
      </Skeleton>
    );
  }

  if (company.type === CompanyType.SPV && company.status !== CompanyStatus.RUNNING) {
    return <SPVDashboardView />;
  }

  return (
    <Grid
      gap="10"
      gridTemplateColumns={{ base: '1fr', xl: '2fr 1fr' }}
      minHeight="0"
      minWidth="0"
      templateAreas={{
        base: `"header"
            "side"
            "main"`,
        xl: `"header header"
            "main side"`,
      }}
    >
      <GridItem area="header" minWidth="0">
        <Stack spacing="6">
          <CompanyHeader />
          {!isComplete && (
            <Alert status="info">
              <AlertIcon />
              <Stack spacing="0">
                <AlertTitle>
                  {t.rich('ResumeCompanyImportAlert', {
                    link: (chunks) => (
                      <Link
                        as={ReactRouterLink}
                        display="block"
                        fontWeight="600"
                        textDecoration="underline"
                        to={
                          resolvePath(
                            generatePath(routes.onboardings.company.edit.href, { companyId }),
                            routes.onboardings.company.href,
                          ).pathname
                        }
                      >
                        {chunks}
                      </Link>
                    ),
                  })}
                </AlertTitle>
              </Stack>
            </Alert>
          )}
        </Stack>
      </GridItem>
      <GridItem area="main" minWidth="0">
        <Stack spacing="10">
          {isComplete && <Wallet />}
          <OperationList displayType="cards" limit={3} />
          {isComplete && <AssetsAndOptions />}
        </Stack>
      </GridItem>
      <GridItem area="side" minWidth="0">
        <Stack spacing="10">
          {hasPendingBankDistributions && (
            <Alert status="info">
              <AlertIcon />
              <Stack spacing="0">
                <AlertTitle>
                  {t.rich('ConsultPendingDistributions', {
                    link: (chunks) => (
                      <ChakraLink
                        as={ReactRouterLink}
                        display="block"
                        fontWeight="600"
                        textDecoration="underline"
                        to={routes.company.distributions.href}
                      >
                        {chunks}
                      </ChakraLink>
                    ),
                  })}
                </AlertTitle>
              </Stack>
            </Alert>
          )}
          {renderPhoneBanner && <PhoneBanner />}
          <InvitationList limit={3} size="sm" />
        </Stack>
      </GridItem>
    </Grid>
  );
}

export type CompanyDashboardViewProps = Props;
