import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { alpha3CountryOptions } from '@blockpulse3/helpers';
import { ErrorMessage, Input } from '@blockpulse3/ui/commons';

import { schema, updateAddressDefaultValues } from './schema';
import { IUpdateAddressForm } from './types';

type Props = {
  defaultValues?: IUpdateAddressForm;
  onSubmit: (data: IUpdateAddressForm) => void;
};

/**
 * UpdateAddressForm.
 * Form to update the address of the user.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function UpdateAddressForm({
  defaultValues = updateAddressDefaultValues,
  onSubmit,
}: Props): JSX.Element {
  const t = useTranslations();

  /* ** Control if form is "touched" (!= changed) ** */
  const [isTouched, setIsTouched] = useState<boolean>(false);
  /* ** Update address form ** */
  const { register, control, formState, handleSubmit } = useForm<IUpdateAddressForm>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  /* ** Submit button disabled state ** */
  const isDisabled = useMemo(() => {
    return !formState.isValid || !isTouched;
  }, [isTouched, formState.isValid]);

  const handleFormTouched = (): void => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleFormSubmit: SubmitHandler<IUpdateAddressForm> = (data: IUpdateAddressForm) => {
    onSubmit(data);
  };

  return (
    <form onChange={handleFormTouched} onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }}>
        <Text flexBasis={{ lg: 250 }} fontSize="lg" fontWeight="medium">
          {t('MyAddress')}
        </Text>
        <Card maxW={{ lg: '3xl' }} variant="divider-bottom" w="full">
          <CardBody>
            <Stack spacing="6">
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors.line}>
                  <FormLabel htmlFor="line">{t('Street')}</FormLabel>
                  <Input id="line" type="text" {...register('line')} />
                  <ErrorMessage error={formState.errors.line} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors.city}>
                  <FormLabel htmlFor="city">{t('City')}</FormLabel>
                  <Input id="city" type="text" {...register('city')} />
                  <ErrorMessage error={formState.errors.city} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors.postalCode}>
                  <FormLabel htmlFor="postalCode">{t('PostalCode', { nb: 1 })}</FormLabel>
                  <Input id="postalCode" type="text" {...register('postalCode')} />
                  <ErrorMessage error={formState.errors.postalCode} />
                </FormControl>
                <Controller
                  control={control}
                  name="country"
                  render={({ field: { onChange, ...rest } }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors.country}>
                      <FormLabel htmlFor="country">{t('Country')}</FormLabel>
                      <Select
                        id="country"
                        options={alpha3CountryOptions}
                        onChange={(value): void => {
                          onChange(value);
                          handleFormTouched();
                        }}
                        {...rest}
                      />
                      <ErrorMessage error={formState.errors.country?.value} />
                    </FormControl>
                  )}
                />
              </Stack>
            </Stack>
          </CardBody>
          <Divider />
          <CardFooter justifyContent="flex-end">
            <Button disabled={isDisabled} type="submit">
              {t('Save')}
            </Button>
          </CardFooter>
        </Card>
      </Stack>
    </form>
  );
}

export type UpdateAddressFormProps = Props;
