import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import {
  OperationType,
  useExtendOperationMutation,
  useGetOperationLazyQuery,
} from '@blockpulse3/graphql/hooks';
import {
  DropzoneInput,
  ErrorMessage,
  Input,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { extendFundraisingOperationSchema, extendOperationSchema } from './schema';
import { IExtendOperationForm } from './type';

type Props = {
  operationId: string;
  operationType: OperationType;
  title?: string;
} & Omit<ResponsiveModalProps, 'children'>;

export function ExtendOperationModal({
  operationId,
  operationType,
  title,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
  const [pvDocumentFile, setPvDocumentFile] = useState<File>();
  const [pvDocumentFileName, setPvDocumentFileName] = useState<{ [key: string]: File }>({});

  const successToast = useSuccessToast();

  const { onClose } = props;

  const [getOperation] = useGetOperationLazyQuery();
  const [extendOperation, { loading: extendOperationLoading }] = useExtendOperationMutation();

  const isFundraising = operationType === OperationType.FUNDRAISING;

  const schemaType = isFundraising ? extendFundraisingOperationSchema : extendOperationSchema;
  const defaultValuesType = isFundraising ? { duration: 0, pvExerciceFile: '' } : { duration: 0 };

  const { register, control, formState, handleSubmit, setError, setValue } =
    useForm<IExtendOperationForm>({
      defaultValues: defaultValuesType,
      resolver: yupResolver(schemaType),
    });

  /* ** Upload file handler ** */
  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    if (fileRejections.length === 0) {
      setValue('pvExerciceFile', acceptedFiles[0].name);
      setPvDocumentFileName({
        1: new File([acceptedFiles[0].name], acceptedFiles[0].name),
      });
      setPvDocumentFile(acceptedFiles[0]);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('pvExerciceFile', {
            type: 'custom',
            message: FormErrors.FileTooLarge,
          });
          break;
        }
        default: {
          setError('pvExerciceFile', {
            type: 'custom',
            message: FormErrors.DropzoneDefault,
          });
          break;
        }
      }
    }
  };

  /* ** Method to upload a file using axios ** */
  const handleFileUpload = async (data: IExtendOperationForm): Promise<void> => {
    if (!pvDocumentFile) return;

    const formData = new FormData();
    formData.append('operationId', operationId);
    formData.append('extensionDuration', `${data.duration}`);
    formData.append('document', pvDocumentFile);

    const endpoint = `/fundraisings/${operationId}/extendFundraising`;

    setIsDocumentLoading(true);
    await axios
      .post(process.env['NX_API_CONTROLLER_ENDPOINT'] + endpoint, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        successToast({ title: t('SuccessfulFileUpload') });
        setIsDocumentLoading(false);
        getOperation({
          variables: {
            operationId,
          },
          fetchPolicy: 'network-only',
        });
        onClose();
      })
      .catch(() => {
        setError('pvExerciceFile', {
          type: 'custom',
          message: FormErrors.DropzoneDefault,
        });
        setIsDocumentLoading(false);
      });
  };

  const handleDocumentDelete = (): void => {
    setValue('pvExerciceFile', '');
    setPvDocumentFileName({});
  };

  const handleExtendOperation = async (data: IExtendOperationForm): Promise<void> => {
    await extendOperation({
      variables: {
        operationId,
        extendOperationInput: {
          extensionDuration: data.duration,
        },
      },
      onCompleted: (): void => {
        successToast({ title: t('OperationExtendedSuccessfully') });
        getOperation({
          variables: {
            operationId,
          },
          fetchPolicy: 'network-only',
        });
        onClose();
      },
    });
  };

  const handleFormSubmit: SubmitHandler<IExtendOperationForm> = async (
    data: IExtendOperationForm,
  ): Promise<void> => {
    if (isFundraising) {
      handleFileUpload(data);
    } else {
      handleExtendOperation(data);
    }
  };

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <ModalHeader>{title || t('ExtendOperation')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            {isFundraising && (
              <Alert status="info">
                <AlertIcon />
                <Stack spacing="2">
                  <AlertTitle>{t('ExtendOperationReminder')}</AlertTitle>
                </Stack>
              </Alert>
            )}
            <form id="extend-operation-form" onSubmit={handleSubmit(handleFormSubmit)}>
              <Stack>
                <FormControl isInvalid={!!formState.errors?.duration}>
                  <FormLabel htmlFor="extendDuration">{t('ExtendOperationDuration')}</FormLabel>
                  <InputGroup>
                    <Input id="extendDuration" type="number" {...register('duration')} />
                    <InputRightAddon>{t('DurationUnits.day')}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.duration} />
                </FormControl>
                {isFundraising && (
                  <Controller
                    control={control}
                    name="pvExerciceFile"
                    render={(): JSX.Element => (
                      <FormControl>
                        <FormLabel>{t('UploadPVExercice')}</FormLabel>
                        <DropzoneInput
                          accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
                          files={pvDocumentFileName}
                          isLoading={isDocumentLoading}
                          maxFiles={1}
                          subTitle={t('ImageUpTo5MB')}
                          onDelete={handleDocumentDelete}
                          onDrop={handleFileDrop}
                        />
                        <ErrorMessage error={formState.errors?.pvExerciceFile} />
                      </FormControl>
                    )}
                  />
                )}
              </Stack>
            </form>
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button
            form="extend-operation-form"
            isLoading={isDocumentLoading || extendOperationLoading}
            type="submit"
          >
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
