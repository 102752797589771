import { Button, Container, Heading, Link, Stack, Text } from '@chakra-ui/react';
import { FaGoogle, FaMicrosoft, FaYahoo } from 'react-icons/fa';
import { useTranslations } from 'use-intl';

import { BrandIcon } from '@blockpulse3/ui/brand-theme';

type Props = {
  email: string;
};

/**
 * CheckEmail.
 * Mail link providers CTA component.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function CheckEmail({ email }: Props): JSX.Element {
  const t = useTranslations();

  const message = t('ClickTemporaryLink', { email });
  const emailIndex = message.search(new RegExp(email.replace('+', '\\+')));
  const textAfterEmail = message.substring(emailIndex + email.length, message.length);
  const textBeforeEmail = message.substring(0, emailIndex);

  return (
    <Container bg={{ base: 'transparent', md: 'bg-surface' }} maxW="lg">
      <Stack align="center" spacing="12">
        <Stack align="center" spacing="6">
          <BrandIcon alignSelf="flex-start" />
          <Heading alignSelf="start" size="lg">
            {t('VerifyEmail')}
          </Heading>
          <Text maxWidth="345px">
            <Text as="span" textAlign="start">
              {textBeforeEmail}
            </Text>
            <Text as="span" fontWeight="bold" textAlign="start">
              {email}
            </Text>
            <Text as="span" textAlign="start">
              {textAfterEmail}
            </Text>
          </Text>
          <Stack align="center" direction="row" spacing={2}>
            <Link isExternal href="https://mail.google.com/">
              <Button
                borderColor="gray.300"
                leftIcon={<FaGoogle />}
                size="md"
                variant="outline"
                width="110px"
              >
                Gmail
              </Button>
            </Link>
            <Link isExternal href="https://outlook.live.com/">
              <Button
                borderColor="gray.300"
                leftIcon={<FaMicrosoft />}
                size="md"
                variant="outline"
                width="110px"
              >
                Outlook
              </Button>
            </Link>
            <Link isExternal href="https://mail.yahoo.com/">
              <Button
                borderColor="gray.300"
                leftIcon={<FaYahoo />}
                size="md"
                variant="outline"
                width="110px"
              >
                Yahoo
              </Button>
            </Link>
          </Stack>
          <Button w="full">{t('ResendLink')}</Button>
        </Stack>
      </Stack>
    </Container>
  );
}
