import { useCallback, useContext, useState } from 'react';

import { IStepFormContext, StepFormContext } from './context';

type Props = {
  children: React.ReactNode;
};

export function StepFormContextProvider({ children }: Props): JSX.Element {
  const [handleStepCancel, setHandleStepCancel] = useState(() => () => {});
  const [handleStepSubmit, setHandleStepSubmit] = useState(() => () => {});

  const setCancelHandler = useCallback((handler: () => void): void => {
    setHandleStepCancel(() => handler);
  }, []);

  const setSubmitHandler = useCallback((handler: () => void): void => {
    setHandleStepSubmit(() => handler);
  }, []);

  const value: IStepFormContext = {
    setCancelHandler,
    handleStepCancel,
    setSubmitHandler,
    handleStepSubmit,
  };

  return <StepFormContext.Provider value={value}>{children}</StepFormContext.Provider>;
}

export function useStepFormContext(): IStepFormContext {
  return useContext(StepFormContext);
}

export type StepFormContextProviderProps = Props;
