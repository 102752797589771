import { Card, CardBody } from '@chakra-ui/react';

import { AdminRegistrationsTable } from './AdminRegistrationsTable';

export function AdminRegistrationsList(): JSX.Element {
  return (
    <Card>
      <CardBody>
        <AdminRegistrationsTable />
      </CardBody>
    </Card>
  );
}
