import { Navigate, Route, Routes } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { IdentityContextProvider } from '@blockpulse3/web-client/auth';
import { ClosePopup } from '@blockpulse3/web-client/dashboard';
import { WorkspaceSwitcherContextProvider } from '@blockpulse3/web-client/sidebar';
import { InvitationDeclinedView, VerifyEmailView } from '@blockpulse3/web-client/user';

import { CenterLayout, EmptyLayout } from '../layouts';
import { CompanyWorkspaceRoutes } from './CompanyWorkspaceRoutes';
import { ManageRoutes } from './ManageRoutes';
import { OnboardingCompanyRoutes } from './OnboardingCompanyRoutes';
import { OnboardingSPVRoutes } from './OnboardingSPVRoutes';
import { SpaceRoutes } from './SpaceRoutes';
import { UserWorkspaceRoutes } from './UserWorkspaceRoutes';

type Props = unknown;

export function AppScreen(): JSX.Element {
  return (
    <Routes>
      <Route element={<IdentityContextProvider />}>
        <Route element={<WorkspaceSwitcherContextProvider />}>
          <Route element={<SpaceRoutes />} path={routes.space.href + '/*'} />
          <Route element={<ManageRoutes />} path={routes.manage.href + '/*'} />
          <Route element={<UserWorkspaceRoutes />} path={routes.me.href + '/*'} />
          <Route element={<CompanyWorkspaceRoutes />} path={routes.company.href + '/*'} />
          <Route element={<CenterLayout />}>
            <Route element={<InvitationDeclinedView />} path={routes.invitationDeclined.href} />
            <Route element={<VerifyEmailView />} path={routes.verifyEmail.href} />
          </Route>
          <Route element={<EmptyLayout />}>
            <Route element={<ClosePopup />} path={routes.closePopup.href} />
          </Route>
          <Route
            element={<OnboardingCompanyRoutes />}
            path={routes.onboardings.company.href + '/*'}
          />
          <Route element={<OnboardingSPVRoutes />} path={routes.onboardings.spv.href + '/*'} />
          <Route element={<Navigate replace to={routes.me.href} />} path="*" />
        </Route>
      </Route>
    </Routes>
  );
}

export type AppScreenProps = Props;
