import { Stack, useRadioGroup } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FiscalAdvantage, HoldingMethod, noop } from '@blockpulse3/data/shared';
import { useGetSubscriptionQuery, useGetWalletsQuery } from '@blockpulse3/graphql/hooks';

import { HoldingMethodEntry } from './HoldingMethodEntry';
import { IWalletForm } from './types';
import { getWalletValues, mapWalletsByHoldingMethod } from './utils';

type Props = {
  identityId: string;
  assetId?: string;
  defaultValue?: HoldingMethod;
  isDisabled?: boolean;
  isReading?: boolean;
  allowedFiscalAdvantages?: FiscalAdvantage[];
  allowedHoldingMethods?: HoldingMethod[];
  onHoldingMethodChange?: (value: HoldingMethod) => void;
  onWalletCreate?: (holdingMethod: HoldingMethod) => void;
  onWalletUpdate?: (wallet: IWalletForm) => void;
};

export function HoldingMethodSelect({
  identityId,
  assetId = '',
  defaultValue,
  isDisabled = false,
  isReading = false,
  allowedFiscalAdvantages = [],
  allowedHoldingMethods = [HoldingMethod.DIRECT, HoldingMethod.ADMINISTERED],
  onHoldingMethodChange = noop,
  onWalletCreate = noop,
  onWalletUpdate = noop,
}: Props): JSX.Element {
  const { subscriptionId = '' } = useParams();

  const { data } = useGetWalletsQuery({
    variables: { identityId },
    skip: !identityId,
  });
  const { wallets = [] } = data || {};

  const { data: subscriptionData } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !identityId || !subscriptionId || !assetId,
  });
  const formattedWallets = mapWalletsByHoldingMethod(wallets, subscriptionData, assetId);

  const { getRootProps, getRadioProps, setValue, value } = useRadioGroup({
    defaultValue,
    onChange: onHoldingMethodChange,
  });

  useEffect(() => {
    if (defaultValue && defaultValue !== value) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const hasIrPme = allowedFiscalAdvantages.includes(FiscalAdvantage.IR_PME);

  const handleWalletAdd = (holdingMethod: HoldingMethod): void => {
    onWalletCreate(holdingMethod);
  };

  const handleWalletUpdate = (holdingMethod: HoldingMethod): void => {
    const walletValues = getWalletValues(formattedWallets[holdingMethod]);
    onWalletUpdate(walletValues);
  };

  return (
    <Stack {...getRootProps()}>
      {(isReading || allowedHoldingMethods.includes(HoldingMethod.DIRECT)) && (
        <HoldingMethodEntry
          hasIrPme={hasIrPme}
          isDisabled={isDisabled}
          isReading={isReading}
          value={HoldingMethod.DIRECT}
          wallet={formattedWallets[HoldingMethod.DIRECT]}
          {...getRadioProps({ value: HoldingMethod.DIRECT })}
        />
      )}
      {(isReading || allowedHoldingMethods.includes(HoldingMethod.ADMINISTERED)) && (
        <HoldingMethodEntry
          isDisabled={isDisabled}
          isReading={isReading}
          value={HoldingMethod.ADMINISTERED}
          wallet={formattedWallets[HoldingMethod.ADMINISTERED]}
          onAddWallet={(): void => handleWalletAdd(HoldingMethod.ADMINISTERED)}
          onUpdateWallet={(): void => handleWalletUpdate(HoldingMethod.ADMINISTERED)}
          {...getRadioProps({ value: HoldingMethod.ADMINISTERED })}
        />
      )}
      {(isReading || allowedFiscalAdvantages.includes(FiscalAdvantage.PEA)) && (
        <HoldingMethodEntry
          isDisabled={isDisabled}
          isReading={isReading}
          value={HoldingMethod.PEA}
          wallet={formattedWallets[HoldingMethod.PEA]}
          onAddWallet={(): void => handleWalletAdd(HoldingMethod.PEA)}
          onUpdateWallet={(): void => handleWalletUpdate(HoldingMethod.PEA)}
          {...getRadioProps({ value: HoldingMethod.PEA })}
        />
      )}
      {(isReading || allowedFiscalAdvantages.includes(FiscalAdvantage.PEA_PME)) && (
        <HoldingMethodEntry
          isDisabled={isDisabled}
          isReading={isReading}
          value={HoldingMethod.PEA_PME}
          wallet={formattedWallets[HoldingMethod.PEA_PME]}
          onAddWallet={(): void => handleWalletAdd(HoldingMethod.PEA_PME)}
          onUpdateWallet={(): void => handleWalletUpdate(HoldingMethod.PEA_PME)}
          {...getRadioProps({ value: HoldingMethod.PEA_PME })}
        />
      )}
    </Stack>
  );
}
