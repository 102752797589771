import { Button, Card, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

import { useWorkspaceSwitcher } from './provider';

export function CompanyImportButton(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const { onSwitcherClose } = useWorkspaceSwitcher();

  const handleAddExistingCompany = (): void => {
    navigate(routes.onboardings.company.href);
    onSwitcherClose();
  };

  return (
    <Card>
      <Button
        data-cy="add-existing-company"
        h="auto"
        justifyContent="flex-start"
        px="5"
        py="3"
        variant="ghost"
        onClick={handleAddExistingCompany}
      >
        <HStack alignItems="center" textAlign="left" whiteSpace="normal">
          <Flex
            alignItems="center"
            bg="gray.100"
            boxSize="32px"
            flexShrink="0"
            justifyContent="center"
            mr="2"
            rounded="sm"
          >
            <Icon as={PlusIcon} boxSize="18px" />
          </Flex>
          <Text>{t('ExistingCompanyImportAction')}</Text>
        </HStack>
      </Button>
    </Card>
  );
}
