import { Image } from '@chakra-ui/react';

import { ThemeIcon, ThemeIconProps } from '../../components';
import Logo from './assets/logo.svg';

function LightIcon(): JSX.Element {
  return <Image alt="France Angels" height="full" loading="lazy" src={Logo} />;
}

function DarkIcon(): JSX.Element {
  return <Image alt="France Angels" height="full" loading="lazy" src={Logo} />;
}

export function SmallIcon({ ...props }: ThemeIconProps): JSX.Element {
  return <ThemeIcon darkIcon={<DarkIcon />} lightIcon={<LightIcon />} {...props} />;
}
