import { ModalOverlay } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { ResponsiveModal, ResponsiveModalProps } from '@blockpulse3/ui/commons';

import {
  CompanyVerificationStep,
  IdentityInformationVerificationStep,
  SwanIdentityOnboardingStep,
  UserVerificationStep,
} from './VerificationSteps';
import { VerificationStepContextProvider } from './providers';

type Props = {
  refetchData: () => void;
  shouldVerifyInformation?: boolean;
  isSwanOnboarding?: boolean;
  isIssuer?: boolean;
} & Omit<ResponsiveModalProps, 'children'>;

/**
 * IdentityVerificationModal.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function IdentityVerificationModal({
  refetchData,
  shouldVerifyInformation = false,
  isSwanOnboarding = false,
  isIssuer = false,
  ...props
}: Props): JSX.Element {
  const { companyId = '' } = useParams();

  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const handleNextStep = (): void => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleOnClose = (): void => {
    refetchData();
    setActiveStep(0);
    props.onClose();
  };

  const handleVerificationModify = (): void => {
    if (companyId) {
      navigate(routes.company.settings.href);
    } else {
      navigate(routes.me.settings.href);
    }
  };

  const steps = [
    <IdentityInformationVerificationStep
      key="identity-information-verification-step"
      isIssuer={isIssuer}
      onModify={handleVerificationModify}
      onSubmit={handleNextStep}
    />,
  ];

  if (isSwanOnboarding) {
    steps.push(<SwanIdentityOnboardingStep key="swan-onboarding-step" onClose={handleOnClose} />);
  } else {
    if (companyId) {
      steps.push(
        <CompanyVerificationStep key="company-verification-step" onSubmit={handleNextStep} />,
      );
    }
    steps.push(<UserVerificationStep key="user-verification-step" onClose={handleOnClose} />);
  }

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <VerificationStepContextProvider activeSteps={steps.length} stepIndex={activeStep}>
        {steps[activeStep]}
      </VerificationStepContextProvider>
    </ResponsiveModal>
  );
}

export type IdentityVerificationModalProps = Props;
