import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  theme,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { IntlSpaceTypes } from '@blockpulse3/data/locales/types';
import { noop } from '@blockpulse3/data/shared';
import { Space, useGetSpaceQuery, useUpdateSpaceMutation } from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  ErrorQueryModal,
  Input,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  SkeletonModal,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { schema } from './schema';
import { SpaceForm } from './types';
import { getBrandOptions, getSpace, getSpaceTypeOptions } from './utils';

type Props = Omit<ResponsiveModalProps, 'children'> & {
  /* ** Space to update ** */
  spaceId: Space['id'];
  /* ** Boolean to check if the space has a provider ** */
  hasProvider?: boolean;
  /* ** Callback on form submit success ** */
  onSubmitSuccess?: () => void;
  /* ** Callback on form submit error ** */
  onSubmitError?: () => void;
};

/**
 * UpdateSpaceFormModal.
 * Modal form to update an existing space.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function UpdateSpaceFormModal({
  spaceId,
  onSubmitSuccess = noop,
  onSubmitError = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nSpaceTypes = useTranslations('SpaceTypes');

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { user: authUser } = useAuthUser();
  const isSuperAdmin = authUser?.isSuperAdmin;

  const brandOptions = getBrandOptions();
  const spaceTypeOptions = getSpaceTypeOptions(!isSuperAdmin);

  const { register, control, formState, handleSubmit, reset } = useForm<SpaceForm>({
    resolver: yupResolver(schema),
    context: { isUpdate: true },
  });

  const { data, loading, error } = useGetSpaceQuery({
    variables: { spaceId },
    onCompleted: ({ space }) => {
      const spaceForm = getSpace(space);
      reset({ ...spaceForm });
    },
  });

  const [updateSpace, { loading: updateSpaceLoading }] = useUpdateSpaceMutation();

  const handleFormSubmit: SubmitHandler<SpaceForm> = (data) => {
    const { name, type, brand } = data;

    updateSpace({
      variables: {
        updateSpaceInput: {
          spaceId,
          name,
          type: type.value,
          brand: isSuperAdmin ? brand.value : undefined,
        },
      },
      onCompleted: () => {
        successToast({ title: t('UpdateSpaceSuccess') });
        onSubmitSuccess();
      },
      onError: () => {
        errorToast({ title: t('UpdateSpaceError') });
        onSubmitError();
      },
    });
  };

  if (loading) {
    return (
      <ResponsiveModal {...props}>
        <ModalOverlay />
        <SkeletonModal minHeight="350px" />
      </ResponsiveModal>
    );
  }

  if (error) {
    return (
      <ResponsiveModal {...props}>
        <ModalOverlay />
        <ErrorQueryModal />
      </ResponsiveModal>
    );
  }

  if (!data) {
    return (
      <ResponsiveModal {...props}>
        <ModalOverlay />
        <ErrorQueryModal />
      </ResponsiveModal>
    );
  }

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('EditSpace')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="update-space" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <FormControl isInvalid={!!formState.errors.name}>
                <FormLabel>{t('Name')}</FormLabel>
                <Input type="text" {...register('name')} />
                <ErrorMessage error={formState.errors.name} />
              </FormControl>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <Controller
                  control={control}
                  name="type"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors.type}>
                      <FormLabel htmlFor="type">{t('Type')}</FormLabel>
                      <Select
                        id="type"
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        options={spaceTypeOptions}
                        getOptionLabel={(option): string =>
                          i18nSpaceTypes(option.value as IntlSpaceTypes)
                        }
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: theme.zIndices.modal + 1,
                          }),
                        }}
                        {...field}
                      />
                    </FormControl>
                  )}
                />
                {isSuperAdmin && (
                  <Controller
                    control={control}
                    name="brand"
                    render={({ field }): JSX.Element => (
                      <FormControl isInvalid={!!formState.errors.type}>
                        <FormLabel htmlFor="brand">{t('Brand')}</FormLabel>
                        <Select
                          id="brand"
                          menuPlacement="auto"
                          menuPortalTarget={document.body}
                          options={brandOptions}
                          styles={{
                            menuPortal: (provided) => ({
                              ...provided,
                              zIndex: theme.zIndices.modal + 1,
                            }),
                          }}
                          {...field}
                        />
                      </FormControl>
                    )}
                  />
                )}
              </Stack>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button type="button" variant="secondary" onClick={props.onClose}>
            {t('Cancel')}
          </Button>
          <Button form="update-space" isLoading={updateSpaceLoading} type="submit">
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type UpdateSpaceFormModalProps = Props;
