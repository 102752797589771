import { Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { IdentityType, useGetSubscriptionQuery } from '@blockpulse3/graphql/hooks';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';

/**
 * TransactionPanelIdentityCard.
 * Display identity of Seller.
 *
 * @returns {JSX.Element}
 */
export function TransactionPanelIdentityCard(): JSX.Element {
  const { subscriptionId = '' } = useParams();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  if (loading || !data || error) {
    return (
      <Stack px="4">
        <Skeleton h="80px" />
      </Stack>
    );
  }

  const { sellerIdentity } = data.subscription;

  const isLegal = sellerIdentity?.type === IdentityType.COMPANY;
  const name = `${sellerIdentity?.name}${isLegal ? ` (${sellerIdentity?.identifier})` : ''}`;

  return (
    <IdentityCard px="4">
      <IdentityAvatar
        boxSize="14"
        src={sellerIdentity?.profilePicture}
        type={sellerIdentity?.type}
      />
      <IdentityCardTitle isChecked={sellerIdentity?.isVerified}>
        <Text>{name}</Text>
      </IdentityCardTitle>
      <IdentityCardDescription>{sellerIdentity?.email}</IdentityCardDescription>
    </IdentityCard>
  );
}
