import { HStack } from '@chakra-ui/react';
import { Column, Table } from '@tanstack/react-table';

import { DebouncedInput } from './DebouncedInput';

export function Filter({
  column,
  table,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>;
}): JSX.Element {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === 'number' ? (
    <HStack spacing="1">
      <DebouncedInput
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
        maxW="xs"
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
        rounded="sm"
        size="sm"
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        placeholder={`Min ${
          column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
        }`}
        onChange={(value): void =>
          column.setFilterValue((old: [number, number]) => [value, old?.[1]])
        }
      />
      <DebouncedInput
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
        maxW="xs"
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
        rounded="sm"
        size="sm"
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        placeholder={`Max ${
          column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
        }`}
        onChange={(value): void =>
          column.setFilterValue((old: [number, number]) => [old?.[0], value])
        }
      />
    </HStack>
  ) : (
    <DebouncedInput
      maxW="xs"
      placeholder="Rechercher..."
      rounded="sm"
      size="sm"
      type="text"
      value={(columnFilterValue ?? '') as string}
      onChange={(value): void => column.setFilterValue(value)}
    />
  );
}
