import { useCallback, useMemo } from 'react';

export type IBadge = {
  color: string;
  label: string;
};

export type IUseBadge = {
  badge: IBadge;
  getBadge: (value: IBadgeValue) => IBadge;
};

export type IBadgeValue = string | boolean | number | undefined | null;
export type IBadgeOption = { value: IBadgeValue } & IBadge;

/**
 * useBadge.
 * Hook to reuse value check over a list of options.
 * Returns the desired color and label.
 * This avoid multiple switch blocks inside components.
 *
 * @param {IBadgeValue} value
 * @param {Array} options
 * @param {IBadge} initialBadge
 * @returns {IBadge}
 */
export function useBadge(
  value: IBadgeValue,
  options: IBadgeOption[],
  initialBadge: IBadge,
): IUseBadge {
  const getBadge = useCallback(
    (v: IBadgeValue) => {
      let result = options.find((option) => option.value === v);
      if (!result) {
        result = options.find((option) => v?.toString().startsWith(option.value?.toString() || ''));
      }
      if (result) {
        return { color: result.color, label: result.label };
      }
      return initialBadge;
    },
    [initialBadge, options],
  );

  const badge = useMemo(() => getBadge(value), [getBadge, value]);

  return { badge, getBadge };
}
