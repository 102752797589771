import { Avatar, Button, ButtonProps, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { ShieldExclamationIcon } from '@heroicons/react/solid';

import { noop } from '@blockpulse3/data/shared';

type Props = {
  /* ** Workspace ID ** */
  spaceId: string;
  /* ** Item name ** */
  name: string;
  /* ** Item subtitle ** */
  children: React.ReactNode;
  /* ** Callback on click ** */
  onItemClick?: (value: string) => void;
} & ButtonProps;

/**
 * SpaceSwitcherItem.
 * Component rendering spaces in Switcher list.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SpaceSwitcherItem({
  spaceId,
  name,
  children,
  onItemClick = noop,
  ...props
}: Props): JSX.Element {
  /* ** Handle item click, pass value ** */
  const handleItemClick = (): void => {
    onItemClick(spaceId);
  };

  return (
    <Button {...props} variant="item" onClick={handleItemClick}>
      <HStack justifyContent="space-between" w="full">
        <HStack spacing="3">
          <Avatar
            boxSize="14"
            flexShrink="0"
            icon={<Icon as={ShieldExclamationIcon} boxSize="50%" color="gray.400" />}
            rounded="sm"
          />
          <Stack alignItems="flex-start" spacing="1" title={name}>
            <Text fontSize="md" noOfLines={1} title={name} wordBreak="break-all">
              {name}
            </Text>
            {children}
          </Stack>
        </HStack>
      </HStack>
    </Button>
  );
}
