import {
  Alert,
  AlertIcon,
  AlertTitle,
  Badge,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { PauseIcon, XCircleIcon } from '@heroicons/react/solid';
import { generatePath, resolvePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  IdentityType,
  OperationInvestStatus,
  SubscriptionSide,
  SubscriptionStepStatus,
  SubscriptionStepType,
  useGetCompaniesQuery,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useSubscriptionBadge } from '@blockpulse3/ui/ui-hooks';
import { useIdentity, useManagedIndividual } from '@blockpulse3/web-client/auth';

import * as SubscriptionStepsComponents from './Steps';
import { isStepDisabled } from './utils';

type Props = BoxProps;

/**
 * SubscriptionSteps.
 *
 * @returns {JSX.Element}
 */
export function SubscriptionSteps({ ...props }: Props): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();
  const { subscriptionId = '', companyId = '' } = useParams();

  const { individual } = useManagedIndividual();

  const { identityId } = useIdentity();

  /* ** Fetch subscription ** */
  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const { subscription = null } = data || {};
  const subscriptionBadge = useSubscriptionBadge(subscription);

  const companiesReq = useGetCompaniesQuery();

  if (loading || !identityId) {
    return (
      <Skeleton {...props}>
        <Card h="375px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard {...props} h="375px" />;
  }

  if (!subscription) {
    return <ErrorQueryCard {...props} h="375px" />;
  }

  // Redirect to the right subscription url
  const subscriptionSide = subscription.side || SubscriptionSide.BUYER;
  const isSeller = subscriptionSide === SubscriptionSide.SELLER;
  const subscriberIdentity = isSeller ? subscription.sellerIdentity : subscription.buyerIdentity;
  if (subscriberIdentity?.id !== identityId) {
    const companyIds = companiesReq.data?.companies.map((company) => company.id) || [];

    const isSubscriberCompany = subscriberIdentity?.type === IdentityType.COMPANY;
    const subscriptionPath = generatePath(routes.subscription.href, { subscriptionId });
    if (
      isSubscriberCompany &&
      !companyId &&
      companyIds.includes(subscriberIdentity?.companyIdentity?.id || '')
    ) {
      navigate(
        resolvePath(
          subscriptionPath,
          generatePath(routes.company.href, { companyId: subscriberIdentity?.companyIdentity?.id }),
        ).pathname,
      );
    } else if (
      !isSubscriberCompany &&
      companyId &&
      individual?.identity?.id === subscriberIdentity?.id
    ) {
      navigate(resolvePath(subscriptionPath, routes.me.href).pathname);
    } else {
      const otherIdentity = isSeller ? subscription.buyerIdentity : subscription.sellerIdentity;
      if (
        otherIdentity?.type === IdentityType.COMPANY &&
        companyIds.includes(otherIdentity?.companyIdentity?.id || '')
      ) {
        navigate(
          resolvePath(
            subscriptionPath,
            generatePath(routes.company.href, { companyId: otherIdentity?.companyIdentity?.id }),
          ).pathname,
        );
      } else if (companyId) {
        navigate(resolvePath(subscriptionPath, routes.me.href).pathname);
      } else {
        /* ** 'setState' ** */
        navigate(routes.me.href);
      }
    }
  }

  /* ** Compute completed steps ** */
  const subscriptionSteps = subscription.data.mergedSteps;

  const excludedOppositeStepTypes = [
    SubscriptionStepType.IDENTITY_VERIFICATION,
    SubscriptionStepType.SWAN_ONBOARDING,
  ];
  const filteredSubscriptionSteps = subscriptionSteps.filter((step) => {
    return (
      !step.side || step.side === subscriptionSide || !excludedOppositeStepTypes.includes(step.type)
    );
  });

  const completeSteps =
    filteredSubscriptionSteps?.reduce(
      (acc, step) => (step.status === SubscriptionStepStatus.VALID ? acc + 1 : acc),
      0,
    ) || 0;

  // List available components for steps
  const StepsComponents: Record<
    string,
    ({
      subscriptionStep,
      isDisabled,
    }: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      subscriptionStep: any;
      isDisabled: boolean;
    }) => JSX.Element | null
  > = {};
  const { MifidModal, ...restSubscriptionStepsComponents } = SubscriptionStepsComponents;
  Object.entries(restSubscriptionStepsComponents).forEach(([name, value]) => {
    return (StepsComponents[name] = value);
  });

  return (
    <Card {...props}>
      <CardHeader>
        <Stack alignItems="flex-start" spacing="0">
          <HStack alignItems="center">
            <Heading size="md">{t(isSeller ? 'MySale' : 'MyInvestment')}</Heading>
            <Badge colorScheme={subscriptionBadge.color}>{subscriptionBadge.label}</Badge>
          </HStack>
          {![OperationInvestStatus.DECLINED, OperationInvestStatus.REFUSED].includes(
            subscription?.investStatus,
          ) && (
            <Text fontSize="sm">
              {t.rich('CompletionSteps', {
                completeSteps,
                totalStep: filteredSubscriptionSteps.length,
                important: (chunks) => (
                  <Text as="span" fontWeight="bold">
                    {chunks}
                  </Text>
                ),
              })}
            </Text>
          )}
        </Stack>
      </CardHeader>
      <CardBody>
        {[OperationInvestStatus.DECLINED, OperationInvestStatus.REFUSED].includes(
          subscription?.investStatus,
        ) || !filteredSubscriptionSteps?.length ? (
          <Alert status="error">
            <AlertIcon as={XCircleIcon} />
            <AlertTitle>
              {t(
                subscription?.investStatus === OperationInvestStatus.DECLINED
                  ? 'SubscriptionDeclinedUserMessage'
                  : 'SubscriptionNotFinalizedOnTime',
              )}
            </AlertTitle>
          </Alert>
        ) : (
          <Stack spacing="3">
            {subscription.locked && (
              <Alert status="warning">
                <AlertIcon as={PauseIcon} />
                <AlertTitle>{t('SubscriptionGoalReachedDisabled')}</AlertTitle>
              </Alert>
            )}
            {filteredSubscriptionSteps.map((step) => {
              if (step.__typename) {
                const index = subscriptionSteps.findIndex(
                  ({ subscriptionStepId }) => subscriptionStepId === step.subscriptionStepId,
                );
                const isDisabled =
                  subscription.locked || isStepDisabled(subscriptionSteps, index, subscriptionSide);
                const StepComponent = StepsComponents[step.__typename.replace('Merged', '')];
                return (
                  <StepComponent
                    key={step.subscriptionStepId}
                    isDisabled={isDisabled}
                    subscriptionStep={step}
                  />
                );
              }
              return null;
            })}
          </Stack>
        )}
      </CardBody>
    </Card>
  );
}

export type SubscriptionStepsProps = Props;
