import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useTranslations } from 'use-intl';

import {
  Identity,
  MovementType,
  MovementWithSubscriptionInfosFragment,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import { AssetBadge } from '@blockpulse3/ui/commons';
import { SubscriptionPanelDocuments } from '@blockpulse3/web-client/operation/commons';

type Props = {
  movement: MovementWithSubscriptionInfosFragment;
  identityId: Identity['id'] | null;
};

export function StockTransactionListItem({ movement, identityId }: Props): JSX.Element {
  const t = useTranslations();

  return (
    <Accordion allowToggle variant="unstyled">
      <AccordionItem>
        <AccordionButton _expanded={{ fontWeight: '600' }}>
          <HStack spacing="1" w="full">
            <AccordionIcon ml="-1" />
            <Text fontSize="sm">
              {movement.type === MovementType.BURN ||
              (movement.type === MovementType.TRANSFER && movement.fromWallet?.id === identityId)
                ? t('TransferOfShareDate', {
                    date: dayjs(movement.createdAt).format('D MMMM YYYY'),
                  })
                : t('AcquisitionDateNb', {
                    date: dayjs(movement.createdAt).format('D MMMM YYYY'),
                  })}
            </Text>
          </HStack>
        </AccordionButton>
        <AccordionPanel as={Stack} py="2" spacing="4">
          <Stack p="2" spacing="2">
            <HStack fontSize="sm" justifyContent="space-between">
              <Text color="gray.500" fontWeight="500">
                {t('SharesCount')}
              </Text>
              <Stack alignItems="flex-end" flexShrink="0" spacing="0">
                <AssetBadge type={movement.asset.assetType} value={movement.amount} />
              </Stack>
            </HStack>
            <HStack fontSize="sm" justifyContent="space-between">
              <Text color="gray.500" fontWeight="500">
                {t('AcquisitionPrice')}
              </Text>
              <Text fontWeight="500">
                {t('NbPricePerShare', {
                  price: movement.subscription?.operation?.sharePrice
                    ? formatNumberCurrency(movement.subscription?.operation?.sharePrice)
                    : '-',
                })}
              </Text>
            </HStack>
            {movement.subscription && (
              <SubscriptionPanelDocuments
                p="0"
                subscriptionId={movement.subscription.id}
                titleFontSize="sm"
              />
            )}
          </Stack>
          <Divider />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
