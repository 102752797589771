import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

type Props = unknown;

export function EmptyLayout(): JSX.Element {
  return (
    <Box as="section" bg="gray.50" height="auto" minH="100vh">
      <Outlet />
    </Box>
  );
}

export type EmptyLayoutProps = Props;
