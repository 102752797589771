import dayjs from 'dayjs';

import {
  AssetEntityInfosFragment,
  BondAssetData,
  BondAssetInput,
} from '@blockpulse3/graphql/hooks';

import { defaultValues, durationUnitOptions } from './schema';
import { IBondAsset } from './types';

export function getBondAssetDefaultValues(
  asset: AssetEntityInfosFragment | BondAssetInput,
): IBondAsset {
  const assetData: Omit<BondAssetData, 'contractType'> | null =
    ('data' in asset && asset.data?.__typename === 'BondAssetData' ? asset.data : null) ||
    ('assetId' in asset ? asset : null);
  if (!assetData) return defaultValues;
  return {
    name: asset.name,
    nominalValue: asset.nominalValue ? asset.nominalValue : defaultValues.nominalValue,
    date: assetData.date ? dayjs(assetData.date).format('YYYY-MM-DD') : defaultValues.date,
    durationUnit:
      durationUnitOptions.find((option) => option.value === assetData.durationUnit) ||
      defaultValues.durationUnit,
    period: assetData.period ? assetData.period : defaultValues.period,
    reimbursementInterval: assetData.reimbursementInterval
      ? assetData.reimbursementInterval
      : defaultValues.reimbursementInterval,
    interestRate: assetData.interestRate ? assetData.interestRate : defaultValues.interestRate,
    interestPeriod: assetData.interestPeriod
      ? assetData.interestPeriod
      : defaultValues.interestPeriod,
    interestPaymentInterval: assetData.interestPaymentInterval
      ? assetData.interestPaymentInterval
      : defaultValues.interestPaymentInterval,
    issuancePremium: assetData.issuancePremium
      ? assetData.issuancePremium
      : defaultValues.issuancePremium,
    redemptionPremium: assetData.redemptionPremium
      ? assetData.redemptionPremium
      : defaultValues.redemptionPremium,
    accelerable: assetData.accelerable ? assetData.accelerable : defaultValues.accelerable,
    extendable: assetData.extendable ? assetData.extendable : defaultValues.extendable,
    transferable: assetData.transferable ? assetData.transferable : defaultValues.transferable,
  };
}
