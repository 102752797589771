import { CompanyInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatCountry } from '@blockpulse3/helpers';

import { CompanyInformationsForm } from './types';

/**
 * getCompanyParameters.
 * Format fetched company informations into form.
 *
 * @param {CompanyInfosFragment | undefined} data
 * @returns {CompanyInformationsForm}
 */
export function getCompanyParameters(
  data: CompanyInfosFragment | undefined,
): CompanyInformationsForm {
  return {
    name: data?.name || '',
    country: {
      label: formatCountry(data?.country || 'FRA'),
      value: data?.country || 'FRA',
    },
    registrationNumber: data?.registrationNumber || '',
    registrationEntity: data?.registrationEntity || '',
    corporateForm: data?.corporateForm || '',
    creationDate: data?.creationDate || '',
    shareCapital: data?.shareCapital || 0,
    capitalType: {
      label: data?.capitalType || '',
      value: data?.capitalType || '',
    },
    nominalValue: data?.nominalValue || undefined,
    address: {
      line: data?.address?.line || '',
      city: data?.address?.city || '',
      postalCode: data?.address?.postalCode || '',
      country: {
        label: formatCountry(data?.address?.country || 'FRA'),
        value: data?.address?.country || 'FRA',
      },
    },
    businessActivityDescription: '',
  };
}
