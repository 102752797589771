import { Button, Flex, Icon } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

type Props = {
  onNextPage: () => void;
  onPreviousPage: () => void;
  hasNextPage: boolean | undefined;
  hasPreviousPage: boolean | undefined;
  loading: boolean;
  currentPage: number;
  pageCount: number;
};

export function PaginationButtons({
  onNextPage,
  onPreviousPage,
  hasNextPage,
  hasPreviousPage,
  loading,
  currentPage,
  pageCount,
}: Props): JSX.Element {
  const prevDisabled = loading || !hasPreviousPage;
  const nextDisabled = loading || !hasNextPage;
  return (
    <Flex justifyContent="center">
      <Button isDisabled={prevDisabled} mx={2} variant="secondary" onClick={onPreviousPage}>
        <Icon as={ChevronLeftIcon} />
      </Button>
      <Button isDisabled variant="ghost">
        {currentPage} / {pageCount}
      </Button>
      <Button isDisabled={nextDisabled} mx={2} variant="secondary" onClick={onNextPage}>
        <Icon as={ChevronRightIcon} />
      </Button>
    </Flex>
  );
}
