import { Skeleton } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useGetSpvQuery, useUpdateCompanyMutation } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, useErrorToast } from '@blockpulse3/ui/commons';

import { SPVInformations } from './SPVInformations';
import { SPVInformationsForm } from './types';
import { getSPVParameters } from './utils';

type Props = {
  /* ** Step submitted callback ** */
  onSubmit: () => void;
};

/**
 * SPVParameters.
 * SPV informations, update step.
 *
 * @returns {JSX.Element}
 */
export function SPVParameters({ onSubmit }: Props): JSX.Element {
  const errorToast = useErrorToast();

  const { companyId = '' } = useParams();

  const [updateCompanyInformations] = useUpdateCompanyMutation();

  const { data, loading, error } = useGetSpvQuery({ variables: { companyId } });

  const defaultValues = useMemo(() => getSPVParameters(data?.company), [data?.company]);

  const handleUpdateSPV = (data: SPVInformationsForm): void => {
    const { address, revocationMajority, ...rest } = data;

    updateCompanyInformations({
      variables: {
        updateCompanyInput: {
          companyId,
          address: {
            line: address.line,
            city: address.city,
            postalCode: address.postalCode,
            country: address.country.value,
          },
          country: address.country.value,
          revocationMajority: revocationMajority.value,
          ...rest,
        },
      },
      onCompleted: () => {
        onSubmit();
      },
      onError: () => {
        errorToast({ title: 'Erreur update' });
      },
    });
  };

  if (loading) {
    return <Skeleton height="350px" />;
  }

  if (error) {
    return <ErrorQueryCard />;
  }

  if (!data) {
    return <ErrorQueryCard />;
  }

  return <SPVInformations defaultValues={defaultValues} onSubmit={handleUpdateSPV} />;
}
