export enum Exceptions {
  DisposableEmail = 'Disposable email used',
  MissingRequiredProperties = 'Missing required properties',
  AlreadyExistingCompany = 'A company with the same registration number already exists',
  AlreadyUsedEmail = 'A user with the same email already exists',
  TotalSharePercentageAboveHundred = 'Total share percentage is greater than 100',
  BelowMinimalAmount = 'Invest amount below minimal amount',
  AboveMaximalAmount = 'Invest amount above maximal amount',
  ExceedsHardCap = 'Invest amount out of bounds: total exceeds the hard cap',
  IbanGenerationRejected = 'Fundraising must be in status REVIEWED to generate IBAN and transfer the funds',
  CannotReviewedHardCapExceeded = 'Fundraising cannot be REVIEWED as hard cap is exceeded',
  ODMWithPendingSignature = 'An ODM already has a pending signature in the same company',
  FailedToFetch = 'Failed to fetch',
  AlreadyHasBankAccount = 'The user already has a bank account',
  ErrorBuyerIdentityNotApproved = 'Buyer identity not approved',
  ErrorSellerIdentityNotApproved = 'Seller identity not approved',
  WalletMissingBankInformation = 'Wallet missing bank information',
  NotARepresentativeOfCompany = 'User is not a representative of the company',
  AlreadySpaceMember = 'User already member of a space',
}
