import * as yup from 'yup';

import { requiredSelectField } from '@blockpulse3/data/shared';

import { INewSecondaryOperationSeller } from './types';

export const schema = yup.object({
  sellerIdentity: requiredSelectField,
}) as yup.ObjectSchema<INewSecondaryOperationSeller>;

export const operationSellerDefaultValues: INewSecondaryOperationSeller = {
  sellerIdentity: { label: '', value: '' },
};
