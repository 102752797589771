import { Stack, Text } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import { DocumentResetData } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';

type Props = {
  date: Date;
  data: DocumentResetData;
};

export function ActivityDocumentReset({ date, data }: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValues = useTranslations('DocumentValues');

  const { documentType, validatorName } = data;

  return (
    <Stack spacing={1}>
      <Text fontSize="sm">
        {t.rich('ResetDocumentRich', {
          documentName: i18nDocumentValues(documentType as IntlDocumentValues),
          important: (chunks) => (
            <Text as="span" fontWeight="600">
              {chunks}
            </Text>
          ),
        })}
      </Text>
      <Stack color="gray.500" spacing={1}>
        <Text>
          {formatDate(date, 'DD/MM/YYYY')}
          {validatorName && <> &middot; {validatorName}</>}
        </Text>
      </Stack>
    </Stack>
  );
}

export type ActivityDocumentResetProps = Props;
