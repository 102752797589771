import { Image } from '@chakra-ui/react';

import { ThemeIcon, ThemeIconProps } from '../../components';
import Logo from './assets/logo.svg';

function LightIcon(): JSX.Element {
  return <Image alt="France Angels" maxH="100%" src={Logo} />;
}

function DarkIcon(): JSX.Element {
  return <Image alt="France Angels" maxH="100%" src={Logo} />;
}

export function Icon({ ...props }: ThemeIconProps): JSX.Element {
  return <ThemeIcon darkIcon={<DarkIcon />} lightIcon={<LightIcon />} {...props} />;
}
