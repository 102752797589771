import {
  Badge,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { LibraryIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { IntlHoldingMethodTypeValues } from '@blockpulse3/data/locales/types';
import { ContractType } from '@blockpulse3/data/shared';
import {
  Identity,
  MovementWithSubscriptionInfosFragment,
  OptionTokenGrantFragment,
  OptionTokenInfosFragment,
} from '@blockpulse3/graphql/hooks';

import {
  BondTransactionListItem,
  OptionTransactionListItem,
  StockTransactionListItem,
} from './TransactionListItems';

type Props = {
  movements: MovementWithSubscriptionInfosFragment[];
  identityId: Identity['id'] | null;
  contractType: ContractType;
  grants?: OptionTokenGrantFragment[];
  optionToken?: OptionTokenInfosFragment;
};

export function TransactionsList({
  movements,
  identityId,
  contractType,
  grants,
  optionToken,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nHoldingMethodType = useTranslations('HoldingMethodTypeValues');

  const wallets = movements.reduce(
    (acc: Record<string, MovementWithSubscriptionInfosFragment[]>, movement) => {
      const ownerWallet =
        movement?.fromWallet?.identity?.id === identityId ? movement.fromWallet : movement.toWallet;

      if (ownerWallet) {
        if (acc[ownerWallet.holdingMethod]) {
          acc[ownerWallet.holdingMethod].push(movement);
        } else {
          acc[ownerWallet.holdingMethod] = [movement];
        }
      }
      return acc;
    },
    {},
  );

  const TransactionListItem = ({
    movement,
  }: {
    movement: MovementWithSubscriptionInfosFragment;
  }): JSX.Element | null => {
    switch (contractType) {
      case ContractType.STOCK_TOKEN:
      case ContractType.BASIC_TOKEN:
        return <StockTransactionListItem identityId={identityId} movement={movement} />;
      case ContractType.BOND_TOKEN:
        return <BondTransactionListItem identityId={identityId} movement={movement} />;
      case ContractType.OPTION_TOKEN:
        if (grants && optionToken) {
          return (
            <OptionTransactionListItem
              grants={grants}
              movement={movement}
              optionToken={optionToken}
            />
          );
        } else {
          return null;
        }
    }
  };

  return (
    <Stack px="4" spacing="4">
      <Text color="gray.600" fontWeight="600">
        {t('TransactionsList')}
      </Text>
      <Stack spacing="8">
        {Object.keys(wallets).map((holdingMethod) => (
          <Stack key={holdingMethod} spacing="4">
            <HStack justifyContent="space-between" w="full">
              <HStack>
                <Icon as={LibraryIcon} boxSize="14px" color="black" />
                <Text fontSize="sm" fontWeight="600">
                  {i18nHoldingMethodType(holdingMethod as IntlHoldingMethodTypeValues)}
                </Text>
              </HStack>
              <Badge bgColor="gray.100" color="gray.600" fontSize="xs">
                {t.rich('TransactionCount', {
                  nb: wallets[holdingMethod]?.length || 0,
                  important: (chunk) => (
                    <Text as="span" fontWeight="bold">
                      {chunk}
                    </Text>
                  ),
                })}
              </Badge>
            </HStack>
            {wallets[holdingMethod].map((movement) => (
              <TransactionListItem key={movement.id} movement={movement} />
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
