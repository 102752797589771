import * as yup from 'yup';

import { requiredStringField } from '@blockpulse3/data/shared';

import { RepatriationShareTransferRegisterIdentitiesForm } from './types';

export const shareTransferRegisterIdentitiesSchema = yup.object({
  individualIdentitiesFile: yup.string().when('disableIndividualIdentities', {
    is: (disableIndividualIdentities: boolean) => !disableIndividualIdentities,
    then: () => requiredStringField,
  }),
  disableIndividualIdentities: yup.boolean(),
  companyIdentitiesFile: yup.string().when('disableCompanyIdentities', {
    is: (disableCompanyIdentities: boolean) => !disableCompanyIdentities,
    then: () => requiredStringField,
  }),
  disableCompanyIdentities: yup.boolean(),
}) as yup.ObjectSchema<RepatriationShareTransferRegisterIdentitiesForm>;
