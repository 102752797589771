import { useContext, useState } from 'react';
import { IntlProvider } from 'use-intl';

import { messages } from '@blockpulse3/data/shared';
import { Locale } from '@blockpulse3/graphql/hooks';

import { RootIntlContext, RootIntlContextType, rootIntlContextInit } from './context';

type Props = {
  children: React.ReactNode;
};

/**
 * RootIntlProvider.
 * Wrapper around <IntlProvider />. Expose useIntl() to set the locale from the children.
 * This is a front-end setting. Used with update user mutation.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function RootIntlProvider({ children }: Props): JSX.Element {
  const [intl, setIntl] = useState({
    locale: rootIntlContextInit.locale,
    messages: messages[rootIntlContextInit.locale],
  });

  const handleLocaleChange = (newLocale: Locale): void => {
    setIntl({ locale: newLocale, messages: messages[newLocale] });
  };

  return (
    <RootIntlContext.Provider value={{ locale: intl.locale, setLocale: handleLocaleChange }}>
      <IntlProvider locale={intl.locale} messages={intl.messages}>
        {children}
      </IntlProvider>
    </RootIntlContext.Provider>
  );
}

export function useIntl(): RootIntlContextType {
  return useContext(RootIntlContext);
}
