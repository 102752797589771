import { Card, CardBody, Stack, useDisclosure } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { ManagerFilterType } from '@blockpulse3/data/shared';
import {
  GetManagementsDocument,
  GetManagementsQuery,
  GetManagementsQueryVariables,
  ManagementType,
  Manager,
  ManagerEntityInfosFragment,
  ManagersFilterInput,
  useDeleteManagerMutation,
} from '@blockpulse3/graphql/hooks';
import { ConfirmModal, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';
import { usePagination } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { PAGE_SIZE } from '../utils';
import { SpaceManagementsControls } from './SpaceManagementsControls';
import { SpaceManagementsTable } from './SpaceManagementsTable';

type Props = {
  type: ManagementType;
};

export function SpaceManagements({ type }: Props): JSX.Element {
  const t = useTranslations();

  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const confirmRef = useRef(null);
  const deleteManagerModal = useDisclosure();

  const [deleteManagerId, setDeleteManagerId] = useState<Manager['id'] | null>(null);
  const [filters, setFilters] = useState<ManagersFilterInput[]>([
    { name: '', type: ManagerFilterType.MANAGER_TYPE, value: type },
  ]);
  const [deleteManager] = useDeleteManagerMutation();

  const paginationProps = usePagination<
    GetManagementsQuery,
    GetManagementsQueryVariables,
    ManagerEntityInfosFragment
  >({
    queryDocument: GetManagementsDocument,
    queryOptions: {
      variables: {
        spaceId,
        first: PAGE_SIZE,
        filterBy: filters,
      },
      skip: !spaceId,
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'managers',
    pageSize: PAGE_SIZE,
  });
  const { refetch, reset } = paginationProps;

  const handleManagerDeleteModal = (managerId: Manager['id']): void => {
    setDeleteManagerId(managerId);
    deleteManagerModal.onOpen();
  };

  const handleManagerDelete = (): void => {
    if (!deleteManagerId) return;

    deleteManager({
      variables: {
        managerId: deleteManagerId,
      },
      onCompleted: () => {
        successToast({ title: t('DeleteManagerSuccess') });
        setDeleteManagerId(null);
        deleteManagerModal.onClose();
        refetch();
      },
      onError: () => {
        errorToast({ title: t('DeleteManagerError') });
        setDeleteManagerId(null);
        deleteManagerModal.onClose();
      },
    });
  };

  const handleSearchInputSubmit = (value: string): void => {
    const isInputAndFilterSearchEmpty =
      value === '' && !filters.some((filter) => filter.name === 'searchValue');
    if (isInputAndFilterSearchEmpty) return;

    resetSpecificFilter('searchValue', ManagerFilterType.CUSTOM, value);
  };

  const resetSpecificFilter = (
    filterName: string,
    filterType: ManagerFilterType,
    value: string,
  ): void => {
    const currentFilterWithoutFilterName = filters.filter((filter) => filter.name !== filterName);
    const newFilter: ManagersFilterInput = {
      name: filterName,
      type: filterType,
      value,
    };
    reset();
    setFilters([...currentFilterWithoutFilterName, newFilter]);
  };

  return (
    <Card>
      <CardBody as={Stack} spacing="4">
        <SpaceManagementsControls type={type} onSearchSubmit={handleSearchInputSubmit} />
        <SpaceManagementsTable
          type={type}
          onRowDeleteClick={handleManagerDeleteModal}
          {...paginationProps}
        />
      </CardBody>
      <ConfirmModal
        isOpen={deleteManagerModal.isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('ConfirmManagerDeleteTitle')}
        onClose={deleteManagerModal.onClose}
        onConfirm={handleManagerDelete}
      />
    </Card>
  );
}

export type SpaceManagementsProps = Props;
