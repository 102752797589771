import { Card, CardProps, HStack, Text, Tooltip } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { AssetType, BalanceInfosFragment, IdentityType } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  ASSET_COLOR_MAP,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';

import { AssetPerformance } from '../AssetPerformance';
import { CompanyValuationsChart } from '../CompanyValuationsChart';

type Props = {
  balance: BalanceInfosFragment;
  underlyingAssetType: AssetType;
} & CardProps;

export function CompanyValuationsCard({
  balance,
  underlyingAssetType,
  ...props
}: Props): JSX.Element | null {
  const t = useTranslations();

  return (
    <Card
      alignItems="center"
      bg="transparent"
      borderRadius="0"
      boxShadow="none"
      flexDirection="row"
      gap="3"
      h="fit-content"
      p="4"
      pb="8"
      pt="10"
      {...props}
    >
      <IdentityCard>
        <IdentityAvatar
          boxSize="14"
          src={balance.asset.company.profilePicture}
          type={IdentityType.COMPANY}
        />
        <IdentityCardTitle fontSize="lg">
          <Text fontWeight="600">{balance.asset.company.name}</Text>
        </IdentityCardTitle>
        {balance?.valueOfShares && (
          <IdentityCardDescription color="gray.800" fontSize="lg">
            <Tooltip hasArrow label={t('CurrentValuation')} placement="top">
              <Text cursor="default" fontSize="sm" fontWeight="500" whiteSpace="nowrap">
                {formatNumberCurrency(balance.valueOfShares)}
              </Text>
            </Tooltip>
          </IdentityCardDescription>
        )}
      </IdentityCard>
      <HStack
        alignItems="center"
        justifyContent="space-between"
        minH="52px"
        ml="auto"
        p="1"
        spacing="0"
      >
        <CompanyValuationsChart
          balance={balance}
          companyId={balance.asset.company.id}
          h="50px"
          lineColor={ASSET_COLOR_MAP[balance.asset.assetType]}
          underlyingAssetType={underlyingAssetType}
          w="85px"
        />
        <AssetPerformance
          alignItems="flex-end"
          currentPrice={balance?.valueOfShare}
          displayPrice={balance?.valueOfShare}
          initialPrice={balance?.subscriptionEntryPrice}
          mx="3"
          underlyingAssetType={underlyingAssetType}
        />
      </HStack>
    </Card>
  );
}
