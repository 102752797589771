import { createContext } from 'react';

import { noop } from '@blockpulse3/data/shared';

export type IWorkspaceSwitcherContext = {
  isSwitcherOpen: boolean;
  onSwitcherOpen: () => unknown;
  onSwitcherClose: () => unknown;
};

export const initWorkspaceSwitcherContext: IWorkspaceSwitcherContext = {
  isSwitcherOpen: false,
  onSwitcherOpen: noop,
  onSwitcherClose: noop,
};

export const WorkspaceSwitcherContext = createContext<IWorkspaceSwitcherContext>(
  initWorkspaceSwitcherContext,
);
