import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';

import { getBrand, getBrandConfig, getHostName, routes } from '@blockpulse3/data/shared';
import { LoadingPage } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { AppScreen, AuthScreen, IdentityScreen } from './screens';

const defaultZendeskSnippetSrc =
  'https://static.zdassets.com/ekr/snippet.js?key=7e36a96b-4e25-4a34-bc91-c056906e932e';

export function Screen(): JSX.Element {
  const { user, status, setStatus, refetch } = useAuthUser();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      refetch();
    } else {
      setStatus('unauthenticated');
    }
  }, [refetch, setStatus]);

  useEffect(() => {
    const setVh = (): void => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('load', setVh);
    window.addEventListener('resize', setVh);
    return () => {
      window.removeEventListener('load', setVh);
      window.removeEventListener('resize', setVh);
    };
  }, []);

  if (status === 'loading') {
    return <LoadingPage />;
  }

  if (status === 'unauthenticated') {
    return <AuthScreen />;
  }

  if (!user?.individualIdentity?.address) {
    /* ** Onboarding not completed ** */
    return <IdentityScreen />;
  }

  return <AppScreen />;
}

const TokenExpirationChecker = (): null => {
  const navigate = useNavigate();
  const location = useLocation();

  // Function to decode the JWT and check expiration
  const isTokenExpired = (): boolean => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return true;

      const payloadBase64 = token.split('.')[1];
      const decodedPayload = JSON.parse(atob(payloadBase64));
      const currentTime = Math.floor(Date.now() / 1000);

      return decodedPayload.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };

  // Check token expiration when the window gains focus
  const checkTokenOnFocus = (): void => {
    if (isTokenExpired()) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (location.pathname === routes.login.href) return;

    // Add focus event listener
    window.addEventListener('focus', checkTokenOnFocus);

    return () => {
      window.removeEventListener('focus', checkTokenOnFocus);
    };
  }, [navigate]);

  return null;
};

export function App(): JSX.Element {
  const hostname = getHostName();
  const brand = getBrand(hostname);
  const brandConfig = getBrandConfig(brand);

  return (
    <>
      <Helmet>
        <title>Tableau de bord - {brandConfig.name}</title>
        <link
          href={`assets/brands/${brandConfig.slug}/favicon.ico`}
          rel="icon"
          type="image/x-icon"
        />
        {brandConfig?.zendeskSnippetSrc !== 'disabled' && (
          <script
            id="ze-snippet"
            src={brandConfig?.zendeskSnippetSrc || defaultZendeskSnippetSrc}
          ></script>
        )}
      </Helmet>
      <TokenExpirationChecker />
      <Screen />
    </>
  );
}

export default App;
