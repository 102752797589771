import { Button, useDisclosure } from '@chakra-ui/react';
import { useRef } from 'react';
import { useTranslations } from 'use-intl';

import {
  MemberRole,
  OperationDocumentType,
  SubscriptionInfosFragment,
  useGetSubscriptionQuery,
  useResetSubscriptionDocumentMutation,
} from '@blockpulse3/graphql/hooks';
import { DeleteConfirmModal } from '@blockpulse3/ui/commons';
import { useManagerRole } from '@blockpulse3/web-client/auth';

type Props = {
  subscriptionId: SubscriptionInfosFragment['id'];
  operationDocumentType: OperationDocumentType;
};

export function ResetDocumentSignature({
  subscriptionId,
  operationDocumentType,
}: Props): JSX.Element | null {
  const t = useTranslations();

  const draftConfirmRef = useRef(null);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const isUserAuthorized = useManagerRole({ subscriptionId }, [
    MemberRole.OWNER,
    MemberRole.ADMIN,
    MemberRole.COMPLIANCE_OFFICER,
  ]);

  const { refetch: refetchSubscription } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });
  const [resetDocumentSignature, { loading: isResetDocumentLoading }] =
    useResetSubscriptionDocumentMutation();

  if (!isUserAuthorized) return null;

  const handleDocumentReset = (): void => {
    resetDocumentSignature({
      variables: {
        resetSubscriptionDocumentInput: {
          subscriptionId,
          documentType: operationDocumentType,
        },
      },
      onCompleted: () => {
        refetchSubscription();
        onClose();
      },
    });
  };

  return (
    <>
      <Button
        isDisabled={isResetDocumentLoading}
        isLoading={isResetDocumentLoading}
        size="sm"
        variant="secondary"
        onClick={onOpen}
      >
        {t('ResetDocument')}
      </Button>
      <DeleteConfirmModal
        isLoading={isResetDocumentLoading}
        isOpen={isOpen}
        leastDestructiveRef={draftConfirmRef}
        subtitle={t('DefinitiveAction')}
        title={`${t('ResetDocument')} ?`}
        onClose={onClose}
        onDelete={handleDocumentReset}
      />
    </>
  );
}
