import { CloseIcon } from '@chakra-ui/icons';
import { Button, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

type Props = unknown;

export function VerifyEmailExpired(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack align="center" direction="column" spacing="8">
      <Stack>
        <Stack direction="row" gap="4">
          <CloseIcon alignSelf="center" color="red.400" h="8" w="8" />
          <Text fontSize="xl" fontWeight="bold">
            {t('MailConfirmationLinkExpired')}
          </Text>
        </Stack>
        <Text fontSize="lg">{t('VerificationLinkResent')}</Text>
      </Stack>
      <Link style={{ width: '100%' }} to={routes.me.href}>
        <Button width="full">{t('BackToHome')}</Button>
      </Link>
    </Stack>
  );
}

export type VerifyEmailExpiredProps = Props;
