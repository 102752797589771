import {
  OperationInfosFragment,
  SubscriptionFilterType,
  SubscriptionsFilterInput,
} from '@blockpulse3/graphql/hooks';

/**
 * getSubscriptionFilterHoldingMethod.
 *
 * @param {OperationInfosFragment} operation
 * @returns {SubscriptionsFilterInput[]}
 */
export function getSubscriptionFilterHoldingMethod(
  operation?: OperationInfosFragment,
): SubscriptionsFilterInput[] {
  if (!operation || !operation?.allowedFiscalAdvantages) {
    return [];
  }

  const filters = operation.allowedHoldingMethods.map((holdingMethod) => ({
    type: SubscriptionFilterType.HOLDING_METHOD,
    name: holdingMethod,
    value: holdingMethod,
  }));

  return filters;
}

/**
 * getSubscriptionFilterAmount.
 *
 * @param {OperationInfosFragment} operation
 * @returns {SubscriptionsFilterInput[]}
 */
export function getSubscriptionFilterAmount(
  operation?: OperationInfosFragment,
): SubscriptionsFilterInput[] {
  if (!operation || !operation?.data) {
    return [];
  }

  const operationData = operation.data;
  const minDeclaredAmount = operationData?.minDeclaredAmount
    ? `${operationData.minDeclaredAmount}`
    : undefined;
  const maxDeclaredAmount = operationData?.maxDeclaredAmount
    ? `${operationData.maxDeclaredAmount}`
    : undefined;

  return [
    {
      type: SubscriptionFilterType.AMOUNT,
      name: 'minDeclaredAmount',
      value: minDeclaredAmount,
    },
    {
      type: SubscriptionFilterType.AMOUNT,
      name: 'maxDeclaredAmount',
      value: maxDeclaredAmount,
    },
  ];
}

export function getSubscriptionFilterTags(
  operation?: OperationInfosFragment,
): SubscriptionsFilterInput[] {
  if (!operation || !operation?.company?.tags) {
    return [];
  }

  return operation.company.tags.map((tag) => ({
    type: SubscriptionFilterType.TAGS,
    name: tag.name,
    value: tag.id,
  }));
}
