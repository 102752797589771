import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  ErrorMessage,
  Input,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';

import { IPartialOperationParameters } from '../../utils';
import { schema, subscriptionDefaultValues } from './schema';
import { SubscriptionPanelInvestmentForm } from './types';

type Props = {
  operationParameters: IPartialOperationParameters;
  defaultValues?: SubscriptionPanelInvestmentForm;
  isLoading?: boolean;
  onSubmit: (data: SubscriptionPanelInvestmentForm) => void;
} & Omit<ResponsiveModalProps, 'children'>;

export function SubscriptionPanelInvestmentModal({
  operationParameters,
  defaultValues = subscriptionDefaultValues,
  isLoading = false,
  onSubmit = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const { onClose = noop, isOpen } = props;

  const [hardCap, setHardCap] = useState<number>(0);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(true);

  const { register, formState, handleSubmit, reset, watch } =
    useForm<SubscriptionPanelInvestmentForm>({
      defaultValues,
      resolver: yupResolver(schema),
      context: { hardCap },
    });

  useEffect(() => {
    setHardCap(operationParameters.hardCap || 0);
  }, [operationParameters]);

  useEffect(() => {
    // Reset form values when modal is opened
    if (isOpen) {
      reset({ ...defaultValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleFormSubmit: SubmitHandler<SubscriptionPanelInvestmentForm> = (data) => {
    setIsFormDirty(true);
    onSubmit(data);
  };

  const onFormChange = (): void => {
    if (isFormDirty) {
      setIsFormDirty(false);
    }
  };

  const minAmount = watch('minimalAmount');
  const maxAmount = watch('maximalAmount');

  const operationMinAmount = operationParameters.minimalAmount || operationParameters.sharePrice;
  const operationMaxAmount = operationParameters.maximalAmount || operationParameters.hardCap;

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('ModifyInvestmentAmount')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            id="update-invest-amount"
            onChange={onFormChange}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Stack>
              <Stack>
                <FormControl isInvalid={!!formState.errors.amount}>
                  <FormLabel htmlFor="investAmount">{t('NewAmount')}</FormLabel>
                  <InputGroup id="investAmount">
                    <Input step="0.01" type="number" {...register('amount')} />
                    <InputRightAddon>€</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage
                    error={formState.errors.amount}
                    values={{
                      minimalAmount: formatNumberCurrency(minAmount || operationMinAmount),
                      maximalAmount: formatNumberCurrency(maxAmount || operationMaxAmount || 0),
                    }}
                  />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors.minimalAmount}>
                  <FormLabel htmlFor="minimalAmount">{t('MinimumAmount')}</FormLabel>
                  <InputGroup id="minimalAmount">
                    <Input
                      id="minimalAmount"
                      step="0.01"
                      type="number"
                      {...register('minimalAmount', { valueAsNumber: true })}
                    />
                    <InputRightAddon>€</InputRightAddon>
                  </InputGroup>
                  <Text color="gray.500" fontSize="xs">
                    {t('Default')} {formatNumberCurrency(operationMinAmount)}
                  </Text>
                  <ErrorMessage error={formState.errors.minimalAmount} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors.maximalAmount}>
                  <FormLabel htmlFor="maximalAmount">{t('MaximumAmount')}</FormLabel>
                  <InputGroup id="maximalAmount">
                    <Input
                      id="maximalAmount"
                      step="0.01"
                      type="number"
                      {...register('maximalAmount', { valueAsNumber: true })}
                    />
                    <InputRightAddon>€</InputRightAddon>
                  </InputGroup>
                  <Text color="gray.500" fontSize="xs">
                    {t('Default')}{' '}
                    {operationMaxAmount ? formatNumberCurrency(operationMaxAmount) : t('None')}
                  </Text>
                  <ErrorMessage error={formState.errors.maximalAmount} />
                </FormControl>
              </Stack>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            form="update-invest-amount"
            isDisabled={isFormDirty}
            isLoading={isLoading}
            type="submit"
            variant="primary"
          >
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
