import { GetSpvQuery } from '@blockpulse3/graphql/hooks';

import { FinancialInformationsForm } from './types';

export function getFinancialInformations(
  data: GetSpvQuery['company'] | undefined,
): FinancialInformationsForm {
  return {
    shareCapital: data?.shareCapital || 0,
    compensation: data?.compensation || 0,
    carriedInterest: data?.carriedInterest || 0,
    preferredSharesPercentage: data?.preferredSharesPercentage || 0,
  };
}
