import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  theme,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import {
  ManagementType,
  useCreateManagerMutation,
  useDeleteManagerMutation,
  useGetManagerIdentityQuery,
  useGetManagerSpacesQuery,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { schema, spaceManagerDefaultValues } from './schema';
import { SpaceManagerForm } from './types';

type Props = {
  identityId: string;
  children: React.ReactNode;
  isCompany?: boolean;
} & Omit<ResponsiveModalProps, 'children'>;

/**
 * AdminSpaceManagerModal.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function AdminSpaceManagerModal({
  identityId,
  children,
  isCompany = false,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nManagementTypes = useTranslations('ManagementTypes');

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { formState, control, handleSubmit, reset } = useForm<SpaceManagerForm>({
    resolver: yupResolver(schema),
  });

  const spacesReq = useGetManagerSpacesQuery({ skip: !identityId });

  const identityReq = useGetManagerIdentityQuery({
    variables: { identityId },
    onCompleted: (data) => {
      const manager = data.identity.manager;
      if (manager) {
        reset({
          space: { label: manager.space.name, value: manager.space.id },
          type: { label: i18nManagementTypes(manager.type), value: manager.type },
        });
      } else {
        reset(spaceManagerDefaultValues);
      }
    },
    skip: !identityId,
  });

  const [createManager] = useCreateManagerMutation();
  const [deleteManager] = useDeleteManagerMutation();

  const managerId = identityReq.data?.identity.manager?.id;

  const handleClose = (): void => {
    props.onClose();
    reset(spaceManagerDefaultValues);
    spacesReq.refetch();
    identityReq.refetch();
  };

  const handleFormSubmit: SubmitHandler<SpaceManagerForm> = (data) => {
    createManager({
      variables: {
        createManagerInput: {
          identityId,
          spaceId: data.space.value,
          type: isCompany ? data.type.value : ManagementType.MANAGER,
        },
      },
      onCompleted: (data) => {
        const spaceName = data.createManager.space.name;
        successToast({ title: t('CreateManagerSuccess', { spaceName }) });
        handleClose();
      },
      onError: () => {
        errorToast({ title: t('CreateManagerError') });
        handleClose();
      },
    });
  };

  const handleManagerDelete = (): void => {
    if (managerId) {
      deleteManager({
        variables: { managerId },
        onCompleted: () => {
          successToast({ title: t('DeleteManagerSuccess') });
          handleClose();
        },
        onError: () => {
          errorToast({ title: t('DeleteManagerError') });
          handleClose();
        },
      });
    }
  };

  const spaceOptions = (spacesReq.data?.getManagerSpaces || []).map((space) => ({
    label: space.name,
    value: space.id,
  }));
  const managementTypeOptions = Object.values(ManagementType).map((type) => ({
    label: i18nManagementTypes(type),
    value: type,
  }));

  const isLoading = spacesReq.loading || identityReq.loading;

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {children}
        <ModalBody>
          <Stack spacing={2}>
            <form id="space-manager" onSubmit={handleSubmit(handleFormSubmit)}>
              <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors.space}>
                  <FormLabel>{t('Space', { nb: 1 })}</FormLabel>
                  <Controller
                    control={control}
                    name="space"
                    render={({ field }): JSX.Element => (
                      <Select
                        isLoading={isLoading}
                        menuPortalTarget={document.body}
                        options={spaceOptions}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: theme.zIndices.modal + 1,
                          }),
                        }}
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage error={formState.errors.space?.value} />
                </FormControl>
                <FormControl isDisabled={!isCompany} isInvalid={!!formState.errors.type}>
                  <FormLabel>{t('Type')}</FormLabel>
                  <Controller
                    control={control}
                    name="type"
                    render={({ field }): JSX.Element => (
                      <Select
                        isLoading={isLoading}
                        menuPortalTarget={document.body}
                        options={managementTypeOptions}
                        getOptionLabel={(option): string =>
                          option.label === option.value
                            ? i18nManagementTypes(option.label)
                            : option.label
                        }
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: theme.zIndices.modal + 1,
                          }),
                        }}
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage error={formState.errors.type?.value} />
                </FormControl>
              </Stack>
            </form>
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter justifyContent={managerId ? 'space-between' : 'flex-end'}>
          {managerId && (
            <Button type="button" variant="secondary" onClick={handleManagerDelete}>
              {t('DeleteManager')}
            </Button>
          )}
          <Stack direction={{ base: 'column', md: 'row' }}>
            <Button type="button" variant="secondary" onClick={props.onClose}>
              {t('Cancel')}
            </Button>
            <Button form="space-manager" type="submit" variant="primary">
              {t('Validate')}
            </Button>
          </Stack>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type AdminSpaceManagerModalProps = Props;
