import {
  SubscriptionSide,
  SubscriptionTab,
  SubscriptionsTabsStatus,
} from '@blockpulse3/data/shared';
import {
  IdentityType,
  OperationDocumentType,
  OperationInfosFragment,
  SubscriptionFilterType,
  SubscriptionStepStatus,
  SubscriptionStepType,
  SubscriptionsCountInfosFragment,
  SubscriptionsFilterInput,
} from '@blockpulse3/graphql/hooks';

/* ** STEP Filters ** */
export const listSubscriptionFilterStep = (
  operation?: OperationInfosFragment,
): SubscriptionsFilterInput[] => {
  return [
    {
      type: SubscriptionFilterType.STEP,
      name: 'MifidInProgress',
      value: 'MifidInProgress',
      stepType: SubscriptionStepType.MIFID,
      stepStatuses: [SubscriptionStepStatus.IN_PROGRESS],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'KycKybMissing',
      value: 'KycKybMissing',
      stepType: SubscriptionStepType.IDENTITY_VERIFICATION,
      stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.IN_PROGRESS],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'KycKybPending',
      value: 'KycKybPending',
      stepType: SubscriptionStepType.IDENTITY_VERIFICATION,
      stepStatuses: [SubscriptionStepStatus.PENDING],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'KycKybRefused',
      value: 'KycKybRefused',
      stepType: SubscriptionStepType.IDENTITY_VERIFICATION,
      stepStatuses: [SubscriptionStepStatus.ERROR],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'AmountNotChosen',
      value: 'AmountNotChosen',
      stepType: SubscriptionStepType.DETAILS,
      stepStatuses: [SubscriptionStepStatus.NONE],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'AwaitingFundsSourceCertificate',
      value: 'AwaitingFundsSourceCertificate',
      stepType: SubscriptionStepType.DETAILS,
      stepStatuses: [
        SubscriptionStepStatus.IN_PROGRESS,
        SubscriptionStepStatus.PENDING,
        SubscriptionStepStatus.ERROR,
      ],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'SignatureNotSigned',
      value: 'SignatureNotSigned',
      stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
      documentType: operation
        ? operation.subscriptionDocumentType
        : OperationDocumentType.SUBSCRIPTION,
      stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.ERROR],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'SignatureInProgress',
      value: 'SignatureInProgress',
      stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
      documentType: operation
        ? operation.subscriptionDocumentType
        : OperationDocumentType.SUBSCRIPTION,
      stepStatuses: [SubscriptionStepStatus.PENDING, SubscriptionStepStatus.IN_PROGRESS],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'PaymentInProgress',
      value: 'PaymentInProgress',
      stepType: SubscriptionStepType.PAYMENT,
      stepStatuses: [
        SubscriptionStepStatus.NONE,
        SubscriptionStepStatus.PENDING,
        SubscriptionStepStatus.IN_PROGRESS,
      ],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'InsufficientPayment',
      value: 'InsufficientPayment',
      stepType: SubscriptionStepType.PAYMENT,
      stepStatuses: [SubscriptionStepStatus.ERROR],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'OverPayment',
      value: 'OverPayment',
      stepType: SubscriptionStepType.PAYMENT,
      stepStatuses: [SubscriptionStepStatus.VALID],
    },
  ];
};

/* ** IDENTITY_TYPE Filters ** */
export const subscriptionFilterIdentity: SubscriptionsFilterInput[] = [
  {
    type: SubscriptionFilterType.IDENTITY_TYPE,
    name: 'IndividualIdentity',
    value: `${IdentityType.INDIVIDUAL}_${SubscriptionSide.BUYER}`,
  },
  {
    type: SubscriptionFilterType.IDENTITY_TYPE,
    name: 'CompanyIdentity',
    value: `${IdentityType.COMPANY}_${SubscriptionSide.BUYER}`,
  },
];

/**
 * getSubscriptionFilterStep.
 *
 * @param {OperationInfosFragment} operation
 * @returns {SubscriptionsFilterInput[]}
 */
export function getSubscriptionFilterStep(
  operation?: OperationInfosFragment,
): SubscriptionsFilterInput[] {
  if (!operation || !operation?.subscriptionSteps) {
    return listSubscriptionFilterStep();
  }

  let subscriptionFilterStepPartial = listSubscriptionFilterStep(operation);

  const detailsStep = operation.subscriptionSteps.some(
    (step) => step.__typename === 'SubscriptionDetailsStepType',
  );
  if (!detailsStep) {
    subscriptionFilterStepPartial = subscriptionFilterStepPartial.filter(
      (filter) => filter.stepType !== SubscriptionStepType.DETAILS,
    );
  }

  const mifidStep = operation.subscriptionSteps.some((step) => step.__typename === 'MifidStepType');
  if (!mifidStep) {
    subscriptionFilterStepPartial = subscriptionFilterStepPartial.filter(
      (filter) =>
        filter.stepType !== SubscriptionStepType.MIFID &&
        filter.name !== 'AwaitingFundsSourceCertificate',
    );
  }

  return subscriptionFilterStepPartial;
}

export function getTabsWithCount(
  count?: SubscriptionsCountInfosFragment,
  hasMifid = false,
  isAllIn = false,
): SubscriptionTab[] {
  return [
    {
      name: SubscriptionsTabsStatus.ALL_STATUS,
      count: count?.total || 0,
    },
    {
      name: SubscriptionsTabsStatus.NOT_SIGNED_UP,
      count: count?.invitationSent || 0,
    },
    {
      name: SubscriptionsTabsStatus.SIGNED_UP,
      count: count?.signedUp || 0,
    },
    {
      name: SubscriptionsTabsStatus.PENDING,
      count: count?.pending || 0,
    },
    hasMifid
      ? {
          name: SubscriptionsTabsStatus.COMPLETED,
          count: count?.completed || 0,
        }
      : undefined,
    {
      name: SubscriptionsTabsStatus.APPROVED,
      count: count?.approved || 0,
    },
    isAllIn
      ? {
          name: SubscriptionsTabsStatus.FINALIZED,
          count: count?.finalized || 0,
        }
      : undefined,
    {
      name: SubscriptionsTabsStatus.REFUSED,
      count: count?.refused || 0,
    },
  ].filter((tab) => tab) as SubscriptionTab[];
}
