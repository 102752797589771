import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

import { StepFormContextProvider } from '../../provider';
import { OnboardingStep } from '../OnboardingStep';
import { SPVParameters } from '../forms/SPVInformations';

/**
 * OnboardingSPVParameters.
 * SPV informations, update step.
 *
 * @returns {JSX.Element}
 */
export function OnboardingSPVParameters(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const handleStepSubmit = (): void => {
    navigate(routes.onboardings.spv.edit.compensation.href);
  };

  return (
    <StepFormContextProvider>
      <OnboardingStep subTitle={t('DefineInvestmentVehicleInfo')} title={t('LegalInformation')}>
        <SPVParameters onSubmit={handleStepSubmit} />
        <Button key="submit" data-cy="next" form="spv-informations" type="submit" w="full">
          {t('Next')}
        </Button>
      </OnboardingStep>
    </StepFormContextProvider>
  );
}
