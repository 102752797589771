import { ApolloError, QueryResult } from '@apollo/client';
import { useMatch, useParams } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import {
  GetAllOperationsQuery,
  GetOperationsQuery,
  GetSpaceOperationsQuery,
  OperationStatus,
  SpaceType,
  useGetAllOperationsQuery,
  useGetOperationsQuery,
  useGetSpaceOperationsQuery,
  useGetSpaceQuery,
} from '@blockpulse3/graphql/hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type OperationsQueryResult = QueryResult<
  GetOperationsQuery | GetAllOperationsQuery | GetSpaceOperationsQuery,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>;

type OperationsData =
  | GetAllOperationsQuery['getAllOperations']
  | GetOperationsQuery['operations']
  | GetSpaceOperationsQuery['getSpaceOperations'];

type OperationsQuery = {
  operations: OperationsData;
  operationsLoading: boolean;
  operationsError: ApolloError | undefined;
  operationsRefetch: () => void;
};

export function useGetOperations(operationStatus?: OperationStatus): OperationsQuery {
  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { companyId = '', spaceId = authSpaceId } = useParams();

  const isSpace = useMatch(routes.space.href + '/*');

  const spaceReq = useGetSpaceQuery({
    variables: { spaceId },
    skip: !spaceId,
  });
  const space = spaceReq?.data?.space;
  const isSpaceGlobal = space?.type === SpaceType.GLOBAL;

  // Admin query
  const allOperationsReq = useGetAllOperationsQuery({
    variables: {
      status: operationStatus,
    },
    fetchPolicy: 'cache-and-network',
    skip: !!companyId || !isSpace || !space || !isSpaceGlobal,
  });

  // Space query
  const spaceOperationsReq = useGetSpaceOperationsQuery({
    variables: {
      status: operationStatus,
      spaceId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !!companyId || !isSpace || !space || !spaceId || isSpaceGlobal,
  });

  // Company query
  const operationsReq = useGetOperationsQuery({
    variables: {
      companyId,
      status: operationStatus,
    },
    fetchPolicy: 'cache-and-network',
    skip: !!isSpace || !companyId,
  });

  const {
    data,
    loading: operationsLoading,
    error: operationsError,
    refetch: operationsRefetch,
  } = ((operationsReq.called && operationsReq) ||
    (allOperationsReq.called && allOperationsReq) ||
    (spaceOperationsReq.called && spaceOperationsReq)) as OperationsQueryResult;

  let operations: OperationsData = [];
  if (data) {
    if ('getAllOperations' in data) {
      operations = data.getAllOperations;
    } else if ('getSpaceOperations' in data) {
      operations = data.getSpaceOperations;
    } else if ('operations' in data) {
      operations = data.operations;
    }
  }

  return {
    operations,
    operationsLoading,
    operationsError,
    operationsRefetch,
  };
}
