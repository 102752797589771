import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  HStack,
  Heading,
  Skeleton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  OperationSubscription,
  SecondaryMarketType,
  SubscriptionSide,
  useDeleteAuthorizedIdentityMutation,
  useGetCompanyShareholdersQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import {
  DeleteConfirmModal,
  ErrorQueryCard,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { NewSecondaryOperationSellerTable } from './NewSecondaryOperationSellerTable';
import { NewSecondaryOperationSellerTableControls } from './NewSecondaryOperationSellerTableControls';

type Props = unknown;

/**
 * NewSecondaryOperationSellers.
 * Seller step of the secondary operation workflow.
 *
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationSellers(): JSX.Element {
  const t = useTranslations();

  const deleteModalRef = useRef(null);

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const sellerDeleteModal = useDisclosure();

  const { companyId = '', operationId = '' } = useParams();
  const navigate = useNavigate();

  const {
    data: operationData,
    loading,
    error,
    refetch,
  } = useGetOperationQuery({
    variables: { operationId },
  });
  const { data: shareholdersData } = useGetCompanyShareholdersQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const [deleteAuthorizedIdentity, { loading: isDeleteLoading }] =
    useDeleteAuthorizedIdentityMutation();

  const [searchInput, setSearchInput] = useState<string>('');
  const [deletedIdentityId, setDeletedIdentityId] = useState<OperationSubscription['id'] | null>(
    null,
  );

  const sellers = useMemo(
    () => operationData?.operation.authorizedSellers || [],
    [operationData?.operation.authorizedSellers],
  );
  const filteredSellers = useMemo(() => {
    if (searchInput === '') {
      return sellers;
    }
    const searchInputLowerCase = searchInput.toLowerCase();
    return sellers.filter(
      (seller) =>
        seller.name.toLowerCase().includes(searchInputLowerCase) ||
        seller.email?.toLowerCase().includes(searchInputLowerCase) ||
        seller.identifier?.toLowerCase().includes(searchInputLowerCase),
    );
  }, [searchInput, sellers]);

  const handleSearchInputChange = (input: string): void => {
    setSearchInput(input);
  };

  const handleFormCancel = (): void => {
    navigate('..');
  };

  const handleFormSubmit = (): void => {
    if (
      operationData?.operation?.settings?.secondaryMarketType ===
      SecondaryMarketType.WITHDRAWAL_MARKET
    ) {
      navigate('../' + routes.company.newSecondary.edit.summary.href);
    } else {
      navigate('../' + routes.company.newSecondary.edit.buyers.href);
    }
  };

  const handleSellerDelete = (id: OperationSubscription['id']): void => {
    sellerDeleteModal.onOpen();
    setDeletedIdentityId(id);
  };

  const handleSellerDeleteConfirm = (): void => {
    if (!deletedIdentityId) return;

    deleteAuthorizedIdentity({
      variables: {
        deleteAuthorizedIdentityInput: {
          operationId,
          identityId: deletedIdentityId,
          side: SubscriptionSide.SELLER,
        },
      },
      onCompleted: () => {
        successToast({ title: t('SellerDeleted') });
        setDeletedIdentityId(null);
        sellerDeleteModal.onClose();
        refetch();
      },
      onError: () => {
        errorToast({ title: t('ErrorDeletingSeller') });
        setDeletedIdentityId(null);
        sellerDeleteModal.onClose();
      },
    });
  };

  if (loading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (!operationData || error) {
    return <ErrorQueryCard height="350px" width="full" />;
  }

  const { operation } = operationData;

  const shareholders = shareholdersData?.getCompanyShareholders || [];
  const isAddDisabled =
    sellers.length === shareholders.length || (!!operation.opportunityId && !!sellers.length);
  const canAddAllShareholders = !operation.opportunityId;
  const isNextDisabled = sellers.length === 0;

  return (
    <Card variant="divider-top" width="full">
      <CardHeader>
        <Heading size="lg">{t('SellersConfiguration')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        <NewSecondaryOperationSellerTableControls
          canAddAllShareholders={canAddAllShareholders}
          isAddDisabled={isAddDisabled}
          onSearchInputChange={handleSearchInputChange}
        />
        <NewSecondaryOperationSellerTable
          values={filteredSellers}
          onDeleteSeller={handleSellerDelete}
        />
      </CardBody>
      <CardFooter as={HStack} spacing="4">
        <Button type="button" variant="secondary" w="full" onClick={handleFormCancel}>
          {t('Back')}
        </Button>
        <Button isDisabled={isNextDisabled} type="submit" w="full" onClick={handleFormSubmit}>
          {t('Next')}
        </Button>
      </CardFooter>
      <DeleteConfirmModal
        isLoading={isDeleteLoading}
        isOpen={sellerDeleteModal.isOpen}
        leastDestructiveRef={deleteModalRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteSellerQuestion', { nb: 1 })}
        onClose={sellerDeleteModal.onClose}
        onDelete={handleSellerDeleteConfirm}
      />
    </Card>
  );
}

export type NewSecondaryOperationSellersProps = Props;
