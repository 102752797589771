import * as yup from 'yup';

import { requiredStringField } from '@blockpulse3/data/shared';

import { GenderPickerForm } from './types';

export const schema = yup
  .object({
    gender: requiredStringField,
  })
  .required();

export const genderPickerFormDefaultValues: GenderPickerForm = {
  gender: '',
};
