import { Image } from '@chakra-ui/react';

import { ThemeIcon, ThemeIconProps } from '../../components';
import LightLogo from './assets/auchan_dark.svg';
import DarkLogo from './assets/auchan_light.svg';

function LightIcon(): JSX.Element {
  return <Image alt="Auchan" height="100%" src={LightLogo} width="full" />;
}

function DarkIcon(): JSX.Element {
  return <Image alt="Auchan" height="100%" src={DarkLogo} />;
}

export function Icon({ ...props }: ThemeIconProps): JSX.Element {
  return <ThemeIcon darkIcon={<DarkIcon />} lightIcon={<LightIcon />} {...props} />;
}
