import {
  EnabledBy,
  MergedSubscriptionStepUnion,
  SubscriptionSide,
} from '@blockpulse3/graphql/hooks';

/**
 * isStepDisabled.
 * Go through other steps to determine if current step should be disabled
 *
 * @param {MergedSubscriptionStepUnion[]} subscriptionSteps
 * @param {number} currentStepIndex
 * @returns {boolean}
 */
export function isStepDisabled(
  subscriptionSteps: MergedSubscriptionStepUnion[],
  currentStepIndex: number,
  subscriptionSide: SubscriptionSide,
): boolean {
  // Skip if index for current step does not exist
  if (!subscriptionSteps[currentStepIndex]) return false;

  const { side, enabledBy } = subscriptionSteps[currentStepIndex];

  // Disable if side is different from subscription side
  if (side && subscriptionSide && side !== subscriptionSide) return true;

  // Check if current step is enabled by other steps
  const isEnabled = enabledBy.every((stepEnabledBy: EnabledBy) => {
    const targetIndex = currentStepIndex + stepEnabledBy.index;
    // Skip if no step found for index
    if (!subscriptionSteps[targetIndex]) return false;

    const targetedStep = subscriptionSteps[targetIndex];
    return stepEnabledBy.statuses.includes(targetedStep.status);
  });
  return !isEnabled;
}
