import { Stack } from '@chakra-ui/react';
import {
  CashIcon,
  ChartPieIcon,
  CurrencyEuroIcon,
  FolderIcon,
  HomeIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/outline';
import { useLocation, useParams, useResolvedPath } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  CompanyStatus,
  CompanyType,
  useGetBankAccountMemberQuery,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { NavGroupItem } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = unknown;

/**
 * SettingsNavCompany.
 * Render navigation items on company sub routes.
 *
 * @returns {JSX.Element}
 */
export function SettingsNavCompany(): JSX.Element {
  const t = useTranslations();

  const { pathname } = useLocation();

  const { user: authUser } = useAuthUser();
  const { companyId = '' } = useParams();

  const workspacePath = routes.company;

  const dashboard = useResolvedPath('');
  const captable = useResolvedPath(workspacePath.captable.href);
  const settings = useResolvedPath(workspacePath.settings.href);
  const documents = useResolvedPath(workspacePath.documents.href);
  const distributions = useResolvedPath(routes.company.distributions.href);

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const company = companyReq?.data?.company;

  const bankAccountMemberReq = useGetBankAccountMemberQuery({
    variables: {
      bankAccountId: company?.identity?.bankAccount?.id || '',
      individualIdentityId: authUser?.individualIdentity?.id || '',
    },
    skip: !company?.identity?.bankAccount || !authUser?.individualIdentity,
  });
  const hasBankAccount = !!company?.identity?.bankAccount;
  const bankDashboardUrl = bankAccountMemberReq.data?.getBankAccountMember?.bankDashboardUrl;

  const hasCaptable =
    !!company?.capTable?.fullyDilutedTotalSupply &&
    (!!company?.capTable?.stockTotalSupply || company?.type === CompanyType.PARTIAL);

  /* ** Assume workspace is complete when creation date is set ** */
  const isComplete = company?.status === CompanyStatus.RUNNING;

  return (
    <Stack spacing="1">
      <NavGroupItem
        active={dashboard.pathname === pathname}
        href=""
        icon={<HomeIcon height="18" width="18" />}
        label={t('Dashboard')}
      />
      {isComplete ? (
        <>
          {hasCaptable && (
            <NavGroupItem
              active={captable.pathname === pathname}
              href={workspacePath.captable.href}
              icon={<ChartPieIcon height="18" width="18" />}
              label={t('Shareholding')}
            />
          )}
          <NavGroupItem
            active={documents.pathname === pathname}
            href={workspacePath.documents.href}
            icon={<FolderIcon height="18" width="18" />}
            label={t('Document', { nb: 2 })}
          />
          {hasBankAccount && (
            <NavGroupItem
              active={distributions.pathname === pathname}
              href={routes.company.distributions.href}
              icon={<CashIcon height="18" width="18" />}
              label={t('Distribution', { nb: 2 })}
            />
          )}
          {bankDashboardUrl && (
            <NavGroupItem
              href={bankDashboardUrl}
              icon={<CurrencyEuroIcon height="18" width="18" />}
              label={t('PaymentAccount')}
            />
          )}
        </>
      ) : null}
      <NavGroupItem
        active={settings.pathname === pathname}
        href={workspacePath.settings.href}
        icon={<OfficeBuildingIcon height="18" width="18" />}
        label={t('CompanySettings')}
      />
    </Stack>
  );
}

export type SettingsNavCompanyProps = Props;
