import { useMemo } from 'react';
import { useTranslations } from 'use-intl';

import { isSubscriptionStarted } from '@blockpulse3/data/shared';
import {
  GetSubscriptionInfosFragment,
  OperationInvestStatus,
  OperationStatus,
} from '@blockpulse3/graphql/hooks';

import { IBadge } from './useBadge';

export function useSubscriptionBadge(subscription: GetSubscriptionInfosFragment | null): IBadge {
  const t = useTranslations();

  const investStatus = subscription?.investStatus;

  return useMemo(() => {
    if (
      investStatus &&
      [OperationInvestStatus.DECLINED, OperationInvestStatus.REFUSED].includes(investStatus)
    ) {
      return { color: 'red', label: t('SubscriptionRejected') };
    }
    if (subscription?.operation?.status === OperationStatus.FINALIZED) {
      return { color: 'green', label: t('Finalized') };
    }
    if (investStatus === OperationInvestStatus.COMPLETED) {
      return { color: 'blue', label: t('Completed', { nb: 1 }) };
    }
    if (investStatus === OperationInvestStatus.APPROVED) {
      return { color: 'green', label: t('Validated') };
    }
    if (investStatus === OperationInvestStatus.FINALIZED) {
      return { color: 'green', label: t('Finalized') };
    }
    if (isSubscriptionStarted(subscription)) {
      return { color: 'yellow', label: t('InProgress') };
    }
    return { color: 'gray', label: t('Pending') };
  }, [t, investStatus, subscription]);
}
