import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FormErrors, noop } from '@blockpulse3/data/shared';
import {
  OperationDocumentType,
  useDeleteOperationDocumentMutation,
  useGetOperationQuery,
  usePreviewOperationDocumentLazyQuery,
  useUpdateFundraisingMutation,
} from '@blockpulse3/graphql/hooks';
import { getURLObject } from '@blockpulse3/helpers';
import {
  CONTENT_LOADING,
  DropzoneInput,
  ErrorMessage,
  PreviewDocumentModal,
  useErrorToast,
} from '@blockpulse3/ui/commons';

import { fundraisingBankDetailsFormDefaults } from './schema';
import { FundraisingBankDetailsType, INewFundraisingUploadBankDetailsForm } from './types';

type Props = {
  /* ** On submit loading ** */
  isLoading?: boolean;
  /* ** Optional loading text to render on the submit button ** */
  loadingText?: string;
  /* ** Callback on form cancel ** */
  onCancel?: () => void;
  /* ** Callback on form submit ** */
  onSubmit?: (data: INewFundraisingUploadBankDetailsForm) => void;
};

/**
 * NewFundraisingUploadBankDetails.
 * Simple form to upload BankDetails document, used in Private and Crowdfunding workflows.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NewFundraisingUploadBankDetails({
  isLoading = false,
  loadingText = '',
  onSubmit = noop,
  onCancel = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { operationId = '', companyId = '' } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [fileSrc, setFileSrc] = useState<string | symbol | null>(CONTENT_LOADING);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);

  const { control, handleSubmit, formState, setValue, setError, clearErrors } =
    useForm<INewFundraisingUploadBankDetailsForm>({
      defaultValues: fundraisingBankDetailsFormDefaults,
    });

  const { data, loading, refetch } = useGetOperationQuery({
    variables: {
      operationId,
    },
    onCompleted: ({ operation }) => {
      setValue(
        'bankDetailsType',
        operation.isExternalIBAN
          ? FundraisingBankDetailsType.TEMPLATE
          : FundraisingBankDetailsType.DEFAULT,
      );
    },
    skip: !operationId,
  });

  const [updateFundraising] = useUpdateFundraisingMutation();
  const [previewOperationDocument] = usePreviewOperationDocumentLazyQuery();
  const [deleteOperationDocument] = useDeleteOperationDocumentMutation();

  const operation = data?.operation;
  const bankDetailsDocument = useMemo(() => {
    return (operation?.documents || []).find((doc) => doc.type === OperationDocumentType.IBAN);
  }, [operation]);

  const fileName = bankDetailsDocument
    ? {
        [bankDetailsDocument.id]: new File(
          [bankDetailsDocument.document.title],
          bankDetailsDocument.document.title,
        ),
      }
    : {};

  const handleFileUpload = async (acceptedFiles: File[]): Promise<void> => {
    if (!operationId) return;

    const formData = new FormData();
    formData.append('operationId', operationId);
    formData.append('document', acceptedFiles[0]);
    formData.append('documentType', OperationDocumentType.IBAN);
    setIsFileLoading(true);

    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/uploadOperationDocument',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .catch(() => {
        setError('bankDetailsFile', {
          type: 'custom',
          message: FormErrors.DropzoneInvalidTemplate,
        });
      });
    await refetch();
    setIsFileLoading(false);
  };

  /* ** Delete file handler ** */
  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    clearErrors('bankDetailsFile');
    if (fileRejections.length === 0) {
      handleFileUpload(acceptedFiles);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('bankDetailsFile', { type: 'custom', message: FormErrors.FileTooLarge });
          break;
        }
        default: {
          setError('bankDetailsFile', { type: 'custom', message: FormErrors.DropzoneDefault });
          break;
        }
      }
    }
  };

  const handleFileDelete = (): void => {
    if (operationId && bankDetailsDocument?.document) {
      deleteOperationDocument({
        variables: {
          deleteOperationDocumentInput: {
            operationId,
            documentType: OperationDocumentType.IBAN,
            documentId: bankDetailsDocument.document.id,
          },
        },
        onCompleted: () => {
          setValue('bankDetailsFile', undefined);
          refetch();
        },
        onError: () => {
          setError('bankDetailsFile', { type: 'custom', message: FormErrors.DropzoneDefault });
        },
      });
    }
  };

  const handleFormSubmit: SubmitHandler<INewFundraisingUploadBankDetailsForm> = async (
    data,
  ): Promise<void> => {
    if (data.bankDetailsType === FundraisingBankDetailsType.TEMPLATE && !data.bankDetailsFile) {
      setError('bankDetailsFile', { type: 'custom', message: FormErrors.FileRequired });
    } else {
      await refetch();
      onSubmit(data);
    }
  };

  const handleFormCancel = (): void => {
    if (!companyId) {
      return;
    }
    onCancel();
  };

  const handleBankDetailsTypeChange = (e: FundraisingBankDetailsType): void => {
    /* ** Custom onChange method, because update of document and isExternalIBAN are separate ** */
    setValue('bankDetailsType', e);
    updateFundraising({
      variables: {
        updateFundraisingInput: {
          operationId,
          isExternalIBAN: e === FundraisingBankDetailsType.TEMPLATE,
        },
      },
      onError: () => {
        errorToast({ title: t('FundraisingUpdateError') });
      },
    });
  };

  const handleFilePreview = (): void => {
    if (!operation) return;
    setFileSrc(CONTENT_LOADING);
    onOpen();

    previewOperationDocument({
      variables: {
        documentType: OperationDocumentType.IBAN,
        previewOperationDocumentId: operationId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ pdfURL }) => {
        const objURL = getURLObject(pdfURL, 'application/pdf');
        setFileSrc(objURL);
      },
    });
  };

  useEffect(() => {
    /* ** Reset dropzone file slot on `bankDetailsDocument` update ** */
    if (bankDetailsDocument) {
      setValue('bankDetailsFile', bankDetailsDocument.id);
    }
  }, [bankDetailsDocument, setValue]);

  return (
    <>
      <Card variant="divider-top" w="full">
        <CardHeader py="6">
          <Stack spacing="4">
            <Heading size="lg">{t('BankInfos')}</Heading>
            <Text>{t('ProvideOperationAccountDetails')}</Text>
          </Stack>
        </CardHeader>
        <Divider />
        <CardBody py="6">
          <form id="fundraising-bankDetails-document" onSubmit={handleSubmit(handleFormSubmit)}>
            <Controller
              control={control}
              name="bankDetailsType"
              render={({ field }): JSX.Element => (
                <FormControl isInvalid={!!formState.errors.bankDetailsType} w="full">
                  <FormLabel fontSize="xl" pb="2">
                    {t('BankIdentityStatement')}
                  </FormLabel>
                  <RadioGroup
                    as={Stack}
                    name={field.name}
                    ref={field.ref}
                    spacing="4"
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={(e: FundraisingBankDetailsType): void => {
                      handleBankDetailsTypeChange(e);
                    }}
                  >
                    <Radio value={FundraisingBankDetailsType.DEFAULT} variant="solid">
                      {t('SwanAccountUsage')}
                    </Radio>
                    <Radio value={FundraisingBankDetailsType.TEMPLATE} variant="solid">
                      {t('ExternalCapitalIncreaseAccountUsage')}
                      <Stack pt="4">
                        <Controller
                          control={control}
                          name="bankDetailsFile"
                          render={(): JSX.Element => (
                            <FormControl>
                              <DropzoneInput
                                isPreviewEnabled
                                accept={{ 'application/PDF': [] }}
                                files={fileName}
                                isLoading={isFileLoading}
                                maxFiles={1}
                                subTitle=".PDF"
                                icon={
                                  <Icon
                                    as={DocumentTextIcon}
                                    boxSize="42px"
                                    color="gray.400"
                                    strokeWidth="1px"
                                  />
                                }
                                onDelete={handleFileDelete}
                                onDrop={handleFileDrop}
                                onPreview={handleFilePreview}
                              />
                              <ErrorMessage error={formState.errors?.bankDetailsFile} />
                            </FormControl>
                          )}
                        />
                      </Stack>
                    </Radio>
                  </RadioGroup>
                </FormControl>
              )}
            />
          </form>
        </CardBody>
        <CardFooter as={HStack} spacing="4">
          <Button type="button" variant="secondary" w="full" onClick={handleFormCancel}>
            {t('Back')}
          </Button>
          <Button
            data-cy="next"
            form="fundraising-bankDetails-document"
            isDisabled={isFileLoading || loading}
            isLoading={isLoading}
            loadingText={loadingText}
            type="submit"
            w="full"
          >
            {t('Next')}
          </Button>
        </CardFooter>
      </Card>
      <PreviewDocumentModal isOpen={isOpen} src={fileSrc} title="IBAN" onClose={onClose} />
    </>
  );
}

export type NewFundraisingUploadBankDetailsProps = Props;
