import { HStack, Heading, Skeleton, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { useGetWalletsQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { WalletBalances } from './WalletBalances';

type Props = unknown;

/**
 * Wallet.
 *
 * @returns {JSX.Element}
 */
export function Wallet(): JSX.Element | null {
  const t = useTranslations();

  const { identityId } = useIdentity();

  const { data, loading, error } = useGetWalletsQuery({
    variables: {
      identityId: identityId || '',
    },
    skip: !identityId,
  });

  if (loading || !identityId) {
    return (
      <Stack spacing="4">
        <Heading size="md">{t('MyPortfolio')}</Heading>
        <HStack overflowX="hidden" spacing="4">
          <Skeleton minH="200px" minW="150px"></Skeleton>
          <Skeleton minH="200px" minW="150px"></Skeleton>
          <Skeleton minH="200px" minW="150px"></Skeleton>
          <Skeleton minH="200px" minW="150px"></Skeleton>
          <Skeleton minH="200px" minW="150px"></Skeleton>
          <Skeleton minH="200px" minW="150px"></Skeleton>
          <Skeleton minH="200px" minW="150px"></Skeleton>
          <Skeleton minH="200px" minW="150px"></Skeleton>
        </HStack>
      </Stack>
    );
  }

  if (error) {
    return (
      <Stack spacing="4">
        <Heading size="md">{t('MyPortfolio')}</Heading>
        <ErrorQueryCard h="200px" />
      </Stack>
    );
  }

  const wallets = data?.wallets || [];

  return (
    <Stack spacing="4">
      <Heading size="md">{t('MyPortfolio')}</Heading>
      <WalletBalances wallets={wallets} />
    </Stack>
  );
}

export type WalletProps = Props;
