import { Stack, Text } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { formatDate } from '@blockpulse3/helpers';

type Props = {
  date: Date;
};

/**
 * SubscriptionCompleted.
 * Displays the details of subscription validated activity.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function ActivitySubscriptionCompleted({ date }: Props): JSX.Element {
  const t = useTranslations();

  return (
    <Stack spacing={1}>
      <Text fontSize="sm">{t('SubscriptionCompleted')}</Text>
      <Stack color="gray.500" spacing={1}>
        <Text>{formatDate(date, 'DD/MM/YYYY')}</Text>
      </Stack>
    </Stack>
  );
}

export type ActivitySubscriptionCompletedProps = Props;
