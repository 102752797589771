import { ModalFooter, ModalFooterProps, Stack } from '@chakra-ui/react';

type Props = ModalFooterProps;

/**
 * ResponsiveModalFooter.
 * Custom responsive ModalFooter version with default props.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function ResponsiveModalFooter({ ...props }: Props): JSX.Element {
  return (
    <ModalFooter
      alignItems="stretch"
      as={Stack}
      direction={{ base: 'column', md: 'row' }}
      {...props}
    />
  );
}

export type ResponsiveModalFooterProps = Props;
