import { Brand } from '../types';
import { Icon } from './Icon';
import { SmallIcon } from './SmallIcon';
import { Theme } from './Theme';

export const DecathlonTheme: Brand = {
  Theme,
  SmallIcon,
  Icon,
};
