import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { useReconcileBankTransferMutation } from '@blockpulse3/graphql/hooks';
import {
  Input,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

type Props = {
  onClose: () => void;
  bankTransferId: string;
  onSubmit: () => void;
} & Omit<ResponsiveModalProps, 'children'>;

export function ReconcileBankTransferModal({
  bankTransferId,
  onSubmit,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const [value, setValue] = useState('');

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const [reconcileBankTransfer, { loading }] = useReconcileBankTransferMutation();

  const handleSubmit = (): void => {
    if (!value) return;

    reconcileBankTransfer({
      variables: {
        bankTransferId,
        subscriptionReference: value,
      },
      onCompleted: (res) => {
        if (res.reconcileBankTransfer) {
          successToast({ title: t('ReconciliationDone') });
          onSubmit();
          props.onClose();
        } else {
          errorToast({ title: t('ReconciliationError') });
        }
      },
    });
  };

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="xl" fontWeight="500">
          <Text>{t('ReconcileTransfer')}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor="reference">{t('SubscriptionReference')}</FormLabel>
            <Text color="gray.500" fontSize="sm" mb={2}>
              {t('ReconcileTransferDescription')}
            </Text>
            <Input
              id="reference"
              placeholder="DC-123456 or COMPLETED"
              type="text"
              value={value}
              onChange={(e): void => setValue(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button isDisabled={loading} onClick={props.onClose}>
            {t('Cancel')}
          </Button>
          <Button isLoading={loading} variant="secondary" onClick={handleSubmit}>
            {t('Reconcile')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
