import { Text } from '@chakra-ui/react';

import { IdentityShortInfosFragment, IdentityType } from '@blockpulse3/graphql/hooks';

import { IdentityAvatar } from '../../IdentityAvatar';
import { IdentityCard, IdentityCardDescription, IdentityCardTitle } from '../../IdentityCard';

type Props = {
  identity: IdentityShortInfosFragment;
};

export function IdentitySelectBadge({ identity }: Props): JSX.Element {
  const { type, profilePicture, isVerified, identifier, name, email } = identity;

  return (
    <IdentityCard>
      <IdentityAvatar src={profilePicture} type={type} />
      <IdentityCardTitle fontSize="md" isChecked={isVerified}>
        <Text fontWeight="600">{name}</Text>
        {type === IdentityType.COMPANY && <Text>({identifier})</Text>}
      </IdentityCardTitle>
      <IdentityCardDescription>{email}</IdentityCardDescription>
    </IdentityCard>
  );
}
