import { Box, Flex, HStack, Icon, IconButton, Spinner, Stack, Text } from '@chakra-ui/react';
import { DocumentTextIcon, EyeIcon, XIcon } from '@heroicons/react/solid';
import { ReactNode } from 'react';
import { DropzoneProps, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';

export const DEFAULT_DROP_ZONE_ACCEPT = {
  'image/jpeg': [],
  'image/png': [],
  'application/pdf': [],
};

/* ** Keep track of Document['id'] with associated File object ** */
export type DropzoneFiles = Record<string, File>;

type Props = {
  /* ** Track loading state ** */
  isLoading?: boolean;
  /* ** Should display preview button ** */
  isPreviewEnabled?: boolean;
  /* ** State files ** */
  files: DropzoneFiles;
  /* ** Accept multiple drag & drop ** */
  multiple?: boolean;
  /* ** Subtitle text, generally conditions ** */
  subTitle?: string;
  /* ** Icon ** */
  icon?: ReactNode | null;
  /* ** Callback on file drop ** */
  onDrop?: (files: File[], fileRejections: FileRejection[]) => void;
  /* ** Callback on file delete ** */
  onDelete?: (id: string) => void;
  /* ** Callback on file preview ** */
  onPreview?: (id: string) => void;
  /* ** Disable all box ** */
  isDisabled?: boolean;
} & Omit<DropzoneProps, 'multiple' | 'onChange'>;

export function DropzoneInput({
  isLoading = false,
  isPreviewEnabled = false,
  files,
  multiple = false,
  onDrop = noop,
  onDelete = noop,
  onPreview = noop,
  maxFiles = 1,
  subTitle = '',
  icon = null,
  isDisabled = false,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const { getRootProps, getInputProps } = useDropzone({
    accept: DEFAULT_DROP_ZONE_ACCEPT,
    maxSize: 5_000_000,
    maxFiles,
    multiple,
    onDrop,
    ...props,
  });

  const shouldDisable = Object.values(files).length >= maxFiles;

  if (isLoading) {
    return (
      <Stack h="100px" layerStyle="emptyState">
        <Spinner flexShrink="0" />
      </Stack>
    );
  }

  return (
    <Stack
      cursor={isDisabled ? 'not-allowed' : 'unset'}
      opacity={isDisabled ? '0.6' : '1'}
      spacing="1"
    >
      <Box
        position="relative"
        {...getRootProps()}
        borderColor="gray.300"
        borderStyle="dashed"
        borderWidth="2px"
        cursor="pointer"
        display={shouldDisable ? 'none' : 'box'}
        outline="0"
        pointerEvents={isDisabled ? 'none' : 'unset'}
        rounded="md"
      >
        <input
          data-cy="drag-and-drop"
          type="file"
          {...getInputProps()}
          style={{
            display: 'block',
            opacity: 0,
            height: '1px',
            position: 'absolute',
            cursor: 'pointer',
          }}
        />
        <Stack alignItems="center" justifyContent="center" p="3" spacing="1">
          {icon}
          <Text color="gray.600" fontSize="md" fontWeight="500">
            <Text as="span" color="secondary">
              {t('DownloadFile')}
            </Text>{' '}
            {t('OrDragAndDrop').toLowerCase()}
          </Text>
          <Text color="gray.500" fontSize="sm" fontWeight="400">
            {subTitle}
          </Text>
        </Stack>
      </Box>
      {Object.keys(files).map((id: string) => (
        <Flex
          key={id}
          alignItems="center"
          bg="gray.50"
          borderColor="secondary"
          borderStyle="solid"
          borderWidth="1px"
          justifyContent="space-between"
          p="5"
          pointerEvents={isDisabled ? 'none' : 'unset'}
          rounded="md"
        >
          <HStack spacing="2">
            <Icon as={DocumentTextIcon} boxSize="24px" color="gray.600" />
            <Text color="secondary" fontWeight="600">
              {files[id].name}
            </Text>
          </HStack>
          <HStack spacing="4">
            {isPreviewEnabled && (
              <IconButton
                aria-label="preview"
                icon={<Icon as={EyeIcon} boxSize="22px" color="gray.500" />}
                variant="unstyled"
                onClick={(): void => onPreview(id)}
              />
            )}
            <IconButton
              aria-label="preview"
              icon={<Icon as={XIcon} boxSize="16px" color="gray.400" />}
              variant="unstyled"
              onClick={(): void => onDelete(id)}
            />
          </HStack>
        </Flex>
      ))}
    </Stack>
  );
}

export type DropzoneInputProps = Props;
