import { Box } from '@chakra-ui/react';
import { explorerPlugin } from '@graphiql/plugin-explorer';
import '@graphiql/plugin-explorer/dist/style.css';
import { useTheme } from '@graphiql/react';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { GraphiQL } from 'graphiql';
import 'graphiql/graphiql.min.css';
import { useEffect } from 'react';

const fetcher = createGraphiQLFetcher({
  url: process.env?.['NX_API_ENDPOINT'] || '',
});

export function GraphiQLView(): JSX.Element {
  const explorer = explorerPlugin({
    showAttribution: false,
  });

  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme('light');
  }, [setTheme]);

  return (
    <Box height="100vh" mx={{ base: '-4', md: '-8' }} my="-6">
      <GraphiQL fetcher={fetcher} plugins={[explorer]}>
        <GraphiQL.Logo>
          <></>
        </GraphiQL.Logo>
      </GraphiQL>
    </Box>
  );
}
