import { Card, CardBody, Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { OperationList } from '@blockpulse3/web-client/dashboard';

import { SpaceHeader } from '../SpaceHeader';

type Props = unknown;

/**
 * AdminOperationList.
 * Dashboard of the admin user.
 *
 * @returns {JSX.Element}
 */
export function AdminOperationList(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack h="full" spacing="6">
      <Stack spacing="4">
        <SpaceHeader />
        <Heading size="lg">{t('Operation', { nb: 3 })}</Heading>
      </Stack>
      <Card>
        <CardBody>
          <OperationList displayType="table" />
        </CardBody>
      </Card>
    </Stack>
  );
}

export type AdminOperationListProps = Props;
