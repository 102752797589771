import { useDisclosure } from '@chakra-ui/hooks';
import { HStack, Icon, Stack, Text, Tooltip } from '@chakra-ui/react';
import { ArrowNarrowDownIcon, ArrowNarrowRightIcon, ScaleIcon } from '@heroicons/react/outline';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Exceptions } from '@blockpulse3/data/shared';
import {
  AssetType,
  MergedSaleStep,
  SubscriptionSide,
  SubscriptionStepStatus,
  SubscriptionStepType,
  useGetBalancesByAssetAndIdentityQuery,
  useGetSubscriptionQuery,
  useValidateSubscriptionSaleMutation,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  AssetBadge,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  useErrorToast,
} from '@blockpulse3/ui/commons';
import { useStepStatusMap } from '@blockpulse3/ui/ui-hooks';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionStep } from '../../SubscriptionStep';
import { SaleModal } from './SaleModal';
import { SaleStepForm } from './types';
import { getSaleStepValues } from './utils';

type Props = {
  subscriptionStep: MergedSaleStep;
  isDisabled: boolean;
};

export function SaleStep({ subscriptionStep, isDisabled }: Props): JSX.Element {
  const t = useTranslations();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const errorToast = useErrorToast();

  const { subscriptionId = '' } = useParams();

  const { identityId } = useIdentity();

  const [validateSubscriptionSale, { loading: validateStepLoading }] =
    useValidateSubscriptionSaleMutation();

  const { data, loading, refetch } = useGetSubscriptionQuery({
    variables: {
      subscriptionId,
      identityId,
    },
    skip: !subscriptionId || !identityId,
    notifyOnNetworkStatusChange: true,
  });
  const subscription = data?.subscription;
  const defaultValues = getSaleStepValues(subscription);

  const assetId = subscription?.operation?.asset?.id;
  const { refetch: balanceRefetch } = useGetBalancesByAssetAndIdentityQuery({
    variables: {
      identityId: identityId || '',
      assetId: assetId || '',
    },
    skip: !assetId || !identityId,
  });

  const [stepStatusMap, steps] = useStepStatusMap(subscription?.data?.mergedSteps);

  /* ** Get subscription's identity side context * **/
  const subscriptionSide =
    subscription?.sellerIdentity?.id === identityId
      ? SubscriptionSide.SELLER
      : SubscriptionSide.BUYER;

  const handleFormSubmit: SubmitHandler<SaleStepForm> = (data): void => {
    validateSubscriptionSale({
      variables: {
        validateSubscriptionSaleInput: {
          subscriptionId,
          investAmount: data.investAmount,
          buyerIdentityId: data.buyerIdentity.value,
        },
      },
      onCompleted: () => {
        refetch();
        balanceRefetch();
        onClose();
      },
      onError: (err) => {
        if (err.message === Exceptions.WalletMissingBankInformation) {
          errorToast({ title: t('WalletMissingBankInformation') });
        } else {
          errorToast({ title: t('SharesSaleValidationError') });
        }
      },
    });
  };

  const handleModalOpen = (): void => {
    const hasDocumentSign = steps.find(
      (s) =>
        s.type === SubscriptionStepType.DOCUMENT_SIGNATURE &&
        s.status !== SubscriptionStepStatus.NONE,
    );
    const isPaymentStepValidated =
      stepStatusMap[SubscriptionStepType.PAYMENT] === SubscriptionStepStatus.VALID;

    if (!!hasDocumentSign || isPaymentStepValidated) {
      return;
    }
    onOpen();
  };

  const { type, status, side } = subscriptionStep;
  const isStepDisabled = isDisabled || subscriptionSide !== side;

  const { buyerIdentity, sellerIdentity } = subscription || {};
  const { shareCount } = defaultValues;
  const sharePrice = subscription?.operation?.sharePrice || 0;
  const shouldDisplayDescription =
    !!shareCount && !!sharePrice && !!buyerIdentity && !!sellerIdentity;

  return (
    <>
      <SubscriptionStep
        icon={ScaleIcon}
        isDisabled={isStepDisabled}
        status={status}
        title={t('TermsOfSale')}
        type={type}
        onClick={handleModalOpen}
      >
        {shouldDisplayDescription && (
          <Stack
            align="flex-start"
            direction={{ base: 'column', xl: 'row' }}
            justifyContent="flex-start"
            py="2"
          >
            <IdentityCard
              background="gray.50"
              boxShadow="base"
              maxW={{ base: 'full', xl: '40%' }}
              px="2"
              py="1"
              rounded="md"
            >
              <IdentityAvatar
                boxSize="8"
                src={sellerIdentity.profilePicture}
                type={sellerIdentity.type}
              />
              <Tooltip
                placement="top"
                label={
                  <Stack spacing="0">
                    <HStack>
                      <Text>{sellerIdentity.name}</Text>
                    </HStack>
                    <Text>{sellerIdentity.email} </Text>
                  </Stack>
                }
              >
                <Stack
                  overflow="hidden"
                  spacing="0"
                  textOverflow="ellipsis"
                  w="full"
                  whiteSpace="nowrap"
                >
                  <IdentityCardTitle isChecked>
                    <Text fontWeight="600" overflow="hidden" textOverflow="ellipsis">
                      {sellerIdentity.name}
                    </Text>
                  </IdentityCardTitle>
                  <IdentityCardDescription overflow="hidden" textOverflow="ellipsis">
                    {sellerIdentity.identifier}
                  </IdentityCardDescription>
                </Stack>
              </Tooltip>
            </IdentityCard>
            <Stack
              alignItems="center"
              direction={{ base: 'row', xl: 'column' }}
              maxW={{ base: 'full', xl: '20%' }}
              px="2"
              spacing=".5"
            >
              <Icon
                as={ArrowNarrowRightIcon}
                boxSize="22px"
                display={{ base: 'none', xl: 'flex' }}
                marginBottom="-5px"
              />
              <Icon
                as={ArrowNarrowDownIcon}
                boxSize="18px"
                display={{ base: 'flex', xl: 'none' }}
              />
              <AssetBadge
                size="xs"
                type={subscription?.operation.assetType || AssetType.ORDINARY_SHARE}
                value={shareCount}
              />
              <Text color="gray.700" display={{ base: 'flex', xl: 'none' }} fontSize="xs">
                &nbsp;/&nbsp;
              </Text>
              <Text color="gray.700" fontSize="xs" whiteSpace="nowrap">
                {t('AtUnitPriceShort')} {formatNumberCurrency(sharePrice)}
              </Text>
            </Stack>
            <IdentityCard
              background="gray.50"
              boxShadow="base"
              maxW={{ base: 'full', xl: '40%' }}
              px="2"
              py="1"
              rounded="md"
            >
              <IdentityAvatar
                boxSize="8"
                src={buyerIdentity.profilePicture}
                type={buyerIdentity.type}
              />
              <Tooltip
                placement="top"
                label={
                  <Stack spacing="0">
                    <HStack>
                      <Text>{buyerIdentity.name}</Text>
                    </HStack>
                    <Text>{buyerIdentity.identifier} </Text>
                  </Stack>
                }
              >
                <Stack
                  overflow="hidden"
                  spacing="0"
                  textOverflow="ellipsis"
                  w="full"
                  whiteSpace="nowrap"
                >
                  <IdentityCardTitle isChecked>
                    <Text fontWeight="600" overflow="hidden" textOverflow="ellipsis">
                      {buyerIdentity.name}
                    </Text>
                  </IdentityCardTitle>
                  <IdentityCardDescription overflow="hidden" textOverflow="ellipsis">
                    {buyerIdentity.identifier}
                  </IdentityCardDescription>
                </Stack>
              </Tooltip>
            </IdentityCard>
          </Stack>
        )}
      </SubscriptionStep>
      {isOpen && (
        <SaleModal
          defaultValues={defaultValues}
          isLoading={validateStepLoading || loading}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={handleFormSubmit}
        />
      )}
    </>
  );
}
