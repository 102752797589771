import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerProps,
  HStack,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import { ContractType } from '@blockpulse3/data/shared';
import {
  AssetType,
  BalanceInfosFragment,
  Identity,
  IdentityType,
  OperationDocumentType,
  OptionTokenGrantFragment,
  useGetAssetQuery,
  useGetDocumentPdfUrlLazyQuery,
  useGetIdentityGrantsLazyQuery,
  useGetMovementsByAssetAndIdentityQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  ASSET_COLOR_MAP,
  AssetBadge,
  DocumentLink,
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';

import { CompanyValuationsCard } from '../CompanyValuationsCard';
import { TransactionsList } from '../TransactionsList';

type Props = {
  balance: BalanceInfosFragment;
  identityId: Identity['id'] | null;
} & Omit<DrawerProps, 'children'>;

export function OptionAssetSidePanel({ balance, identityId, ...props }: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValue = useTranslations('DocumentValues');

  const [getIdentityGrants] = useGetIdentityGrantsLazyQuery();
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();

  const [grants, setGrants] = useState<OptionTokenGrantFragment[]>([]);

  const assetReq = useGetAssetQuery({
    variables: {
      companyId: balance.asset.company.id,
      assetId: balance.asset.id,
    },
    skip: !balance.asset.id,
  });

  const movementsReq = useGetMovementsByAssetAndIdentityQuery({
    variables: {
      assetId: balance.asset.id,
      identityId: identityId || '',
    },
    skip: !identityId || !balance.asset.id,
  });

  useEffect(() => {
    if (!identityId) return;

    getIdentityGrants({
      variables: {
        getIdentityGrantsInput: {
          assetId: balance.asset.id,
          companyId: balance.asset.company.id,
          identityId,
        },
      },
      onCompleted: (data) => {
        setGrants(data.getIdentityGrants);
      },
    });
  }, [balance, identityId, getIdentityGrants]);

  if (assetReq.loading || movementsReq.loading) {
    return (
      <Drawer size="md" {...props}>
        <DrawerContent maxW={{ base: 'lg', xl: 'calc((100vw - var(--chakra-sizes-64))* 0.33)' }}>
          <DrawerCloseButton />
          <DrawerBody p="0">
            <Stack alignItems="center" h="full" justifyContent="center" py="4" spacing="4">
              <Spinner />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  if (
    !assetReq.data ||
    assetReq.error ||
    assetReq.data.asset?.token?.__typename !== 'OptionTokenModel'
  ) {
    return (
      <Drawer size="md" {...props}>
        <DrawerContent maxW={{ base: 'lg', xl: 'calc((100vw - var(--chakra-sizes-64))* 0.33)' }}>
          <DrawerCloseButton />
          <DrawerBody p="0">
            <ErrorQueryCard height="full" width="full" />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  const optionToken = assetReq.data.asset?.token;
  const assetDocuments = assetReq.data.asset?.documents || [];
  const movements = movementsReq.data?.getMovementsByAssetAndIdentity || [];

  const { subscriptionPrice, exercisePrice } = optionToken;

  const handleDocumentClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        window.open(pdfUrl, '_blank');
      },
    });
  };

  const { signer, contact } = balance.asset.company;
  const repIndividualIdentity = contact?.individualIdentity || signer.individualIdentity;

  const subscriptionSharePrice =
    movements.length && movements[0]?.subscription?.operation?.sharePrice;

  return (
    <Drawer size="md" {...props}>
      <DrawerContent
        borderColor={ASSET_COLOR_MAP[balance.asset.assetType]}
        borderStyle="solid"
        borderTopWidth="2px"
        maxW={{ base: 'lg', xl: 'calc((100vw - var(--chakra-sizes-64))* 0.33)' }}
      >
        <DrawerCloseButton />
        <DrawerBody p="0">
          <Stack pb="4" spacing="6">
            <Stack bg="gray.50" p="4" spacing="1">
              <CompanyValuationsCard
                balance={balance}
                m="-4"
                mb="0"
                underlyingAssetType={AssetType.ORDINARY_SHARE}
              />
              <HStack alignItems="start" fontSize="sm" justifyContent="space-between">
                <Text color="gray.500" fontWeight="500">
                  {t('Asset', { nb: balance.total })}
                </Text>
                <Tooltip hasArrow label={balance.asset.name} placement="top">
                  <HStack>
                    <AssetBadge
                      assetCount={balance.grantsCount}
                      type={balance.asset.assetType}
                      value={balance.total}
                    />
                    <Text fontSize="sm">- {balance.asset.name}</Text>
                  </HStack>
                </Tooltip>
              </HStack>
              {balance?.valuationAtGrant && (
                <HStack alignItems="start" fontSize="sm" justifyContent="space-between">
                  <Text color="gray.500" fontWeight="500">
                    {t('ValuationAtGrant')}
                  </Text>
                  <Stack alignItems="flex-end" justifyContent="space-between" spacing="0">
                    <Text fontWeight="500">{formatNumberCurrency(balance.valuationAtGrant)}</Text>
                    {movements.length && movements[0]?.subscription?.operation && (
                      <Text>
                        {t('NbPricePerShare', {
                          price: subscriptionSharePrice
                            ? formatNumberCurrency(subscriptionSharePrice)
                            : '-',
                        })}
                      </Text>
                    )}
                  </Stack>
                </HStack>
              )}
              {![AssetType.AGA, AssetType.BSPCE].includes(balance.asset.assetType) && (
                <HStack fontSize="sm" justifyContent="space-between">
                  <Text color="gray.500" fontWeight="500">
                    {t('SubscriptionPrice')}
                  </Text>
                  <Text fontWeight="500">{formatNumberCurrency(subscriptionPrice)}</Text>
                </HStack>
              )}
              {balance.asset.assetType !== AssetType.AGA && (
                <HStack fontSize="sm" justifyContent="space-between">
                  <Text color="gray.500" fontWeight="500">
                    {t('ExercisePrice')}
                  </Text>
                  <Text fontWeight="500">{formatNumberCurrency(exercisePrice)}</Text>
                </HStack>
              )}
              {balance.asset.assetType === AssetType.BSA_AIR && (
                <Text color="gray.400" fontSize="xs" fontWeight="500">
                  {t('PriceInfoBSA_AIR')}
                </Text>
              )}
            </Stack>
            <TransactionsList
              contractType={ContractType.OPTION_TOKEN}
              grants={grants}
              identityId={identityId}
              movements={movements}
              optionToken={optionToken}
            />
            <Divider />
            {assetDocuments.length && (
              <>
                <Stack px="4" spacing="2">
                  <Text color="gray.600" fontWeight="600">
                    {t('CompanyDocuments')}
                  </Text>
                  <Stack spacing="2">
                    {assetDocuments.map((document) => {
                      if (!document) return null;
                      return (
                        <DocumentLink
                          key={document.id}
                          fileName={
                            document.type === OperationDocumentType.CUSTOM_FILE
                              ? document.title
                              : i18nDocumentValue(document.type as IntlDocumentValues)
                          }
                          onClick={(): void => handleDocumentClick(document.id)}
                        />
                      );
                    })}
                  </Stack>
                </Stack>
                <Divider />
              </>
            )}
            <Stack px="4" spacing="2">
              <Text color="gray.600" fontWeight="600">
                {t('Contact', { nb: 1 })}
              </Text>
              <IdentityCard bg="gray.50" p="3">
                <IdentityAvatar type={IdentityType.INDIVIDUAL} />
                <IdentityCardTitle isChecked>
                  <Text>{repIndividualIdentity?.name}</Text>
                </IdentityCardTitle>
                <IdentityCardDescription>{repIndividualIdentity?.email}</IdentityCardDescription>
              </IdentityCard>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export type OptionAssetSidePanelProps = Props;
