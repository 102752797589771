import { Card, HStack, Heading, Icon, Skeleton } from '@chakra-ui/react';
import { ShieldExclamationIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useGetSpaceQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, useErrorToast } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = unknown;

/**
 * SpaceHeader.
 *
 * @returns {JSX.Element}
 */
export function SpaceHeader(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();
  const { spaceId = '' } = useAuthUser();

  const errorToast = useErrorToast();

  const { data, loading, error } = useGetSpaceQuery({
    variables: { spaceId },
    skip: !spaceId,
    onError: () => {
      navigate(routes.me.href);
      errorToast({ title: t('SpaceRetrievalError') });
    },
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="30px" />
      </Skeleton>
    );
  }

  if (error || !data) {
    return <ErrorQueryCard h="30px" />;
  }

  const { space } = data;

  return (
    <HStack>
      <Icon as={ShieldExclamationIcon} boxSize="30px" color="black" />
      <Heading color="black" fontWeight="800" size="md" textTransform="uppercase">
        {space.name}
      </Heading>
    </HStack>
  );
}

export type SpaceHeader = Props;
