import { Avatar, AvatarProps, Icon } from '@chakra-ui/react';
import { UserIcon } from '@heroicons/react/outline';

type Props = Omit<AvatarProps, 'src'> & {
  src?: string | null;
};

/**
 * UserIdentityAvatar.
 * Avatar wrapper for Identity User. Fallback is default Avatar icon.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function UserIdentityAvatar({ src, ...props }: Props): JSX.Element {
  return (
    <Avatar
      boxSize="9"
      icon={<Icon as={UserIcon} boxSize="50%" color="gray.400" />}
      loading="lazy"
      src={src || undefined}
      {...props}
    />
  );
}

export type UserIdentityAvatarProps = Props;
