import { Box, BoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = {
  lightIcon: ReactNode;
  darkIcon?: ReactNode;
  bgTheme?: 'light' | 'dark';
} & BoxProps;

/**
 * ThemeIcon.
 * DarkIcons are used with dark backgrounds
 * You might not want to pass it a DarkIcon if you're using pngs
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function ThemeIcon({
  lightIcon,
  darkIcon = lightIcon,
  bgTheme = 'light',
  ...props
}: Props): JSX.Element {
  return (
    <Box h="65px" maxHeight="65px" w="200px" {...props}>
      {bgTheme === 'dark' ? darkIcon : lightIcon}
    </Box>
  );
}

export type ThemeIconProps = Omit<Props, 'lightIcon' | 'darkIcon'>;
