import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { ErrorMessage, Input } from '@blockpulse3/ui/commons';

import { schema } from './schema';
import { IUpdatePasswordForm } from './types';

type Props = {
  onSubmit: (data: IUpdatePasswordForm) => void;
};

/**
 * UpdatePasswordForm.
 * Form to update the password of the user.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function UpdatePasswordForm({ onSubmit }: Props): JSX.Element {
  const t = useTranslations();

  const [showCurPassword, setShowCurPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPassword2, setShowPassword2] = useState<boolean>(false);

  /* ** Control if form is "touched" (!= changed) ** */
  const [isTouched, setIsTouched] = useState<boolean>(false);
  /* ** Update password form ** */
  const { register, formState, handleSubmit } = useForm<IUpdatePasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  /* ** Submit button disabled state ** */
  const isDisabled = useMemo(() => {
    return !formState.isValid || !isTouched;
  }, [isTouched, formState.isValid]);

  const handleFormTouched = (): void => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleFormSubmit: SubmitHandler<IUpdatePasswordForm> = (data: IUpdatePasswordForm) => {
    onSubmit(data);
  };

  return (
    <form onChange={handleFormTouched} onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }}>
        <Text flexBasis={{ lg: 250 }} fontSize="lg" fontWeight="medium">
          {t('ChangeMyPassword')}
        </Text>
        <Card maxW={{ lg: '3xl' }} variant="divider-bottom" w="full">
          <CardBody>
            <Stack spacing="6">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="4"
                w={{ base: 'full', md: '49%' }}
              >
                <FormControl isInvalid={!!formState.errors.currentPassword}>
                  <FormLabel htmlFor="password">{t('CurrentPassword')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="password"
                      type={showCurPassword ? 'text' : 'password'}
                      {...register('currentPassword')}
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="preview"
                        display="flex"
                        icon={<Icon as={showCurPassword ? EyeIcon : EyeOffIcon} boxSize={4} />}
                        variant="unstyled"
                        onClick={(): void => setShowCurPassword((crr) => !crr)}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <ErrorMessage error={formState.errors.currentPassword} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors.newPassword}>
                  <FormLabel htmlFor="newPassword">{t('NewPassword')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="newPassword"
                      type={showPassword ? 'text' : 'password'}
                      {...register('newPassword')}
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="preview"
                        display="flex"
                        icon={<Icon as={showPassword ? EyeIcon : EyeOffIcon} boxSize={4} />}
                        variant="unstyled"
                        onClick={(): void => setShowPassword((crr) => !crr)}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <ErrorMessage error={formState.errors.newPassword} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors.confirm}>
                  <FormLabel htmlFor="confirm">{t('NewPasswordConfirmation')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="confirm"
                      type={showPassword2 ? 'text' : 'password'}
                      {...register('confirm')}
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="preview"
                        display="flex"
                        icon={<Icon as={showPassword2 ? EyeIcon : EyeOffIcon} boxSize={4} />}
                        variant="unstyled"
                        onClick={(): void => setShowPassword2((crr) => !crr)}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <ErrorMessage error={formState.errors.confirm} />
                </FormControl>
              </Stack>
            </Stack>
          </CardBody>
          <Divider />
          <CardFooter justifyContent="flex-end">
            <Button disabled={isDisabled} type="submit">
              {t('Save')}
            </Button>
          </CardFooter>
        </Card>
      </Stack>
    </form>
  );
}

export type UpdatePasswordFormProps = Props;
