import { Icon, IconButton, Stack, Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import { TrashIcon } from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { Asset, AssetEntityInfosFragment, AssetType } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import { AssetBadge, TableContainer } from '@blockpulse3/ui/commons';

import { IAssetRow } from './types';

type Props = {
  /* ** List of previously created assets ** */
  assets: IAssetRow[];
  /* ** On edit asset callback ** */
  onEditAsset?: (asset: AssetEntityInfosFragment) => void;
  /* ** On delete asset callback ** */
  onDeleteAsset?: (id: Asset['id']) => void;
};

export function RepatriationShareTransferRegisterAssetTable({
  assets = [],
  onEditAsset = noop,
  onDeleteAsset = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const handleEditAsset = (asset: AssetEntityInfosFragment): void => {
    onEditAsset(asset);
  };

  const handleDeleteAsset = (id: Asset['id']): void => {
    onDeleteAsset(id);
  };

  assets.sort((a, b) => {
    if (a.data.assetType !== b.data.assetType) {
      return a.data.assetType === AssetType.ORDINARY_SHARE ? -1 : 1;
    }
    return a.name < b.name ? -1 : 1;
  });

  return (
    <Stack spacing="4">
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>{t('AssetName')}</Th>
              <Th>{t('Type')}</Th>
              <Th isNumeric>{t('NominalValue')}</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {assets.map((asset) => (
              <Tr key={asset.id}>
                <Td fontWeight="600">{asset.name}</Td>
                <Td fontWeight="600">
                  <AssetBadge type={asset.data.assetType} value={null} />
                </Td>
                <Td isNumeric fontWeight="600">
                  {formatNumberCurrency(asset.nominalValue)}
                </Td>
                <Td p="4">
                  <IconButton
                    aria-label="edit"
                    icon={<Icon as={PencilIcon} boxSize="4" />}
                    size="sm"
                    variant="icon"
                    onClick={(): void => handleEditAsset(asset.data)}
                  />
                  <IconButton
                    aria-label="delete"
                    icon={<Icon as={TrashIcon} boxSize="4" />}
                    size="sm"
                    variant="icon-delete"
                    onClick={(): void => handleDeleteAsset(asset.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th fontWeight="bold">{t('AssetCount', { nb: assets.length })}</Th>
              <Th />
              <Th />
              <Th />
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export type RepatriationShareTransferRegisterAssetTableProps = Props;
