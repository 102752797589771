import { HStack, Icon, Skeleton, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { BriefcaseIcon, PencilIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlHoldingMethodTypeValues } from '@blockpulse3/data/locales/types';
import {
  HoldingMethod,
  IdentityType,
  OperationStatus,
  OperationType,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { formatReferenceLabel } from '@blockpulse3/helpers';
import { IconButtonWithTooltip } from '@blockpulse3/ui/commons';

import { ChangeHoldingMethodModal } from './ChangeHoldingMethodModal';

type Props = {
  subscriptionId?: string;
};

/**
 * OperationPanelHoldingMethod.
 * Holding method logic section of the susbcription side panel.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationPanelHoldingMethod({
  subscriptionId: subscriptionIdProp = '',
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nHoldingMethodType = useTranslations('HoldingMethodTypeValues');

  const { subscriptionId = subscriptionIdProp } = useParams();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('HoldingMode')}
        </Text>
        <Skeleton h="60px" />
      </Stack>
    );
  }

  const subscription = data.subscription;
  const isSeller = subscription?.operation?.type === OperationType.SECONDARY;
  const subscriptionIdentity = isSeller
    ? subscription?.sellerIdentity
    : subscription?.buyerIdentity;
  const isCompany = subscriptionIdentity?.type === IdentityType.COMPANY;
  const isClosed = [OperationStatus.CLOSED, OperationStatus.FINALIZED].includes(
    subscription.operation.status,
  );

  const canCorrectHoldingMethod = !isClosed && !isCompany && !isSeller;

  const { holdingMethod } = subscription || {
    name: t('RegisteredForm'),
  };

  const referenceLabel =
    subscription?.reference && subscriptionIdentity
      ? formatReferenceLabel(subscriptionIdentity.name, subscription.reference.label)
      : '';

  /* ** Wallet name ** */
  const wallet = subscription.wallet;
  const walletTitle =
    wallet && wallet.holdingMethod !== HoldingMethod.DIRECT
      ? wallet.name + (wallet?.bankCode ? ` (${wallet.bankCode})` : '')
      : '';
  const holdingMethodTitle = i18nHoldingMethodType(holdingMethod as IntlHoldingMethodTypeValues);

  return (
    <Stack px="4" spacing="2">
      <HStack justifyContent="space-between">
        <Text color="gray.600" fontWeight="600">
          {t('HoldingMode')}
        </Text>
        {canCorrectHoldingMethod && (
          <IconButtonWithTooltip
            aria-label="edit"
            icon={<Icon as={PencilIcon} boxSize="18px" color="gray.700" />}
            label={t('UpdateHoldingMethod')}
            size="sm"
            variant="secondary"
            onClick={onOpen}
          />
        )}
      </HStack>
      <HStack>
        <Icon
          as={BriefcaseIcon}
          backgroundColor="gray.50"
          borderRadius="100px"
          boxSize="30px"
          color="gray.500"
          p="4px"
        />
        <Text fontSize="sm" fontWeight="600">
          {walletTitle || holdingMethodTitle}
        </Text>
      </HStack>
      {wallet?.bankAccountNumber && (
        <HStack justify="space-between">
          <Text color="gray.500" fontSize="sm" fontWeight="500">
            {t('AccountNumber')}
          </Text>
          <Text fontSize="sm" fontWeight="500">
            {wallet.bankAccountNumber}
          </Text>
        </HStack>
      )}
      {wallet?.bankEmail && (
        <HStack justify="space-between">
          <Text color="gray.500" fontSize="sm" fontWeight="500">
            {t('BankerEmail')}
          </Text>
          <Text fontSize="sm" fontWeight="500">
            {wallet.bankEmail}
          </Text>
        </HStack>
      )}
      {referenceLabel && (
        <HStack justify="space-between">
          <Text color="gray.500" fontSize="sm" fontWeight="500">
            {t('TransferReference')}
          </Text>
          <Text fontSize="sm" fontWeight="500">
            {referenceLabel}
          </Text>
        </HStack>
      )}
      {canCorrectHoldingMethod && isOpen && (
        <ChangeHoldingMethodModal
          isOpen={isOpen}
          subscriptionId={subscriptionId}
          onClose={onClose}
        />
      )}
    </Stack>
  );
}

export type OperationPanelHoldingMethodProps = Props;
