import { Icon, Stack, StackProps, Text } from '@chakra-ui/react';
import { MailIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { ISubscription } from '@blockpulse3/data/shared';

import { InvitationCard } from './InvitationCard';

type Props = {
  subscriptions: ISubscription[];
  limit?: number;
  size?: 'sm' | 'md' | 'lg';
} & StackProps;

export function InvitationCards({ subscriptions, limit, size, ...props }: Props): JSX.Element {
  const t = useTranslations();

  if (!subscriptions.length) {
    return (
      <Stack layerStyle="emptyState">
        <Icon as={MailIcon} boxSize="40px" color="gray.500" />
        <Text>{t(limit ? 'NoCurrentTransaction' : 'NoTransaction')}</Text>
      </Stack>
    );
  }

  const subscriptionsLimited = limit ? subscriptions.slice(0, limit) : subscriptions;

  return (
    <Stack gap="3" {...props}>
      {subscriptionsLimited.map((subscription) => {
        return <InvitationCard key={subscription.id} size={size} subscription={subscription} />;
      })}
    </Stack>
  );
}
