import { Badge, HStack, Icon, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';

type Props = {
  title: string;
  status: 'valid' | 'error';
};

export function VerificationBadge({ title, status }: Props): JSX.Element {
  const badge = {
    bgColor: status === 'valid' ? 'green.100' : 'red.100',
    color: status === 'valid' ? 'green.800' : 'red.800',
    dotColor: status === 'valid' ? 'green.500' : 'red.400',
  };

  return (
    <Badge backgroundColor={badge.bgColor} color={badge.color} pl="1" pr="2" py="0.5">
      <HStack spacing="1">
        <Icon as={CheckCircleIcon} boxSize="15px" color={badge.dotColor} />
        <Text fontSize="xs">{title}</Text>
      </HStack>
    </Badge>
  );
}
