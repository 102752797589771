import { createContext } from 'react';

import { SubscriptionInfosFragment } from '@blockpulse3/graphql/hooks';

export type IMifidModalContext = {
  isSectionDisabled: boolean;
  subscriptionId: SubscriptionInfosFragment['id'];
};

export const MifidModalContext = createContext<IMifidModalContext>({
  isSectionDisabled: false,
  subscriptionId: '',
});
