import { QueryResult } from '@apollo/client';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import {
  Exact,
  GetCompanyQuery,
  IdentityVerificationStatus,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { isCompanyComplete } from '@blockpulse3/helpers';

/**
 * useGetOnboardingExistingCompany.
 * Hook wrapping redirection to the workspace of the company if its verified.
 *
 * @returns {QueryResult< GetCompanyQuery, Exact<{ companyId: string }> >}
 */
export function useGetOnboardingExistingCompany(
  isEditing = false,
): QueryResult<GetCompanyQuery, Exact<{ companyId: string }>> {
  const navigate = useNavigate();
  const { companyId = '' } = useParams();

  return useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
    onCompleted: (data) => {
      if (
        !isEditing &&
        data.company.kybVerificationStatus === IdentityVerificationStatus.APPROVED &&
        isCompanyComplete(data.company)
      ) {
        navigate(generatePath(routes.company.href, { companyId: data.company.id }));
      }
    },
  });
}
