import { Button, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslations } from 'use-intl';

export function ClosePopup(): JSX.Element {
  const t = useTranslations();

  useEffect(() => {
    window.opener.closePopup();
  }, []);

  return (
    <Stack h="full" p="4" w="full">
      <Button onClick={(): void => window.close()}>{t('CloseTheWindow')}</Button>
    </Stack>
  );
}
