import { Alert, AlertIcon, AlertTitle, Link, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { getBrand, getBrandConfig, getHostName } from '@blockpulse3/data/shared';

type Props = unknown;

/**
 * PhoneBanner.
 * Brand specific component for telephone informations.
 *
 * @returns {JSX.Element}
 */
export function PhoneBanner(): JSX.Element {
  const t = useTranslations();

  const { phone } = getBrandConfig(getBrand(getHostName()));

  return (
    <Alert status="info">
      <AlertIcon />
      <Stack spacing="0">
        <AlertTitle>
          {t.rich('ContactUsForInfo', {
            phone: phone.label,
            important: (chunk) => (
              <Link fontWeight="bold" href={`tel:=${phone.href}`} whiteSpace="nowrap">
                {chunk}
              </Link>
            ),
          })}
          {phone.extraInfo ? ` ${phone.extraInfo}` : null}
        </AlertTitle>
      </Stack>
    </Alert>
  );
}

export type PhoneBannerProps = Props;
