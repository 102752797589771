import { FormControl, FormLabel, Stack, Switch, Text, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Exceptions, noop } from '@blockpulse3/data/shared';
import {
  IdentityType,
  useCreateCompanyRepresentativeMutation,
  useCreateIndividualRepresentativeMutation,
  useDeleteRepresentativeMutation,
} from '@blockpulse3/graphql/hooks';
import {
  SwitchIdentityType,
  SwitchIdentityTypeChange,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useAuthUser, useManagedIndividual } from '@blockpulse3/web-client/auth';

import { representativeFormDefaults } from '../schema';
import { IRepresentativeForm } from '../types';
import { getIndividualRepresentative } from '../utils';
import { LegalRepresentativeInformationsForm } from './LegalRepresentativeInformationsForm';
import { NaturalRepresentativeInformationsForm } from './NaturalRepresentativeInformationsForm';

type Props = {
  /* ** Id of the representative ** */
  id: string | null;
  /* ** IdentityType of the form ** */
  type?: IdentityType;
  /* ** Default form values for the Legal and Natural ** */
  defaultValues?: IRepresentativeForm;
  /* ** Is the Cancel button disabled ** */
  shouldCancel?: boolean;
  /* ** Representative is the president ** */
  isPresident?: boolean;
  /* ** Should fill creation date for moral representatives ** */
  hasCreationDate?: boolean;
  /* ** Should fill filiation for natural representatives ** */
  hasFiliation?: boolean;
  /* ** Callback of the Cancel button ** */
  onCancel?: () => void;
  /* ** Callback on submit complete ** */
  onSubmitComplete?: () => void;
  /* ** Callback on submit error ** */
  onSubmitError?: () => void;
};

export const RepresentantInformationsForm = React.forwardRef<HTMLFormElement, Props>(
  (props, ref) => {
    const {
      id = null,
      defaultValues = representativeFormDefaults,
      shouldCancel = false,
      isPresident = false,
      hasCreationDate = false,
      hasFiliation = false,
      onCancel = noop,
      onSubmitComplete = noop,
      onSubmitError = noop,
    } = props;

    const t = useTranslations();

    const errorToast = useErrorToast();
    const successToast = useSuccessToast();

    const { companyId = '' } = useParams();
    const { user: authUser } = useAuthUser();
    const { individual } = useManagedIndividual();

    const [type, setType] = useState<IdentityType>(defaultValues.type);

    const [formValues, setFormValues] = useState<IRepresentativeForm>(defaultValues);
    const [showAlert, setShowAlert] = useState(false);

    const [createCompanyRepresentative] = useCreateCompanyRepresentativeMutation();
    const [createIndividualRepresentative] = useCreateIndividualRepresentativeMutation();
    const [deleteRepresentative] = useDeleteRepresentativeMutation();

    /* ** Disclosure to control the natural switch ** */
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure({
      defaultIsOpen: false,
    });

    const handleUserIdentityChange = (): void => {
      if (type === IdentityType.COMPANY) {
        setType(IdentityType.INDIVIDUAL);
        onOpen();
      } else {
        onToggle();
      }
      if (!isOpen && individual) {
        /* ** Fill with individual values ** */
        const individualValues = getIndividualRepresentative(individual);
        setFormValues(individualValues);
      }
    };

    const handleIdentityTypeChange = ({ next }: SwitchIdentityTypeChange): void => {
      if (next === IdentityType.COMPANY) {
        onClose();
      }
      setType(next);
      /* ** Reset form on type change, forcing update ** */
      setFormValues(representativeFormDefaults);
    };

    const deletePreviousRepresentative = async (id: string | null): Promise<void> => {
      if (id && companyId) {
        await deleteRepresentative({ variables: { companyId, representativeId: id } });
      }
    };

    // TODO: handle deletion of signer representative
    const handleLegalFormSubmit = async (data: IRepresentativeForm): Promise<void> => {
      /* ** Create new representative ** */
      createCompanyRepresentative({
        variables: {
          createCompanyRepresentativeInput: {
            role: data.role,
            companyId,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            position: data.position,
            creationDate: data.creationDate,
            address: {
              line: data.address.line,
              city: data.address.city,
              postalCode: data.address.postalCode,
              country: data.address.country.value,
            },
            name: data.name,
            corporateForm: data.corporateForm,
            shareCapital: data.shareCapital,
            registrationEntity: data.registrationEntity,
            registrationNumber: data.registrationNumber,
            businessActivityDescription: data.businessActivityDescription,
          },
        },
        onCompleted: async (data) => {
          if (!id) {
            successToast({ title: t('RepresentativeAdded') });
          } else {
            if (id !== data.createCompanyRepresentative.id) {
              /* ** Delete previous submitted representative with its id ** */
              await deletePreviousRepresentative(id);
            }
            successToast({ title: t('RepresentativeModified') });
          }
          setShowAlert(false);
          onSubmitComplete();
        },
        onError: (err) => {
          if (err.message === Exceptions.NotARepresentativeOfCompany) {
            setShowAlert(true);
          } else {
            setShowAlert(false);
          }
          errorToast({ title: t('AddRepresentativeError') });
          onSubmitError();
        },
      });
    };

    const handleNaturalFormSubmit = async (data: IRepresentativeForm): Promise<void> => {
      setShowAlert(false);
      /* ** Create new representative ** */
      createIndividualRepresentative({
        variables: {
          createIndividualRepresentativeInput: {
            role: data.role,
            companyId,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            nationality: data.nationality.value,
            birthdate: data.birthdate,
            birthplace: data.birthplace,
            address: {
              line: data.address.line,
              city: data.address.city,
              postalCode: data.address.postalCode,
              country: data.address.country.value,
            },
            birthCityPostalCode: data.birthCityPostalCode,
            birthCountry: data.birthCountry.value,
            filiation: {
              fatherFirstName: data?.fatherFirstName || '',
              fatherLastName: data?.fatherLastName || '',
              motherFirstName: data?.motherFirstName || '',
              motherLastName: data?.motherLastName || '',
            },
          },
        },
        onCompleted: async (data) => {
          if (!id) {
            successToast({ title: t('RepresentativeAdded') });
          } else {
            if (id !== data.createIndividualRepresentative.id) {
              /* ** Delete previous submitted representative with its id ** */
              await deletePreviousRepresentative(id);
            }
            successToast({ title: t('RepresentativeModified') });
          }
          onSubmitComplete();
        },
        onError: () => {
          errorToast({ title: t('AddRepresentativeError') });
          onSubmitError();
        },
      });
    };

    /* ** Cancel whatever representant form is being rendered ** */
    const handleFormCancel = (): void => {
      onCancel();
    };

    const isUserIndividual = authUser?.individualIdentity?.id === individual?.id;

    return (
      <Stack spacing="4">
        <SwitchIdentityType
          defaultValue={type}
          direction={{ base: 'column', md: 'row' }}
          onChange={handleIdentityTypeChange}
        />
        <Stack direction={{ base: 'column', md: 'row' }}>
          <FormControl alignItems="center" display="flex">
            <Switch id="isMe" isChecked={isOpen} onChange={handleUserIdentityChange} />
            <FormLabel htmlFor="isMe" mb="0" ml="2">
              <Text>
                {t.rich(isUserIndividual ? 'ItsMe' : 'ItsName', {
                  name: `${individual?.firstName} ${individual?.lastName}`,
                  important: (chunk) => (
                    <Text as="span" fontWeight="bold">
                      {chunk}
                    </Text>
                  ),
                })}
              </Text>
            </FormLabel>
          </FormControl>
        </Stack>
        {type === IdentityType.COMPANY ? (
          <LegalRepresentativeInformationsForm
            defaultValues={formValues}
            hasCreationDate={hasCreationDate}
            isPresident={isPresident}
            ref={ref}
            shouldCancel={shouldCancel}
            showRepresentativeAlert={showAlert}
            onCancel={handleFormCancel}
            onSubmit={handleLegalFormSubmit}
          />
        ) : (
          <NaturalRepresentativeInformationsForm
            defaultValues={formValues}
            hasFiliation={hasFiliation}
            isPresident={isPresident}
            ref={ref}
            shouldCancel={shouldCancel}
            onCancel={handleFormCancel}
            onSubmit={handleNaturalFormSubmit}
          />
        )}
      </Stack>
    );
  },
);

RepresentantInformationsForm.displayName = 'RepresentantInformationsForm';

export type RepresentantInformationsFormProps = Props;
