import { Card, CardBody, Stack } from '@chakra-ui/react';
import { SortingState, Updater } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import {
  GetAllIndividualIdentitiesDocument,
  GetAllIndividualIdentitiesQuery,
  GetAllIndividualIdentitiesQueryVariables,
  IndividualIdentitiesFilterInput,
  IndividualIdentityFilterType,
  IndividualIdentityListInfosFragment,
  OrderBy,
  OrderByDirection,
} from '@blockpulse3/graphql/hooks';
import { usePagination } from '@blockpulse3/ui/ui-hooks';

import { AdminIndividualsControls } from './AdminIndividualsControls';
import { AdminIndividualsTable } from './AdminIndividualsTable';

type Props = {
  /* ** Count of subscriptions by page ** */
  pageSize?: number;
};

/**
 * AdminIndividualsList.
 * List all the individuals in the system.
 *
 * @returns {JSX.Element}
 */
export function AdminIndividualsList({ pageSize = 10 }: Props): JSX.Element {
  /* ** All filters ** */
  const [filters, setFilters] = useState<IndividualIdentitiesFilterInput[]>([]);
  const [sorting, setSorting] = useState<SortingState>([{ id: 'lastName', desc: false }]);

  // For now only handle unique column sorting
  const orderBy: OrderBy | undefined = useMemo(() => {
    if (!sorting.length) return undefined;
    return {
      direction: sorting[0].desc ? OrderByDirection.DESC : OrderByDirection.ASC,
      field: sorting[0].id,
    };
  }, [sorting]);

  const paginationProps = usePagination<
    GetAllIndividualIdentitiesQuery,
    GetAllIndividualIdentitiesQueryVariables,
    IndividualIdentityListInfosFragment
  >({
    queryDocument: GetAllIndividualIdentitiesDocument,
    queryOptions: {
      variables: {
        filterBy: filters,
        first: pageSize,
        orderBy,
      },
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'individualIdentities',
    pageSize,
  });

  const { totalCount, refetch, reset } = paginationProps;

  const handleSearchInputSubmit = (value: string): void => {
    const isInputAndFilterSearchEmpty =
      value === '' && !filters.some((filter) => filter.name === 'searchValue');

    if (isInputAndFilterSearchEmpty) return;

    resetSpecificFilter('searchValue', IndividualIdentityFilterType.CUSTOM, value);
  };

  const resetSpecificFilter = (
    filterName: string,
    filterType: IndividualIdentityFilterType,
    value: string,
  ): void => {
    const currentFilterWithoutFilterName = filters.filter((filter) => filter.name !== filterName);
    const newFilter: IndividualIdentitiesFilterInput = {
      name: filterName,
      type: filterType,
      value,
    };
    reset();
    setFilters([...currentFilterWithoutFilterName, newFilter]);
  };

  const handleSortingChange = (sortFn: Updater<SortingState>): void => {
    setSorting(sortFn);
    refetch();
  };

  return (
    <Card>
      <CardBody>
        <Stack spacing="4">
          <AdminIndividualsControls
            filters={filters}
            totalCount={totalCount}
            onSearchInputSubmit={handleSearchInputSubmit}
          />
          <AdminIndividualsTable
            sorting={sorting}
            onSortingChange={handleSortingChange}
            {...paginationProps}
          />
        </Stack>
      </CardBody>
    </Card>
  );
}
