import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerProps,
  Stack,
} from '@chakra-ui/react';

import { MovementPanelIdentityCard } from './MovementPanelIdentityCard';
import { MovementPanelMovementDetail } from './MovementPanelMovementDetail';
import { MovementPanelOperation } from './MovementPanelOperation';

type Props = Omit<DrawerProps, 'children'>;

/**
 * MovementPanel.
 * Side panel of the rmt table view. Visibility is triggered by
 *
 * `useDisclosureLink()` in Main shareholding view (RMT).
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function MovementPanel({ ...props }: Props): JSX.Element {
  return (
    <Drawer size="sm" {...props}>
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody pb="16" pt="4" px="0">
          <Stack spacing="4">
            <MovementPanelIdentityCard />
            <Divider />
            <MovementPanelMovementDetail />
            <Divider />
            <MovementPanelOperation />
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export type MovementPanelProps = Props;
