import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { SortingState, Updater } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { generatePath, resolvePath, useLocation, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { SubscriptionFilterType, SubscriptionsTabsStatus, routes } from '@blockpulse3/data/shared';
import {
  GetSubscriptionsDocument,
  GetSubscriptionsQuery,
  GetSubscriptionsQueryVariables,
  OperationStatus,
  OrderBy,
  OrderByDirection,
  SubscriptionInfosFragment,
  SubscriptionShortInfosFragment,
  SubscriptionsFilterInput,
  useGetOperationQuery,
  useGetSubscriptionsCountQuery,
} from '@blockpulse3/graphql/hooks';
import { useDisclosureLink, usePagination } from '@blockpulse3/ui/ui-hooks';
import {
  OperationRemindersModal,
  OperationSubscribersType,
  useOperationFilterContext,
} from '@blockpulse3/web-client/operation/commons';

import { OptionPoolAttributionsControls } from './OptionPoolAttributionsControls';
import { OptionPoolAttributionsTable } from './OptionPoolAttributionsTable';
import { getTabsWithCount } from './utils';

const PAGE_SIZE = 10;

type Props = {
  /* ** Attribution panel disclosure ** */
  attributionPanel: ReturnType<typeof useDisclosureLink>;
  /* ** Count of subscriptions by page ** */
  pageSize?: number;
};

/**
 * OptionPoolAttributionsList.
 * Display title and filters of option pool attributions table.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OptionPoolAttributionsList({
  attributionPanel,
  pageSize = PAGE_SIZE,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nSubscriptionsTabsStatus = useTranslations('SubscriptionsTabsStatusValues');

  const { operationId = '' } = useParams();

  const location = useLocation();

  const reminderModal = useDisclosure();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const { filters, setFilters } = useOperationFilterContext();
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data: operationData } = useGetOperationQuery({
    variables: { operationId },
  });

  const subscriptionsCountsReq = useGetSubscriptionsCountQuery({
    variables: { operationId },
    fetchPolicy: 'cache-and-network',
  });

  // For now only handle unique column sorting
  const orderBy: OrderBy | undefined = useMemo(() => {
    if (!sorting.length) return undefined;
    return {
      direction: sorting[0].desc ? OrderByDirection.DESC : OrderByDirection.ASC,
      field: sorting[0].id,
    };
  }, [sorting]);

  const paginationProps = usePagination<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables,
    SubscriptionShortInfosFragment
  >({
    queryDocument: GetSubscriptionsDocument,
    queryOptions: {
      variables: {
        operationId,
        filterBy: filters,
        first: pageSize,
        orderBy,
      },
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'subscriptions',
    pageSize,
  });

  const { totalCount, refetch, reset } = paginationProps;

  let filteredTotalInvestAmount = 0;
  paginationProps.results.forEach((res) => (filteredTotalInvestAmount += res.investAmount));

  const operation = operationData?.operation;

  const subscriptionsCounts = subscriptionsCountsReq.data?.getSubscriptionsCount;
  const tabs = getTabsWithCount(subscriptionsCounts);
  const allowedFiscalAdvantages = operation?.allowedFiscalAdvantages || [];

  /* ** Refetch subscriptions() on filter modal submit ** */
  const handleFiltersSubmit = (values: SubscriptionsFilterInput[]): void => {
    const searchValueFilter = filters.filter((filter) =>
      ['searchValue', 'investStatus'].includes(filter.name),
    );
    reset();
    setFilters([...values, ...searchValueFilter]);
  };

  const handleSearchInputSubmit = (value: string): void => {
    const isInputAndFilterSearchEmpty =
      value === '' && !filters.some((filter) => filter.name === 'searchValue');

    if (isInputAndFilterSearchEmpty) return;

    resetSpecificFilter('searchValue', SubscriptionFilterType.CUSTOM, value);
  };

  const resetSpecificFilter = (
    filterName: string,
    filterType: SubscriptionFilterType,
    value: string,
  ): void => {
    const currentFilterWithoutFilterName = filters.filter((filter) => filter.name !== filterName);
    const newFilter: SubscriptionsFilterInput = {
      name: filterName,
      type: filterType,
      value,
    };
    reset();
    setFilters([...currentFilterWithoutFilterName, newFilter]);
  };

  const handleTabsChange = (index: number): void => {
    setTabIndex(index);

    const newFilters: SubscriptionsFilterInput[] = [];
    if (tabs[index].name !== SubscriptionsTabsStatus.ALL_STATUS) {
      newFilters.push({
        name: 'investStatus',
        type: SubscriptionFilterType.STATUS,
        value: tabs[index].name,
      });
    }

    if (
      [SubscriptionsTabsStatus.PENDING, SubscriptionsTabsStatus.ALL_STATUS].includes(
        tabs[index].name,
      )
    ) {
      const resetFilterStatus = filters.filter((filter) => filter.name !== 'investStatus');
      setFilters([...newFilters, ...resetFilterStatus]);
    } else {
      const resetFilterStatus = filters.filter((filter) => filter.name === 'searchValue');
      setFilters([...newFilters, ...resetFilterStatus]);
    }
    refetch();
  };

  const handleSortingChange = (sortFn: Updater<SortingState>): void => {
    setSorting(sortFn);
    refetch();
  };

  const handleRowClick = (subscriptionId: SubscriptionInfosFragment['id']): void => {
    attributionPanel.onOpen(
      resolvePath(
        generatePath(routes.company.fundraising.subscription.href, { subscriptionId }),
        location.pathname,
      ).pathname,
    );
  };

  return (
    <Card>
      <CardHeader
        alignItems={{ base: 'flex-start', md: 'center' }}
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing="4"
      >
        <Heading size="md">{t('AttributionList')}</Heading>
      </CardHeader>
      <CardBody>
        <Tabs defaultIndex={0} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((tab, i) => (
              <Tab key={i}>
                <Text>
                  {tab.name === SubscriptionsTabsStatus.NOT_SIGNED_UP &&
                  [OperationStatus.DRAFT, OperationStatus.VALIDATED].includes(
                    operation?.status || OperationStatus.DRAFT,
                  )
                    ? i18nSubscriptionsTabsStatus(`${tab.name}_PENDING`)
                    : i18nSubscriptionsTabsStatus(tab.name)}
                </Text>
                <Badge ml="2">{tab.count}</Badge>
              </Tab>
            ))}
          </TabList>
          <TabPanels pt="4">
            <Stack spacing="4">
              <OptionPoolAttributionsControls
                activeTabIndex={tabIndex}
                filters={filters}
                tabs={tabs}
                onFiltersChange={handleFiltersSubmit}
                onSearchInputSubmit={handleSearchInputSubmit}
              />
              <OptionPoolAttributionsTable
                allowedFiscalAdvantages={allowedFiscalAdvantages}
                operationStatus={operation?.status}
                sorting={sorting}
                tabName={tabs[tabIndex].name}
                onRowClick={handleRowClick}
                onSortingChange={handleSortingChange}
                {...paginationProps}
              />
            </Stack>
          </TabPanels>
        </Tabs>
      </CardBody>
      <OperationRemindersModal
        filters={filters}
        isOpen={reminderModal.isOpen}
        operationId={operationId}
        subscribersType={OperationSubscribersType.SUBSCRIBERS}
        totalCount={totalCount}
        onClose={reminderModal.onClose}
      />
    </Card>
  );
}
