import {
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import {
  DocumentStatus,
  OperationDocumentType,
  useGetOperationLazyQuery,
} from '@blockpulse3/graphql/hooks';
import { isDocumentTypePVExercice } from '@blockpulse3/helpers';
import {
  DropzoneInput,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

type Props = {
  documentType: OperationDocumentType;
} & Omit<ResponsiveModalProps, 'children'>;

export function UploadSignedPVModal({ documentType, onClose, ...props }: Props): JSX.Element {
  const t = useTranslations();
  const i18nError = useTranslations('FormErrors');

  const { operationId = '' } = useParams();

  const [error, setError] = useState<string | undefined>();
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [documentFile, setDocumentFile] = useState<File>();
  const [documentFileName, setDocumentFileName] = useState<{ [key: string]: File }>({});

  const successToast = useSuccessToast();

  const [getOperation] = useGetOperationLazyQuery();

  const onCloseModal = (): void => {
    onClose();
  };

  /* ** Method to upload a file using axios ** */
  const handleFileUpload = async (): Promise<void> => {
    if (!operationId || !documentFile) return;

    const formData = new FormData();
    formData.append('operationId', operationId);
    formData.append('document', documentFile);
    formData.append('documentType', documentType);
    formData.append('status', DocumentStatus.SIGNED);

    setIsFileLoading(true);
    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/uploadOperationDocument',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(() => {
        successToast({ title: t('SuccessfulFileUpload') });
        getOperation({
          variables: {
            operationId,
          },
          fetchPolicy: 'network-only',
        });
      })
      .catch(() => {
        setError(i18nError(FormErrors.DropzoneDefault));
        setIsFileLoading(false);
      });
    setIsFileLoading(false);
    onClose();
  };

  /* ** Upload file handler ** */
  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    if (fileRejections.length === 0) {
      setDocumentFileName({
        1: new File([acceptedFiles[0].name], acceptedFiles[0].name),
      });
      setDocumentFile(acceptedFiles[0]);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError(i18nError(FormErrors.FileTooLarge));
          break;
        }
        default: {
          setError(i18nError(FormErrors.DropzoneDefault));
          break;
        }
      }
    }
  };

  const handleFileDelete = (): void => {
    setDocumentFileName({});
    setDocumentFile(undefined);
  };

  const handleSubmit = (): void => {
    handleFileUpload();
  };

  return (
    <ResponsiveModal onClose={onCloseModal} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t(
            isDocumentTypePVExercice(documentType)
              ? 'UploadSignedPVExercice'
              : 'UploadSignedPVConstatation',
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} spacing="4">
          <Stack>
            <DropzoneInput
              accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
              files={documentFileName}
              isDisabled={isFileLoading}
              isLoading={isFileLoading}
              maxFiles={1}
              subTitle={t('ImageUpTo5MB')}
              onDelete={handleFileDelete}
              onDrop={handleFileDrop}
            />
            {error && (
              <Text color="red" fontSize="xs">
                {error}
              </Text>
            )}
          </Stack>
        </ModalBody>{' '}
        <Divider />
        <ResponsiveModalFooter>
          <Button onClick={handleSubmit}>{t('Validate')}</Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
