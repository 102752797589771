import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  IbanValidityOutput,
  useUpdateIdentityDistributionIbanMutation,
  useValidateIbanLazyQuery,
  useValidateIbanQuery,
} from '@blockpulse3/graphql/hooks';
import { formatIBAN } from '@blockpulse3/helpers';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { BankDetailsCard } from '../BankDetailsCard';
import { ErrorMessage } from '../ErrorMessage';
import { Input } from '../Input';
import { ResponsiveModal, ResponsiveModalProps } from '../ResponsiveModal';
import { ResponsiveModalFooter } from '../ResponsiveModalFooter';
import { useErrorToast, useSuccessToast } from '../Toast';
import { schema } from './schema';
import { IUpdateIBANForm } from './types';

type Props = {
  onComplete?: () => void;
  defaultIban?: string | null;
} & Omit<ResponsiveModalProps, 'children'>;

export function UpdateIBANFormModal({
  onComplete = noop,
  defaultIban,
  ...props
}: Props): JSX.Element {
  const { onClose } = props;

  const t = useTranslations();

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [ibanInfo, setIbanInfo] = useState<IbanValidityOutput>();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { identity, refetch } = useIdentity();
  const { subscriptionId } = useParams();

  const { data: defaultIbanInfoData } = useValidateIbanQuery({
    variables: { iban: defaultIban || '' },
    skip: !defaultIban,
  });
  const [validateIban, { loading: validateLoading }] = useValidateIbanLazyQuery();
  const [updateDistributionIban, { loading: updateLoading }] =
    useUpdateIdentityDistributionIbanMutation();

  const { formState, handleSubmit, register, reset } = useForm<IUpdateIBANForm>({
    defaultValues: { iban: defaultIban || '' },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({ iban: defaultIban ? formatIBAN(defaultIban) : '' });
  }, [defaultIban, reset]);

  const defaultIbanInfo = defaultIbanInfoData?.validateIban;
  useEffect(() => {
    setIbanInfo(defaultIbanInfo);
  }, [defaultIbanInfo]);

  const handleUpdateIban = async (iban: string): Promise<void> => {
    if (!identity) return;

    await updateDistributionIban({
      variables: {
        updateIdentityDistributionIbanInput: {
          identityId: identity.id,
          iban,
          subscriptionId,
        },
      },
      onCompleted: () => {
        successToast({ title: t('IBANUpdated') });
        refetch();
        onComplete();
        onClose();
      },
      onError: () => {
        errorToast({ title: t('IBANUpdateError') });
      },
    });
  };

  const handleFormSubmit = async (input: IUpdateIBANForm): Promise<void> => {
    const iban = input.iban.replace(/\s/g, '');
    if (!ibanInfo) {
      reset({ iban: formatIBAN(iban) });
      await validateIban({
        variables: { iban },
        onCompleted: (data) => setIbanInfo(data.validateIban),
        onError: () => setDisplayError(true),
      });
    } else {
      handleUpdateIban(iban);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIbanInfo(undefined);
    setDisplayError(false);
  };

  const handleClose = (): void => {
    setIbanInfo(undefined);
    setDisplayError(false);
    reset();
    onClose();
  };

  const bankName = ibanInfo?.bankName || '';
  const branch = ibanInfo?.branch || '';
  const country = ibanInfo?.country || '';
  const bic = ibanInfo?.bic || '';

  const getBankAddress = (info: IbanValidityOutput | undefined): string | undefined => {
    if (!info) return undefined;

    return info.line && info.city && info.country
      ? `${info.line}, ${info.postalCode || ''} ${info.city}, ${info.country}`
      : '';
  };

  const bankAddress = getBankAddress(ibanInfo) || '';

  return (
    <ResponsiveModal {...props} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('IBANReceiptFunds')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="iban-form" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <FormControl isInvalid={!!formState.errors.iban}>
                <FormLabel htmlFor="iban">{t('EnterYourIBAN')}</FormLabel>
                <Input
                  id="iban"
                  type="text"
                  {...register('iban', {
                    onChange: handleInputChange,
                  })}
                />
                <ErrorMessage error={formState.errors.iban} />
              </FormControl>
              {ibanInfo && (
                <Stack>
                  <FormLabel>{t('VerifyInformationsFunded')}</FormLabel>
                  <BankDetailsCard
                    bankAddress={bankAddress}
                    bankName={bankName}
                    branch={branch}
                    country={country}
                    swift={bic}
                  />
                </Stack>
              )}
              {displayError && (
                <Alert status="error">
                  <AlertIcon />
                  <Stack>
                    <AlertTitle>{t('ErrorIBAN')}</AlertTitle>
                    <AlertDescription>{t('VerifyIBAN')}</AlertDescription>
                  </Stack>
                </Alert>
              )}
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button form="iban-form" isLoading={validateLoading || updateLoading} type="submit">
            {ibanInfo ? t('Save') : t('Check')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type UpdateIBANFormModalProps = Props;
