import { Stack } from '@chakra-ui/react';

import { noop } from '@blockpulse3/data/shared';

import { NaturalCompanyAccessForm } from './NaturalCompanyAccessForm';
import { naturalAccessFormDefaults } from './schema';
import { INaturalCompanyAccessForm } from './types';

type Props = {
  isDisabled?: boolean;
  isEditing?: boolean;
  /* ** Default form values for the Legal and Natural form version ** */
  defaultValues?: INaturalCompanyAccessForm;
  /* ** Should activate 'Delete' button ** */
  shouldCancel?: boolean;
  /* ** Callback of the Cancel button ** */
  onCancel?: (id: INaturalCompanyAccessForm['id']) => void;
  /* ** Callback of the Submit button ** */
  onSubmit?: (data: INaturalCompanyAccessForm) => void;
};

export function CompanyAccessForm({
  isDisabled = false,
  isEditing = false,
  defaultValues = naturalAccessFormDefaults,
  shouldCancel = true,
  onCancel = noop,
  onSubmit = noop,
}: Props): JSX.Element {
  /* ** Submit whatever other role form is being rendered ** */
  const handleSubmit = (data: INaturalCompanyAccessForm): void => {
    onSubmit(data);
  };

  /* ** Cancel whatever other role form is being rendered ** */
  const handleCancel = (): void => {
    onCancel(defaultValues.id);
  };

  return (
    <Stack spacing="4">
      <NaturalCompanyAccessForm
        defaultValues={defaultValues}
        isDisabled={isDisabled}
        isEditing={isEditing}
        shouldCancel={shouldCancel}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </Stack>
  );
}

CompanyAccessForm.displayName = 'CompanyAccessForm';

export type CompanyAccessFormProps = Props;
