import {
  Box,
  Progress as ChakraProgress,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { Outlet, generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { FundraisingType, useDeleteOperationMutation } from '@blockpulse3/graphql/hooks';
import {
  DeleteDraftConfirmModal,
  Progress,
  ProgressStep,
  STEP_HEADER_HEIGHT_BASE,
  STEP_HEADER_HEIGHT_MD,
  StepHeader,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { useFundraisingSteps } from '../commons';

type Props = unknown;

/**
 * NewPrivateFundraisingLayout.
 * Layout wrapping private fundraising workflow.
 *
 * @returns {JSX.Element}
 */
export function NewPrivateFundraisingLayout(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const top = useBreakpointValue({ base: STEP_HEADER_HEIGHT_BASE, md: STEP_HEADER_HEIGHT_MD });

  const navigate = useNavigate();
  const { operationId = '', companyId = '' } = useParams();

  const draftConfirmRef = useRef(null);
  const draftConfirmModal = useDisclosure();

  const fundraisingRoutes = routes.company.newFundraising.private;

  const { progress, activeSteps } = useFundraisingSteps(fundraisingRoutes, FundraisingType.PRIVATE);

  const [deleteOperation] = useDeleteOperationMutation();

  const isCreateActive = useMatch(fundraisingRoutes.full);

  const handleCloseNewFundraising = (): void => {
    navigate(generatePath(routes.company.href, { companyId }));
  };

  const handleFundraisingDelete = (): void => {
    if (operationId) {
      deleteOperation({
        variables: {
          operationId,
        },
        onCompleted: (res) => {
          if (res.deleteOperation) {
            navigate(generatePath(routes.company.href, { companyId }));
            successToast({ title: t('SuccessfullyDeletedFundraising') });
          } else {
            errorToast({ title: t('ErrorDeletingFundraising') });
          }
        },
        onError: () => {
          errorToast({ title: t('ErrorDeletingFundraising') });
        },
      });
    }
  };

  return (
    <Flex direction="column" minH="100vh">
      <StepHeader
        p="4"
        showDelete={!isCreateActive}
        onClose={handleCloseNewFundraising}
        onDelete={draftConfirmModal.onOpen}
      >
        <Heading size="lg">{t('ConfigureCapitalRaise')}</Heading>
        <Text color="gray.600" fontWeight="normal">
          {t('EditDocumentInfo')}
        </Text>
      </StepHeader>
      <ChakraProgress
        height={1}
        max={activeSteps.length - 1}
        position="sticky"
        top={top}
        value={progress}
        zIndex={10}
        sx={{
          '&>div': {
            transition: 'width .7s ease',
          },
        }}
      />
      <Box bg="gray.50" flexGrow="1" h="full">
        <Stack direction={{ base: 'column', lg: 'row' }} p="6" spacing={{ base: 4, lg: 0 }}>
          <Flex flexBasis={{ base: 0, lg: 200 }} />
          <Progress>
            {activeSteps.map((step, i) => (
              <ProgressStep
                key={step.label}
                index={i}
                isActive={!!step.value}
                isCompleted={progress > i}
                title={step.label}
              />
            ))}
          </Progress>
          <Flex flexGrow="1" maxW="3xl">
            <Outlet />
          </Flex>
        </Stack>
      </Box>
      <DeleteDraftConfirmModal
        isOpen={draftConfirmModal.isOpen}
        leastDestructiveRef={draftConfirmRef}
        onClose={draftConfirmModal.onClose}
        onDelete={handleFundraisingDelete}
      />
    </Flex>
  );
}

export type NewPrivateFundraisingLayoutProps = Props;
