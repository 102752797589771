import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CashIcon, CollectionIcon, InboxInIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberInt } from '@blockpulse3/helpers';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

/**
 * OpportunityOperationInformations.
 * Computed information section of the opportunity operation.
 *
 * @returns {JSX.Element}
 */
export function OpportunityOperationInformations(): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const { data, loading, error } = useGetOperationQuery({
    variables: { operationId },
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="260px" />
      </Skeleton>
    );
  }

  if (!data || error) {
    return <ErrorQueryCard h="260px" />;
  }

  const { operation } = data;
  if (operation.data?.__typename !== 'OpportunityData') {
    return <ErrorQueryCard h="260px" />;
  }

  const {
    totalApprovedAmount,
    totalCollectedFees,
    totalApprovedSubscriptions,
    totalApprovedOrdinaryShares,
  } = operation.data;

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '8' }}>
      <Card w="full">
        <CardHeader>
          <HStack spacing="4" justifyContent="space-between">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('ExpressedIntents')}
            </Heading>
            <Icon as={InboxInIcon} color="gray.500" boxSize="5" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberInt(totalApprovedSubscriptions)}
          </Text>
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader>
          <HStack spacing="4" justifyContent="space-between">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('CountOfSharesToPurchase')}
            </Heading>
            <Icon as={CollectionIcon} color="gray.500" boxSize="5" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberInt(totalApprovedOrdinaryShares || 0)}
          </Text>
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader>
          <HStack spacing="4" justifyContent="space-between">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('TotalAmountOfIntents')}
            </Heading>
            <Icon as={CashIcon} color="gray.500" boxSize="5" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberCurrency(totalApprovedAmount + totalCollectedFees)}
          </Text>
          {!!totalCollectedFees && (
            <Text color="gray.600" fontSize="sm">
              {t('IncludingFees', { fees: formatNumberCurrency(totalCollectedFees) })}
            </Text>
          )}
        </CardBody>
      </Card>
    </Stack>
  );
}
