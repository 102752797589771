import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import { useFinalizeSwanAuthLazyQuery } from '@blockpulse3/graphql/hooks';

type Props = unknown;

/**
 * UserStateRedirects.
 * Simple wrapper to handle state redirects (mainly swan redirectUrl) from the default home page
 * to the decoded base64 URL.
 *
 * @returns {JSX.Element}
 */
export function UserStateRedirects(): JSX.Element | null {
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');
  const code = searchParams.get('code');

  const navigate = useNavigate();
  const [finalizeSwanAuth] = useFinalizeSwanAuthLazyQuery();

  const decodedState = state ? atob(state) : '';
  let redirectTo = decodedState;
  let identityId: string | undefined;
  if (decodedState.startsWith('{')) {
    ({ redirectTo, identityId } = JSON.parse(decodedState));
  }

  useEffect(() => {
    if (code && identityId) {
      finalizeSwanAuth({
        variables: {
          code,
          identityId,
        },
        onCompleted: (data) => {
          navigate(redirectTo);
        },
      });
    }
  }, [code, identityId, navigate, redirectTo, finalizeSwanAuth]);

  if (code && identityId) return null;

  return redirectTo ? <Navigate replace to={redirectTo} /> : <Outlet />;
}

export type UserStateRedirectsProps = Props;
