import { Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { SpaceHeader } from '../SpaceHeader';
import { SpaceList } from './SpaceList';

type Props = unknown;

/**
 * AdminSpaces.
 *
 * @returns {JSX.Element}
 */
export function AdminSpaces(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack height="full" spacing="6">
      <Stack spacing="4">
        <SpaceHeader />
        <Heading size="lg">{t('Space', { nb: 2 })}</Heading>
      </Stack>
      <SpaceList />
    </Stack>
  );
}

export type AdminSpacesProps = Props;
