import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useGetCompanyQuery } from '@blockpulse3/graphql/hooks';

export function ResumeOperationRepatriationAlert(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const navigate = useNavigate();

  const { data } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const currentRepatriation = data?.company?.currentRepatriation;

  const handleRepatriateOperation = (): void => {
    if (!currentRepatriation) return;

    navigate(
      generatePath(routes.company.newOperation.repatriation.edit.full, {
        companyId,
        operationId: currentRepatriation,
      }),
    );
  };

  return (
    <Alert boxShadow="md" status="info">
      <Stack
        alignItems="stretch"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing="4"
        width="full"
      >
        <HStack alignItems="flex-start" spacing="0">
          <AlertIcon />
          <Stack spacing="0">
            <AlertTitle fontWeight="bold">{t('ResumeRepatriationInfo')}</AlertTitle>
            <AlertDescription>{t('ResumeRepatriationInfoDescription')}</AlertDescription>
          </Stack>
        </HStack>
        <HStack alignItems="center" ml={{ base: '0', md: '4' }}>
          <Button className="confirm" fontSize="sm" onClick={handleRepatriateOperation}>
            {t('ResumeRepatriation')}
          </Button>
        </HStack>
      </Stack>
    </Alert>
  );
}
