import { Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { CreditCardIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlHoldingMethodTypeValues } from '@blockpulse3/data/locales/types';
import {
  MergedPurchaseIntentStep,
  SubscriptionStepStatus,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionStep } from '../../SubscriptionStep';
import { PurchaseIntentModal } from './PurchaseIntentModal';

type Props = {
  subscriptionStep: MergedPurchaseIntentStep;
  isDisabled: boolean;
};

export function PurchaseIntentStep({ subscriptionStep, isDisabled }: Props): JSX.Element {
  const t = useTranslations();
  const i18nHoldingMethodType = useTranslations('HoldingMethodTypeValues');

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = data?.subscription;
  const { investAmount, feesAmount, holdingMethod, operation } = subscription || {};
  const isInvestAmount = !!investAmount && investAmount !== 0;

  const proofOfFundsThreshold = operation?.proofOfFundsThreshold;
  const needsFundsSourceCertificate =
    !!proofOfFundsThreshold &&
    !!investAmount &&
    investAmount >= proofOfFundsThreshold &&
    subscriptionStep.status !== SubscriptionStepStatus.VALID;

  return (
    <>
      <SubscriptionStep
        icon={CreditCardIcon}
        isDisabled={isDisabled}
        status={subscriptionStep.status}
        title={t('PurchaseIntent')}
        type={subscriptionStep.type}
        onClick={onOpen}
      >
        {(isInvestAmount || needsFundsSourceCertificate) && (
          <>
            {isInvestAmount && (
              <Text fontSize="sm">
                {t.rich('SubscribeForAmountAndMethod', {
                  investAmount: formatNumberCurrency(investAmount),
                  holdingMethod: i18nHoldingMethodType(
                    holdingMethod as IntlHoldingMethodTypeValues,
                  ),
                  amount: (chunks) =>
                    feesAmount ? (
                      <Tooltip
                        hasArrow
                        label={t('PlusFeesAmount', { fees: formatNumberCurrency(feesAmount) })}
                        placement="top"
                        textAlign="center"
                      >
                        <Text as="b" textDecoration="underline">
                          {chunks}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text as="b">{chunks}</Text>
                    ),
                  important: (chunks) => <Text as="b">{chunks}</Text>,
                })}
              </Text>
            )}
            {needsFundsSourceCertificate && (
              <Text color="yellow.700" fontSize="sm">
                {t('MissingOriginCertificate')}
              </Text>
            )}
          </>
        )}
      </SubscriptionStep>
      <PurchaseIntentModal isOpen={isOpen} subscriptionStep={subscriptionStep} onClose={onClose} />
    </>
  );
}
