import { BorderProps, ColorProps } from '@chakra-ui/react';

import { getHostName } from '../getHostName';
import * as brandConfigs from './config';

const DEFAULT_BRAND = 'blockpulse';

export interface BrandConfig {
  slug: string;
  name: string;
  hostnames: {
    local: string;
    dev: string;
    preprod: string;
    prod: string;
  };
  signaturit: {
    local: string;
    dev: string;
    preprod: string;
    prod: string;
  };
  mail: string;
  phone: {
    href: string;
    label: string;
    extraInfo?: string;
  };
  logo: string;
  zendeskSnippetSrc?: string;
  socialNetworks: {
    linkedin: string;
    twitter: string;
    instagram: string;
  };
  theme: {
    brandName: string;
    color: {
      primary: ColorProps['color'];
      secondary: ColorProps['color'];
    };
    borderRadius: {
      button: BorderProps['rounded'];
      card: BorderProps['rounded'];
    };
  };
  fundTransferAlert?: string;
  features?: Record<string, boolean>;
}

export type BrandName = keyof typeof brandConfigs;
export type SupportedEnv = 'local' | 'dev' | 'preprod' | 'prod';

export const getBrand = (hostname: string): BrandName => {
  const currentBrandConfig = Object.values(brandConfigs).find((cfg) =>
    Object.values(cfg.hostnames).includes(hostname),
  );
  if (currentBrandConfig) return currentBrandConfig.slug as BrandName;
  return DEFAULT_BRAND;
};

export const getBrandConfig = (slug: BrandName): BrandConfig => {
  if (brandConfigs[slug]) return brandConfigs[slug];
  return brandConfigs[DEFAULT_BRAND];
};

export const getBrandAppUrl = (slug: BrandName, env: SupportedEnv): string => {
  const brandConfig = getBrandConfig(slug);
  const protocol = env === 'local' ? 'http' : 'https';
  const hostname = brandConfig.hostnames[env];
  return `${protocol}://${hostname}`;
};

export const getSupportedHostnames = (env: SupportedEnv): string[] => {
  const hostnames: string[] = [];
  const protocol = env === 'local' ? 'http' : 'https';
  Object.values(brandConfigs).map((cfg) => hostnames.push(`${protocol}://${cfg.hostnames[env]}`));
  return hostnames;
};

export const getBrandSignaturitId = (slug: BrandName, env: SupportedEnv): string => {
  const brandConfig = getBrandConfig(slug);
  return brandConfig.signaturit[env];
};

export const isFeatureEnabled = (featureName: string): boolean => {
  const brandConfig = getBrandConfig(getBrand(getHostName()));
  return !!brandConfig.features?.[featureName];
};
