import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

import { StepFormContextProvider } from '../../provider';
import { OnboardingStep } from '../OnboardingStep';
import { ExistingCompanyParameters } from '../forms';

/**
 * OnboardingExistingCompanyParameters.
 * Existing company informations, update step.
 *
 * @returns {JSX.Element}
 */
export function OnboardingExistingCompanyParameters(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const handleStepSubmit = (): void => {
    navigate(routes.onboardings.company.edit.representatives.href);
  };

  return (
    <StepFormContextProvider>
      <OnboardingStep subTitle={t('AllTheseInfoInCompanyBylaws')} title={t('GeneralInformation')}>
        <ExistingCompanyParameters onSubmit={handleStepSubmit} />
        <Button form="company-informations" type="submit" w="full">
          {t('Validate')}
        </Button>
      </OnboardingStep>
    </StepFormContextProvider>
  );
}
