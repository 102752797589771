import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { XCircleIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useGetOperationQuery,
  useUpdateOperationVideoLinkMutation,
} from '@blockpulse3/graphql/hooks';

import { Input } from '../Input';
import { useSuccessToast } from '../Toast';
import { VideoLinkModal } from '../VideoLinkModal';

export function TabPanelOperationVideoLink(): JSX.Element {
  const t = useTranslations();
  const [value, setValue] = useState<string | undefined>();

  const { operationId = '' } = useParams();

  const successToast = useSuccessToast();

  const [updateOperationVideoLink, { loading: updating }] = useUpdateOperationVideoLinkMutation();

  const getOperationReq = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
    onCompleted: (data) => {
      setValue(data.operation?.videoLink || '');
    },
  });

  const operationVideoLink = getOperationReq.data?.operation?.videoLink || '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const handleInputReset = (): void => {
    setValue('');
  };

  const handleSubmit = (): void => {
    updateOperationVideoLink({
      variables: {
        updateOperationVideoLinkInput: {
          operationId,
          videoLink: value || null,
        },
      },
      onCompleted: () => {
        getOperationReq.refetch();
        successToast({ title: t('LinkUpdatedSuccessfully') });
      },
    });
  };

  return (
    <Card>
      <CardHeader>
        <Heading size="md">{t('PresentationVideo')}</Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing="4">
          {operationVideoLink && <VideoLinkModal videoLink={operationVideoLink} />}
          <HStack spacing="4">
            <InputGroup size="sm" width={{ base: 'full', md: '500px' }}>
              <Input placeholder={t('AddVideoLink')} value={value} onChange={handleChange} />
              {!!value && (
                <InputRightElement>
                  <IconButton
                    aria-label="reset"
                    icon={<Icon as={XCircleIcon} />}
                    size="sm"
                    variant="ghost"
                    onClick={handleInputReset}
                  />
                </InputRightElement>
              )}
            </InputGroup>
            <Button
              isDisabled={operationVideoLink === value}
              isLoading={updating}
              size="sm"
              onClick={handleSubmit}
            >
              {t('UpdateLink')}
            </Button>
          </HStack>
        </Stack>
      </CardBody>
    </Card>
  );
}
