import { Icon, IconProps } from '@chakra-ui/react';

export const WithdrawalIcon = (props: IconProps): JSX.Element => (
  <Icon width="54" height="54" viewBox="0 0 54 54" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.757 10.2686C15.757 8.08739 17.5206 6.31915 19.6962 6.31915H35.453C37.6286 6.31915 39.3922 8.08739 39.3922 10.2686V33.9654C40.48 33.9654 41.3618 34.8495 41.3618 35.9402C41.3618 37.0308 40.48 37.9149 39.3922 37.9149H32.5138C31.9615 37.9149 31.5138 37.4672 31.5138 36.9149V31.9907C31.5138 30.9001 30.632 30.016 29.5442 30.016H25.605C24.5172 30.016 23.6354 30.9001 23.6354 31.9907V36.9149C23.6354 37.4672 23.1877 37.9149 22.6354 37.9149H15.757C14.6692 37.9149 13.7874 37.0308 13.7874 35.9402C13.7874 34.8495 14.6692 33.9654 15.757 33.9654V10.2686ZM21.6658 12.2434H25.605V16.1928H21.6658V12.2434ZM25.605 20.1423H21.6658V24.0918H25.605V20.1423ZM29.5442 12.2434H33.4834V16.1928H29.5442V12.2434ZM33.4834 20.1423H29.5442V24.0918H33.4834V20.1423Z"
      fill="currentColor"
    />
    <path
      d="M22.1152 42.0693L24.8435 39.3636L24.8435 49.9069C24.8435 51.4214 26.0781 52.6277 27.5745 52.6277C29.071 52.6277 30.3055 51.4214 30.3055 49.9069L30.3055 39.3636L33.0339 42.0693C34.0994 43.1259 35.8217 43.1259 36.8872 42.0693C37.9597 41.0057 37.9597 39.2762 36.8872 38.2127L29.5012 30.8882C28.4357 29.8316 26.7134 29.8316 25.6479 30.8882L18.2619 38.2127C17.1894 39.2762 17.1894 41.0057 18.2619 42.0693C19.3274 43.1259 21.0497 43.1259 22.1152 42.0693Z"
      fill="currentColor"
      stroke="#F9FAFB"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
