export type IUseCompanyShares = {
  totalSharesCount: number;
  ordinarySharesCount: number;
  preferredSharesCount: number;
};

export type IUseCompanySharesParams = {
  nominalValue?: number | null;
  shareCapital?: number | null;
  preferredSharesPercentage?: number | null;
};

/**
 * useCompanyShares.
 * Compute count for ordinary (AO) and preferred shares (AP).
 * For later use in Legal and Natural ShareholderInformationsForm
 *
 * @param {IUseCompanySharesParams} params
 * @returns {IUseCompanyShares}
 */
export function useCompanyShares(params: IUseCompanySharesParams): IUseCompanyShares {
  /* ** Handle request and form defaults ** */
  const shareCapital = params?.shareCapital || 1;
  const nominalValue = params?.nominalValue || 1;
  const preferredSharesPercentage = params?.preferredSharesPercentage || 0;

  const totalSharesCount = shareCapital / nominalValue;
  const ordinarySharesCount = Math.round(
    totalSharesCount * ((100 - preferredSharesPercentage) / 100),
  );
  const preferredSharesCount = Math.max(totalSharesCount - ordinarySharesCount, 1);

  return { totalSharesCount, ordinarySharesCount, preferredSharesCount };
}
