import {
  Button,
  Divider,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { ResponsiveModal, ResponsiveModalFooter } from '@blockpulse3/ui/commons';
import { useStepFormContext } from '@blockpulse3/web-client/onboardings';

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: (JSX.Element | null | false)[] | JSX.Element;
};

export function SettingsCompanyEditModal({ title, isOpen, onClose, children }: Props): JSX.Element {
  const t = useTranslations();

  const { handleStepSubmit, handleStepCancel } = useStepFormContext();

  return (
    <ResponsiveModal isOpen={isOpen} size="3xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody as={Stack} spacing="4">
          {children}
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button variant="secondary" onClick={handleStepCancel}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleStepSubmit}>{t('Save')}</Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type SettingsCompanyEditModalProps = Props;
