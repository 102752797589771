import { OperationType } from '@blockpulse3/graphql/hooks';

import { OperationRow } from '../../types';
import { OperationTableFundraisingRow } from './OperationTableFundraisingRow';
import { OperationTableOpportunityRow } from './OperationTableOpportunityRow';
import { OperationTableOptionPoolRow } from './OperationTableOptionPoolRow';
import { OperationTableSecondaryRow } from './OperationTableSecondaryRow';
import { OperationTableUnknownRow } from './OperationTableUnknownRow';

type Props = {
  operation: OperationRow;
};

/**
 * OperationTableRow.
 * Switch table to render different type of Operations, since the table mixes all types.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationTableRow({ operation }: Props): JSX.Element {
  switch (operation.type) {
    case OperationType.SECONDARY:
      return <OperationTableSecondaryRow operation={operation} />;
    case OperationType.FUNDRAISING:
      return <OperationTableFundraisingRow operation={operation} />;
    case OperationType.OPPORTUNITY:
      return <OperationTableOpportunityRow operation={operation} />;
    case OperationType.OPTION_POOL:
      return <OperationTableOptionPoolRow operation={operation} />;
    default:
      return <OperationTableUnknownRow operation={operation} />;
  }
}

export type OperationTableRowsProps = Props;
