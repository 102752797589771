import { useBreakpoint } from '@chakra-ui/react';

export type Breakpoints = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeScreen: boolean;
};

export function useBreakpoints(): Breakpoints {
  const breakpoint = useBreakpoint({ ssr: false });

  return {
    isMobile: breakpoint === 'sm',
    isTablet: breakpoint === 'md',
    isDesktop: breakpoint === 'lg' || breakpoint === 'xl',
    isLargeScreen: breakpoint === '2xl',
  };
}
