import { Box, Center } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

type Props = unknown;

export function CenterLayout(): JSX.Element {
  return (
    <Box bg="gray.50" h="100vh">
      <Center display="flex" flexDir="column" h="full" mx="2">
        <Outlet />
      </Center>
    </Box>
  );
}

export type CenterLayoutProps = Props;
