import * as yup from 'yup';

import { requiredPastDateField, requiredStringField } from '@blockpulse3/data/shared';

export const uploadSpvDocumentSchema = yup.object({
  companyDocumentFile: requiredStringField,
});

export const uploadRegisterExtractSchema = yup.object({
  companyDocumentFile: requiredStringField,
  registrationNumber: requiredStringField,
  registrationEntity: requiredStringField,
  creationDate: requiredPastDateField,
});
