import { OperationDocumentType } from '@blockpulse3/data/shared';

/**
 * isDocumentTypeSubscription.
 *
 * Simple helper to clear conditions. Check if `documentType` is a `SUBSCRIPTION(_BOND)?`.
 *
 * @param {OperationDocumentType | null | undefined} documentType
 * @returns {boolean}
 */
export function isDocumentTypeSubscription(
  documentType: OperationDocumentType | null | undefined,
): boolean {
  if (!documentType) return false;

  return [OperationDocumentType.SUBSCRIPTION, OperationDocumentType.SUBSCRIPTION_BOND].includes(
    documentType,
  );
}

/**
 * isDocumentTypePVExercice.
 *
 * Simple helper to clear conditions. Check if `documentType` is a `PV_EXERCICE(_BOND)?`.
 *
 * @param {OperationDocumentType | null | undefined} documentType
 * @returns {boolean}
 */
export function isDocumentTypePVExercice(
  documentType: OperationDocumentType | null | undefined,
): boolean {
  if (!documentType) return false;

  return [OperationDocumentType.PV_EXERCICE, OperationDocumentType.PV_EXERCICE_BOND].includes(
    documentType,
  );
}

/**
 * isDocumentTypePVConstatation.
 *
 * Simple helper to clear conditions. Check if `documentType` is a `PV_CONSTATATION(_BOND)?`.
 *
 * @param {OperationDocumentType | null | undefined} documentType
 * @returns {boolean}
 */
export function isDocumentTypePVConstatation(
  documentType: OperationDocumentType | null | undefined,
): boolean {
  if (!documentType) return false;

  return [
    OperationDocumentType.PV_CONSTATATION,
    OperationDocumentType.PV_CONSTATATION_BOND,
  ].includes(documentType);
}
