import {
  Badge,
  Button,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ArrowNarrowRightIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import { Space, SpaceEntityInfosFragment } from '@blockpulse3/graphql/hooks';
import { capitalizeFirstLetter } from '@blockpulse3/helpers';
import { ErrorQueryCard, PaginationButtons, TableContainer } from '@blockpulse3/ui/commons';
import { PaginationHandlers } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { spaceTypeIcons } from '../../utils';

type Props = PaginationHandlers<SpaceEntityInfosFragment> & {
  /* ** Callback on update button click ** */
  onRowUpdateClick?: (spaceId: Space['id']) => void;
  /* ** Callback on delete button click ** */
  onRowDeleteClick?: (spaceId: Space['id']) => void;
};

/**
 * SpaceListTable.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SpaceListTable({
  onRowUpdateClick = noop,
  onRowDeleteClick = noop,
  results,
  ...paginationProps
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nSpaceType = useTranslations('SpaceTypes');

  const { spaceId = '' } = useParams();

  const { user: authUser } = useAuthUser();
  const isSuperAdmin = authUser?.isSuperAdmin;

  const navigate = useNavigate();

  if (paginationProps.loading) {
    return (
      <Stack layerStyle="emptyState">
        <Spinner />
      </Stack>
    );
  }

  if (!results) {
    return <ErrorQueryCard />;
  }

  if (results.length === 0) {
    return (
      <Stack layerStyle="emptyState">
        <Text>{t('NoResult')}</Text>
      </Stack>
    );
  }

  const handleRowClick = (id: Space['id']): void => {
    const pathPrefix = spaceId ? '../../' : '../';
    navigate(pathPrefix + generatePath(routes.space.space.href, { spaceId: id }), {
      relative: 'path',
    });
  };

  const handleRowUpdateClick = (id: Space['id']): void => {
    onRowUpdateClick(id);
  };

  const handleRowDeleteClick = (id: Space['id']): void => {
    onRowDeleteClick(id);
  };

  return (
    <Stack>
      <TableContainer maxH="none">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>{t('Name')}</Th>
              {isSuperAdmin && <Th>{t('Brand')}</Th>}
              <Th>{t('Type')}</Th>
              <Th w="1">{t('Action', { nb: 2 })}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {results.map((space) => (
              <Tr key={space.id}>
                <Td>
                  <Button
                    fontWeight="600"
                    minW="0"
                    variant="unstyled"
                    onClick={(): void => handleRowClick(space.id)}
                  >
                    {space.name}
                  </Button>
                </Td>
                {isSuperAdmin && <Td>{capitalizeFirstLetter(space.brand)}</Td>}
                <Td>
                  <Badge colorScheme="blackAlpha">
                    <HStack spacing="1">
                      <Icon as={spaceTypeIcons[space.type]} boxSize="14px" color="gray.500" />
                      <Text>{i18nSpaceType(space.type)}</Text>
                    </HStack>
                  </Badge>
                </Td>
                <Td>
                  <HStack spacing="1">
                    <IconButton
                      aria-label="edit"
                      icon={<Icon as={PencilIcon} boxSize="18px" color="gray.700" />}
                      variant="secondary"
                      onClick={(): void => handleRowUpdateClick(space.id)}
                    />
                    {isSuperAdmin && (
                      <IconButton
                        aria-label="delete"
                        icon={<Icon as={TrashIcon} boxSize="18px" color="gray.700" />}
                        variant="secondary"
                        onClick={(): void => handleRowDeleteClick(space.id)}
                      />
                    )}
                    <Button
                      fontSize="sm"
                      rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                      variant="secondary"
                      onClick={(): void => handleRowClick(space.id)}
                    >
                      {t('ShowDetails')}
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationButtons
        currentPage={paginationProps.currentPage}
        hasNextPage={paginationProps.pageInfo.hasNextPage}
        hasPreviousPage={paginationProps.pageInfo.hasPreviousPage}
        loading={paginationProps.isLoadingMore}
        pageCount={paginationProps.pageCount}
        onNextPage={paginationProps.handleNextPage}
        onPreviousPage={paginationProps.handlePreviousPage}
      />
    </Stack>
  );
}

export type SpaceListTableProps = Props;
