import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

import { StepFormContextProvider } from '../../provider';
import { OnboardingStep } from '../OnboardingStep';
import { CompanyRepresentatives } from '../forms';

/**
 * OnboardingSPVRepresentatives.
 * Step that renders the form for the representant of the spv.
 * It is splitted on purpose, to keep the form agnostic.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OnboardingSPVRepresentatives(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const handleStepSubmit = (): void => {
    navigate('../' + routes.onboardings.spv.edit.shareholders.href);
  };

  const handleStepCancel = (): void => {
    navigate('../' + routes.onboardings.spv.edit.compensation.href);
  };

  return (
    <StepFormContextProvider>
      <OnboardingStep
        subTitle={t('WhoWillRepresentInvestmentVehicle')}
        title={t('LegalRepresentative', { nb: 1 })}
      >
        <CompanyRepresentatives
          hasCreationDate={true}
          hasFiliation={true}
          isPresident={true}
          onCancel={handleStepCancel}
          onSubmit={handleStepSubmit}
        />
        <Button key="cancel" variant="secondary" w="full">
          {t('Back')}
        </Button>
        <Button key="submit" data-cy="next" w="full">
          {t('Next')}
        </Button>
      </OnboardingStep>
    </StepFormContextProvider>
  );
}
