import { useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

import { UseOnboardingCompanySteps } from './types';

/**
 * useOnboardingCompanyLayoutSteps.
 * Build available steps depending on the onboarding company.
 * `activeSteps` looks for the first PathMatch, that is the current step from the URL.
 *
 * @returns {UseFundraisingSteps}
 */
export function useOnboardingCompanyLayoutSteps(): UseOnboardingCompanySteps {
  const t = useTranslations();

  const onboardingsCompanyRoutes = routes.onboardings.company;

  const isCreateActive = useMatch(onboardingsCompanyRoutes.href);
  const editPath = onboardingsCompanyRoutes.href + '/' + onboardingsCompanyRoutes.edit.href;
  const isParameters = useMatch(editPath);
  const isRepresentativesActive = useMatch(
    editPath + '/' + onboardingsCompanyRoutes.edit.representatives.href,
  );
  const isUbosActive = useMatch(editPath + '/' + onboardingsCompanyRoutes.edit.ubos.href);
  const isAccessActive = useMatch(editPath + '/' + onboardingsCompanyRoutes.edit.access.href);
  const isSummaryActive = useMatch(editPath + '/' + onboardingsCompanyRoutes.edit.summary.href);

  const activeSteps = [
    {
      label: t('GeneralInformation'),
      value: isCreateActive || isParameters,
    },
    {
      label: t('LegalRepresentative', { nb: 3 }),
      value: isRepresentativesActive,
    },
    {
      label: t('Owner', { nb: 1 }),
      value: isUbosActive,
    },
    { label: t('AccessSharing'), value: isAccessActive },
    { label: t('Verification'), value: isSummaryActive },
  ];

  const progress = activeSteps.findIndex((v) => v.value);

  return { progress, activeSteps };
}
