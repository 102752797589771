import { FormControl, FormLabel, HStack, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { MergedQuestionSingleChoice } from '@blockpulse3/graphql/hooks';
import { ExternalLink } from '@blockpulse3/ui/commons';

import { QuestionTooltip } from '../QuestionTooltip';
import { useMifidModal } from '../provider';

type Props = {
  /* ** Current question ** */
  question: MergedQuestionSingleChoice;
};

export function SingleChoiceQuestion({ question }: Props): JSX.Element {
  const t = useTranslations();

  const { questionId, label, tooltip, externalLink } = question;

  const { isSectionDisabled } = useMifidModal();
  const { setValue, control } = useFormContext();

  const handleChange = (value: string): void => {
    setValue(questionId, [value]);
  };

  return (
    <Controller
      key={questionId}
      control={control}
      name={questionId}
      render={({ field }): JSX.Element => (
        <FormControl my="2">
          <FormLabel htmlFor={questionId}>
            <HStack spacing="1">
              <Text>
                {label}
                {externalLink && <ExternalLink link={externalLink} mx="1" title={t('LearnMore')} />}
                {tooltip && <QuestionTooltip label={tooltip} />}
              </Text>
            </HStack>
          </FormLabel>
          <RadioGroup
            isDisabled={isSectionDisabled}
            value={(field.value?.[0] || '') as string}
            onChange={(value): void => handleChange(value)}
          >
            <Stack>
              {question.values.map((value) => (
                <Radio key={value.label} spacing="4" value={value.label} variant="answer" w="full">
                  <Stack spacing="1">
                    <Text>{value.label}</Text>
                    {value.description && (
                      <Text color="gray.600" fontSize="sm" lineHeight="1.5">
                        {value.description}
                      </Text>
                    )}
                  </Stack>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}
