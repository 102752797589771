import { Button, HStack, Icon, Skeleton, Stack, Text, Tooltip } from '@chakra-ui/react';
import { MailIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useGetSubscriptionQuery,
  useRemindSubscriptionInvitationMutation,
} from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

type Props = unknown;

/**
 * OperationPanelReminder.
 * Remind button logic section of the transaction side panel.
 *
 * @returns {JSX.Element}
 */
export function OperationPanelReminder(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { data, loading, error, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });
  const [sendReminder, sendReminderReq] = useRemindSubscriptionInvitationMutation();

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Follow-up', { nb: 1 })}
        </Text>
        <Skeleton h="60px" />
      </Stack>
    );
  }

  const handleSendReminder = (): void => {
    sendReminder({
      variables: { subscriptionId },
      onCompleted: () => {
        successToast({ title: t('ReminderSentSuccessfully') });
        refetch();
      },
      onError: () => {
        errorToast({ title: t('ReminderSendingError') });
      },
    });
  };

  const subscription = data.subscription;
  /* ** Enable remind button less than 24 hours ** */
  const shouldRemind = dayjs(subscription.lastEmailSentAt).isBefore(dayjs().subtract(1, 'h'));

  return (
    <Stack px="4" spacing="2">
      <HStack justifyContent="space-between">
        <Text color="gray.600" fontWeight="600">
          {t('Follow-up', { nb: 1 })}
        </Text>
        <Tooltip
          hasArrow
          isDisabled={shouldRemind}
          label={t('RemindSubscribersOncePerDay')}
          placement="top"
        >
          <Button
            isDisabled={!shouldRemind}
            isLoading={sendReminderReq.loading}
            leftIcon={<Icon as={MailIcon} />}
            loadingText={t('SendReminderInProgress')}
            size="sm"
            onClick={handleSendReminder}
          >
            {t('SendReminder')}
          </Button>
        </Tooltip>
      </HStack>
      <Text color="gray.500" fontSize="sm">
        {t('LastEmailSentThe')}
        {subscription?.lastEmailSentAt && (
          <Text as="span" fontWeight="500">
            {formatDate(subscription?.lastEmailSentAt, 'D MMM YYYY')}
          </Text>
        )}
      </Text>
    </Stack>
  );
}

export type OperationPanelReminderProps = Props;
