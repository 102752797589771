import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerProps,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

import {
  OperationPanelActivityFeed,
  OperationPanelComments,
  OperationPanelHoldingMethod,
  OperationPanelReminder,
  OperationPanelTags,
  RefundModal,
} from '../..';
import { SubscriptionPanelAdmin } from './SubscriptionPanelAdmin';
import { SubscriptionPanelDelete } from './SubscriptionPanelDelete';
import { SubscriptionPanelDocuments } from './SubscriptionPanelDocuments';
import { SubscriptionPanelIdentityCard } from './SubscriptionPanelIdentityCard';
import { SubscriptionPanelInvestment } from './SubscriptionPanelInvestment';
import { SubscriptionPanelProgressSteps } from './SubscriptionPanelProgressSteps';

type Props = Omit<DrawerProps, 'children'>;

/**
 * SubscriptionPanel.
 * Side panel of the fundraising operation table view. Visibility is triggered by
 * `useDisclosureLink()` in `<FundraisingSubscribersList />`.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SubscriptionPanel({ ...props }: Props): JSX.Element {
  const t = useTranslations();

  const isSpace = useMatch(routes.space.href + '/*');

  const { subscriptionId = '' } = useParams();

  const [refundAmount, setRefundAmount] = useState<number>(0);
  const refundModal = useDisclosure();

  const openRefundModal = (amount = 0): void => {
    setRefundAmount(amount);
    refundModal.onOpen();
  };

  return (
    <Drawer size="sm" {...props}>
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody pb="16" pt="4" px="0">
          <Stack spacing="4">
            <SubscriptionPanelIdentityCard />
            {isSpace && <SubscriptionPanelAdmin />}
            <Divider />
            <SubscriptionPanelInvestment />
            <Divider />
            <SubscriptionPanelProgressSteps openRefundModal={openRefundModal} />
            {refundModal.isOpen && (
              <RefundModal
                defaultAmount={refundAmount}
                isOpen={refundModal.isOpen}
                subscriptionId={subscriptionId}
                onClose={refundModal.onClose}
              />
            )}
            <Divider />
            <SubscriptionPanelDocuments />
            <Divider />
            <OperationPanelHoldingMethod />
            <Divider />
            <OperationPanelReminder />
            <Divider />
            <OperationPanelTags />
            <Divider />
            <Stack px="4" spacing="4">
              <Tabs isLazy defaultIndex={0}>
                <TabList>
                  <Tab>{t('Activity', { nb: 2 })}</Tab>
                  <Tab>{t('Comment', { nb: 2 })}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <OperationPanelActivityFeed />
                  </TabPanel>
                  <TabPanel>
                    <OperationPanelComments />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
            <SubscriptionPanelDelete />
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export type SubscriptionPanelProps = Props;
