import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const defaultTimezone = 'Europe/Paris';

const timezones: Record<string, string> = {
  FRA: 'Europe/Paris',
};

/**
 * getTimezoneFromCountryCode
 *
 * @param {string} countryCode
 * @returns
 */
export function getTimezoneFromCountryCode(countryCode: string): string {
  return timezones?.[countryCode] || defaultTimezone;
}

/**
 * getUserTimezone
 *
 * @returns {string}
 */
export function getUserTimezone(): string {
  return dayjs.tz.guess();
}

/**
 *  stringToDate.
 *  Convert a string to a date object.
 *
 * @param {string} date
 * @param {string} timezone
 * @param {string} inputFormat - 'YYYY-MM-DD'
 * @returns {Date}
 */
export function stringToDate(date: string, timezone?: string, inputFormat = 'YYYY-MM-DD'): Date {
  return dayjs
    .utc(date, inputFormat)
    .tz(timezone || defaultTimezone, true)
    .toDate();
}
