import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import { Card, CardBody, CardHeader, Icon, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useDeclineSubscriptionInvitationMutation } from '@blockpulse3/graphql/hooks';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';

export function InvitationDeclinedView(): JSX.Element {
  const t = useTranslations();
  const location = useLocation();

  const [declineSubscriptionInvitation, { loading, error, data }] =
    useDeclineSubscriptionInvitationMutation();

  const qs = new URLSearchParams(location.search);
  const qsEmail = qs.get('email');
  const qsToken = qs.get('token');
  const qsSubscriptionId = qs.get('id') || '';

  useEffect(() => {
    if (qsEmail && qsToken && qsSubscriptionId) {
      declineSubscriptionInvitation({
        variables: {
          declineSubscriptionInvitationInput: {
            subscriptionId: qsSubscriptionId,
            email: qsEmail,
            token: qsToken,
          },
        },
      });
    }
  }, []);

  const RenderIconAndTextComponent = (): JSX.Element | null => {
    if (!!error || !qsToken || !qsEmail) {
      return (
        <>
          <CloseIcon color="red.400" h="8" w="8" />
          <Text fontSize="xl" fontWeight="bold">
            {t('InvitationDeclinedError')}
          </Text>
        </>
      );
    }

    if (data) {
      return (
        <>
          <Icon as={CheckCircleIcon} boxSize="8" color="green.500" />
          <Text fontSize="xl" fontWeight="bold">
            {t('InvitationDeclinedSuccessfully')}
          </Text>
        </>
      );
    }

    return null;
  };

  return (
    <Skeleton isLoaded={!loading}>
      <Card w={{ base: 'full', sm: 420 }}>
        <CardHeader alignItems="center" as={Stack} w="full">
          <BrandIcon display="flex" justifyContent="center" objectFit="cover" width="200px" />
        </CardHeader>
        <CardBody>
          <Stack align="center" direction="column" spacing="8">
            <Stack alignSelf="center" direction="row" gap="4">
              <RenderIconAndTextComponent />
            </Stack>
          </Stack>
        </CardBody>
      </Card>
    </Skeleton>
  );
}
