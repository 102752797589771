export enum ActivityType {
  TRANSFER_RECEIVED = 'TRANSFER_RECEIVED',
  TRANSFER_SENT = 'TRANSFER_SENT',
  DOCUMENT_RESET = 'DOCUMENT_RESET',
  DOCUMENT_SIGNED = 'DOCUMENT_SIGNED',
  DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED',
  FUNDS_SOURCE_CERTIFICATE_REFUSED = 'FUNDS_SOURCE_CERTIFICATE_REFUSED',
  SUBSCRIPTION_COMPLETED = 'SUBSCRIPTION_COMPLETED',
  SUBSCRIPTION_VALIDATED = 'SUBSCRIPTION_VALIDATED',
  INVEST_AMOUNT_REVIEWED = 'INVEST_AMOUNT_REVIEWED',
  SUBSCRIPTION_DELETED = 'SUBSCRIPTION_DELETED',
}
