import { Navigate, Route, Routes } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { OnboardingIndividualIdentityView } from '@blockpulse3/web-client/onboardings';

import { HeroLayout } from '../layouts';

type Props = unknown;

/**
 * IdentityScreen.
 * Screen rendering onboarding related routes.
 *
 * @returns {JSX.Element}
 */
export function IdentityScreen(): JSX.Element {
  return (
    <Routes>
      <Route element={<HeroLayout />}>
        <Route element={<OnboardingIndividualIdentityView />} path={routes.onboarding.href} />
      </Route>
      <Route element={<Navigate replace to={routes.onboarding.href} />} path="*" />
    </Routes>
  );
}

export type IdentityScreenProps = Props;
