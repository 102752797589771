import { Badge, HStack, List, ListIcon, ListItem, Skeleton, Stack, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { AssetType, IdentityType, useGetSubscriptionQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  AssetBadge,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';
import { useInvestStatusBadge } from '@blockpulse3/ui/ui-hooks';

type Props = unknown;

/**
 * TransactionPanelOperation.
 * Operation details section of the transaction side panel.
 *
 * @returns {JSX.Element}
 */
export function TransactionPanelOperation(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const investStatus = useInvestStatusBadge();

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Transfer', { nb: 1 })}
        </Text>
        <Skeleton h="40px" />
        <Skeleton h="40px" />
      </Stack>
    );
  }

  const subscription = data.subscription;
  const buyer = subscription.buyerIdentity;
  const investStatusBadge = investStatus(subscription, subscription.operation.status);
  const assetType = subscription.operation.asset?.assetType;
  const shareCount = subscription.investAmount / (subscription.operation.sharePrice || 1);

  return (
    <Stack px="4" spacing="2">
      <HStack spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Transfer', { nb: 1 })}
        </Text>
        <Badge colorScheme={investStatusBadge.color} fontSize="xs">
          {investStatusBadge.label}
        </Badge>
      </HStack>
      <AssetBadge size="xl" type={assetType || AssetType.ORDINARY_SHARE} value={shareCount} />
      <HStack fontSize="sm" justifyContent="space-between">
        <Text color="gray.500" fontWeight="500">
          {t('UnitPrice')}
        </Text>
        <Text fontWeight="500">
          {t('NbPricePerShare', {
            price: formatNumberCurrency(subscription.operation.sharePrice || 1),
          })}
        </Text>
      </HStack>
      <Stack spacing="2">
        <Text color="gray.500" fontSize="sm" fontWeight="500">
          {t('Buyer', { nb: 1 })}
        </Text>
        {buyer ? (
          <IdentityCard background="gray.50" boxShadow="base" p="2" rounded="md">
            <IdentityAvatar boxSize="12" src={buyer.profilePicture} type={buyer.type} />
            <IdentityCardTitle isChecked>
              <Text fontWeight="600">{buyer.name}</Text>
              {buyer.type === IdentityType.COMPANY && <Text>({buyer.identifier})</Text>}
            </IdentityCardTitle>
            <IdentityCardDescription>{buyer.email}</IdentityCardDescription>
          </IdentityCard>
        ) : (
          <Text fontWeight="600">-</Text>
        )}
      </Stack>
      <Stack background="gray.50" fontSize="sm" p="4" rounded="md">
        <Text color="black" fontWeight="600">
          {t('RightShareholdersToFollow')}
        </Text>
        <List color="gray.600" fontWeight="500" spacing="2">
          <ListItem alignItems="center" display="flex">
            <ListIcon as={CheckCircleIcon} boxSize="20px" color="green.400" />
            {t('RightOfPreEmption')}
          </ListItem>
          <ListItem alignItems="center" display="flex">
            <ListIcon as={CheckCircleIcon} boxSize="20px" color="green.400" />
            {t('RightOfApproval')}
          </ListItem>
        </List>
      </Stack>
    </Stack>
  );
}

export type TransactionPanelOperationProps = Props;
