import { useDisclosure } from '@chakra-ui/react';
import {
  ArchiveIcon,
  ClockIcon,
  ShieldCheckIcon,
  TableIcon,
  UploadIcon,
} from '@heroicons/react/outline';
import axios from 'axios';
import { useState } from 'react';
import { generatePath, resolvePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { AssetType, FundraisingType, Operation, OperationStatus } from '@blockpulse3/graphql/hooks';
import { downloadCsv, downloadFile } from '@blockpulse3/helpers';
import { useErrorToast } from '@blockpulse3/ui/commons';

import { ExtendOperationModal } from '../../../ExtendOperationModal';
import { UploadSubscriptionTemplateModal } from '../../../UploadSubscriptionTemplateModal';
import { OperationRow } from '../../types';
import { OperationTableRowTemplate } from './OperationTableRowTemplate';
import { OperationTableUnknownRow } from './OperationTableUnknownRow';

type Props = {
  operation: OperationRow;
};

export function OperationTableFundraisingRow({ operation }: Props): JSX.Element {
  const t = useTranslations();

  const [isPECLoading, setIsPECLoading] = useState<boolean>(false);
  const [isArchiveLoading, setIsArchiveLoading] = useState<boolean>(false);
  const [isSignedCertificatesLoading, setIsSignedCertificatesLoading] = useState<boolean>(false);

  const { companyId = '' } = useParams();

  const errorToast = useErrorToast();

  const navigate = useNavigate();

  const extendOperationModal = useDisclosure();
  const uploadSubscriptionTemplateModal = useDisclosure();

  const isSpace = useMatch(routes.space.href + '/*');

  const handleOperationDraftClick = (): void => {
    if (isSpace) {
      return handleOperationCurrentClick();
    }

    if (!companyId) return;

    navigate(
      generatePath(
        operation.fundraising?.type === FundraisingType.PRIVATE
          ? routes.company.newFundraising.private.href +
              '/' +
              routes.company.newFundraising.private.edit.href
          : routes.company.newFundraising.crowdfunding.href +
              '/' +
              routes.company.newFundraising.crowdfunding.edit.href,
        {
          operationId: operation.id,
        },
      ),
    );
  };

  const handleOperationCurrentClick = (): void => {
    const rowCompanyId = isSpace && operation.company?.id ? operation.company.id : companyId;
    if (rowCompanyId) {
      const relPath = generatePath(routes.company.fundraising.href, {
        companyId: rowCompanyId,
        operationId: operation.id,
      });
      navigate(
        isSpace
          ? resolvePath(
              relPath,
              generatePath(routes.space.company.full, { companyId: rowCompanyId }),
            ).pathname
          : relPath,
      );
    }

    return;
  };

  const handleRowClick = (): void => {
    switch (operation.status) {
      case OperationStatus.DRAFT: {
        handleOperationDraftClick();
        break;
      }

      default: {
        handleOperationCurrentClick();
        break;
      }
    }
  };

  const handlePECDownload = (operationId: Operation['id']): void => {
    setIsPECLoading(true);
    axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/exportRegulatoryReportCsv',
        { operationId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'text/csv',
          },
          responseType: 'text',
        },
      )
      .then((response) => {
        downloadCsv(response.data, `pec-${operationId}.csv`);
        setIsPECLoading(false);
      })
      .catch(() => {
        setIsPECLoading(false);
      });
  };

  const handleArchiveDownload = (operationId: Operation['id']): void => {
    setIsArchiveLoading(true);
    axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/exportSubscriptionsReportZip',
        {
          operationId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/zip',
          },
          responseType: 'blob',
        },
      )
      .then((response) => {
        downloadFile(response.data, `archive-files-${operationId}.zip`);
        setIsArchiveLoading(false);
      })
      .catch(() => {
        setIsArchiveLoading(false);
      });
  };

  const handleSignedCertificatesDownload = (operationId: Operation['id']): void => {
    setIsSignedCertificatesLoading(true);
    axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/exportSignedCertificateDocuments',
        { operationId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/zip',
          },
          responseType: 'blob',
        },
      )
      .then((response) => {
        downloadFile(response.data, `signed-certificates-${operationId}.zip`);
        setIsSignedCertificatesLoading(false);
      })
      .catch(() => {
        errorToast({ title: t('ArchiveDownloadFailed') });
        setIsSignedCertificatesLoading(false);
      });
  };
  const endDate = operation.closingDate || operation.endSubscriptionDate;

  const canExportPEC = isSpace && operation.status !== OperationStatus.DRAFT;
  const isClosed =
    isSpace &&
    [OperationStatus.CLOSED, OperationStatus.REVIEWED, OperationStatus.FINALIZED].includes(
      operation.status,
    );
  const canExportArchive = isClosed;
  const canUploadSubscriptionTemplate = !isClosed;
  const canExportSignedCertificates = isSpace && operation.status === OperationStatus.FINALIZED;
  const canExtendOperation = isSpace && operation.status === OperationStatus.STARTED && endDate;

  const operationSubTitle =
    operation.assetType === AssetType.BOND ? t('BondIssuance') : t('CapitalIncrease');

  if (!operation.sharePrice) {
    return <OperationTableUnknownRow operation={operation} operationSubtitle={operationSubTitle} />;
  }

  return (
    <>
      <OperationTableRowTemplate
        operation={operation}
        operationSubtitle={operationSubTitle}
        totalAmount={operation.fundraising?.hardCap}
        menuActions={[
          {
            label: t('ExtendOperation'),
            ariaLabel: 'extend operation',
            icon: ClockIcon,
            disabled: !canExtendOperation,
            onClick: extendOperationModal.onOpen,
          },
          {
            label: t('ImportSubscriptionTemplate'),
            ariaLabel: 'import subscription template',
            icon: UploadIcon,
            disabled: !canUploadSubscriptionTemplate,
            onClick: uploadSubscriptionTemplateModal.onOpen,
          },
          {
            label: t('DownloadSignedCertificates'),
            ariaLabel: 'Download archive of signed certificates',
            icon: ShieldCheckIcon,
            disabled: !canExportSignedCertificates,
            loading: isSignedCertificatesLoading,
            onClick: () => handleSignedCertificatesDownload(operation.id),
          },
          {
            label: t('DownloadPECFile'),
            ariaLabel: 'Download PEC file',
            icon: TableIcon,
            disabled: !canExportPEC,
            loading: isPECLoading,
            onClick: () => handlePECDownload(operation.id),
          },
          {
            label: t('DownloadOperationArchive'),
            ariaLabel: 'Download archive of operation file',
            icon: ArchiveIcon,
            disabled: !canExportArchive,
            loading: isArchiveLoading,
            onClick: () => handleArchiveDownload(operation.id),
          },
        ]}
        onRowClick={handleRowClick}
      />
      {canExtendOperation && extendOperationModal.isOpen && (
        <ExtendOperationModal
          isOpen={extendOperationModal.isOpen}
          operationId={operation.id}
          operationType={operation.type}
          title={t('ExtendOperationWName', { operationName: operation.name })}
          onClose={extendOperationModal.onClose}
        />
      )}
      {canUploadSubscriptionTemplate && uploadSubscriptionTemplateModal.isOpen && (
        <UploadSubscriptionTemplateModal
          isOpen={uploadSubscriptionTemplateModal.isOpen}
          operationId={operation.id}
          onClose={uploadSubscriptionTemplateModal.onClose}
        />
      )}
    </>
  );
}

export type OperationTableFundraisingRowProps = Props;
