import { UpDownIcon } from '@chakra-ui/icons';
import {
  Button,
  FlexProps,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import { IdentificationIcon, LogoutIcon, UserGroupIcon, UserIcon } from '@heroicons/react/outline';
import { Link, useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { UserIdentityAvatar } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = FlexProps;

export function SettingsMenu(): JSX.Element {
  const t = useTranslations();

  const { user: authUser, spaceId } = useAuthUser();
  const isSpace = useMatch(routes.space.href + '/*');
  const isManaging = useMatch(routes.manage.href + '/*');

  const handleLogout = (): void => {
    localStorage.removeItem('token');
    window.location.href = routes.login.href;
  };

  const member = authUser?.members?.[0];
  const isSuperAdmin = authUser?.isSuperAdmin;

  return (
    <Menu matchWidth>
      <MenuButton
        as={Button}
        bg={isManaging && spaceId ? 'black' : undefined}
        data-cy="settings-menu"
        pl="0"
        variant="menu-button"
        w="full"
      >
        <HStack w="full">
          <UserIdentityAvatar
            boxSize="8"
            ml="1.5"
            src={authUser?.individualIdentity?.identity?.profilePicture}
          />
          <Stack alignItems="flex-start" spacing="0">
            <Text>
              {authUser?.individualIdentity?.firstName} {authUser?.individualIdentity?.lastName}
            </Text>
            <Text fontSize="xs" fontWeight="normal">
              {t('MyAccount')}
            </Text>
          </Stack>
          <UpDownIcon boxSize="4" ml="auto" />
        </HStack>
      </MenuButton>
      <MenuList color="gray.900" p="2" shadow="lg">
        {member && !isSpace && (
          <Link to={routes.space.href}>
            <MenuItem fontWeight="semibold" px="2" rounded="md">
              <HStack>
                <Icon as={UserGroupIcon} />
                <Text>{t(isSuperAdmin ? 'AdminSpace' : 'ManagerSpace')}</Text>
              </HStack>
            </MenuItem>
          </Link>
        )}
        {(isSpace || isManaging) && (
          <Link to={routes.me.href}>
            <MenuItem fontWeight="semibold" px="2" rounded="md">
              <HStack>
                <Icon as={UserIcon} />
                <Text>{t('UserSpace')}</Text>
              </HStack>
            </MenuItem>
          </Link>
        )}
        <Link to={routes.me.settings.full}>
          <MenuItem fontWeight="semibold" px="2" rounded="md">
            <HStack>
              <IdentificationIcon height="18" width="18" />
              <Text>{t('Settings')}</Text>
            </HStack>
          </MenuItem>
        </Link>
        <MenuItem data-cy="logout" fontWeight="semibold" px="2" rounded="md" onClick={handleLogout}>
          <HStack>
            <LogoutIcon height="18" width="18" />
            <Text>{t('Logout')}</Text>
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export type SettingsMenuProps = Props;
