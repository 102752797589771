import { Card, HStack, Icon, Link, Skeleton, Text, useDisclosure } from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/solid';
import { Link as ReactRouterLink, matchPath, useLocation, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  IdentityType,
  IdentityVerificationStatus,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';

import { IdentityHeader } from './IdentityHeader';

/**
 * CompanyHeader.
 * Header information of company identity.
 *
 * @returns {JSX.Element}
 */
export function CompanyHeader(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const location = useLocation();

  const editDisclosure = useDisclosure();

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const company = companyReq?.data?.company;
  const isVerified = company?.kybVerificationStatus === IdentityVerificationStatus.APPROVED;

  const isDashboard = !!matchPath(routes.company.href, location.pathname);

  if (!company) {
    return (
      <Skeleton>
        <Card h="80px" />
      </Skeleton>
    );
  }

  return (
    <IdentityHeader>
      <IdentityCard onMouseEnter={editDisclosure.onOpen} onMouseLeave={editDisclosure.onClose}>
        <IdentityAvatar boxSize="14" src={company?.profilePicture} type={IdentityType.COMPANY} />
        <HStack spacing="3">
          <IdentityCardTitle isChecked={isVerified}>
            <Text>{company?.name && `${company.name} (${company.registrationNumber})`}</Text>
          </IdentityCardTitle>
          {editDisclosure.isOpen && (
            <HStack fontSize="sm" spacing="1">
              <Icon as={PencilIcon} />
              <Link
                as={ReactRouterLink}
                fontWeight="400"
                relative="path"
                to={(isDashboard ? '' : '../') + routes.company.settings.href}
              >
                {t('EditInformation')}
              </Link>
            </HStack>
          )}
          <Link
            display={{ base: 'block', md: 'none' }}
            fontWeight="400"
            href={routes.me.settings.href}
          >
            <Icon as={PencilIcon} />
          </Link>
        </HStack>
        <IdentityCardDescription>{company?.address?.formatted}</IdentityCardDescription>
      </IdentityCard>
    </IdentityHeader>
  );
}
