import { As } from '@chakra-ui/react';
import {
  BriefcaseIcon,
  GlobeAltIcon,
  LibraryIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/solid';

import { SpaceType } from '@blockpulse3/graphql/hooks';

/* ** Pagination length ** */
export const PAGE_SIZE = 10;

export const spaceTypeIcons: Record<SpaceType, As> = {
  [SpaceType.GLOBAL]: GlobeAltIcon,
  [SpaceType.PROVIDER]: LibraryIcon,
  [SpaceType.GROUP]: OfficeBuildingIcon,
  [SpaceType.ADVISOR]: BriefcaseIcon,
};
