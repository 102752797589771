import { Icon, ListItem, Tooltip, UnorderedList } from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { IntlUbbleResponseCodes } from '@blockpulse3/data/locales/types';
import { IdentityVerificationOrigin, SubscriptionSide } from '@blockpulse3/data/shared';
import {
  GetSubscriptionInfosFragment,
  SubscriptionStepStatus,
  SubscriptionStepType,
} from '@blockpulse3/graphql/hooks';
import { SubscriptionStep } from '@blockpulse3/ui/ui-hooks';

type Props = {
  subscription: GetSubscriptionInfosFragment;
  step: SubscriptionStep;
};

export const StepTooltipLabel = ({ subscription, step }: Props): JSX.Element | null => {
  const i18nUbbleResponseCodes = useTranslations('UbbleResponseCodes');

  if (step.type !== SubscriptionStepType.IDENTITY_VERIFICATION) {
    return null;
  }

  if (
    !step.status ||
    ![
      SubscriptionStepStatus.NONE,
      SubscriptionStepStatus.PENDING,
      SubscriptionStepStatus.ERROR,
    ].includes(step.status)
  ) {
    return null;
  }

  const isSeller = step.side === SubscriptionSide.SELLER;

  const identity = isSeller ? subscription?.sellerIdentity : subscription?.buyerIdentity;

  const individualIdentity =
    identity?.individualIdentity ?? identity?.companyIdentity?.kybIndividualIdentity;

  const ubbleResponseCodes = individualIdentity?.ubbleResponseCodes || [];

  const hasWarningMessage =
    !!ubbleResponseCodes.length &&
    individualIdentity?.kycOrigin === IdentityVerificationOrigin.UBBLE;

  if (!hasWarningMessage) {
    return null;
  }

  const tooltipLabel = (
    <UnorderedList>
      {ubbleResponseCodes.map((responseCode) => {
        return (
          <ListItem key={responseCode.code} fontSize="sm">
            {i18nUbbleResponseCodes(responseCode.summary.toUpperCase() as IntlUbbleResponseCodes)}
          </ListItem>
        );
      })}
    </UnorderedList>
  );

  return (
    <Tooltip hasArrow alignSelf="center" label={tooltipLabel} placement="top">
      <Icon as={InformationCircleIcon} />
    </Tooltip>
  );
};
