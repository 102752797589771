import { Badge, HStack, Skeleton, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import MessageKeys from 'use-intl/dist/core/utils/MessageKeys';

import {
  BankIdentityInfosFragment,
  IdentityType,
  IdentityVerificationStatus,
  useGetSpvQuery,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';

import { getVerificationStatusBadge } from '../../../utils';

interface BankIdentitiesListProps {
  bankIdentities: BankIdentityInfosFragment[];
}

export const SPVIdentitiesList = ({
  bankIdentities,
}: BankIdentitiesListProps): JSX.Element | null => {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const { data, error, loading } = useGetSpvQuery({ variables: { companyId } });

  if (loading) {
    return <Skeleton h="50px" w="full" />;
  }

  if (error) {
    return <ErrorQueryCard h="50px" />;
  }

  if (!data) {
    return <ErrorQueryCard h="50px" />;
  }

  if (!bankIdentities?.length) {
    return null;
  }

  const company = data.company;

  /* ** Return full name of the identity ** */
  const getIdentityName = (bankIdentity: BankIdentityInfosFragment): string => {
    const { identity } = bankIdentity;
    if (identity.type === IdentityType.COMPANY) {
      return identity.companyIdentity?.name || '';
    }
    return `${identity.individualIdentity?.firstName} ${identity.individualIdentity?.lastName}`;
  };

  const handleIdentityClick = (bankIdentity: BankIdentityInfosFragment): void => {
    const { onboardingLink, verificationStatus } = bankIdentity;
    if (
      onboardingLink &&
      [
        IdentityVerificationStatus.NONE,
        IdentityVerificationStatus.REFUSED,
        IdentityVerificationStatus.IN_PROGRESS,
      ].includes(verificationStatus)
    ) {
      window.location.href = onboardingLink;
    }
  };

  return (
    <>
      {bankIdentities.map((bankIdentity) => {
        const verificationStatus = bankIdentity.verificationStatus;
        const { color, label } = getVerificationStatusBadge(verificationStatus);

        return (
          <IdentityCard
              key={bankIdentity.id}
              _hover={{ bg: 'gray.50' }}
              cursor="pointer"
              layerStyle="border"
              p="4"
              rounded="md"
              onClick={(): void => handleIdentityClick(bankIdentity)}
            >
              <IdentityAvatar identity={bankIdentity.identity} />
              <HStack justifyContent="space-between" w="full">
                <IdentityCardTitle fontSize="lg">
                  <Text fontWeight="600">
                    {t(
                      bankIdentity.identity.id === company?.identity?.id
                        ? 'SPVLong'
                        : 'InitialShareholder',
                      { nb: 1 },
                    )}
                  </Text>
                </IdentityCardTitle>
                <Badge colorScheme={color}>
                  {t(label as MessageKeys<IntlMessages, keyof Messages>)}
                </Badge>
              </HStack>
              <IdentityCardDescription color="gray.800" fontSize="md">
                {getIdentityName(bankIdentity)}
              </IdentityCardDescription>
            </IdentityCard>
        );
      })}
    </>
  );
};
