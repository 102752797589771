import { Button, useDisclosure } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { SubscriptionInfosFragment } from '@blockpulse3/graphql/hooks';
import { MifidModal } from '@blockpulse3/web-client/subscription';

type Props = {
  subscriptionId: SubscriptionInfosFragment['id'];
};

export function ConsultMifid({ subscriptionId }: Props): JSX.Element {
  const t = useTranslations();

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button size="sm" variant="secondary" onClick={onOpen}>
        {t('ConsultResults')}
      </Button>
      <MifidModal
        isOpen={isOpen}
        isReading={true}
        subscriptionId={subscriptionId}
        onClose={onClose}
      />
    </>
  );
}
