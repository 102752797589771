import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { AdjustmentsIcon, CheckIcon, InboxInIcon } from '@heroicons/react/outline';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { BoxIcon, ErrorQueryCard } from '@blockpulse3/ui/commons';

/**
 * NewOpportunityOperationSummary.
 *
 * @returns {JSX.Element}
 */
export function NewOpportunityOperationSummary(): JSX.Element {
  const t = useTranslations();

  const { companyId = '', operationId = '' } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useGetOperationQuery({
    variables: { operationId },
  });

  const handleStepCancel = (): void => {
    navigate('../' + routes.company.newOpportunity.edit.documentation.href);
  };

  const handleStepSubmit = (): void => {
    navigate(generatePath(routes.company.opportunity.full, { companyId, operationId }));
  };

  if (loading) {
    return <Skeleton h="500px" w="full" />;
  }

  if (!data || error) {
    return (
      <HStack px="6" spacing="4">
        <ErrorQueryCard h="500px" w="full" />
      </HStack>
    );
  }

  const { operation } = data;

  return (
    <Card variant="divider-top" width="full">
      <CardHeader as={Stack} spacing="2">
        <Heading size="lg">{t('Summary')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="5">
        <Stack direction={{ base: 'column', md: 'row' }} spacing="2">
          <HStack
            alignItems="flex-start"
            bg="gray.50"
            p="8"
            rounded="md"
            spacing="4"
            w={{ base: 'full', md: '50%' }}
          >
            <BoxIcon icon={InboxInIcon} iconBoxSize="24px" />
            <Stack spacing="4">
              <Stack spacing="0">
                <Text fontSize="lg" fontWeight="600">
                  {operation?.name}
                </Text>
                <Text lineHeight="1">{t('OperationName')}</Text>
              </Stack>
            </Stack>
          </HStack>
          <HStack
            alignItems="flex-start"
            bg="gray.50"
            p="8"
            rounded="md"
            spacing="4"
            w={{ base: 'full', md: '50%' }}
          >
            <BoxIcon icon={AdjustmentsIcon} iconBoxSize="24px" />
            <Stack spacing="4">
              <Stack spacing="0">
                <Text fontSize="lg" fontWeight="600">
                  {t('OperationStartDate')}
                </Text>
                <Text lineHeight="1">
                  {formatDate(operation.startSubscriptionDate, 'D MMMM YYYY')}
                </Text>
              </Stack>
              <Stack spacing="0">
                <Text fontSize="lg" fontWeight="600">
                  {t('OperationEndDate')}
                </Text>
                <Text lineHeight="1">
                  {formatDate(operation.endSubscriptionDate, 'D MMMM YYYY')}
                </Text>
              </Stack>
            </Stack>
          </HStack>
        </Stack>
        <Stack spacing="6">
          <Stack spacing="4">
            <Heading size="md">{t('IncludedServices')}</Heading>
            <SimpleGrid gap="4" minChildWidth="300px">
              <HStack>
                <Icon as={CheckIcon} boxSize="24px" color="green.500" />
                <Text color="gray.600" fontWeight="medium">
                  {t('IdentitiesVerification')}
                </Text>
              </HStack>
              <HStack>
                <Icon as={CheckIcon} boxSize="24px" color="green.500" />
                <Text color="gray.600" fontWeight="medium">
                  {t('IntentCollection')}
                </Text>
              </HStack>
            </SimpleGrid>
          </Stack>
        </Stack>
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button variant="secondary" w="full" onClick={handleStepCancel}>
          {t('Back')}
        </Button>
        <Button w="full" onClick={handleStepSubmit}>
          {t('Validate')}
        </Button>
      </CardFooter>
    </Card>
  );
}
