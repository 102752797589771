import { HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { NoticeProps, OptionProps, chakraComponents } from 'chakra-react-select';
import { useTranslations } from 'use-intl';

import { CompanyOption, CompanyOptionGroup } from './types';

export function Option({
  children,
  ...props
}: OptionProps<CompanyOption, false, CompanyOptionGroup>): JSX.Element {
  return (
    <chakraComponents.Option {...props}>
      <HStack justifyContent="space-between" w="full">
        <Stack spacing="0">
          <Text fontWeight="semibold">{children}</Text>
          <HStack>
            <Text fontSize="sm">{props.data?.address?.city}</Text>
            <Text fontSize="sm"> - </Text>
            <Text fontSize="sm">{props.data.registrationNumber}</Text>
          </HStack>
        </Stack>
        <Icon as={ChevronRightIcon} />
      </HStack>
    </chakraComponents.Option>
  );
}

export function NoOptionsMessage({ ...props }: NoticeProps<CompanyOption>): JSX.Element {
  const t = useTranslations();

  return (
    <chakraComponents.NoOptionsMessage {...props}>
      {t('NoResult')}
    </chakraComponents.NoOptionsMessage>
  );
}

export function LoadingMessage({ ...props }: NoticeProps<CompanyOption>): JSX.Element {
  const t = useTranslations();

  return (
    <chakraComponents.LoadingMessage {...props}>{t('Loading')}</chakraComponents.LoadingMessage>
  );
}

export const components = {
  LoadingMessage,
  NoOptionsMessage,
  Option,
};
