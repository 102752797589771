export const nationalities: { [key: string]: string } = {
  FRA: 'Française',
  AFG: 'Afghane',
  ALB: 'Albanaise',
  DZA: 'Algerienne',
  AND: 'Andorrane',
  AGO: 'Angolaise',
  ATG: 'Antiguaise et barbudienne',
  AZE: 'Azerbaïdjanaise',
  ARG: 'Argentine',
  AUS: 'Australienne',
  AUT: 'Autrichienne',
  BHS: 'Bahamienne',
  BHR: 'Bahreinienne',
  BGD: 'Bangladaise',
  ARM: 'Armenienne',
  BRB: 'Barbadienne',
  BEL: 'Belge',
  BTN: 'Bhoutanaise',
  BOL: 'Bolivienne',
  BIH: 'Bosnienne',
  BWA: 'Botswanaise',
  BRA: 'Bresilienne',
  BLZ: 'Belizienne',
  SLB: 'Salomonaise',
  BRN: 'Bruneienne',
  BGR: 'Bulgare',
  MMR: 'Birmane',
  BDI: 'Burundaise',
  BLR: 'Bielorusse',
  KHM: 'Cambodgienne',
  CMR: 'Camerounaise',
  CAN: 'Canadienne',
  CPV: 'Cap-verdienne',
  CAF: 'Centrafricaine',
  LKA: 'Sri-lankaise',
  TCD: 'Tchadienne',
  CHL: 'Chilienne',
  CHN: 'Chinoise',
  TWN: 'Taiwanaise',
  COL: 'Colombienne',
  COM: 'Comorienne',
  COG: 'Congolaise',
  CRI: 'Costaricaine',
  HRV: 'Croate',
  CUB: 'Cubaine',
  CYP: 'Chypriote',
  CZE: 'Tcheque',
  BEN: 'Beninoise',
  DNK: 'Danoise',
  DMA: 'Dominiquaise',
  DOM: 'Dominicaine',
  ECU: 'Equatorienne',
  SLV: 'Salvadorienne',
  GNQ: 'Equato-guineenne',
  GBR: 'Britanique',
  ETH: 'Ethiopienne',
  ERI: 'Erythreenne',
  EST: 'Estonienne',
  FJI: 'Fidjienne',
  FIN: 'Finlandaise',
  DJI: 'Djiboutienne',
  GAB: 'Gabonaise',
  GEO: 'Georgienne',
  GMB: 'Gambienne',
  PSE: 'Palestinienne',
  DEU: 'Allemande',
  GHA: 'Ghaneenne',
  KIR: 'Kiribatienne',
  GRC: 'Hellenique',
  GRD: 'Grenadienne',
  GTM: 'Guatemalteque',
  GIN: 'Guineenne',
  GUY: 'Guyanienne',
  HTI: 'Haïtienne',
  VAT: 'Vaticane',
  HND: 'Hondurienne',
  HUN: 'Hongroise',
  ISL: 'Islandaise',
  IND: 'Indienne',
  IDN: 'Indonesienne',
  IRQ: 'Irakienne',
  IRL: 'Irlandaise',
  ISR: 'Israélienne',
  ITA: 'Italienne',
  CIV: 'Ivoirienne',
  JAM: 'Jamaïcaine',
  JPN: 'Japonaise',
  KAZ: 'Kazakhstanaise',
  JOR: 'Jordanienne',
  KEN: 'Kenyane',
  PRK: 'Nord-coréenne',
  KOR: 'Sud-coréenne',
  KWT: 'Koweitienne',
  KGZ: 'Kirghize',
  LAO: 'Laotienne',
  LBN: 'Libanaise',
  LSO: 'Lesothane',
  LVA: 'Lettone',
  LBR: 'Liberienne',
  LBY: 'Libyenne',
  LIE: 'Liechtensteinoise',
  LTU: 'Lituanienne',
  LUX: 'Luxembourgeoise',
  MDG: 'Malgache',
  MWI: 'Malawienne',
  MYS: 'Malaisienne',
  MDV: 'Maldivienne',
  MLI: 'Malienne',
  MLT: 'Maltaise',
  MRT: 'Mauritanienne',
  MUS: 'Mauricienne',
  MEX: 'Mexicaine',
  MCO: 'Monegasque',
  MNG: 'Mongole',
  MDA: 'Moldave',
  MAR: 'Marocaine',
  MOZ: 'Mozambicaine',
  OMN: 'Omanaise',
  NAM: 'Namibienne',
  NRU: 'Nauruane',
  NPL: 'Nepalaise',
  NLD: 'Neerlandaise',
  VUT: 'Vanuatuane',
  NZL: 'Neo-zelandaise',
  NIC: 'Nicaraguayenne',
  NER: 'Nigerienne',
  NGA: 'Nigeriane',
  NOR: 'Norvegienne',
  FSM: 'Micronesienne',
  MHL: 'Marshallaise',
  PLW: 'Palau',
  PAK: 'Pakistanaise',
  PAN: 'Panameenne',
  PNG: 'Papouane-neoguineenne',
  PRY: 'Paraguayenne',
  PER: 'Peruvienne',
  PHL: 'Philippine',
  POL: 'Polonaise',
  PRT: 'Portugaise',
  GNB: 'Bissau-Guinéenne',
  TLS: 'Est-timoraise',
  PRI: 'Portoricaine',
  QAT: 'Qatarienne',
  ROU: 'Roumaine',
  RUS: 'Russe',
  RWA: 'Rwandaise',
  KNA: 'Kittitienne-et-nevicienne',
  LCA: 'Saint-Lucienne',
  VCT: 'Saint-Vincentaise-et-Grenadine',
  SMR: 'Saint-Marinaise',
  STP: 'Santomeenne',
  SAU: 'Saoudienne',
  SEN: 'Senegalaise',
  SYC: 'Seychelloise',
  SLE: 'Sierra-leonaise',
  SGP: 'Singapourienne',
  SVK: 'Slovaque',
  VNM: 'Vietnamienne',
  SVN: 'Slovene',
  SOM: 'Somalienne',
  ZAF: 'Sud-africaine',
  ZWE: 'Zimbabweenne',
  ESP: 'Espagnole',
  SDN: 'Soudanaise',
  SUR: 'Surinamaise',
  SWZ: 'Swazie',
  SWE: 'Suedoise',
  CHE: 'Suisse',
  SYR: 'Syrienne',
  TJK: 'Tadjike',
  THA: 'Thaïlandaise',
  TGO: 'Togolaise',
  TON: 'Tonguienne',
  TTO: 'Trinidadienne',
  ARE: 'Emirienne',
  TUN: 'Tunisienne',
  TUR: 'Turque',
  TKM: 'Turkmene',
  TUV: 'Tuvaluane',
  UGA: 'Ougandaise',
  UKR: 'Ukrainienne',
  MKD: 'Macedonienne',
  EGY: 'Egyptienne',
  TZA: 'Tanzanienne',
  USA: 'Americaine',
  BFA: 'Burkinabe',
  URY: 'Uruguayenne',
  UZB: 'Ouzbeke',
  VEN: 'Venezuelienne',
  WSM: 'Samoane',
  YEM: 'Yemenite',
  ZMB: 'Zambienne',
};

export const nationalityKeys = Object.keys(nationalities);
export const nationalityOptions = nationalityKeys.map((code) => ({
  label: nationalities[code],
  value: code,
}));
