import {
  Button,
  Divider,
  FormControl,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import { useGetOperationQuery, useUpdateMifidMutation } from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

type Props = {
  onClose: () => void;
} & Omit<ResponsiveModalProps, 'children'>;

export function UpdateMifidFormModal({ onClose, ...props }: Props): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();
  const successToast = useSuccessToast();

  const [value, setValue] = useState<string>('');
  const [inputError, setInputError] = useState<FieldError | undefined>(undefined);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [updateMifid, { loading }] = useUpdateMifidMutation();

  const { data } = useGetOperationQuery({ variables: { operationId }, skip: !operationId });

  useEffect(() => {
    if (data?.operation) {
      setValue(data.operation.rawMifidSections || '');
    }
  }, [data]);

  const isValidJson = (json?: string): boolean => {
    if (!json) return false;

    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleSubmit = (): void => {
    const mifidJson = inputRef.current?.value;
    if (!isValidJson(mifidJson)) {
      setInputError({ type: FormErrors.BadJsonFormat, message: FormErrors.BadJsonFormat });
      return;
    }

    if (mifidJson) {
      updateMifid({
        variables: {
          updateMifidInput: {
            operationId,
            mifidSections: JSON.parse(mifidJson),
          },
        },
        onCompleted: () => {
          successToast({ title: t('MifidUpdated') });
          onClose();
        },
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setValue(e.target.value);

    if (inputError) {
      setInputError(undefined);
    }
  };

  return (
    <ResponsiveModal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="xl" p="4">
          {t('MifidUpdateLabel')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack}>
          <Stack spacing="4">
            <FormControl isInvalid={!!inputError}>
              <Textarea minH="400" ref={inputRef} size="sm" value={value} onChange={handleChange} />
              <ErrorMessage error={inputError} />
            </FormControl>
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button isLoading={loading} onClick={handleSubmit}>
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
