import { createContext } from 'react';

export type IVerificationStepContext = {
  stepIndex: number;
  activeSteps: number;
};

export const initVerificationStepContext: IVerificationStepContext = {
  stepIndex: 0,
  activeSteps: 0,
};

export const VerificationStepContext = createContext<IVerificationStepContext>(initVerificationStepContext);
