import {
  Badge,
  Button,
  Card,
  HStack,
  Icon,
  Skeleton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import {
  SortingState,
  Updater,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';
import { useTranslations } from 'use-intl';

import { SubscriptionsTabsStatus, noop } from '@blockpulse3/data/shared';
import {
  FiscalAdvantage,
  HoldingMethod,
  IdentityType,
  OperationStatus,
  SubscriptionShortInfosFragment,
} from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  PaginationButtons,
  ProgressStep,
  TableContainer,
} from '@blockpulse3/ui/commons';
import {
  PaginationHandlers,
  useInvestStatusBadge,
  useStepStatusMapLazy,
} from '@blockpulse3/ui/ui-hooks';

import { FundraisingSubscribersTableEmpty } from './FundraisingSubscribersTableEmpty';

type Props = PaginationHandlers<SubscriptionShortInfosFragment> & {
  operationStatus: OperationStatus | undefined;
  tabName: SubscriptionsTabsStatus;
  sorting: SortingState;
  allowedFiscalAdvantages: FiscalAdvantage[];
  onRowClick?: (id: SubscriptionShortInfosFragment['id']) => void;
  onSortingChange: (sortFn: Updater<SortingState>) => void;
};

export function FundraisingSubscribersTable({
  operationStatus,
  tabName,
  results,
  sorting,
  pageInfo,
  pageCount,
  currentPage,
  loading,
  isLoadingMore,
  allowedFiscalAdvantages = [],
  handleNextPage,
  handlePreviousPage,
  onSortingChange,
  onRowClick = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  /* ** Function to return subscription invest status badge ** */
  const investStatusBadge = useInvestStatusBadge();

  const generateStepStatusMap = useStepStatusMapLazy();

  const hasIrPme = allowedFiscalAdvantages.includes(FiscalAdvantage.IR_PME);

  const columnHelper = useMemo(() => createColumnHelper<SubscriptionShortInfosFragment>(), []);
  const columns = useMemo(
    () => [
      columnHelper.accessor('buyerIdentity', {
        header: t('Subscriber', { nb: 1 }),
        enableSorting: false,
        cell: (info) => {
          const buyerIdentity = info.getValue();

          if (!buyerIdentity) {
            return (
              <Td>
                <Text fontWeight="600">-</Text>
              </Td>
            );
          }

          return (
            <Td>
              <IdentityCard>
                <IdentityAvatar src={buyerIdentity?.profilePicture} type={buyerIdentity?.type} />
                <IdentityCardTitle fontSize="md" isChecked={buyerIdentity?.isVerified}>
                  <Text fontWeight="600">{buyerIdentity?.name}</Text>
                  {buyerIdentity?.type === IdentityType.COMPANY && (
                    <Text>({buyerIdentity?.identifier})</Text>
                  )}
                </IdentityCardTitle>
                <IdentityCardDescription>{buyerIdentity?.email}</IdentityCardDescription>
              </IdentityCard>
            </Td>
          );
        },
      }),
      columnHelper.accessor('tags', {
        header: 'Tags',
        enableSorting: false,
        cell: (info) => {
          const tags = info.getValue();
          return (
            <Td>
              <HStack flexWrap="wrap" minWidth="150px" spacing="2">
                {tags.map((tag) => (
                  <Badge key={tag.id} colorScheme={tag.color}>
                    {tag.name}
                  </Badge>
                ))}
              </HStack>
            </Td>
          );
        },
      }),
      columnHelper.accessor('investStatus', {
        header: t('Status', { nb: 1 }),
        enableSorting: false,
        cell: ({ row }) => {
          const subscriber: SubscriptionShortInfosFragment = row.original;
          const badge = investStatusBadge(subscriber, operationStatus);
          return (
            <Td>
              <Badge colorScheme={badge.color}>{badge.label}</Badge>
            </Td>
          );
        },
      }),
      columnHelper.accessor('stepStatuses', {
        header: t('Step', { nb: 1 }),
        enableSorting: false,
        cell: ({ row }) => {
          const subscriber: SubscriptionShortInfosFragment = row.original;
          const [, steps] = generateStepStatusMap(subscriber.data?.mergedSteps);
          return (
            <Td>
              <HStack>
                {steps.map((step) => (
                  <Tooltip key={step.index} hasArrow label={step.title} placement="top">
                    <Button minW="0" variant="link">
                      <ProgressStep
                        index={step.index}
                        status={step.status}
                        textProps={{ fontSize: 'sm', fontWeight: '500', color: 'gray.500' }}
                      />
                    </Button>
                  </Tooltip>
                ))}
              </HStack>
            </Td>
          );
        },
      }),
      columnHelper.accessor('investAmount', {
        header: t('Amount', { nb: 1 }),
        enableSorting: false,
        cell: ({ row }) => {
          const { feesAmount, amountDue } = row.original;
          const amount = amountDue ? formatNumberCurrency(amountDue) : '-';
          return (
            <Td isNumeric={true}>
              {feesAmount ? (
                <Tooltip
                  hasArrow
                  label={t('IncludingFees', { fees: formatNumberCurrency(feesAmount) })}
                  placement="top"
                  textAlign="center"
                >
                  <Text display="inline-block" fontWeight="600" textDecoration="underline">
                    {amount}
                  </Text>
                </Tooltip>
              ) : (
                <Text display="inline-block" fontWeight="600">
                  {amount}
                </Text>
              )}
            </Td>
          );
        },
      }),
      columnHelper.accessor('holdingMethod', {
        header: t('HoldingMode'),
        enableSorting: false,
        cell: ({ row }) => {
          const isIndividual = row.original.buyerIdentity?.type === IdentityType.INDIVIDUAL;
          const isDirect = row.original.holdingMethod === HoldingMethod.DIRECT;
          const shouldDisplayIrPme = isIndividual && isDirect && hasIrPme;
          return (
            <Td fontSize="sm" fontWeight="600">
              {shouldDisplayIrPme
                ? t('IR-PME')
                : t(`HoldingMethodTypeValues.${row.original.holdingMethod}`)}
            </Td>
          );
        },
      }),
      columnHelper.accessor('completedAt', {
        header: t('CompleteFileOn'),
        enableSorting: true,
        cell: (info) => {
          const completedAt = info.getValue();
          return (
            <Td align="right" fontWeight="600">
              {completedAt ? formatDate(completedAt, 'DD/MM/YYYY HH:mm:ss') : '-'}
            </Td>
          );
        },
      }),
      columnHelper.accessor('id', {
        header: '',
        enableSorting: false,
        cell: (info) => (
          <Td key={info.cell.id} align="right" textAlign="right">
            <Button fontSize="sm" variant="secondary">
              {t('ShowDetails')}
            </Button>
          </Td>
        ),
      }),
    ],
    [t, columnHelper, investStatusBadge, hasIrPme],
  );
  const numericColumns = ['investAmount', 'admissionFees'];

  const table = useReactTable({
    data: (results.length ? results : []) as SubscriptionShortInfosFragment[],
    columns,
    state: { sorting },
    onSortingChange: (sortFn) => {
      onSortingChange(sortFn);
    },
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    table.setColumnVisibility({
      stepStatuses: tabName === SubscriptionsTabsStatus.PENDING,
      completedAt: tabName === SubscriptionsTabsStatus.COMPLETED,
    });
  }, [tabName, table]);

  const handleRowClick = (id: SubscriptionShortInfosFragment['id']): void => {
    onRowClick(id);
  };

  if (loading) {
    return (
      <Skeleton>
        <Card h="400px" />
      </Skeleton>
    );
  }

  if (!isLoadingMore && !results.length) {
    return <FundraisingSubscribersTableEmpty />;
  }

  return (
    <Stack spacing="4">
      <TableContainer maxH="900px">
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    cursor={header.column.getCanSort() ? 'pointer' : 'initial'}
                    isNumeric={numericColumns.includes(header.id)}
                    w={header.id === 'identity' ? '40%' : '15%'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <HStack
                      justifyContent={numericColumns.includes(header.id) ? 'end' : undefined}
                      spacing="0"
                    >
                      <Text>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </Text>
                      {header.column.getIsSorted() && (
                        <Icon
                          boxSize="16px"
                          as={
                            header.column.getIsSorted() === 'asc' ? ChevronUpIcon : ChevronDownIcon
                          }
                        />
                      )}
                    </HStack>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isLoadingMore ? (
              <Tr key="0">
                <Td colSpan={5}>
                  <Stack alignItems="center" h="737px" justifyContent="center">
                    <Spinner />
                  </Stack>
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.original.id}
                  role="button"
                  onClick={(): void => handleRowClick(row.original.id)}
                >
                  {row.getVisibleCells().map((cell) =>
                    flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      key: cell.id,
                    }),
                  )}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationButtons
        currentPage={currentPage}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        loading={isLoadingMore}
        pageCount={pageCount}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
      />
    </Stack>
  );
}
