import {
  As,
  Badge,
  Button,
  ButtonProps,
  Flex,
  HStack,
  Icon,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  DocumentInfosFragment,
  DocumentStatus,
  useGetDocumentQuery,
} from '@blockpulse3/graphql/hooks';
import { useBadge } from '@blockpulse3/ui/ui-hooks';

import { styles } from './styles';

let timeoutId: NodeJS.Timeout | null = null;

type Props = {
  /* ** Document object ** */
  document?: DocumentInfosFragment;
  /* ** Title ** */
  title: string;
  /* ** Is callback disabled ** */
  isDisabled?: boolean;
  /* ** Custom icon ** */
  icon?: As;
  /* ** Default size ** */
  size?: 'md' | 'sm';
  /* ** Callback on click ** */
  onClick?: () => void;
  /* ** Callback on signature complete ** */
  onSignatureComplete?: () => void;
} & Omit<ButtonProps, 'size'>;

/**
 * SignatureDocumentButton.
 * Custom button to render a document to be signed with its status.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SignatureDocumentButton({
  document,
  isDisabled = false,
  icon = DocumentTextIcon,
  title,
  size = 'md',
  onClick = noop,
  onSignatureComplete = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const [isPollingStarted, setIsPollingStarted] = useState<boolean>(false);
  const [isPollingLoading, setIsPollingLoading] = useState<boolean>(false);

  const { startPolling, stopPolling } = useGetDocumentQuery({
    variables: {
      documentId: document?.id || '',
    },
    skip: !document?.id || document?.status === DocumentStatus.SIGNED,
  });

  useEffect(() => {
    if (!isPollingStarted && document?.status === DocumentStatus.SIGNATURES_PENDING) {
      setIsPollingStarted(true);
      setIsPollingLoading(true);
      startPolling(2000);

      if (timeoutId) clearTimeout(timeoutId);

      /* ** Stop polling after 20sec ** */
      timeoutId = setTimeout(() => {
        stopPolling();
        setIsPollingLoading(false);
      }, 20_000);
    }

    /* ** Stop polling when document status is VALID ** */
    if (isPollingStarted && document?.status === DocumentStatus.SIGNED) {
      onSignatureComplete();
      stopPolling();
      setIsPollingStarted(false);
      setIsPollingLoading(false);
    }
  }, [document, isPollingStarted, startPolling, stopPolling, onSignatureComplete]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  /* ** Render badge label and color for different status ** */
  const { badge } = useBadge(
    document?.status,
    [
      { value: DocumentStatus.SIGNED, label: t('Signed'), color: 'green' },
      { value: DocumentStatus.CANCELLED, label: t('Canceled'), color: 'red' },
      {
        value: DocumentStatus.SIGNATURES_PENDING,
        label: t('InProgress'),
        color: 'yellow',
      },
    ],
    {
      color: 'gray',
      label: t('RequiredAction', { nb: 1 }),
    },
  );

  const handleDocumentClick = (): void => {
    if (!isDisabled) {
      onClick();
    }
  };

  return (
    <Button
      alignItems={{ base: 'flex-start', md: 'center' }}
      aria-disabled="false"
      direction={{ base: 'column', md: 'row' }}
      flexShrink="0"
      flexWrap="wrap"
      height="full"
      isDisabled={isDisabled}
      justifyContent="space-between"
      lineHeight="inherit"
      px="2"
      py="1"
      rounded="sm"
      variant="ghost"
      onClick={handleDocumentClick}
      {...props}
    >
      <HStack>
        <Flex
          alignItems="center"
          bg="gray.50"
          borderRadius="full"
          boxSize="32px"
          justifyContent="center"
        >
          <Icon as={icon} boxSize="24px" color="gray.500" />
        </Flex>
        <Text
          fontSize={styles[size].fontSize}
          fontWeight={styles[size].fontWeight}
          textDecoration="underline"
        >
          {title}
        </Text>
      </HStack>
      <Badge colorScheme={badge.color} fontSize={styles[size].badgeFontSize}>
        <HStack alignItems="center" spacing="1">
          {isPollingLoading && <Spinner size="xs" speed="0.7s" />}
          <Text>{badge.label}</Text>
        </HStack>
      </Badge>
    </Button>
  );
}

export type SignatureDocumentButtonProps = Props;
