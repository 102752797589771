import {
  Badge,
  Button,
  HStack,
  Icon,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, TemplateIcon } from '@heroicons/react/outline';
import {
  SortingState,
  Updater,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';
import { useTranslations } from 'use-intl';

import { SubscriptionsTabsStatus, noop } from '@blockpulse3/data/shared';
import {
  IdentityType,
  OperationStatus,
  SubscriptionShortInfosFragment,
} from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  PaginationButtons,
  ProgressStep,
  TableContainer,
} from '@blockpulse3/ui/commons';
import {
  PaginationHandlers,
  useInvestStatusBadge,
  useStepStatusMapLazy,
} from '@blockpulse3/ui/ui-hooks';

type Props = {
  operationStatus: OperationStatus | undefined;
  tabName: SubscriptionsTabsStatus;
  sorting?: SortingState;
  onRowClick?: (id: string) => void;
  onSortingChange?: (sortFn: Updater<SortingState>) => void;
} & PaginationHandlers<SubscriptionShortInfosFragment>;

/**
 * OpportunityOperationIntentsTable.
 * Table of intents of an opportunity operation.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OpportunityOperationIntentsTable({
  operationStatus,
  tabName,
  results,
  sorting,
  loading,
  pageInfo,
  pageCount,
  currentPage,
  isLoadingMore,
  handleNextPage,
  handlePreviousPage,
  onRowClick = noop,
  onSortingChange = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  /* ** Function to return subscription invest status badge ** */
  const investStatusBadge = useInvestStatusBadge();

  const generateStepStatusMap = useStepStatusMapLazy();

  const columnHelper = useMemo(() => createColumnHelper<SubscriptionShortInfosFragment>(), []);
  const columns = useMemo(
    () => [
      columnHelper.accessor('buyerIdentity', {
        header: t('Buyer', { nb: 1 }),
        enableSorting: false,
        cell: (info) => {
          const buyerIdentity = info.getValue();

          if (!buyerIdentity) {
            return (
              <Td>
                <Text fontWeight="600">-</Text>
              </Td>
            );
          }

          return (
            <Td>
              <IdentityCard>
                <IdentityAvatar src={buyerIdentity?.profilePicture} type={buyerIdentity?.type} />
                <IdentityCardTitle fontSize="md" isChecked={buyerIdentity?.isVerified}>
                  <Text fontWeight="600">{buyerIdentity?.name}</Text>
                  {buyerIdentity?.type === IdentityType.COMPANY && (
                    <Text>({buyerIdentity?.identifier})</Text>
                  )}
                </IdentityCardTitle>
                <IdentityCardDescription>{buyerIdentity?.email}</IdentityCardDescription>
              </IdentityCard>
            </Td>
          );
        },
      }),
      columnHelper.accessor('tags', {
        header: t('Tags'),
        enableSorting: false,
        cell: (info) => {
          const tags = info.getValue();
          return (
            <Td>
              <HStack flexWrap="wrap" minWidth="150px" spacing="2">
                {tags.map((tag) => (
                  <Badge key={tag.id} colorScheme={tag.color}>
                    {tag.name}
                  </Badge>
                ))}
              </HStack>
            </Td>
          );
        },
      }),
      columnHelper.accessor('investStatus', {
        header: t('Status', { nb: 1 }),
        enableSorting: false,
        cell: ({ row }) => {
          const subscriber: SubscriptionShortInfosFragment = row.original;
          const badge = investStatusBadge(subscriber, operationStatus);
          return (
            <Td>
              <Badge colorScheme={badge.color}>{badge.label}</Badge>
            </Td>
          );
        },
      }),
      columnHelper.accessor('stepStatuses', {
        header: t('Step', { nb: 1 }),
        enableSorting: false,
        cell: ({ row }) => {
          const subscriber: SubscriptionShortInfosFragment = row.original;
          const [, steps] = generateStepStatusMap(subscriber.data?.mergedSteps);
          return (
            <Td>
              <HStack>
                {steps.map((step) => (
                  <Tooltip key={step.index} hasArrow label={step.title} placement="top">
                    <Button minW="0" variant="link">
                      <ProgressStep
                        index={step.index}
                        status={step.status}
                        textProps={{ fontSize: 'sm', fontWeight: '500', color: 'gray.500' }}
                      />
                    </Button>
                  </Tooltip>
                ))}
              </HStack>
            </Td>
          );
        },
      }),
      columnHelper.accessor('investAmount', {
        header: t('Amount', { nb: 1 }),
        enableSorting: false,
        cell: ({ row }) => {
          const { feesAmount, amountDue } = row.original;
          const amount = amountDue ? formatNumberCurrency(amountDue) : '-';
          return (
            <Td isNumeric={true}>
              {feesAmount ? (
                <Tooltip
                  hasArrow
                  label={t('IncludingFees', { fees: formatNumberCurrency(feesAmount) })}
                  placement="top"
                  textAlign="center"
                >
                  <Text display="inline-block" fontWeight="600" textDecoration="underline">
                    {amount}
                  </Text>
                </Tooltip>
              ) : (
                <Text display="inline-block" fontWeight="600">
                  {amount}
                </Text>
              )}
            </Td>
          );
        },
      }),
      columnHelper.accessor('completedAt', {
        header: t('CompleteFileOn'),
        enableSorting: true,
        cell: (info) => {
          const completedAt = info.getValue();
          return (
            <Td align="right" fontWeight="600">
              {completedAt ? formatDate(completedAt, 'DD/MM/YYYY HH:mm:ss') : '-'}
            </Td>
          );
        },
      }),
      columnHelper.accessor('id', {
        header: '',
        enableSorting: false,
        cell: (info) => (
          <Td key={info.cell.id} align="right" textAlign="right">
            <Button fontSize="sm" variant="secondary">
              {t('ShowDetails')}
            </Button>
          </Td>
        ),
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data: results,
    columns,
    state: { sorting },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: (sortFn) => {
      onSortingChange(sortFn);
    },
  });

  useEffect(() => {
    table.setColumnVisibility({
      stepStatuses: tabName === SubscriptionsTabsStatus.PENDING,
      completedAt: tabName === SubscriptionsTabsStatus.COMPLETED,
    });
  }, [tabName, table]);

  const handleRowClick = (id: string): void => {
    onRowClick(id);
  };

  if (loading) {
    return (
      <Stack layerStyle="emptyState">
        <Spinner />
      </Stack>
    );
  }

  if (results.length === 0) {
    return (
      <Stack layerStyle="emptyState">
        <Icon as={TemplateIcon} boxSize="40px" color="gray.500" />
        <Text>{t('NoIntent')}</Text>
      </Stack>
    );
  }

  return (
    <Stack>
      <TableContainer maxH="900px">
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    cursor={header.column.getCanSort() ? 'pointer' : 'initial'}
                    isNumeric={'investAmount' === header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <HStack spacing="1">
                      <Text width="full">
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </Text>
                      {header.column.getIsSorted() && (
                        <Icon
                          boxSize="16px"
                          as={
                            header.column.getIsSorted() === 'asc' ? ChevronUpIcon : ChevronDownIcon
                          }
                        />
                      )}
                    </HStack>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.original.id}
                role="button"
                onClick={(): void => handleRowClick(row.original.id)}
              >
                {row.getVisibleCells().map((cell) =>
                  flexRender(cell.column.columnDef.cell, {
                    ...cell.getContext(),
                    key: cell.id,
                  }),
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationButtons
        currentPage={currentPage}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        loading={isLoadingMore}
        pageCount={pageCount}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
      />
    </Stack>
  );
}
