/**
 * formatIBAN.
 * Format a text into an IBAN format
 * Add a space to a given text every 4 characters
 *
 * @param {string} options
 * @returns {string}
 */
export function formatIBAN(text: string): string {
  const filteredText = text.replace(/\s/g, '');
  const split = filteredText.match(/.{1,4}/g);
  if (!split) return text;

  const formattedIBAN = split.join(' ');
  return formattedIBAN;
}
