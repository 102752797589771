import { Box } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
  size?: '40px' | '30px';
  mr?: '36px' | '24px' | '16px' | '8px';
};

export function IconBoxWrapper({ children, size = '40px', mr = '36px' }: Props): JSX.Element {
  return (
    <Box
      alignItems="center"
      bg="gray.50"
      borderRadius="3px"
      display="flex"
      h={size}
      justifyContent="center"
      minW={size}
      mr={mr}
      w={size}
    >
      {children}
    </Box>
  );
}
