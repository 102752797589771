import * as yup from 'yup';

import {
  requiredNumberField,
  requiredSelectField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { AssetType } from '@blockpulse3/graphql/hooks';

import { ICompleteOperationForm } from './type';

export const completeOperationFormDefaults: ICompleteOperationForm = {
  name: '',
  type: { label: '', value: '' },
  closingDate: '',
  asset: {
    label: 'default',
    value: AssetType.ORDINARY_SHARE,
    type: AssetType.ORDINARY_SHARE,
  },
  sharePrice: 0,
};

export const completeOperationFormSchema = yup.object({
  name: requiredStringField,
  type: requiredSelectField,
  closingDate: requiredStringField,
  asset: requiredSelectField,
  sharePrice: requiredNumberField,
}) as yup.ObjectSchema<ICompleteOperationForm>;
