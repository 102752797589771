import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { languageOptions, noop } from '@blockpulse3/data/shared';
import { ErrorMessage } from '@blockpulse3/ui/commons';

import { schema } from './schema';
import { IUpdateLanguageForm } from './types';

type Props = {
  defaultValues: IUpdateLanguageForm;
  onSubmit?: (data: IUpdateLanguageForm) => void;
};

export function UpdateLanguageForm({ defaultValues, onSubmit = noop }: Props): JSX.Element {
  const t = useTranslations();

  const { control, formState, handleSubmit } = useForm<IUpdateLanguageForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleFormSubmit: SubmitHandler<IUpdateLanguageForm> = (data) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 5, lg: 8 }}>
        <Text flexBasis={{ lg: 250 }} fontSize="lg" fontWeight="medium">
          {t('Language', { nb: 1 })}
        </Text>
        <Card maxW={{ lg: '3xl' }} variant="divider-bottom" w="full">
          <CardBody>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <Controller
                control={control}
                name="language"
                render={({ field }): JSX.Element => (
                  <FormControl
                    isInvalid={!!formState.errors.language}
                    w={{ base: 'full', md: '49%' }}
                  >
                    <FormLabel htmlFor="language">{t('Language', { nb: 1 })}</FormLabel>
                    <Select id="language" options={languageOptions} {...field} />
                    <ErrorMessage error={formState.errors.language?.value} />
                  </FormControl>
                )}
              />
            </Stack>
          </CardBody>
          <Divider />
          <CardFooter justifyContent="flex-end">
            <Button type="submit">{t('Save')}</Button>
          </CardFooter>
        </Card>
      </Stack>
    </form>
  );
}

export type UpdateLanguageFormProps = Props;
