import { InfoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  InputGroup,
  InputRightAddon,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select, SingleValue } from 'chakra-react-select';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { RepresentativeRole } from '@blockpulse3/graphql/hooks';
import { alpha3CountryOptions, formatCountry } from '@blockpulse3/helpers';
import { ErrorMessage, Input } from '@blockpulse3/ui/commons';

import { CompanyOption, SearchCompanySelect } from '../../../../SearchCompanySelect';
import { legalRepresentativeFormSchema, representativeFormDefaults } from '../../schema';
import { IRepresentativeForm } from '../../types';

type Props = {
  /* ** Default form values ** */
  defaultValues?: IRepresentativeForm;
  /* ** Is the Cancel button disabled ** */
  shouldCancel?: boolean;
  /* ** Representative is the president ** */
  isPresident?: boolean;
  /* ** Should fill creation date of the company ** */
  hasCreationDate?: boolean;
  /* ** Should display alert if user is not representative of representative company ** */
  showRepresentativeAlert?: boolean;
  /* ** Callback of the Cancel button ** */
  onCancel?: () => void;
  /* ** Callback of the Submit button ** */
  onSubmit?: (data: IRepresentativeForm) => void;
};

/**
 * LegalRepresentantForm.
 * Legal version of the RepresentantForm. Uses a forwardRef function to be able to submit it
 * from the outside.
 */
export const LegalRepresentativeInformationsForm = React.forwardRef<HTMLFormElement, Props>(
  (props, ref) => {
    const {
      defaultValues = representativeFormDefaults,
      shouldCancel = false,
      isPresident = false,
      hasCreationDate = false,
      showRepresentativeAlert = false,
      onCancel = noop,
      onSubmit = noop,
    } = props;

    const t = useTranslations();
    const i18nRoleValues = useTranslations('RoleValues');

    const { register, control, formState, setValue, trigger, handleSubmit, reset } =
      useForm<IRepresentativeForm>({
        defaultValues,
        resolver: yupResolver(legalRepresentativeFormSchema),
        context: { hasCreationDate },
      });

    /* ** Update fields on company name change ** */
    const handleNameChange = (option: SingleValue<CompanyOption>): void => {
      if (!option) {
        return;
      }
      setValue('name', option.name);
      setValue('corporateForm', option.corporateForm);
      setValue('creationDate', option.creationDate);
      setValue('shareCapital', option.shareCapital);
      setValue('registrationNumber', option.registrationNumber);
      setValue('registrationEntity', option.registrationEntity);
      setValue('address.line', option.address.line);
      setValue('address.city', option.address.city);
      setValue('address.postalCode', option.address.postalCode);
      setValue('address.country', {
        label: formatCountry(option.address.country),
        value: option.address.country,
      });
      setValue('businessActivityDescription', option.businessActivityDescription);
      trigger();
    };

    const handleFormSubmit: SubmitHandler<IRepresentativeForm> = (data): void => {
      onSubmit(data);
    };

    const handleFormCancel = (): void => {
      onCancel();
    };

    useEffect(() => {
      reset({ ...defaultValues });
    }, [reset, defaultValues]);

    return (
      <form ref={ref} onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="8">
          <Stack spacing="4">
            <Stack spacing="4">
              {!isPresident && (
                <Controller
                  control={control}
                  name="role"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors?.role}>
                      <FormLabel htmlFor="role">{t('Role', { nb: 1 })}</FormLabel>
                      <RadioGroup id="role" {...field}>
                        <HStack spacing="4">
                          <Radio value={RepresentativeRole.PRESIDENT}>
                            {i18nRoleValues(RepresentativeRole.PRESIDENT)}
                          </Radio>
                          <Radio value={RepresentativeRole.COMPANY_MANAGER}>
                            {i18nRoleValues(RepresentativeRole.COMPANY_MANAGER)}
                          </Radio>
                        </HStack>
                      </RadioGroup>
                      <ErrorMessage error={formState.errors?.role} />
                    </FormControl>
                  )}
                />
              )}
              <FormControl>
                <FormLabel htmlFor="searchCompany">{t('SearchCompany')}</FormLabel>
                <SearchCompanySelect
                  menuPlacement="auto"
                  placeholder={t('SearchByNameOrSIREN')}
                  value={null}
                  onChange={handleNameChange}
                />
              </FormControl>
            </Stack>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.name}>
                <FormLabel htmlFor="name">{t('CompanyName')}</FormLabel>
                <Input id="name" type="text" {...register('name')} />
                <ErrorMessage error={formState.errors?.name} />
              </FormControl>
              {hasCreationDate && (
                <FormControl isInvalid={!!formState.errors?.creationDate}>
                  <FormLabel htmlFor="creationDate">{t('CreationDate')}</FormLabel>
                  <Input id="creationDate" type="date" {...register('creationDate')} />
                  <ErrorMessage error={formState.errors?.creationDate} />
                </FormControl>
              )}
            </Stack>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.corporateForm}>
                <HStack alignItems="flex-start" spacing="0">
                  <FormLabel htmlFor="corporateForm">{t('CorporateForm')}</FormLabel>
                </HStack>
                <Input id="corporateForm" type="text" {...register('corporateForm')} />
                <ErrorMessage error={formState.errors?.corporateForm} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.shareCapital}>
                <HStack alignItems="flex-start" spacing="0">
                  <FormLabel htmlFor="shareCapital">{t('ShareCapital')}</FormLabel>
                </HStack>
                <InputGroup>
                  <Input
                    id="shareCapital"
                    step="0.01"
                    type="number"
                    {...register('shareCapital')}
                  />
                  <InputRightAddon>€</InputRightAddon>
                </InputGroup>
                <ErrorMessage error={formState.errors?.shareCapital} />
              </FormControl>
            </Stack>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.registrationNumber}>
                <HStack alignItems="flex-start" spacing="0">
                  <FormLabel htmlFor="registrationNumber">{t('RegistrationNumber')}</FormLabel>
                  <Tooltip
                    hasArrow
                    label={t('NonFrenchCompanyIdentificationNumber')}
                    placement="top"
                  >
                    <Icon as={InfoIcon} color="gray.500" />
                  </Tooltip>
                </HStack>
                <Input id="registrationNumber" type="text" {...register('registrationNumber')} />
                <ErrorMessage error={formState.errors?.registrationNumber} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.registrationEntity}>
                <HStack alignItems="flex-start" spacing="0">
                  <FormLabel htmlFor="registrationEntity">{t('RegistrationEntityCity')}</FormLabel>
                </HStack>
                <Input id="registrationEntity" type="text" {...register('registrationEntity')} />
                <ErrorMessage error={formState.errors?.registrationEntity} />
              </FormControl>
            </Stack>
            <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
              <Text fontSize="lg" fontWeight="bold">
                {t('Residence')}
              </Text>
              <Stack spacing="4">
                <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                  <FormControl isInvalid={!!formState.errors?.address?.line}>
                    <FormLabel htmlFor="line">{t('Address', { nb: 1 })}</FormLabel>
                    <Input id="line" type="string" {...register('address.line')} />
                    <ErrorMessage error={formState.errors?.address?.line} />
                  </FormControl>
                  <FormControl isInvalid={!!formState.errors?.address?.city}>
                    <FormLabel htmlFor="city">{t('City')}</FormLabel>
                    <Input id="city" type="string" {...register('address.city')} />
                    <ErrorMessage error={formState.errors?.address?.city} />
                  </FormControl>
                </Stack>
                <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                  <FormControl isInvalid={!!formState.errors.address?.postalCode}>
                    <FormLabel htmlFor="zipcode">{t('PostalCode', { nb: 1 })}</FormLabel>
                    <Input id="zipcode" type="string" {...register('address.postalCode')} />
                    <ErrorMessage error={formState.errors?.address?.postalCode} />
                  </FormControl>
                  <Controller
                    control={control}
                    name="address.country"
                    render={({ field }): JSX.Element => (
                      <FormControl isInvalid={!!formState.errors.address?.country}>
                        <FormLabel htmlFor="country">{t('Country')}</FormLabel>
                        <Select
                          id="country"
                          menuPlacement="auto"
                          options={alpha3CountryOptions}
                          {...field}
                        />
                        <ErrorMessage error={formState.errors.address?.country?.value} />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
              <Text fontSize="lg" fontWeight="bold">
                {t('SigningPersonIdentification')}
              </Text>
              <Stack spacing="4">
                <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                  <FormControl isInvalid={!!formState.errors?.firstName}>
                    <FormLabel htmlFor="firstName">{t('FirstName')}</FormLabel>
                    <Input id="firstName" type="string" {...register('firstName')} />
                    <ErrorMessage error={formState.errors?.firstName} />
                  </FormControl>
                  <FormControl isInvalid={!!formState.errors?.lastName}>
                    <FormLabel htmlFor="lastName">{t('LastName')}</FormLabel>
                    <Input id="lastName" type="string" {...register('lastName')} />
                    <ErrorMessage error={formState.errors?.lastName} />
                  </FormControl>
                </Stack>
                <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                  <FormControl isInvalid={!!formState.errors?.position}>
                    <FormLabel htmlFor="lastName">{t('RoleInCompany')}</FormLabel>
                    <Input id="repRole" type="string" {...register('position')} />
                    <ErrorMessage error={formState.errors?.position} />
                  </FormControl>
                  <FormControl isInvalid={!!formState.errors?.email}>
                    <FormLabel htmlFor="email">{t('Email', { nb: 1 })}</FormLabel>
                    <Input id="email" type="text" {...register('email')} />
                    <ErrorMessage error={formState.errors?.email} />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {showRepresentativeAlert && (
            <Alert status="warning">
              <AlertIcon as={ExclamationIcon} />
              <Stack spacing="1">
                <AlertTitle>{t('MissingRightsWithCompany')}</AlertTitle>
                <AlertDescription>{t('MissingRightsWithCompanyDescription')}</AlertDescription>
              </Stack>
            </Alert>
          )}
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <Button
              isDisabled={!shouldCancel}
              type="button"
              variant="secondary"
              width="full"
              onClick={handleFormCancel}
            >
              {t('Delete')}
            </Button>
            <Button type="submit" width="full">
              {t('Validate')}
            </Button>
          </Stack>
        </Stack>
      </form>
    );
  },
);

LegalRepresentativeInformationsForm.displayName = 'LegalRepresentativeInformationsForm';

export type LegalRepresentativeInformationsFormProps = Props;
