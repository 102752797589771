import en from '@blockpulse3/data/locales/en.json';
import fr from '@blockpulse3/data/locales/fr.json';
import { Locale } from '@blockpulse3/graphql/hooks';

/* ** Supported locales ** */
export const locales: Record<Locale, string> = {
  [Locale.fr]: 'Français',
  [Locale.en]: 'English',
};

/* ** Supported locales with message file ** */
export const messages: Record<Locale, typeof fr> = {
  [Locale.fr]: fr,
  [Locale.en]: en,
};

/* ** Typed Object.keys() ** */
const localeKeys = <Obj extends object>(obj: Obj): (keyof Obj)[] => {
  return Object.keys(obj) as (keyof Obj)[];
};

/* ** For <Select /> ** */
export const languageOptions = localeKeys(locales).map((locale) => ({
  label: locales[locale],
  value: locale,
}));
