import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

/**
 * formatDate.
 *
 * @param date
 * @param template - 'DD/MM/YYYY'
 * @param locale - 'fr'
 * @returns
 */
export function formatDate(
  date: Date | string | null | undefined,
  template = 'DD/MM/YYYY',
  locale = 'fr',
  timezone = 'Europe/Paris',
): string {
  if (date === '') return '';
  return dayjs(date).tz(timezone).locale(locale).format(template);
}

/**
 * formatDate.
 *
 * @param date
 * @param template - 'DD/MM/YYYY'
 * @param locale - 'fr'
 * @returns
 */
export function simpleFormatDate(
  date: Date | string | null | undefined,
  template = 'DD/MM/YYYY',
  locale = 'fr',
): string {
  if (date === '') return '';
  return dayjs(date).locale(locale).format(template);
}

/**
 * secondsSinceEpoch.
 * Get number of seconds since 01/01/1970. Usefull for token contracts.
 *
 * @param {Date} date
 * @returns {number}
 */
export function secondsSinceEpoch(date: Date): number {
  const utcMilllisecondsSinceEpoch = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
  const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000);

  return utcSecondsSinceEpoch;
}

/**
 * endOfNextYear.
 * Get the end of next year.
 */
export function endOfNextYear(): Date {
  return dayjs.utc().add(1, 'year').endOf('year').hour(12).toDate();
}
