import { IndividualIdentityInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatCountry, formatNationalityOption } from '@blockpulse3/helpers';

import { IUpdateAddressForm } from './UpdateAddressForm';
import { IUpdateProfileForm } from './UpdateProfileForm';

/**
 * fmtProfile.
 * Format MeQuery from context into default form values.
 *
 * @param {IndividualIdentityInfosFragment} individual
 * @returns {IUpdateProfileForm}
 */
export function fmtProfile(individual: IndividualIdentityInfosFragment): IUpdateProfileForm {
  const nationality = formatNationalityOption(individual?.nationality || '');

  return {
    /* ** Always set empty string for default <input type="file"/> values ** */
    profilePicture: '',
    firstName: individual?.firstName || '',
    lastName: individual?.lastName || '',
    birthdate: individual?.birthdate || '',
    birthplace: individual?.birthplace || '',
    birthCityPostalCode: individual?.birthCityPostalCode || '',
    birthCountry: {
      label: formatCountry(individual?.birthCountry || ''),
      value: individual?.birthCountry || '',
    },
    nationality,
    gender: individual?.gender || '',
  };
}

/**
 * fmtAddress.
 * Format address from individualIdentity into default form values.
 *
 * @param {IndividualIdentityInfosFragment} individual
 * @returns {IUpdateAddressForm}
 */
export function fmtAddress(individual: IndividualIdentityInfosFragment): IUpdateAddressForm {
  return {
    line: individual?.address?.line || '',
    city: individual?.address?.city || '',
    postalCode: individual?.address?.postalCode || '',
    country: {
      label: formatCountry(individual?.address?.country || ''),
      value: individual?.address?.country || '',
    },
  };
}
