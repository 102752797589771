import { HStack, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { useGetCompanyQuery } from '@blockpulse3/graphql/hooks';
import { CompanyIdentityAvatar, UserIdentityAvatar } from '@blockpulse3/ui/commons';
import { useManagedIndividual } from '@blockpulse3/web-client/auth';

type Props = unknown;

/**
 * SwitcherButtonCompany.
 * Company version of SwitcherButton.
 *
 * @returns {JSX.Element}
 */
export function SwitcherButtonCompany(): JSX.Element {
  const { individual } = useManagedIndividual();

  const { companyId = '' } = useParams();

  const { data } = useGetCompanyQuery({ variables: { companyId } });

  const company = data?.company;

  return (
    <HStack pl="0.5" w="full">
      <CompanyIdentityAvatar boxSize="8" ml="1.5" src={company?.identity?.profilePicture} />
      <Stack alignItems="flex-start" flexGrow="1" pl="0.5" spacing="0.5">
        <Text
          fontSize="md"
          fontWeight="semibold"
          maxWidth="150px"
          overflow="hidden"
          textAlign="left"
          variant="ellipsis"
        >
          {company?.name || '...'}
        </Text>
        <HStack alignItems="flex-start" spacing="1">
          <UserIdentityAvatar boxSize="4" src={individual?.identity?.profilePicture} />
          <Text
            fontSize="sm"
            fontWeight="medium"
            maxWidth="130px"
            overflow="hidden"
            textAlign="left"
            variant="ellipsis"
          >
            {`${individual?.firstName || '...'} ${individual?.lastName || ''}`}
          </Text>
        </HStack>
      </Stack>
    </HStack>
  );
}

export type SwitcherButtonCompanyProps = Props;
