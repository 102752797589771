import { InfoIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormLabel,
  HStack,
  Icon,
  InputGroup,
  InputRightAddon,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { Controller, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { alpha3CountryOptions } from '@blockpulse3/helpers';
import { ErrorMessage, Input } from '@blockpulse3/ui/commons';

import { CompanyUbos } from '../../CompanyUbos';
import { schemaLegalShareholder } from '../schema';
import { ILegalShareholderForm } from '../types';

type Props = {
  /* ** Are fields disabled ** */
  isDisabled?: boolean;
  /* ** Default form values, Legal form version ** */
  defaultValues?: ILegalShareholderForm;
  /* ** Id of the targeted company ** */
  targetCompanyId?: string;
  /* ** Callback of the Cancel button ** */
  onCancel: () => void;
  /* ** Callback of the Submit button ** */
  onSubmit: () => void;
};

/**
 * LegalShareholderInformationsForm.
 * Legal version of the ShareholderForm.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function LegalShareholderInformationsForm({
  isDisabled = false,
  defaultValues,
  targetCompanyId,
  onCancel,
  onSubmit,
}: Props): JSX.Element {
  const t = useTranslations();

  /* ** Legal ubo form ** */
  const { register, control, formState } = useForm<ILegalShareholderForm>({
    defaultValues,
    resolver: yupResolver(schemaLegalShareholder),
    shouldFocusError: false,
  });

  return (
    <Stack spacing="8">
      <Stack spacing="4">
        <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
          <FormControl isInvalid={!!formState.errors?.name}>
            <FormLabel htmlFor="name">{t('CompanyName')}</FormLabel>
            <Input id="name" isDisabled={isDisabled} type="text" {...register('name')} />
            <ErrorMessage error={formState.errors?.name} />
          </FormControl>
          <FormControl isInvalid={!!formState.errors?.creationDate}>
            <FormLabel htmlFor="creationDate">{t('CreationDate')}</FormLabel>
            <Input
              id="creationDate"
              isDisabled={isDisabled}
              type="date"
              {...register('creationDate')}
            />
            <ErrorMessage error={formState.errors?.creationDate} />
          </FormControl>
        </Stack>
        <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
          <FormControl isInvalid={!!formState.errors?.corporateForm}>
            <HStack alignItems="flex-start" spacing="0">
              <FormLabel htmlFor="corporateForm">{t('CorporateForm')}</FormLabel>
            </HStack>
            <Input
              id="corporateForm"
              isDisabled={isDisabled}
              type="text"
              {...register('corporateForm')}
            />
            <ErrorMessage error={formState.errors?.corporateForm} />
          </FormControl>
          <FormControl isInvalid={!!formState.errors?.shareCapital}>
            <HStack alignItems="flex-start" spacing="0">
              <FormLabel htmlFor="shareCapital">{t('ShareCapital')}</FormLabel>
            </HStack>
            <InputGroup>
              <Input
                id="shareCapital"
                isDisabled={isDisabled}
                step="0.01"
                type="number"
                {...register('shareCapital')}
              />
              <InputRightAddon>€</InputRightAddon>
            </InputGroup>
            <ErrorMessage error={formState.errors?.shareCapital} />
          </FormControl>
        </Stack>
        <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
          <FormControl isInvalid={!!formState.errors?.registrationNumber}>
            <HStack alignItems="flex-start" spacing="0">
              <FormLabel htmlFor="registrationNumber">{t('RegistrationNumber')}</FormLabel>
              <Tooltip hasArrow label={t('NonFrenchCompanyIdentificationNumber')} placement="top">
                <Icon as={InfoIcon} color="gray.500" />
              </Tooltip>
            </HStack>
            <Input
              id="registrationNumber"
              isDisabled={isDisabled}
              type="text"
              {...register('registrationNumber')}
            />
            <ErrorMessage error={formState.errors?.registrationNumber} />
          </FormControl>
          <FormControl isInvalid={!!formState.errors?.registrationEntity}>
            <HStack alignItems="flex-start" spacing="0">
              <FormLabel htmlFor="registrationEntity">{t('RegistrationEntityCity')}</FormLabel>
            </HStack>
            <Input
              id="registrationEntity"
              isDisabled={isDisabled}
              type="text"
              {...register('registrationEntity')}
            />
            <ErrorMessage error={formState.errors?.registrationEntity} />
          </FormControl>
        </Stack>
        <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
          <Text fontSize="lg" fontWeight="bold">
            {t('Residence')}
          </Text>
          <Stack spacing="4">
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.address?.line}>
                <FormLabel htmlFor="line">{t('Address', { nb: 1 })}</FormLabel>
                <Input
                  id="line"
                  isDisabled={isDisabled}
                  type="string"
                  {...register('address.line')}
                />
                <ErrorMessage error={formState.errors?.address?.line} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.address?.city}>
                <FormLabel htmlFor="city">{t('City')}</FormLabel>
                <Input
                  id="city"
                  isDisabled={isDisabled}
                  type="string"
                  {...register('address.city')}
                />
                <ErrorMessage error={formState.errors?.address?.city} />
              </FormControl>
            </Stack>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.address?.postalCode}>
                <FormLabel htmlFor="zipcode">{t('PostalCode', { nb: 1 })}</FormLabel>
                <Input
                  id="zipcode"
                  isDisabled={isDisabled}
                  type="string"
                  {...register('address.postalCode')}
                />
                <ErrorMessage error={formState.errors?.address?.postalCode} />
              </FormControl>
              <Controller
                control={control}
                name="address.country"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors.address?.country}>
                    <FormLabel htmlFor="country">{t('Country')}</FormLabel>
                    <Select
                      id="country"
                      isDisabled={isDisabled}
                      menuPlacement="auto"
                      options={alpha3CountryOptions}
                      {...field}
                    />
                    <ErrorMessage error={formState.errors.address?.country?.value} />
                  </FormControl>
                )}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
          <Text fontSize="lg" fontWeight="bold">
            {t('ClosestNaturalPersonRepresentingCompany')}
          </Text>
          <Stack spacing="4">
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.firstName}>
                <FormLabel htmlFor="firstName">{t('FirstName')}</FormLabel>
                <Input
                  id="firstName"
                  isDisabled={isDisabled}
                  type="string"
                  {...register('firstName')}
                />
                <ErrorMessage error={formState.errors?.firstName} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.lastName}>
                <FormLabel htmlFor="lastName">{t('LastName')}</FormLabel>
                <Input isDisabled={isDisabled} type="string" {...register('lastName')} />
                <ErrorMessage error={formState.errors?.lastName} />
              </FormControl>
            </Stack>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.position}>
                <FormLabel htmlFor="position">{t('RoleInCompany')}</FormLabel>
                <Input
                  id="position"
                  isDisabled={isDisabled}
                  type="string"
                  {...register('position')}
                />
                <ErrorMessage error={formState.errors?.position} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.email}>
                <FormLabel htmlFor="email">{t('Email', { nb: 1 })}</FormLabel>
                <Input id="email" isDisabled={isDisabled} type="text" {...register('email')} />
                <ErrorMessage error={formState.errors?.email} />
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
        <CompanyUbos targetCompanyId={targetCompanyId} onCancel={onCancel} onSubmit={onSubmit} />
      </Stack>
    </Stack>
  );
}
