import * as yup from 'yup';

import {
  phoneField,
  requiredBirthdateField,
  requiredSelectField,
  requiredStringField,
} from '@blockpulse3/data/shared';

import { IPersonForm } from './types';

export const schema = yup
  .object({
    firstName: requiredStringField,
    lastName: requiredStringField,
    birthdate: requiredBirthdateField,
    birthCity: requiredStringField,
    nationality: requiredSelectField,
    gender: requiredStringField,
    phone: phoneField.notRequired(),
  })
  .required() as yup.ObjectSchema<IPersonForm>;

export const personFormDefaultValues: IPersonForm = {
  firstName: '',
  lastName: '',
  birthdate: '',
  birthCity: '',
  gender: '',
  nationality: {
    label: '',
    value: '',
  },
  phone: '',
};
