import { createContext } from 'react';

import { noop } from '@blockpulse3/data/shared';

export type IStepFormContext = {
  setCancelHandler: (handler: () => void) => void;
  handleStepCancel: () => void;
  setSubmitHandler: (handler: () => void) => void;
  handleStepSubmit: () => void;
};

export const initStepFormContext: IStepFormContext = {
  setCancelHandler: noop,
  handleStepCancel: noop,
  setSubmitHandler: noop,
  handleStepSubmit: noop,
};

export const StepFormContext = createContext<IStepFormContext>(initStepFormContext);
