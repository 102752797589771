import { ThemeTypings } from '@chakra-ui/react';

import { GetTagsInput, useGetTagsQuery } from '@blockpulse3/graphql/hooks';
import { getNextColorTag } from '@blockpulse3/helpers';

/**
 * useGetNextColorTag.
 * Simple hook to return nextTagColor color tag from existing ones (from the company).
 *
 * @param {GetTagsInput} getTagsInput
 * @returns {ThemeTypings['colorSchemes']}
 */
export function useGetNextColorTag(getTagsInput: GetTagsInput): ThemeTypings['colorSchemes'] {
  const { data, loading, error } = useGetTagsQuery({ variables: { getTagsInput } });

  if (loading || error || !data) {
    return 'gray';
  }

  return getNextColorTag((data.getTags || []).length);
}
