import { Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled } from '@blockpulse3/data/shared';
import { LinkButton, PhoneBanner } from '@blockpulse3/ui/commons';

import {
  SubscriptionDocumentList,
  SubscriptionHeader,
  SubscriptionInformations,
  SubscriptionSteps,
} from './Subscription';

type Props = unknown;

/**
 * SubscriptionView.
 * View of a current active draft.
 *
 * @returns {JSX.Element}
 */
export function SubscriptionView(): JSX.Element {
  const t = useTranslations();

  const renderPhoneBanner = isFeatureEnabled('phoneBanner');

  return (
    <Stack spacing="6">
      <LinkButton label={t('BackToDashboard')} route={'../..'} />
      {renderPhoneBanner && <PhoneBanner />}
      <SubscriptionHeader />
      <Stack
        alignItems={{ base: 'auto', md: 'flex-start' }}
        direction={{ base: 'column', md: 'row' }}
        spacing="6"
      >
        <SubscriptionSteps minW="65%" />
        <SubscriptionDocumentList w="full" />
      </Stack>
      <SubscriptionInformations />
    </Stack>
  );
}

export type SubscriptionViewProps = Props;
