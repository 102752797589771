export enum CompanyDocumentType {
  DNC = 'DNC',
  DOMICILIATION = 'DOMICILIATION',
  SOUSCRIPTEURS = 'SOUSCRIPTEURS',
  STATUTS = 'STATUTS',
  POUVOIR_FORMALITES = 'POUVOIR_FORMALITES',
  DBE = 'DBE',
  FORMULAIRE_M0 = 'FORMULAIRE_M0',
  CAPITAL_SHARE_DEPOSIT_CERTIFICATE = 'CAPITAL_SHARE_DEPOSIT_CERTIFICATE',
  REGISTER_EXTRACT = 'REGISTER_EXTRACT',
  SHAREHOLDER_REGISTER_EXTRACT = 'SHAREHOLDER_REGISTER_EXTRACT',
  SHAREHOLDER_ARTICLES_OF_INCORPORATION = 'SHAREHOLDER_ARTICLES_OF_INCORPORATION',
  CAPTABLE = 'CAPTABLE',
  RMT = 'RMT',
  CIA = 'CIA',
  RMT_EXTRACT = 'RMT_EXTRACT',
  RMT_IMPORT = 'RMT_IMPORT',
  INDIVIDUAL_IDENTITIES_IMPORT = 'INDIVIDUAL_IDENTITIES_IMPORT',
  COMPANY_IDENTITIES_IMPORT = 'COMPANY_IDENTITIES_IMPORT',
  OPTION_GRANTS_IMPORT = 'OPTION_GRANTS_IMPORT',
  RESIDENCE_CERTIFICATE = 'RESIDENCE_CERTIFICATE',
  IBAN = 'IBAN',
}
