import { BrandTheme } from '@blockpulse3/ui/brand-theme';

export const colors = {
  primary: BrandTheme.color.primary,
  secondary: BrandTheme.color.secondary,
  gray: {
    900: '#111827',
    800: '#1F2937',
    700: '#374151',
    600: '#4B5563',
    500: '#6B7280',
    400: '#9CA3AF',
    300: '#D1D5DB',
    200: '#E5E7EB',
    100: '#F3F4F6',
    50: '#F9FAFB',
  },
  red: {
    900: '#7F1D1D',
    800: '#991B1B',
    700: '#B91C1C',
    600: '#DC2626',
    500: '#EF4444',
    400: '#F87171',
    300: '#FCA5A5',
    200: '#FECACA',
    100: '#FEE2E2',
    50: '#FEF2F2',
  },
  orange: {
    900: '#7C2D12',
    800: '#9A3412',
    700: '#C2410C',
    600: '#EA580C',
    500: '#F97316',
    400: '#FB923C',
    300: '#FDBA74',
    200: '#FED7AA',
    100: '#FFEDD5',
    50: '#FFF7ED',
  },
  yellow: {
    900: '#78350F',
    800: '#92400E',
    700: '#B45309',
    600: '#D97706',
    500: '#F59E0B',
    400: '#FBBF24',
    300: '#FCD34D',
    200: '#FDE68A',
    100: '#FEF3C7',
    50: '#FFFBEB',
  },
  green: {
    900: '#064E3B',
    800: '#065F46',
    700: '#047857',
    600: '#059669',
    500: '#10B981',
    400: '#34D399',
    300: '#6EE7B7',
    200: '#A7F3D0',
    100: '#D1FAE5',
    50: '#ECFDF5',
  },
  teal: {
    900: '#134E4A',
    800: '#115E59',
    700: '#0F766E',
    600: '#0D9488',
    500: '#14B8A6',
    400: '#2DD4BF',
    300: '#5EEAD4',
    200: '#99F6E4',
    100: '#CCFBF1',
    50: '#F0FDFA',
  },
  cyan: {
    900: '#164E63',
    800: '#155E75',
    700: '#0E7490',
    600: '#0891B2',
    500: '#06B6D4',
    400: '#22D3EE',
    300: '#67E8F9',
    200: '#A5F3FC',
    100: '#CFFAFE',
    50: '#ECFEFF',
  },
  lightblue: {
    900: '#0C4A6E',
    800: '#075985',
    700: '#0369A1',
    600: '#0284C7',
    500: '#0EA5E9',
    400: '#38BDF8',
    300: '#7DD3FC',
    200: '#BAE6FD',
    100: '#E0F2FE',
    50: '#F0F9FF',
  },
  blue: {
    900: '#1E3A8A',
    800: '#1E40AF',
    700: '#1D4ED8',
    600: '#2563EB',
    500: '#3B82F6',
    400: '#60A5FA',
    300: '#93C5FD',
    200: '#BFDBFE',
    100: '#DBEAFE',
    50: '#EFF6FF',
  },
  indigo: {
    900: '#312E81',
    800: '#3730A3',
    700: '#4338CA',
    600: '#4F46E5',
    500: '#6366F1',
    400: '#818CF8',
    300: '#A5B4FC',
    200: '#C7D2FE',
    100: '#E0E7FF',
    50: '#EEF2FF',
  },
  purple: {
    900: '#4C1D95',
    800: '#5B21B6',
    700: '#6D28D9',
    600: '#7C3AED',
    500: '#8B5CF6',
    400: '#A78BFA',
    300: '#C4B5FD',
    200: '#DDD6FE',
    100: '#EDE9FE',
    50: '#F5F3FF',
  },
  pink: {
    900: '#831843',
    800: '#9D174D',
    700: '#BE185D',
    600: '#DB2777',
    500: '#EC4899',
    400: '#F472B6',
    300: '#F9A8D4',
    200: '#FBCFE8',
    100: '#FCE7F3',
    50: '#FDF2F8',
  },
  rose: {
    900: '#881337',
    800: '#9F1239',
    700: '#BE123C',
    600: '#E11D48',
    500: '#F43F5E',
    400: '#FB7185',
    300: '#FDA4AF',
    200: '#FECDD3',
    100: '#FFE4E6',
    50: '#FFF1F2',
  },
  npurple: {
    50: '#FCE8F9',
    100: '#F7C0EF',
    200: '#F297E5',
    300: '#ED6EDB',
    400: '#E845D1',
    500: '#E31CC6',
    600: '#B5179F',
    700: '#881177',
    800: '#5B0B4F',
    900: '#2D0628',
  },
  nrose: {
    50: '#F9EBEF',
    100: '#EFC8D3',
    200: '#E4A5B7',
    300: '#D9819B',
    400: '#CF5E7E',
    500: '#C43B62',
    600: '#9D2F4E',
    700: '#76233B',
    800: '#4E1827',
    900: '#270C14',
  },
  nred: {
    50: '#FDE8E8',
    100: '#F8BEBE',
    200: '#F49494',
    300: '#F06B6B',
    400: '#EC4141',
    500: '#E81717',
    600: '#B91313',
    700: '#8B0E0E',
    800: '#5D0909',
    900: '#2E0505',
  },
  norange: {
    50: '#FDF2E8',
    100: '#F9DBBE',
    200: '#F5C493',
    300: '#F1AD69',
    400: '#EE963F',
    500: '#EA8015',
    600: '#BB6611',
    700: '#8C4D0D',
    800: '#5E3308',
    900: '#2F1904',
  },
  nyellow: {
    50: '#FFFDE5',
    100: '#FFFAB8',
    200: '#FFF78A',
    300: '#FFF35C',
    400: '#FFF02E',
    500: '#FFED00',
    600: '#CCBD00',
    700: '#998E00',
    800: '#665F00',
    900: '#332F00',
  },
  ngreen: {
    50: '#F2FBEA',
    100: '#DBF3C3',
    200: '#C4EB9D',
    300: '#ADE477',
    400: '#96DC51',
    500: '#80D52A',
    600: '#66AA22',
    700: '#4D8019',
    800: '#335511',
    900: '#1A2B08',
  },
  nmint: {
    50: '#E9FBF1',
    100: '#C3F4D9',
    200: '#9CEDC0',
    300: '#75E6A7',
    400: '#4EDF8E',
    500: '#27D876',
    600: '#1FAD5E',
    700: '#188147',
    800: '#10562F',
    900: '#082B18',
  },
  nteal: {
    50: '#E9FBFA',
    100: '#C2F5F0',
    200: '#9AEEE6',
    300: '#73E8DC',
    400: '#4CE1D2',
    500: '#24DBC8',
    600: '#1DAFA0',
    700: '#168378',
    800: '#0F5750',
    900: '#072C28',
  },
  nlightblue: {
    50: '#E5FDFF',
    100: '#B8F9FF',
    200: '#8AF5FF',
    300: '#5CF1FF',
    400: '#2EEEFF',
    500: '#00EAFF',
    600: '#00BBCC',
    700: '#008C99',
    800: '#005E66',
    900: '#002F33',
  },
  ncyan: {
    50: '#E5F9FF',
    100: '#B8EDFF',
    200: '#8AE2FF',
    300: '#5CD6FF',
    400: '#2ECBFF',
    500: '#00BFFF',
    600: '#0099CC',
    700: '#007399',
    800: '#004D66',
    900: '#002633',
  },
  nblue: {
    50: '#EBF0FA',
    100: '#C7D6F0',
    200: '#A3BCE6',
    300: '#7FA2DC',
    400: '#5B88D2',
    500: '#376DC8',
    600: '#2C57A0',
    700: '#214278',
    800: '#162C50',
    900: '#0B1628',
  },
  nviolet: {
    50: '#F2ECF9',
    100: '#DBC9ED',
    200: '#C4A7E2',
    300: '#AD85D6',
    400: '#9662CB',
    500: '#7F40BF',
    600: '#663399',
    700: '#4C2673',
    800: '#33194D',
    900: '#190D26',
  },
};
