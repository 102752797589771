import * as yup from 'yup';

import {
  requiredNumberField,
  requiredPastDateField,
  requiredSelectField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { formatCountry } from '@blockpulse3/helpers';

import { CompanyInformationsForm } from './types';

export const companyInformationsFormSchema = yup.object({
  name: requiredStringField,
  country: requiredSelectField,
  registrationNumber: requiredStringField,
  registrationEntity: requiredStringField,
  corporateForm: requiredStringField,
  creationDate: requiredPastDateField,
  shareCapital: requiredNumberField,
  capitalType: yup.object({
    label: yup.string(),
    value: yup.string(),
  }),
  nominalValue: yup.lazy((value) => (value === '' ? yup.string() : yup.number())),
  address: yup.object({
    line: requiredStringField,
    city: requiredStringField,
    postalCode: requiredStringField,
    country: requiredSelectField,
  }),
}) as yup.ObjectSchema<CompanyInformationsForm>;

export const companyInformationsFormDefaults: CompanyInformationsForm = {
  profilePicture: '',
  name: '',
  country: { label: formatCountry('FRA'), value: 'FRA' },
  registrationNumber: '',
  registrationEntity: '',
  corporateForm: '',
  creationDate: '',
  shareCapital: 0,
  address: {
    line: '',
    city: '',
    postalCode: '',
    country: { label: formatCountry('FRA'), value: 'FRA' },
  },
};
