import { Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetSpvQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberPercentage } from '@blockpulse3/helpers';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

/**
 * SummaryCompanyInfos.
 * Summary company infos for the SPV funnel.
 *
 * @returns {JSX.Element}
 */
export function SummaryCompanyInfos(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const { data, loading, error } = useGetSpvQuery({ variables: { companyId } });

  if (loading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (error || !data) {
    return <ErrorQueryCard width="full" />;
  }

  const { company } = data;
  const address = company?.address;

  return (
    <Stack spacing="4">
      <Text fontSize="xl" fontWeight="bold">
        {t('BasicInformation')}
      </Text>
      <Stack bg="gray.50" borderRadius="md" p="3" spacing="1">
        <Text>
          <Text as="span" fontWeight="bold" mr="2">
            {t('CompanyName')} :
          </Text>
          {company.name}
        </Text>
        <Text>
          <Text as="span" fontWeight="bold" mr="2">
            {t('Headquarters')} :
          </Text>
          {address?.formatted || ''}
        </Text>
        <Text>
          <Text as="span" fontWeight="bold" mr="2">
            {t('CorporateForm')} :
          </Text>
          {company.corporateForm || t('DefaultCorporateForm')}
        </Text>
        <Text>
          <Text as="span" fontWeight="bold" mr="2">
            {t('ShareCapital')} :
          </Text>
          {formatNumberCurrency(company?.shareCapital || 0)}
        </Text>
        <Text>
          <Text as="span" fontWeight="bold" mr="2">
            {t('PerformanceRemunerationCarriedInterest')} :
          </Text>
          {formatNumberPercentage(company?.carriedInterest || 0)}
        </Text>
        <Text>
          <Text as="span" fontWeight="bold" mr="2">
            {t('InvestmentCompanyName')} :
          </Text>
          {company?.target?.name || ''}
        </Text>
      </Stack>
    </Stack>
  );
}
