import { Button, Text, Tooltip } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { DocumentStatus } from '@blockpulse3/graphql/hooks';

import { ResponsiveModalProps } from '../ResponsiveModal';
import { PreviewDocumentModal } from './PreviewDocumentModal';

export const CONTENT_LOADING = Symbol('Symbol For Loading Content');
export const CONTENT_ERROR = Symbol('Symbol For Error On Loading');

type Props = {
  /* ** User role (ie. redirection in same tab) ** */
  isUserSigner: boolean;
  /* ** `onSign()` loading status, ie. signature loading ** */
  isSignatureLoading: boolean;
  /* ** Is Submit button disabled ** */
  isDisabled?: boolean;
  /* ** Modal title ** */
  title: string;
  /* ** Modal subtitle ** */
  subTitle?: string;
  /* ** Source of the document to preview ** */
  src?: string | symbol | null;
  /* ** Signing status of the document ** */
  status: DocumentStatus | null;
  /* ** If we add errorTitle the modal will display an Alert with the errorTitle text  ** */
  errorTitle?: string;
  /* ** Callback on sign button click ** */
  onSign?: () => void;
  /* ** Callback on cancel button click ** */
  onCancel?: () => void;
} & Omit<ResponsiveModalProps, 'children'>;

export function SignDocumentModal({
  isUserSigner,
  isSignatureLoading,
  isDisabled = false,
  status,
  errorTitle,
  onSign = noop,
  onCancel = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const signDocumentLabel = (): string => {
    if (isUserSigner) {
      return t(status ? 'ResumeSignature' : 'SignDocument');
    }
    return t(status ? 'SendReminder' : 'SendToSignatory');
  };

  const handleCancelClick = (): void => {
    onCancel();
  };

  const handleSignClick = (): void => {
    onSign();
  };

  const remindDisabled = isDisabled && !isUserSigner;

  return (
    <PreviewDocumentModal errorTitle={errorTitle} {...props}>
      <Button variant="secondary" onClick={handleCancelClick}>
        {t('Cancel')}
      </Button>
      {status !== DocumentStatus.SIGNED && status !== DocumentStatus.CANCELLED && (
        <Tooltip
          hasArrow
          isDisabled={!remindDisabled}
          label={<Text textAlign="center">{t('SignatoryReceivedMailInLastHour')}</Text>}
          placement="top"
        >
          <Button
            data-cy="sign-document"
            isDisabled={(isDisabled && !isUserSigner) || !!errorTitle}
            isLoading={isSignatureLoading}
            loadingText={t('DocumentGenerationInProgress')}
            onClick={handleSignClick}
          >
            {signDocumentLabel()}
          </Button>
        </Tooltip>
      )}
    </PreviewDocumentModal>
  );
}

export type SignDocumentModalProps = Props;
