import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import { useStepFormContext } from '../provider';

type Props = {
  title: string;
  subTitle?: string;
  children: (JSX.Element | null | false)[];
};

export function OnboardingStep({ title, subTitle, children }: Props): JSX.Element {
  const { handleStepSubmit, handleStepCancel } = useStepFormContext();

  return (
    <Card variant="divider-top" width="full">
      <CardHeader as={Stack} spacing="2">
        <Heading size="lg">{title}</Heading>
        {subTitle && (
          <Text color="gray.600" fontSize="lg">
            {subTitle}
          </Text>
        )}
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        {React.Children.map(
          children,
          (child) => child && !['cancel', 'submit'].includes(child.key?.toString() || '') && child,
        )}
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        {React.Children.map(children, (child) => {
          if (!child || !['cancel', 'submit'].includes(child.key?.toString() || '')) return null;
          if (child.key === 'submit') {
            return React.cloneElement(child, {
              onClick: handleStepSubmit,
            });
          }
          if (child.key === 'cancel') {
            return React.cloneElement(child, {
              onClick: handleStepCancel,
            });
          }
          return null;
        })}
      </CardFooter>
    </Card>
  );
}

export type OnboardingStepProps = Props;
