import { Center, Spinner } from '@chakra-ui/react';

type Props = unknown;

/**
 * LoadingPage.
 * Simple component rendering a centered spinner.
 *
 * @returns {JSX.Element}
 */
export function LoadingPage(): JSX.Element {
  return (
    <Center height="100vh">
      <Spinner color="secondary" size="xl" speed="0.6s" thickness="4px" />
    </Center>
  );
}

export type LoadingPageProps = Props;
