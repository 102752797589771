import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { AssetType } from '@blockpulse3/graphql/hooks';

import { bondAssetTypeOptions } from '../../BondCreateModal/schema';
import { optionAssetTypeOptions } from '../../OptionCreateModal/schema';
import { stockAssetTypeOptions } from '../../StockCreateModal/schema';

type Props = {
  onCreateBond: (assetType: AssetType) => void;
  onCreateStock: (assetType: AssetType) => void;
  onCreateOption: (assetType: AssetType) => void;
  assetCount: number;
};

export function RepatriationShareTransferRegisterAssetsTableControls({
  onCreateBond,
  onCreateStock,
  onCreateOption,
  assetCount,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nAssetType = useTranslations('AssetTypeValues');

  const handleCreateStock = (assetType: AssetType): void => {
    onCreateStock(assetType);
  };

  const handleCreateOption = (assetType: AssetType): void => {
    onCreateOption(assetType);
  };

  const handleCreateBond = (assetType: AssetType): void => {
    onCreateBond(assetType);
  };

  return (
    <Stack
      alignItems="flex-end"
      direction={{ base: 'column', md: 'row' }}
      justifyContent="space-between"
      spacing="4"
    >
      <Heading size="md">{t('AssetsList')}</Heading>
      <HStack spacing="4">
        <Menu direction="rtl">
          <MenuButton
            as={Button}
            leftIcon={<Icon as={PlusIcon} boxSize="5" />}
            rightIcon={<ChevronDownIcon />}
          >
            {t('AddAsset')}
          </MenuButton>
          <MenuList>
            <MenuGroup title={t('AddStock')}>
              {stockAssetTypeOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  px="4"
                  isDisabled={
                    AssetType[option.value] !== AssetType.ORDINARY_SHARE && assetCount === 0
                  }
                  onClick={(): void => handleCreateStock(AssetType[option.value])}
                >
                  {i18nAssetType(option.label, { nb: 0 })}
                </MenuItem>
              ))}
            </MenuGroup>
            <MenuGroup title={t('AddOption')}>
              {optionAssetTypeOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  isDisabled={assetCount === 0}
                  px="4"
                  onClick={(): void => handleCreateOption(AssetType[option.value])}
                >
                  {i18nAssetType(option.label, { nb: 0 })}
                </MenuItem>
              ))}
            </MenuGroup>
            <MenuGroup title={t('AddBond')}>
              {bondAssetTypeOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  isDisabled={assetCount === 0}
                  px="4"
                  onClick={(): void => handleCreateBond(AssetType[option.value])}
                >
                  {i18nAssetType(option.label, { nb: 0 })}
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuList>
        </Menu>
      </HStack>
    </Stack>
  );
}

export type RepatriationShareTransferRegisterAssetsTableControlsProps = Props;
