import { InfoIcon } from '@chakra-ui/icons';
import {
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  ListItem,
  Stack,
  Switch,
  Text,
  Tooltip,
  UnorderedList,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { IndividualDocumentType, useGetIndividualDocumentsQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryModal } from '@blockpulse3/ui/commons';
import { useManagedIndividual } from '@blockpulse3/web-client/auth';

import { kycDefaultValues, kycSchema } from '../schema';
import { IVerificationUserForm } from '../types';
import { UploadIdentityDocument } from './UploadIdentityDocument';

type Props = {
  onSubmit: () => void;
  setIsValidable: React.Dispatch<React.SetStateAction<boolean>>;
};

export function DocumentUserVerification({ onSubmit = noop, setIsValidable }: Props): JSX.Element {
  const t = useTranslations();

  const { individual } = useManagedIndividual();
  const individualIdentityId = individual?.id || '';

  // Fetch individual documents
  const individualDocumentsReq = useGetIndividualDocumentsQuery({
    variables: { individualIdentityId },
    skip: !individualIdentityId,
  });

  // Retrieve identity documents
  const individualDocuments = individualDocumentsReq.data?.getIndividualDocuments || [];
  const identityDocument = individualDocuments.find(
    (doc) => doc.document.type === IndividualDocumentType.IDENTITY_DOCUMENT,
  );
  const identityDocumentBack = individualDocuments.find(
    (doc) => doc.document.type === IndividualDocumentType.IDENTITY_DOCUMENT_BACK,
  );
  const identityDocumentSecondary = individualDocuments.find(
    (doc) => doc.document.type === IndividualDocumentType.IDENTITY_DOCUMENT_SECONDARY,
  );
  const identityDocumentSecondaryBack = individualDocuments.find(
    (doc) => doc.document.type === IndividualDocumentType.IDENTITY_DOCUMENT_SECONDARY_BACK,
  );

  const formMethods = useForm<IVerificationUserForm>({
    defaultValues: { ...kycDefaultValues },
    resolver: yupResolver(kycSchema),
  });
  const { setValue, watch, register, handleSubmit } = formMethods;
  const addIdentityDocumentBackFile = watch('addIdentityDocumentBackFile');
  const addIdentityDocumentSecondaryBackFile = watch('addIdentityDocumentSecondaryBackFile');

  // Set form as validable when all required files are uploaded
  useEffect(() => {
    const firstDocumentBack = !addIdentityDocumentBackFile || !!identityDocumentBack;
    const secondDocumentBack =
      !addIdentityDocumentSecondaryBackFile || !!identityDocumentSecondaryBack;
    const requiredFilesUploaded =
      identityDocument && identityDocumentSecondary && firstDocumentBack && secondDocumentBack;

    setIsValidable(!!requiredFilesUploaded);
  }, [
    identityDocument,
    identityDocumentSecondary,
    identityDocumentBack,
    identityDocumentSecondaryBack,
    addIdentityDocumentBackFile,
    addIdentityDocumentSecondaryBackFile,
  ]);

  // Init form with existing documents
  useEffect(() => {
    if (identityDocument) {
      setValue('identityDocumentFile', identityDocument?.document.id || '');
    }
    if (identityDocumentBack) {
      setValue('identityDocumentBackFile', identityDocumentBack?.document.id || '');
      setValue('addIdentityDocumentBackFile', true);
    }
    if (identityDocumentSecondary) {
      setValue('identityDocumentSecondaryFile', identityDocumentSecondary?.document.id || '');
    }
    if (identityDocumentSecondaryBack) {
      setValue(
        'identityDocumentSecondaryBackFile',
        identityDocumentSecondaryBack?.document.id || '',
      );
      setValue('addIdentityDocumentSecondaryBackFile', true);
    }
  }, [
    identityDocument,
    identityDocumentBack,
    identityDocumentSecondary,
    identityDocumentSecondaryBack,
  ]);

  if (individualDocumentsReq.error) {
    return <ErrorQueryModal h="380px" />;
  }

  const handleFormSubmit = (): void => {
    onSubmit();
  };

  return (
    <Stack spacing="2">
      <FormProvider {...formMethods}>
        <form id="document-upload" onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack spacing="4">
            <HStack>
              <Text fontWeight="500">{t('VerifyIdentityDownloadDocs')}</Text>
              <Tooltip
                hasArrow
                placement="top"
                label={
                  <UnorderedList>
                    <ListItem>{t('FirstPiecePassportOrID')}</ListItem>
                    <ListItem>{t('SecondPiece')}</ListItem>
                  </UnorderedList>
                }
              >
                <Icon as={InfoIcon} color="gray.600" />
              </Tooltip>
            </HStack>

            {/* 1st document front */}
            <UploadIdentityDocument
              documentInputLabel={t('IDCard1FrontBack')}
              documentInputName="identityDocumentFile"
              documentType={IndividualDocumentType.IDENTITY_DOCUMENT}
            />

            {/* 1st document back */}
            <FormControl alignItems="center" display="flex">
              <Switch
                disabled={!!identityDocumentBack}
                isChecked={addIdentityDocumentBackFile}
                {...register('addIdentityDocumentBackFile')}
                id="addIdentityDocumentBackFile"
              />
              <FormLabel
                _hover={{ cursor: 'pointer' }}
                fontSize="sm"
                fontWeight="400"
                htmlFor="addIdentityDocumentBackFile"
                mb="0"
                ml="3"
              >
                {t('AddBackIdentitySecondaryDocument')}
              </FormLabel>
            </FormControl>
            {addIdentityDocumentBackFile && (
              <>
                <UploadIdentityDocument
                  documentInputLabel={t('IDCard1Back')}
                  documentInputName="identityDocumentBackFile"
                  documentType={IndividualDocumentType.IDENTITY_DOCUMENT_BACK}
                />
                <Divider my={3} />
              </>
            )}

            {/* 2nd document front */}
            <UploadIdentityDocument
              documentInputLabel={t('IDCard2FrontBack')}
              documentInputName="identityDocumentSecondaryFile"
              documentType={IndividualDocumentType.IDENTITY_DOCUMENT_SECONDARY}
            />

            {/* 2nd document back */}
            <FormControl alignItems="center" display="flex">
              <Switch
                defaultChecked={addIdentityDocumentSecondaryBackFile}
                disabled={!!identityDocumentSecondaryBack}
                {...register('addIdentityDocumentSecondaryBackFile')}
                id="addIdentityDocumentSecondaryBackFile"
              />
              <FormLabel
                _hover={{ cursor: 'pointer' }}
                fontSize="sm"
                fontWeight="400"
                htmlFor="addIdentityDocumentSecondaryBackFile"
                mb="0"
                ml="3"
              >
                {t('AddBackIdentitySecondaryDocument')}
              </FormLabel>
            </FormControl>
            {addIdentityDocumentSecondaryBackFile && (
              <UploadIdentityDocument
                documentInputLabel={t('IDCard2Back')}
                documentInputName="identityDocumentSecondaryBackFile"
                documentType={IndividualDocumentType.IDENTITY_DOCUMENT_SECONDARY_BACK}
              />
            )}
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
}
