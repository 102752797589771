import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import {
  GetSubscriptionInfosFragment,
  MergedDocumentSignatureStep,
  OperationDocumentType,
  OperationStatus,
  SubscriptionStepStatus,
  SubscriptionStepType,
} from '@blockpulse3/graphql/hooks';

import { SubscriptionDocumentsSelectProps } from './type';

export const useGetSubscriptionDocumentsOptions = (): SubscriptionDocumentsSelectProps[] => {
  const i18nDocumentValues = useTranslations('DocumentValues');

  return [
    {
      label: i18nDocumentValues('IR_PME_CERTIFICATE'),
      value: 'irPmeCertificate',
      type: OperationDocumentType.IR_PME_CERTIFICATE,
    },
    {
      label: i18nDocumentValues('PEA_CERTIFICATE'),
      value: 'peaCertificate',
      type: OperationDocumentType.PEA_CERTIFICATE,
    },
    {
      label: i18nDocumentValues('PEA_PME_CERTIFICATE'),
      value: 'peaPmeCertificate',
      type: OperationDocumentType.PEA_PME_CERTIFICATE,
    },
    {
      label: i18nDocumentValues('SUBSCRIPTION'),
      value: 'subscription',
      type: OperationDocumentType.SUBSCRIPTION,
    },
    {
      label: i18nDocumentValues('OWNERSHIP_CERTIFICATE'),
      value: 'ownershipCertificate',
      type: OperationDocumentType.OWNERSHIP_CERTIFICATE,
    },
    {
      label: i18nDocumentValues('FUNDS_SOURCE_CERTIFICATE'),
      value: 'fundsSourceCertificate',
      type: OperationDocumentType.FUNDS_SOURCE_CERTIFICATE,
    },
    {
      label: i18nDocumentValues('CUSTOM_FILE'),
      value: 'customFile',
      type: OperationDocumentType.CUSTOM_FILE,
    },
  ];
};

export const useGetDocumentsComplianceOfficerOptions = (
  subscription?: GetSubscriptionInfosFragment,
): SubscriptionDocumentsSelectProps[] => {
  const i18nDocumentValues = useTranslations('DocumentValues');

  const documentOptions: SubscriptionDocumentsSelectProps[] = [];
  if (
    subscription &&
    ![OperationStatus.CLOSED, OperationStatus.FINALIZED].includes(subscription.operation?.status)
  ) {
    const subscriptionDocumentType =
      subscription.operation?.subscriptionDocumentType || OperationDocumentType.SUBSCRIPTION;
    const subscriptionDocumentStep = subscription.data?.mergedSteps?.find(
      (step) =>
        step.type === SubscriptionStepType.DOCUMENT_SIGNATURE &&
        (step as MergedDocumentSignatureStep).documentType === subscriptionDocumentType,
    );
    if (
      subscriptionDocumentStep &&
      subscriptionDocumentStep.status === SubscriptionStepStatus.NONE
    ) {
      documentOptions.push({
        label: i18nDocumentValues(subscriptionDocumentType as IntlDocumentValues),
        value: 'subscription',
        type: subscriptionDocumentType,
      });
    }
  }

  documentOptions.push(
    {
      label: i18nDocumentValues('FUNDS_SOURCE_CERTIFICATE'),
      value: 'fundsSourceCertificate',
      type: OperationDocumentType.FUNDS_SOURCE_CERTIFICATE,
    },
    {
      label: i18nDocumentValues('CUSTOM_FILE'),
      value: 'customFile',
      type: OperationDocumentType.CUSTOM_FILE,
    },
  );

  return documentOptions;
};

export const useOnlyCustomFile = (): SubscriptionDocumentsSelectProps[] => {
  const i18nDocumentValues = useTranslations('DocumentValues');

  return [
    {
      label: i18nDocumentValues('CUSTOM_FILE'),
      value: 'customFile',
      type: OperationDocumentType.CUSTOM_FILE,
    },
  ];
};
