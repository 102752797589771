import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { BigNumber } from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import { AssetType, useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, getNearestMultiple } from '@blockpulse3/helpers';
import { ErrorMessage, ErrorQueryCard, Input } from '@blockpulse3/ui/commons';

import { getOperationParameters } from '../../../utils';
import { schema } from './schema';
import { IOperationNaturalSubscriberInformations } from './types';

type Props = {
  defaultValues: IOperationNaturalSubscriberInformations;
  amountRequired: boolean;
  amountRangeRequired: boolean;
  feesRequired: boolean;
  modalSubmitText: string;
  onSubmit: (data: IOperationNaturalSubscriberInformations) => void;
};

export function NaturalSubscriberInformationsForm({
  defaultValues,
  amountRequired,
  amountRangeRequired,
  feesRequired,
  modalSubmitText,
  onSubmit,
}: Props): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  /* ** Track amount touched state ** */
  const [isAmountTouched, setIsAmountTouched] = useState<boolean>(false);
  const [hardCap, setHardCap] = useState<number>(0);

  const { data, loading, error } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });
  const operation = data?.operation;

  const { register, formState, handleSubmit, watch, setError } =
    useForm<IOperationNaturalSubscriberInformations>({
      defaultValues,
      resolver: yupResolver(schema),
      context: { hardCap, amountRequired, amountRangeRequired, feesRequired },
    });

  const parameters = useMemo(() => getOperationParameters(operation), [operation]);

  useEffect(() => {
    setHardCap(parameters.hardCap || 0);
  }, [parameters]);

  if (loading) {
    //TODO: remove spinner
    return <Spinner />;
  }

  if (error) {
    return <ErrorQueryCard />;
  }

  /* ** Compute amount to a multiple of the sharePrice ** */
  const amount = new BigNumber(watch('amount'));
  const fixedAmount = getNearestMultiple(amount, parameters.sharePrice);
  const isFixed = !amount.isEqualTo(fixedAmount) && !fixedAmount.isNaN();
  const isNull = fixedAmount.isZero() || fixedAmount.isNaN();
  const showAlert = (isFixed || isNull) && isAmountTouched;

  const handleFormSubmit: SubmitHandler<IOperationNaturalSubscriberInformations> = (data): void => {
    /* ** Check for a new condition on submit. Forbid a 0 value rounded amount ** */
    const nbAmount = fixedAmount.toNumber();
    if (amountRequired && nbAmount === 0) {
      setError('amount', { type: 'custom', message: FormErrors.PositiveFormat });
    } else {
      onSubmit({ ...data, amount: nbAmount });
    }
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLFormElement>): void => {
    /* ** Avoid onBlur formState.touchedFields update ** */
    if (e.target.id === 'amount') {
      setIsAmountTouched(true);
    }
  };

  const fixedAmountLabel =
    operation?.assetType === AssetType.BOND
      ? t('SubscriptionAmountRoundedBond', { fixedAmount: formatNumberCurrency(fixedAmount) })
      : t('SubscriptionAmountRounded', { fixedAmount: formatNumberCurrency(fixedAmount) });

  return (
    <form onChange={handleFormChange} onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing="8">
        <Stack spacing="4">
          <FormControl isInvalid={!!formState.errors.email}>
            <FormLabel htmlFor="email">{t('Email', { nb: 1 })}</FormLabel>
            <Input id="email" type="string" {...register('email')} />
            <ErrorMessage error={formState.errors.email} />
          </FormControl>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors.firstName}>
              <FormLabel htmlFor="firstName">{t('FirstName')}</FormLabel>
              <Input id="firstName" type="string" {...register('firstName')} />
              <ErrorMessage error={formState.errors.firstName} />
            </FormControl>
            <FormControl isInvalid={!!formState.errors.lastName}>
              <FormLabel htmlFor="lastName">{t('LastName')}</FormLabel>
              <Input id="lastName" type="string" {...register('lastName')} />
              <ErrorMessage error={formState.errors.lastName} />
            </FormControl>
          </Stack>
          {amountRangeRequired && (
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors.minimalAmount}>
                <FormLabel htmlFor="minimalAmount">{t('MinimumAmount')}</FormLabel>
                <InputGroup>
                  <Input
                    id="minimalAmount"
                    step="0.01"
                    type="number"
                    {...register('minimalAmount', { valueAsNumber: true })}
                  />
                  <InputRightAddon>€</InputRightAddon>
                </InputGroup>
                <Text color="gray.500" fontSize="xs">
                  {t('Default')}{' '}
                  {formatNumberCurrency(parameters.minimalAmount || parameters.sharePrice)}
                </Text>
                <ErrorMessage error={formState.errors.minimalAmount} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors.maximalAmount}>
                <FormLabel htmlFor="maximalAmount">{t('MaximumAmount')}</FormLabel>
                <InputGroup>
                  <Input
                    id="maximalAmount"
                    step="0.01"
                    type="number"
                    {...register('maximalAmount', { valueAsNumber: true })}
                  />
                  <InputRightAddon>€</InputRightAddon>
                </InputGroup>
                <Text color="gray.500" fontSize="xs">
                  {t('Default')}{' '}
                  {parameters.maximalAmount || parameters.hardCap
                    ? formatNumberCurrency(parameters.maximalAmount || parameters.hardCap || 0)
                    : t('None')}
                </Text>
                <ErrorMessage error={formState.errors.maximalAmount} />
              </FormControl>
            </Stack>
          )}
          {amountRequired && (
            <FormControl isInvalid={!!formState.errors.amount}>
              <FormLabel htmlFor="amount">{t('SubscriptionAmountInEuros')}</FormLabel>
              <InputGroup>
                <Input id="amount" step="0.01" type="number" {...register('amount')} />
                <InputRightAddon>€</InputRightAddon>
              </InputGroup>
              <ErrorMessage error={formState.errors.amount} />
              {showAlert && (
                <Alert mt="3" status="warning">
                  <AlertIcon as={ExclamationIcon} />
                  <Stack spacing="1">
                    <AlertTitle>{t('CorrectedAmount')}</AlertTitle>
                    <AlertDescription>
                      {isNull ? t('SubscriptionAmountGreaterThanZero') : fixedAmountLabel}
                    </AlertDescription>
                  </Stack>
                </Alert>
              )}
            </FormControl>
          )}
          {feesRequired && (
            <FormControl isInvalid={!!formState.errors.admissionFees}>
              <FormLabel htmlFor="admissionFees">{t('SubscriptionPercentageFees')}</FormLabel>
              <InputGroup>
                <Input
                  id="admissionFees"
                  step="0.01"
                  type="number"
                  {...register('admissionFees')}
                />
                <InputRightAddon>%</InputRightAddon>
              </InputGroup>
              <ErrorMessage error={formState.errors.admissionFees} />
            </FormControl>
          )}
        </Stack>
        <Button data-cy="validate-subscriber" type="submit" w="full">
          {modalSubmitText}
        </Button>
      </Stack>
    </form>
  );
}

export type NaturalSubscriberInformationsFormProps = Props;
