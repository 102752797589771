import { InfoIcon } from '@chakra-ui/icons';
import { FormControl, FormLabel, HStack, Icon, Stack, Text, Tooltip } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { IntlRevocationMajorityValues } from '@blockpulse3/data/locales/types';
import { alpha3CountryOptions } from '@blockpulse3/helpers';
import { ErrorMessage, Input } from '@blockpulse3/ui/commons';

import { revocationMajorityOptions } from './constants';
import { companyInformationSchema } from './schema';
import { SPVInformationsForm } from './types';

type Props = {
  /* ** Default form values ** */
  defaultValues?: SPVInformationsForm;
  /* ** Callback on form submit ** */
  onSubmit: (data: SPVInformationsForm) => void;
};

/**
 * SPVInformations.
 * Form for the legal informations of a SPV.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SPVInformations({ defaultValues, onSubmit }: Props): JSX.Element {
  const t = useTranslations();
  const i18nRevocationMajority = useTranslations('RevocationMajorityValues');

  const { register, control, formState, handleSubmit, reset } = useForm<SPVInformationsForm>({
    defaultValues,
    resolver: yupResolver(companyInformationSchema),
  });

  const handleFormSubmit: SubmitHandler<SPVInformationsForm> = (data) => {
    onSubmit(data);
  };

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  return (
    <form id="spv-informations" onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing="4">
        <FormControl isInvalid={!!formState.errors?.name}>
          <FormLabel htmlFor="name">{t('SPVName')}</FormLabel>
          <Input id="name" type="text" {...register('name')} />
          <ErrorMessage error={formState.errors.name} />
        </FormControl>
        <Stack bg="gray.50" borderRadius="md" p="3" spacing="4">
          <Text fontSize="lg" fontWeight="bold">
            {t('Domiciliation')}
          </Text>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.address?.line}>
              <FormLabel htmlFor="line">{t('Address', { nb: 1 })}</FormLabel>
              <Input id="line" type="string" {...register('address.line')} />
              <ErrorMessage error={formState.errors.address?.line} />
            </FormControl>
            <FormControl isInvalid={!!formState.errors?.address?.city}>
              <FormLabel htmlFor="city">{t('City')}</FormLabel>
              <Input id="city" type="string" {...register('address.city')} />
              <ErrorMessage error={formState.errors.address?.city} />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.address?.postalCode}>
              <FormLabel htmlFor="zipcode">{t('PostalCode', { nb: 1 })}</FormLabel>
              <Input id="zipcode" type="string" {...register('address.postalCode')} />
              <ErrorMessage error={formState.errors.address?.postalCode} />
            </FormControl>
            <Controller
              control={control}
              name="address.country"
              render={({ field }): JSX.Element => (
                <FormControl isInvalid={!!formState.errors.address?.country}>
                  <FormLabel htmlFor="country">{t('Country')}</FormLabel>
                  <Select
                    id="country"
                    menuPlacement="auto"
                    options={alpha3CountryOptions}
                    placeholder=""
                    {...field}
                  />
                  <ErrorMessage error={formState.errors.address?.country?.value} />
                </FormControl>
              )}
            />
          </Stack>
        </Stack>
        <Controller
          control={control}
          name="revocationMajority"
          render={({ field: statusField }): JSX.Element => (
            <FormControl isInvalid={!!formState.errors?.revocationMajority} maxWidth="full">
              <FormLabel htmlFor="revocationMajorityData">{t('RevocationMode')}</FormLabel>
              <Select
                id="revocationMajority"
                isSearchable={false}
                menuPlacement="auto"
                options={revocationMajorityOptions}
                placeholder=""
                getOptionLabel={(option): string =>
                  option.label
                    ? i18nRevocationMajority(option.label as IntlRevocationMajorityValues)
                    : ''
                }
                {...statusField}
              />
              <ErrorMessage error={formState.errors?.revocationMajority?.value} />
            </FormControl>
          )}
        />
        <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
          <Stack spacing="4">
            <Text fontSize="lg" fontWeight="bold">
              {t('InvestmentVehicleDesiredCompany')}
            </Text>
            <FormControl isInvalid={!!formState.errors?.target?.name}>
              <FormLabel htmlFor="targetName">{t('TargetName')}</FormLabel>
              <Input id="targetName" type="text" {...register('target.name')} />
              <ErrorMessage error={formState.errors.target?.name} />
            </FormControl>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.target?.registrationNumber}>
                <HStack alignItems="flex-start" spacing="0">
                  <FormLabel htmlFor="registrationNumber">{t('RegistrationNumber')}</FormLabel>
                  <Tooltip
                    hasArrow
                    label={t('NonFrenchCompanyIdentificationNumber')}
                    placement="top"
                  >
                    <Icon as={InfoIcon} color="gray.500" />
                  </Tooltip>
                </HStack>
                <Input
                  id="registrationNumber"
                  type="text"
                  {...register('target.registrationNumber')}
                />
                <ErrorMessage error={formState.errors.target?.registrationNumber} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.target?.registrationEntity}>
                <FormLabel htmlFor="registrationEntity">{t('RegistrationEntityCity')}</FormLabel>
                <Input
                  id="registrationEntity"
                  type="text"
                  {...register('target.registrationEntity')}
                />
                <ErrorMessage error={formState.errors.target?.registrationEntity} />
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}

export type LegalInformationsFormProps = Props;
