import { useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Exceptions, routes } from '@blockpulse3/data/shared';
import { useCreateUserMutation, useVerifyEmailMutation } from '@blockpulse3/graphql/hooks';
import { CheckEmail, useErrorToast } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { ISignUpForm, SignUpForm, signUpFormDefaults } from '../SignUpForm';

type Props = unknown;

/**
 * InvitationSignUpView.
 * SignUpForm with invite logic.
 *
 * @returns {JSX.Element}
 */
export function InvitationSignUpView(): JSX.Element {
  const t = useTranslations();

  const { refetch } = useAuthUser();
  const [searchParams] = useSearchParams();

  const [verifyEmail] = useVerifyEmailMutation();
  const [createUser, { loading }] = useCreateUserMutation();

  const [shouldVerifyEmail, setShouldVerifyEmail] = useState<boolean>(false);

  const redirectEmail = searchParams.get('email') || '';
  const redirectToken = searchParams.get('token') || '';
  const isEmailDisabled = !!redirectEmail;

  const errorToast = useErrorToast();

  const handleSubmit = (data: ISignUpForm): void => {
    createUser({
      variables: {
        createUserInput: {
          firstName: '',
          lastName: '',
          email: isEmailDisabled ? redirectEmail : data.email,
          password: data.password,
          timezone: data.timezone,
        },
      },
      onCompleted: (res) => {
        verifyEmail({
          variables: { verifyEmailInput: { email: data.email, token: redirectToken } },
          onCompleted: (res) => {
            if (res?.verifyEmail?.access_token) {
              localStorage.setItem('token', res.verifyEmail.access_token);
              refetch();
            } else {
              setShouldVerifyEmail(true);
            }
          },
          onError: (err: unknown) => {
            errorToast({ title: t('AccountCreationError') });
          },
        });
      },
      onError: (err: unknown) => {
        const error = err as Error;
        if (error.message === Exceptions.AlreadyUsedEmail) {
          errorToast({ title: t('FieldErrorEmailAlreadyUsed') });
        } else {
          errorToast({ title: t('AccountCreationError') });
        }
      },
    });
  };

  if (shouldVerifyEmail) {
    return <CheckEmail email={redirectEmail} />;
  }

  if (!redirectEmail || !redirectToken) {
    /* ** Redirect to default signup if no parameters ** */
    return <Navigate to={routes.signup.href} />;
  }

  return (
    <SignUpForm
      defaultValues={{ ...signUpFormDefaults, email: redirectEmail }}
      isDisabled={isEmailDisabled}
      loading={loading}
      onSubmit={handleSubmit}
    />
  );
}

export type InvitationSignUpViewProps = Props;
