import { Box, Button, Input } from '@chakra-ui/react';
import { useRef } from 'react';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';

type Props<T extends FieldValues> = {
  onUpload?: (files: FileList) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
} & UseControllerProps<T>;

export function InputImage<T extends FieldValues>({
  onUpload = noop,
  ...props
}: Props<T>): JSX.Element {
  const t = useTranslations();

  const inputRef = useRef<HTMLInputElement>(null);

  const { isDisabled, isLoading, ...controllerProps } = props;
  const {
    field: { ref, onChange, ...fieldRest },
  } = useController(controllerProps);

  const handleButtonClick = (): void => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e): void => {
    if (e.target.files) {
      onUpload(e.target.files);
      onChange(e);
    }
  };

  return (
    <Box>
      <Input
        display="none"
        {...fieldRest}
        accept=".jpg,.jpeg,.png,.webp"
        placeholder={fieldRest.name}
        ref={inputRef}
        type="file"
        onChange={handleInputChange}
      />
      <Button
        isDisabled={isDisabled}
        isLoading={isLoading}
        variant="secondary"
        onClick={handleButtonClick}
      >
        {t('Modify')}
      </Button>
    </Box>
  );
}
