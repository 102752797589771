/* ** documents type from draft query ** */
import {
  CompanyDocumentInfosFragment,
  CompanyDocumentType,
  CreditTransferStatus,
  GetBankCapitalAccountByCompanyQuery,
  GetBankIdentitiesByCompanyQuery,
  IdentityVerificationStatus,
  IndividualIdentityInfosFragment,
  SpvInfosFragment,
  SpvStatus,
} from '@blockpulse3/graphql/hooks';
import { IBadge } from '@blockpulse3/ui/ui-hooks';

type SPVDocumentRecord = Record<CompanyDocumentType | string, CompanyDocumentInfosFragment>;

/**
 * getDraftCurrentStep.
 * Return current step number in spv registry details.
 *
 * @param {SpvStatus | undefined | null} status
 * @returns {number}
 */
export function getDraftCurrentStep(status: SpvStatus | undefined | null): number {
  switch (status) {
    case SpvStatus.SIGNED:
      return 2;
    case SpvStatus.VERIFIED:
      return 3;
    case SpvStatus.CREATING:
      return 4;
    default:
      return 1;
  }
}

export function isDraftEditable(
  company: SpvInfosFragment,
  individual?: IndividualIdentityInfosFragment | null,
  isManagingSpace = false,
): boolean {
  const spvCreator = company.creator?.individualIdentity;
  if (!isManagingSpace && spvCreator?.id !== individual?.id) {
    return false;
  }
  if (company?.spvStatus) {
    return [SpvStatus.PAID, SpvStatus.EDITING].includes(company.spvStatus);
  }
  return false;
}

/**
 * getIdentityBadge.
 * Return IBadge for the identity accordion section.
 *
 * @param {SpvStatus | null | undefined} spvStatus
 * @param {GetBankIdentitiesByCompanyQuery['getBankIdentitiesByCompany']} bankIdentities
 */
export function getIdentityBadge(
  spvStatus: SpvStatus | null | undefined,
  bankIdentities: GetBankIdentitiesByCompanyQuery['getBankIdentitiesByCompany'],
): IBadge {
  const noBankIdentities = !bankIdentities.length;
  const verificationPending = bankIdentities.some(
    (el) => el?.verificationStatus === IdentityVerificationStatus.PENDING,
  );

  if (noBankIdentities || !spvStatus) {
    return {
      color: 'gray',
      label: 'InProgress',
    };
  }
  if (spvStatus && [SpvStatus.VERIFIED, SpvStatus.CREATING].includes(spvStatus)) {
    return {
      color: 'green',
      label: 'Verified',
    };
  }
  if (verificationPending) {
    return {
      color: 'yellow',
      label: 'VerificationInProgress',
    };
  }
  return {
    color: 'gray',
    label: 'NotVerified',
  };
}

/**
 * getVerificationStatusBadge.
 * Return IBadge for the identity verification onboarding.
 *
 * @param {IdentityVerificationStatus} status
 * @returns {IBadge}
 */
export function getVerificationStatusBadge(status: IdentityVerificationStatus): IBadge {
  switch (status) {
    case IdentityVerificationStatus.APPROVED:
      return {
        color: 'green',
        label: 'Verified',
      };

    case IdentityVerificationStatus.REFUSED:
      return {
        color: 'red',
        label: 'Rejected',
      };

    case IdentityVerificationStatus.PENDING:
      return {
        color: 'yellow',
        label: 'VerificationInProgress',
      };

    case IdentityVerificationStatus.NONE:
    default:
      return {
        color: 'gray',
        label: 'NotVerified',
      };
  }
}

/**
 * getDepositBadge.
 * Return IBadge for the capital deposit accordion section.
 *
 * @param {SpvInfosFragment} company
 * @param {BankCapitalAccount | null | undefined} bankCapitalAccount
 * @returns {IBadge}
 */
export function getDepositBadge(
  company: SpvInfosFragment,
  bankCapitalAccount: GetBankCapitalAccountByCompanyQuery['getBankCapitalAccountByCompany'],
): IBadge {
  if (company.spvStatus === SpvStatus.CREATING) {
    return {
      color: 'green',
      label: 'Received',
    };
  }
  if (bankCapitalAccount?.transferStatus === CreditTransferStatus.INSUFFICIENT) {
    return {
      color: 'red',
      label: 'IncompleteAmount',
    };
  }
  if (bankCapitalAccount?.transferStatus === CreditTransferStatus.RECEIVED) {
    if (bankCapitalAccount?.balance === company?.shareCapital) {
      return {
        color: 'green',
        label: 'Received',
      };
    }
    if (bankCapitalAccount?.balance < (company?.shareCapital || 0)) {
      return {
        color: 'red',
        label: 'IncompleteAmount',
      };
    }
    return {
      color: 'yellow',
      label: 'IncorrectAmount',
    };
  }
  return {
    color: 'gray',
    label: 'InProgress',
  };
}

export const SPV_DOCUMENT_TYPE_TO_SIGN = [
  CompanyDocumentType.STATUTS,
  CompanyDocumentType.DOMICILIATION,
  CompanyDocumentType.SOUSCRIPTEURS,
  CompanyDocumentType.DBE,
  CompanyDocumentType.POUVOIR_FORMALITES,
  CompanyDocumentType.FORMULAIRE_M0,
  CompanyDocumentType.DNC,
];

export function getSPVDocuments(documents: CompanyDocumentInfosFragment[]): SPVDocumentRecord {
  const documentsMap: Record<CompanyDocumentType | string, CompanyDocumentInfosFragment> = {};
  documents.forEach((doc) => {
    if (SPV_DOCUMENT_TYPE_TO_SIGN.includes(doc?.type)) {
      documentsMap[doc?.type] = doc;
    }
  });

  return documentsMap;
}

/**
 * cleanDraftDocuments.
 * Filter out irrelevant documents from draft query.
 *
 * @param documents
 * @returns
 */
export function cleanDraftDocuments(
  documents: CompanyDocumentInfosFragment[],
): Record<string, CompanyDocumentInfosFragment> {
  return documents
    .filter((doc) => SPV_DOCUMENT_TYPE_TO_SIGN.includes(doc.type))
    .reduce((acc, cur) => ({ ...acc, [cur.type]: cur }), {});
}
