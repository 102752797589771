import {
  Button,
  Divider,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react/solid';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { OperationType, useGetMovementQuery } from '@blockpulse3/graphql/hooks';

import { CompleteOperationModal } from '../';
import { CreateMovementSubscription } from './CreateMovementSubscription';
import { MovementPanelDocuments } from './MovementPanelDocuments';
import { MovementPanelHoldingMethod } from './MovementPanelHoldingMethod';
import { MovementPanelInvestment } from './MovementPanelInvestment';

export function MovementPanelOperation(): JSX.Element {
  const t = useTranslations();

  const { companyId = '', movementId = '' } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useGetMovementQuery({
    variables: {
      companyId,
      movementId,
    },
    skip: !companyId || !movementId,
  });

  if (loading || !data || error) {
    return (
      <>
        <Stack px="4" spacing="2">
          <Text color="gray.600" fontWeight="600">
            {t('Operation', { nb: 1 })}
          </Text>
          <Skeleton h="60px" />
        </Stack>
        <Divider />
        <MovementPanelInvestment />
      </>
    );
  }

  const handleMovementSubscriptionCreated = async (): Promise<void> => {
    refetch();
  };

  const handleOperationCompleted = (operationId: string): void => {
    refetch();
    onClose();
  };

  const handleModalOpen = (): void => {
    onOpen();
  };

  const { movement } = data;
  const operation = movement.subscription?.operation;
  const isOperationIncomplete = operation?.type === OperationType.UNKNOWN || !operation?.sharePrice;

  const handleDetailClick = (): void => {
    let operationRoute = '';
    if (operation?.type === OperationType.FUNDRAISING) {
      operationRoute = generatePath(routes.company.fundraising.full, {
        companyId,
        operationId: operation.id,
      });
    } else if (operation?.type === OperationType.SECONDARY) {
      operationRoute = generatePath(routes.company.secondary.full, {
        companyId,
        operationId: operation.id,
      });
    } else if (operation?.type === OperationType.OPTION_POOL) {
      operationRoute = generatePath(routes.company.optionPool.full, {
        companyId,
        operationId: operation.id,
      });
    }
    if (operationRoute) {
      navigate(operationRoute);
    }
  };

  return (
    <>
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Operation', { nb: 1 })}
        </Text>
        {!operation ? (
          <CreateMovementSubscription
            movementId={movementId}
            onSubmit={handleMovementSubscriptionCreated}
          />
        ) : (
          <HStack alignItems="center" justifyContent="space-between" spacing="2">
            <Text fontSize="xl" fontWeight="700">
              {operation.name}
            </Text>
            {isOperationIncomplete ? (
              <>
                <Button
                  rightIcon={<Icon as={PencilIcon} boxSize="5" />}
                  size="sm"
                  variant="secondary"
                  onClick={handleModalOpen}
                >
                  {t('CompleteAction')}
                </Button>
                {isOpen && (
                  <CompleteOperationModal
                    isOpen={isOpen}
                    operationId={operation.id}
                    operationType={operation.type}
                    onClose={onClose}
                    onCompleted={handleOperationCompleted}
                  />
                )}
              </>
            ) : (
              <Button
                rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                size="sm"
                variant="secondary"
                onClick={handleDetailClick}
              >
                {t('ShowDetails')}
              </Button>
            )}
          </HStack>
        )}
      </Stack>
      {operation && (
        <>
          <Divider />
          <MovementPanelInvestment />
          <Divider />
          <MovementPanelDocuments />
          <Divider />
          <MovementPanelHoldingMethod />
        </>
      )}
    </>
  );
}
