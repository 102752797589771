import { Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { SpaceHeader } from './SpaceHeader';
import { SpaceMembers } from './SpaceMembers';

type Props = unknown;

/**
 * SpaceMembersView.
 * Space admin view with member table.
 *
 * @returns {JSX.Element}
 */
export function SpaceMembersView(): JSX.Element {
  const t = useTranslations();
  return (
    <Stack spacing="6">
      <Stack spacing="4">
        <SpaceHeader />
        <Heading size="lg">{t('Member', { nb: 2 })}</Heading>
      </Stack>
      <SpaceMembers />
    </Stack>
  );
}

export type SpaceMembersViewProps = Props;
