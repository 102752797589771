import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  AssetEntityInfosFragment,
  AssetType,
  useCreateOrUpdateStockAssetMutation,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  Input,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
} from '@blockpulse3/ui/commons';

import { defaultValues, schema } from './schema';
import { IStockAsset } from './types';
import { getStockAssetDefaultValues } from './utils';

type Props = {
  assetType: AssetType;
  editAsset?: AssetEntityInfosFragment | null;
  onClose: () => void;
  onComplete: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

/**
 * StockCreateModal.
 * Form to create a Stock Asset.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function StockCreateModal({
  assetType,
  editAsset,
  onClose,
  onComplete,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { companyId = '' } = useParams();
  const [createOrUpdateStockAsset, { loading: isCreateLoading }] =
    useCreateOrUpdateStockAssetMutation();

  const { register, formState, handleSubmit, reset } = useForm<IStockAsset>({
    defaultValues: editAsset ? getStockAssetDefaultValues(editAsset) : defaultValues,
    resolver: yupResolver(schema),
  });

  const handleModalClose = (): void => {
    onClose();
    reset({ ...defaultValues });
  };

  const handleFormSubmit: SubmitHandler<IStockAsset> = (data): void => {
    if (!companyId) return;

    const stockAsset = {
      ...data,
      assetId: editAsset?.id,
      assetType,
      nominalValue: parseFloat(data.nominalValue.toString()),
    };

    createOrUpdateStockAsset({
      variables: {
        companyId,
        createOrUpdateStockAssetInput: { ...stockAsset },
      },
      onCompleted: () => {
        onComplete();
        handleModalClose();
      },
      onError: () => {
        errorToast({ title: t('ErrorAddingStock') });
        handleModalClose();
      },
    });
  };

  return (
    <ResponsiveModal closeOnOverlayClick={false} onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t(editAsset ? 'UpdateStock' : 'AddExistingStock', { assetType })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="add-stock-asset" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <FormControl flex="2" isInvalid={!!formState.errors?.name}>
                <FormLabel htmlFor="name">{t('StockName')}</FormLabel>
                <Input id="name" type="string" {...register('name')} />
                <ErrorMessage error={formState.errors?.name} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.nominalValue}>
                <FormLabel htmlFor="nominalValue">{t('NominalValue')}</FormLabel>
                <InputGroup>
                  <Input
                    id="nominalValue"
                    step="0.01"
                    type="number"
                    {...register('nominalValue')}
                  />
                  <InputRightAddon>€</InputRightAddon>
                </InputGroup>
                <ErrorMessage error={formState.errors?.nominalValue} />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button
            isDisabled={isCreateLoading}
            type="button"
            variant="secondary"
            onClick={handleModalClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            data-cy="add-asset"
            form="add-stock-asset"
            isDisabled={isCreateLoading}
            isLoading={isCreateLoading}
            type="submit"
          >
            {t(editAsset ? 'UpdateStock' : 'AddTheStock', { assetType })}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type StockCreateModalProps = Props;
