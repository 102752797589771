import { matchPath, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';

export type UseDisclosureLinkReturn = {
  /* ** Computed visibility state ** */
  isOpen: boolean;
  /* ** Callback to navigate to a visible state ** */
  onOpen: (href?: string) => void;
  /* ** Callback to navigate to a hidden state ** */
  onClose: (href?: string) => void;
};

export type UseDisclosureLinkParameters = {
  /* ** Href route of hidden state ** */
  hiddenHref: string;
  /* ** Href route of visible state ** */
  visibleHref: string;
};

/**
 * useDisclosureLink.
 *
 * @param {UseDisclosureLinkParameters} options
 * @returns {UseDisclosureLinkReturn}
 */
export function useDisclosureLink(options: UseDisclosureLinkParameters): UseDisclosureLinkReturn {
  const { hiddenHref, visibleHref } = options;

  /* ** Use full match to compute visibility ** */
  const location = useLocation();
  const fullHiddenPath = useResolvedPath(hiddenHref);
  const fullVisiblePath = useResolvedPath(visibleHref);
  let openedIndex = -1;

  openedIndex = matchPath(fullVisiblePath.pathname, location.pathname) ? 0 : -1;

  const defaultHiddenHref = fullHiddenPath.pathname;
  const defaultVisibleHref = fullVisiblePath.pathname;

  const navigate = useNavigate();

  /**
   * onOpen.
   * Navigate to the visible state route. Use `visibleHref` by default.
   *
   * @param {string} href
   * @returns {void}
   */
  const onOpen = (href: string = defaultVisibleHref): void => {
    navigate(href);
  };

  /**
   * onClose.
   * Navigate to the hidden state route. Use `hiddenHref` by default.
   *
   * @param {string} href
   * @returns {void}
   */
  const onClose = (href: string = defaultHiddenHref): void => {
    navigate(href);
  };

  return { isOpen: openedIndex !== -1, onOpen, onClose };
}
