import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Heading,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useGetSpvQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { SummaryRoleInfos } from '../../SummaryRoleInfos';
import { SummaryCompanyInfos } from './SummaryCompanyInfos';
import { SummaryDocuments } from './SummaryDocuments';

/**
 * OnboardingSPVSummary.
 * Summary step for the SPV funnel.
 *
 * @returns {JSX.Element}
 */
export function OnboardingSPVSummary(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const navigate = useNavigate();

  const { data, loading, error } = useGetSpvQuery({ variables: { companyId } });

  if (loading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (error || !data) {
    return <ErrorQueryCard width="full" />;
  }

  const handleStepCancel = (): void => {
    navigate('../' + routes.onboardings.spv.edit.access.href);
  };

  const handleWizardSubmit = (): void => {
    navigate('../' + routes.onboardings.spv.edit.matriculation.href);
  };

  return (
    <Card variant="divider-top" width="full">
      <CardHeader as={Stack} spacing="2">
        <Heading size="lg">{t('Verification')}</Heading>
        <Text color="gray.600" fontSize="lg">
          {t('SummaryOfInfoBeforeValidation')}
        </Text>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="8">
        <SummaryCompanyInfos />
        <SummaryRoleInfos />
        <SummaryDocuments />
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button variant="secondary" w="full" onClick={handleStepCancel}>
          {t('Back')}
        </Button>
        <Button data-cy="submitAndPay" w="full" onClick={handleWizardSubmit}>
          {t('ValidateAndPay')}
        </Button>
      </CardFooter>
    </Card>
  );
}
