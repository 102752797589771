import * as yup from 'yup';

import { requiredSelectField, requiredStringField } from '@blockpulse3/data/shared';
import { ManagementType } from '@blockpulse3/graphql/hooks';

import { SpaceManagerForm } from './types';

export const schema: yup.ObjectSchema<SpaceManagerForm> = yup.object({
  space: requiredSelectField,
  type: yup.object({
    label: requiredStringField,
    value: yup.mixed<ManagementType>().oneOf(Object.values(ManagementType)).required(),
  }),
});

export const spaceManagerDefaultValues: SpaceManagerForm = {
  space: {
    label: '',
    value: '',
  },
  type: {
    label: ManagementType.MANAGER,
    value: ManagementType.MANAGER,
  },
};
