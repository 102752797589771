import { PathMatch, useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

type UseOpportunityOperationSteps = {
  progress: number;
  activeSteps: { label: string; value: PathMatch<string> | null }[];
};

/**
 * useOpportunityOperationSteps.
 *
 * @returns {useOpportunityOperationSteps}
 */
export function useOpportunityOperationSteps(): UseOpportunityOperationSteps {
  const t = useTranslations();

  const opportunityRoutes = routes.company.newOpportunity;

  const isCreateActive = useMatch(opportunityRoutes.full);
  const isParametersActive = useMatch(opportunityRoutes.edit.full);
  const isDocumentationActive = useMatch(
    opportunityRoutes.edit.full + '/' + opportunityRoutes.edit.documentation.href,
  );
  const isSummaryActive = useMatch(
    opportunityRoutes.edit.full + '/' + opportunityRoutes.edit.summary.href,
  );

  const activeSteps = [
    {
      label: t('Settings'),
      value: isParametersActive || isCreateActive,
    },
    { label: t('Documentation'), value: isDocumentationActive },
    { label: t('Summary'), value: isSummaryActive },
  ];

  const progress = activeSteps.findIndex((step) => step.value);

  return { progress, activeSteps };
}
