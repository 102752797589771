import { Button, HStack, Icon, Text } from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { MenuListProps, SingleValueProps, chakraComponents } from 'chakra-react-select';
import { useTranslations } from 'use-intl';

import { OptionAssetType, isFeatureEnabled, noop } from '@blockpulse3/data/shared';
import { AssetType, OperationType } from '@blockpulse3/graphql/hooks';

import { OperationAssetOption, OperationAssetOptionGroup } from './types';

type OperationAssetMenuListProps = MenuListProps<
  OperationAssetOption,
  false,
  OperationAssetOptionGroup
> & {
  onClick?: (assetType: AssetType) => void;
  operationType: OperationType;
  isAddButtonDisabled?: boolean;
};

/**
 * MenuList.
 * Custom menu list component, add a link to the settings.
 *
 * @param {HoldingMethodMenuListProps} props
 * @returns {JSX.Element}
 */
export function SelectMenuList({
  operationType,
  isAddButtonDisabled = false,
  onClick = noop,
  ...props
}: OperationAssetMenuListProps): JSX.Element {
  const t = useTranslations();
  const i18nAssetType = useTranslations('AssetTypeValues');

  const handleClick = (assetType: AssetType): void => {
    onClick(assetType);
  };

  const isFeatureOperationBond = isFeatureEnabled('fundraisingBond');
  const isFeatureOptionPool = isFeatureEnabled('optionPool');

  const AddBondButton = (): JSX.Element | null => {
    if (
      ![OperationType.FUNDRAISING, OperationType.OPPORTUNITY].includes(operationType) ||
      !isFeatureOperationBond
    ) {
      return null;
    }
    return (
      <HStack px="3" py="2">
        <Button
          color="body.text"
          fontWeight="400"
          isDisabled={isAddButtonDisabled}
          leftIcon={<Icon as={PlusIcon} boxSize="3" color="body.text" />}
          variant="link"
          onClick={(): void => handleClick(AssetType.BOND)}
        >
          <Text fontSize="sm">{t('NewBond')}</Text>
        </Button>
      </HStack>
    );
  };

  const AddOptionButtons = (): JSX.Element | null => {
    if (operationType !== OperationType.OPTION_POOL || !isFeatureOptionPool) {
      return null;
    }
    return (
      <>
        {Object.values(OptionAssetType).map((assetType) => (
          <HStack key={assetType} px="3" py="2">
            <Button
              color="body.text"
              fontWeight="400"
              isDisabled={isAddButtonDisabled}
              leftIcon={<Icon as={PlusIcon} boxSize="3" color="body.text" />}
              variant="link"
              onClick={(): void => handleClick(AssetType[assetType])}
            >
              <Text fontSize="sm">
                {t('NewOption')} - {i18nAssetType(assetType, { nb: 0 })}
              </Text>
            </Button>
          </HStack>
        ))}
      </>
    );
  };

  return (
    <chakraComponents.MenuList {...props}>
      {props.children}
      <AddBondButton />
      <AddOptionButtons />
    </chakraComponents.MenuList>
  );
}

type OperationAssetSingleValueProps = SingleValueProps<
  OperationAssetOption,
  false,
  OperationAssetOptionGroup
> & {
  /* ** Callback on modify button click, open Modal ** */
  onModifyClick?: (data: OperationAssetOption) => void;
};

export function SelectSingleValue({
  onModifyClick = noop,
  ...props
}: OperationAssetSingleValueProps): JSX.Element {
  const t = useTranslations();

  const showModify = props.data.isDraft;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onModifyClick(props.data);
  };

  const handleMouseDown: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <chakraComponents.SingleValue {...props}>
      <Text maxWidth="200px" variant="ellipsis">
        {props.children}
      </Text>
      {showModify && (
        <Button
          flexShrink="0"
          size="xs"
          variant="ghost"
          onClick={handleClick}
          onMouseDown={handleMouseDown}
        >
          {t('Modify')}
        </Button>
      )}
    </chakraComponents.SingleValue>
  );
}
