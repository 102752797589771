import { CompanyInfosFragment, RepresentativeRole } from '@blockpulse3/graphql/hooks';
import { IIdentityContext, IUserContext } from '@blockpulse3/web-client/auth';

export function canUserUpdateCompany(
  authUser: IUserContext['user'],
  individual: IIdentityContext['individual'],
  company?: CompanyInfosFragment,
): boolean {
  if (!authUser || !individual || !company) {
    return false;
  }

  if (authUser.isSuperAdmin) return true;

  return company.companyRepresentative.some(
    (rep) =>
      [
        RepresentativeRole.PRESIDENT,
        RepresentativeRole.COMPANY_MANAGER,
        RepresentativeRole.SIGNER,
      ].includes(rep.role) && rep.representativeIdentity.individualIdentity?.id === individual?.id,
  );
}
