import { Modal, ModalProps } from '@chakra-ui/react';

type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xs' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
} & Omit<ModalProps, 'size'>;

/**
 * ResponsiveModal.
 * Custom Modal component to bypass defaultProps type responsive issue :
 * https://github.com/chakra-ui/chakra-ui/issues/7444#issuecomment-1474907306
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function ResponsiveModal({ size = 'lg', ...props }: Props): JSX.Element {
  return <Modal scrollBehavior="inside" size={{ base: 'full', md: size }} {...props} />;
}

export type ResponsiveModalProps = Props;
