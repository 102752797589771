import {
  DocumentInfosFragment,
  OperationDocumentType,
  SubscriptionInfosFragment,
  UserInfosFragment,
} from '@blockpulse3/graphql/hooks';

/**
 * getSubscriptionDocument.
 *
 * Hide document selection into this function, handle null and undefined states.
 *
 * @param {OperationDocumentType} documentType
 * @param {DocumentInfosFragment[]} documents
 * @returns {DocumentInfosFragment | null}
 */
export function getSubscriptionDocument(
  documentType: OperationDocumentType,
  documents?: DocumentInfosFragment[],
): DocumentInfosFragment | null {
  if (!documents) {
    return null;
  }
  const documentToSign = documents?.find((document) => document.type === documentType);

  return documentToSign || null;
}

/**
 * getIsUserSigner.
 *
 * Hide conditions and object access into this function, the components are much readable.
 *
 * @param {SubscriptionInfosFragment | undefined} subscription
 * @param {User | undefined} user
 * @returns {boolean}
 */
export function getIsUserSigner(
  subscription: SubscriptionInfosFragment | undefined,
  user: UserInfosFragment | null,
): { isUserSigner: boolean; isUserBuyer: boolean; isUserSeller: boolean } {
  if (!subscription || !user) {
    return { isUserSigner: false, isUserBuyer: false, isUserSeller: false };
  }
  /* ** Is current user a signer and able to be redirected to SignatureIt ** */
  const userIndividual = user?.individualIdentity;
  const buyerSigner = subscription?.buyerIdentity?.companyIdentity?.signer?.individualIdentity;
  const buyerIndividualIdentity = subscription?.buyerIdentity?.individualIdentity;
  const sellerSigner = subscription?.sellerIdentity?.companyIdentity?.signer?.individualIdentity;
  const sellerIndividualIdentity = subscription?.sellerIdentity?.individualIdentity;

  const isUserBuyer =
    buyerIndividualIdentity?.id === userIndividual?.id || buyerSigner?.id === userIndividual?.id;
  const isUserSeller =
    !!subscription?.sellerIdentity &&
    (sellerIndividualIdentity?.id === userIndividual?.id ||
      sellerSigner?.id === userIndividual?.id);

  return {
    isUserSigner: isUserBuyer || isUserSeller,
    isUserBuyer,
    isUserSeller,
  };
}
