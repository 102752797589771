import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { useGetIndividualDocumentsQuery } from '@blockpulse3/graphql/hooks';
import {
  AddIdentityDocuments,
  ErrorQueryCard,
  LinkButton,
  useGetKycDocuments,
} from '@blockpulse3/ui/commons';
import { useManagedIndividual, useManagerRole } from '@blockpulse3/web-client/auth';

import { DocumentTable } from '../DocumentTable';

type Props = {
  backHref?: string;
};

/**
 * UserDocumentsView.
 *
 * @returns {JSX.Element}
 */
export function UserDocumentsView({ backHref }: Props): JSX.Element {
  const t = useTranslations();

  const [isAddingDocuments, setIsAddingDocuments] = useState<boolean>(false);

  const { individual } = useManagedIndividual();

  const isUploadAuthorized = useManagerRole({ individualIdentityId: individual?.id });

  const {
    data: documentsData,
    loading: loadingDocuments,
    error: documentsError,
    refetch,
  } = useGetIndividualDocumentsQuery({
    variables: {
      individualIdentityId: individual?.id || '',
    },
    skip: !individual?.id,
  });

  const [getKycDocuments] = useGetKycDocuments();

  const identityId = individual?.identity?.id;

  if (!individual || loadingDocuments) {
    return (
      <Stack spacing="6">
        {backHref && <LinkButton label={t('Back')} route={backHref} />}
        <Heading>{t('Document', { nb: 1 })}</Heading>
        <Skeleton>
          <Card h="300px" />
        </Skeleton>
      </Stack>
    );
  }

  if (documentsError || !identityId) {
    return (
      <Stack spacing="6">
        {backHref && <LinkButton label={t('Back')} route={backHref} />}
        <Heading>{t('Document', { nb: 1 })}</Heading>
        <ErrorQueryCard h="300px" />
      </Stack>
    );
  }

  const documents = documentsData?.getIndividualDocuments?.map(({ document }) => document) || [];
  const hasAllDocuments = getKycDocuments().every((addableDocument) =>
    documents.some((document) => document.type === addableDocument.type),
  );

  return (
    <Stack spacing="6">
      {backHref && <LinkButton label={t('Back')} route={backHref} />}
      <Heading>{t('Document', { nb: 2 })}</Heading>
      <Card>
        <CardHeader alignItems="center" as={HStack} justifyContent="space-between">
          <Heading size="md">{t('PersonalDocuments')}</Heading>
          {!hasAllDocuments && isUploadAuthorized && (
            <Button
              leftIcon={<AddIcon />}
              size="sm"
              variant="secondary"
              w="fit-content"
              onClick={(): void => setIsAddingDocuments((val) => !val)}
            >
              {t('AddDocument')}
            </Button>
          )}
        </CardHeader>
        <CardBody>
          {isAddingDocuments && (
            <Stack pb="4">
              <AddIdentityDocuments identityId={identityId} onUploadCompleted={refetch} />
            </Stack>
          )}
          <DocumentTable identityId={identityId} values={documents} onDeleteCompleted={refetch} />
        </CardBody>
      </Card>
    </Stack>
  );
}

export type UserDocumentsViewProps = Props;
