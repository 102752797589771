import { Box, BoxProps, HStack, Icon } from '@chakra-ui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import React from 'react';

type Props = {
  /* ** Show green check if verified ** */
  isChecked?: boolean;
  /* ** Content ** */
  children: React.ReactNode;
} & BoxProps;

export function IdentityCardTitle({ isChecked, children, ...props }: Props): JSX.Element {
  return (
    <Box fontSize="sm" fontWeight="500" wordBreak="break-word" {...props}>
      <HStack spacing="1">
        {children}
        {isChecked && <Icon as={CheckCircleIcon} boxSize="15px" color="green.400" />}
      </HStack>
    </Box>
  );
}

export type IdentityCardTitleProps = Props;
