export enum ArchiveDocumentErrorCode {
  ARCHIVE_DOWNLOAD_FAILED = 'ARCHIVE_DOWNLOAD_FAILED',
  ARCHIVE_PARSING_FAILED = 'ARCHIVE_PARSING_FAILED',
  FOLDER_NOT_FOUND = 'FOLDER_NOT_FOUND',
}

export enum ArchiveParsingErrorCode {
  IDENTITY_NOT_FOUND_IN_OPERATION = 'IDENTITY_NOT_FOUND_IN_OPERATION',
  FILE_TYPE_UNSUPPORTED = 'FILE_TYPE_UNSUPPORTED',
}

export type ArchiveParsingError = {
  code: ArchiveParsingErrorCode;
  path: string;
};

export type ArchiveDocumentError =
  | {
      code:
        | ArchiveDocumentErrorCode.ARCHIVE_DOWNLOAD_FAILED
        | ArchiveDocumentErrorCode.FOLDER_NOT_FOUND;
      message: string;
    }
  | {
      code: ArchiveDocumentErrorCode.ARCHIVE_PARSING_FAILED;
      message: string;
      parsingErrors: ArchiveParsingError[];
    };
