import { AddIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { RefreshIcon, TrashIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import {
  CompanyDocumentType,
  IdentityVerificationOrigin,
  IdentityVerificationStatus,
  noop,
} from '@blockpulse3/data/shared';
import {
  IdentityType,
  IndividualDocumentType,
  useDeleteCompanyDocumentMutation,
  useDeleteKycDocumentMutation,
  useGetDocumentPdfUrlLazyQuery,
  useGetIdentityDocumentsQuery,
  useGetIdentityQuery,
  useHandleIdentityApprovalMutation,
  useResetUbbleIdentificationPdfMutation,
} from '@blockpulse3/graphql/hooks';
import { getDocumentIds } from '@blockpulse3/helpers';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import {
  AddIdentityDocuments,
  useGetKybDocuments,
  useGetKycDocuments,
} from '../AddIdentityDocuments';
import { DeleteConfirmModal } from '../DeleteConfirmModal';
import { DocumentLink } from '../DocumentLink';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { ResponsiveModal, ResponsiveModalProps } from '../ResponsiveModal';
import { ResponsiveModalFooter } from '../ResponsiveModalFooter';
import { WarningCard } from '../WarningCard';
import { UltimateBeneficialOwnerCard } from './UltimateBeneficialOwnerCard';

type Props = {
  identityId: string;
  isUserAuthorized: boolean;
  onCompleted?: () => void;
} & Omit<ResponsiveModalProps, 'children'>;

export function IdentityValidationModal({
  identityId,
  isUserAuthorized,
  onClose,
  onCompleted = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const { user } = useAuthUser();
  const isSuperAdmin = user?.isSuperAdmin;

  const { companyId } = useParams();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isAddingDocuments, setIsAddingDocuments] = useState<boolean>(false);

  const confirmRef = useRef(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    CompanyDocumentType | IndividualDocumentType | undefined
  >(undefined);
  const [isIdentityApproved, setIsIdentityApproved] = useState<boolean>(false);
  const [isIdentityRefused, setIsIdentityRefused] = useState<boolean>(false);

  const deleteModal = useDisclosure();

  const {
    data: identityData,
    refetch: refetchIdentity,
    loading: identityLoading,
  } = useGetIdentityQuery({
    variables: {
      identityId,
    },
    skip: !identityId,
  });

  const {
    data: identityDocumentsData,
    refetch: refetchDocuments,
    loading: documentLoading,
  } = useGetIdentityDocumentsQuery({
    variables: {
      identityId,
    },
    fetchPolicy: 'no-cache',
  });

  const refetch = (): void => {
    refetchDocuments();
    refetchIdentity();
  };

  const isLoading = identityLoading || documentLoading;

  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();
  const [handleIdentityApproval, { loading: isIdentityApprovalLoading }] =
    useHandleIdentityApprovalMutation();
  const [deleteCompanyDocument, { loading: isDocumentDeletionLoading }] =
    useDeleteCompanyDocumentMutation();
  const [deleteDocument, { loading: isKycDeletionLoading }] = useDeleteKycDocumentMutation();
  const [resetUbbleIdentificationPdf, { loading: isUbbleIdentificationPdfLoading }] =
    useResetUbbleIdentificationPdfMutation();
  const [getKybDocuments] = useGetKybDocuments();
  const [getKycDocuments] = useGetKycDocuments();

  const identity = identityData?.identity;
  const isCompany = identity?.type === IdentityType.COMPANY;
  const kycbVerificationStatus = isCompany
    ? identity?.companyIdentity?.kybVerificationStatus
    : identity?.individualIdentity?.kycVerificationStatus;

  const displayButton = [
    IdentityVerificationStatus.PENDING,
    IdentityVerificationStatus.REFUSED,
  ].includes(kycbVerificationStatus as IdentityVerificationStatus);
  const displayDeleteButton = [
    IdentityVerificationStatus.NONE,
    IdentityVerificationStatus.PENDING,
    IdentityVerificationStatus.REFUSED,
  ].includes(kycbVerificationStatus as IdentityVerificationStatus);

  const companyName = identity?.companyIdentity?.name || '';
  const individualIdentity =
    identity?.individualIdentity || identity?.companyIdentity?.signer?.individualIdentity;
  const individualIdentityId = individualIdentity?.id || '';

  const documents = identityDocumentsData?.getIdentityDocuments || [];
  const documentIds = getDocumentIds(documents);
  const isUbbleVerified = individualIdentity?.kycOrigin === IdentityVerificationOrigin.UBBLE;

  const ubos = identity?.companyIdentity?.ubos || [];

  const hasAllDocuments = isCompany
    ? Object.keys(documentIds).length >= getKybDocuments().length
    : Object.keys(documentIds).length >= getKycDocuments().length;

  useEffect(() => {
    if (!hasAllDocuments) {
      return;
    }

    setIsAddingDocuments(false);
  }, [hasAllDocuments]);

  const onDocumentClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        window.open(pdfUrl, '_blank');
      },
    });
  };

  const handleIdentityApproved = (): void => {
    setIsIdentityApproved(true);
    handleApproval(IdentityVerificationStatus.APPROVED);
  };
  const handleIdentityRefused = (): void => {
    setIsIdentityRefused(true);
    handleApproval(IdentityVerificationStatus.REFUSED);
  };
  const handleApproval = (verificationStatus: IdentityVerificationStatus): void => {
    if (!identity) return;

    const customMessage =
      verificationStatus === IdentityVerificationStatus.REFUSED
        ? inputRef.current?.value
        : undefined;

    handleIdentityApproval({
      variables: {
        handleIdentityApprovalInput: {
          identityId: identity.id,
          companyId,
          verificationStatus,
          customMessage,
        },
      },
      onCompleted: () => {
        refetchIdentity();
        onCompleted();
        onCloseModal();
      },
    });
  };

  const handleFileDelete = (documentType: CompanyDocumentType | IndividualDocumentType): void => {
    // For now only handle company documents
    setSelectedDocumentType(documentType);
    deleteModal.onOpen();
  };

  const handleDelete = (): void => {
    if (!selectedDocumentType) return;

    const companyId = identity?.companyIdentity?.id;

    if (selectedDocumentType in CompanyDocumentType && companyId) {
      deleteCompanyDocument({
        variables: {
          deleteCompanyDocumentInput: {
            companyId,
            documentType: selectedDocumentType,
          },
        },
        onCompleted: () => {
          refetch();
        },
      });
    } else if (individualIdentityId) {
      deleteDocument({
        variables: {
          deleteKycDocumentInput: {
            individualIdentityId,
            documentType: selectedDocumentType,
          },
        },
        onCompleted: () => {
          refetch();
        },
      });
    }

    deleteModal.onClose();
  };

  const handleUbbleIdentificationPdfResetting = (): void => {
    resetUbbleIdentificationPdf({
      variables: { individualIdentityId },
      onCompleted: () => {
        refetch();
      },
    });
  };

  const onCloseModal = (): void => {
    setIsAddingDocuments(false);
    onClose();
  };

  return (
    <ResponsiveModal onClose={onCloseModal} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('IdentityVerification')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} spacing="4">
          {isLoading ? (
            <Stack h="100px" layerStyle="emptyState">
              <Spinner flexShrink="0" />
            </Stack>
          ) : (
            <>
              <HStack justifyContent="space-between">
                <Text color="gray.600" fontWeight="600">
                  {t('Document', { nb: documents.length })}
                </Text>
                {isUserAuthorized && !hasAllDocuments && (
                  <Button
                    leftIcon={<AddIcon />}
                    size="sm"
                    variant="secondary"
                    w="fit-content"
                    onClick={(): void => setIsAddingDocuments((crr) => !crr)}
                  >
                    {t('AddDocument')}
                  </Button>
                )}
              </HStack>
              {isUserAuthorized && isAddingDocuments && identity?.id && (
                <AddIdentityDocuments identityId={identity?.id} onUploadCompleted={refetch} />
              )}
              {!Object.keys(documentIds).length && !isAddingDocuments && (
                <Stack layerStyle="emptyState">
                  <Text>{t('NoIdentityDocumentDownloaded')}</Text>
                </Stack>
              )}
            </>
          )}
          <Stack spacing="2">
            {Object.keys(documentIds).map((documentType, index) => (
              <HStack key={index} justify="space-between">
                <DocumentLink
                  fileName={i18nDocumentTitle(
                    `${documentType}wName` as IntlDocumentValues,
                    documentType in CompanyDocumentType
                      ? { companyName }
                      : { name: individualIdentity?.name || '[représentant légal]' },
                  )}
                  onClick={(): void => onDocumentClick(documentIds[documentType])}
                />
                <HStack>
                  {displayDeleteButton && isUserAuthorized && (
                    <IconButton
                      aria-label="delete-document"
                      icon={<Icon as={TrashIcon} boxSize="4" />}
                      isDisabled={isDocumentDeletionLoading || isLoading || isKycDeletionLoading}
                      size="xs"
                      variant="icon-delete"
                      onClick={(): void =>
                        handleFileDelete(
                          documentType as CompanyDocumentType | IndividualDocumentType,
                        )
                      }
                    />
                  )}
                  {isUbbleVerified &&
                    isSuperAdmin &&
                    documentType === IndividualDocumentType.IDENTITY_DOCUMENT && (
                      <IconButtonWithTooltip
                        aria-label="reset-document"
                        icon={<Icon as={RefreshIcon} boxSize="4" />}
                        isLoading={isUbbleIdentificationPdfLoading}
                        label={t('ResetUbbleIdentificationReport')}
                        size="xs"
                        variant="icon"
                        onClick={handleUbbleIdentificationPdfResetting}
                      />
                    )}
                </HStack>
              </HStack>
            ))}
          </Stack>
          {ubos.length && (
            <>
              <Divider />
              <Text color="gray.600" fontWeight="600">
                {t('EffectiveRecipient', { nb: 3 })}
              </Text>
              {ubos.map((ubo, index) => {
                return <UltimateBeneficialOwnerCard key={ubo.id} index={index} ubo={ubo} />;
              })}
            </>
          )}
          {displayButton && !isUserAuthorized && (
            <WarningCard title={t('ComplianceManagersAuthorized')} />
          )}
        </ModalBody>
        {displayButton && (
          <>
            <Divider />
            <ResponsiveModalFooter>
              <Stack gap="4" w="full">
                <Accordion allowToggle variant="unstyled">
                  <AccordionItem>
                    <AccordionButton data-cy="expand-parameters">
                      <Text color="gray" fontSize="sm" textAlign="left">
                        {t('CustomInvalidIdentityMessageOptional')}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p="0">
                      <Textarea minH="200" ref={inputRef} size="sm" />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <HStack alignSelf="end">
                  <Button
                    isDisabled={!isUserAuthorized || isIdentityApprovalLoading || isLoading}
                    isLoading={(isIdentityApprovalLoading && isIdentityRefused) || isLoading}
                    variant="secondary"
                    onClick={handleIdentityRefused}
                  >
                    {t('InvalidateIdentityAction')}
                  </Button>
                  <Button
                    isDisabled={!isUserAuthorized || isIdentityApprovalLoading || isLoading}
                    isLoading={(isIdentityApprovalLoading && isIdentityApproved) || isLoading}
                    onClick={handleIdentityApproved}
                  >
                    {t('ApproveTheIdentity')}
                  </Button>
                </HStack>
              </Stack>
            </ResponsiveModalFooter>
          </>
        )}
        <DeleteConfirmModal
          isLoading={isDocumentDeletionLoading}
          isOpen={deleteModal.isOpen}
          leastDestructiveRef={confirmRef}
          subtitle={t('DefinitiveAction')}
          title={t('DeleteDocumentQuestion')}
          onClose={deleteModal.onClose}
          onDelete={handleDelete}
        />
      </ModalContent>
    </ResponsiveModal>
  );
}
