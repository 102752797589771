import { Icon, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { CollectionIcon } from '@heroicons/react/outline';
import { useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';

import { HoldingMethod } from '@blockpulse3/data/shared';
import {
  BalanceInfosFragment,
  ContractType,
  WalletInfosFragment,
} from '@blockpulse3/graphql/hooks';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { BondAssetSidePanel } from '../BondAssetSidePanel';
import { OptionAssetSidePanel } from '../OptionAssetSidePanel';
import { StockAssetSidePanel } from '../StockAssetSidePanel';
import { WalletCards } from './WalletCards';
import { WalletPills } from './WalletPills';
import { IWalletPills } from './types';

type Props = {
  wallets: WalletInfosFragment[];
};

/**
 * Wallet Table.
 *
 * @returns {JSX.Element}
 */
export function WalletBalances({ wallets }: Props): JSX.Element {
  const t = useTranslations();

  const { identityId } = useIdentity();

  /* ** Side panel infos ** */
  const [sidePanelBalance, setSidePanelBalance] = useState<BalanceInfosFragment | null>(null);
  const [selectedWallets, setSelectedWallets] = useState<IWalletPills[]>([]);
  const [holdingMethods, setHoldingMethods] = useState<HoldingMethod[]>(
    Object.values(HoldingMethod),
  );

  const sortedWallets = useMemo(
    () =>
      [...wallets]
        .sort((a, b) => ((a?.balances?.length ?? 0) < (b?.balances?.length ?? 0) ? 1 : -1))
        .map((wallet) => ({ ...wallet, isSelected: true })),
    [wallets],
  );

  const hasBalances = sortedWallets.some((wallet) => (wallet?.balances?.length ?? 0) > 0);

  useEffect(() => {
    setSelectedWallets([...sortedWallets]);
  }, [sortedWallets]);

  /* ** Side panel disclosures ** */
  const stockAssetSidePanel = useDisclosure();
  const optionAssetSidePanel = useDisclosure();
  const bondAssetSidePanel = useDisclosure();

  const handleCardClick = (row: BalanceInfosFragment): void => {
    if (!identityId) {
      return;
    }
    setSidePanelBalance(row);

    switch (row.asset.contractType) {
      /* ** Option Token ** */
      case ContractType.OPTION_TOKEN: {
        optionAssetSidePanel.onOpen();
        break;
      }
      case ContractType.BOND_TOKEN: {
        bondAssetSidePanel.onOpen();
        break;
      }
      /* ** Stock Token (or old version Basic Token) ** */
      default: {
        stockAssetSidePanel.onOpen();
        break;
      }
    }
  };

  const handlePillsClick = (wallet: IWalletPills): void => {
    const newSelectedWallets = selectedWallets.map((selectedWallet) => {
      if (selectedWallet.id === wallet.id) {
        return { ...selectedWallet, isSelected: !selectedWallet.isSelected };
      }
      return selectedWallet;
    });
    setSelectedWallets(newSelectedWallets);

    const newHoldingMethods = newSelectedWallets
      .filter((wallet) => wallet.isSelected)
      .map((wallet) => wallet.holdingMethod);
    setHoldingMethods(newHoldingMethods);
  };

  if (!hasBalances) {
    return (
      <Stack layerStyle="emptyState">
        <Icon as={CollectionIcon} boxSize="40px" color="gray.500" />
        <Text>{t('NoSharesHeldYet')}</Text>
      </Stack>
    );
  }

  return (
    <Stack spacing="4">
      <WalletPills wallets={selectedWallets} onClick={handlePillsClick} />
      <WalletCards
        holdingMethods={holdingMethods}
        wallets={wallets}
        onCardClick={handleCardClick}
      />
      {sidePanelBalance && stockAssetSidePanel.isOpen && (
        <StockAssetSidePanel
          balance={sidePanelBalance}
          identityId={identityId}
          isOpen={stockAssetSidePanel.isOpen}
          onClose={stockAssetSidePanel.onClose}
        />
      )}
      {sidePanelBalance && optionAssetSidePanel.isOpen && (
        <OptionAssetSidePanel
          balance={sidePanelBalance}
          identityId={identityId}
          isOpen={optionAssetSidePanel.isOpen}
          onClose={optionAssetSidePanel.onClose}
        />
      )}
      {sidePanelBalance && bondAssetSidePanel.isOpen && (
        <BondAssetSidePanel
          balance={sidePanelBalance}
          identityId={identityId}
          isOpen={bondAssetSidePanel.isOpen}
          onClose={bondAssetSidePanel.onClose}
        />
      )}
    </Stack>
  );
}

export type WalletTableProps = Props;
