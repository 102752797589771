import { InputProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Input } from '@blockpulse3/ui/commons';

// A debounced input react component
export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<InputProps, 'onChange'>): JSX.Element {
  const [value, setValue] = useState(initialValue);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!isFocus) return;

    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return (): void => clearTimeout(timeout);
  }, [value, debounce, onChange, isFocus]);

  return (
    <Input
      onBlur={(): void => setIsFocus(false)}
      onFocus={(): void => setIsFocus(true)}
      {...props}
      value={value}
      onChange={(e): void => setValue(e.target.value)}
    />
  );
}
