import dayjs from 'dayjs';

import { ConnectionType } from '@blockpulse3/data/shared';

/**
 * Encodes a date into a cursor string using base64 encoding.
 *
 * @param date - The date to be encoded.
 * @returns The base64 encoded cursor string.
 */
export function encodeCursor(data: Record<string, unknown>): string {
  const preparedData = { ...data };
  for (const key in preparedData) {
    if (preparedData[key] instanceof Date) {
      preparedData[key] = dayjs(preparedData[key] as Date)
        .utc(true)
        .toISOString();
    }
  }
  return Buffer.from(JSON.stringify(preparedData)).toString('base64');
}

/**
 * Decodes a base64 encoded cursor string into a date.
 *
 * @param cursor - The base64 encoded cursor string.
 * @returns The decoded date, or `undefined` if no cursor string is provided.
 */
export function decodeCursor(cursor?: string): Record<string, unknown> {
  if (!cursor) {
    return {};
  }
  const data = JSON.parse(Buffer.from(cursor, 'base64').toString('ascii'));
  return data;
}

/**
 * Extracts the nodes from a connection
 *
 * @param connection  The connection to extract the nodes from
 * @returns The nodes from the connection
 */
export function extractNodesFromEdges<T>(connection: ConnectionType<T>): T[] {
  return connection.edges.map((edge) => edge.node);
}
