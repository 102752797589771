import { HStack, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { isFeatureEnabled } from '@blockpulse3/data/shared';
import {
  IdentityType,
  IdentityVerificationStatus,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  PhoneBanner,
  SPVMatriculationStatusAlert,
} from '@blockpulse3/ui/commons';
import { IdentityHeader } from '@blockpulse3/web-client/sidebar';

import { OperationList } from './OperationList';

/**
 * SPVDashboardView.
 * Dashboard of the draft spv.
 *
 * @returns {JSX.Element}
 */
export function SPVDashboardView(): JSX.Element {
  const { companyId = '' } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const renderPhoneBanner = isFeatureEnabled('phoneBanner');
  const isVerified = company?.kybVerificationStatus === IdentityVerificationStatus.APPROVED;

  return (
    <Stack gap="10">
      <IdentityHeader>
        <IdentityCard>
          <IdentityAvatar boxSize="14" src={company?.profilePicture} type={IdentityType.COMPANY} />
          <HStack spacing="3">
            <IdentityCardTitle isChecked={isVerified}>
              <Text>{company?.name && `${company.name}`}</Text>
            </IdentityCardTitle>
          </HStack>
          <IdentityCardDescription>{company?.address?.formatted}</IdentityCardDescription>
        </IdentityCard>
      </IdentityHeader>
      <SPVMatriculationStatusAlert companyId={companyId} spvStatus={company?.spvStatus} />
      {renderPhoneBanner && <PhoneBanner />}
      <OperationList displayType="cards" limit={3} />
    </Stack>
  );
}
