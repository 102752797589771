import { Props, Select, components } from 'chakra-react-select';

import { Option, SingleValue } from './components';
import { IdentitySelectOption, IdentitySelectOptionGroup } from './types';

export type IdentitySelectProps = Props<IdentitySelectOption, false, IdentitySelectOptionGroup> & {
  placeholder?: string;
  isDisabled?: boolean;
};

export function IdentitySelect({
  defaultValue,
  placeholder,
  isDisabled = false,
  ...props
}: IdentitySelectProps): JSX.Element {
  return (
    <Select<IdentitySelectOption, false>
      colorScheme="gray"
      defaultValue={defaultValue}
      getOptionValue={(option): string => option.value}
      isDisabled={isDisabled}
      menuPlacement="auto"
      menuPosition="fixed"
      placeholder={placeholder}
      selectedOptionStyle="check"
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          _disabled: {
            opacity: 1,
          },
        }),
      }}
      components={{
        Option,
        SingleValue,
        DropdownIndicator: isDisabled ? null : components.DropdownIndicator,
      }}
      {...props}
    />
  );
}
