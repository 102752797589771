import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

import { StepFormContextProvider } from '../../provider';
import { OnboardingStep } from '../OnboardingStep';
import { SPVShareholders } from '../forms';

export function OnboardingSPVShareholders(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  /* ** Submit whatever representant form is being rendered ** */
  const handleStepSubmit = (): void => {
    navigate('../' + routes.onboardings.spv.edit.access.href);
  };

  /* ** Cancel whatever representant form is being rendered ** */
  const handleStepCancel = (): void => {
    navigate('../' + routes.onboardings.spv.edit.representatives.href);
  };

  return (
    <StepFormContextProvider>
      <OnboardingStep
        subTitle={t('FirstShareholderOfInvestmentVehicle')}
        title={t('InitialShareholder', { nb: 1 })}
      >
        <SPVShareholders onCancel={handleStepCancel} onSubmit={handleStepSubmit} />
        <Button key="cancel" variant="secondary" w="full">
          {t('Back')}
        </Button>
        <Button key="submit" data-cy="next" w="full">
          {t('Next')}
        </Button>
      </OnboardingStep>
    </StepFormContextProvider>
  );
}
