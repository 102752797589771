import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetBankCapitalAccountByCompanyQuery, useGetSpvQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  BankDetailsCard,
  ErrorQueryModal,
  ResponsiveModal,
  ResponsiveModalProps,
  SkeletonModal,
} from '@blockpulse3/ui/commons';

type Props = Omit<ResponsiveModalProps, 'children'>;

export function SPVMatriculationBankDetailsModal({ ...props }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const { data: spvData, loading, error } = useGetSpvQuery({ variables: { companyId } });
  const getBankCapitalAccountReq = useGetBankCapitalAccountByCompanyQuery({
    variables: { companyId },
  });

  if (loading) {
    return (
      <ResponsiveModal {...props}>
        <ModalOverlay />
        <SkeletonModal height="400px" />
      </ResponsiveModal>
    );
  }

  if (error || !spvData || !getBankCapitalAccountReq.data) {
    return (
      <ResponsiveModal {...props}>
        <ModalOverlay />
        <ErrorQueryModal height="400px" />
      </ResponsiveModal>
    );
  }

  const company = spvData.company;
  const capitalDepositTotal = company?.shareCapital || 0;

  const bankCapitalAccount = getBankCapitalAccountReq.data.getBankCapitalAccountByCompany;

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('CompleteInitialCapitalDeposit')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} spacing="4">
          <Text fontSize="sm">
            {t.rich('InitiateBankAccountOpening', {
              important: (chunk) => <b>{chunk}</b>,
            })}
          </Text>
          <BankDetailsCard
            bankName={bankCapitalAccount?.bank}
            iban={bankCapitalAccount?.iban}
            recipientAddress={bankCapitalAccount?.address}
            recipientName={bankCapitalAccount?.holderName}
            swift={bankCapitalAccount?.swift}
            title={`${t('BankDetails')} ${company.name}`}
          />
          <Card bg="gray.50" boxShadow="none">
            <CardHeader as={Stack}>
              <Text fontSize="md" fontWeight="600">
                {t('CapitalDeposit')}
              </Text>
              <HStack justify="space-between">
                <Text color="gray.500" fontSize="sm" fontWeight="600">
                  {t('SubscriptionOfShares')}
                </Text>
                <Text fontSize="sm" fontWeight="600">
                  {formatNumberCurrency(capitalDepositTotal)}
                </Text>
              </HStack>
            </CardHeader>
            <CardBody as={Stack} spacing="2">
              <Divider />
              <HStack justifyContent="space-between" pt="1">
                <Text fontSize="md" fontWeight="600">
                  {t('Total', { nb: 1 })}
                </Text>
                <Text fontSize="md" fontWeight="600">
                  {formatNumberCurrency(capitalDepositTotal)}
                </Text>
              </HStack>
            </CardBody>
          </Card>
        </ModalBody>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type SPVMatriculationBankDetailsModalProps = Props;
