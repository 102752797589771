import { Icon, Skeleton, Stack, Text } from '@chakra-ui/react';
import { CubeTransparentIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { OperationStatus } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { OperationCards } from './OperationCards';
import { OperationTable } from './OperationTable';
import { useGetOperations } from './hook';
import { OperationRowDisplayType } from './types';

type Props = {
  displayType: OperationRowDisplayType;
};

/**
 * OperationRowsPassed.
 * Query and display PASSED operations.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationRowsPassed({ displayType }: Props): JSX.Element {
  const t = useTranslations();

  const { operations, operationsError, operationsLoading } = useGetOperations(
    OperationStatus.FINALIZED,
  );

  if (operationsLoading) {
    return (
      <Stack gap="3">
        <Skeleton minH="111px"></Skeleton>
        <Skeleton minH="111px"></Skeleton>
        <Skeleton minH="111px"></Skeleton>
      </Stack>
    );
  }

  if (operationsError) {
    return <ErrorQueryCard h="200px" />;
  }

  const isEmpty = operations.length === 0;

  const RenderOperationRows = (): JSX.Element => {
    if (displayType === 'cards') {
      return <OperationCards operations={operations} />;
    }

    return <OperationTable operations={operations} />;
  };

  return isEmpty ? (
    <Stack layerStyle="emptyState">
      <Icon as={CubeTransparentIcon} boxSize="40px" color="gray.500" />
      <Text>{t('NoPassedOperation')}</Text>
    </Stack>
  ) : (
    <RenderOperationRows />
  );
}
