import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { Operation, OperationType } from '@blockpulse3/graphql/hooks';
import {
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';

import { CompleteOperationForm } from './CompleteOperationForm';

type Props = {
  /** Operation ID to complete **/
  operationId?: Operation['id'];
  /** Asset ID to preselect in the form if no `operationId` arg **/
  assetId?: string;
  /** Operation type to preselect in the form if no `operationId` arg **/
  operationType?: OperationType;
  /** Callback to close the modal **/
  onClose: () => void;
  /** Callback to execute when the operation is completed **/
  onCompleted: (operationId: string) => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

/**
 * CompleteOperationModal.
 * Complete an operation that was imported.
 * If no `operationId` is provided, create an operation placeholder instead.
 *
 * @returns {JSX.Element}
 */
export function CompleteOperationModal({
  operationId,
  assetId,
  operationType,
  onClose,
  onCompleted,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const [isLoading, setIsLoading] = useState(false);

  const handleModalClose = (): void => {
    onClose();
  };

  return (
    <ResponsiveModal onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('CompleteOperation')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Alert mb="4" status="info">
            <AlertIcon />
            <AlertTitle>{t('ImportedOperationToComplete')}</AlertTitle>
          </Alert>
          <CompleteOperationForm
            assetId={assetId}
            operationId={operationId}
            operationType={operationType}
            setIsLoading={setIsLoading}
            onCompleted={onCompleted}
          />
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button
            form="complete-operation"
            isDisabled={isLoading}
            isLoading={isLoading}
            type="submit"
          >
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
