import { Navigate, Route, Routes } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { DistributionView, DistributionsView } from '@blockpulse3/web-client/bank';
import {
  InvitationView,
  UserDashboardView,
  UserDocumentsView,
} from '@blockpulse3/web-client/dashboard';
import { SubscriptionView } from '@blockpulse3/web-client/subscription';
import { UserSettingsView } from '@blockpulse3/web-client/user';

import { AppLayout } from '../layouts';
import { BankRoutes } from './BankRoutes';
import { UserStateRedirects } from './UserStateRedirects';

type Props = unknown;

/**
 * UserWorkspaceRoutes.
 * Renders all user related routes, set workspace to null.
 * userIdentity will be used instead.
 *
 * @returns {JSX.Element}
 */
export function UserWorkspaceRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<BankRoutes />} path="*" />
      <Route element={<AppLayout />}>
        <Route element={<UserStateRedirects />}>
          <Route index element={<UserDashboardView />} />
        </Route>
        <Route element={<SubscriptionView />} path={routes.subscription.href} />
        <Route element={<UserDocumentsView />} path={routes.me.documents.href} />
        <Route element={<UserSettingsView />} path={routes.me.settings.href} />
        <Route element={<InvitationView />} path={routes.me.invitations.href} />
        <Route element={<DistributionsView />} path={routes.me.payments.href} />
        <Route element={<DistributionView />} path={routes.me.payment.href} />
        <Route element={<Navigate replace to={routes.me.href} />} path="*" />
      </Route>
    </Routes>
  );
}

export type UserWorkspaceRoutesProps = Props;
