import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

type MenuAction = {
  label: string;
  icon: React.ComponentType;
  ariaLabel: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
};

export type OperationRowMenuProps = {
  actions: MenuAction[];
};

export function OperationRowMenu({ actions }: OperationRowMenuProps): JSX.Element {
  const t = useTranslations();

  const isLoading = actions.some((action) => action.loading);

  return (
    <Menu>
      <MenuButton
        as={Button}
        fontSize="sm"
        isLoading={isLoading}
        rightIcon={<Icon as={DotsVerticalIcon} boxSize="4" />}
        variant="secondary"
      >
        {t('Action', { nb: 2 })}
      </MenuButton>
      <MenuList>
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            aria-label={action.ariaLabel}
            fontSize="sm"
            icon={<Icon as={action.icon} boxSize="5" color="gray.500" />}
            isDisabled={action.disabled || action.loading}
            onClick={action.onClick}
          >
            {action.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
