import {
  Card,
  Link as ChakraLink,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { DocumentDuplicateIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import {
  CompanyStatus,
  Identity,
  IdentityType,
  ManagementType,
  Manager,
  ManagerEntityInfosFragment,
  Member,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorQueryCard,
  IconButtonWithTooltip,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  PaginationButtons,
  TableContainer,
} from '@blockpulse3/ui/commons';
import { PaginationHandlers } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = PaginationHandlers<ManagerEntityInfosFragment> & {
  type: ManagementType;
  /* ** Callback on delete button click ** */
  onRowDeleteClick?: (managerId: Manager['id']) => void;
};

/**
 * SpaceManagementsTable.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SpaceManagementsTable({
  onRowDeleteClick = noop,
  type,
  results,
  ...paginationProps
}: Props): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const [tooltipId, setTooltipId] = useState<Member['id'] | null>(null);

  if (paginationProps.loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (!results) {
    return <ErrorQueryCard h="200px" />;
  }

  const handleIdCopyClick = (id: Identity['id']): void => {
    navigator.clipboard.writeText(id);
    setTooltipId(id);
    setTimeout(() => {
      setTooltipId(null);
    }, 3000);
  };

  const handleRowDeleteClick = (id: Manager['id']): void => {
    onRowDeleteClick(id);
  };

  if (results.length === 0) {
    return (
      <Stack layerStyle="emptyState">
        <Text>{t('NoResult')}</Text>
      </Stack>
    );
  }

  const handleRowEditClick = (companyId?: string): void => {
    if (!companyId) return;
    navigate(generatePath(routes.manage.company.full, { companyId }));
  };

  return (
    <Stack>
      <TableContainer maxH="none">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>{t('Name')}</Th>
              <Th>id</Th>
              <Th>{t('Share', { nb: 2 })}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {results.map(({ id, identity }) => {
              const managementLink =
                identity.type === IdentityType.COMPANY
                  ? generatePath(routes.manage.company.full, {
                      spaceId,
                      companyId: identity.companyIdentity?.id || '',
                    })
                  : generatePath(routes.manage.individual.full, {
                      spaceId,
                      individualIdentityId: identity.individualIdentity?.id || '',
                    });

              return (
                <Tr key={identity.id}>
                  <Td>
                    <IdentityCard>
                      <IdentityAvatar src={identity.profilePicture} type={identity.type} />
                      <ChakraLink as={Link} to={managementLink}>
                        <IdentityCardTitle fontSize="md" isChecked={identity.isVerified}>
                          <Text fontWeight="600">{identity.name}</Text>
                          {identity.type === IdentityType.COMPANY && (
                            <Text>({identity.identifier})</Text>
                          )}
                        </IdentityCardTitle>
                      </ChakraLink>
                      <IdentityCardDescription>{identity.email}</IdentityCardDescription>
                    </IdentityCard>
                  </Td>
                  <Td>
                    <HStack spacing="1">
                      <Text color="gray.500">{identity.id}</Text>
                      <Tooltip
                        hasArrow
                        isOpen={tooltipId === identity.id}
                        label={t('Copied')}
                        placement="top"
                      >
                        <Icon
                          as={DocumentDuplicateIcon}
                          boxSize="20px"
                          cursor="pointer"
                          onClick={(): void => handleIdCopyClick(identity.id)}
                        />
                      </Tooltip>
                    </HStack>
                  </Td>
                  <Td>
                    <HStack spacing="1">
                      {type === ManagementType.OWNER &&
                        identity.companyIdentity?.status === CompanyStatus.DRAFT && (
                          <IconButtonWithTooltip
                            aria-label="edit"
                            icon={<Icon as={PencilIcon} boxSize="18px" color="gray.700" />}
                            label={t('Edit')}
                            variant="secondary"
                            onClick={(): void => handleRowEditClick(identity.companyIdentity?.id)}
                          />
                        )}
                      <IconButtonWithTooltip
                        aria-label="delete"
                        icon={<Icon as={TrashIcon} boxSize="18px" color="gray.700" />}
                        label={t('Delete')}
                        variant="secondary"
                        onClick={(): void => handleRowDeleteClick(id)}
                      />
                    </HStack>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationButtons
        currentPage={paginationProps.currentPage}
        hasNextPage={paginationProps.pageInfo.hasNextPage}
        hasPreviousPage={paginationProps.pageInfo.hasPreviousPage}
        loading={paginationProps.isLoadingMore}
        pageCount={paginationProps.pageCount}
        onNextPage={paginationProps.handleNextPage}
        onPreviousPage={paginationProps.handlePreviousPage}
      />
    </Stack>
  );
}

export type SpaceManagementsTableProps = Props;
