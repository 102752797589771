import { GetSubscriptionInfosFragment } from '@blockpulse3/graphql/hooks';

import { SubscriptionPanelInvestmentForm } from './types';

export function formatSubscription(
  subscription: GetSubscriptionInfosFragment,
): SubscriptionPanelInvestmentForm {
  return {
    amount: subscription.investAmount || 0,
    minimalAmount: subscription.minimalAmount || null,
    maximalAmount: subscription.maximalAmount || null,
  };
}
