import {
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { generatePath, resolvePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Exceptions, routes } from '@blockpulse3/data/shared';
import {
  IdentityVerificationStatus,
  SubscriptionStepStatus,
  SubscriptionStepType,
  useGenerateSwanAuthUrlLazyQuery,
  useGetBankAccountByCompanyQuery,
  useGetBankAccountStatusByCompanyQuery,
  useGetOperationQuery,
  useGetSubscriptionQuery,
  useOnboardCompanyMutation,
  useOnboardSubscriptionMutation,
} from '@blockpulse3/graphql/hooks';
import { ResponsiveModalFooter, useErrorToast } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { useVerificationStepContext } from '../../providers';
import { UserVerificationApproved } from '../UserVerificationStep/UserVerificationApproved';
import { UserVerificationPending } from '../UserVerificationStep/UserVerificationPending';

type Props = {
  onClose: () => void;
};

let interval: NodeJS.Timeout | null = null;

export function SwanIdentityOnboardingStep({ onClose }: Props): JSX.Element {
  const t = useTranslations();
  const popupWindowRef = useRef<Window | null>(null);
  const errorToast = useErrorToast();

  const { subscriptionId = '', operationId = '', companyId = '' } = useParams();

  const [isOnboardingInProgress, setIsOnboardingInProgress] = useState(false);

  // Operation issuer case
  const operationReq = useGetOperationQuery({
    variables: {
      operationId,
    },
    skip: !operationId,
  });
  const operation = operationReq.data?.operation;

  const { identityId: sessionIdentityId } = useIdentity();
  const identityId = operation?.company?.identity?.id || sessionIdentityId || '';

  const bankAccountReq = useGetBankAccountByCompanyQuery({
    variables: {
      companyId: companyId || '',
    },
    skip: !operation || operation?.isExternalIBAN,
  });
  const bankAccount = bankAccountReq.data?.getBankAccountByCompany;

  const bankAccountStatusReq = useGetBankAccountStatusByCompanyQuery({
    variables: {
      companyId: companyId || '',
    },
    skip: !operation || operation?.isExternalIBAN || bankAccountReq.loading || !!bankAccount,
  });
  const bankAccountStatus =
    bankAccountStatusReq.data?.getBankAccountStatusByCompany || IdentityVerificationStatus.NONE;

  // Subscriber case
  const subscriptionReq = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = subscriptionReq.data?.subscription;
  const swanOnboardingStep = subscription?.data.mergedSteps.find(
    (step) =>
      step.type === SubscriptionStepType.SWAN_ONBOARDING && step.side === subscription?.side,
  );
  const stepStatus = swanOnboardingStep?.status;

  const [onboardSubscription, { loading: onboardingSubLoading }] = useOnboardSubscriptionMutation();
  const [onboardCompany, { loading: onboardingCoLoading }] = useOnboardCompanyMutation();
  const onboardingLoading = onboardingSubLoading || onboardingCoLoading;
  const [generateSwanAuthUrl] = useGenerateSwanAuthUrlLazyQuery();
  const { activeSteps, stepIndex } = useVerificationStepContext();

  // Function triggered by child popup window on close
  window.closePopup = async function (): Promise<void> {
    if (!popupWindowRef.current) return;
    if (interval) clearInterval(interval);
    popupWindowRef.current.close();
    setTimeout(() => {
      setIsOnboardingInProgress(false);
      if (subscriptionId) {
        subscriptionReq.refetch();
      } else if (operationId) {
        operationReq.refetch();
        bankAccountReq.refetch();
        bankAccountStatusReq.refetch();
      }
    }, 2000);
  };

  useEffect(() => {
    interval = setInterval(() => {
      // Reset loader when popup window is closed
      if (popupWindowRef?.current?.closed) {
        setIsOnboardingInProgress(false);
      }
    }, 500);

    return (): void => {
      if (interval) clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const finalizeOnboarding = (): void => {
    const redirectUrlState = resolvePath(
      routes.bank.finalizeSwanOnboarding.href,
      companyId ? generatePath(routes.company.href, { companyId }) : routes.me.href,
    ).pathname;

    generateSwanAuthUrl({
      variables: {
        identityId,
        redirectUrlState,
      },
      onCompleted: (data) => {
        setIsOnboardingInProgress(true);
        const swanAuthorizationUrl = data.generateSwanAuthUrl;

        const popupWidth = 500;
        const popupHeight = 700;

        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width =
          window.innerWidth || document.documentElement.clientWidth || window.screen.width;
        const height =
          window.innerHeight || document.documentElement.clientHeight || window.screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft;
        const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop;

        popupWindowRef.current = window.open(
          swanAuthorizationUrl,
          'SwanOAuthPopup',
          `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
        );
      },
    });
  };

  const handleOnboarding = (): void => {
    if (subscriptionId) {
      if (stepStatus === SubscriptionStepStatus.PENDING) return;

      if (stepStatus === SubscriptionStepStatus.IN_PROGRESS) {
        finalizeOnboarding();
      }

      onboardSubscription({
        variables: {
          onboardSubscriptionInput: {
            subscriptionId,
            identityId,
          },
        },
        onCompleted: () => {
          finalizeOnboarding();
        },
        onError: (error) => {
          if (error.message === Exceptions.AlreadyHasBankAccount) {
            subscriptionReq.refetch();
          }
        },
      });
    } else if (operationId && operation) {
      onboardCompany({
        variables: {
          onboardCompanyInput: {
            companyId: operation.company.id,
            redirectUrlState: resolvePath(
              generatePath(routes.company.fundraising.href, { operationId }),
              generatePath(routes.company.href, { companyId: operation.company.id }),
            ).pathname,
          },
        },
        onCompleted: (data) => {
          finalizeOnboarding();
        },
        onError: (error) => {
          if (error.message === Exceptions.AlreadyHasBankAccount) {
            operationReq.refetch();
          } else {
            errorToast({ title: t('BankAccountCreationError') });
          }
        },
      });
    }
  };

  const RenderVerificationModalContent = (): JSX.Element => {
    if (
      (subscriptionId && stepStatus === SubscriptionStepStatus.PENDING) ||
      (operationId && bankAccountStatus === IdentityVerificationStatus.PENDING)
    ) {
      return <UserVerificationPending />;
    }

    if (
      (subscriptionId && stepStatus === SubscriptionStepStatus.VALID) ||
      (operationId && bankAccount)
    ) {
      return <UserVerificationApproved />;
    }

    return (
      <Stack spacing="4">
        <Text fontWeight="500">{t('BankAccountCreationAndIdentityVerificationRequest')}</Text>
        <Button
          isDisabled={onboardingLoading || isOnboardingInProgress}
          isLoading={onboardingLoading || isOnboardingInProgress}
          variant="secondary"
          onClick={handleOnboarding}
        >
          {t('VerifyMyIdentity')}
        </Button>
      </Stack>
    );
  };

  return (
    <ModalContent>
      <ModalHeader>
        <Text>{t('IdentityVerification')}</Text>
        {activeSteps > 1 && (
          <Text color="secondary" fontSize="sm">
            {t('StepInfo', {
              stepCount: stepIndex + 1,
              totalStepCount: activeSteps,
            })}
            {t('BankAccountCreationAndIdentityVerification')}
          </Text>
        )}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody as={Stack} spacing="4">
        <RenderVerificationModalContent />
      </ModalBody>
      <Divider />
      <ResponsiveModalFooter>
        <Button
          isDisabled={onboardingLoading || isOnboardingInProgress}
          width="full"
          onClick={onClose}
        >
          {t('Close')}
        </Button>
      </ResponsiveModalFooter>
    </ModalContent>
  );
}
