import * as yup from 'yup';

import { requiredStringField } from '@blockpulse3/data/shared';

import { IOperationSubscribersCSVModal } from './types';

export const csvFileSchema = yup.object({
  csvFile: requiredStringField,
}) as yup.ObjectSchema<IOperationSubscribersCSVModal>;

export const csvFileDefaultValue = {
  csvFile: '',
};
