import { GetSpvQuery } from '@blockpulse3/graphql/hooks';
import { formatCountry } from '@blockpulse3/helpers';

import { companyInformationsFormDefaults } from './schema';
import { SPVInformationsForm } from './types';

/**
 * getSPVParameters.
 * Format fetched spv informations into form.
 *
 * @param {GetSpvQuery['company'] | undefined} data
 * @returns {SPVInformationsForm}
 */
export function getSPVParameters(data: GetSpvQuery['company'] | undefined): SPVInformationsForm {
  if (!data) return companyInformationsFormDefaults;

  return {
    name: data?.name || '',
    address: {
      line: data?.address?.line || '',
      city: data?.address?.city || '',
      postalCode: data?.address?.postalCode || '',
      country: {
        label: formatCountry(data?.address?.country || 'FRA'),
        value: data?.address?.country || 'FRA',
      },
    },
    target: {
      name: data?.target?.name || '',
      registrationEntity: data?.target?.registrationEntity || '',
      registrationNumber: data?.target?.registrationNumber || '',
    },
    revocationMajority: {
      label: data?.revocationMajority || '',
      value: data?.revocationMajority || null,
    },
  };
}
