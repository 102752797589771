import * as yup from 'yup';

import { FormErrors, requiredPasswordField } from '@blockpulse3/data/shared';

export const schema = yup
  .object({
    currentPassword: yup.string().required(FormErrors.RequiredField),
    newPassword: requiredPasswordField,
    confirm: yup
      .string()
      .required(FormErrors.RequiredField)
      .test(
        'password-confirmation',
        FormErrors.PasswordMatch,
        (value, context) => value === context.parent.newPassword,
      ),
  })
  .required();
