import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  FormControl,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { ParseError } from 'papaparse';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { IntlCSVParseError } from '@blockpulse3/data/locales/types';
import { Exceptions, FormErrors, noop } from '@blockpulse3/data/shared';
import { SubscriptionSide } from '@blockpulse3/graphql/hooks';
import {
  DownloadCsvTemplate,
  DropzoneFiles,
  DropzoneInput,
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
  useSuccessToast,
  useWarningToast,
} from '@blockpulse3/ui/commons';

import { csvFileDefaultValue, csvFileSchema } from './schema';
import { IOperationSubscribersCSVModal, OperationCSVType } from './types';
import { operationCsvLabels } from './utils';

type Props = {
  /* ** Callback on modal close ** */
  onClose: () => void;
  /* ** Callback when modal submit is completed ** */
  onComplete?: () => void;
  operationId: string;
  operationCsvType: OperationCSVType;
} & Omit<ResponsiveModalProps, 'children'>;

export function OperationSubscribersCSVModal({
  onClose,
  onComplete = noop,
  operationId,
  operationCsvType,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nCSVParseError = useTranslations('CSVParseError');

  const [files, setFiles] = useState<DropzoneFiles>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const errorToast = useErrorToast();
  const warningToast = useWarningToast();
  const successToast = useSuccessToast();

  const { csvTemplateLink, successToastTitle, submitTitle, alertTitle } =
    operationCsvLabels[operationCsvType];

  const { control, handleSubmit, setValue, setError, clearErrors, formState, resetField } =
    useForm<IOperationSubscribersCSVModal>({
      defaultValues: csvFileDefaultValue,
      resolver: yupResolver(csvFileSchema),
      mode: 'onChange',
    });

  const handleFileDrop = (files: File[]): void => {
    clearErrors('csvFile');
    setValue('csvFile', 'document.csv', { shouldValidate: true });
    setFiles({ [files[0].name]: files[0] });
  };

  const handleFileDelete = (): void => {
    clearErrors('csvFile');
    setValue('csvFile', '', { shouldValidate: true });
    setFiles({});
  };

  const handleFileError = (): void => {
    setError('csvFile', { message: FormErrors.InvalidFileFormat });
  };

  const handleModalClose = (): void => {
    resetField('csvFile');
    setFiles({});
    onClose();
  };

  const handleCSVSubmit = async (): Promise<void> => {
    setFiles({});
    const file = Object.values(files)[0];
    if (file && operationId) {
      const formData = new FormData();
      formData.append('operationId', operationId);
      formData.append('subscriptions', file);
      // Append subscription side to secondary operation case
      if (
        [OperationCSVType.SECONDARY_BUYER, OperationCSVType.SECONDARY_SELLER].includes(
          operationCsvType,
        )
      ) {
        const subscriptionSide =
          operationCsvType === OperationCSVType.SECONDARY_BUYER
            ? SubscriptionSide.BUYER
            : SubscriptionSide.SELLER;

        formData.append('subscriptionSide', subscriptionSide);
      }
      try {
        setIsUploading(true);
        const rep = await axios.post(
          process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/importSubscriptions',
          formData,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        if (rep.data > 0) {
          successToast({ title: t(successToastTitle) });
        } else {
          warningToast({ title: t('FileIsEmpty') });
        }
        setIsUploading(false);
      } catch (err: unknown) {
        if (axios.isAxiosError(err)) {
          const error = err?.response?.data as ParseError;
          if (!error.code && error.message === Exceptions.DisposableEmail) {
            errorToast({ title: i18nCSVParseError('DisposableEmail') });
          } else if (!error.code && error.message === Exceptions.MissingRequiredProperties) {
            errorToast({ title: t('SomeRequiredInfoMissing') });
          } else {
            const code = error.code || 'DefaultError';
            errorToast({ title: i18nCSVParseError(code as IntlCSVParseError) });
          }
        }
        setIsUploading(false);
      }
    }
    handleModalClose();
    onComplete();
  };

  return (
    <ResponsiveModal onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('CSVImport')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="4">
            <Alert status="info">
              <AlertIcon />
              <AlertTitle>
                <DownloadCsvTemplate path={csvTemplateLink} />
              </AlertTitle>
            </Alert>
            {alertTitle && (
              <Alert status="warning">
                <AlertIcon />
                <AlertTitle>{t(alertTitle)}</AlertTitle>
              </Alert>
            )}
            <form onSubmit={handleSubmit(handleCSVSubmit)}>
              <Controller
                control={control}
                name="csvFile"
                render={(): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.csvFile}>
                    <DropzoneInput
                      accept={{ 'text/csv': [] }}
                      files={files}
                      icon={<Icon as={DocumentTextIcon} boxSize="42px" strokeWidth="1px" />}
                      subTitle="CSV de 5M"
                      onDelete={handleFileDelete}
                      onDrop={handleFileDrop}
                      onError={handleFileError}
                    />
                    <ErrorMessage error={formState.errors?.csvFile} />
                  </FormControl>
                )}
              />
            </form>
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button isDisabled={isUploading} variant="secondary" onClick={handleModalClose}>
            {t('Cancel')}
          </Button>
          <Button
            isDisabled={!formState.isValid || isUploading}
            isLoading={isUploading}
            variant="primary"
            onClick={handleCSVSubmit}
          >
            {t(submitTitle)}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type OperationSubscribersCSVModalProps = Props;
