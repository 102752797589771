import { OptionProps, chakraComponents } from 'chakra-react-select';

import { IdentitySelectOption, IdentitySelectOptionGroup } from '../types';
import { IdentitySelectBadge } from './IdentitySelectBadge';

export type IdentitySelectOptionProps = OptionProps<
  IdentitySelectOption,
  false,
  IdentitySelectOptionGroup
>;

export const Option = (props: IdentitySelectOptionProps): JSX.Element => {
  const { identity } = props.data;

  return (
    <chakraComponents.Option {...props}>
      <IdentitySelectBadge identity={identity} />
    </chakraComponents.Option>
  );
};
