import { InfoIcon } from '@chakra-ui/icons';
import { Icon, Stack, Text, Tooltip } from '@chakra-ui/react';

type Props = {
  label: string;
};

export function QuestionTooltip({ label }: Props): JSX.Element {
  return (
    <Tooltip
      hasArrow
      placement="right"
      label={
        <Stack>
          {label.split('\n').map((label, index) => {
            return <Text key={index}>{label}</Text>;
          })}
        </Stack>
      }
    >
      <Icon as={InfoIcon} color="gray.500" mx="1" verticalAlign="text-bottom" />
    </Tooltip>
  );
}
