import {
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputRightAddon,
  Text,
} from '@chakra-ui/react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import { MergedQuestionNumber } from '@blockpulse3/graphql/hooks';
import { ErrorMessage, ExternalLink, Input } from '@blockpulse3/ui/commons';

import { QuestionTooltip } from '../QuestionTooltip';
import { useMifidModal } from '../provider';

type Props = {
  /* ** Current question ** */
  question: MergedQuestionNumber;
};

export function NumberQuestion({ question }: Props): JSX.Element {
  const t = useTranslations();

  const { questionId, label, tooltip, externalLink } = question;

  const { isSectionDisabled } = useMifidModal();
  const { setValue, formState, setError, clearErrors, control } = useFormContext();

  const handleChange = (value: string): void => {
    // Handle negative input errors
    if (parseInt(value) < 0) {
      setError(questionId, {
        type: 'custom',
        message: FormErrors.PositiveFormat,
      });
      // Reset question form state to prevent step validation
      setValue(questionId, []);
      return;
    } else {
      clearErrors(questionId);
    }

    setValue(questionId, [value]);
  };

  return (
    <Controller
      key={questionId}
      control={control}
      name={questionId}
      render={({ field }): JSX.Element => (
        <FormControl my="2">
          <FormLabel htmlFor={questionId}>
            <HStack spacing="1">
              <Text>
                {label}
                {externalLink && <ExternalLink link={externalLink} mx="1" title={t('LearnMore')} />}
                {tooltip && <QuestionTooltip label={tooltip} />}
              </Text>
            </HStack>
          </FormLabel>
          <InputGroup w={{ base: 'full', md: '60%' }}>
            <Input
              defaultValue={field.value}
              id={questionId}
              isDisabled={isSectionDisabled}
              type="number"
              onChange={(event): void => handleChange(event.target.value)}
            />
            {question.isCurrency && <InputRightAddon>€</InputRightAddon>}
            <ErrorMessage error={formState.errors[questionId] as FieldError} />
          </InputGroup>
        </FormControl>
      )}
    />
  );
}
