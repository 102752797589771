import { Badge, Card, HStack, Heading, Hide, Skeleton, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  OptionTokenStatus,
  useGetAssetQuery,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { CompanyIdentityAvatar, ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useBadge } from '@blockpulse3/ui/ui-hooks';

/**
 * OptionHeader.
 *
 * @returns {JSX.Element}
 */
export function OptionHeader(): JSX.Element | null {
  const t = useTranslations();
  const i18nAssetValue = useTranslations('AssetTypeValues');
  const i18nBadgeValues = useTranslations('BadgeValues');

  const { assetId = '', companyId = '' } = useParams();

  const { getBadge } = useBadge(
    null,
    [
      { value: OptionTokenStatus.LOCKED, label: i18nBadgeValues('Locked'), color: 'red' },
      { value: OptionTokenStatus.RUNNING, label: i18nBadgeValues('InProgress'), color: 'green' },
    ],
    { label: i18nBadgeValues('InProgress'), color: 'green' },
  );

  const { data, loading, error } = useGetAssetQuery({
    variables: {
      assetId,
      companyId,
    },
  });

  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  if (error || companyError) {
    return <ErrorQueryCard h="200px" />;
  }

  if (loading || companyLoading || !data) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  const option = data.asset;
  if (!option || option.token?.__typename !== 'OptionTokenModel') return null;

  const badge = getBadge(option.tokenData?.status);
  const company = companyData?.company;

  const expirationDate = option.token.expirationDate;
  const expirationDateFmt = formatDate(expirationDate, 'D MMMM YYYY');
  const remainingDays = expirationDate ? dayjs(expirationDate).diff(dayjs(), 'days') : 0;
  const isPassed = remainingDays <= 0;

  return (
    <Card overflow="hidden">
      <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
        <HStack>
          <Hide below="md">
            <CompanyIdentityAvatar boxSize="32" src={company?.identity?.profilePicture} />
          </Hide>
          <Stack
            alignItems="flex-start"
            justifyContent="center"
            pb="2"
            pt={{ base: '5', md: '2' }}
            px="5"
            spacing="1"
          >
            <Text color="gray.500" fontSize="xs" fontWeight="semibold" textTransform="uppercase">
              {i18nAssetValue(option.assetType)}
            </Text>
            <Heading fontWeight="bold">{option.name}</Heading>
            <Badge colorScheme={badge.color}>{badge.label}</Badge>
          </Stack>
        </HStack>
        <Stack
          alignItems={{ base: 'flex-start', md: 'flex-end' }}
          bg="gray.50"
          justifyContent="center"
          p="5"
          spacing="1"
        >
          <HStack>
            <Text color="gray.600" fontWeight="500">
              {t('FinishOn')}
            </Text>
          </HStack>
          <Text color="gray.600" fontSize="2xl" fontWeight="600">
            {expirationDateFmt}
          </Text>
          {!isPassed && (
            <Text color="gray.600" fontSize="sm" fontWeight="600">
              {t('RemainingDays', { nb: remainingDays })}
            </Text>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
