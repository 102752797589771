import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

const lineVariant = definePartsStyle({
  tablist: {
    overflowX: 'scroll',
    mb: '-2px',
    borderBottom: 0,
  },
  tab: {
    color: 'gray.500',
    fontWeight: '500',
    mb: '0',
    pb: 3,
    flexShrink: '0',
    _selected: {
      color: 'secondary',
      opacity: 1,
    },
  },
  tabpanels: {
    borderTop: '2px solid',
    borderColor: 'inherit',
  },
  tabpanel: {
    paddingTop: 4,
    paddingBottom: 0,
    paddingX: 0,
  },
});

const variants = {
  line: lineVariant,
};

export const tabsTheme = defineMultiStyleConfig({ variants });
