import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  Card,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  IdentityType,
  OptionTokenGrantFragment,
  useGetAssetQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate, getOptionTokenGrantStatus } from '@blockpulse3/helpers';
import {
  AssetBadge,
  ErrorQueryCard,
  IdentityAvatar,
  TableContainer,
} from '@blockpulse3/ui/commons';
import { useGrantBadge } from '@blockpulse3/ui/ui-hooks';

type Props = {
  values?: OptionTokenGrantFragment[];
};

export function OptionSummarySubscribersTable({ values = [] }: Props): JSX.Element | null {
  const t = useTranslations();

  const { getBadge } = useGrantBadge();

  const { assetId = '', companyId = '' } = useParams();

  const { data, loading, error } = useGetAssetQuery({
    variables: {
      assetId,
      companyId,
    },
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="200px" />;
  }

  if (!data) {
    return <ErrorQueryCard h="200px" />;
  }

  const option = data.asset;
  if (!option || option.token?.__typename !== 'OptionTokenModel') return null;

  const optionToken = option.token;

  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>{t('Subscriber', { nb: 1 })}</Th>
            <Th>{t('Status', { nb: 1 })}</Th>
            <Th>{t('AttributionDate')}</Th>
            <Th>{t('AllocatedAmount')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {values.map((grant, index) => {
            const badge = getBadge(getOptionTokenGrantStatus(optionToken, grant));
            return (
              <Tr key={index}>
                <Td fontWeight="600">
                  <HStack spacing="2.5">
                    <IdentityAvatar identity={grant.identity} />
                    <Stack spacing="1">
                      <HStack spacing="1">
                        <Text fontWeight="600">{grant.identity.name}</Text>
                        {grant.identity.isVerified && (
                          <Icon as={CheckCircleIcon} boxSize="15px" color="green.400" />
                        )}
                        {grant.identity.type === IdentityType.COMPANY && (
                          <Text fontWeight="500">({grant.identity.identifier})</Text>
                        )}
                      </HStack>
                      <Text color="gray.500" fontWeight="400">
                        {grant.identity.identifier}
                      </Text>
                    </Stack>
                  </HStack>
                </Td>
                <Td>
                  <Badge colorScheme={badge.color}>{badge.label}</Badge>
                </Td>
                <Td fontWeight="600">
                  {formatDate(dayjs.unix(grant.date).toDate(), 'D MMMM YYYY')}
                </Td>
                <Td>
                  <AssetBadge type={option.assetType} value={grant.amount} />
                </Td>
                <Td>
                  <Button variant="secondary">{t('ShowDetails')}</Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export type OptionSummarySubscribersTableProps = Props;
