import * as yup from 'yup';

import { requiredSelectField, requiredStringField } from '@blockpulse3/data/shared';

import { IUpdateAddressForm } from './types';

export const schema = yup
  .object({
    line: requiredStringField,
    city: requiredStringField,
    postalCode: requiredStringField,
    country: requiredSelectField,
  })
  .required();

export const updateAddressDefaultValues: IUpdateAddressForm = {
  line: '',
  city: '',
  postalCode: '',
  country: { label: '', value: '' },
};
