import { BoxProps, Icon, Stack, Text } from '@chakra-ui/react';
import { XCircleIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

type Props = BoxProps;

/**
 * ErrorQueryCard.
 * Full width stack for error on data fetching.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function ErrorQueryCard({ ...props }: Props): JSX.Element {
  const t = useTranslations();

  return (
    <Stack layerStyle="emptyState" {...props}>
      <Icon as={XCircleIcon} boxSize="40px" color="gray.400" />
      <Stack alignItems="center" spacing="0">
        <Text>{t('OopsInternalProblem')}</Text>
        <Text>{t('ReloadPageOrRetry')}</Text>
      </Stack>
    </Stack>
  );
}

export type ErrorQueryCardProps = Props;
