import {
  CompanyRepresentativeInfosFragment,
  IdentityType,
  IndividualIdentityInfosFragment,
  RepresentativeRole,
} from '@blockpulse3/graphql/hooks';
import { formatCountry, formatNationality } from '@blockpulse3/helpers';

import { representativeFormDefaults } from './schema';
import { IRepresentativeForm } from './types';

export function getRepresentatives(
  data: CompanyRepresentativeInfosFragment[] | undefined | null,
): IRepresentativeForm[] {
  if (!data) {
    return [];
  }

  const presidents = data.filter(
    (rep) =>
      rep.role === RepresentativeRole.PRESIDENT || rep.role === RepresentativeRole.COMPANY_MANAGER,
  );

  return presidents.map((president) => {
    const isCompany = president?.representativeIdentity?.type === IdentityType.COMPANY;

    const company = president?.representativeIdentity?.companyIdentity;
    const individual = president?.representativeIdentity?.individualIdentity;

    const firstName =
      (isCompany ? company?.signer?.individualIdentity?.firstName : individual?.firstName) ||
      representativeFormDefaults.firstName;
    const lastName =
      (isCompany ? company?.signer?.individualIdentity?.lastName : individual?.lastName || '') ||
      representativeFormDefaults.lastName;

    const email =
      (isCompany ? company?.signer?.individualIdentity?.email : individual?.email) ||
      representativeFormDefaults.email;

    const position =
      (isCompany ? company?.signer?.individualRepresentative?.position : '') ||
      representativeFormDefaults.position;

    const _address = isCompany ? company?.address : individual?.address;

    const address: IRepresentativeForm['address'] = {
      line: _address?.line || representativeFormDefaults.address.line,
      city: _address?.city || representativeFormDefaults.address.city,
      postalCode: _address?.postalCode || representativeFormDefaults.address.postalCode,
      country: {
        label: formatCountry(_address?.country || 'FRA'),
        value: _address?.country || representativeFormDefaults.address.country.value,
      },
    };

    return {
      id: president.id,
      /* ** Common ** */
      type: president?.representativeIdentity.type || representativeFormDefaults.type,
      role: president?.role || representativeFormDefaults.role,
      address,
      /* ** Company ** */
      name: company?.name || representativeFormDefaults.name,
      registrationEntity:
        company?.registrationEntity || representativeFormDefaults.registrationEntity,
      registrationNumber:
        company?.registrationNumber || representativeFormDefaults.registrationNumber,
      nominalValue: company?.nominalValue || representativeFormDefaults.nominalValue,
      birthdate: individual?.birthdate || representativeFormDefaults.birthdate,
      birthplace: individual?.birthplace || representativeFormDefaults.birthplace,
      birthCityPostalCode:
        individual?.birthCityPostalCode || representativeFormDefaults.birthCityPostalCode,
      birthCountry: {
        label: formatCountry(individual?.birthCountry || 'FRA'),
        value: individual?.birthCountry || 'FRA',
      },
      corporateForm: company?.corporateForm || representativeFormDefaults.corporateForm,
      creationDate: company?.creationDate || representativeFormDefaults.creationDate,
      shareCapital: company?.shareCapital || representativeFormDefaults.shareCapital,
      nationality: {
        label: formatNationality(individual?.nationality || 'FRA'),
        value: individual?.nationality || 'FRA',
      },
      country: { label: 'FRA', value: 'FRA' },
      businessActivityDescription:
        company?.businessActivityDescription ||
        representativeFormDefaults.businessActivityDescription,
      /* ** Individual ** */
      firstName,
      lastName,
      email,
      position,
      fatherFirstName:
        individual?.filiation?.fatherFirstName || representativeFormDefaults.fatherFirstName,
      fatherLastName:
        individual?.filiation?.fatherLastName || representativeFormDefaults.fatherLastName,
      motherFirstName:
        individual?.filiation?.motherFirstName || representativeFormDefaults.motherFirstName,
      motherLastName:
        individual?.filiation?.motherLastName || representativeFormDefaults.motherLastName,
    };
  });
}

export function getIndividualRepresentative(
  individual: IndividualIdentityInfosFragment | null,
): IRepresentativeForm {
  return {
    ...representativeFormDefaults,
    firstName: individual?.firstName || representativeFormDefaults.firstName,
    lastName: individual?.lastName || representativeFormDefaults.lastName,
    birthdate: individual?.birthdate || representativeFormDefaults.birthdate,
    birthplace: individual?.birthplace || representativeFormDefaults.birthplace,
    birthCityPostalCode:
      individual?.birthCityPostalCode || representativeFormDefaults.birthCityPostalCode,
    birthCountry: {
      label: formatCountry(individual?.birthCountry || 'FRA'),
      value: individual?.birthCountry || 'FRA',
    },
    email: individual?.email || representativeFormDefaults.email,
    address: {
      line: individual?.address?.line || representativeFormDefaults.address.line,
      city: individual?.address?.city || representativeFormDefaults.address.city,
      postalCode: individual?.address?.postalCode || representativeFormDefaults.address.postalCode,
      country: {
        label: formatCountry(individual?.address?.country || 'FRA'),
        value: individual?.address?.country || 'FRA',
      },
    },

    fatherFirstName:
      individual?.filiation?.fatherFirstName || representativeFormDefaults.fatherFirstName,
    fatherLastName:
      individual?.filiation?.fatherLastName || representativeFormDefaults.fatherLastName,
    motherFirstName:
      individual?.filiation?.motherFirstName || representativeFormDefaults.motherFirstName,
    motherLastName:
      individual?.filiation?.motherLastName || representativeFormDefaults.motherLastName,
  };
}
