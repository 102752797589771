import { Card, Icon, Skeleton, Stack, Text } from '@chakra-ui/react';
import { ReceiptTaxIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetOptionAssetsQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { AssetAndOptionList } from './AssetAndOptionList';

export function OptionTable(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const { data, loading, error } = useGetOptionAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId,
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="300px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="300px" />;
  }

  const currentOptions = data?.getOptionAssets || [];
  const isEmpty = currentOptions.length === 0;

  return isEmpty ? (
    <Stack layerStyle="emptyState">
      <Icon as={ReceiptTaxIcon} boxSize="40px" color="gray.500" />
      <Text>{t('NoPlan')}</Text>
    </Stack>
  ) : (
    <AssetAndOptionList displayType="table" options={currentOptions} />
  );
}
