import { Stack } from '@chakra-ui/react';

import { isFeatureEnabled } from '@blockpulse3/data/shared';

import { AssetAndOptionAlert } from './AssetAndOptionAlert';
import { AssetsAndOptions } from './AssetsAndOptions';
import { OptionList } from './OptionList';

type Props = unknown;

/**
 * CapTableView.
 * Main captable view, rendering specific captable informations and the list of shareholders.
 *
 * @returns {JSX.Element}
 */
export function AssetsAndPoolsView(): JSX.Element {
  const isFeatureOptionPool = isFeatureEnabled('optionPool');

  return (
    <Stack spacing="10">
      <AssetAndOptionAlert />
      <AssetsAndOptions showOptions={false} />
      {isFeatureOptionPool && <OptionList />}
    </Stack>
  );
}

export type AssetsAndPoolsViewProps = Props;
