import { Stack, StackProps } from '@chakra-ui/react';

import { ProgressStepProps } from './ProgressStep';

type Props = {
  children: React.ReactElement<ProgressStepProps> | React.ReactElement<ProgressStepProps>[];
} & StackProps;

/**
 * Progress.
 *
 * Simple wrapper to <ProgressStep />.
 * Created for reusability.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function Progress({ children, ...props }: Props): JSX.Element {
  return (
    <Stack
      direction={{ base: 'row', lg: 'column' }}
      maxW={{ base: 'full', lg: 350 }}
      overflowX="auto"
      spacing="5"
      w="full"
      {...props}
    >
      {children}
    </Stack>
  );
}

export type ProgressProps = Props;
