import * as yup from 'yup';

import { FormErrors, requiredNumberField, requiredStringField } from '@blockpulse3/data/shared';
import { CompanyCapitalType } from '@blockpulse3/graphql/hooks';

import { ICompleteCompany } from './types';

export const schemaCompleteCompany = yup.object({
  nominalValue: requiredNumberField,
  shareCapital: requiredNumberField,
  capitalType: requiredStringField,
  minimumShareCapital: yup
    .number()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
    .nullable()
    .test('min-val', FormErrors.MinimalCapitalGreaterThanMaximal, (value, context) => {
      return context.parent.maximumShareCapital >= (value || 0);
    })
    .test('val-required', FormErrors.RequiredField, (value, context) => {
      if (context.parent.capitalType === CompanyCapitalType.VARIABLE && (!value || value === 0)) {
        return false;
      }

      return true;
    })
    .test('min-than-share-cap', FormErrors.AmountGreaterThanShareCap, (value, context) => {
      if (!value || value === 0) {
        return true;
      }
      return value < context.parent.shareCapital;
    }),
  maximumShareCapital: yup
    .number()
    .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
    .nullable()
    .test('max-min', FormErrors.MaximalCapitalLessThanMinimal, (value, context) => {
      return (value || 0) >= context.parent.minimumShareCapital;
    })
    .test('val-required', FormErrors.RequiredField, (value, context) => {
      if (context.parent.capitalType === CompanyCapitalType.VARIABLE && (!value || value === 0)) {
        return false;
      }

      return true;
    })
    .test('max-than-share-cap', FormErrors.AmountLowerThanShareCap, (value, context) => {
      if (!value || value === 0) {
        return true;
      }
      return value > context.parent.shareCapital;
    }),
}) as yup.ObjectSchema<ICompleteCompany>;
