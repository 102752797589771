import { Box, BoxProps, ColorProps, useToken } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { AssetType } from '@blockpulse3/data/shared';
import { BalanceInfosFragment, useGetAssetPricesByCompanyQuery } from '@blockpulse3/graphql/hooks';
import { ILineChartData, SimpleLineChart } from '@blockpulse3/ui/commons';

type Props = {
  companyId: string;
  balance: BalanceInfosFragment;
  underlyingAssetType: AssetType;
  lineColor?: ColorProps['color'];
} & BoxProps;

export function CompanyValuationsChart({
  companyId,
  balance,
  underlyingAssetType,
  lineColor = 'white',
  ...props
}: Props): JSX.Element | null {
  const [lineColorCode] = useToken('colors', [lineColor as string]);

  const { data, loading } = useGetAssetPricesByCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const formattedDatas: ILineChartData[] =
    data?.getAssetPricesByCompany?.map((assetPrice) => ({
      x: dayjs(assetPrice.date).valueOf(),
      y: assetPrice.price,
    })) || [];

  if (underlyingAssetType !== AssetType.ORDINARY_SHARE || formattedDatas.length <= 1 || loading) {
    return null;
  }

  return (
    <Box {...props}>
      <SimpleLineChart
        data={formattedDatas}
        displayAxis={false}
        displayLabel={false}
        displayTooltip={false}
        lineColor={lineColorCode}
      />
    </Box>
  );
}
