import { Button, useDisclosure } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslations } from 'use-intl';

import {
  MemberRole,
  SubscriptionInfosFragment,
  useGetIdentityDocumentsQuery,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { IdentityValidationModal } from '@blockpulse3/ui/commons';
import { useManagedIndividual, useManagerRole } from '@blockpulse3/web-client/auth';

type Props = {
  subscriptionId: SubscriptionInfosFragment['id'];
  isSeller?: boolean;
};

export function ConsultIdentityVerification({
  subscriptionId,
  isSeller = false,
}: Props): JSX.Element | null {
  const t = useTranslations();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const isUserManager = useManagerRole({ subscriptionId }, [MemberRole.COMPLIANCE_OFFICER]);
  const { individual } = useManagedIndividual();

  const { data: subscriptionData, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });
  const subscription = subscriptionData?.subscription;

  const identityId = isSeller
    ? subscription?.sellerIdentity?.id || ''
    : subscription?.buyerIdentity?.id || '';

  const { data, loading } = useGetIdentityDocumentsQuery({
    variables: {
      identityId,
    },
    skip: !identityId,
  });

  const handleValidationCompleted = (): void => {
    refetch();
  };

  const isCompanySigner =
    subscription?.operation?.company?.signer?.individualIdentity?.id === individual?.id;
  const isUserAuthorized = isUserManager || isCompanySigner;

  const hideButton = useMemo(
    () => !data || (data && (!data.getIdentityDocuments.length || !isUserAuthorized)),
    [data, isUserAuthorized],
  );
  if (hideButton) return null;

  return (
    <>
      <Button
        isDisabled={loading}
        isLoading={loading}
        size="sm"
        variant="secondary"
        onClick={onOpen}
      >
        {t('ShowDocuments')}
      </Button>
      {isOpen && (
        <IdentityValidationModal
          identityId={identityId}
          isOpen={isOpen}
          isUserAuthorized={!!isUserAuthorized}
          onClose={onClose}
          onCompleted={handleValidationCompleted}
        />
      )}
    </>
  );
}
