import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { CashIcon, CollectionIcon, CurrencyEuroIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetCompanyCaptableQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberInt } from '@blockpulse3/helpers';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

type Props = unknown;

/**
 * CapTableInformations.
 *
 * @returns {JSX.Element}
 */
export function CapTableInformations(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const { data, loading, error } = useGetCompanyCaptableQuery({
    variables: { companyId },
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="300px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="300px" />;
  }

  if (!data) {
    return <ErrorQueryCard />;
  }

  const { shareCapital, nominalValue, capTable } = data.company || {};
  const { stockTotalSupply, fullyDilutedTotalSupply, fullyDilutedShareCapital } = capTable || {
    stockTotalSupply: 0,
  };

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '8' }}>
      <Card w="full">
        <CardHeader>
          <HStack justifyContent="space-between" spacing="4">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('ShareCapital')}
            </Heading>
            <Icon as={CashIcon} boxSize="5" color="gray.500" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberCurrency(shareCapital || 0)}
          </Text>
          <Tooltip
            hasArrow
            label={t('FullyDiluted', { gender: 'male', nb: 1 })}
            placement="right-end"
          >
            <Text color="gray.600" display="inline-block" fontSize="sm">
              {formatNumberCurrency(fullyDilutedShareCapital || 0)}
            </Text>
          </Tooltip>
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader>
          <HStack justifyContent="space-between" spacing="4">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('SharesCount')}
            </Heading>
            <Icon as={CollectionIcon} boxSize="5" color="gray.500" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberInt(stockTotalSupply || 0)}
          </Text>
          <Tooltip
            hasArrow
            label={t('FullyDiluted', { gender: 'male', nb: 1 })}
            placement="right-end"
          >
            <Text color="gray.600" display="inline-block" fontSize="sm">
              {formatNumberInt(fullyDilutedTotalSupply || 0)}
            </Text>
          </Tooltip>
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader>
          <HStack justifyContent="space-between" spacing="4">
            <Heading color="gray.500" fontSize="md" fontWeight="400" size="sm">
              {t('NominalValue')}
            </Heading>
            <Icon as={CurrencyEuroIcon} boxSize="5" color="gray.500" />
          </HStack>
        </CardHeader>
        <CardBody pt="2">
          <Text color="gray.800" fontSize="lg" fontWeight="700">
            {formatNumberCurrency(nominalValue || 0)}
          </Text>
        </CardBody>
      </Card>
    </Stack>
  );
}

export type CapTableInformationsProps = Props;
