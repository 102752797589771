import { As, Badge, Box, HStack, Icon, Spinner, Stack, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { SubscriptionStepStatus, SubscriptionStepType } from '@blockpulse3/graphql/hooks';
import { useBadge } from '@blockpulse3/ui/ui-hooks';

type Props = {
  /* ** Is step disabled ** */
  isDisabled?: boolean;
  /* ** Is step badge loading ** */
  isBadgeLoading?: boolean;
  /* ** Type of the step ** */
  type: SubscriptionStepType;
  /* ** Status of the step ** */
  status: SubscriptionStepStatus;
  /* ** Left icon ** */
  icon?: As;
  /* ** Title of the step ** */
  title: string;
  /* ** Other step info, subtitle, colors... ** */
  children?: React.ReactNode;
  /* ** Surcharge left node default color ** */
  iconColor?: string;
  /* ** Callback on step click ** */
  onClick?: (status: SubscriptionStepStatus) => void;
};

/**
 * SubscriptionStep.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SubscriptionStep({
  isDisabled = false,
  isBadgeLoading = false,
  type,
  status = SubscriptionStepStatus.NONE,
  title,
  icon,
  children = null,
  onClick = noop,
  iconColor,
}: Props): JSX.Element {
  const t = useTranslations();

  /* ** Background step if disabled ** */
  const bg = isDisabled ? 'gray.100' : 'default';
  /* ** Left icon with status check ** */
  const leftNode = status === SubscriptionStepStatus.VALID ? CheckCircleIcon : icon;
  /* ** Left icon color with status check ** */
  const leftNodeColor =
    iconColor || (status === SubscriptionStepStatus.VALID ? 'green.400' : 'gray.600');
  /* ** Cursor ** */
  const cursor = isDisabled ? 'default' : 'pointer';

  const fullStatus = [
    SubscriptionStepStatus.ERROR,
    SubscriptionStepStatus.IN_PROGRESS,
    SubscriptionStepStatus.PENDING,
  ].includes(status)
    ? `${status}-${type}`
    : status;

  const { badge } = useBadge(
    fullStatus,
    [
      {
        value: SubscriptionStepStatus.VALID,
        color: 'green',
        label: t('Validated'),
      },
      {
        value: SubscriptionStepStatus.IN_PROGRESS,
        color: 'yellow',
        label: t('InProgress'),
      },
      {
        value: SubscriptionStepStatus.PENDING,
        color: 'yellow',
        label: t('VerificationInProgress'),
      },
      {
        value: `${SubscriptionStepStatus.PENDING}-${SubscriptionStepType.PAYMENT}`,
        color: 'yellow',
        label: t('PendingPayment'),
      },
      {
        value: `${SubscriptionStepStatus.IN_PROGRESS}-${SubscriptionStepType.PAYMENT}`,
        color: 'yellow',
        label: t('ScheduledPayment'),
      },
      {
        value: `${SubscriptionStepStatus.ERROR}-${SubscriptionStepType.IDENTITY_VERIFICATION}`,
        color: 'red',
        label: t('VerificationProcessFailedRetry'),
      },
      {
        value: `${SubscriptionStepStatus.ERROR}-${SubscriptionStepType.PAYMENT}`,
        color: 'red',
        label: t('IncompleteAmount'),
      },
      {
        value: SubscriptionStepStatus.WAITING,
        color: isDisabled ? 'blackAlpha' : 'gray',
        label: t('Waiting'),
      },
    ],
    { color: isDisabled ? 'blackAlpha' : 'gray', label: t('RequiredAction', { nb: 1 }) },
  );

  const handleClick = (): void => {
    if (!isDisabled) {
      onClick(status);
    }
  };

  return (
    <Stack
      bg={bg}
      border="1px"
      borderColor="gray.200"
      cursor={cursor}
      direction="column"
      justifyContent="space-between"
      p="4"
      rounded="sm"
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      onClick={handleClick}
    >
      <HStack justifyContent="space-between" spacing="3" w="full">
        <HStack spacing="2">
          <Icon
            alignSelf={{ base: 'flex-start', md: 'center' }}
            as={leftNode}
            boxSize="24px"
            color={leftNodeColor}
            mt={{ base: 1, md: 0 }}
          />
          <Box flex="1" textAlign="left">
            <Text fontSize="xl" fontWeight="semibold">
              {title}
            </Text>
          </Box>
        </HStack>
        <Badge colorScheme={badge.color} justifySelf="right">
          <HStack alignItems="center" spacing="1">
            {isBadgeLoading && <Spinner size="xs" speed="0.7s" />}
            <Text>{badge.label}</Text>
          </HStack>
        </Badge>
      </HStack>
      {children && <Box ml="32px">{children}</Box>}
    </Stack>
  );
}

export type SubscriptionStepProps = Props;
