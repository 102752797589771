import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { generatePath, useMatch, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  LinkButton,
  TabPanelOperationDocuments,
  TabPanelOperationVideoLink,
} from '@blockpulse3/ui/commons';
import { useDisclosureLink } from '@blockpulse3/ui/ui-hooks';
import { IntentPanel } from '@blockpulse3/web-client/operation/commons';

import { OpportunityOperationHeader } from './OpportunityOperationHeader';
import { OpportunityOperationInformations } from './OpportunityOperationInformations';
import { OpportunityOperationIntentsList } from './OpportunityOperationIntentsList';
import { OpportunityOperationStatusAlert } from './OpportunityOperationStatusAlert/OpportunityOperationStatusAlert';

type Props = {
  backHref?: string;
};

/**
 * OpportunityOperationView.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OpportunityOperationView({ backHref }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const opportunityRoutes = routes.company.opportunity;
  const intentPanel = useDisclosureLink({
    hiddenHref: '',
    visibleHref: opportunityRoutes.subscription.href,
  });

  const isSpace = useMatch(routes.space.href + '/*');
  const spaceRoute = routes.space.operations.full;
  const workspaceRoute = generatePath(routes.company.href, { companyId });
  const routeBack = isSpace ? spaceRoute : backHref || workspaceRoute;

  return (
    <Stack spacing="6">
      <LinkButton label={t('BackToDashboard')} route={routeBack} />
      <OpportunityOperationHeader />
      <Tabs isLazy defaultIndex={0}>
        <TabList>
          <Tab>{t('Monitoring')}</Tab>
          <Tab>{t('Document', { nb: 2 })}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel as={Stack} gap="6">
            <OpportunityOperationStatusAlert />
            <OpportunityOperationInformations />
            <OpportunityOperationIntentsList intentPanel={intentPanel} />
            <IntentPanel isOpen={intentPanel.isOpen} onClose={intentPanel.onClose} />
          </TabPanel>
          <TabPanel as={Stack} gap="6">
            <TabPanelOperationDocuments />
            <TabPanelOperationVideoLink />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export type OpportunityOperationViewProps = Props;
