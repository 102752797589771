import { Stack } from '@chakra-ui/react';
import { generatePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { LinkButton } from '@blockpulse3/ui/commons';

import { InvitationList } from './InvitationList';

/**
 * InvitationView.
 * View of subscription invitation tables (STARTED, PASSED).
 *
 * @returns {JSX.Element}
 */
export function InvitationView(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const routeBack = companyId ? generatePath(routes.company.href, { companyId }) : routes.me.href;

  return (
    <Stack spacing="6">
      <LinkButton label={t('BackToDashboard')} route={routeBack} />
      <InvitationList size="lg" />
    </Stack>
  );
}
