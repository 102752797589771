import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Skeleton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import { SignatureDocumentCache } from '@blockpulse3/data/shared';
import {
  CompanyDocumentType,
  DocumentStatus,
  RepresentativeRole,
  SpvStatus,
  useGetCompanyDocumentsQuery,
  useGetSpvQuery,
  usePreviewSpvDocumentLazyQuery,
  useValidateSpvDocumentMutation,
} from '@blockpulse3/graphql/hooks';
import { getURLObject } from '@blockpulse3/helpers';
import {
  ErrorQueryCard,
  CONTENT_ERROR as PDF_URL_ERROR,
  CONTENT_LOADING as PDF_URL_LOADING,
  SignDocumentModal,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { getSPVDocuments } from '../../utils';
import { SPVMatriculationDeposit } from './SPVMatriculationDeposit';
import { SPVMatriculationDocuments } from './SPVMatriculationDocuments';
import { SPVMatriculationIdentity } from './SPVMatriculationIdentity';

export function SPVMatriculation(): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValues = useTranslations('DocumentValues');

  const { companyId = '' } = useParams();

  const { user: authUser } = useAuthUser();

  const signatureModal = useDisclosure();
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const spvReq = useGetSpvQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const companyDocumentsReq = useGetCompanyDocumentsQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const [loadPreview] = usePreviewSpvDocumentLazyQuery();
  const [validateDocument, { loading: isDocumentValidating }] = useValidateSpvDocumentMutation();

  /* ** Signature modal states ** */
  const [signatureModalTitle, setSignatureModalTitle] = useState<string>('');
  const [isSignatureModalDisabled, setIsSignatureModalDisabled] = useState<boolean>(false);
  /* ** Current document in signature ** */
  const [signDocumentType, setSignDocumentType] = useState<CompanyDocumentType | null>(null);
  const [signDocumentStatus, setSignDocumentStatus] = useState<DocumentStatus | null>(null);
  /* ** Cache SPV document previews ** */
  const [documentPreviews, setDocumentPreviews] = useState<
    SignatureDocumentCache<CompanyDocumentType>
  >({
    [CompanyDocumentType.DBE]: PDF_URL_LOADING,
    [CompanyDocumentType.DNC]: PDF_URL_LOADING,
    [CompanyDocumentType.DOMICILIATION]: PDF_URL_LOADING,
    [CompanyDocumentType.POUVOIR_FORMALITES]: PDF_URL_LOADING,
    [CompanyDocumentType.SOUSCRIPTEURS]: PDF_URL_LOADING,
    [CompanyDocumentType.STATUTS]: PDF_URL_LOADING,
    [CompanyDocumentType.FORMULAIRE_M0]: PDF_URL_LOADING,
  });

  if (spvReq.loading || companyDocumentsReq.loading) {
    return (
      <Skeleton>
        <Card h="380px" />
      </Skeleton>
    );
  }

  if (spvReq.error || companyDocumentsReq.error) {
    return <ErrorQueryCard h="380px" />;
  }

  if (!spvReq.data || !companyDocumentsReq.data) {
    return <ErrorQueryCard h="380px" />;
  }

  const company = spvReq.data.company;
  const documents = companyDocumentsReq.data.getCompanyDocuments || [];

  /* ** Preview URL rendered in the signature modal ** */
  const signDocumentURL = signDocumentType ? documentPreviews[signDocumentType] : PDF_URL_LOADING;
  const spvDocuments = getSPVDocuments(documents);

  /* ** Is current user a signer and able to be redirected to SignatureIt ** */
  const isUserPresident = company.companyRepresentative.some(
    (rep) =>
      rep.role === RepresentativeRole.PRESIDENT &&
      rep.representativeIdentity.email === authUser?.email,
  );

  const handlePreviewModalClose = (): void => {
    signatureModal.onClose();
  };

  const handleDocumentSign = (): void => {
    if (!signDocumentType) return;

    validateDocument({
      variables: {
        validateSPVDocumentInput: {
          companyId,
          documentType: signDocumentType,
        },
      },
      onCompleted: (data) => {
        if (data.validateSPVDocument?.signingUrl) {
          window.open(data.validateSPVDocument.signingUrl, '_self');
        } else {
          successToast({ title: t('DocumentSentToSignatory') });
          setIsSignatureModalDisabled(true);
        }
        spvReq.refetch();
        companyDocumentsReq.refetch();
        setSignDocumentStatus(DocumentStatus.ONGOING);
        handlePreviewModalClose();
      },
      onError: () => {
        errorToast({ title: t('SignatureSendingError') });
      },
    });
  };

  const handleDocumentClick = (type: CompanyDocumentType): void => {
    const title = type as IntlDocumentValues;
    const spvDocument = spvDocuments[type];

    setSignDocumentType(type);
    setSignatureModalTitle(i18nDocumentValues(title));

    if (spvDocument) {
      const { lastRemindEmailSentAt, createdAt } = spvDocument.document.signature || {};
      const shouldRemind = dayjs(lastRemindEmailSentAt || createdAt).isBefore(
        dayjs().subtract(1, 'h'),
      );
      setIsSignatureModalDisabled(!shouldRemind);
      setSignDocumentStatus(spvDocument.document.status);
    } else {
      setIsSignatureModalDisabled(false);
      setSignDocumentStatus(null);
    }

    signatureModal.onOpen();

    if (typeof documentPreviews[type] !== 'string') {
      loadPreviewURL(type);
    }
  };

  const loadPreviewURL = (type: CompanyDocumentType): void => {
    loadPreview({
      variables: {
        companyId,
        documentType: type,
      },
      onCompleted: ({ pdfURL }: { pdfURL: string }) => {
        const objURL = getURLObject(pdfURL, 'application/pdf');
        setDocumentPreviews((curr) => ({ ...curr, [type]: objURL }));
      },
      onError: () => {
        setDocumentPreviews((curr) => ({ ...curr, [type]: PDF_URL_ERROR }));
      },
    });
  };

  return (
    <Card>
      <CardHeader>
        <HStack>
          <Heading size="md">{t('MyRegistration')}</Heading>
          <Badge colorScheme="yellow">{t('InProgress')}</Badge>
        </HStack>
      </CardHeader>
      <CardBody>
        <Stack spacing="0">
          {company?.spvStatus === SpvStatus.EDITING && (
            <Alert status="warning">
              <AlertIcon as={ExclamationIcon} />
              <Stack spacing="1">
                <AlertTitle>{t('PendingPayment')}</AlertTitle>
                <AlertDescription>{t('InvoicePaymentRequiredBeforeProceed')}</AlertDescription>
              </Stack>
            </Alert>
          )}
          <SPVMatriculationDocuments onDocumentClick={handleDocumentClick} />
          <SPVMatriculationIdentity />
          <SPVMatriculationDeposit />
        </Stack>
      </CardBody>
      <SignDocumentModal
        isDisabled={isSignatureModalDisabled}
        isOpen={signatureModal.isOpen}
        isSignatureLoading={isDocumentValidating}
        isUserSigner={isUserPresident}
        src={signDocumentURL}
        status={signDocumentStatus}
        title={signatureModalTitle}
        onCancel={handlePreviewModalClose}
        onClose={handlePreviewModalClose}
        onSign={handleDocumentSign}
      />
    </Card>
  );
}
