import { createContext } from 'react';

import { locales, noop } from '@blockpulse3/data/shared';
import { Locale } from '@blockpulse3/graphql/hooks';

export type RootIntlContextType = {
  locale: Locale;
  setLocale: (locale: Locale) => void;
};

const availableLocales = Object.keys(locales);
/* ** Navigator language from priority list. Fallback to french ** */
const locale = (navigator.languages.find((locale) => availableLocales.includes(locale)) ||
  Locale.fr) as Locale;

/* ** Default context values ** */
export const rootIntlContextInit: RootIntlContextType = {
  locale,
  setLocale: noop,
};

export const RootIntlContext = createContext<RootIntlContextType>(rootIntlContextInit);
