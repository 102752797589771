import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { EmailVerificationStatus, useAutoLoginMutation } from '@blockpulse3/graphql/hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = unknown;

export function AutoLogin(): JSX.Element {
  const { refetch } = useAuthUser();
  const [autoLoginMutation, { loading }] = useAutoLoginMutation();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (token && !loading) {
      autoLoginMutation({
        variables: {
          autoLoginInput: {
            token: token || '',
          },
        },
        onCompleted: (data) => {
          if (data?.autoLogin?.access_token) {
            localStorage.setItem('token', data.autoLogin.access_token);
            refetch();
          } else if (
            data?.autoLogin?.process_step === EmailVerificationStatus.REDIRECT &&
            data?.autoLogin?.redirect_url
          ) {
            window.location.href = data?.autoLogin?.redirect_url;
          }
        },
      });
    }
  }, [token, autoLoginMutation, loading, refetch]);

  return <></>;
}

export type AutoLoginProps = Props;
