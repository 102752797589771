import { Skeleton } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { string } from 'yup';

import { noop } from '@blockpulse3/data/shared';
import {
  CompanyCapitalType,
  useGetCompanyQuery,
  useSearchSirenLazyQuery,
  useUpdateCompanyMutation,
} from '@blockpulse3/graphql/hooks';
import { formatCountry } from '@blockpulse3/helpers';
import { ErrorQueryCard, useErrorToast } from '@blockpulse3/ui/commons';

import { ExistingCompanyInformations } from './ExistingCompanyInformations';
import { companyInformationsFormDefaults, companyInformationsFormSchema } from './schema';
import { CompanyInformationsForm } from './types';
import { getCompanyParameters } from './utils';

type Props = {
  /* ** Activate additional fields when editing running company ** */
  isEditing?: boolean;
  /* ** Step cancelled callback ** */
  onCancel?: () => void;
  /* ** Step submitted callback ** */
  onSubmit?: () => void;
};

/**
 * ExistingCompanyParameters.
 * Existing company informations, update step.
 *
 * @returns {JSX.Element}
 */
export function ExistingCompanyParameters({
  isEditing = false,
  onCancel = noop,
  onSubmit = noop,
}: Props): JSX.Element {
  const errorToast = useErrorToast();

  const { companyId = '' } = useParams();

  const [isPrefilled, setIsPrefilled] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<CompanyInformationsForm>(
    companyInformationsFormDefaults,
  );

  const [updateCompanyInformations] = useUpdateCompanyMutation();
  /* ** Pappers /entreprise query ** */
  const [searchSiren] = useSearchSirenLazyQuery();

  const { data, loading, error, refetch } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
    onCompleted: (data) => {
      const defaultValues = getCompanyParameters(data.company);

      setFormValues(defaultValues);

      const shouldPrefill =
        /* ** If company has SIREN ** */
        string().required().isValidSync(defaultValues.registrationNumber) &&
        /* ** And it's partially valid ** */
        !companyInformationsFormSchema.isValidSync(defaultValues);

      if (shouldPrefill) {
        searchSiren({
          variables: { siren: data.company.registrationNumber || '' },
          onCompleted: (siren) => {
            const company = siren?.searchSIREN;

            if (company) {
              const date = dayjs(company?.creationDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

              setFormValues({
                name: company?.name || '',
                country: { label: formatCountry('FRA'), value: 'FRA' },
                registrationEntity: company?.registrationEntity || '',
                registrationNumber: data.company.registrationNumber || '',
                corporateForm: company?.corporateForm || '',
                creationDate: date,
                shareCapital: parseFloat(company?.shareCapital || '0'),
                address: {
                  line: company?.address.line || '',
                  city: company?.address.city || '',
                  postalCode: company?.address.postalCode || '',
                  country: {
                    label: formatCountry(company?.address.country || 'FRA'),
                    value: company?.address.country || 'FRA',
                  },
                },
                businessActivityDescription: company?.businessActivityDescription || '',
              });
              setIsPrefilled(true);
            }
          },
        });
      } else {
        setIsPrefilled(false);
      }
    },
  });

  const handleUpdateExistingCompany = (data: CompanyInformationsForm): void => {
    const { address, country, capitalType, nominalValue, profilePicture, ...rest } = data;

    updateCompanyInformations({
      variables: {
        updateCompanyInput: {
          companyId,
          address: {
            line: address.line,
            city: address.city,
            postalCode: address.postalCode,
            country: address.country.value,
          },
          country: country.value,
          nominalValue: nominalValue || null,
          ...(capitalType?.value && { capitalType: capitalType.value as CompanyCapitalType }),
          ...rest,
        },
      },
      onCompleted: () => {
        onSubmit();
      },
      onError: () => {
        errorToast({ title: 'Erreur update' });
      },
    });
  };

  if (loading) {
    return <Skeleton height="350px" />;
  }

  if (error) {
    return <ErrorQueryCard />;
  }

  if (!data) {
    return <ErrorQueryCard />;
  }

  return (
    <ExistingCompanyInformations
      isRegistrationNumberDisabled
      company={data?.company}
      defaultValues={formValues}
      isEditing={isEditing}
      isPrefilled={isPrefilled}
      refetchCompany={refetch}
      onCancel={onCancel}
      onSubmit={handleUpdateExistingCompany}
    />
  );
}

export type ExistingCompanyParametersProps = Props;
