import { Box, Icon, Tooltip, useClipboard } from '@chakra-ui/react';
import { DocumentDuplicateIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

type Props = {
  textToCopy: string;
};

export function ContentCopy({ textToCopy }: Props): JSX.Element {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const { onCopy } = useClipboard(textToCopy);

  const handleClick = (): void => {
    setIsOpen(true);
    onCopy();
    setTimeout(() => {
      setIsOpen(false);
    }, 1000);
  };

  return (
    <Tooltip hasArrow isOpen={isOpen} label={t('Copied')} placement="top">
      <Box cursor="pointer" title={t('CopyToClipboard')}>
        <Icon as={DocumentDuplicateIcon} boxSize="20px" onClick={handleClick} />
      </Box>
    </Tooltip>
  );
}
