import { Stack, Text } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { SubscriptionDeletedData } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';

type Props = {
  data: SubscriptionDeletedData;
  date: Date;
};

/**
 * ActivitySubscriptionDeleted.
 * Displays the details of subscription deleted activity.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function ActivitySubscriptionDeleted({ data, date }: Props): JSX.Element {
  const t = useTranslations();

  const { validatorName } = data;

  return (
    <Stack spacing={1}>
      <Text fontSize="sm">{t('SubscriptionDeleted')}</Text>
      <Stack color="gray.500" spacing={1}>
        <Text>
          {formatDate(date, 'DD/MM/YYYY')}
          {validatorName && <> &middot; {validatorName}</>}
        </Text>
      </Stack>
    </Stack>
  );
}
