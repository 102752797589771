import { MemberEntityInfosFragment, MemberRole } from '@blockpulse3/graphql/hooks';
import { capitalizeFirstLetter } from '@blockpulse3/helpers';

import { spaceMemberFormDefaultValues } from './schema';
import { SpaceMemberForm } from './types';

/**
 * getMemberRoleOptions.
 * Format MemberRole enum into select options.
 *
 * @returns {SpaceMemberForm['role'][]}
 */
export function getMemberRoleOptions(): SpaceMemberForm['roles'] {
  return Object.values(MemberRole).map((type) => ({
    label: capitalizeFirstLetter(type),
    value: type,
  }));
}

/**
 * getMember.
 * Format fetched member entity into a SpaceMemberForm.
 *
 * @param {MemberEntityInfosFragment | undefined} data
 * @returns {SpaceMemberForm}
 */
export function getMember(data: MemberEntityInfosFragment | undefined): SpaceMemberForm {
  if (!data || !data.user) {
    return spaceMemberFormDefaultValues;
  }

  return {
    email: data.user.email,
    firstName: data.user.firstName,
    lastName: data.user.lastName,
    roles: data.roles.map((role) => ({
      label: role,
      value: role,
    })),
  };
}
