import * as yup from 'yup';

import {
  requiredBooleanField,
  requiredFutureDateField,
  requiredNumberField,
  requiredSelectField,
  requiredStringField,
  requiredTodayOrFutureDateField,
} from '@blockpulse3/data/shared';
import { SecondaryMarketType } from '@blockpulse3/graphql/hooks';

import { ISecondaryOperationInformationsForm } from './types';

export const secondaryOperationInformationsSchema = yup.object({
  name: requiredStringField,
  startSubscriptionDate: requiredTodayOrFutureDateField,
  endSubscriptionDate: requiredFutureDateField,
  asset: requiredSelectField,
  sharePrice: requiredNumberField,
  isPromiseToSell: requiredBooleanField,
  enableSellerInvitation: yup.boolean(),
  usePivotFiscalAccount: requiredBooleanField,
  secondaryMarketType: requiredStringField,
}) as yup.ObjectSchema<ISecondaryOperationInformationsForm>;

export const secondaryOperationInformationsDefaults: ISecondaryOperationInformationsForm = {
  name: '',
  startSubscriptionDate: '',
  endSubscriptionDate: '',
  asset: { label: '', value: '' },
  sharePrice: 0,
  isPromiseToSell: false,
  enableSellerInvitation: true,
  usePivotFiscalAccount: false,
  secondaryMarketType: SecondaryMarketType.TRANSFER_MARKET,
};
