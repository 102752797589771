import * as yup from 'yup';

import { FormErrors, requiredEmailField, requiredStringField } from '@blockpulse3/data/shared';
import { MemberRole } from '@blockpulse3/graphql/hooks';

import { SpaceMemberForm } from './types';

export const schema: yup.ObjectSchema<SpaceMemberForm> = yup.object({
  firstName: requiredStringField,
  lastName: requiredStringField,
  email: requiredEmailField,
  roles: yup
    .array(
      yup.object({
        label: requiredStringField,
        value: yup.mixed<MemberRole>().oneOf(Object.values(MemberRole)).required(),
      }),
    )
    .required(FormErrors.RequiredField),
});

export const spaceMemberFormDefaultValues: SpaceMemberForm = {
  firstName: '',
  lastName: '',
  email: '',
  roles: [
    {
      label: MemberRole.COLLABORATOR,
      value: MemberRole.COLLABORATOR,
    },
  ],
};
