import dayjs from 'dayjs';
import * as yup from 'yup';

import {
  FormErrors,
  OptionAssetType,
  dateField,
  optionalNumberField,
  requiredBooleanField,
  requiredNumberField,
  requiredPastDateField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { TokenDurationUnit } from '@blockpulse3/graphql/hooks';

import { IOptionAsset } from './types';

export const optionAssetTypeOptions = Object.values(OptionAssetType).map((assetType) => ({
  label: assetType,
  value: assetType,
}));

export const durationUnitOptions = [
  { label: TokenDurationUnit.day, value: TokenDurationUnit.day },
  { label: TokenDurationUnit.month, value: TokenDurationUnit.month },
];

export const schema = yup.object({
  name: requiredStringField,
  initialSupply: requiredNumberField,
  remainingSupply: optionalNumberField,
  date: requiredPastDateField,
  expirationDate: dateField.test(
    'before-creation-date',
    FormErrors.DateBeforeCreationDate,
    (value, context) => {
      return dayjs(value).isAfter(dayjs(context.parent.date));
    },
  ),
  durationUnit: yup.object({
    label: requiredStringField,
    value: requiredStringField,
  }),
  attributionPeriod: optionalNumberField.when('$attributionPeriodRequired', {
    is: true,
    then: () => requiredNumberField,
  }),
  subscriptionPeriod: optionalNumberField.when('$subscriptionPeriodRequired', {
    is: true,
    then: () => requiredNumberField,
  }),
  subscriptionPrice: optionalNumberField.when('$subscriptionPriceRequired', {
    is: true,
    then: () => requiredNumberField,
  }),
  exercisePrice: optionalNumberField.when('$exercisePriceRequired', {
    is: true,
    then: () => requiredNumberField,
  }),
  conversionParity: requiredNumberField,
  delegated: requiredBooleanField,
  transferable: requiredBooleanField,
  accelerable: requiredBooleanField,
  approvable: requiredBooleanField,
  hasCurve: requiredBooleanField,
  cliffDuration: optionalNumberField.test(
    'duration-longer-than-vesting',
    FormErrors.DurationLongerThanVesting,
    (value, context) => {
      return context.parent.vestingDuration >= (value || 0);
    },
  ),
  vestingDuration: optionalNumberField,
  intervalDuration: optionalNumberField.test(
    'duration-longer-than-vesting',
    FormErrors.DurationLongerThanVesting,
    (value, context) => {
      return context.parent.vestingDuration >= (value || 0);
    },
  ),
}) as yup.ObjectSchema<IOptionAsset>;

export const defaultValues: IOptionAsset = {
  name: '',
  initialSupply: 0,
  remainingSupply: 0,
  date: '',
  expirationDate: '',
  durationUnit: durationUnitOptions[0],
  attributionPeriod: 0,
  subscriptionPeriod: 0,
  subscriptionPrice: 0,
  exercisePrice: 0,
  conversionParity: 1,
  delegated: false,
  transferable: false,
  accelerable: false,
  approvable: false,
  hasCurve: false,
  cliffDuration: 0,
  vestingDuration: 0,
  intervalDuration: 0,
};
