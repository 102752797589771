import {
  Icon,
  IconButton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { TrashIcon, UserGroupIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  Identity,
  IdentityType,
  ShareholderInfosFragment,
  useGetCompanyCaptableQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  AssetBadge,
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  TableContainer,
} from '@blockpulse3/ui/commons';

import { ISecondaryBuyer } from './types';

type Props = {
  /* ** Table identity table values ** */
  values: ISecondaryBuyer[];
  /* ** Callback on delete buyer ** */
  onDeleteBuyer?: (id: Identity['id']) => void;
  displayAmount: boolean;
};

/**
 * NewSecondaryOperationBuyerTable.
 * Render available buyers of an operation as identities.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationBuyerTable({
  values = [],
  onDeleteBuyer = noop,
  displayAmount = false,
}: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '', operationId = '' } = useParams();

  const { data: operationData, loading: operationLoading } = useGetOperationQuery({
    variables: { operationId },
  });
  const { data, loading, error } = useGetCompanyCaptableQuery({ variables: { companyId } });

  if (!values || values?.length === 0) {
    return (
      <Stack layerStyle="emptyState">
        <Icon as={UserGroupIcon} boxSize="40px" color="gray.500" />
        <Text>{t('NoBuyer')}</Text>
      </Stack>
    );
  }

  if (loading || operationLoading) {
    return (
      <Stack layerStyle="emptyState">
        <Spinner />
      </Stack>
    );
  }

  if (error) {
    return <ErrorQueryCard />;
  }

  const capTable = data?.company?.capTable;
  const shareholders = capTable?.shareholders || [];

  /* ** Return shareholder first match from the captable ** */
  const getCapTableEntry = (identityId: Identity['id']): ShareholderInfosFragment | undefined => {
    return shareholders.find(
      (key) =>
        key.identity.id === identityId && key.assetId === operationData?.operation?.asset?.id,
    );
  };

  const handleDeleteBuyer = (id: Identity['id']): void => {
    onDeleteBuyer(id);
  };

  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>{t('Buyer', { nb: 1 })}</Th>
            {displayAmount && <Th isNumeric>{t('Amount', { nb: 1 })}</Th>}
            <Th>{t('HoldingAssets')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {values?.map((buyer) => {
            const capTableEntry = getCapTableEntry(buyer.identityId);

            return (
              <Tr key={buyer.subscriptionId || buyer.identityId}>
                <Td>
                  <IdentityCard>
                    <IdentityAvatar boxSize="9" src={buyer.profilePicture} type={buyer.type} />
                    <IdentityCardTitle fontSize="md" isChecked={buyer.isVerified}>
                      <Text fontWeight="600">{buyer.name}</Text>
                      {buyer.type === IdentityType.COMPANY && <Text>({buyer.identifier})</Text>}
                    </IdentityCardTitle>
                    <IdentityCardDescription>{buyer.email}</IdentityCardDescription>
                  </IdentityCard>
                </Td>
                {displayAmount && (
                  <Td isNumeric fontWeight="600">
                    {buyer.investAmount ? formatNumberCurrency(buyer.investAmount) : '-'}
                  </Td>
                )}
                <Td>
                  {capTableEntry ? (
                    <AssetBadge type={capTableEntry.assetType} value={capTableEntry.shareCount} />
                  ) : (
                    <Text>-</Text>
                  )}
                </Td>
                <Td padding="0">
                  <IconButton
                    aria-label="delete-buyer"
                    icon={<Icon as={TrashIcon} boxSize="4" />}
                    size="sm"
                    variant="icon-delete"
                    onClick={(): void => handleDeleteBuyer(buyer.identityId)}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export type NewSecondaryOperationBuyerTableProps = Props;
