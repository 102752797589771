import { Button, Container, Heading, Stack } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';

import { nationalityOptions } from '@blockpulse3/data/shared';
import {
  useCreateIndividualIdentityMutation,
  useGetIndividualIdentityByEmailQuery,
} from '@blockpulse3/graphql/hooks';
import { formatCountry } from '@blockpulse3/helpers';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { AddressForm, IAddressForm, addressFormDefaultValues } from './AddressForm';
import { IPersonForm, PersonForm, personFormDefaultValues } from './PersonForm';

type Props = unknown;
type FormKey = 'person' | 'address';

export function IndividualIdentityForm(): JSX.Element {
  const t = useTranslations();

  const { user, refetch } = useAuthUser();

  /* ** Look for identity ** */
  const { data: prefillIdentity } = useGetIndividualIdentityByEmailQuery({
    variables: { email: user?.email || '' },
  });

  /* ** Which form to render ** */
  const [formKey, setFormKey] = useState<FormKey>('person');
  /* ** Hold person form values ** */
  const [person, setPerson] = useState<IPersonForm>(personFormDefaultValues);
  /* ** Hold address form values ** */
  const [address, setAddress] = useState<IAddressForm>(addressFormDefaultValues);

  /* ** Create individual identity ** */
  const [createIndividualIdentity] = useCreateIndividualIdentityMutation();

  const handlePersonFormSubmit = (data: IPersonForm): void => {
    setFormKey('address');
    setPerson(data);
  };

  const handleAddressFormSubmit = (address: IAddressForm): void => {
    if (user) {
      createIndividualIdentity({
        variables: {
          createIndividualIdentityInput: {
            firstName: person.firstName,
            lastName: person.lastName,
            gender: person.gender,
            email: user.email,
            phone: person.phone,
            birthdate: person.birthdate,
            birthplace: person.birthCity,
            nationality: person.nationality.value,
            address: {
              line: address.line,
              city: address.city,
              postalCode: address.postalCode,
              country: address.country.value,
            },
          },
        },
        onCompleted: () => {
          refetch();
        },
      });
    }
  };

  const handleFormKeyChange = (): void => {
    switch (formKey) {
      case 'address':
        setFormKey('person');
        break;
    }
  };

  useEffect(() => {
    if (prefillIdentity?.getIndividualIdentityByEmail) {
      const identity = prefillIdentity.getIndividualIdentityByEmail;
      setPerson({
        ...personFormDefaultValues,
        firstName: identity.firstName || '',
        lastName: identity.lastName || '',
        birthdate: identity.birthdate || '',
        birthCity: identity.birthplace || '',
        nationality: nationalityOptions.find((option) => option.value === identity.nationality) || {
          label: '',
          value: '',
        },
      });
      setAddress({
        ...addressFormDefaultValues,
        ...identity.address,
        country: {
          label: formatCountry(identity.address?.country || ''),
          value: identity.address?.country || '',
        },
      });
    }
  }, [prefillIdentity]);

  return (
    <Container maxW="lg" p="4">
      <Stack spacing="4">
        <Stack alignItems="start" spacing="6">
          <BrandIcon />
          {formKey !== 'person' && (
            <Button
              leftIcon={<ChevronLeftIcon height="20" width="20" />}
              variant="link"
              onClick={handleFormKeyChange}
            >
              {t('Back')}
            </Button>
          )}
          <Heading alignSelf="start" size="lg">
            {t('TellUsMoreAboutYou')}
          </Heading>
        </Stack>
        {formKey === 'person' && (
          <PersonForm defaultValues={person} onSubmit={handlePersonFormSubmit} />
        )}
        {formKey === 'address' && (
          <AddressForm defaultValues={address} onSubmit={handleAddressFormSubmit} />
        )}
      </Stack>
    </Container>
  );
}

export type IndividualIdentityFormProps = Props;
