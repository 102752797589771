import { alertAnatomy } from '@chakra-ui/anatomy';
import { AlertProps, createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  alertAnatomy.keys,
);

const colors = {
  info: 'blue',
  warning: 'yellow',
  error: 'red',
  success: 'green',
  loading: 'gray',
};

const baseStyle = definePartsStyle((props: AlertProps) => {
  const { status = 'info' } = props;

  return {
    title: {
      color: `${colors[status]}.800`,
      fontSize: 'sm',
      fontWeight: 500,
    },
    description: {
      color: `${colors[status]}.700`,
      fontSize: 'sm',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    container: {
      'background': `${colors[status]}.50`,
      'rounded': 'md',
      'alignItems': 'flex-start',

      'button.confirm': {
        bg: `${colors[status]}.700`,
      },
    },
    icon: {
      color: `${colors[status]}.400`,
    },
  };
});

const toastVariant = definePartsStyle({
  container: {
    background: 'white',
  },
  title: {
    color: 'gray.900',
    fontSize: 'sm',
    fontWeight: 500,
    lineHeight: '20px',
  },
  description: {
    color: 'gray.900',
    fontSize: 'sm',
    fontWeight: 400,
    lineHeight: '20px',
  },
});

export const alertTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { toast: toastVariant },
});
