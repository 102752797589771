import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { AssetEntityInfosFragment, AssetType } from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  Input,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';

import { defaultValues, durationUnitOptions, schema } from './schema';
import { IOptionAsset } from './types';
import { getOptionAssetDefaultValues } from './utils';

type Props = {
  assetType: AssetType;
  editAsset?: AssetEntityInfosFragment | null;
  isLoading?: boolean;
  modalTitle?: string;
  modalSubmitText?: string;
  attributionPeriodRequired?: boolean;
  subscriptionPeriodRequired?: boolean;
  subscriptionPriceRequired?: boolean;
  exercisePriceRequired?: boolean;
  onClose: () => void;
  handleFormSubmit: SubmitHandler<IOptionAsset>;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

/**
 * OptionCreateModal.
 * Form to create an Option Asset.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OptionCreateModal({
  assetType,
  editAsset,
  isLoading = false,
  modalTitle,
  modalSubmitText,
  attributionPeriodRequired = true,
  subscriptionPeriodRequired = true,
  subscriptionPriceRequired = true,
  exercisePriceRequired = true,
  onClose = noop,
  handleFormSubmit = noop,
  ...props
}: Props): JSX.Element | null {
  const t = useTranslations();
  const i18nAssetType = useTranslations('AssetTypeValues');

  const { register, control, formState, handleSubmit, reset, watch } = useForm<IOptionAsset>({
    defaultValues: editAsset ? getOptionAssetDefaultValues(editAsset) : defaultValues,
    resolver: yupResolver(schema),
    context: { attributionPeriodRequired, subscriptionPeriodRequired },
  });

  const handleModalClose = (): void => {
    onClose();
    reset({ ...defaultValues });
  };

  const durationUnit = watch('durationUnit');
  const hasCurve = watch('hasCurve');

  const defaultModalTitle = t(editAsset ? 'UpdateOption' : 'AddExistingOption');
  const defaultModalSubmitText = t(editAsset ? 'UpdateOption' : 'AddTheOption');

  return (
    <ResponsiveModal closeOnOverlayClick={false} onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle || defaultModalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="add-option-asset" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <FormControl flex="2" isInvalid={!!formState.errors?.name}>
                <FormLabel htmlFor="name">{t('OptionName')}</FormLabel>
                <Input id="name" type="string" {...register('name')} />
                <ErrorMessage error={formState.errors?.name} />
              </FormControl>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.initialSupply}>
                  <FormLabel htmlFor="initialSupply">{t('InitialQuantity')}</FormLabel>
                  <InputGroup>
                    <Input id="initialSupply" type="number" {...register('initialSupply')} />
                    <InputRightAddon>{i18nAssetType(assetType, { nb: 1 })}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.initialSupply} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.remainingSupply}>
                  <FormLabel htmlFor="remainingSupply">{t('ReminderQuantity')}</FormLabel>
                  <InputGroup>
                    <Input id="remainingSupply" type="number" {...register('remainingSupply')} />
                    <InputRightAddon>{i18nAssetType(assetType, { nb: 1 })}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.remainingSupply} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.date}>
                  <FormLabel htmlFor="date">{t('CreationDate')}</FormLabel>
                  <Input id="date" type="date" {...register('date')} />
                  <ErrorMessage error={formState.errors?.date} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.expirationDate}>
                  <FormLabel htmlFor="expirationDate">{t('ExpirationDate')}</FormLabel>
                  <Input id="expirationDate" type="date" {...register('expirationDate')} />
                  <ErrorMessage error={formState.errors?.expirationDate} />
                </FormControl>
              </Stack>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="4"
                w={{ base: '100%', md: '48%' }}
              >
                <Controller
                  control={control}
                  name="durationUnit"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors?.durationUnit}>
                      <FormLabel htmlFor="durationUnit">{t('DurationUnit')}</FormLabel>
                      <Select
                        id="durationUnit"
                        isSearchable={false}
                        menuPlacement="auto"
                        options={durationUnitOptions}
                        getOptionLabel={(option): string =>
                          option.label ? t(`DurationUnits.${option.label}`) : ''
                        }
                        {...field}
                      />
                      <ErrorMessage error={formState.errors?.durationUnit?.label} />
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.attributionPeriod}>
                  <FormLabel htmlFor="attributionPeriod">{t('AttributionPeriod')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="attributionPeriod"
                      type="number"
                      {...register('attributionPeriod')}
                    />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.attributionPeriod} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.subscriptionPeriod}>
                  <FormLabel htmlFor="subscriptionPeriod">{t('SubscriptionPeriod')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="subscriptionPeriod"
                      type="number"
                      {...register('subscriptionPeriod')}
                    />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.subscriptionPeriod} />
                </FormControl>
              </Stack>
              {(subscriptionPriceRequired || exercisePriceRequired) && (
                <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                  {subscriptionPriceRequired && (
                    <FormControl
                      isInvalid={!!formState.errors?.subscriptionPrice}
                      w={{ base: '100%', md: '48%' }}
                    >
                      <FormLabel htmlFor="subscriptionPrice">{t('SubscriptionPrice')}</FormLabel>
                      <InputGroup>
                        <Input
                          id="subscriptionPrice"
                          step="0.01"
                          type="number"
                          {...register('subscriptionPrice')}
                        />
                        <InputRightAddon>€</InputRightAddon>
                      </InputGroup>
                      <ErrorMessage error={formState.errors?.subscriptionPrice} />
                    </FormControl>
                  )}
                  {exercisePriceRequired && (
                    <FormControl
                      isInvalid={!!formState.errors?.exercisePrice}
                      w={{ base: '100%', md: '48%' }}
                    >
                      <FormLabel htmlFor="exercisePrice">{t('ExercisePrice')}</FormLabel>
                      <InputGroup>
                        <Input
                          id="exercisePrice"
                          step="0.01"
                          type="number"
                          {...register('exercisePrice')}
                        />
                        <InputRightAddon>€</InputRightAddon>
                      </InputGroup>
                      <ErrorMessage error={formState.errors?.exercisePrice} />
                    </FormControl>
                  )}
                </Stack>
              )}
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="4"
                w={{ base: '100%', md: '48%' }}
              >
                <FormControl isInvalid={!!formState.errors?.conversionParity}>
                  <FormLabel htmlFor="conversionParity">{t('ConversionParity')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="conversionParity"
                      step="0.01"
                      type="number"
                      {...register('conversionParity')}
                    />
                    <InputRightAddon>{t('OrdinaryShare', { nb: 3 })}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.conversionParity} />
                </FormControl>
              </Stack>
              <FormControl>
                <Checkbox {...register('delegated')}>
                  <Text>{t('AttributionDelegatedToPresident')}</Text>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register('transferable')}>
                  <Text>{t('TransferOfThisAssetAuthorized')}</Text>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register('accelerable')}>
                  <Text>{t('VestingCanBeAccelerated')}</Text>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register('approvable')}>
                  <Text>{t('AssetExerciseApprovalRequired')}</Text>
                </Checkbox>
              </FormControl>
              <Accordion
                allowToggle
                defaultIndex={hasCurve ? [0] : []}
                index={hasCurve ? [0] : []}
                variant="unstyled"
              >
                <AccordionItem>
                  <HStack>
                    <FormControl>
                      <Checkbox {...register('hasCurve')}>
                        <Text fontWeight="semibold">{t('ExerciseRightsFollowVestingCurve')}</Text>
                      </Checkbox>
                    </FormControl>
                    <AccordionButton maxW="20px">
                      <AccordionIcon />
                    </AccordionButton>
                  </HStack>
                  <AccordionPanel mt="24px" p="0">
                    <Stack spacing="4">
                      <Stack
                        direction={{ base: 'column', md: 'row' }}
                        spacing="4"
                        w={{ base: '100%', md: '48%' }}
                      >
                        <FormControl isInvalid={!!formState.errors?.cliffDuration}>
                          <FormLabel htmlFor="cliffDuration">{t('CliffDuration')}</FormLabel>
                          <InputGroup>
                            <Input
                              id="cliffDuration"
                              type="number"
                              {...register('cliffDuration')}
                            />
                            <InputRightAddon>
                              {t(`DurationUnits.${durationUnit.label}`)}
                            </InputRightAddon>
                          </InputGroup>
                          <ErrorMessage error={formState.errors?.cliffDuration} />
                        </FormControl>
                      </Stack>
                      <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                        <FormControl isInvalid={!!formState.errors?.vestingDuration}>
                          <FormLabel htmlFor="vestingDuration">
                            {t('TotalVestingDuration')}
                          </FormLabel>
                          <InputGroup>
                            <Input
                              id="vestingDuration"
                              type="number"
                              {...register('vestingDuration')}
                            />
                            <InputRightAddon>
                              {t(`DurationUnits.${durationUnit.label}`)}
                            </InputRightAddon>
                          </InputGroup>
                          <ErrorMessage error={formState.errors?.vestingDuration} />
                        </FormControl>
                        <FormControl isInvalid={!!formState.errors?.intervalDuration}>
                          <FormLabel htmlFor="intervalDuration">{t('IntervalDuration')}</FormLabel>
                          <InputGroup>
                            <Input
                              id="intervalDuration"
                              type="number"
                              {...register('intervalDuration')}
                            />
                            <InputRightAddon>
                              {t(`DurationUnits.${durationUnit.label}`)}
                            </InputRightAddon>
                          </InputGroup>
                          <ErrorMessage error={formState.errors?.intervalDuration} />
                        </FormControl>
                      </Stack>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button isDisabled={isLoading} type="button" variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            data-cy="add-option"
            form="add-option-asset"
            isDisabled={isLoading}
            isLoading={isLoading}
            type="submit"
          >
            {modalSubmitText || defaultModalSubmitText}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type OptionCreateModalProps = Props;
