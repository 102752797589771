import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  useGetOptionAssetsQuery,
  useRegisterOperationRegisterExtractMutation,
  useValidateRmtDocumentMutation,
} from '@blockpulse3/graphql/hooks';
import { useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

import { CapTablePreview } from './CapTablePreview';
import { ShareTransferRegisterPreview } from './ShareTransferRegisterPreview';

type Props = unknown;

export function RepatriationShareTransferRegisterSummary(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { companyId = '', operationId } = useParams();
  const navigate = useNavigate();

  const [validateDocument, { loading: validateLoading }] = useValidateRmtDocumentMutation();
  const [registerOperationRegisterExtract] = useRegisterOperationRegisterExtractMutation();

  const optionAssetReq = useGetOptionAssetsQuery({ variables: { companyId, operationId } });
  const optionAssets = optionAssetReq?.data?.getOptionAssets || [];

  const loading = isLoading || validateLoading;

  const handleStepCancel = (): void => {
    if (optionAssets.length) {
      return navigate('../' + routes.company.repatriation.grants.href);
    }
    navigate('../' + routes.company.repatriation.import.href);
  };

  const handleStepSubmit = (): void => {
    if (operationId) {
      setIsLoading(true);
      registerOperationRegisterExtract({
        variables: { companyId, operationId },
        onCompleted: (): void => {
          successToast({ title: t('ShareholdingRepatriationSuccess') });
          navigate('../../../../../' + routes.company.captable.href, {
            relative: 'path',
          });
          setIsLoading(false);
        },
        onError: (): void => {
          errorToast({ title: t('ShareholdingRepatriationError') });
          setIsLoading(false);
        },
      });
    } else {
      validateDocument({
        variables: { companyId },
        onCompleted: (data) => {
          if (data.validateRmtDocument?.signingUrl) {
            window.open(data.validateRmtDocument.signingUrl, '_self');
          } else {
            successToast({ title: t('DocumentSentToSignatory') });
            navigate(routes.company.href.replace(':companyId', companyId));
          }
        },
        onError: () => {
          errorToast({ title: t('SignatureSendingError') });
        },
      });
    }
  };

  return (
    <Card variant="divider-top" width="full">
      <CardHeader as={Stack} spacing="2">
        <Heading size="lg">{t('Summary')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        {operationId ? (
          <>
            <Heading size="md">{t('ShareTransferRegisterExtract')}</Heading>
            <ShareTransferRegisterPreview />
          </>
        ) : (
          <Tabs isLazy>
            <TabList>
              <Tab>{t('CapitalizationTable')}</Tab>
              <Tab>{t('ShareTransferRegister')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <CapTablePreview />
              </TabPanel>
              <TabPanel px="0">
                <ShareTransferRegisterPreview />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button isDisabled={loading} variant="secondary" w="full" onClick={handleStepCancel}>
          {t('Back')}
        </Button>
        <Button isDisabled={loading} isLoading={loading} w="full" onClick={handleStepSubmit}>
          {t(operationId ? 'Validate' : 'ValidateAndCertify')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export type RepatriationShareTransferRegisterSummaryProps = Props;
