import {
  Divider,
  Flex,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ExclamationIcon, LockClosedIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

import { ResponsiveModal, ResponsiveModalProps } from '../ResponsiveModal';
import { ResponsiveModalFooter } from '../ResponsiveModalFooter';
import { CONTENT_ERROR, CONTENT_LOADING } from './SignDocumentModal';

type Props = {
  /* ** Modal title ** */
  title: string;
  /* ** Modal subtitle ** */
  subTitle?: string;
  /* ** Source of the document to preview ** */
  src?: string | symbol | null;
  /* ** Children for action button ** */
  children?: (JSX.Element | null | false)[] | JSX.Element;
  /* ** If we add errorTitle the modal will display an Alert with the errorTitle text  ** */
  errorTitle?: string;
} & Omit<ResponsiveModalProps, 'children'>;

export function PreviewDocumentModal({
  title,
  subTitle = '',
  src = CONTENT_LOADING,
  children,
  errorTitle,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  return (
    <ResponsiveModal scrollBehavior="inside" size="5xl" {...props}>
      <ModalOverlay />
      <ModalContent height="calc(100% - 64px)" marginBottom="32px" marginTop="32px">
        <ModalHeader>
          {title}
          <Text size="md">{subTitle}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0">
          <Stack height="100%" spacing="2">
            {typeof src === 'string' ? (
              <iframe data-cy="pdf-preview" height="100%" src={src} title="PDF preview" />
            ) : (
              <Flex alignItems="center" h="full" justifyContent="center" w="full">
                {src === CONTENT_LOADING && (
                  <Spinner color="secondary" size="xl" speed="0.6s" thickness="4px" />
                )}
                {src === CONTENT_ERROR && (
                  <Stack alignItems="center">
                    {errorTitle ? (
                      <>
                        <Icon as={LockClosedIcon} color="gray.600" height="12" width="12" />
                        <Text>{errorTitle}</Text>
                      </>
                    ) : (
                      <>
                        <Icon as={ExclamationIcon} color="gray.600" height="12" width="12" />
                        <Text>{t('DocumentLoadingError')}</Text>
                      </>
                    )}
                  </Stack>
                )}
              </Flex>
            )}
          </Stack>
        </ModalBody>
        {children && (
          <>
            <Divider />
            <ResponsiveModalFooter>{children}</ResponsiveModalFooter>
          </>
        )}
      </ModalContent>
    </ResponsiveModal>
  );
}

export type PreviewDocumentModalProps = Props;
