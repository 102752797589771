import {
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import {
  CompanyType,
  useGetCompanyLazyQuery,
  useUpdateCompanyTypeMutation,
} from '@blockpulse3/graphql/hooks';
import {
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

type Props = {
  companyId: string;
  currentType: CompanyType;
} & Omit<ResponsiveModalProps, 'children'>;

export function AdminCompaniesTypesModal({ companyId, currentType, ...props }: Props): JSX.Element {
  const t = useTranslations();

  const { onClose } = props;

  const [type, setType] = useState<CompanyType>(currentType);
  const [, { refetch }] = useGetCompanyLazyQuery({
    variables: {
      companyId,
    },
  });

  const successToast = useSuccessToast();
  const [updateCompanyType, { loading }] = useUpdateCompanyTypeMutation();

  const handleSumbit = (): void => {
    updateCompanyType({
      variables: {
        updateCompanyTypeInput: {
          companyId,
          type,
        },
      },
      onCompleted: () => {
        refetch();
        successToast({ title: t('CompanyTypeUpdateSuccess') });
        onClose();
      },
    });
  };

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('AddUserRole')}</ModalHeader>
        <ModalBody>
          <Stack spacing={2}>
            <RadioGroup
              value={type}
              onChange={(newValue): void => setType(newValue as CompanyType)}
            >
              <Stack>
                <Radio value={CompanyType.COMPANY}>{CompanyType.COMPANY}</Radio>
                <Radio value={CompanyType.PARTIAL}>{CompanyType.PARTIAL}</Radio>
                <Radio value={CompanyType.SPV}>{CompanyType.SPV}</Radio>
                <Radio value={CompanyType.TOKENIZED}>{CompanyType.TOKENIZED}</Radio>
              </Stack>
            </RadioGroup>
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button isLoading={loading} onClick={handleSumbit}>
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
