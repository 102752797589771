import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { getUserTimezone } from '@blockpulse3/helpers';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';
import { ErrorMessage, Input } from '@blockpulse3/ui/commons';

import { LoginFormDefaults, schema } from './schema';
import { ILoginForm } from './types';

type Props = {
  /* ** Login form default values, can hold email from redirect ** */
  defaultValues?: ILoginForm;
  loading?: boolean;
  onSubmit: (data: ILoginForm) => void;
};

export function LoginForm({
  defaultValues = LoginFormDefaults,
  loading = false,
  onSubmit,
}: Props): JSX.Element {
  const t = useTranslations();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { register, formState, handleSubmit } = useForm<ILoginForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  /* Styles */
  const bgValue = useBreakpointValue({ base: 'transparent', sm: 'bg-surface' });

  const handleLoginSubmit: SubmitHandler<ILoginForm> = (data: ILoginForm) => {
    data.timezone = getUserTimezone();
    onSubmit(data);
  };

  return (
    <Container bg={bgValue} maxW="lg">
      <Stack spacing="8">
        <Stack align="start" spacing="6">
          <BrandIcon />
          <Heading alignSelf="start" size="lg">
            {t('Welcome')}
          </Heading>
        </Stack>
        <form onSubmit={handleSubmit(handleLoginSubmit)}>
          <Stack spacing="6">
            <Stack spacing="4">
              <FormControl isInvalid={!!formState.errors.email}>
                <FormLabel htmlFor="email">{t('Email', { nb: 1 })}</FormLabel>
                <Input data-cy="email-input" id="email" type="text" {...register('email')} />
                <ErrorMessage error={formState.errors.email} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">{t('Password')}</FormLabel>
                <InputGroup>
                  <Input
                    data-cy="password-input"
                    id="password"
                    isInvalid={!!formState.errors?.password}
                    type={showPassword ? 'text' : 'password'}
                    {...register('password')}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label="preview"
                      display="flex"
                      icon={<Icon as={showPassword ? EyeIcon : EyeOffIcon} boxSize={4} />}
                      variant="unstyled"
                      onClick={(): void => setShowPassword((crr) => !crr)}
                    />
                  </InputRightElement>
                </InputGroup>
                <ErrorMessage error={formState.errors.password} />
                <Link data-cy="forgot-password-link" to={routes.forgotPassword.href}>
                  <Text color="secondary" textAlign="end" textDecoration="underline">
                    {t('ForgotPassword')}
                  </Text>
                </Link>
              </FormControl>
            </Stack>
            <Stack spacing="2">
              <Button data-cy="submit" isDisabled={loading} isLoading={loading} type="submit">
                {t('TwoFactorVerificationSendCode')}
              </Button>
              <Link data-cy="signup-link" to={routes.signup.href}>
                <Text color="secondary" textAlign="center" textDecoration="underline">
                  {t('NoAccountYetSignUp')}
                </Text>
              </Link>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}

export type LoginFormProps = Props;
