import { Badge, Box, HStack, Icon, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { IdentificationIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';

import { useBadge } from '@blockpulse3/ui/ui-hooks';

import { IdentityVerificationModal } from './IdentityVerificationModal';

export type ISubscriptionStepStatus =
  | 'valid'
  | 'error'
  | 'error-identity'
  | 'error-fund'
  | 'in-progress'
  | 'pending'
  | 'none';

type Props = {
  status: ISubscriptionStepStatus;
  refetchData: () => void;
  isDisabled?: boolean;
};

let timeoutId: NodeJS.Timeout | null = null;
/**
 * SubscriptionStep.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function IdentityVerification({
  status = 'none',
  refetchData,
  isDisabled = false,
}: Props): JSX.Element {
  const [isPollingStarted, setIsPollingStarted] = useState<boolean>(false);
  const [isPollingLoading, setIsPollingLoading] = useState<boolean>(false);

  const t = useTranslations();

  const verificationModal = useDisclosure();

  const isVerificationDisabled = isDisabled || status === 'valid';

  /* ** Background step if disabled ** */
  const bg = 'default';
  /* ** Left icon with status check ** */
  const leftNode = status === 'valid' ? CheckCircleIcon : IdentificationIcon;
  /* ** Left icon color with status check ** */
  const leftNodeColor = status === 'valid' ? 'green.400' : 'gray.600';
  /* ** Cursor ** */
  const cursor = isVerificationDisabled ? 'default' : 'pointer';

  useEffect(() => {
    if (!isPollingStarted && status === 'in-progress') {
      setIsPollingStarted(true);
      setIsPollingLoading(true);
      refetchData();

      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsPollingLoading(false);
        setIsPollingStarted(false);
      }, 120_000);
    }

    if (isPollingStarted && status !== 'in-progress') {
      setIsPollingStarted(false);
      setIsPollingLoading(false);
    }
  }, [isPollingStarted, refetchData, status]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const { badge } = useBadge(
    status,
    [
      { value: 'error-identity', color: 'red', label: t('VerificationProcessFailedRetry') },
      { value: 'valid', color: 'green', label: t('Validated') },
      { value: 'in-progress', color: 'yellow', label: t('InProgress') },
      { value: 'pending', color: 'yellow', label: t('VerificationInProgress') },
    ],
    { color: 'gray', label: t('RequiredAction', { nb: 1 }) },
  );

  const handleClick = (): void => {
    if (!isVerificationDisabled) {
      if (['error-identity', 'none'].includes(status)) {
        verificationModal.onOpen();
      }
    }
  };

  return (
    <>
      <Stack
        alignItems={{ base: 'flex-start', md: 'center' }}
        bg={bg}
        cursor={cursor}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        rounded="sm"
        spacing="3"
        w="full"
        onClick={handleClick}
      >
        <HStack>
          <Icon
            alignSelf={{ base: 'flex-start', md: 'center' }}
            as={leftNode}
            boxSize="24px"
            color={leftNodeColor}
            mt={{ base: 1, md: 0 }}
          />
          <Box flex="1" marginLeft="4" textAlign="left">
            <Text fontSize="xl" fontWeight="semibold">
              {t('IdentityVerification')}
            </Text>
          </Box>
        </HStack>
        <Badge colorScheme={badge.color} justifySelf="right">
          <HStack alignItems="center" spacing="1">
            {isPollingLoading && <Spinner size="xs" speed="0.7s" />}
            <Text>{badge.label}</Text>
          </HStack>
        </Badge>
      </Stack>
      {verificationModal.isOpen && (
        <IdentityVerificationModal
          isOpen={verificationModal.isOpen}
          refetchData={refetchData}
          shouldVerifyInformation={false}
          onClose={verificationModal.onClose}
        />
      )}
    </>
  );
}

export type IdentityVerificationProps = Props;
