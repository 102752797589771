import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CreditCardIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

import { ContentCopy } from './ContentCopy';
import { CONTENT_ERROR, CONTENT_LOADING } from './DocumentModal/SignDocumentModal';

type Props = {
  /* ** Bank details section title ** */
  title: string;
  /* ** `<iframe/> src ** */
  src?: string | symbol | null;
  /* ** Optional transaction reference ** */
  referenceLabel?: string | null;
} & BoxProps;

/**
 * BankDetailsCardDocument.
 * Render bank details document (RIB).
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function BankDetailsCardDocument({
  src,
  title,
  referenceLabel,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  return (
    <Card bg="gray.50" boxShadow="none" height="full" {...props}>
      <CardHeader as={HStack}>
        <Icon alignSelf="center" as={CreditCardIcon} boxSize="24px" color="gray.900" />
        <Text fontSize="md" fontWeight="600">
          {title}
        </Text>
      </CardHeader>
      <CardBody as={Stack} flex="unset" height="400px">
        {typeof src === 'string' ? (
          <iframe src={src} style={{ height: '100%' }} title="PDF preview" />
        ) : (
          <Box alignItems="center" display="flex" h="full" justifyContent="center" w="full">
            {src === CONTENT_LOADING && (
              <Spinner color="secondary" size="xl" speed="0.6s" thickness="4px" />
            )}
            {src === CONTENT_ERROR && (
              <Stack alignItems="center">
                <Icon as={WarningTwoIcon} color="gray.500" height="24" width="24" />
                <Text>{t('LoadingError')}</Text>
              </Stack>
            )}
          </Box>
        )}
        {referenceLabel && (
          <Box>
            <Divider marginY="4" />
            <Stack direction="row" justify="space-between" spacing="0">
              <Stack direction="column" spacing="0">
                <Text color="gray.900" fontSize="sm" fontWeight="500">
                  {t('TransferReferenceForTransfer')}
                </Text>
                <Text
                  backgroundColor="indigo.100"
                  borderRadius="3"
                  color="indigo.600"
                  fontSize="sm"
                  fontWeight="600"
                  px="1"
                  py="0.5"
                  width="fit-content"
                >
                  {referenceLabel}
                </Text>
              </Stack>
              <Stack justify="center" spacing="0">
                <ContentCopy textToCopy={referenceLabel} />
              </Stack>
            </Stack>
          </Box>
        )}
      </CardBody>
    </Card>
  );
}

export type BankDetailsCardDocumentProps = Props;
