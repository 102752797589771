import { b64toBlob } from './b64toBlob';

/**
 * getURLObject.
 *
 * @param {string} url
 * @param {string} mimeType
 * @returns {string}
 */
export function getURLObject(url: string, mimeType: string): string {
  let objURL: string;
  if (url.startsWith('http')) {
    objURL = url;
  } else {
    const blob = b64toBlob(url, mimeType);
    objURL = URL.createObjectURL(blob);
  }

  return objURL;
}
