import { Icon, InfoIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { nationalityOptions, noop } from '@blockpulse3/data/shared';
import { RepresentativeRole } from '@blockpulse3/graphql/hooks';
import { alpha3CountryOptions } from '@blockpulse3/helpers';
import { ErrorMessage, Input } from '@blockpulse3/ui/commons';

import { naturalRepresentativeFormSchema, representativeFormDefaults } from '../../schema';
import { IRepresentativeForm } from '../../types';

type Props = {
  /* ** Default form values ** */
  defaultValues?: IRepresentativeForm;
  /* ** Is the Cancel button disabled ** */
  shouldCancel?: boolean;
  /* ** Representative is the president ** */
  isPresident?: boolean;
  /* ** Should fill filitation of the representative ** */
  hasFiliation?: boolean;
  /* ** Callback of the Cancel button ** */
  onCancel?: () => void;
  /* ** Callback of the Submit button ** */
  onSubmit?: (data: IRepresentativeForm) => void;
};

/**
 * NaturalRepresentantForm.
 * Natural version of the RepresentantForm. Uses a forwardRef function to be able to submit it
 * from the outside.
 */
export const NaturalRepresentativeInformationsForm = React.forwardRef<HTMLFormElement, Props>(
  (props, ref) => {
    const {
      defaultValues = representativeFormDefaults,
      shouldCancel = false,
      isPresident = false,
      hasFiliation = false,
      onCancel = noop,
      onSubmit = noop,
    } = props;

    const t = useTranslations();
    const i18nRoleValues = useTranslations('RoleValues');

    const { register, control, formState, handleSubmit, reset } = useForm<IRepresentativeForm>({
      defaultValues,
      resolver: yupResolver(naturalRepresentativeFormSchema),
      context: { hasFiliation },
    });

    const handleFormSubmit: SubmitHandler<IRepresentativeForm> = (data): void => {
      onSubmit(data);
    };

    const handleFormCancel = (): void => {
      onCancel();
    };

    useEffect(() => {
      reset({ ...defaultValues });
    }, [reset, defaultValues]);

    return (
      <form ref={ref} onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="8">
          <Stack spacing="4">
            {!isPresident && (
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <Controller
                  control={control}
                  name="role"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors?.role}>
                      <FormLabel htmlFor="role">{t('Role', { nb: 1 })}</FormLabel>
                      <RadioGroup id="role" {...field}>
                        <HStack spacing="4">
                          <Radio value={RepresentativeRole.PRESIDENT}>
                            {i18nRoleValues(RepresentativeRole.PRESIDENT)}
                          </Radio>
                          <Radio value={RepresentativeRole.COMPANY_MANAGER}>
                            {i18nRoleValues(RepresentativeRole.COMPANY_MANAGER)}
                          </Radio>
                        </HStack>
                      </RadioGroup>
                      <ErrorMessage error={formState.errors?.role} />
                    </FormControl>
                  )}
                />
              </Stack>
            )}
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.firstName}>
                <FormLabel htmlFor="firstName">{t('FirstName')}</FormLabel>
                <Input id="firstName" type="text" {...register('firstName')} />
                <ErrorMessage error={formState.errors?.firstName} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.lastName}>
                <FormLabel htmlFor="lastName">{t('LastName')}</FormLabel>
                <Input id="lastName" type="text" {...register('lastName')} />
                <ErrorMessage error={formState.errors?.lastName} />
              </FormControl>
            </Stack>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.email}>
                <FormLabel htmlFor="email">{t('Email', { nb: 1 })}</FormLabel>
                <Input id="email" type="text" {...register('email')} />
                <ErrorMessage error={formState.errors?.email} />
              </FormControl>
              <Controller
                control={control}
                name="nationality"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.nationality}>
                    <FormLabel htmlFor="nationalityData">{t('Nationality')}</FormLabel>
                    <Select
                      id="nationalityData"
                      menuPlacement="auto"
                      options={nationalityOptions}
                      {...field}
                    />
                    <ErrorMessage error={formState.errors?.nationality?.value} />
                  </FormControl>
                )}
              />
            </Stack>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.birthdate}>
                <FormLabel htmlFor="birthdate">{t('BirthDate')}</FormLabel>
                <Input id="birthdate" type="date" {...register('birthdate')} />
                <ErrorMessage error={formState.errors?.birthdate} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.birthplace}>
                <FormLabel htmlFor="birthplace">{t('BirthCity')}</FormLabel>
                <Input id="birthplace" type="text" {...register('birthplace')} />
                <ErrorMessage error={formState.errors?.birthplace} />
              </FormControl>
            </Stack>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.birthCityPostalCode}>
                <FormLabel htmlFor="birthCityPostalCode">{t('BirthPostalCode')}</FormLabel>
                <Input
                  id="birthCityPostalCode"
                  type="string"
                  {...register('birthCityPostalCode')}
                />
                <ErrorMessage error={formState.errors?.birthCityPostalCode} />
              </FormControl>
              <Controller
                control={control}
                name={'birthCountry'}
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors.birthCountry}>
                    <FormLabel htmlFor="birthCountry">{t('BirthCountry')}</FormLabel>
                    <Select
                      id="birthCountry"
                      menuPlacement="auto"
                      options={alpha3CountryOptions}
                      {...field}
                    />
                    <ErrorMessage error={formState.errors?.birthCountry?.value} />
                  </FormControl>
                )}
              />
            </Stack>
            <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
              <Text fontSize="lg" fontWeight="bold">
                {t('Residence')}
              </Text>
              <Stack spacing="4">
                <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                  <FormControl isInvalid={!!formState.errors?.address?.line}>
                    <FormLabel htmlFor="line">{t('Address', { nb: 1 })}</FormLabel>
                    <Input id="line" type="string" {...register('address.line')} />
                    <ErrorMessage error={formState.errors?.address?.line} />
                  </FormControl>
                  <FormControl isInvalid={!!formState.errors?.address?.city}>
                    <FormLabel htmlFor="city">{t('City')}</FormLabel>
                    <Input id="city" type="string" {...register('address.city')} />
                    <ErrorMessage error={formState.errors?.address?.city} />
                  </FormControl>
                </Stack>
                <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                  <FormControl isInvalid={!!formState.errors?.address?.postalCode}>
                    <FormLabel htmlFor="zipcode">{t('PostalCode', { nb: 1 })}</FormLabel>
                    <Input id="zipcode" type="string" {...register('address.postalCode')} />
                    <ErrorMessage error={formState.errors?.address?.postalCode} />
                  </FormControl>
                  <Controller
                    control={control}
                    name="address.country"
                    render={({ field }): JSX.Element => (
                      <FormControl isInvalid={!!formState.errors.address?.country}>
                        <FormLabel htmlFor="country">{t('Country')}</FormLabel>
                        <Select
                          id="country"
                          menuPlacement="auto"
                          options={alpha3CountryOptions}
                          {...field}
                        />
                        <ErrorMessage error={formState.errors.address?.country?.value} />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
            {hasFiliation && (
              <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
                <HStack alignItems="flex-start" spacing="2">
                  <Text fontSize="lg" fontWeight="bold">
                    {t('Lineage')}
                  </Text>
                  <Tooltip hasArrow label={t('RequiredForNonConvictionFiliation')} placement="top">
                    <Icon as={InfoIcon} color="gray.500" />
                  </Tooltip>
                </HStack>
                <Stack spacing="4">
                  <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                    <FormControl isInvalid={!!formState.errors?.fatherFirstName}>
                      <FormLabel htmlFor="fatherFirstName">{t('FatherFirstName')}</FormLabel>
                      <Input id="fatherFirstName" type="text" {...register('fatherFirstName')} />
                      <ErrorMessage error={formState.errors?.fatherFirstName} />
                    </FormControl>
                    <FormControl isInvalid={!!formState.errors?.fatherLastName}>
                      <FormLabel htmlFor="fatherLastName">{t('FatherLastName')}</FormLabel>
                      <Input id="fatherLastName" type="text" {...register('fatherLastName')} />
                      <ErrorMessage error={formState.errors?.fatherLastName} />
                    </FormControl>
                  </Stack>
                  <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                    <FormControl isInvalid={!!formState.errors?.motherFirstName}>
                      <FormLabel htmlFor="motherFirstName">{t('MotherFirstName')}</FormLabel>
                      <Input id="motherFirstName" type="text" {...register('motherFirstName')} />
                      <ErrorMessage error={formState.errors?.motherFirstName} />
                    </FormControl>
                    <FormControl isInvalid={!!formState.errors?.motherLastName}>
                      <FormLabel htmlFor="motherLastName">{t('MotherMaidenName')}</FormLabel>
                      <Input id="motherLastName" type="text" {...register('motherLastName')} />
                      <ErrorMessage error={formState.errors?.motherLastName} />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <Button
              isDisabled={!shouldCancel}
              type="button"
              variant="secondary"
              w="full"
              onClick={handleFormCancel}
            >
              {t('Delete')}
            </Button>
            <Button type="submit" w="full">
              {t('Validate')}
            </Button>
          </Stack>
        </Stack>
      </form>
    );
  },
);

NaturalRepresentativeInformationsForm.displayName = 'NaturalRepresentativeInformationsForm';

export type NaturalRepresentativeInformationsFormProps = Props;
