import { Stack } from '@chakra-ui/react';
import {
  CashIcon,
  CurrencyEuroIcon,
  FolderIcon,
  HomeIcon,
  IdentificationIcon,
} from '@heroicons/react/outline';
import { useLocation, useResolvedPath } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  useGetBankAccountMemberQuery,
  useGetIdentityQuery,
  useGetPendingBankDistributionsQuery,
} from '@blockpulse3/graphql/hooks';
import { NavGroupItem } from '@blockpulse3/ui/commons';
import { useAuthUser, useManagedIndividual } from '@blockpulse3/web-client/auth';

type Props = unknown;

/**
 * SettingsNavUser.
 * Render navigation items on user sub routes.
 *
 * @returns {JSX.Element}
 */
export function SettingsNavUser(): JSX.Element {
  const t = useTranslations();

  const { user: authUser } = useAuthUser();
  const { individual } = useManagedIndividual();
  const { pathname } = useLocation();

  const userPath = routes.me;
  const dashboard = useResolvedPath('');
  const settings = useResolvedPath(userPath.settings.href);
  const documents = useResolvedPath(userPath.documents.href);
  const payments = useResolvedPath(userPath.payments.href);

  const { data: IdentityData } = useGetIdentityQuery({
    variables: { identityId: individual?.identity?.id || '' },
    skip: !individual?.identity?.id,
  });
  const identity = IdentityData?.identity;

  const bankAccountMemberReq = useGetBankAccountMemberQuery({
    variables: {
      bankAccountId: identity?.bankAccount?.id || '',
      individualIdentityId: authUser?.individualIdentity?.id || '',
    },
    skip: !identity?.bankAccount || !authUser?.individualIdentity,
  });
  const bankDashboardUrl = bankAccountMemberReq.data?.getBankAccountMember?.bankDashboardUrl;

  const { data } = useGetPendingBankDistributionsQuery({
    variables: { identityId: identity?.id || '' },
    skip: !identity?.id,
  });
  const hasPendingBankDistributions = data?.getPendingBankDistributions?.length;

  return (
    <Stack spacing="1">
      <NavGroupItem
        active={dashboard.pathname === pathname}
        href=""
        icon={<HomeIcon height="18" width="18" />}
        label={t('Dashboard')}
      />
      <NavGroupItem
        active={documents.pathname === pathname}
        href={userPath.documents.href}
        icon={<FolderIcon height="18" width="18" />}
        label={t('Document', { nb: 2 })}
      />
      {hasPendingBankDistributions && (
        <NavGroupItem
          active={payments.pathname === pathname}
          href={routes.me.payments.href}
          icon={<CashIcon height="18" width="18" />}
          label={t('Payment', { nb: 2 })}
        />
      )}
      {bankDashboardUrl && (
        <NavGroupItem
          href={bankDashboardUrl}
          icon={<CurrencyEuroIcon height="18" width="18" />}
          label={t('PaymentAccount')}
        />
      )}
      <NavGroupItem
        active={settings.pathname === pathname}
        href={userPath.settings.href}
        icon={<IdentificationIcon height="18" width="18" />}
        label={t('PersonalSettings')}
      />
    </Stack>
  );
}

export type SettingsNavUserProps = Props;
