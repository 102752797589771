import { Alert, AlertIcon, AlertTitle, Button, Stack } from '@chakra-ui/react';
import {
  ActionMeta,
  AsyncCreatableSelect,
  ChakraStylesConfig,
  InputProps,
  MultiValue,
  components,
} from 'chakra-react-select';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  Movement,
  OperationStatus,
  useCreateSubscriptionForMovementMutation,
  useGetMovementQuery,
  useGetOperationsQuery,
} from '@blockpulse3/graphql/hooks';

type Props = {
  movementId: Movement['id'];
  onSubmit: () => void;
};

type OperationOption = {
  label: string;
  value: string;
};
const chakraStyles: ChakraStylesConfig<OperationOption, true> = {
  placeholder: (provided) => ({
    ...provided,
    color: 'gray.400',
  }),
};

export function OperationInput({
  children,
  ...props
}: InputProps<OperationOption, true>): JSX.Element {
  return (
    <components.Input {...props} maxLength={255}>
      {children}
    </components.Input>
  );
}

export function CreateMovementSubscription({ movementId, onSubmit }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const [selectedOperation, setSelectedOperation] = useState<OperationOption | null>(null);
  const [createdOperation, setCreatedOperation] = useState<string | null>(null);

  const [CreateSubscriptionForMovement, { loading: loadingCreation }] =
    useCreateSubscriptionForMovementMutation();

  const { data } = useGetMovementQuery({
    variables: {
      companyId,
      movementId,
    },
    skip: !companyId || !movementId,
  });

  const operationsReq = useGetOperationsQuery({
    variables: {
      companyId,
      status: OperationStatus.FINALIZED,
    },
    skip: !companyId,
  });

  const movement = data?.movement;

  const operations = useMemo(() => operationsReq?.data?.operations || [], [operationsReq]);
  const operationOptions = useMemo(() => {
    const options =
      operations?.map((operation) => ({
        label: operation.name,
        value: operation.id,
      })) || [];
    if (createdOperation) {
      options.push({ label: createdOperation, value: 'new' });
    }
    return options;
  }, [operations, createdOperation]);

  const loadOptions = useCallback(
    async (inputValue: string) => {
      if (!operationOptions.length) {
        return [];
      }
      if (!inputValue) {
        return operationOptions;
      }

      const ops = operationOptions.filter((operation) =>
        operation.label.toLowerCase().includes(inputValue.toLowerCase()),
      );
      return ops;
    },
    [operationOptions],
  );

  const handleFormSubmit = (): void => {
    if (!movement || !selectedOperation) return;

    CreateSubscriptionForMovement({
      variables: {
        CreateSubscriptionForMovementInput: {
          companyId,
          movementId,
          operationName: selectedOperation?.value === 'new' ? selectedOperation.label : undefined,
          operationId: selectedOperation?.value === 'new' ? undefined : selectedOperation?.value,
          closingDate: movement.createdAt,
        },
      },
      onCompleted: () => {
        setSelectedOperation(null);
        setCreatedOperation(null);
        operationsReq.refetch();
        onSubmit();
      },
    });
  };

  const handleOperationChange = (
    option: MultiValue<OperationOption>,
    action: ActionMeta<OperationOption>,
  ): void => {
    const selectedOption = option as unknown as OperationOption;
    setSelectedOperation(selectedOption || null);
  };

  const handleOperationCreate = (name: string): void => {
    setCreatedOperation(name);
    setSelectedOperation({ label: name, value: 'new' });
  };

  return (
    <Stack spacing="4">
      <Alert status="info">
        <AlertIcon />
        <AlertTitle>{t('MovementNotLinkToOperation')}</AlertTitle>
      </Alert>
      <AsyncCreatableSelect
        isSearchable
        chakraStyles={chakraStyles}
        defaultOptions={operationOptions}
        isClearable={false}
        isLoading={operationsReq.loading}
        loadOptions={loadOptions}
        menuPlacement="auto"
        noOptionsMessage={(): string => t('NoMatchingOperations')}
        placeholder={t('SelectOrCreateOperation')}
        value={selectedOperation}
        components={{
          Input: OperationInput,
        }}
        onChange={handleOperationChange}
        onCreateOption={handleOperationCreate}
      />
      <Button
        form="create-subscription-rmt"
        isDisabled={loadingCreation}
        isLoading={loadingCreation}
        onClick={handleFormSubmit}
      >
        {t('Validate')}
      </Button>
    </Stack>
  );
}
