import {
  Box,
  HStack,
  Icon,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { CheckIcon, InboxInIcon, SwitchHorizontalIcon } from '@heroicons/react/outline';
import { UserGroupIcon, UsersIcon } from '@heroicons/react/solid';
import { generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, noop, routes } from '@blockpulse3/data/shared';
import { useFinalizeOpportunityMutation, useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import {
  IconBoxWrapper,
  ResponsiveModal,
  ResponsiveModalProps,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

type Props = {
  opportunityId?: string;
  hasCrowdfunding?: boolean;
  hasSecondary?: boolean;
  hasOpportunity?: boolean;
  hasFinalizedOnly?: boolean;
} & Omit<ResponsiveModalProps, 'children'>;

/**
 * OperationTypeModal.
 * Links to operation workflows.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationTypeModal({
  opportunityId,
  hasCrowdfunding = true,
  hasSecondary = true,
  hasOpportunity = true,
  hasFinalizedOnly = false,
  onClose = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const navigate = useNavigate();

  const isOperationPage = useMatch(
    routes.company.href + '/' + routes.company.operations.href + '/*',
  );
  const routePrefix = isOperationPage ? '../' : '';

  const successToast = useSuccessToast();

  const operationReq = useGetOperationQuery({
    variables: { operationId: opportunityId || '' },
    skip: !opportunityId,
  });

  const isFeatureFundraisingCrowdfunding = isFeatureEnabled('fundraisingCrowdfunding');
  const isFeatureSecondary = isFeatureEnabled('secondaryMarket');

  const [finalizeOpportunity] = useFinalizeOpportunityMutation();

  const handleFinalizeOpportunity = async (): Promise<void> => {
    if (!opportunityId) return;

    await finalizeOpportunity({
      variables: { operationId: opportunityId },
      onCompleted: () => {
        successToast({ title: t('OpportunityFinalizedSuccessfully') });
        operationReq.refetch();
        onClose();
      },
    });
  };

  const handlePrivateFundraisingRedirect = (): void => {
    const path = opportunityId
      ? generatePath(routes.company.newFundraising.private.fromOpportunity.full, {
          companyId,
          opportunityId,
        })
      : routes.company.newFundraising.private.href;

    navigate(routePrefix + path);
  };

  const handleCrowdfundingFundraisingRedirect = (): void => {
    navigate(routePrefix + routes.company.newFundraising.crowdfunding.href);
  };

  const handleSecondaryMarketRedirect = (): void => {
    const path = opportunityId
      ? generatePath(routes.company.newSecondary.fromOpportunity.full, {
          companyId,
          opportunityId,
        })
      : routes.company.newSecondary.href;

    navigate(routePrefix + path);
  };

  const handlePurchaseOpportunityRedirect = (): void => {
    navigate(routePrefix + routes.company.newOpportunity.href);
  };

  return (
    <ResponsiveModal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">{t('OperationTypeQuestion')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="4" w="full">
            <Box
              cursor="pointer"
              data-cy="create-private-fundraising"
              layerStyle="container"
              pl="24px"
              onClick={handlePrivateFundraisingRedirect}
            >
              <IconBoxWrapper mr="24px">
                <Icon as={UsersIcon} boxSize="20px" color="gray.700" />
              </IconBoxWrapper>
              <Stack spacing="1">
                <Text fontWeight="semibold">{t('PrivateFundraising')}</Text>
                <UnorderedList color="gray.700" fontSize="sm" fontWeight="light" pl="1.5">
                  <ListItem>{t('KnowSubscribersAction')}</ListItem>
                  <ListItem>{t('TheirSubscriptionAmounts')}</ListItem>
                  <ListItem>{t('LessThan150Subscribers')}</ListItem>
                </UnorderedList>
              </Stack>
            </Box>
            {hasCrowdfunding && isFeatureFundraisingCrowdfunding && (
              <Box
                cursor="pointer"
                data-cy="create-crowdfunding-fundraising"
                layerStyle="container"
                pl="24px"
                onClick={handleCrowdfundingFundraisingRedirect}
              >
                <IconBoxWrapper mr="24px">
                  <Icon as={UserGroupIcon} boxSize="20px" color="gray.700" />
                </IconBoxWrapper>
                <Stack spacing="1">
                  <Text fontWeight="semibold">{t('CommunityFundraising')}</Text>
                  <UnorderedList color="gray.700" fontSize="sm" fontWeight="light" pl="1.5">
                    <ListItem>{t('MoreThan150Persons')}</ListItem>
                    <ListItem>{t('AmountsAndSubscribersUnknown')}</ListItem>
                  </UnorderedList>
                </Stack>
              </Box>
            )}
            {hasSecondary && isFeatureSecondary && (
              <HStack
                cursor="pointer"
                data-cy="create-secondary-operation"
                layerStyle="container"
                pl="24px"
                spacing="0"
                onClick={handleSecondaryMarketRedirect}
              >
                <IconBoxWrapper mr="24px">
                  <Icon as={SwitchHorizontalIcon} boxSize="20px" color="gray.700" />
                </IconBoxWrapper>
                <Stack spacing="1">
                  <Text fontWeight="semibold">{t('SecondaryMarket')}</Text>
                  <Text color="gray.700" fontSize="sm" fontWeight="light">
                    {t('OfferLiquidityWindowsToShareholders')}
                  </Text>
                </Stack>
              </HStack>
            )}
            {hasOpportunity && (
              <Box
                cursor="pointer"
                data-cy="create-opportunity"
                layerStyle="container"
                pl="24px"
                onClick={handlePurchaseOpportunityRedirect}
              >
                <IconBoxWrapper mr="24px">
                  <Icon as={InboxInIcon} boxSize="20px" color="gray.700" />
                </IconBoxWrapper>
                <Stack spacing="1">
                  <Text fontWeight="semibold">{t('PurchaseOpportunity')}</Text>
                  <Text color="gray.700" fontSize="sm" fontWeight="light">
                    {t('PurchaseIntentCollection')}
                  </Text>
                </Stack>
              </Box>
            )}
            {hasFinalizedOnly && (
              <Box
                cursor="pointer"
                data-cy="finalize-opportunity"
                layerStyle="container"
                pl="24px"
                onClick={handleFinalizeOpportunity}
              >
                <IconBoxWrapper mr="24px">
                  <Icon as={CheckIcon} boxSize="20px" color="gray.700" />
                </IconBoxWrapper>
                <Stack spacing="1">
                  <Text fontWeight="semibold">{t('FinalizeOperation')}</Text>
                  <Text color="gray.700" fontSize="sm" fontWeight="light">
                    {t('FinalizeOpportunityWithoutNewOperation')}
                  </Text>
                </Stack>
              </Box>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </ResponsiveModal>
  );
}
