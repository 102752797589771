import { nationalities, nationalityOptions } from '@blockpulse3/data/shared';

type NationalityType = {
  label: string;
  value: string;
};

export function formatNationality(countryCode: string): string {
  return nationalities[countryCode] || countryCode;
}

export function formatNationalityOption(nationalityFormatted: string): NationalityType {
  const nationality = nationalityOptions.find(({ value }) => value === nationalityFormatted) || {
    label: '',
    value: '',
  };
  return nationality;
}
