import { useTranslations } from 'use-intl';

import { CompanyDocumentType, IndividualDocumentType } from '@blockpulse3/graphql/hooks';

import { IdentityDocumentsSelectProps } from './types';

export const useGetKybDocuments = (): [(signerName?: string) => IdentityDocumentsSelectProps[]] => {
  const i18nDocumentTitle = useTranslations('DocumentValues');

  return [
    (signerName?: string): IdentityDocumentsSelectProps[] => [
      {
        label: i18nDocumentTitle('DBE'),
        value: 'dbe',
        type: CompanyDocumentType.DBE,
      },
      {
        label: i18nDocumentTitle('STATUTS'),
        value: 'status',
        type: CompanyDocumentType.STATUTS,
      },
      {
        label: i18nDocumentTitle('REGISTER_EXTRACTOrPappers'),
        value: 'registerExtract',
        type: CompanyDocumentType.REGISTER_EXTRACT,
      },
      {
        label: i18nDocumentTitle('IDENTITY_DOCUMENTwName', {
          name: signerName || '[représentant légal]',
        }),
        value: 'identityDocument',
        type: IndividualDocumentType.IDENTITY_DOCUMENT,
      },
      {
        label: i18nDocumentTitle('IDENTITY_DOCUMENT_SECONDARYwName', {
          name: signerName || '[représentant légal]',
        }),
        value: 'identityDocumentSecondary',
        type: IndividualDocumentType.IDENTITY_DOCUMENT_SECONDARY,
      },
    ],
  ];
};

export const useGetCompanyDocuments = (): [() => IdentityDocumentsSelectProps[]] => {
  const i18nDocumentTitle = useTranslations('DocumentValues');

  return [
    (): IdentityDocumentsSelectProps[] => [
      {
        label: i18nDocumentTitle('DBE'),
        value: 'dbe',
        type: CompanyDocumentType.DBE,
      },
      {
        label: i18nDocumentTitle('STATUTS'),
        value: 'status',
        type: CompanyDocumentType.STATUTS,
      },
      {
        label: i18nDocumentTitle('REGISTER_EXTRACTOrPappers'),
        value: 'registerExtract',
        type: CompanyDocumentType.REGISTER_EXTRACT,
      },
    ],
  ];
};

export const useGetKycDocuments = (): [(name?: string) => IdentityDocumentsSelectProps[]] => {
  const i18nDocumentTitle = useTranslations('DocumentValues');

  return [
    (name?: string): IdentityDocumentsSelectProps[] => [
      {
        label: i18nDocumentTitle('IDENTITY_DOCUMENTwName', { name }),
        value: 'identityDocument',
        type: IndividualDocumentType.IDENTITY_DOCUMENT,
      },
      {
        label: i18nDocumentTitle('IDENTITY_DOCUMENT_SECONDARYwName', { name }),
        value: 'identityDocumentSecondary',
        type: IndividualDocumentType.IDENTITY_DOCUMENT_SECONDARY,
      },
    ],
  ];
};
