import * as Apollo from '@apollo/client';
import { useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import {
  GetIndividualIdentityQuery,
  GetIndividualIdentityQueryVariables,
  IndividualIdentityInfosFragment,
  useGetCompanyQuery,
  useGetIndividualIdentityQuery,
} from '@blockpulse3/graphql/hooks';

import { useAuthUser } from '../user';
import { IIdentityContext, IdentityContext } from './context';

/**
 * IdentityProvider.
 *
 * Custom IdentityContext provider, exposing identity state
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function IdentityContextProvider(): JSX.Element {
  const { individual, refetch } = useManagedIndividualProvider();

  const { companyId: compId = '' } = useParams();

  const companyId = useMemo(() => {
    return compId || '';
  }, [compId]);

  const { data } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = data?.company;

  const identity = useMemo(() => {
    if (companyId && company) {
      return company.identity || null;
    }
    if (!companyId && individual) {
      return individual?.identity || null;
    }
    return null;
  }, [companyId, company, individual]);

  return (
    <IdentityContext.Provider
      value={{ identity, identityId: identity?.id || '', individual, refetch }}
    >
      <Outlet />
    </IdentityContext.Provider>
  );
}

export function useIdentity(): IIdentityContext {
  return useContext(IdentityContext);
}

/**
 * useManagedIndividualProvider.
 * Retrieve the individual depending on the sub routes. If there is an individualIdentityId in the route,
 * the authenticated user is managing an other individual that is returned.
 *
 * Since the query can be non-blocking, accept useQuery's baseOptions as argument.
 *
 * @param {Apollo.QueryHookOptions} options
 * @returns {IUserContext}
 */
export function useManagedIndividualProvider(
  options?: Apollo.QueryHookOptions<
    GetIndividualIdentityQuery,
    GetIndividualIdentityQueryVariables
  >,
): { individual: IndividualIdentityInfosFragment | null; refetch: () => void } {
  const { user, refetch: refetchUser } = useAuthUser();

  const { individualIdentityId = '' } = useParams();

  const { data, refetch: refetchIndividual } = useGetIndividualIdentityQuery({
    variables: { individualIdentityId },
    skip: !individualIdentityId,
    ...options,
  });

  const refetch = (): void => {
    if (individualIdentityId) {
      refetchIndividual();
    } else {
      refetchUser();
    }
  };

  // Retrieve current active individual identity
  const individual =
    (individualIdentityId ? data?.individualIdentity : user?.individualIdentity) || null;

  return { individual, refetch };
}

export function useManagedIndividual(): IIdentityContext {
  return useContext(IdentityContext);
}
