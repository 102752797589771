import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  accordionAnatomy.keys,
);

const baseStyle = definePartsStyle({
  container: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.300',
    borderRadius: '3px',
    mt: '4',
  },
  icon: {
    color: 'gray.400',
  },
  button: {
    '&[aria-expanded="true"]': {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'gray.300',
    },
  },
  panel: {
    px: 5,
    py: 5,
  },
});

const unstyled = definePartsStyle({
  container: {
    border: 'none',
    boxShadow: 'none',
    mt: '0',
  },
  button: {
    p: 0,
    _hover: {
      background: 'inherit',
      textDecoration: 'underline',
    },
  },
  panel: {
    px: 0,
    py: 5,
  },
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle, variants: { unstyled } });
