import { Box, Button, IconButton, IconButtonProps, Text, chakra } from '@chakra-ui/react';

const Bar = chakra('span', {
  baseStyle: {
    display: 'block',
    pos: 'absolute',
    w: '20px',
    h: '2px',
    rounded: 'full',
    bg: 'white',
    mx: 'auto',
    insetStart: '2px',
    transition: 'all 0.25s',
  },
});

export type ToggleIconProps = {
  active: boolean;
};
export type ToggleButtonProps = {
  isOpen: boolean;
} & IconButtonProps;

export function ToggleIcon({ active }: ToggleIconProps): JSX.Element {
  return (
    <Button h="32px" variant="menu-button">
      <Box
        aria-hidden
        as="span"
        className="group"
        data-active={active ? '' : undefined}
        display="block"
        h="1.7rem"
        m="0px"
        pointerEvents="none"
        pos="relative"
        w="1.5rem"
      >
        <Bar _groupActive={{ top: '0.8rem', transform: 'rotate(45deg)' }} top="0.4rem" />
        <Bar _groupActive={{ transform: 'rotateY(-90deg)' }} bottom="0.8rem" />
        <Bar _groupActive={{ bottom: '0.8rem', transform: 'rotate(-45deg)' }} bottom="0.4rem" />
      </Box>
      <Text color="white" ml="2">
        Menu
      </Text>
    </Button>
  );
}

export function ToggleButton({ isOpen, ...props }: ToggleButtonProps): JSX.Element {
  return (
    <IconButton
      color="on-accent"
      icon={<ToggleIcon active={isOpen} />}
      position="relative"
      size="sm"
      variant="unstyled"
      {...props}
    />
  );
}
