import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerProps,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import {
  OperationPanelActivityFeed,
  OperationPanelComments,
  OperationPanelHoldingMethod,
  OperationPanelReminder,
  OperationPanelTags,
} from '../..';
import { AttributionPanelDelete } from './AttributionPanelDelete';
import { AttributionPanelDocuments } from './AttributionPanelDocuments';
import { AttributionPanelIdentityCard } from './AttributionPanelIdentityCard';
import { AttributionPanelOperation } from './AttributionPanelOperation';
import { AttributionPanelProgressSteps } from './AttributionPanelProgressSteps';

type Props = Omit<DrawerProps, 'children'>;

/**
 * AttributionPanel.
 * Side panel of the option pool operation table view. Visibility is triggered by
 * `useDisclosureLink()` in `<OptionPoolAttributionsList />`.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function AttributionPanel({ ...props }: Props): JSX.Element {
  const t = useTranslations();

  return (
    <Drawer size="sm" {...props}>
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody pb="16" pt="4" px="0">
          <Stack spacing="4">
            <AttributionPanelIdentityCard />
            <Divider />
            <AttributionPanelOperation />
            <Divider />
            <AttributionPanelProgressSteps />
            <Divider />
            <AttributionPanelDocuments />
            <Divider />
            <OperationPanelHoldingMethod />
            <Divider />
            <OperationPanelReminder />
            <Divider />
            <OperationPanelTags />
            <Divider />
            <Stack px="4" spacing="4">
              <Tabs isLazy defaultIndex={0}>
                <TabList>
                  <Tab>{t('Activity', { nb: 2 })}</Tab>
                  <Tab>{t('Comment', { nb: 2 })}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <OperationPanelActivityFeed />
                  </TabPanel>
                  <TabPanel>
                    <OperationPanelComments />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
            <AttributionPanelDelete />
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
