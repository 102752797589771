import {
  Box,
  Progress as ChakraProgress,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Outlet, generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { OptionAssetType, routes } from '@blockpulse3/data/shared';
import { AssetType, useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import {
  Progress,
  ProgressStep,
  STEP_HEADER_HEIGHT_BASE,
  STEP_HEADER_HEIGHT_MD,
  StepHeader,
} from '@blockpulse3/ui/commons';

type Props = unknown;

export function RepatriationOperationLayout(): JSX.Element {
  const t = useTranslations();

  const { companyId = '', operationId = '' } = useParams();
  const navigate = useNavigate();

  const operationReq = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });
  const operation = operationReq?.data?.operation;

  const top = useBreakpointValue({ base: STEP_HEADER_HEIGHT_BASE, md: STEP_HEADER_HEIGHT_MD });

  const basePath = generatePath(
    operationId
      ? routes.company.newOperation.repatriation.edit.full
      : routes.company.newOperation.repatriation.full,
    { companyId, operationId },
  );

  const isOperationActive = useMatch(basePath);
  const isIdentitiesActive = useMatch(basePath + '/' + routes.company.repatriation.identities.href);
  const isImportActive = useMatch(basePath + '/' + routes.company.repatriation.import.href);
  const isGrantsActive = useMatch(basePath + '/' + routes.company.repatriation.grants.href);
  const isSummaryActive = useMatch(basePath + '/' + routes.company.repatriation.summary.href);

  const activeSteps = [
    { label: t('CompleteOperationInfo'), value: isOperationActive },
    { label: t('IdentitiesImport'), value: isIdentitiesActive },
    { label: t('ShareTransferRegisterImport'), value: isImportActive },
    { label: t('Summary'), value: isSummaryActive },
  ];

  if (
    operation?.assetType &&
    Object.values(OptionAssetType as unknown as AssetType).includes(operation?.assetType)
  ) {
    activeSteps.splice(3, 0, { label: t('OptionGrantsImport'), value: isGrantsActive });
  }

  const progress = activeSteps.findIndex((step) => step.value);

  const handleClose = (): void => {
    navigate(generatePath(routes.company.href, { companyId }));
  };

  return (
    <Flex direction="column" minH="100vh">
      <StepHeader p="4" showDelete={false} onClose={handleClose}>
        <Heading size="lg">{t('OperationRepatriation')}</Heading>
        <Text color="gray.600" fontWeight="normal">
          {t('OperationRepatriationDescription')}
        </Text>
      </StepHeader>
      <ChakraProgress
        height={1}
        max={activeSteps.length - 1}
        position="sticky"
        top={top}
        value={progress}
        zIndex="2"
        sx={{
          '&>div': {
            transition: 'width .7s ease',
          },
        }}
      />
      <Box bg="gray.50" flexGrow="1" h="full">
        <Stack direction={{ base: 'column', lg: 'row' }} p="6" spacing={{ base: 4, lg: 0 }}>
          <Flex flexBasis={{ base: 0, lg: 200 }} />
          <Progress>
            {activeSteps.map((step, i) => (
              <ProgressStep
                key={step.label}
                index={i}
                isActive={!!step.value}
                isCompleted={progress > i}
                title={step.label}
              />
            ))}
          </Progress>
          <Flex flexGrow="1" maxW="3xl">
            <Outlet />
          </Flex>
        </Stack>
      </Box>
    </Flex>
  );
}

export type RepatriationOperationLayoutProps = Props;
