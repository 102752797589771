import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  AssetEntityInfosFragment,
  AssetInfosFragment,
  AssetType,
  useCreateOrUpdateBondAssetMutation,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  Input,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
} from '@blockpulse3/ui/commons';

import { defaultValues, durationUnitOptions, schema } from './schema';
import { IBondAsset } from './types';
import { getBondAssetDefaultValues } from './utils';

type Props = {
  assetType: AssetType;
  editAsset?: AssetEntityInfosFragment | null;
  isNewAsset?: boolean;
  onClose: () => void;
  onComplete: (asset: AssetInfosFragment) => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

/**
 * StockCreateModal.
 * Form to create a Stock Asset.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function BondCreateModal({
  assetType,
  editAsset,
  isNewAsset = false,
  onClose,
  onComplete,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { companyId = '', operationId } = useParams();
  const [createOrUpdateBondAsset, { loading: isCreateLoading }] =
    useCreateOrUpdateBondAssetMutation();

  const { register, control, formState, handleSubmit, reset, watch } = useForm<IBondAsset>({
    defaultValues: editAsset ? getBondAssetDefaultValues(editAsset) : defaultValues,
    resolver: yupResolver(schema(isNewAsset)),
  });

  const handleModalClose = (): void => {
    onClose();
    reset({ ...defaultValues });
  };

  const handleFormSubmit: SubmitHandler<IBondAsset> = (data): void => {
    if (!companyId) return;

    const { durationUnit, ...bondData } = data;

    const bondAsset = {
      ...bondData,
      assetId: editAsset && 'id' in editAsset ? editAsset.id : undefined,
      assetType,
      durationUnit: durationUnit.value,
    };

    createOrUpdateBondAsset({
      variables: {
        companyId,
        createOrUpdateBondAssetInput: {
          ...bondAsset,
        },
        operationId,
      },
      onCompleted: (res) => {
        onComplete(res?.createOrUpdateBondAsset);
        handleModalClose();
      },
      onError: () => {
        errorToast({ title: t('ErrorAddingBond') });
        handleModalClose();
      },
    });
  };

  const durationUnit = watch('durationUnit');

  return (
    <ResponsiveModal closeOnOverlayClick={false} onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(editAsset ? 'UpdateBond' : 'AddExistingBond')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="add-option-asset" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <FormControl isInvalid={!!formState.errors?.name}>
                <FormLabel htmlFor="name">{t('BondName')}</FormLabel>
                <Input id="name" type="string" {...register('name')} />
                <ErrorMessage error={formState.errors?.name} />
              </FormControl>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="4"
                w={isNewAsset ? { base: '100%', md: '48%' } : undefined}
              >
                <FormControl isInvalid={!!formState.errors?.nominalValue}>
                  <FormLabel htmlFor="nominalValue">{t('NominalValue')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="nominalValue"
                      step="0.01"
                      type="number"
                      {...register('nominalValue')}
                    />
                    <InputRightAddon>€</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.nominalValue} />
                </FormControl>
                {!isNewAsset && (
                  <FormControl isInvalid={!!formState.errors?.date}>
                    <FormLabel htmlFor="date">{t('CreationDate')}</FormLabel>
                    <Input id="date" type="date" {...register('date')} />
                    <ErrorMessage error={formState.errors?.date} />
                  </FormControl>
                )}
              </Stack>
              <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                <Controller
                  control={control}
                  name="durationUnit"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors?.durationUnit}>
                      <FormLabel htmlFor="durationUnit">{t('DurationUnit')}</FormLabel>
                      <Select
                        id="durationUnit"
                        isSearchable={false}
                        menuPlacement="auto"
                        options={durationUnitOptions}
                        getOptionLabel={(option): string =>
                          option.label ? t(`DurationUnits.${option.label}`) : ''
                        }
                        {...field}
                      />
                      <ErrorMessage error={formState.errors?.durationUnit?.label} />
                    </FormControl>
                  )}
                />
                <FormControl isInvalid={!!formState.errors?.period}>
                  <FormLabel htmlFor="period">{t('Maturity')}</FormLabel>
                  <InputGroup>
                    <Input id="period" type="number" {...register('period')} />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.period} />
                </FormControl>
              </Stack>
              <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.interestRate}>
                  <FormLabel htmlFor="interestRate">{t('InterestRate')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="interestRate"
                      step="0.01"
                      type="number"
                      {...register('interestRate')}
                    />
                    <InputRightAddon>%</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.interestRate} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors.interestPeriod}>
                  <FormLabel htmlFor="interestPeriod">{t('InterestPeriod')}</FormLabel>
                  <InputGroup>
                    <Input id="interestPeriod" type="number" {...register('interestPeriod')} />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.interestPeriod} />
                </FormControl>
              </Stack>
              <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.interestPaymentInterval}>
                  <FormLabel htmlFor="interestPaymentInterval">
                    {t('InterestPaymentInterval')}
                  </FormLabel>
                  <InputGroup>
                    <Input
                      id="interestPaymentInterval"
                      type="number"
                      {...register('interestPaymentInterval')}
                    />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.interestPaymentInterval} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.reimbursementInterval}>
                  <FormLabel htmlFor="reimbursementInterval">
                    {t('ReimbursementInterval')}
                  </FormLabel>
                  <InputGroup>
                    <Input
                      id="reimbursementInterval"
                      type="number"
                      {...register('reimbursementInterval')}
                    />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.reimbursementInterval} />
                </FormControl>
              </Stack>
              <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.issuancePremium}>
                  <FormLabel htmlFor="issuancePremium">{t('IssuancePremium')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="issuancePremium"
                      step="0.01"
                      type="number"
                      {...register('issuancePremium')}
                    />
                    <InputRightAddon>€</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.issuancePremium} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.redemptionPremium}>
                  <FormLabel htmlFor="redemptionPremium">{t('RedemptionPremium')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="redemptionPremium"
                      step="0.01"
                      type="number"
                      {...register('redemptionPremium')}
                    />
                    <InputRightAddon>€</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.redemptionPremium} />
                </FormControl>
              </Stack>
              <FormControl>
                <Checkbox {...register('accelerable')}>
                  <Text>{t('BondReimbursementCanBeAccelerated')}</Text>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register('extendable')}>
                  <Text>{t('BondDurationCanBeExtended')}</Text>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register('transferable')}>
                  <Text>{t('TransferOfThisAssetAuthorized')}</Text>
                </Checkbox>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button isDisabled={isCreateLoading} type="button" variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            data-cy="add-option"
            form="add-option-asset"
            isDisabled={isCreateLoading}
            isLoading={isCreateLoading}
            type="submit"
          >
            {t(editAsset ? 'UpdateBond' : 'AddTheBond')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type BondCreateModalProps = Props;
