import { CloseIcon } from '@chakra-ui/icons';
import { Button, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = unknown;

export function VerifyEmailFailure(): JSX.Element {
  const t = useTranslations();
  const { status } = useAuthUser();

  return (
    <Stack align="center" direction="column" spacing="8">
      <Stack alignSelf="center" direction="row" gap="4">
        <CloseIcon color="red.400" h="8" w="8" />
        <Text fontSize="xl" fontWeight="bold">
          {t('EmailVerificationImpossible')}
        </Text>
      </Stack>
      <Link
        style={{ width: '100%' }}
        to={status === 'authenticated' ? routes.me.href : routes.login.href}
      >
        <Button width="full">{t('BackToHome')}</Button>
      </Link>
    </Stack>
  );
}

export type VerifyEmaiFailureProps = Props;
