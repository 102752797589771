import { Stack } from '@chakra-ui/react';
import { generatePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { LinkButton } from '@blockpulse3/ui/commons';

import { OperationList } from './OperationList';

/**
 * OperationList.
 * Dashboard section of operation tables (DRAFT, PASSED, STARTED).
 *
 * @returns {JSX.Element}
 */
export function OperationListView(): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const workspaceRoute = companyId
    ? generatePath(routes.company.href, { companyId })
    : routes.me.href;

  return (
    <Stack spacing="6">
      <LinkButton label={t('BackToDashboard')} route={workspaceRoute} />
      <OperationList displayType="cards" />
    </Stack>
  );
}
