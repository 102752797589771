import { Button, Icon, Stack, Td, Text, Tr } from '@chakra-ui/react';
import { ArrowNarrowRightIcon, PencilIcon, UserGroupIcon } from '@heroicons/react/solid';
import { useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import { FundraisingWorkflowType } from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { OperationBadge } from '@blockpulse3/ui/commons';

import { OperationRow } from '../../types';
import { OperationRowMenu, OperationRowMenuProps } from './OperationRowMenu';

type Props = {
  operation: OperationRow;
  operationSubtitle: string;
  totalAmount?: number;
  isIncomplete?: boolean;
  menuActions?: OperationRowMenuProps['actions'];
  onRowClick?: () => void;
};

export function OperationTableRowTemplate({
  operation,
  operationSubtitle,
  totalAmount,
  isIncomplete = false,
  menuActions,
  onRowClick = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const isSpace = useMatch(routes.space.href + '/*');

  const handleRowClick = (): void => {
    onRowClick();
  };

  const endDate = operation.closingDate || operation.endSubscriptionDate;
  const isAllInSubscriptionWorkflow =
    operation.fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;

  return (
    <Tr role="button" onClick={!isSpace ? handleRowClick : noop}>
      <Td role="button" onClick={isSpace ? handleRowClick : noop}>
        <Text fontWeight="600">{operation.name}</Text>
        <Text color="gray.500" fontSize="xs" fontWeight="400">
          {operationSubtitle}
        </Text>
      </Td>
      {isSpace && (
        <>
          <Td>{menuActions && <OperationRowMenu actions={menuActions} />}</Td>
          <Td>
            <OperationBadge
              isAllInSubscriptionWorkflow={isAllInSubscriptionWorkflow}
              operationStatus={operation.status}
            />
          </Td>
          <Td>
            <Text fontWeight="600">{operation.company?.name || '-'}</Text>
          </Td>
        </>
      )}
      <Td isNumeric>{totalAmount ? formatNumberCurrency(totalAmount) : '-'}</Td>
      <Td>
        <Stack alignItems="center" direction="row">
          <Icon as={UserGroupIcon} boxSize="18px" color="gray.900" />
          <Text>{operation.subscriptionCount}</Text>
        </Stack>
      </Td>
      <Td>{endDate ? formatDate(endDate, 'll') : '-'}</Td>
      <Td textAlign="right">
        {isIncomplete ? (
          <Button
            fontSize="sm"
            rightIcon={<Icon as={PencilIcon} boxSize="4" />}
            variant="secondary"
            onClick={handleRowClick}
          >
            {t('CompleteAction')}
          </Button>
        ) : (
          <Button
            fontSize="sm"
            rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
            variant="secondary"
            onClick={isSpace ? handleRowClick : noop}
          >
            {t('ShowDetails')}
          </Button>
        )}
      </Td>
    </Tr>
  );
}
