import { OperationType } from '@blockpulse3/graphql/hooks';

import { OperationRow } from '../../types';
import { OperationCardFundraising } from './OperationCardFundraising';
import { OperationCardOpportunity } from './OperationCardOpportunity';
import { OperationCardOptionPool } from './OperationCardOptionPool';
import { OperationCardSecondary } from './OperationCardSecondary';
import { OperationCardUnknown } from './OperationCardUnknown';

type Props = {
  operation: OperationRow;
};

/**
 * OperationCard.
 * Switch card to render different type of Operations.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationCard({ operation }: Props): JSX.Element {
  switch (operation.type) {
    case OperationType.SECONDARY:
      return <OperationCardSecondary operation={operation} />;
    case OperationType.FUNDRAISING:
      return <OperationCardFundraising operation={operation} />;
    case OperationType.OPPORTUNITY:
      return <OperationCardOpportunity operation={operation} />;
    case OperationType.OPTION_POOL:
      return <OperationCardOptionPool operation={operation} />;
    default:
      return <OperationCardUnknown operation={operation} />;
  }
}
