import { Stack, StackProps, Text, Tooltip } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { AssetType } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberPercentage } from '@blockpulse3/helpers';

type Props = {
  initialPrice: number | null | undefined;
  currentPrice: number | null | undefined;
  displayPrice: number | null | undefined;
  displayTitle?: boolean;
  underlyingAssetType: AssetType;
} & StackProps;

export function AssetPerformance({
  initialPrice,
  currentPrice,
  displayPrice,
  displayTitle = true,
  underlyingAssetType,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  function calculatePriceChange(initialPrice: number, currentPrice: number): string {
    const difference = currentPrice - initialPrice;
    const percentageChange = (difference / initialPrice) * 100;

    if (percentageChange >= 0) {
      return `+${formatNumberPercentage(percentageChange)}`;
    } else {
      return `${formatNumberPercentage(percentageChange)}`;
    }
  }

  const priceChange =
    initialPrice && currentPrice ? calculatePriceChange(initialPrice, currentPrice) : '\u00A0';

  const priceChangeColor = priceChange[0] === '-' ? 'red.400' : 'green.400';

  return (
    <Stack flexWrap="wrap" justify="flex-end" lineHeight="1.3" spacing="0" {...props}>
      {displayTitle && (
        <Text color="gray.500" fontSize="xs">
          {t(underlyingAssetType === AssetType.BOND ? 'Bond' : 'Share', { nb: 1 })}
        </Text>
      )}
      {displayPrice && (
        <Tooltip
          hasArrow
          placement="top"
          label={
            currentPrice
              ? t('NbPricePerShare', {
                  price: formatNumberCurrency(currentPrice),
                })
              : ''
          }
        >
          <Text cursor="default" fontWeight="700" whiteSpace="nowrap">
            {formatNumberCurrency(displayPrice)}
          </Text>
        </Tooltip>
      )}
      <Text color={priceChangeColor} fontSize="2xs" fontWeight="500" whiteSpace="nowrap">
        {priceChange}
      </Text>
    </Stack>
  );
}
