import * as yup from 'yup';

import { requiredStringField } from '@blockpulse3/data/shared';

import { IVerificationUserForm } from './types';

export const kycSchema = yup.object({
  identityDocumentFile: requiredStringField,
  addIdentityDocumentBackFile: yup.boolean(),
  identityDocumentBackFile: yup.string().when('addIdentityDocumentBackFile', {
    is: (addIdentityDocumentBackFile: boolean) => addIdentityDocumentBackFile,
    then: () => requiredStringField,
  }),

  identityDocumentSecondaryFile: requiredStringField,
  addIdentityDocumentSecondaryBackFile: yup.boolean(),
  identityDocumentSecondaryBackFile: yup.string().when('addIdentityDocumentSecondaryBackFile', {
    is: (addIdentityDocumentSecondaryBackFile: boolean) => addIdentityDocumentSecondaryBackFile,
    then: () => requiredStringField,
  }),
}) as yup.ObjectSchema<IVerificationUserForm>;

export const kycDefaultValues: IVerificationUserForm = {
  identityDocumentFile: '',
  identityDocumentBackFile: '',
  identityDocumentSecondaryFile: '',
  identityDocumentSecondaryBackFile: '',
  addIdentityDocumentBackFile: false,
  addIdentityDocumentSecondaryBackFile: false,
};
