import { Stack } from '@chakra-ui/react';

import { MergedMifidSection } from '@blockpulse3/graphql/hooks';
import { Progress, ProgressStep } from '@blockpulse3/ui/commons';

type Props = {
  /* ** Sections to be displayed in the stepper ** */
  mifidSections: MergedMifidSection[];
  /* ** Current section index ** */
  sectionIndex: number;
};

export function MifidSteps({ mifidSections, sectionIndex }: Props): JSX.Element {
  return (
    <Stack
      direction={{ base: 'row', md: 'column' }}
      flexWrap="nowrap"
      overflowX={{ base: 'scroll', md: 'hidden' }}
      overflowY={{ base: 'hidden', md: 'scroll' }}
      spacing="2"
    >
      {mifidSections.map((section, index) => (
        <Stack
          key={index}
          direction={{ base: 'column', lg: 'row' }}
          p="2"
          spacing={{ base: 4, lg: 0 }}
        >
          <Progress>
            <ProgressStep
              index={index}
              isActive={index === sectionIndex}
              isCompleted={index < sectionIndex}
              title={section.title}
            />
          </Progress>
        </Stack>
      ))}
    </Stack>
  );
}
