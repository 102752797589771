import { Button } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useGetCompanyQuery } from '@blockpulse3/graphql/hooks';

import { StepFormContextProvider } from '../../provider';
import { OnboardingStep } from '../OnboardingStep';
import { CompanyUbos } from '../forms';

export function OnboardingExistingCompanyUbos(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const { companyId = '' } = useParams();
  const { data } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const handleStepSubmit = (): void => {
    navigate('../' + routes.onboardings.company.edit.access.href);
  };

  const handleStepCancel = (): void => {
    navigate('../' + routes.onboardings.company.edit.representatives.href);
  };

  return (
    <StepFormContextProvider>
      <OnboardingStep
        title={t('Owner', { nb: 1 })}
        subTitle={t('DirectOwner25PercentInfo', {
          companyName: data?.company.name || '',
        })}
      >
        <CompanyUbos onCancel={handleStepCancel} onSubmit={handleStepSubmit} />
        <Button key="cancel" variant="secondary" w="full">
          {t('Back')}
        </Button>
        <Button key="submit" data-cy="next" w="full">
          {t('Next')}
        </Button>
      </OnboardingStep>
    </StepFormContextProvider>
  );
}
