import { Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { OperationType, useGetMovementQuery } from '@blockpulse3/graphql/hooks';
import {
  SubscriptionPanelDocuments,
  TransactionPanelDocuments,
} from '@blockpulse3/web-client/operation/commons';

/**
 * MovementPanelDocuments.
 * Display the documents section of the movement side panel.
 *
 * @returns {JSX.Element}
 */
export function MovementPanelDocuments(): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '', movementId = '' } = useParams();

  const { data, loading, error } = useGetMovementQuery({
    variables: {
      companyId,
      movementId,
    },
    skip: !companyId || !movementId,
  });

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Document', { nb: 2 })}
        </Text>
        <Skeleton h="60px" />
      </Stack>
    );
  }

  const { movement } = data;
  const subscription = movement.subscription;
  const operation = subscription?.operation;

  if (subscription && operation) {
    if (operation.type === OperationType.FUNDRAISING) {
      return <SubscriptionPanelDocuments subscriptionId={subscription.id} />;
    }
    if (operation.type === OperationType.SECONDARY) {
      return <TransactionPanelDocuments subscriptionId={subscription.id} />;
    }
  }

  return (
    <Stack px="4" spacing="2">
      <Text color="gray.600" fontWeight="600">
        {t('Document', { nb: 2 })}
      </Text>
      <Text color="gray.500" fontSize="sm">
        {t('NoDocumentsLinked')}
      </Text>
    </Stack>
  );
}
