import { useNavigate, useParams } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { useGetCompanyQuery } from '@blockpulse3/graphql/hooks';

import { NewOpportunityOperationDocumentation } from './NewOpportunityOperationDocumentation';

type Props = unknown;

/**
 * NewOpportunityOperationDocument.
 * Crowdfunding fundraising version of the documentation step.
 *
 * @returns {JSX.Element}
 */
export function NewOpportunityOperationDocument(): JSX.Element {
  const navigate = useNavigate();

  const { companyId = '' } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const handleFormSubmitCompleted = (): void => {
    if (!company) return;
    navigate('../' + routes.company.newOpportunity.edit.summary.href);
  };

  const handleFormCancel = (): void => {
    if (!company) return;
    navigate('..');
  };

  return (
    <NewOpportunityOperationDocumentation
      onCancel={handleFormCancel}
      onSubmitCompleted={handleFormSubmitCompleted}
    />
  );
}

export type NewCrowdfundingFundraisingDocumentationProps = Props;
