import { Box, HStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

type Props = {
  href: string;
  label: string;
  active?: boolean;
  icon: React.ReactElement;
  endElement?: React.ReactElement;
  children?: React.ReactNode;
};

type ALinkProps = {
  href: string;
  children?: React.ReactNode;
};

function ALink({ href, children }: ALinkProps): JSX.Element {
  if (href.startsWith('http')) {
    return (
      <a href={href} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }
  return <Link to={href}>{children}</Link>;
}

export function NavGroupItem({
  href,
  label,
  active = false,
  icon,
  endElement,
  children,
}: Props): JSX.Element {
  return (
    <ALink href={href}>
      <HStack
        _hover={{ bg: 'whiteAlpha.400' }}
        bg={active ? 'whiteAlpha.300' : undefined}
        cursor="pointer"
        mb="1"
        px="2"
        py="1.5"
        rounded="md"
        transition="all 0.2s"
        userSelect="none"
        w="full"
      >
        <Box fontSize="lg" pl="1" pr="2">
          {icon}
        </Box>
        <Box
          flex="1"
          fontSize={['md', 'sm']}
          fontWeight="inherit"
          overflowX="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {label}
        </Box>
        {endElement && !children && <Box>{endElement}</Box>}
        {children && <Box flexShrink={0} fontSize="xs" />}
      </HStack>
    </ALink>
  );
}

export type NavGroupItemProps = Props;
