import { CheckCircleIcon } from '@chakra-ui/icons';
import { Button, Icon, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = unknown;

export function VerifyEmailSuccess(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();
  const { refetch } = useAuthUser();

  const onClick = (): void => {
    refetch();
    navigate(routes.me.href);
  };

  return (
    <Stack align="center" direction="column" spacing="8">
      <Stack direction="row" gap="4">
        <Icon as={CheckCircleIcon} boxSize="8" color="green.500" />
        <Text fontSize="xl" fontWeight="bold">
          {t('EmailSuccessfullyVerified')}
        </Text>
      </Stack>
      <Button width="full" onClick={onClick}>
        {t('LogMyself')}
      </Button>
    </Stack>
  );
}

export type VerifyEmailSuccessProps = Props;
