import { ThemeTypings } from '@chakra-ui/react';

export const tagPalette: ThemeTypings['colorSchemes'][] = [
  'npurple',
  'nblue',
  'nrose',
  'nteal',
  'nviolet',
  'ncyan',
  'nyellow',
  'ngreen',
  'nlightblue',
];
