export enum CsvCellErrorCode {
  REQUIRED = 'REQUIRED',
  INVALID_DATE = 'INVALID_DATE',
  INVALID_IBAN = 'INVALID_IBAN',
  INVALID_NUMBER = 'INVALID_NUMBER',
  IDENTITY_NOT_FOUND = 'IDENTITY_NOT_FOUND',
  REPRESENTATIVE_NOT_FOUND = 'REPRESENTATIVE_NOT_FOUND',
  ASSET_NOT_FOUND = 'ASSET_NOT_FOUND',
  INVALID_HOLDING_METHOD = 'INVALID_HOLDING_METHOD',
  MISSING_DISTRIBUTION_IBAN = 'MISSING_DISTRIBUTION_IBAN',
  IMPORT_EXISTING_IDENTITY = 'IMPORT_EXISTING_IDENTITY',
  MOVEMENT_DATE_BEFORE_LAST_EXISTING = 'MOVEMENT_DATE_BEFORE_LAST_EXISTING',
  GAP_WITH_LAST_EXISTING = 'GAP_WITH_LAST_EXISTING',
  INDEX_NOT_MATCHING = 'INDEX_NOT_MATCHING',
  SUBSCRIBED_AMOUNT_MISMATCH = 'SUBSCRIBED_AMOUNT_MISMATCH',
  INVALID_MOVEMENT_TYPE = 'INVALID_MOVEMENT_TYPE',
}

export type CsvCellError = {
  row: number;
  column: number;
  columnKey: string;
  code: CsvCellErrorCode;
};
