import { Card, Skeleton, Text, useDisclosure } from '@chakra-ui/react';
import { CreditCardIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  HoldingMethod,
  MergedDocumentSignatureStep,
  SubscriptionSide,
  SubscriptionStepStatus,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, UpdateIBANFormModal } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionStep } from '../../SubscriptionStep';

type Props = {
  /* ** Is Step disabled ** */
  isDisabled: boolean;
  /* ** Subscription data step ** */
  subscriptionStep: MergedDocumentSignatureStep;
};

/**
 * DistributionIbanStep.
 * Add an IBAN distribution to a identy/company.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function DistributionIbanStep({ subscriptionStep, isDisabled }: Props): JSX.Element | null {
  const t = useTranslations();

  const { identity, identityId } = useIdentity();
  const { subscriptionId = '' } = useParams();

  const subscriptionReq = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { subscription } = subscriptionReq.data || {};
  /* ** Allowed holding methods for filtering ** */

  if (subscriptionReq.loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (subscriptionReq.error) {
    return <ErrorQueryCard />;
  }

  if (!subscriptionReq.data || !subscription) {
    return <ErrorQueryCard />;
  }

  const isBuyer = subscription.side === SubscriptionSide.BUYER;
  if (
    [HoldingMethod.PEA, HoldingMethod.PEA_PME].includes(subscription.holdingMethod) &&
    subscription.operation.usePivotFiscalAccount
  ) {
    return null;
  }

  return (
    <>
      <SubscriptionStep
        icon={CreditCardIcon}
        isDisabled={isDisabled}
        status={subscriptionStep.status}
        title={t('ExternalIBANAdding')}
        type={subscriptionStep.type}
        onClick={!isBuyer ? onOpen : undefined}
      >
        {isBuyer && subscriptionStep.status !== SubscriptionStepStatus.VALID && (
          <Text fontSize="sm">{t('IBANDistributionAwaiting')}</Text>
        )}
      </SubscriptionStep>
      {isOpen && (
        <UpdateIBANFormModal
          defaultIban={identity?.distributionIban}
          isOpen={isOpen}
          onClose={onClose}
          onComplete={subscriptionReq.refetch}
        />
      )}
    </>
  );
}
