import { noop } from '@blockpulse3/data/shared';
import { AssetInfosFragment } from '@blockpulse3/graphql/hooks';
import { ScrollableHStack } from '@blockpulse3/ui/commons';

import { AssetCard } from './AssetCard';

type Props = {
  stocks?: AssetInfosFragment[];
  bonds?: AssetInfosFragment[];
  options?: AssetInfosFragment[];
  handleStockClick: () => void;
  handleOptionClick: (asset: AssetInfosFragment) => void;
  handleBondClick: (asset: AssetInfosFragment) => void;
  handleDeleteModalOpen: (asset: AssetInfosFragment) => void;
};

export function AssetAndOptionCards({
  stocks,
  bonds,
  options,
  handleStockClick = noop,
  handleOptionClick = noop,
  handleBondClick = noop,
  handleDeleteModalOpen = noop,
}: Props): JSX.Element {
  return (
    <ScrollableHStack overflow="scroll" scrollAmount={200}>
      {stocks?.map((stock) => (
        <AssetCard
          key={stock.id}
          asset={stock}
          handleDeleteModalOpen={handleDeleteModalOpen}
          onClick={handleStockClick}
        />
      ))}
      {options?.map((option) => (
        <AssetCard
          key={option.id}
          asset={option}
          handleDeleteModalOpen={handleDeleteModalOpen}
          onClick={handleOptionClick}
        />
      ))}
      {bonds &&
        [...bonds]
          .sort((a, b) => (a.name < b.name ? -1 : 1))
          .map((bond) => (
            <AssetCard
              key={bond.id}
              asset={bond}
              handleDeleteModalOpen={handleDeleteModalOpen}
              onClick={handleBondClick}
            />
          ))}
    </ScrollableHStack>
  );
}
