import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { BrandTheme } from '@blockpulse3/ui/brand-theme';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  popoverAnatomy.keys,
);

const baseStyle = definePartsStyle({
  content: {
    background: 'white',
    borderRadius: BrandTheme.borderRadius.card,
    boxShadow: 'lg',
  },
});

export const popoverTheme = defineMultiStyleConfig({ baseStyle });
