import {
  Box,
  Progress as ChakraProgress,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Outlet, generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useGetOptionAssetsQuery } from '@blockpulse3/graphql/hooks';
import {
  Progress,
  ProgressStep,
  STEP_HEADER_HEIGHT_BASE,
  STEP_HEADER_HEIGHT_MD,
  StepHeader,
} from '@blockpulse3/ui/commons';

type Props = unknown;

export function RepatriationShareTransferRegisterLayout(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const navigate = useNavigate();

  const optionAssetReq = useGetOptionAssetsQuery({ variables: { companyId } });

  const top = useBreakpointValue({ base: STEP_HEADER_HEIGHT_BASE, md: STEP_HEADER_HEIGHT_MD });

  const isCompanyActive = useMatch(routes.company.repatriation.full);
  const isAssetsActive = useMatch(
    routes.company.repatriation.full + '/' + routes.company.repatriation.assets.href,
  );
  const isIdentitiesActive = useMatch(
    routes.company.repatriation.full + '/' + routes.company.repatriation.identities.href,
  );
  const isImportActive = useMatch(
    routes.company.repatriation.full + '/' + routes.company.repatriation.import.href,
  );
  const isGrantsActive = useMatch(
    routes.company.repatriation.full + '/' + routes.company.repatriation.grants.href,
  );
  const isSummaryActive = useMatch(
    routes.company.repatriation.full + '/' + routes.company.repatriation.summary.href,
  );

  const activeSteps = [
    { label: t('CompleteCompanyInfo'), value: isCompanyActive },
    { label: t('CompanyAssetsSettings'), value: isAssetsActive },
    { label: t('IdentitiesImport'), value: isIdentitiesActive },
    { label: t('ShareTransferRegisterImport'), value: isImportActive },
    { label: t('Summary'), value: isSummaryActive },
  ];

  const optionAssets = optionAssetReq?.data?.getOptionAssets || [];
  if (optionAssets.length) {
    activeSteps.splice(4, 0, { label: t('OptionGrantsImport'), value: isGrantsActive });
  }

  const progress = activeSteps.findIndex((step) => step.value);

  const handleClose = (): void => {
    navigate(generatePath(routes.company.href, { companyId }));
  };

  return (
    <Flex direction="column" minH="100vh">
      <StepHeader p="4" showDelete={false} onClose={handleClose}>
        <Heading size="lg">{t('ShareholdingRepatriation')}</Heading>
        <Text color="gray.600" fontWeight="normal">
          {t('ShareholdingRepatriationDescription')}
        </Text>
      </StepHeader>
      <ChakraProgress
        height={1}
        max={activeSteps.length - 1}
        position="sticky"
        top={top}
        value={progress}
        zIndex="2"
        sx={{
          '&>div': {
            transition: 'width .7s ease',
          },
        }}
      />
      <Box bg="gray.50" flexGrow="1" h="full">
        <Stack direction={{ base: 'column', lg: 'row' }} p="6" spacing={{ base: 4, lg: 0 }}>
          <Flex flexBasis={{ base: 0, lg: 200 }} />
          <Progress>
            {activeSteps.map((step, i) => (
              <ProgressStep
                key={step.label}
                index={i}
                isActive={!!step.value}
                isCompleted={progress > i}
                title={step.label}
              />
            ))}
          </Progress>
          <Flex flexGrow="1" maxW="3xl">
            <Outlet />
          </Flex>
        </Stack>
      </Box>
    </Flex>
  );
}

export type RepatriationShareTransferRegisterLayoutProps = Props;
