import { useDisclosure } from '@chakra-ui/react';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { SwitcherModal } from './SwitcherModal';
import { IWorkspaceSwitcherContext, WorkspaceSwitcherContext } from './context';

// type Props = {
//   children: React.ReactNode;
// };

export function WorkspaceSwitcherContextProvider(/*{ children }: Props*/): JSX.Element {
  const {
    isOpen: isSwitcherOpen,
    onOpen: onSwitcherOpen,
    onClose: onSwitcherClose,
  } = useDisclosure();

  const value: IWorkspaceSwitcherContext = {
    isSwitcherOpen,
    onSwitcherOpen,
    onSwitcherClose,
  };

  return (
    <WorkspaceSwitcherContext.Provider value={value}>
      <SwitcherModal />
      <Outlet />
    </WorkspaceSwitcherContext.Provider>
  );
}

export function useWorkspaceSwitcher(): IWorkspaceSwitcherContext {
  return useContext(WorkspaceSwitcherContext);
}

// export type WorkspaceSwitcherContextProviderProps = Props;
