import { Divider, Stack, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { MergedMifidSection, useGetMifidSectionDocumentsQuery } from '@blockpulse3/graphql/hooks';

import {
  FileQuestion,
  MultipleChoiceQuestion,
  NumberQuestion,
  SingleChoiceQuestion,
  TextQuestion,
} from './QuestionTypes';
import { useMifidModal } from './provider';
import { MifidQuestionForm } from './types';

type Props = {
  /* ** Current Mifid section ** */
  section: MergedMifidSection;
  /* ** Submit handler for current section ** */
  onSubmit: (sectionData: MifidQuestionForm) => void;
  /* ** Handler to update state of next button ** */
  onUpdateNextEnabled: (enabled: boolean) => void;
};

export function MifidForm({ section, onSubmit, onUpdateNextEnabled }: Props): JSX.Element {
  const mifidRef = useRef<HTMLFormElement>(null);
  const sectionRef = useRef(section);

  const { sectionId } = section;
  const { subscriptionId } = useMifidModal();

  // Find file questions on current section
  const fileQuestions = section.questions?.filter(
    (question) => question.__typename === 'MergedQuestionFile',
  );

  const { data } = useGetMifidSectionDocumentsQuery({
    variables: {
      sectionId,
      subscriptionId,
    },
    skip: !subscriptionId || !fileQuestions.length,
  });

  const defaultValues = useMemo(
    () =>
      section.questions.reduce((acc, question) => {
        acc[question.questionId] = question?.answerValues || [];
        return acc;
      }, {} as MifidQuestionForm),
    [section],
  );

  const methods = useForm<MifidQuestionForm>({
    defaultValues,
  });
  const { handleSubmit, reset, watch } = methods;

  const answers = watch();

  /* ** Enable next button when all values are filled ** */
  useEffect(() => {
    const allAnswersProvided = Object.values(answers).every((value) => {
      // Check for empty select answers
      const isAnswerProvided = value.length;
      // Check for empty input values
      const isAnswerNotEmpty = value.every((answer) => answer.length);

      return isAnswerProvided && isAnswerNotEmpty;
    });
    onUpdateNextEnabled(allAnswersProvided);
  }, [answers, onUpdateNextEnabled]);

  /* ** Reset form values on each section change ** */
  const hasSectionChanged = section !== sectionRef.current;
  useEffect(() => {
    reset({ ...defaultValues });
    if (mifidRef.current && hasSectionChanged) {
      mifidRef.current.scrollIntoView();
    }
  }, [defaultValues, reset, hasSectionChanged]);

  // Find existing mifid section files
  const files = data?.getMifidSectionDocuments?.sectionDocuments;

  // Format existing mifid section files for dropzone component
  const processedFiles: Record<string, Record<string, File>> = {};
  if (fileQuestions.length) {
    fileQuestions.forEach((fileQuestion) => {
      processedFiles[fileQuestion.questionId] = {};
      if (files && files?.[fileQuestion.questionId]) {
        const file = files?.[fileQuestion.questionId];
        Object.keys(file).forEach((documentId) => {
          const fileName = file[documentId] || '';
          processedFiles[fileQuestion.questionId][documentId] = new File([fileName], fileName);
        });
      }
    });
  }

  return (
    <FormProvider {...methods}>
      <form id="mifid" ref={mifidRef} onSubmit={handleSubmit(onSubmit)}>
        <Stack py="6">
          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="600">
              {section.title}
            </Text>
            {section.subtitle && (
              <Text color="gray.600" fontWeight="500">
                {section.subtitle}
              </Text>
            )}
          </Stack>
          <Divider />
          <Stack spacing="4">
            {section.questions.map((question) => {
              const { questionId } = question;

              switch (question.__typename) {
                case 'MergedQuestionSingleChoice':
                  return <SingleChoiceQuestion key={questionId} question={question} />;
                case 'MergedQuestionMultipleChoice':
                  return <MultipleChoiceQuestion key={questionId} question={question} />;
                case 'MergedQuestionNumber':
                  return <NumberQuestion key={questionId} question={question} />;
                case 'MergedQuestionText':
                  return <TextQuestion key={questionId} question={question} />;
                case 'MergedQuestionFile':
                  return (
                    <FileQuestion
                      key={questionId}
                      files={processedFiles[questionId]}
                      question={question}
                      sectionId={sectionId}
                    />
                  );
                default:
                  return null;
              }
            })}
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}
