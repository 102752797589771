import { IOperationLegalSubscriberInformations } from './Forms';
import { IOperationSubscriber } from './types';

export const operationSubscriberDefaultValues = {
  isLegal: false,
  subscriber: {
    email: '',
    name: '',
    identificationNumber: '',
    amount: 0,
    admissionFees: 0,
    verified: false,
    minimalAmount: null,
    maximalAmount: null,
  },
};

/**
 * isLegalSubscriber.
 * Check if subscriber argument is a legal version or not.
 *
 * @param {IOperationSubscriber['subscriber']} subscriber
 * @returns {subscriber is}
 */
export function isLegalSubscriber(
  subscriber: IOperationSubscriber['subscriber'],
): subscriber is IOperationLegalSubscriberInformations {
  return (
    (subscriber as IOperationLegalSubscriberInformations).name !== '' &&
    (subscriber as IOperationLegalSubscriberInformations).name !== undefined
  );
}
