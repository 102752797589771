import { useNavigate, useParams } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { CompanyCapitalType, useGetCompanyQuery } from '@blockpulse3/graphql/hooks';

import { NewFundraisingDocumentation } from '../commons';

type Props = unknown;

/**
 * NewPrivateFundraisingDocumentation.
 * Private fundraising version of the documentation step.
 *
 * @returns {JSX.Element}
 */
export function NewPrivateFundraisingDocumentation(): JSX.Element {
  const navigate = useNavigate();

  const { companyId = '' } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const fundraisingRoutes = routes.company.newFundraising.private;

  const handleFormSubmitCompleted = (): void => {
    if (!company) return;
    if (company.capitalType === CompanyCapitalType.FIXED) {
      navigate('../' + fundraisingRoutes.edit.iban.href);
    } else {
      navigate('../' + fundraisingRoutes.edit.subscribers.href);
    }
  };

  const handleFormCancel = (): void => {
    if (!company) return;
    navigate('..');
  };

  return (
    <NewFundraisingDocumentation
      onCancel={handleFormCancel}
      onSubmitCompleted={handleFormSubmitCompleted}
    />
  );
}

export type NewPrivateFundraisingDocumentationProps = Props;
