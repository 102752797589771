import { Button, Card, Grid, GridItem, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { ArrowNarrowRightIcon, ClockIcon } from '@heroicons/react/outline';
import { SwitchHorizontalIcon } from '@heroicons/react/solid';
import { generatePath, resolvePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { ISubscription, routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  CompanyStatus,
  IdentityShortInfosFragment,
  IdentityType,
  OperationType,
  SubscriptionSide,
  SubscriptionWithCompanyInfosFragment,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { CompanyIdentityAvatar, SubscriptionTypeBadge } from '@blockpulse3/ui/commons';

type Props = {
  /* ** Subscription entity ** */
  subscription: SubscriptionWithCompanyInfosFragment;
  /* ** size ** */
  size?: 'sm' | 'md' | 'lg';
};

export function InvitationCard({ subscription, size }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const isManaging = useMatch(routes.manage.href + '/*');

  const navigate = useNavigate();

  const isInvitationsPage = useMatch(
    companyId
      ? routes.company.href + '/' + routes.company.invitations.href + '/*'
      : routes.me.href + '/' + routes.me.invitations.href + '/*',
  );
  const routePrefix = isInvitationsPage ? '../' : '';

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const handleSubscriptionClick = (
    subscription: ISubscription,
    subscriptionSide: SubscriptionSide,
    subCompanyId?: string,
  ): void => {
    const subscriberIdentity =
      subscriptionSide === SubscriptionSide.SELLER
        ? subscription.sellerIdentity
        : subscription.buyerIdentity;

    const subCompany =
      subCompanyId && (!companyId || subCompanyId !== companyId)
        ? subscriberIdentity?.companyIdentity
        : company;
    if (subCompany) {
      if (subCompany?.status === CompanyStatus.RUNNING) {
        navigate(
          resolvePath(
            generatePath(routes.subscription.href, { subscriptionId: subscription.id }),
            generatePath(isManaging ? routes.manage.company.href : routes.company.href, {
              companyId: subCompany.id,
            }),
          ).pathname,
        );
      } else {
        navigate(
          resolvePath(
            generatePath(routes.onboardings.company.edit.href, { companyId: subCompany.id }),
            routes.onboardings.company.href,
          ).pathname,
        );
      }
    } else {
      navigate(
        routePrefix + generatePath(routes.subscription.href, { subscriptionId: subscription.id }),
        { relative: 'path' },
      );
    }
  };

  const subscriptionSide = subscription.side || SubscriptionSide.BUYER;
  const subscriberIdentity =
    subscriptionSide === SubscriptionSide.SELLER
      ? subscription.sellerIdentity
      : subscription.buyerIdentity;
  const isSubscriberCompany = subscriberIdentity?.type === IdentityType.COMPANY;

  let otherSideIdentity: IdentityShortInfosFragment | undefined | null = null;
  let isOtherSideCompany = false;
  if (subscription.operation.type === OperationType.SECONDARY) {
    otherSideIdentity =
      subscriptionSide === SubscriptionSide.SELLER
        ? subscription.buyerIdentity
        : subscription.sellerIdentity;
    isOtherSideCompany = otherSideIdentity?.type === IdentityType.COMPANY;
  }

  const isSM = size === 'sm';

  return (
    <Card
      padding="4"
      role="button"
      onClick={(): void =>
        handleSubscriptionClick(
          subscription,
          subscriptionSide,
          isSubscriberCompany ? subscriberIdentity?.companyIdentity?.id : undefined,
        )
      }
    >
      <Grid
        gap="3"
        gridTemplateColumns="2fr 1fr"
        minHeight="0"
        minWidth="0"
        whiteSpace="nowrap"
        templateAreas={
          isSM
            ? `"title title"
            "details button"`
            : {
                base: `"title title"
            "details button"`,
                md: `"title button"
            "details button"`,
              }
        }
      >
        <GridItem area="title" minWidth="0">
          <Stack spacing="0">
            <HStack>
              <Text fontWeight="600">{subscription.operation.name}</Text>
              <SubscriptionTypeBadge
                fontSize="xs"
                operationType={subscription.operation.type}
                subscriptionSide={subscription.side || SubscriptionSide.BUYER}
              />
            </HStack>
            <Text color="gray.500" fontSize="xs" fontWeight="400">
              {subscription.operation.type === OperationType.FUNDRAISING &&
                (subscription.operation.assetType === AssetType.BOND
                  ? t('BondIssuance')
                  : t('CapitalIncrease'))}
              {subscription.operation.type === OperationType.SECONDARY && t('TransferOfShare')}
              {subscription.operation.type === OperationType.OPPORTUNITY && t('IntentCollection')}
            </Text>
          </Stack>
        </GridItem>
        <GridItem
          alignSelf={isSM ? 'end' : { base: 'end', lg: 'center' }}
          area="details"
          minWidth="0"
        >
          <Stack
            direction={isSM ? 'column' : { base: 'column', md: 'row' }}
            spacing={isSM ? '2' : { base: '2', md: '6' }}
          >
            <HStack spacing="1">
              <CompanyIdentityAvatar
                boxSize="5"
                src={subscription.operation.company.identity?.profilePicture}
              />
              <Text fontSize="sm" fontWeight="600">
                {subscription.operation.company.name}
              </Text>
            </HStack>
            {otherSideIdentity && (
              <HStack spacing="1">
                <Icon as={SwitchHorizontalIcon} boxSize="4" fill="gray.600" m="0.5" />
                <Text fontSize="sm">
                  {isOtherSideCompany
                    ? otherSideIdentity?.companyIdentity?.name
                    : otherSideIdentity?.individualIdentity?.name}
                </Text>
              </HStack>
            )}
            <HStack spacing="1">
              <Icon as={ClockIcon} boxSize="3" color="gray.600" m="1" />
              <Text fontSize="sm" whiteSpace="nowrap">
                {subscription.operation.endSubscriptionDate
                  ? formatDate(subscription.operation.endSubscriptionDate, 'D MMMM YYYY')
                  : t('Continuous')}
              </Text>
            </HStack>
          </Stack>
        </GridItem>
        {/* </Stack> */}
        {/* <HStack flex="1">
            <IdentityAvatar boxSize="5" identity={subscriberIdentity || undefined} />
            <Text fontSize="sm">
              {isSubscriberCompany
                ? subscriberIdentity?.companyIdentity?.name
                : subscriberIdentity?.individualIdentity?.name}
            </Text>
            {otherSideIdentity && (
              <>
                <Icon as={SwitchHorizontalIcon} boxSize="4" fill="gray.600" />
                <Text fontSize="sm">
                  {isOtherSideCompany
                    ? otherSideIdentity?.companyIdentity?.name
                    : otherSideIdentity?.individualIdentity?.name}
                </Text>
              </>
            )}
          </HStack> */}
        <GridItem
          alignSelf={isSM ? 'end' : { base: 'end', lg: 'center' }}
          area="button"
          justifySelf={isSM ? 'auto' : { base: 'auto', lg: 'end' }}
          minWidth="0"
        >
          <Stack
            alignItems={isSM ? 'end' : { base: 'end', lg: 'center' }}
            direction={isSM ? 'column' : { base: 'column', lg: 'row' }}
            spacing={isSM ? '2' : { base: '2', lg: '4' }}
          >
            {subscription.investAmount !== 0 && (
              <Text fontWeight="600">{formatNumberCurrency(subscription.investAmount)}</Text>
            )}
            <Button
              fontSize="sm"
              rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="4" />}
              variant="secondary"
            >
              {t('Access')}
            </Button>
          </Stack>
        </GridItem>
      </Grid>
    </Card>
  );
}
