import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, HStack, Icon, IconButton, StackProps } from '@chakra-ui/react';
import { UIEventHandler, useEffect, useRef, useState } from 'react';

type Props = {
  scrollAmount?: number;
} & StackProps;

export function ScrollableHStack({ scrollAmount = 120, children, ...props }: Props): JSX.Element {
  const boxContainerRef = useRef<HTMLDivElement>(null);

  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

  useEffect(() => {
    const boxContainer = boxContainerRef.current;
    if (boxContainer) {
      const { offsetWidth, scrollWidth } = boxContainer;
      setShowRightArrow(offsetWidth < scrollWidth);
    }
  }, []);

  const handleRightArrowClick = (): void => {
    const boxContainer = boxContainerRef.current;
    if (boxContainer) {
      boxContainer.scroll({ left: boxContainer.scrollLeft + scrollAmount, behavior: 'smooth' });
    }
  };

  const handleLeftArrowClick = (): void => {
    const boxContainer = boxContainerRef.current;
    if (boxContainer) {
      boxContainer.scroll({ left: boxContainer.scrollLeft - scrollAmount, behavior: 'smooth' });
    }
  };

  const handleBoxScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const { scrollLeft, offsetWidth, scrollWidth } = e.currentTarget;
    setShowRightArrow(scrollLeft + offsetWidth < scrollWidth);
    setShowLeftArrow(scrollLeft > 0);
  };

  return (
    <Box
      position="relative"
      _after={{
        content: '""',
        display: 'block',
        position: 'absolute',
        right: '0',
        top: '-10px',
        height: 'calc(100% + 20px)',
        width: '15px',
        bgGradient: 'linear(to-r, transparent, gray.50)',
        zIndex: '7',
      }}
      _before={{
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-0.75rem',
        top: '-10px',
        height: 'calc(100% + 20px)',
        width: '15px',
        bgGradient: 'linear(to-l, transparent, gray.50)',
        zIndex: '7',
      }}
    >
      <HStack
        m="-3"
        mr="0"
        overflow="scroll"
        p="3"
        ref={boxContainerRef}
        onScroll={handleBoxScroll}
        {...props}
      >
        {children}
      </HStack>
      {showLeftArrow && (
        <IconButton
          _hover={{ opacity: 1 }}
          aria-label="scroll-left"
          background="gray.50"
          boxShadow="md"
          icon={<Icon as={ChevronLeftIcon} boxSize="6" color="gray.900" />}
          left="0"
          minW="8"
          opacity="1"
          position="absolute"
          rounded="sm"
          top="50%"
          transform="translate(-50%, -50%)"
          zIndex="8"
          onClick={handleLeftArrowClick}
        />
      )}
      {showRightArrow && (
        <IconButton
          _hover={{ opacity: 1 }}
          aria-label="scroll-right"
          background="gray.50"
          boxShadow="md"
          icon={<Icon as={ChevronRightIcon} boxSize="6" color="gray.900" />}
          minW="8"
          position="absolute"
          right="0.75rem"
          rounded="sm"
          top="50%"
          transform="translate(50%, -50%)"
          zIndex="8"
          onClick={handleRightArrowClick}
        />
      )}
    </Box>
  );
}
