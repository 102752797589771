import { useContext, useMemo } from 'react';

import { IVerificationStepContext, VerificationStepContext } from './context';

type Props = {
  children: React.ReactNode;
  activeSteps: number;
  stepIndex: number;
};

export function VerificationStepContextProvider({
  children,
  activeSteps,
  stepIndex,
}: Props): JSX.Element {
  const value: IVerificationStepContext = useMemo(
    () => ({
      activeSteps,
      stepIndex,
    }),
    [activeSteps, stepIndex],
  );

  return (
    <VerificationStepContext.Provider value={value}>{children}</VerificationStepContext.Provider>
  );
}

export function useVerificationStepContext(): IVerificationStepContext {
  return useContext(VerificationStepContext);
}

export type VerificationStepContextProviderProps = Props;
