import { BrandTheme } from '@blockpulse3/ui/brand-theme';

import { accordionTheme } from './components/accordion';
import { alertTheme } from './components/alert';
import { cardTheme } from './components/card';
import { checkboxTheme } from './components/checkbox';
import { modalTheme } from './components/modal';
import { popoverTheme } from './components/popover';
import { radioTheme } from './components/radio';
import { tabsTheme } from './components/tabs';

const Alert = alertTheme;
const Card = cardTheme;
const Tabs = tabsTheme;
const Checkbox = checkboxTheme;
const Radio = radioTheme;
const Accordion = accordionTheme;
const Modal = modalTheme;
const Popover = popoverTheme;

const Avatar = {
  baseStyle: {
    container: {
      bg: 'gray.100',
      color: 'gray.800',
    },
  },
};

const Badge = {
  baseStyle: {
    borderRadius: '10px',
    px: 2,
    textTransform: 'none',
    fontSize: 'sm',
    fontWeight: '500',
  },
  variants: {
    radio: {
      p: 3,
      backgroundColor: 'gray.50',
      borderRadius: 'none',
    },
  },
};

const Button = {
  variants: {
    'primary': {
      'bg': 'secondary',
      'color': 'white',
      'rounded': BrandTheme.borderRadius.button,
      '_disabled': {
        opacity: 0.7,
      },
      '_hover': {
        opacity: 0.7,
      },
      '&:disabled:hover': {
        background: 'secondary',
        opacity: 0.7,
      },
    },
    'secondary': {
      'bg': 'gray.100',
      'color': 'gray.800',
      'rounded': BrandTheme.borderRadius.button,
      '_disabled': {
        opacity: 0.7,
      },
      '_hover': {
        bg: 'gray.200',
      },
      '&:disabled:hover': {
        background: 'gray.200',
        opacity: 0.7,
      },
    },
    'light': {
      bg: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'gray.300',
      borderRadius: '3px',
      whiteSpace: 'normal',
      _hover: {
        bg: 'blackAlpha.50',
      },
    },
    'link': {
      bg: 'transparent',
      color: 'secondary',
      _active: {
        color: 'secondary',
      },
    },
    'menu-button': {
      w: 'full',
      p: '2',
      justifyContent: 'start',
      userSelect: 'none',
      outline: 0,
      transition: 'all 0.2s',
      _active: {
        bg: 'whiteAlpha.400',
      },
      _hover: {
        bg: 'whiteAlpha.400',
      },
      _focus: {
        shadow: 'none',
      },
    },
    'item': {
      w: 'full',
      h: 'full',
      p: 5,
      rounded: 'none',
      borderWidth: '0 0 1px 0',
      borderStyle: 'solid',
      borderColor: 'gray.200',
      justifyContent: 'flex-start',
      _active: {
        backgroundColor: 'gray.50',
      },
      _hover: {
        backgroundColor: 'gray.100',
      },
      _focus: {
        shadow: 'none',
      },
    },
    'workspace': {
      w: 'full',
      bg: 'transparent',
      p: 0,
      pr: 2,
      _active: {
        bg: 'whiteAlpha.300',
        borderWidth: '0',
      },
      _hover: {
        bg: 'whiteAlpha.300',
        borderWidth: '0',
      },
      _focus: {
        shadow: 'none',
      },
    },
    'container': {
      display: 'flex',
      justifyContent: 'start',
      w: 'full',
      h: 'full',
      p: 4,
      bg: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'gray.300',
      borderRadius: 'md',
      whiteSpace: 'normal',

      textAlign: 'start',
      _hover: {
        bg: 'blackAlpha.50',
      },
    },
    'delete': {
      'bg': 'red.600',
      'color': 'white',
      'rounded': BrandTheme.borderRadius.button,
      '_hover': {
        bg: 'red.400',
      },
      '_active': {
        bg: 'red.400',
      },
      '&:disabled:hover': {
        bg: 'red.400',
      },
    },
    'icon-delete': {
      'bg': 'transparent',
      'color': 'gray.300',
      'rounded': BrandTheme.borderRadius.button,
      '_hover': {
        'bg': 'gray.100',
        '> svg': {
          color: 'red.500',
        },
      },
      '_active': {
        bg: 'gray.200',
      },
      '&:disabled:hover': {
        'bg': 'transparent',
        '> svg': {
          color: 'gray.300',
        },
      },
    },
    'icon': {
      'bg': 'transparent',
      'color': 'gray.500',
      'rounded': BrandTheme.borderRadius.button,
      '_hover': {
        'bg': 'gray.100',
        '> svg': {
          color: 'gray.800',
        },
      },
      '_active': {
        bg: 'gray.200',
      },
      '&:disabled:hover': {
        'bg': 'transparent',
        '> svg': {
          color: 'gray.500',
        },
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

const Heading = {
  baseStyle: {
    fontWeight: 'semibold',
  },
};

const Text = {
  variants: {
    ellipsis: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
};

const Divider = {
  baseStyle: {
    borderColor: 'gray.300',
  },
  variants: {
    'menu-button': {
      bg: 'whiteAlpha.600',
      height: '1px',
    },
  },
};

const Input = {
  parts: ['field', 'addon'],
  variants: {
    outline: {
      field: {
        bg: 'white',
      },
      addon: {
        background: 'gray.50',
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'secondary',
  },
};

const Progress = {
  baseStyle: {
    filledTrack: {
      bg: 'primary',
    },
  },
  variants: {
    pending: {
      filledTrack: {
        opacity: 0.6,
      },
    },
  },
};

const Table = {
  parts: ['th', 'td', 'thead', 'tbody'],
  baseStyle: {
    th: {
      fontWeight: 500,
      letterSpacing: 'normal',
      textTransform: 'none',
    },
  },
  variants: {
    striped: {
      th: {
        bg: 'gray.50',
        borderColor: 'gray.200',
        color: 'gray.500',
        fontSize: 'sm',
      },
      tbody: {
        '& tr:nth-of-type(even) td': {
          background: 'gray.50',
          borderColor: 'gray.200',
        },
        '& tr:nth-of-type(odd) td': {
          background: 'white',
          borderColor: 'gray.200',
        },
      },
    },
    light: {
      th: {
        p: 2,
        color: 'gray.500',
      },
      td: {
        p: 2,
        borderColor: 'gray.200',
        borderStyle: 'solid',
        borderWidth: '1px 0 0',
      },
    },
  },
};

const Drawer = {
  defaultProps: {
    size: 'sm',
  },
  baseStyle: {
    dialog: {
      borderRadius: [0, 'lg'],
      boxShadow: 'md',
      m: [0, 5],
      overflow: 'hidden',
    },
    closeButton: {
      '&:focus-visible': {
        boxShadow: 'none',
      },
      'zIndex': 10,
    },
  },
  variants: {
    sm: {
      dialog: {
        borderRadius: 'lg',
        boxShadow: 'md',
        m: 5,
      },
    },
    nav: {
      dialog: {
        borderRadius: 0,
        boxShadow: 'none',
        m: 0,
      },
    },
  },
};

export const components = {
  Alert,
  Avatar,
  Badge,
  Button,
  Card,
  Heading,
  Input,
  Divider,
  Accordion,
  Modal,
  Drawer,
  Progress,
  Tabs,
  Text,
  Table,
  Checkbox,
  Radio,
  Popover,
};
