import { AssetStatus } from '@blockpulse3/data/shared';
import {
  AssetEntityInfosFragment,
  AssetType,
  OperationInfosFragment,
  OperationType,
} from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { OperationAssetOption } from '@blockpulse3/web-client/operation/commons';

import { completeOperationFormDefaults } from './schema';
import { ICompleteOperationForm } from './type';

export function getCompleteOperationParameters(
  operation: OperationInfosFragment | undefined,
  asset?: AssetEntityInfosFragment,
  operationType?: OperationType,
  filteredAssetOptions?: OperationAssetOption[],
): ICompleteOperationForm {
  // Default asset
  const defaultAsset = asset || operation?.asset;
  let label = 'default';
  let value: string = AssetType.ORDINARY_SHARE;
  let assetType = AssetType.ORDINARY_SHARE;
  let isDraft = false;
  if (defaultAsset) {
    label = defaultAsset.name || 'default';
    value = defaultAsset.id;
    assetType = defaultAsset.assetType;
    isDraft = defaultAsset.status === AssetStatus.DRAFT;
  }

  // Default operation type
  const defaultOperationType = operationType || operation?.type || '';
  const assetData =
    filteredAssetOptions && !filteredAssetOptions.find((option) => option.value === value)
      ? null
      : { label, value, type: assetType, isDraft };

  return {
    name: operation?.name || '',
    type: { label: defaultOperationType, value: defaultOperationType },
    closingDate: operation?.closingDate
      ? formatDate(operation.closingDate, 'YYYY-MM-DD')
      : completeOperationFormDefaults.closingDate,
    asset: assetData,
    sharePrice: operation?.sharePrice || completeOperationFormDefaults.sharePrice,
  };
}
