import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CheckCircleIcon, DotsHorizontalIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { generatePath, resolvePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import {
  TransferSide,
  TransferStatus,
  useGenerateSwanAuthUrlLazyQuery,
  useGetBankAccountByIdentityIdQuery,
  useGetBankAccountMemberQuery,
  useGetBankTransfersBySubscriptionQuery,
  useGetSubscriptionLazyQuery,
} from '@blockpulse3/graphql/hooks';
import {
  BankDetailsCard,
  ErrorMessage,
  Input,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  WarningCard,
} from '@blockpulse3/ui/commons';
import { useAuthUser, useIdentity } from '@blockpulse3/web-client/auth';

import { refundSchema } from './schema';
import { IRefundForm } from './types';

type Props = {
  /* ** Subscription ID ** */
  subscriptionId: string;
  /* ** Bank transfer ID ** */
  bankTransferId?: string | null;
  /* ** Default amount to refund ** */
  defaultAmount?: number;
} & Omit<ResponsiveModalProps, 'children'>;

/**
 * RefundModal.
 * Modal form to refund a specified amount to subscriber account.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function RefundModal({
  subscriptionId,
  bankTransferId,
  defaultAmount = 0,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const popupWindowRef = useRef<Window | null>(null);

  const { companyId = '' } = useParams();
  const { user } = useAuthUser();
  const { identityId } = useIdentity();

  const bankAccountReq = useGetBankAccountByIdentityIdQuery({
    variables: { identityId },
    skip: !identityId,
  });
  const bankAccountId = bankAccountReq.data?.getBankAccountByIdentityId?.id;

  const bankAccountMemberReq = useGetBankAccountMemberQuery({
    variables: {
      bankAccountId: bankAccountId || '',
      individualIdentityId: user?.individualIdentity?.id || '',
    },
    skip: !bankAccountId || !user?.individualIdentity,
  });
  const isBankMember = !!bankAccountMemberReq.data?.getBankAccountMember?.bankDashboardUrl;

  const [, { refetch: refetchSubscription }] = useGetSubscriptionLazyQuery({
    variables: { subscriptionId },
  });

  const { data, loading, startPolling, stopPolling } = useGetBankTransfersBySubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId || !isBankMember,
  });
  const bankTransfers = data?.getBankTransfersBySubscription;
  const bankTransfer = bankTransferId
    ? bankTransfers?.find((bt) => bt.id === bankTransferId)
    : bankTransfers?.[0];

  const isLoading = bankAccountReq.loading || bankAccountMemberReq.loading || loading;

  const [generateSwanAuthUrl, { loading: isSwanLoading }] = useGenerateSwanAuthUrlLazyQuery();
  const [isTransferInProgress, setIsTransferInProgress] = useState(false);
  const [isPollingStarted, setIsPollingStarted] = useState(false);
  const [executedAmount, setExecutedAmount] = useState<number>(0);

  const bankTransferInitiated = useMemo(
    () =>
      !!executedAmount &&
      bankTransfers?.length &&
      bankTransfers[bankTransfers.length - 1].amount === executedAmount &&
      bankTransfers[bankTransfers.length - 1].side === TransferSide.OUT &&
      [TransferStatus.PENDING, TransferStatus.BOOKED].includes(
        bankTransfers[bankTransfers.length - 1].status,
      ),
    [executedAmount, bankTransfers],
  );

  useEffect(() => {
    if (isPollingStarted) {
      if (!isTransferInProgress) {
        setIsTransferInProgress(true);
      }

      const timeout = setTimeout(() => {
        stopPolling();
        setIsPollingStarted(false);
        setIsTransferInProgress(false);
      }, 120_000);

      if (bankTransferInitiated) {
        stopPolling();
        setIsPollingStarted(false);
        setIsTransferInProgress(false);
        refetchSubscription();
      }

      return (): void => clearTimeout(timeout);
    }
    return noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isPollingStarted, isTransferInProgress, bankTransferInitiated, stopPolling]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Reset loader when popup window is closed
      if (popupWindowRef?.current?.closed && !isPollingStarted) {
        popupWindowRef.current = null;
        setIsTransferInProgress(false);
      }
    }, 500);

    return (): void => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* ** Overwrite default values with specified amount ** */
  const defaultValues = useMemo(
    (): IRefundForm => ({ amount: defaultAmount, label: t('RefundLabel') }),
    [defaultAmount, t],
  );

  const { register, formState, handleSubmit } = useForm<IRefundForm>({
    defaultValues,
    resolver: yupResolver(refundSchema),
  });

  const handleFormSubmit: SubmitHandler<IRefundForm> = (data) => {
    if (!bankTransfer) return;

    setExecutedAmount(data.amount);

    const searchParams: Record<string, string> = {
      amount: data.amount.toString(),
      label: data.label,
    };
    if (bankTransferId) {
      searchParams['bankTransferId'] = bankTransferId;
    }

    const redirectUrlState =
      resolvePath(
        generatePath(routes.bank.initiatePayments.refund.full, { subscriptionId }),
        companyId ? generatePath(routes.company.href, { companyId }) : routes.me.href,
      ).pathname +
      '?' +
      new URLSearchParams(searchParams).toString();
    generateSwanAuthUrl({
      variables: {
        identityId,
        redirectUrlState,
      },
      onCompleted: (data) => {
        setIsTransferInProgress(true);
        const swanAuthorizationUrl = data.generateSwanAuthUrl;

        const popupWidth = 500;
        const popupHeight = 700;

        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width =
          window.innerWidth || document.documentElement.clientWidth || window.screen.width;
        const height =
          window.innerHeight || document.documentElement.clientHeight || window.screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft;
        const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop;

        popupWindowRef.current = window.open(
          swanAuthorizationUrl,
          'SwanOAuthPopup',
          `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
        );
      },
    });
  };

  // Function triggered by child popup window on close
  window.closePopup = async function (): Promise<void> {
    if (!popupWindowRef.current) return;
    setIsPollingStarted(true);
    startPolling(1000);
    popupWindowRef.current.close();
  };

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Refund')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading && (
            <Stack alignItems="center" h="100px" justifyContent="center">
              <Spinner flexShrink={0} />
            </Stack>
          )}
          {!isLoading && !isBankMember && (
            <WarningCard
              subtitle={t('UnauthorizedOperationOnBankAccountDescription')}
              title={t('UnauthorizedOperationOnBankAccount')}
            />
          )}
          {!isLoading && bankTransferInitiated && (
            <HStack color="green.500" justify="center" pt="5">
              <Icon as={CheckCircleIcon} boxSize="24px" />
              <Text fontSize="lg" fontWeight="500">
                {t('RefundInitiated')}
              </Text>
            </HStack>
          )}
          {!isLoading && !!executedAmount && !bankTransferInitiated && (
            <HStack color="orange.400" justify="center" pt="5">
              <Icon as={DotsHorizontalIcon} boxSize="24px" />
              <Text fontSize="lg" fontWeight="500">
                {t('RefundCreationInProgress')}
              </Text>
            </HStack>
          )}
          {!isLoading &&
            isBankMember &&
            !executedAmount &&
            !bankTransferInitiated &&
            (bankTransfer ? (
              <form id="refund-form" onSubmit={handleSubmit(handleFormSubmit)}>
                <Stack spacing="4">
                  <BankDetailsCard
                    iban={bankTransfer.debtorIban || ''}
                    recipientName={bankTransfer.debtorName || ''}
                    title={t('Recipient', { nb: 1 })}
                  />
                  <FormControl isInvalid={!!formState.errors.amount}>
                    <FormLabel htmlFor="amount">{t('Amount', { nb: 1 })}</FormLabel>
                    <InputGroup>
                      <Input id="amount" step="0.01" type="number" {...register('amount')} />
                      <InputRightAddon>€</InputRightAddon>
                    </InputGroup>
                    <ErrorMessage error={formState.errors?.amount} />
                  </FormControl>
                  <FormControl isInvalid={!!formState.errors?.label}>
                    <FormLabel htmlFor="label">{t('Label', { nb: 1 })}</FormLabel>
                    <Input id="label" type="text" {...register('label')} />
                    <ErrorMessage error={formState.errors?.label} />
                  </FormControl>
                </Stack>
              </form>
            ) : (
              <WarningCard title={t('UnknownBankDetails')} />
            ))}
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          {((!executedAmount && !bankTransferInitiated) || isSwanLoading || isTransferInProgress) &&
          bankTransfer ? (
            <Button
              form="refund-form"
              isDisabled={isSwanLoading || isTransferInProgress}
              isLoading={isSwanLoading || isTransferInProgress}
              type="submit"
            >
              {t('ExecuteBankTransfer')}
            </Button>
          ) : (
            <Button onClick={props.onClose}>{t('Close')}</Button>
          )}
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
