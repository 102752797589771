import { Input as ChakraInput, InputProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = InputProps;

export const Input: ReturnType<typeof forwardRef<HTMLInputElement, Props>> & { id?: string } =
  forwardRef<HTMLInputElement, Props>((props, ref) => {
    return (
      <ChakraInput
        ref={ref}
        onWheel={(e): void => (e.target as HTMLInputElement).blur()}
        {...props}
      />
    );
  });

Input.displayName = 'Input';
Input.id = 'Input';
