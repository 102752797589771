import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  modalAnatomy.keys,
);

const baseStyle = definePartsStyle({
  header: {
    p: '16px 24px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'gray.300',
    fontWeight: 600,
    fontSize: '2xl',
  },
  closeButton: {
    top: 3,
  },
  body: {
    px: 5,
    py: 5,
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});
