import * as yup from 'yup';

import {
  RepresentativeRole,
  requiredNumberField,
  requiredPastDateField,
  requiredStringField,
  requiredUuidField,
} from '@blockpulse3/data/shared';

/**
 * isCompanyComplete.
 *
 * @param {Company | CompanyInfosFragment} company
 * @returns {boolean}
 */
export function isCompanyComplete<T>(company: T | undefined): boolean {
  const schema = yup.object({
    /* ** Basic informations ** */
    name: requiredStringField,
    creationDate: requiredPastDateField,
    country: requiredStringField,
    registrationNumber: requiredStringField,
    registrationEntity: requiredStringField,
    corporateForm: requiredStringField,
    shareCapital: requiredNumberField,
    /* ** Look for at least one defined address ** */
    address: yup.object({
      id: requiredUuidField,
    }),
    /* ** Look for at least one PRESIDENT or COMPANY_MANAGER ** */
    companyRepresentative: yup
      .array()
      .of(
        yup.object({
          id: requiredUuidField,
          role: yup.string().required(),
        }),
      )
      .test({
        name: 'president',
        test: (value) => {
          if (value) {
            return value.some(
              (rep) =>
                rep.role === RepresentativeRole.PRESIDENT ||
                rep.role === RepresentativeRole.COMPANY_MANAGER,
            );
          }
          return false;
        },
      })
      .test({ name: 'not-empty', test: (value) => (value ? value.length > 0 : false) }),
    /* ** Look for at least one defined UBO ** */
    ubos: yup
      .array()
      .of(
        yup.object({
          id: requiredUuidField,
        }),
      )
      .test({ name: 'not-empty', test: (value) => (value ? value.length > 0 : false) }),
  });

  return schema.isValidSync(company);
}
