import {
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import {
  CompanyDocumentType,
  DocumentStatus,
  FundraisingWorkflowType,
  IdentityType,
  IdentityVerificationStatus,
  OperationDocumentType,
  OperationInvestStatus,
  OperationStatus,
  OperationType,
  SubscriptionSide,
  useGenerateSubscriptionIbanMutation,
  useGetDocumentPdfUrlLazyQuery,
  useGetSubscriptionFundraisingDocumentsQuery,
} from '@blockpulse3/graphql/hooks';
import { getDocumentIds } from '@blockpulse3/helpers';
import {
  DocumentLink,
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  VideoLinkModal,
} from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

type Props = BoxProps;

/**
 * SubscriptionDocumentList.
 * View of all subscription related documents.
 *
 * @returns {JSX.Element}
 */
export function SubscriptionDocumentList({ ...props }: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const [isBankDetailsGenerated, setIsBankDetailsGenerated] = useState<boolean>(false);
  const [isBankDetailsGenerating, setIsBankDetailsGenerating] = useState<boolean>(false);

  const [generateSubscriptionIban] = useGenerateSubscriptionIbanMutation();

  const { data, loading, error, refetch } = useGetSubscriptionFundraisingDocumentsQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });

  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();

  const subscription = data?.subscription;
  const operation = subscription?.operation;
  const reference = subscription?.reference?.id;
  const isFundraising = operation?.type === OperationType.FUNDRAISING;
  const isSecondary = operation?.type === OperationType.SECONDARY;
  const isOpportunity = operation?.type === OperationType.OPPORTUNITY;
  const isSeller = subscription?.side === SubscriptionSide.SELLER && isSecondary;
  const sellerHasBankAccount = !!subscription?.sellerIdentity?.bankAccount;
  const hasBuyer = !!subscription?.buyerIdentity;
  const isAllInSubscriptionWorkflow =
    isFundraising &&
    subscription?.operation?.fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;

  useEffect(() => {
    if (!data) return;
    // Skip bank details generation if the subscription is not ready
    if (
      isOpportunity ||
      (isSecondary && (!sellerHasBankAccount || !hasBuyer || operation.usePivotFiscalAccount)) ||
      (isFundraising &&
        isAllInSubscriptionWorkflow &&
        operation?.status !== OperationStatus.REVIEWED)
    ) {
      setIsBankDetailsGenerated(true);
      return;
    }
    // Generate bank details if not already generated
    if (!reference && !isBankDetailsGenerated && !isBankDetailsGenerating) {
      setIsBankDetailsGenerating(true);
      generateSubscriptionIban({
        variables: {
          subscriptionId: data.subscription.id,
        },
        onCompleted: () => {
          setIsBankDetailsGenerated(true);
          setIsBankDetailsGenerating(false);
          refetch();
        },
        onError: () => {
          setIsBankDetailsGenerated(true);
          setIsBankDetailsGenerating(false);
        },
      });
    } else {
      setIsBankDetailsGenerated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reference,
    data,
    isBankDetailsGenerated,
    generateSubscriptionIban,
    isBankDetailsGenerating,
    refetch,
    sellerHasBankAccount,
    hasBuyer,
    isSecondary,
  ]);

  if (loading || !isBankDetailsGenerated) {
    return (
      <Skeleton>
        <Card h="220px" />
      </Skeleton>
    );
  }

  if (error || !subscription || !operation) {
    return <ErrorQueryCard />;
  }

  const { buyerIdentity } = subscription;
  const { name: companyName, signer, contact } = operation.company;

  const repIndividualIdentity = contact?.individualIdentity || signer.individualIdentity;
  const isRepVerified =
    repIndividualIdentity?.identity?.verificationStatus === IdentityVerificationStatus.APPROVED;

  const documentIds = getDocumentIds(subscription.subscriptionDocuments);
  const holdingMethodCertificate = subscription.subscriptionDocuments.find(
    (doc) =>
      doc.status === DocumentStatus.SIGNED &&
      [
        OperationDocumentType.IR_PME_CERTIFICATE,
        OperationDocumentType.PEA_CERTIFICATE,
        OperationDocumentType.PEA_PME_CERTIFICATE,
        OperationDocumentType.OWNERSHIP_CERTIFICATE,
      ].includes(doc.type as OperationDocumentType),
  );
  const customFile = subscription.subscriptionDocuments.filter(
    (doc) => doc.type === OperationDocumentType.CUSTOM_FILE,
  );

  const onDocumentClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        window.open(pdfUrl, '_blank');
      },
    });
  };

  const showBankDetailsDocument =
    !isSeller &&
    buyerIdentity?.isVerified &&
    !subscription?.bankVirtualIban?.deletedAt &&
    !!documentIds[OperationDocumentType.IBAN] &&
    subscription?.investStatus !== OperationInvestStatus.DECLINED;

  const videoLink = data.subscription.operation.videoLink || '';

  return (
    <Card {...props}>
      <CardHeader>
        <Heading size="md">
          {t('OperationRelatedDocuments', {
            companyName,
          })}
        </Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing="4">
          <Stack spacing="2">
            <DocumentLink
              fileName={i18nDocumentTitle('STATUTSwName', {
                companyName,
              })}
              onClick={(): void => onDocumentClick(documentIds[CompanyDocumentType.STATUTS])}
            />
            <DocumentLink
              fileName={i18nDocumentTitle('REGISTER_EXTRACTwName', {
                companyName,
              })}
              onClick={(): void =>
                onDocumentClick(documentIds[CompanyDocumentType.REGISTER_EXTRACT])
              }
            />
            {isFundraising && (
              <DocumentLink
                fileName={i18nDocumentTitle('PV_EXERCICEshort')}
                onClick={(): void =>
                  onDocumentClick(documentIds[OperationDocumentType.PV_EXERCICE])
                }
              />
            )}
            {showBankDetailsDocument && (
              <DocumentLink
                fileName={t('SubscriptionPersonalIBAN')}
                onClick={(): void => onDocumentClick(documentIds[OperationDocumentType.IBAN])}
              />
            )}
            {holdingMethodCertificate && (
              <DocumentLink
                fileName={i18nDocumentTitle(holdingMethodCertificate.type as IntlDocumentValues)}
                onClick={(): void => onDocumentClick(holdingMethodCertificate.id)}
              />
            )}
            {customFile.map((file, index) => {
              const fileName =
                file.title === 'CUSTOM_FILE' ? `${t('Annex', { nb: 1 })} ${++index}` : file.title;
              return (
                <DocumentLink
                  key={file.id}
                  fileName={fileName}
                  onClick={(): void => onDocumentClick(file.id)}
                />
              );
            })}
            {!!videoLink && <VideoLinkModal videoLink={videoLink} />}
          </Stack>

          <Heading color="gray.600" fontSize="md">
            {t('Contact', { nb: 1 })}
          </Heading>
          <Stack bgColor="gray.50" borderRadius="2" padding="3">
            <IdentityCard>
              <IdentityAvatar
                boxSize="10"
                src={repIndividualIdentity?.identity?.profilePicture}
                type={IdentityType.INDIVIDUAL}
              />
              <IdentityCardTitle isChecked={isRepVerified}>
                <Text>{repIndividualIdentity?.name}</Text>
              </IdentityCardTitle>
              <IdentityCardDescription>{repIndividualIdentity?.email}</IdentityCardDescription>
            </IdentityCard>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
}

export type SubscriptionDocumentListProps = Props;
