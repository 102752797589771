import { FormControl, FormLabel, HStack, Text, useDisclosure } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import {
  MergedMifidSection,
  MergedQuestionFile,
  useDeleteMifidDocumentMutation,
  useGetMifidSectionDocumentsQuery,
  usePreviewMifidQuestionDocumentLazyQuery,
} from '@blockpulse3/graphql/hooks';
import { getURLObject } from '@blockpulse3/helpers';
import {
  CONTENT_LOADING,
  DropzoneInput,
  ErrorMessage,
  ExternalLink,
  PreviewDocumentModal,
} from '@blockpulse3/ui/commons';

import { QuestionTooltip } from '../QuestionTooltip';
import { useMifidModal } from '../provider';

type Props = {
  /* ** Current question ** */
  question: MergedQuestionFile;
  /* ** Current section id ** */
  sectionId: MergedMifidSection['sectionId'];
  /* ** Existing files record for this question ** */
  files: Record<string, File>;
};

export function FileQuestion({ question, sectionId, files }: Props): JSX.Element {
  const t = useTranslations();

  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [templateUrl, setTemplateUrl] = useState<string | symbol | null>(CONTENT_LOADING);
  const { questionId, label, tooltip, externalLink } = question;

  const { isSectionDisabled, subscriptionId } = useMifidModal();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteDocument] = useDeleteMifidDocumentMutation();
  const [previewTemplate] = usePreviewMifidQuestionDocumentLazyQuery();

  const { data, loading, refetch } = useGetMifidSectionDocumentsQuery({
    variables: {
      sectionId,
      subscriptionId,
    },
    skip: !subscriptionId,
  });

  const { setError, clearErrors, setValue, formState, control } = useFormContext();

  const file = data?.getMifidSectionDocuments?.sectionDocuments[questionId];

  useEffect(() => {
    if (file) {
      const documentId = Object.keys(file);
      setValue(questionId, documentId);
    }
  }, [file, questionId, setValue]);

  /* ** Method to upload a file using axios ** */
  const handleFileUpload = async (acceptedFiles: File[]): Promise<void> => {
    if (!subscriptionId) return;

    const formData = new FormData();
    formData.append('subscriptionDocument', acceptedFiles[0]);
    formData.append('subscriptionId', subscriptionId);
    formData.append('sectionId', sectionId);
    formData.append('questionId', questionId);

    setIsFileLoading(true);
    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/subscriptions/uploadMifidQuestionDocument',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .catch(() => {
        setError(questionId, {
          type: 'custom',
          message: FormErrors.DropzoneDefault,
        });
      });

    await refetch();
    setIsFileLoading(false);
  };

  /* ** Upload file handler ** */
  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    if (isSectionDisabled) return;

    clearErrors(questionId);
    if (fileRejections.length === 0) {
      handleFileUpload(acceptedFiles);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError(questionId, { type: 'custom', message: FormErrors.FileTooLarge });
          break;
        }
        default: {
          setError(questionId, {
            type: 'custom',
            message: FormErrors.DropzoneDefault,
          });
          break;
        }
      }
    }
  };

  /* ** Delete file handler ** */
  const handleFileDelete = (): void => {
    if (!subscriptionId || isSectionDisabled) return;

    deleteDocument({
      variables: {
        deleteMifidDocumentInput: {
          subscriptionId,
          sectionId,
          questionId,
        },
      },
      onCompleted: () => {
        refetch();

        // Reset value to prevent navigation to next step
        setValue(questionId, []);
      },
    });
  };

  const handleFilePreview = (): void => {
    setTemplateUrl(CONTENT_LOADING);
    onOpen();

    previewTemplate({
      variables: {
        subscriptionId,
        questionId,
        sectionId,
      },
      fetchPolicy: 'no-cache',
      onCompleted({ previewMifidQuestionDocument: pdfURL }) {
        const objURL = getURLObject(pdfURL, 'application/pdf');
        setTemplateUrl(objURL);
      },
    });
  };

  return (
    <Controller
      key={questionId}
      control={control}
      name={questionId}
      render={(): JSX.Element => (
        <FormControl my="2">
          <FormLabel htmlFor={questionId}>
            <HStack spacing="1">
              <Text>
                {label}
                {externalLink && <ExternalLink link={externalLink} mx="1" title={t('LearnMore')} />}
                {tooltip && <QuestionTooltip label={tooltip} />}
              </Text>
            </HStack>
          </FormLabel>
          <DropzoneInput
            isPreviewEnabled
            accept={{ 'application/pdf': [] }}
            files={files}
            isLoading={isFileLoading || loading}
            maxFiles={1}
            subTitle=".pdf"
            onDelete={handleFileDelete}
            onDrop={handleFileDrop}
            onPreview={handleFilePreview}
          />
          <ErrorMessage error={formState.errors[questionId] as FieldError} />
          <PreviewDocumentModal
            isOpen={isOpen}
            src={templateUrl}
            title={question.label}
            onClose={onClose}
          />
        </FormControl>
      )}
    />
  );
}
