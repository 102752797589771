import { Badge, HStack, Icon, Text } from '@chakra-ui/react';
import { LibraryIcon } from '@heroicons/react/outline';
import { noop } from '@tanstack/react-table';
import { useTranslations } from 'use-intl';

import { IntlHoldingMethodTypeValues } from '@blockpulse3/data/locales/types';

import { IWalletPills } from '../types';

type Props = {
  wallets: IWalletPills[];
  onClick: (wallet: IWalletPills) => void;
};

export function WalletPills({ wallets, onClick = noop }: Props): JSX.Element {
  const i18nHoldingMethodType = useTranslations('HoldingMethodTypeValues');

  const handlePillsClick = (wallet: IWalletPills): void => {
    onClick(wallet);
  };

  return (
    <HStack flexWrap="wrap">
      {wallets.map((wallet) => {
        return (
          <Badge
            key={wallet.id}
            bgColor="transparent"
            borderColor={wallet.isSelected ? 'gray.300' : 'gray.50'}
            borderStyle="solid"
            borderWidth="1px"
            color={wallet.isSelected ? 'black' : 'gray.500'}
            cursor="pointer"
            py="1"
            onClick={(): void => handlePillsClick(wallet)}
          >
            <HStack spacing="2">
              <Icon
                as={LibraryIcon}
                boxSize="14px"
                color={wallet.isSelected ? 'black' : 'gray.500'}
              />
              <Text>
                {i18nHoldingMethodType(wallet.holdingMethod as IntlHoldingMethodTypeValues)}
              </Text>
              <Badge
                bgColor="gray.200"
                borderRadius="full"
                color={wallet.isSelected ? 'black' : 'gray.500'}
                fontSize="xx-small"
                px="1"
              >
                {wallet?.balances?.length || 0}
              </Badge>
            </HStack>
          </Badge>
        );
      })}
    </HStack>
  );
}
