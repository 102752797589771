import { Skeleton, Stack, useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { ConnectionType } from '@blockpulse3/data/shared';
import {
  CreateSubscriptionInput,
  CreateSubscriptionMutation,
  IdentityInfosFragment,
  OperationSubscription,
  UpdateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetOperationQuery,
  useGetSubscriptionsQuery,
} from '@blockpulse3/graphql/hooks';
import { extractNodesFromEdges } from '@blockpulse3/helpers';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import {
  IOperationSubscriber,
  OperationCSVType,
  OperationSubscribersCSVModal,
  OperationSubscribersModal,
  isLegalSubscriber,
  operationSubscriberDefaultValues,
} from '@blockpulse3/web-client/operation/commons';

import { parseSubscriber } from '../../../utils';
import { IFundraisingSubscribersTable } from '../types';
import {
  SubscribersTable,
  SubscribersTableControls,
  SubscribersTableEmpty,
} from './SubscribersTable';

type Props = unknown;

export function FundraisingSubscribersPanel(): JSX.Element {
  const t = useTranslations();

  /* ** Import CSV modal disclosure ** */
  const subscriptionFileModal = useDisclosure();
  /* ** New subscriber modal disclosure ** */
  const subscriptionFormModal = useDisclosure();

  const { operationId = '' } = useParams();

  const operationReq = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });
  const operation = operationReq.data?.operation;

  const subscriptionsReq = useGetSubscriptionsQuery({
    variables: { operationId, first: 150 },
  });

  useEffect(() => {
    subscriptionsReq.refetch();
  }, [operationId, subscriptionsReq]);

  const subscriptionsData = subscriptionsReq.data;
  const subscriptions = useMemo(() => {
    if (subscriptionsData?.subscriptions) {
      const relay = subscriptionsData?.subscriptions as ConnectionType<OperationSubscription>;
      return extractNodesFromEdges(relay);
    }
    return [];
  }, [subscriptionsData]);

  /* ** Search input to filter subscriber's table ** */
  const [searchInput, setSearchInput] = useState<string>('');
  /* ** Subscriber table key ** */
  const [currentKey, setCurrentKey] = useState<string>('');
  /* ** State holding subscriber form info for the modal ** */
  const [currentSubscriber, setCurrentSubscriber] = useState<IOperationSubscriber>(
    operationSubscriberDefaultValues,
  );
  /* ** Set a company triggering the alert component if registration number is associated to a different company name/mail ** */
  const [invalidLegalSubscriber, setInvalidLegalSubscriber] = useState<
    IdentityInfosFragment['companyIdentity'] | undefined
  >(undefined);

  const subscribersInformations: IFundraisingSubscribersTable = useMemo(() => {
    return subscriptions
      ? subscriptions.reduce((acc, cur) => ({ ...acc, [cur.id]: parseSubscriber(cur) }), {})
      : {};
  }, [subscriptions]);

  const isSubscribersListEmpty = Object.values(subscribersInformations).length === 0;

  const [deleteSubscription] = useDeleteSubscriptionMutation();

  /* ** Current displayed subscribers ** */
  const filteredSubscribers = useMemo(() => {
    if (searchInput === '') {
      return subscribersInformations;
    }
    const searchInputLowerCase = searchInput.toLowerCase();
    const ids = Object.keys(subscribersInformations).filter((id) => {
      const sub = subscribersInformations[id].subscriber;
      if (isLegalSubscriber(sub)) {
        return (
          sub.name.toLowerCase().includes(searchInputLowerCase) ||
          sub.email.toLowerCase().includes(searchInputLowerCase) ||
          sub.identificationNumber.toLowerCase().includes(searchInputLowerCase)
        );
      }
      return (
        sub.firstName.toLowerCase().includes(searchInputLowerCase) ||
        sub.lastName.toLowerCase().includes(searchInputLowerCase) ||
        sub.email.toLowerCase().includes(searchInputLowerCase)
      );
    });
    return ids.reduce((acc, cur) => ({ ...acc, [cur]: subscribersInformations[cur] }), {});
  }, [searchInput, subscribersInformations]);

  /* ** Subscriber table toolbar callbacks ** */
  const handleSearchInputChange = (input: string): void => {
    setSearchInput(input);
  };

  const handleImportSubscriptionOpen = (): void => {
    subscriptionFileModal.onOpen();
  };

  const handleImportSubscriptionClose = (): void => {
    subscriptionFileModal.onClose();
  };

  const handleCreateSubscriptionOpen = (): void => {
    setCurrentSubscriber(operationSubscriberDefaultValues);
    setCurrentKey('');
    setSearchInput('');
    subscriptionFormModal.onOpen();
  };

  const handleCreateSubscriptionClose = (): void => {
    setCurrentSubscriber(operationSubscriberDefaultValues);
    subscriptionFormModal.onClose();
  };

  /* ** Subscriber table callbacks ** */
  const handleSubscriberEdit = (key: string, data: IOperationSubscriber): void => {
    setCurrentKey(key);
    setCurrentSubscriber(data);
    subscriptionFormModal.onOpen();
  };

  const handleSubscriberDelete = (subscriptionId: string): void => {
    deleteSubscription({
      variables: {
        subscriptionId,
      },
      onCompleted: () => {
        subscriptionsReq.refetch();
      },
    });
  };

  /* ** Subscriber form modal callbacks ** */
  const handleSubscriberModalComplete = (
    data: CreateSubscriptionMutation | UpdateSubscriptionMutation,
    subscription: CreateSubscriptionInput,
    isLegal: boolean,
  ): void => {
    subscriptionsReq.refetch().then(() => {
      if (isLegal) {
        const createdSubscription =
          (data as CreateSubscriptionMutation)?.createSubscription ||
          (data as UpdateSubscriptionMutation)?.updateSubscription;
        if (createdSubscription) {
          const createdCompany = createdSubscription.buyerIdentity?.companyIdentity;
          if (
            createdCompany?.name !== subscription.name ||
            createdCompany?.signer?.individualIdentity?.email !== subscription.email
          ) {
            setInvalidLegalSubscriber(createdCompany);
          }
        }
      }
    });
  };

  if (operationReq.loading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (!operation || operationReq.error) {
    return <ErrorQueryCard height="350px" width="full" />;
  }

  return (
    <Stack spacing="4">
      <SubscribersTableControls
        onCreateSubscription={handleCreateSubscriptionOpen}
        onImportSubscriptions={handleImportSubscriptionOpen}
        onSearchInputChange={handleSearchInputChange}
      />
      {isSubscribersListEmpty ? (
        <SubscribersTableEmpty />
      ) : (
        <SubscribersTable
          invalidLegalSubscriber={invalidLegalSubscriber}
          rows={filteredSubscribers}
          onSubscriberDelete={handleSubscriberDelete}
          onSubscriberEdit={handleSubscriberEdit}
        />
      )}
      <OperationSubscribersModal
        amountRangeRequired={false}
        amountRequired={true}
        defaultValues={currentSubscriber}
        feesRequired={true}
        isOpen={subscriptionFormModal.isOpen}
        modalSubmitText={currentKey ? t('ModifyTheSubscriber') : t('AddTheSubscriber')}
        modalTitle={currentKey ? t('ModifySubscriber') : t('AddSubscriber')}
        operationId={operationId}
        setInvalidLegalSubscriber={setInvalidLegalSubscriber}
        subscriptionId={currentKey}
        onClose={handleCreateSubscriptionClose}
        onComplete={handleSubscriberModalComplete}
      />
      <OperationSubscribersCSVModal
        isOpen={subscriptionFileModal.isOpen}
        operationCsvType={OperationCSVType.FUNDRAISING_PRIVATE}
        operationId={operationId}
        onClose={handleImportSubscriptionClose}
        onComplete={subscriptionsReq.refetch}
      />
    </Stack>
  );
}

export type FundraisingSubscribersPanelProps = Props;
