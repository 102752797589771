import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { ClipboardListIcon, CurrencyEuroIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Exceptions } from '@blockpulse3/data/shared';
import {
  FundraisingWorkflowType,
  IdentityVerificationStatus,
  OperationStatus,
  useGetBankAccountByCompanyQuery,
  useGetBankAccountStatusByCompanyQuery,
  useGetOperationQuery,
  useReviewFundraisingMutation,
} from '@blockpulse3/graphql/hooks';
import { useErrorToast } from '@blockpulse3/ui/commons';
import { IdentityVerificationModal } from '@blockpulse3/web-client/user';

type Props = unknown;

let timeoutId: NodeJS.Timeout | null = null;

/**
 * FundraisingView.
 * View of a Fundraising.
 *
 * @returns {JSX.Element}
 */
export function FundraisingStatusAlert(): JSX.Element | null {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { operationId = '' } = useParams();

  const [isPollingStarted, setIsPollingStarted] = useState<boolean>(false);
  const [isPollingLoading, setIsPollingLoading] = useState<boolean>(false);

  const swanOnboardingModal = useDisclosure();

  const { data, loading, refetch } = useGetOperationQuery({
    variables: {
      operationId,
    },
  });
  const operation = data?.operation;
  const companyId = operation?.company?.id;

  const bankAccountReq = useGetBankAccountByCompanyQuery({
    variables: {
      companyId: companyId || '',
    },
    skip: !operation || operation?.isExternalIBAN,
  });
  const bankAccount = bankAccountReq.data?.getBankAccountByCompany;

  const bankAccountStatusReq = useGetBankAccountStatusByCompanyQuery({
    variables: {
      companyId: companyId || '',
    },
    skip: !operation || operation?.isExternalIBAN || bankAccountReq.loading || !!bankAccount,
  });
  const bankAccountStatus =
    bankAccountStatusReq.data?.getBankAccountStatusByCompany || IdentityVerificationStatus.NONE;
  const isOnboardingPending = [IdentityVerificationStatus.PENDING].includes(bankAccountStatus);

  useEffect(() => {
    const { startPolling, stopPolling } = bankAccountStatusReq;

    if (!isPollingStarted && isOnboardingPending) {
      setIsPollingStarted(true);
      setIsPollingLoading(true);
      startPolling(2000);

      if (timeoutId) clearTimeout(timeoutId);

      /* ** Stop polling after 2min ** */
      timeoutId = setTimeout(() => {
        stopPolling();
        setIsPollingLoading(false);
      }, 120_000);
    }

    /* ** Stop polling when step status is VALID ** */
    if (isPollingStarted && !isOnboardingPending) {
      stopPolling();
      setIsPollingStarted(false);
      setIsPollingLoading(false);
      refetch();
      bankAccountReq.refetch();
      bankAccountStatusReq.refetch();
    }
  }, [isPollingStarted, isOnboardingPending, bankAccountReq, bankAccountStatusReq, refetch]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const [reviewFundraising, { loading: reviewLoading }] = useReviewFundraisingMutation();

  const handleReviewFundraising = (): void => {
    reviewFundraising({
      variables: {
        operationId,
      },
      onCompleted: () => {
        refetch();
      },
      onError: (err: unknown) => {
        const error = err as Error;
        if (error.message === Exceptions.CannotReviewedHardCapExceeded) {
          errorToast({
            title: t('FundraisingRevisionMaxAmountError'),
          });
        }
      },
    });
  };

  if (!operation?.fundraising || loading || bankAccountReq.loading) return null;

  const isBankAccountAvailable = operation.isExternalIBAN || bankAccount;
  const isHardCapExceeded =
    operation.status === OperationStatus.CLOSED &&
    operation.fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;

  const handleSwanModalOpen = (): void => {
    if (!bankAccount && !isOnboardingPending) {
      swanOnboardingModal.onOpen();
    }
  };

  const refetchAllData = (): void => {
    refetch();
    bankAccountReq.refetch();
    bankAccountStatusReq.refetch();
  };

  if (!isBankAccountAvailable && operation.status !== OperationStatus.FINALIZED) {
    return (
      <Alert boxShadow="md" status="warning">
        <Stack
          alignItems="stretch"
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          spacing="4"
          width="full"
        >
          <HStack alignItems="flex-start" spacing="0">
            <AlertIcon as={CurrencyEuroIcon} />
            <Stack spacing="0">
              <AlertTitle fontWeight="bold">{t('BankAccountCreationOnHold')}</AlertTitle>
              <AlertDescription>{t('BankAccountCreationOnHoldDescription')}</AlertDescription>
            </Stack>
          </HStack>
          <HStack alignItems="center" ml={{ base: '0', md: '4' }}>
            <Button
              className="confirm"
              fontSize="sm"
              isDisabled={swanOnboardingModal.isOpen || isPollingLoading || isOnboardingPending}
              isLoading={swanOnboardingModal.isOpen || isPollingLoading}
              loadingText={isOnboardingPending && t('InProgress')}
              onClick={handleSwanModalOpen}
            >
              {t(isOnboardingPending ? 'InProgress' : 'CreateBankAccount')}
            </Button>
          </HStack>
        </Stack>
        {swanOnboardingModal.isOpen && (
          <IdentityVerificationModal
            isIssuer={true}
            isOpen={swanOnboardingModal.isOpen}
            isSwanOnboarding={true}
            refetchData={refetchAllData}
            onClose={swanOnboardingModal.onClose}
          />
        )}
      </Alert>
    );
  }

  if (isHardCapExceeded) {
    return (
      <Alert boxShadow="md" status="warning">
        <Stack
          alignItems="stretch"
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          spacing="4"
          width="full"
        >
          <HStack alignItems="flex-start" spacing="0">
            <AlertIcon as={ClipboardListIcon} />
            <Stack spacing="0">
              <AlertTitle fontWeight="bold">{t('CapitalIncreaseToCheck')}</AlertTitle>
              <AlertDescription>{t('TotalSubscriptionAmountExceedsMaximal')}</AlertDescription>
            </Stack>
          </HStack>
          <HStack alignItems="center" ml={{ base: '0', md: '4' }}>
            <Button
              className="confirm"
              fontSize="sm"
              isDisabled={reviewLoading}
              isLoading={reviewLoading}
              onClick={handleReviewFundraising}
            >
              {t('RevisionCompleted')}
            </Button>
          </HStack>
        </Stack>
      </Alert>
    );
  }

  return null;
}

export type FundraisingStatusAlertProps = Props;
