import {
  Icon,
  IconButton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { TrashIcon } from '@heroicons/react/outline';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useDeleteAssetPriceMutation,
  useGetAssetPricesByCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { DeleteConfirmModal } from '@blockpulse3/ui/commons';

export function SettingsCompanyAssetPriceTable(): JSX.Element {
  const t = useTranslations();
  const { companyId = '' } = useParams();

  const [selectedAssetPriceId, setSelectedAssetPriceId] = useState<string | null>(null);

  const getAssetPricesByCompanyReq = useGetAssetPricesByCompanyQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const [deleteAssetPrice, { loading: isDeleteLoading }] = useDeleteAssetPriceMutation();

  const confirmRef = useRef(null);
  const deleteModal = useDisclosure();

  const assetsPriceLoading = getAssetPricesByCompanyReq.loading;

  const assetsPrice = getAssetPricesByCompanyReq?.data?.getAssetPricesByCompany;

  const handleDelete = (): void => {
    if (!selectedAssetPriceId) return;

    deleteAssetPrice({
      variables: {
        deleteAssetPriceInput: {
          companyId,
          assetPriceId: selectedAssetPriceId,
        },
      },
      onCompleted: () => {
        getAssetPricesByCompanyReq.refetch();
        setSelectedAssetPriceId(null);
        deleteModal.onClose();
      },
    });
  };

  const handleRowClick = (assetPriceId: string): void => {
    setSelectedAssetPriceId(assetPriceId);
    deleteModal.onOpen();
  };

  return (
    <Stack spacing="2">
      {!assetsPriceLoading && assetsPrice && (
        <Table variant="light">
          <Thead>
            <Tr>
              <Th>{t('Date', { nb: 1 })}</Th>
              <Th>{t('PricePerShare')}</Th>
              <Th>{t('OperationName')}</Th>
              <Th w="10"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {assetsPrice.map((assetPrice) => {
              const label = assetPrice?.operation?.name || assetPrice?.label || '-';

              return (
                <Tr key={assetPrice.id}>
                  <Td>
                    <Text>{formatDate(assetPrice.date, 'ddd D MMMM YYYY')}</Text>
                  </Td>
                  <Td isNumeric fontWeight="600" whiteSpace="nowrap">
                    {formatNumberCurrency(assetPrice.price)}
                  </Td>
                  <Td isNumeric fontWeight="600" whiteSpace="nowrap">
                    {label}
                  </Td>
                  <Td align="right">
                    <IconButton
                      aria-label="delete"
                      icon={<Icon as={TrashIcon} boxSize="4" />}
                      size="xs"
                      variant="icon-delete"
                      onClick={(): void => handleRowClick(assetPrice.id)}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
      <DeleteConfirmModal
        isLoading={isDeleteLoading}
        isOpen={deleteModal.isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteAssetPrice')}
        onClose={deleteModal.onClose}
        onDelete={handleDelete}
      />
    </Stack>
  );
}
