import { useDisclosure } from '@chakra-ui/react';
import { PresentationChartBarIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { MergedMifidStep, SubscriptionStepStatus } from '@blockpulse3/graphql/hooks';

import { SubscriptionStep } from '../../SubscriptionStep';
import { MifidModal } from './MifidModal';

type Props = {
  subscriptionStep: MergedMifidStep;
  isDisabled: boolean;
};

export function MifidStep({ subscriptionStep, isDisabled }: Props): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleModalOpen = (status: SubscriptionStepStatus): void => {
    if (![SubscriptionStepStatus.VALID, SubscriptionStepStatus.PENDING].includes(status)) {
      onOpen();
    }
  };

  return (
    <>
      <SubscriptionStep
        icon={PresentationChartBarIcon}
        isDisabled={isDisabled}
        status={subscriptionStep.status}
        title={t('ProfileInvestor')}
        type={subscriptionStep.type}
        onClick={handleModalOpen}
      />
      <MifidModal isOpen={isOpen} subscriptionId={subscriptionId} onClose={onClose} />
    </>
  );
}
