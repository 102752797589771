import { Box, Image, Spinner } from '@chakra-ui/react';

import BlockpulseLogo from './sm-logo.svg';

type Props = unknown;

export function BlockpulseLoading(): JSX.Element {
  return (
    <Box display="inline-block" position="relative">
      <Spinner emptyColor="gray.200" h="65px" speed="2s" thickness="2px" w="65px" />
      <Box
        alignItems="center"
        bg="rgba(243, 244, 246, 1)"
        borderRadius="50%"
        display="flex"
        height="40px"
        justifyContent="center"
        left="12px"
        position="absolute"
        top="12px"
        width="40px"
        boxShadow="0 5px 4px -1px rgba(14, 26, 81, 0.4),
        inset 0 -6px 6px -1px rgba(14, 26, 81, 0.4)
        "
      >
        <Image alt="blockpulse-logo" boxSize="20px" src={BlockpulseLogo} />
      </Box>
    </Box>
  );
}

export type BlockpulseLoadingProps = Props;
