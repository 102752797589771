import { Badge, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { AssetType, useGetSubscriptionQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberInt } from '@blockpulse3/helpers';
import { AssetBadge } from '@blockpulse3/ui/commons';
import { useInvestStatusBadge } from '@blockpulse3/ui/ui-hooks';

/**
 * AttributionPanelOperation.
 * Operation details section of the attribution side panel.
 *
 * @returns {JSX.Element}
 */
export function AttributionPanelOperation(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const investStatus = useInvestStatusBadge();

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Attribution')}
        </Text>
        <Skeleton h="40px" />
      </Stack>
    );
  }

  const subscription = data.subscription;
  const { operation, grant, shareCount } = subscription;

  const investStatusBadge = investStatus(subscription, operation.status);
  const asset = operation.asset;

  return (
    <Stack px="4" spacing="2">
      <HStack spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Attribution')}
        </Text>
        <Badge colorScheme={investStatusBadge.color} fontSize="xs">
          {investStatusBadge.label}
        </Badge>
      </HStack>
      <AssetBadge type={asset?.assetType || AssetType.ORDINARY_SHARE} value={shareCount} />
      <HStack fontSize="sm" justifyContent="space-between">
        <Text color="gray.500" fontWeight="500">
          {t('ValuationOfRights')}
        </Text>
        <Text fontWeight="500">
          {subscription.investAmount ? formatNumberCurrency(subscription.investAmount) : '-'}
        </Text>
      </HStack>
      <HStack fontSize="sm" justifyContent="space-between">
        <Text color="gray.500" fontWeight="500">
          {t('ConversionParity')}
        </Text>
        <Text fontWeight="500">{formatNumberInt(asset?.conversionParity || 0)}</Text>
      </HStack>
      {grant && (
        <>
          <HStack fontSize="sm" justifyContent="space-between">
            <Text color="gray.500" fontWeight="500">
              {t('VestedQuantity')}
            </Text>
            <Text fontWeight="500">
              {grant?.vestedAmount ? formatNumberInt(grant.vestedAmount || 0) : '-'}
            </Text>
          </HStack>
          <HStack fontSize="sm" justifyContent="space-between">
            <Text color="gray.500" fontWeight="500">
              {t('ExercisedQuantity')}
            </Text>
            <Text fontWeight="500">
              {grant?.exercisedAmount ? formatNumberInt(grant.exercisedAmount || 0) : '-'}
            </Text>
          </HStack>
        </>
      )}
    </Stack>
  );
}
