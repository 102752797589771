import { IdentityType } from '@blockpulse3/graphql/hooks';
import { IOperationSubscriber, isLegalSubscriber } from '@blockpulse3/web-client/operation/commons';

import { ISubscriberTableEntry } from './types';

export function toTableEntry(id: string, value: IOperationSubscriber): ISubscriberTableEntry {
  const { subscriber } = value;

  return isLegalSubscriber(subscriber)
    ? {
        id,
        type: IdentityType.COMPANY,
        name: subscriber.name,
        identifier: subscriber?.identificationNumber || '',
        email: subscriber?.email,
        investAmount: subscriber.amount,
        admissionFees: subscriber.admissionFees,
        verified: subscriber.verified,
      }
    : {
        id,
        type: IdentityType.INDIVIDUAL,
        name: `${subscriber.firstName} ${subscriber.lastName}`,
        email: subscriber.email,
        investAmount: subscriber.amount,
        admissionFees: subscriber.admissionFees,
        verified: subscriber.verified,
      };
}
