import { SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { PlusIcon, XCircleIcon } from '@heroicons/react/outline';
import { ChangeEventHandler, useState } from 'react';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { MemberRole } from '@blockpulse3/graphql/hooks';
import { Input } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = {
  /* ** Callback on new space member button click ** */
  onMemberAdd?: () => void;
  /* ** Callback on search input submit ** */
  onSearchSubmit?: (input: string) => void;
};

export function SpaceMembersControls({
  onMemberAdd = noop,
  onSearchSubmit = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const { user: authUser } = useAuthUser();

  const [searchInput, setSearchInput] = useState<string>('');

  const handleMemberAdd = (): void => {
    onMemberAdd();
  };

  const handleSearchInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputReset = (): void => {
    setSearchInput('');
    onSearchSubmit('');
  };

  const handleSearchInputSubmit = (): void => {
    onSearchSubmit(searchInput);
  };

  const memberRoles = authUser?.members?.[0].roles;
  const showAdminActions =
    memberRoles &&
    (memberRoles.includes(MemberRole.ADMIN) || memberRoles.includes(MemberRole.OWNER));

  return (
    <Stack
      alignItems={{ base: 'flex-start', md: 'flex-end' }}
      direction={{ base: 'column', md: 'row' }}
      justifyContent="space-between"
    >
      <HStack direction={{ base: 'column', md: 'row' }}>
        <InputGroup width={{ base: 'full', md: '300px' }}>
          <Input
            paddingRight="80px"
            placeholder={t('Search')}
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={(e): void => {
              if (e.key === 'Enter') {
                handleSearchInputSubmit();
              }
            }}
          />
          <InputRightElement
            flexDirection="row-reverse"
            justifyContent="space-between"
            paddingX="1"
            width="80px"
          >
            <IconButton
              aria-label="search-members"
              icon={<Icon as={SearchIcon} />}
              size="sm"
              variant="secondary"
              onClick={handleSearchInputSubmit}
            />
            {!!searchInput && (
              <IconButton
                aria-label="reset"
                icon={<Icon as={XCircleIcon} />}
                size="sm"
                variant="ghost"
                onClick={handleSearchInputReset}
              />
            )}
          </InputRightElement>
        </InputGroup>
      </HStack>
      {showAdminActions && (
        <Button
          flexShrink="0"
          leftIcon={<Icon as={PlusIcon} />}
          variant="secondary"
          onClick={handleMemberAdd}
        >
          {t('AddMember')}
        </Button>
      )}
    </Stack>
  );
}

export type SpaceMembersControlsProps = Props;
